/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a clinical note permission resource
 */
export interface NotePermissionResource { 
    /**
     * Indicates whether the permission allows assessments
     */
    canAssess: boolean;
    /**
     * Indicates whether the permission allows deletions.
     */
    canDelete: boolean;
    /**
     * Indicates whether the permission allows updates.
     */
    canUpdate: boolean;
}

