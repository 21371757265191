import {
  ClinicalNoteCreateRequestResource,
  NotePutRequest,
  NoteResource,
  UserInfoResource,
} from '../../../core/generated/api';
import { Person } from '../../../shared/entities/person';
import { ProfessionalCategory } from '../../../shared/entities/professional-category';
import { parseDateTimeFromServer } from '../../../shared/utils/adapter-utils';
import { ClinicalNote } from '../interfaces/clinical-note';
import { NewNoteFormValues } from '../interfaces/new-note-form';

export class ClinicalNotesAdapters {
  public static clinicalNoteBeToFe(note: NoteResource): ClinicalNote {
    let isApproved: boolean | undefined;
    if (note.status === 'approved') {
      isApproved = true;
    } else if (note.status === 'Rejected') {
      isApproved = false;
    }

    const tutor: Person = {
      id: note.createdBy.id,
      firstName: note.createdBy.firstName,
      lastName: note.createdBy.lastName,
    };
    const author: UserInfoResource = {
      id: note.createdBy.id,
      firstName: note.createdBy.firstName,
      lastName: note.createdBy.lastName,
    };

    return {
      id: note.id,
      creationDate: new Date(note.createdAt),
      isApproved,
      typology:
        note.type === 'M'
          ? ProfessionalCategory.Medical
          : ProfessionalCategory.Nursing,
      text: note.additionalNotes,
      evaluationDate: note.assessedAt ? new Date(note.assessedAt) : undefined,
      observationDate: new Date(note.observedAt),

      tutor, //TODO
      author,
      problem: note.detectedProblem
        ? {
            id: note.detectedProblem.problemCatalogEntry.id,
            name: note.detectedProblem.problemCatalogEntry.name,
            valid: note.detectedProblem.problemCatalogEntry.isEnabled,
            problemClass:
              note.detectedProblem.problemCatalogEntry.problemClass === 'M'
                ? ProfessionalCategory.Medical
                : ProfessionalCategory.Nursing, // TODO will be replaced by domain
          }
        : undefined,
      reference: note.clinicalNoteRef
        ? {
            id: note.clinicalNoteRef.linkedTo,
            creationDate: parseDateTimeFromServer(
              note.clinicalNoteRef.createdAt
            ),
          }
        : undefined, //TODO
      encounterId: note.encounterId,
      isUpdated: note.version > 1,
      rejectionReason: note.rejectionReason,
    };
  }

  public static ClinicalNoteFeToCreate(
    note: NewNoteFormValues
  ): ClinicalNoteCreateRequestResource {
    const request: ClinicalNoteCreateRequestResource = {
      type: 'medical' == note.typology ? 'M' : 'I', //TODO: mettere quello reale (M o I)
      observedAt: note.hourAndDate
        ? note.hourAndDate.toISOString()
        : new Date(Date.now()).toISOString(),

      additionalNotes: note.text ?? '', // TODO rimettere possibilità di inviarle undefined se confermato da BE
    };

    if (note.problemId) {
      request.detectedProblemId = note.problemId.id;
    }

    if (note.referenceNote) {
      request.clinicalNoteRefId = note.referenceNote;
    }

    return request;
  }

  static clinicalNoteFeToUpdate(clinicalNote: ClinicalNote): NotePutRequest {
    return {
      description: clinicalNote.text,
      detectedProblemId: clinicalNote.problem?.id,
      ref: clinicalNote.reference?.id,
      startDate: clinicalNote.creationDate.toISOString(),
    };
  }
}

export function randomDate(start: Date, end: Date) {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
}
