import { Sort } from '@angular/material/sort';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityEvent } from '../../../entities/entity-log';
import { createLogComparator } from '../util/log-comparator';
import { LogState } from './log.reducers';

export const selectLogs = createFeatureSelector<LogState>('log');

export const selectLogSort = createSelector(
  selectLogs,
  (state: LogState): Sort | undefined => state.sort
);

export const selectSortedLogEntities = createSelector(
  selectLogs,
  selectLogSort,
  (state: LogState, sort: Sort | undefined): EntityEvent[] => {
    const sortComparator = createLogComparator(sort);
    const sortedLog = state.entities.slice();
    sortedLog.sort(sortComparator);
    return sortedLog.sort(sortComparator);
  }
);

export const selectShowLog = createSelector(
  selectLogs,
  (state: LogState): Date | undefined => state.creationDate
);
