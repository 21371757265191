import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightLetterMatched',
  standalone: true,
})
export class HighlightLetterMatchedPipe implements PipeTransform {
  transform(
    text: string,
    searchCharacter: string,
    spanClass = 'highlighted'
  ): string {
    const pattern = searchCharacter
      .split(' ')
      .filter((texts) => texts.length > 0)
      .join('|');
    const regex = new RegExp(pattern, 'gi');

    return searchCharacter
      ? text.replace(
          regex,
          (match) => `<span class="${spanClass}">${match}</span>`
        )
      : text;
  }
}
