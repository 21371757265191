import { Sort } from '@angular/material/sort';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { EntityEvent } from '../../../entities/entity-log';
import { mockEntityLog } from '../mocks/entity-log.mock';
import { hideLog, showLog, sortLog } from './log.actions';

export interface LogState {
  entities: EntityEvent[];
  creationDate?: Date;
  sort?: Sort;
}

export const initialStateLog: LogState = {
  // TODO: Only for test
  entities: mockEntityLog.events,
  creationDate: undefined,
  sort: { active: 'date', direction: 'asc' },
};

export const logReducer: ActionReducer<LogState, Action> = createReducer(
  initialStateLog,
  on(
    showLog,
    (state: LogState, { creationDate }): LogState => ({
      ...state,
      creationDate,
    })
  ),

  on(
    hideLog,
    (state: LogState): LogState => ({
      ...state,
      creationDate: undefined,
    })
  ),

  on(
    sortLog,
    (state: LogState, { sort }): LogState => ({
      ...state,
      sort,
    })
  )
);
