/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a detected problem log entry resource type.
 */
export interface DetectedProblemLogEntryResource { 
    /**
     * Timestamp when the detected problem was created.
     */
    createdAt: string;
    /**
     * Unique identifier for the user who created the detected problem.
     */
    createdById: string;
    /**
     * Textual content of the detected problem.
     */
    description: string;
    /**
     * Timestamp when the detected problem ended, if applicable.
     */
    endDate?: string;
    /**
     * Unique identifier for the hospitalization where the problem was resolved, if applicable.
     */
    endEncounterId?: string;
    /**
     * Unique identifier for the detected problem.
     */
    id: string;
    /**
     * Unique identifier for the problem catalog entry that was detected.
     */
    problemCatalogEntryId: string;
    /**
     * Timestamp when the detected problem started.
     */
    startDate: string;
    /**
     * Unique identifier for the hospitalization where the problem was initially detected.
     */
    startEncounterId: string;
    /**
     * Timestamp when the detected problem was updated, if applicable.
     */
    updatedAt?: string;
    /**
     * Unique identifier for the user who updated the detected problem, if applicable.
     */
    updatedById?: string;
}

