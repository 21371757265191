import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { selectCurrentDepartment } from '../../../departments/store/departments.selectors';
import { ProblemsFilter } from '../../interfaces/problems-filter';
import { toggleProblemsFilter } from '../../store/problems.actions';
import { selectProblemsFilter } from '../../store/problems.selectors';

interface ProblemsFilterItem {
  id: keyof ProblemsFilter;
  description: string;
}

@Component({
  selector: 'natea-cc-problems-filter',
  templateUrl: './problems-filter.component.html',
  styleUrls: ['./problems-filter.component.scss'],
})
export class ProblemsFilterComponent implements OnInit {
  isDesktopSized = false;

  @Input() isClosedFilter: boolean | undefined;
  @Output() isStateButtonChange = new EventEmitter<boolean>();

  filterProblem$ = this.store.select(selectProblemsFilter);

  problemListItems: Observable<string[]> = this.filterProblem$.pipe(
    map((filter) => {
      const result = [];
      if (filter.showClosed) {
        result.push('closedProblems');
      }
      if (filter.showOpened) {
        result.push('openedProblems');
      }
      if (filter.showMedical) {
        result.push('doctorProblems');
      }
      if (filter.showNursing) {
        result.push('nurseProblems');
      }

      if (
        !filter.showClosed &&
        !filter.showOpened &&
        !filter.showMedical &&
        !filter.showNursing
      ) {
        result.push('noFilter');
      }
      return result;
    })
  );

  readonly filterLabels: ProblemsFilterItem[] = [
    { id: 'showClosed', description: 'closedProblems' },
    { id: 'showOpened', description: 'openedProblems' },
    { id: 'showMedical', description: 'doctorProblems' },
    { id: 'showNursing', description: 'nurseProblems' },
  ];

  showDropdown = false;

  departmentId$ = this.store.select(selectCurrentDepartment);

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.filterProblem$.subscribe((filter) => {
      this.cdr.detectChanges();
      this.cdr.markForCheck();
    });
  }

  onStateButtonChange = (event: Event): void => {
    event.stopPropagation();

    this.showDropdown = !this.showDropdown;
  };

  checkElement = (
    filter: ProblemsFilter | null,
    problemId: keyof ProblemsFilter
  ): boolean => {
    return filter ? filter[problemId] : false;
  };

  onCheckboxChange = (checkBoxChange: MatCheckboxChange): void => {
    this.store.dispatch(
      toggleProblemsFilter({
        key: checkBoxChange.source.value as keyof ProblemsFilter,
      })
    );
  };

  hideDropdown = (event: Event): void => {
    event.stopPropagation();
    this.showDropdown = false;
  };
}
