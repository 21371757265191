/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents the result of clinical notes counting.
 */
export interface NoteStatusCountsResource { 
    /**
     * Count of clinical notes assessed as valid.
     */
    approved?: number;
    /**
     * Count of clinical notes pending assessment.
     */
    approving?: number;
    /**
     * Count of clinical notes assessed as not valid
     */
    rejected?: number;
}

