import { Sort } from '@angular/material/sort';
import { createSelector } from '@ngrx/store';
import { OptionsItem } from 'natea-components';
import { Pagination } from '../../../shared/entities/pagination';
import {
  createProblemsFilterCallback,
  optionItemFromProblemType,
} from '../../../shared/utils/optional-item-entity-utils';
import { hasMorePages } from '../../../shared/utils/utils';
import { selectShownEncounterProblems } from '../../patients/store/clinical-data/problems/patients-data.problems.selectors';
import { PatientData } from '../../patients/store/patients.reducers';
import { selectSelectedPatientData } from '../../patients/store/patients.selectors';
import { selectProblemsUiState } from '../../patients/store/selected-patient/selected-patient.selectors';
import { PatientEncounter } from '../interfaces/patient-encounter';
import { PatientProblem } from '../interfaces/patient-problem';
import { ProblemType } from '../interfaces/problem-type';
import { ProblemsFilter } from '../interfaces/problems-filter';
import { ProblemsVisualization } from '../problems.constants';
import { ProblemModalState, ProblemsState } from './problems.reducers';
import { getOngoingProblems } from '../utils/get-ongoung-problems';

export const selectProblems = selectProblemsUiState;

export const selectProblemsVisualization = createSelector(
  selectProblems,
  (state: ProblemsState): ProblemsVisualization => state.visualization
);

export const selectAllProblemTypes = createSelector(
  selectProblems,
  (state: ProblemsState): ProblemType[] | undefined => state.problemTypes
);

export const selectAllProblemTypesAsOptionItems = createSelector(
  selectProblems,
  selectAllProblemTypes,
  (_: ProblemsState, problemType): OptionsItem<ProblemType>[] =>
    problemType?.map(optionItemFromProblemType) ?? []
);

export const selectProblemsPatientId = createSelector(
  selectProblems,
  (state: ProblemsState): string => state.patientId
);

export const selectProblemsEncounters = createSelector(
  selectProblems,
  (state: ProblemsState): PatientEncounter[] | undefined => state.encounters
);

export const selectProblemsCurrentEncounter = createSelector(
  selectProblems,
  (state: ProblemsState): PatientEncounter | undefined => state.currentEncounter
);
export const selectProblemsSort = createSelector(
  selectProblems,
  (state: ProblemsState): Sort | undefined => state.sort
);

export const selectProblemsFilter = createSelector(
  selectProblems,
  (state: ProblemsState): ProblemsFilter => {
    return state.filter;
  }
);

export const selectIsAppliedFilter = createSelector(
  selectProblemsFilter,
  (filter: ProblemsFilter): boolean => {
    return (
      filter.showClosed ||
      filter.showMedical ||
      filter.showNursing ||
      filter.showOpened
    );
  }
);

export const selectIsFilterNotApplied = createSelector(
  selectIsAppliedFilter,
  (isAppliedFilter: boolean): boolean => !isAppliedFilter
);

export const selectProblemsFiltered = createSelector(
  selectProblems,
  selectShownEncounterProblems,
  selectProblemsFilter,
  (
    _: ProblemsState,
    problems: PatientProblem[] | undefined,
    filter: ProblemsFilter | undefined
  ): PatientProblem[] | undefined => {
    if (!filter) return problems;

    const problemsFilter = createProblemsFilterCallback(filter);
    return problems?.filter(problemsFilter);
  }
);

export const selectProblemsIsLoading = createSelector(
  selectProblems,
  (state: ProblemsState): boolean => state.isLoadingProblems
);

export const selectProblemsIsLoadingProblemData = createSelector(
  selectProblems,
  (state: ProblemsState): boolean => state.isLoadingProblemData
);

export const selectShowProblemsLoader = createSelector(
  selectProblemsIsLoading,
  selectProblemsIsLoadingProblemData,
  (isLoadingProblems, isLoadingProblemsData): boolean =>
    isLoadingProblems || isLoadingProblemsData
);

export const selectProblemsDataPagination = createSelector(
  selectProblems,
  (state: ProblemsState): Pagination | undefined => state.problemsDataPagination
);

export const selectProblemsDataSearchString = createSelector(
  selectProblems,
  (state: ProblemsState): string | undefined => state.problemsDataSearchString
);

export const selectProblemsHasMoreProblemData = createSelector(
  selectProblems,
  (state: ProblemsState): boolean =>
    state.problemsDataPagination
      ? hasMorePages(state.problemsDataPagination)
      : true
);

export const selectProblemsSearchStringAndPagination = createSelector(
  selectProblems,
  selectProblemsDataSearchString,
  selectProblemsDataPagination,
  (
    _: ProblemsState,
    searchString?: string,
    pagination?: Pagination
  ): { searchString?: string; pagination?: Pagination } => ({
    searchString,
    pagination,
  })
);

export const selectProblemModalState = createSelector(
  selectProblems,
  (state: ProblemsState): ProblemModalState | undefined =>
    state.problemModalState
);

export const selectShowProblemModal = createSelector(
  selectProblems,
  (state: ProblemsState): boolean => state.problemModalState !== undefined
);

export const selectProblemsIsCreatingOrEditing = createSelector(
  selectProblems,
  (state: ProblemsState): boolean => state.isCreatingOrEditingProblem
);

export const selectProblemToDelete = createSelector(
  selectProblems,
  (state: ProblemsState): PatientProblem | undefined => state.problemToDelete
);

export const selectSelectedPatientOngoingProblems = createSelector(
  selectSelectedPatientData,
  (state: PatientData | undefined): PatientProblem[] | undefined =>
    state?.problems
);

export const selectSelectedPatientOngoingProblemTypesAsOptionItems =
  createSelector(
    selectSelectedPatientOngoingProblems,
    (
      problems: PatientProblem[] | undefined
    ): OptionsItem<ProblemType>[] | undefined => getOngoingProblems(problems)
  );

export const selectShowProblemLegend = createSelector(
  selectProblems,
  (state: ProblemsState): boolean => state.showProblemLegend
);
