import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Subscription } from 'rxjs';
import { DurationPickerDirective } from '../../directives/duration-picker.directive';
import { NateaIconsModule } from '../../icons/natea-icons.module';
import { FieldErrorComponent } from '../field/field-error/field-error.component';
import { FieldComponent } from '../field/field.component';

export interface Duration {
  minutes: number;
}

@Component({
  selector: 'natea-duration-input',
  templateUrl: './duration-input.component.html',
  styleUrls: ['./duration-input.component.scss'],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NateaIconsModule,
    ReactiveFormsModule,
    FieldErrorComponent,
    DurationPickerDirective,
  ],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DurationInputComponent,
      multi: true,
    },
  ],
})
export class DurationInputComponent
  extends FieldComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Output() focusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  hasFocus = false;
  isAddClass = false;

  private controlSubscription?: Subscription;

  @Input() placeholder?: string;

  get isError(): boolean {
    return this.errorMessages !== undefined && this.errorMessages.length > 0;
  }

  ngOnInit(): void {
    this.controlSubscription = this.internalControl.valueChanges.subscribe(
      (inputValue: string | null) => {
        if (this.onChange) {
          this.onChange(inputValue ? durationFromInput(inputValue) : null);
        }

        this.isAddClass = !!inputValue;
      }
    );
  }
  ngOnDestroy(): void {
    this.controlSubscription?.unsubscribe();
  }

  writeValue(value: Duration | null): void {
    this.durationValue = value ?? undefined;
    this.internalControl.setValue(value ? inputFromDuration(value) : null);
  }

  registerOnChange(fn: (val: Duration | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.internalControl.disable();
    } else {
      this.internalControl.enable();
    }
  }

  onChange?: (val: Duration | null) => void;

  onTouched?: () => void;

  markAsTouched = (): void => {
    if (this.onTouched) {
      this.onTouched();
    }
  };

  inputChanged = (event: Event): void => {
    const inputValue = (event.target as HTMLInputElement).value;
    if (this.onChange) {
      this.onChange(inputValue ? durationFromInput(inputValue) : null);
    }

    this.isAddClass = !!inputValue;
  };

  onFocus = (): void => {
    this.hasFocus = true;
    this.focusChanged.emit(true);
  };

  onBlur = (): void => {
    this.markAsTouched();
    this.hasFocus = false;
    this.focusChanged.emit(false);
  };

  internalControl = new FormControl('00:00');
  durationValue?: Duration;
}

const durationFromInput = (inputValue: string): Duration => {
  const [hours, minutes] = inputValue.split(':');
  return {
    minutes: parseInt(hours, 10) * 60 + parseInt(minutes, 10),
  };
};

const inputFromDuration = (duration: Duration): string => {
  const hours = Math.floor(duration.minutes / 60);
  const minutes = duration.minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
};
