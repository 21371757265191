import { LegendContent, TextsAndOptionsIcons } from '../models/legend-content';

export const textAndIcons: TextsAndOptionsIcons[] = [
  {
    text: 'text1',
    iconName: 'anamnesi',
    iconColor: '#4fa6b5',
    secondColumnText: 'value2',
  },

  {
    text: 'text2',
    iconName: 'anamnesi-familiare',
    iconColor: '#038095',
    secondColumnText: 'value2',
  },
  {
    text: 'text3',
    iconName: 'anamnesi-neonato',
    iconColor: '#025a68',
    secondColumnText: 'value2',
  },
  {
    text: 'text4',
    iconName: 'calendar',
    iconColor: '#4fa6b5',
    secondColumnText: 'value2',
  },
  {
    text: 'text5',
    iconName: 'calendar',
    iconColor: '#4fa6b5',
    secondColumnText: 'value2',
  },
  {
    text: 'text6',
    iconName: 'calendar',
    iconColor: '#4fa6b5',
    secondColumnText: 'value2',
  },
];

export const textAndIcon: LegendContent = {
  isNotForTable: false,
  dataSource: textAndIcons,
};

export const textWithoutIcons: TextsAndOptionsIcons[] = [
  {
    text: 'text1',
    secondColumnText: 'value2',
  },

  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },

  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },

  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },

  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },

  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
  {
    text: 'text1',
    secondColumnText: 'value2',
  },
];

export const textWithoutIcon: LegendContent = {
  isNotForTable: false,
  dataSource: textWithoutIcons,
};

export const oneColumnTextWithoutIcons: TextsAndOptionsIcons[] = [
  {
    text: 'text1',
  },

  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },

  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },

  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },

  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },

  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
  {
    text: 'text1',
  },
];

export const oneColumnTextWithoutIcon: LegendContent = {
  isNotForTable: true,
  dataSource: oneColumnTextWithoutIcons,
};

export const oneColumnTextWitIcons: TextsAndOptionsIcons[] = [
  {
    text: 'text1text1text1text1text1text1df',
    iconName: 'edit',
  },

  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },
  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },
  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },
  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },
  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },
  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },

  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },
  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },
  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },
  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },
  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },
  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },

  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },
  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },
  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },
  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },
  {
    text: 'text1text1text1text1text1text1',
    iconName: 'edit',
  },
  {
    text: 'text1',
    iconName: 'edit',
  },

  {
    text: 'text1',
    iconName: 'edit',
  },
  {
    text: 'text1',
    iconName: 'edit',
  },
  {
    text: 'text1',
    iconName: 'edit',
  },
  {
    text: 'text1',
    iconName: 'edit',
  },
  {
    text: 'text1',
    iconName: 'edit',
  },
  {
    text: 'text1',
    iconName: 'edit',
  },

  {
    text: 'text1',
    iconName: 'edit',
  },
  {
    text: 'text1',
    iconName: 'edit',
  },
  {
    text: 'text1',
    iconName: 'edit',
  },
  {
    text: 'text1',
    iconName: 'calendar',
  },
  {
    text: 'text1',
    iconName: 'bin',
  },
];

export const oneColumnTextWitIcon: LegendContent = {
  isNotForTable: true,
  dataSource: oneColumnTextWitIcons,
};

export const textWiIcon: TextsAndOptionsIcons[] = [
  { iconName: 'edit', iconColor: '#038095', secondColumnText: 'value2' },
  {
    iconName: 'action-visibility',
    iconColor: '#038095',
    secondColumnText: 'value2',
  },
  { iconName: 'esami', iconColor: '#038095', secondColumnText: 'value2' },
  { iconName: 'face', iconColor: '#038095', secondColumnText: 'value2' },
  { iconName: 'hospital', iconColor: '#038095', secondColumnText: 'value2' },
  { iconName: 'anamnesi', iconColor: '#038095', secondColumnText: 'value2' },
  { iconName: 'calendar', iconColor: '#038095', secondColumnText: 'value2' },
  { iconName: 'circle-stop', iconColor: '#038095', secondColumnText: 'value2' },
];

export const tableMockIconWithText: TextsAndOptionsIcons[] = [
  { text: 'value1', iconName: 'edit', secondColumnText: 'value2' },
  { text: 'value1', iconName: 'bin', secondColumnText: 'value2' },
  { text: 'value1', iconName: 'esami', secondColumnText: 'value2' },
  { text: 'value1', iconName: 'face', secondColumnText: 'value2' },
  { text: 'value1', iconName: 'hospital', secondColumnText: 'value2' },
  { text: 'value1', iconName: 'anamnesi', secondColumnText: 'value2' },
  { text: 'value1', iconName: 'calendar', secondColumnText: 'value2' },
  { text: 'value1', iconName: 'circle-stop', secondColumnText: 'value2' },

  { text: 'value1', secondColumnText: 'value2' },
  { text: 'value1', secondColumnText: 'value2' },
  { text: 'value1', secondColumnText: 'value2' },
  { text: 'value1', secondColumnText: 'value2' },
  { text: 'value1', secondColumnText: 'value2' },
  { text: 'value1', secondColumnText: 'value2' },
  { text: 'value1', secondColumnText: 'value2' },
  { text: 'value1', secondColumnText: 'value2' },
];

export const tableMockWithOnlyIcons: TextsAndOptionsIcons[] = [
  { iconName: 'edit', secondColumnText: 'value2' },
  { iconName: 'bin', secondColumnText: 'value2' },
  { iconName: 'esami', secondColumnText: 'value2' },
  { iconName: 'face', secondColumnText: 'value2' },
  { iconName: 'hospital', secondColumnText: 'value2' },
  { iconName: 'anamnesi', secondColumnText: 'value2' },
  { iconName: 'calendar', secondColumnText: 'value2' },
  { iconName: 'circle-stop', secondColumnText: 'value2' },
];

export const tableMockWithOnlyIcon: LegendContent = {
  isNotForTable: false,
  dataSource: tableMockWithOnlyIcons,
};
