import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NateaIconsName } from 'natea-components';
import { User, UserRole } from '../../shared/entities/user';
import { AppRoutes } from '../../shared/routes/routes';
import { BoardUserData } from '../board/interfaces/board-user-type';
import { LoginData } from './store/interfaces/login-data';
import {
  loginData,
  loginDemoAsNotStructuredDoctor,
  loginDemoAsNurse,
  loginDemoAsStructuredDoctor,
  loginDemoAsTutorDoctor,
} from './store/login.actions';
import { selectShowDemoLoginButtons } from './store/login.selectors';

@Component({
  selector: 'natea-cc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  isPasswordVisible = false;

  loginForm: FormGroup<{
    userName: FormControl<string | null>;
    password: FormControl<string | null>;
  }> = this.fb.group({
    userName: new FormControl(''),
    password: new FormControl(''),
  });

  boardUserData: BoardUserData = {
    userId: 'user1',
    originEntityType: 'pasteboard',
  };

  showDemoButtons$ = this.store.select(selectShowDemoLoginButtons);

  get passwordInputType(): string {
    return this.isPasswordVisible ? 'text' : 'password';
  }

  get passwordVisibilityIcon(): NateaIconsName {
    return this.isPasswordVisible ? 'no-visibility' : 'visibility';
  }

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private router: Router
  ) {}

  login = (): void => {
    if (this.loginForm.value.password == 'Natea') {
      if (this.loginForm.value.userName == 'tutor') {
        this.tutorLogin();
      }

      if (this.loginForm.value.userName == 'strutturato') {
        this.structuredLogin();
      }

      if (this.loginForm.value.userName == 'nonstrutturato') {
        this.notStructuredLogin();
      }

      if (this.loginForm.value.userName == 'infermiere') {
        this.nurseLogin();
      }
    } else {
      if (this.loginForm.value.userName && this.loginForm.value.password) {
        const data: LoginData = {
          username: this.loginForm.value.userName,
          password: this.loginForm.value.password,
        };
        this.store.dispatch(loginData({ data }));
      }
    }
  };

  togglePasswordVisibility = (): void => {
    this.isPasswordVisible = !this.isPasswordVisible;
  };

  tutorLogin = () => {
    const user: User = {
      id: 'user1',
      firstName: 'Michele',
      lastName: 'Rossi',
      role: UserRole.Doctor,
    };

    this.store.dispatch(loginDemoAsTutorDoctor({ user }));
    this.router.navigateByUrl(AppRoutes.departmentsRoot);
  };

  structuredLogin = () => {
    const user: User = {
      id: 'user2',
      firstName: 'Marizio',
      lastName: 'Verdi',
      role: UserRole.Doctor,
    };
    this.store.dispatch(loginDemoAsStructuredDoctor({ user }));
    this.router.navigateByUrl(AppRoutes.departmentsRoot);
  };

  notStructuredLogin = () => {
    const user: User = {
      id: 'user3',
      firstName: 'Marta',
      lastName: 'Bianchi',
      role: UserRole.Doctor,
    };
    this.store.dispatch(loginDemoAsNotStructuredDoctor({ user }));
    this.router.navigateByUrl(AppRoutes.departmentsRoot);
  };

  nurseLogin = () => {
    const user: User = {
      id: 'user3',
      firstName: 'Marta',
      lastName: 'Bianchi',
      role: UserRole.DoctorStudent,
    };
    this.store.dispatch(loginDemoAsNurse({ user }));
    this.router.navigateByUrl(AppRoutes.departmentsRoot);
  };
}
