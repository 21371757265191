import { Component, Input } from '@angular/core';
import { TextsAndOptionsIcons } from '../../models/legend-content';

@Component({
  selector: 'natea-cc-text-and-optional-icon',
  templateUrl: './text-and-optional-icon.component.html',
  styleUrls: ['./text-and-optional-icon.component.scss'],
})
export class TextAndOptionalIconComponent {
  @Input() textsAndOptionalIcons: TextsAndOptionsIcons[] = [];
  defaultColor = '#038095';
}
