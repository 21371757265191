import { createAction, props } from '@ngrx/store';
import { TherapyOccurrenceState } from '../../../../therapies/common/entities/therapy-occurrence';

export enum PharmacologicalTherapiesAdministrationAction {
  FilterTherapiesState = '[Pharmacological Therapies Administration] Filter Therapies State',
  FilterTherapiesDate = '[Pharmacological Therapies Administration] Filter Therapies Date',
  CheckFilterTherapiesDate = '[Pharmacological Therapies Administration] Check Filter Therapies Date',
}

export const filterTherapiesAdministrationState = createAction(
  PharmacologicalTherapiesAdministrationAction.FilterTherapiesState,

  props<{
    therapyOccurrenceState?: TherapyOccurrenceState;
  }>()
);

export const filterTherapiesDate = createAction(
  PharmacologicalTherapiesAdministrationAction.FilterTherapiesDate,

  props<{ date?: Date }>()
);

export const checkFilterTherapiesDate = createAction(
  PharmacologicalTherapiesAdministrationAction.CheckFilterTherapiesDate
);
