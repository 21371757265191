import { OptionsItem } from 'natea-components';
import { Pagination } from '../entities/pagination';
import { Patient } from '../entities/patient';
import { User } from '../entities/user';
import { Person } from '../entities/person';
import { DomainItem } from '../entities/domain-item';

export const millisInAMinute: number = 60 * 1000;

export const hasMorePages = (pagination: Pagination): boolean => {
  return pagination.pageNumber < pagination.totalPageNumber;
};

export const optionItemFromUser = (user: User): OptionsItem<User> => {
  return {
    id: user.id,
    label: `${user.firstName} ${user.lastName}`,
    data: user,
  };
};

export const optionItemFromPerson = (person: Person): OptionsItem<Person> => {
  return {
    id: person.id,
    label: `${person.firstName} ${person.lastName}`,
    data: person,
  };
};

export const optionItemFromPatient = (
  patient: Patient
): OptionsItem<Patient> => {
  return {
    id: patient.id,
    label: `${patient.firstName} ${patient.lastName}`,
    data: patient,
  };
};

/** Compare the first and the second date and if one of them is null replace it with the nullValue.
 * The null value is used to force the position of null items in a position (start or end) of the array.
 * */
export const compareDates = (
  a?: Date,
  b?: Date,
  nullValue: Date = new Date(0)
): number => {
  return (
    (a?.getTime() ?? nullValue.getTime()) -
    (b?.getTime() ?? nullValue.getTime())
  );
};

export const groupBy = function <T>(
  list: T[],
  groupKeyFn: (item: T) => string
): {
  [key: string]: T[];
} {
  return list.reduce(function (resultMap: { [key: string]: T[] }, x: T) {
    const key: string = groupKeyFn(x);
    if (!resultMap[key]) {
      resultMap[key] = [];
    }
    resultMap[key].push(x);
    return resultMap;
  }, {});
};

export const formatPersonName = (person: Person | User): string => {
  return `${person.firstName} ${person.lastName}`;
};

export const randomId = (): string => {
  return Math.floor(Math.random() * 100000).toString();
};

export const randomBool = (): boolean => {
  return Math.random() < 0.5;
};

export const emptyFunction = () => {
  // NOOP
};

export const optionItemFromDomainItem = (
  item: DomainItem
): OptionsItem<DomainItem> => {
  return {
    id: item.key,
    label: item.label,
    data: item,
  };
};

export const compareBooleans = (
  a: boolean | undefined,
  b: boolean | undefined
): number => {
  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a === undefined) {
    return 1;
  }
  if (b === undefined) {
    return -1;
  }
  return a === b ? 0 : a ? 1 : -1;
};
