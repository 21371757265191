import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { EncounterApiService } from '../../../core/generated/api';
import { localError } from '../../../shared/entities/errors';
import { Patient } from '../../../shared/entities/patient';
import { PatientAdapters } from './patient.adapters';

@Injectable({
  providedIn: 'root',
})
export class PatientsWebApi {
  constructor(private encounterService: EncounterApiService) {}
  public exportPatientsList(departmentId: string): Observable<void> {
    return this.encounterService
      .exportEncounters(departmentId, undefined, undefined, {
        httpHeaderAccept: 'text/csv',
      })
      .pipe(
        map((result: string) => {
          // TODO download file
          console.log('result', result);
        })
      );
  }

  public getPatientsList(departmentsIds: string[]): Observable<Patient[]> {
    return this.encounterService.getEncounters(departmentsIds).pipe(
      map((result) => {
        console.log('result', result);
        const { payload } = result;
        if (!payload) throw localError(HttpStatusCode.BadRequest);

        return payload.map((encounter) =>
          PatientAdapters.patientFromEncounterResource(encounter)
        );
      })
    );
  }
}
