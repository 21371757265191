import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'natea-cc-more-items-pill',
  templateUrl: './more-items-pill.component.html',
  styleUrls: ['./more-items-pill.component.scss'],
})
export class MoreItemsPillComponent {
  @Input() visible?: boolean;
  @Input() label?: string;

  @HostBinding('class') get hostClass(): string {
    return this.visible ? 'visible' : '';
  }
}
