<mat-form-field
  [class.fill-width]="fillParentWidth"
  [class.auto-width]="autoWidth"
  [class.no-hint]="hintLabel === undefined && errorMessages === undefined"
  [class.warn]="errorMessages !== undefined"
>
  <mat-label
    *ngIf="label"
    [class.disabled]="isDisabled"
    [class.input-filled]="isAddClass"
    >{{ label }}</mat-label
  >
  <div class="wrapper-input-search">
    <input
      #valueContainer
      matInput
      type="text"
      [placeholder]="placeHolder"
      [required]="isRequired"
      [matAutocomplete]="auto"
      [disabled]="isDisabled"
      [value]="_selectedOption?.label"
      (input)="onInput($event)"
      (focus)="onFocus()"
      (focusout)="focusOut($event)"
    />
  </div>

  <mat-autocomplete
    ngDefaultControl
    #autoComplete
    #auto="matAutocomplete"
    [requireSelection]="true"
    [autoActiveFirstOption]="autoActiveFirstOption"
    [panelWidth]="panelWidth"
    [displayWith]="displayWith"
    (closed)="removeEventListenerAfterClosed()"
    (opened)="registerPanelOpen()"
    (optionActivated)="optionActivated.emit($event)"
    (optionSelected)="onOptionSelected($event)"
    (optionsScroll)="onScroll($event)"
  >
    <mat-option *ngFor="let option of renderedOptions" [value]="option">
      <span *ngIf="option.iconName">
        <natea-icons
          class="icon"
          [name]="option.iconName"
          [title]="option.iconTitle"
          [fill]="option.fill"
          [iconClass]="option.iconClass"
          [height]="option.height"
          [width]="option.width"
        ></natea-icons>
      </span>
      <span
        [innerHTML]="option.label | highlightLetterMatched : toHighlight"
      ></span>
    </mat-option>
    <mat-option *ngIf="isLoadingMore" [disabled]="true">
      <natea-loader
        class="loader"
        [diameter]="30"
        [strokeWidth]="3"
      ></natea-loader>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
