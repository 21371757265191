import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LocaleProviderService } from '../services/locale-provider.service';

export enum LocalizedDateFormat {
  Date = 'date',
  DateTime = 'date-time',
  WeekdayDateMonth = 'weekday-date-month',
  Weekday = 'weekday',
  DateMonth = 'date-month',
}

@Pipe({
  name: 'localizedDate',
})
export class LocalizedDatePipe implements PipeTransform {
  private currentLocale = '';

  constructor(
    private datePipe: DatePipe,
    private localeProviderService: LocaleProviderService
  ) {
    this.localeProviderService.locale.subscribe((locale: string) => {
      this.currentLocale = locale;
    });
  }

  transform(
    date: Date,
    format: LocalizedDateFormat = LocalizedDateFormat.DateTime
  ): string | null {
    const formatString: string = this.getFormatString(format);
    return this.datePipe.transform(
      date,
      formatString,
      undefined,
      this.currentLocale
    );
  }

  getFormatString(format: LocalizedDateFormat): string {
    try {
      switch (format) {
        case LocalizedDateFormat.Date:
          return this.currentLocale === 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
        case LocalizedDateFormat.DateTime:
          return this.currentLocale === 'en-US'
            ? 'MM/dd/yyyy HH:mm'
            : 'dd/MM/yyyy HH:mm';
        case LocalizedDateFormat.WeekdayDateMonth:
          return this.currentLocale === 'en-US' ? 'EEE, MMM d' : 'EEE d MMM';
        case LocalizedDateFormat.Weekday:
          return 'EEE';
        case LocalizedDateFormat.DateMonth:
          return this.currentLocale === 'en-US' ? 'MMM d' : 'd MMM';
      }
    } catch (error: unknown) {
      if (
        error instanceof Error &&
        error.message.includes('Missing locale data for the locale')
      ) {
        console.warn(
          `Locale ${this.currentLocale} not found, using default locale`
        );
        return 'dd/MM/yyyy';
      }
      console.error(`An unexpected error occurred: ${error}`);
      return 'Error';
    }
  }
}
