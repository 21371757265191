import { EntityEventType, EntityLog } from '../../../entities/entity-log';

export const mockEntityLog: EntityLog = {
  events: [
    {
      date: new Date('2024-02-10T00:00:00.000Z'),
      type: EntityEventType.Create,
      fields: [
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value2', changed: false },
        { key: 'Field 3', value: 'value3', changed: false },
      ],
    },
    {
      date: new Date('2024-02-02T00:10:20.000Z'),
      type: EntityEventType.Update,
      fields: [
        { key: 'Field 1', value: 'value1', changed: false },
        { key: 'Field 2', value: 'value5', changed: true },
        { key: 'Field 3', value: 'value3', changed: false },
      ],
    },
    {
      date: new Date('2024-02-04T00:22:43.100Z'),
      type: EntityEventType.Update,
      fields: [
        {
          key: 'Field 1',
          value:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non massa felis. Integer elit sapien, pulvinar sit amet risus in, ultrices tempor mauris.',
          changed: true,
        },
        {
          key: 'Field 2',
          value:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non massa felis. Integer elit sapien, pulvinar sit amet risus in, ultrices tempor mauris. Duis tristique lacus quam. Etiam cursus eros quam, vitae convallis magna posuere vel. Aliquam hendrerit, sapien posuere egestas sodales, elit nunc efficitur nulla, finibus tristique nisl mauris eu leo. Nam elit dolor, dictum at interdum sit amet, hendrerit et turpis. Phasellus porta convallis arcu, at imperdiet tortor consequat et. Aliquam ac consequat arcu. Maecenas consequat, nisi nec ultrices gravida, velit mi viverra lectus, id bibendum elit est eget mi.Maecenas a lectus ut sem eleifend facilisis. Vivamus sagittis neque vitae porttitor vehicula. In quis eros vitae lectus tempus varius rhoncus sit amet mauris. Nam nec ipsum nec leo venenatis pharetra. Mauris eu lorem ornare felis imperdiet pellentesque. Sed interdum elit nec ultrices fermentum. Suspendisse nec iaculis metus, in tincidunt risus. Cras ullamcorper feugiat metus, eu viverra justo elementum sit amet. Integer vel urna et augue egestas fermentum. Sed tincidunt magna turpis, eu porttitor neque euismod id. Aenean tincidunt rhoncus interdum. Etiam sagittis ipsum vel elit consequat venenatis. Ut condimentum porta metus at porta. Nullam eu felis elementum eros vestibulum hendrerit nec blandit nulla Proin nunc libero, placerat vel efficitur nec, ultrices bibendum lorem. In a sem turpis. Morbi id suscipit magna, a efficitur turpis. Sed est dolor, tincidunt quis dapibus',
          changed: false,
        },
        { key: 'Field 3', value: 'value34', changed: true },
      ],
    },
  ],
};
