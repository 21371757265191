import { UserPermissions } from '../store/users.reducers';

export const tutorDoctorPermissions: UserPermissions = {
  areNotesAutoValidated: true,
  canCreateNotes: true,
  canValidateNotes: true,
  canConfirmTherapies: true,
};

export const structuredDoctorPermissions: UserPermissions = {
  areNotesAutoValidated: true,
  canCreateNotes: true,
  canValidateNotes: false,
  canConfirmTherapies: true,
};

export const notStructuredDoctorPermissions: UserPermissions = {
  areNotesAutoValidated: false,
  canCreateNotes: true,
  canValidateNotes: false,
  canConfirmTherapies: true,
};

export const nursePermissions: UserPermissions = {
  areNotesAutoValidated: false,
  canCreateNotes: true,
  canValidateNotes: false,
  canConfirmTherapies: false,
};
