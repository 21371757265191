import { Component, Input } from '@angular/core';
import { Patient } from '../../../entities/patient';
import { LocalizedDateFormat } from '../../../pipes/localized-date.pipe';
import { getDateDifference } from '../../../utils/date-utils';

@Component({
  selector: 'natea-cc-sub-header-patient-data-item',
  templateUrl: './sub-header-patient-data-item.component.html',
  styleUrls: ['./sub-header-patient-data-item.component.scss'],
})
export class SubHeaderPatientDataItemComponent {
  LocalizedDateFormat = LocalizedDateFormat;

  @Input() patient?: Patient;

  formattedAge(): string {
    if (!this.patient) {
      return '';
    }

    //TODO: remove this when we have a real patient

    if (this.patient.firstName === 'Mario') {
      return `0 0 20`;
    }

    const birthDate = this.patient.birthDate;
    const now = new Date();

    const { years, months, days } = getDateDifference(birthDate, now);
    return `${years} ${months} ${days}`;
  }
}
