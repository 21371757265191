import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService } from 'natea-components';
import { catchError, delay, exhaustMap, map, of } from 'rxjs';
import {
  ApiError,
  errorMessage,
  localError,
} from '../../../../shared/entities/errors';
import { SNACK_BAR_DEBOUNCE_TIME_MS } from '../../../../shared/utils/constants';
import { PatientAndEncounterIds } from '../../../patients/store/patients.reducers';
import { selectOngoingEncounterAndPatientIds } from '../../../patients/store/patients.selectors';
import { PharmacologicalTherapyCancelSuspend } from '../entities/pharmacological-therapy-cancel-suspend';
import { PharmacologicalTherapyFormData } from '../form/pharmacological-therapy-form-data';
import { pharmacologicalTherapiesListMock } from '../mocks/pharmacological-therapy.mocks';
import { PharmacologicalTherapiesWebApi } from '../webapis/pharmacological-therapies.webapi';
import {
  PharmacologicalTherapiesActions,
  confirmCancelPharmacologicalTherapyFailure,
  confirmCancelPharmacologicalTherapySuccess,
  confirmDeletePharmacologicalTherapyFailure,
  confirmDeletePharmacologicalTherapySuccess,
  createPharmacologicalTherapyFailure,
  createPharmacologicalTherapySuccess,
  editPharmacologicalTherapyFailure,
  editPharmacologicalTherapySuccess,
  loadPharmacologicalTherapiesFailure,
  loadPharmacologicalTherapiesSuccess,
  pharmacologicalTherapySuspensionFailure,
  pharmacologicalTherapySuspensionSuccess,
} from './pharmacological-therapies.actions';
import { PharmacologicalTherapiesCatalogs } from './pharmacological-therapies.reducer';
import {
  selectPharmacologicalCancelTherapyAndEncounter,
  selectPharmacologicalDeleteTherapyAndEncounter,
  selectPharmacologicalTherapyCandidateForSuspension,
} from './pharmacological-therapies.selectors';
import { PharmacologicalTherapy } from '../entities/pharmacological-therapy';

@Injectable()
export class PharmacologicalTherapiesEffects {
  loadPharmacologicalTherapies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PharmacologicalTherapiesActions.LoadPharmacologicalTherapies),
      concatLatestFrom(() => {
        return this.store.select(selectOngoingEncounterAndPatientIds);
      }),
      exhaustMap(([, onGoingEncounterIds]) =>
        onGoingEncounterIds
          ? of(
              loadPharmacologicalTherapiesSuccess({
                patientId: onGoingEncounterIds?.patientId,
                encounterId: onGoingEncounterIds?.encounterId,
                therapies: pharmacologicalTherapiesListMock(), // TODO real data
              })
            ).pipe(delay(100))
          : of(
              loadPharmacologicalTherapiesFailure({
                error: localError(HttpStatusCode.BadRequest),
              })
            )
      ),
      catchError((error: ApiError) =>
        of({
          type: PharmacologicalTherapiesActions.LoadPharmacologicalTherapiesFailure,
          error,
        })
      )
    );
  });

  loadPharmacologicalTherapiesFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          PharmacologicalTherapiesActions.LoadPharmacologicalTherapiesFailure
        ),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.loadTherapiesFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  downloadCatalogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PharmacologicalTherapiesActions.DownloadCatalogs),
      exhaustMap(() => {
        return this.api.downloadCatalogs().pipe(
          map((catalogs: PharmacologicalTherapiesCatalogs) => {
            return {
              type: PharmacologicalTherapiesActions.DownloadCatalogsSuccess,
              catalogs,
            };
          }),
          catchError((error: ApiError) =>
            of({
              type: PharmacologicalTherapiesActions.DownloadCatalogsFailure,
              error,
            })
          )
        );
      }),
      catchError((error: ApiError) =>
        of({
          type: PharmacologicalTherapiesActions.DownloadCatalogsFailure,
          error,
        })
      )
    );
  });

  deletePrescriptionPharmacologicalTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        PharmacologicalTherapiesActions.ConfirmDeletePharmacologicalTherapyModal
      ),
      concatLatestFrom(() => {
        return this.store.select(
          selectPharmacologicalDeleteTherapyAndEncounter
        );
      }),
      exhaustMap(([, therapyToDeleteData]) => {
        return this.api
          .deletePharmacologicalTherapyPrescription(
            therapyToDeleteData.therapyId
          )
          .pipe(
            map((isDeleted: boolean) => {
              if (isDeleted) {
                return confirmDeletePharmacologicalTherapySuccess({
                  therapyId: therapyToDeleteData.therapyId,
                  encounterId: therapyToDeleteData.encounterId ?? '',
                  patientId: therapyToDeleteData.patientId ?? '',
                });
              } else {
                return confirmDeletePharmacologicalTherapyFailure({
                  error: localError(HttpStatusCode.BadRequest),
                  therapyId: therapyToDeleteData.therapyId,
                });
              }
            })
          );
      }),
      catchError((error: ApiError) =>
        of({
          type: PharmacologicalTherapiesActions.ConfirmDeletePharmacologicalTherapyFailure,
          error,
        })
      )
    );
  });

  cancelPrescriptionPharmacologicalTherapy$ = createEffect(() => {
    let selectedTherapy: PharmacologicalTherapyCancelSuspend | undefined;
    return this.actions$.pipe(
      ofType(
        PharmacologicalTherapiesActions.ConfirmCancelPharmacologicalTherapyModal
      ),
      concatLatestFrom(() =>
        this.store.select(selectPharmacologicalCancelTherapyAndEncounter)
      ),
      exhaustMap(
        ([{ reason }, { therapy: therapyCancelData, encounter }]: [
          { reason: string },
          {
            therapy: PharmacologicalTherapyCancelSuspend | undefined;
            encounter: PatientAndEncounterIds | undefined;
          }
        ]) => {
          selectedTherapy = therapyCancelData;
          return this.api
            .cancelPharmacologicalTherapyPrescription(therapyCancelData, reason)
            .pipe(
              map((therapy: PharmacologicalTherapy) => {
                return confirmCancelPharmacologicalTherapySuccess({
                  therapy,
                  encounterId: encounter?.encounterId ?? '',
                  patientId: encounter?.patientId ?? '',
                });
              }),
              catchError((error: ApiError) =>
                of(
                  confirmCancelPharmacologicalTherapyFailure({
                    error,
                    therapy: therapyCancelData,
                  })
                )
              )
            );
        }
      ),
      catchError((error: ApiError) =>
        of(
          confirmCancelPharmacologicalTherapyFailure({
            error,
            therapy: selectedTherapy,
          })
        )
      )
    );
  });

  cancelTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          PharmacologicalTherapiesActions.ConfirmCancelPharmacologicalTherapySuccess
        ),
        map(
          ({
            therapy,
          }: {
            therapy: PharmacologicalTherapyCancelSuspend | undefined;
          }) =>
            this.snackBar.showSnackbar(
              this.translocoService.translate(
                therapy?.isSuspend
                  ? 'therapies.notifications.cancelTherapySuspendSuccess'
                  : 'therapies.notifications.cancelTherapyCancelSuccess'
              ),
              this.translocoService.translate('common.buttons.close'),
              'success-snackbar',
              SNACK_BAR_DEBOUNCE_TIME_MS
            )
        )
      );
    },
    { dispatch: false }
  );

  cancelTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          PharmacologicalTherapiesActions.ConfirmCancelPharmacologicalTherapyFailure
        ),
        map(
          ({
            error,
            therapy,
          }: {
            error: ApiError | undefined;
            therapy: PharmacologicalTherapyCancelSuspend | undefined;
          }) => {
            console.error(
              'Error in canceling therapy: ',
              error,
              'therapy: ',
              therapy?.id,
              'occurrence: ',
              therapy?.occurrenceId,
              'isSuspend: ',
              therapy?.isSuspend
            );

            const message = error ? errorMessage(error) : undefined;
            return this.snackBar.showSnackbar(
              this.translocoService.translate(
                message ?? therapy?.isSuspend
                  ? 'therapies.notifications.cancelTherapySuspendFailure'
                  : 'therapies.notifications.cancelTherapyCancelFailure'
              ),
              this.translocoService.translate('common.buttons.close'),
              'error-snackbar',
              SNACK_BAR_DEBOUNCE_TIME_MS
            );
          }
        )
      );
    },
    { dispatch: false }
  );

  /************************ CREATE THERAPY ************************/

  createTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PharmacologicalTherapiesActions.CreatePharmacologicalTherapy),
      concatLatestFrom(() => {
        return this.store.select(selectOngoingEncounterAndPatientIds);
      }),
      exhaustMap(([{ therapy }, onGoingEncounter]) => {
        return this.api.createPharmacologicalTherapy(therapy).pipe(
          map((therapyResult) => {
            if (!onGoingEncounter) {
              return createPharmacologicalTherapyFailure({
                error: localError(HttpStatusCode.BadRequest),
              });
            }

            return createPharmacologicalTherapySuccess({
              therapy: therapyResult,
              patientId: onGoingEncounter.patientId,
              encounterId: onGoingEncounter.encounterId,
            });
          }),
          catchError((error: ApiError) => {
            return of(createPharmacologicalTherapyFailure({ error }));
          })
        );
      }),
      catchError((error: ApiError) => {
        return of(createPharmacologicalTherapyFailure({ error }));
      })
    );
  });

  createTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          PharmacologicalTherapiesActions.CreatePharmacologicalTherapySuccess
        ),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.createTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  createTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          PharmacologicalTherapiesActions.CreatePharmacologicalTherapyFailure
        ),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.createTherapyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /************************ EDIT THERAPY ************************/

  editTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: PharmacologicalTherapiesActions.EditPharmacologicalTherapy;
        therapyId: string;
        therapy: PharmacologicalTherapyFormData;
      }>(PharmacologicalTherapiesActions.EditPharmacologicalTherapy),
      concatLatestFrom(() => {
        return this.store.select(selectOngoingEncounterAndPatientIds);
      }),
      exhaustMap(([{ therapy, therapyId }, onGoingEncounter]) => {
        return this.api.editPharmacologicalTherapy(therapy, therapyId).pipe(
          map((therapyResult) => {
            if (!onGoingEncounter) {
              return loadPharmacologicalTherapiesFailure({
                error: localError(HttpStatusCode.BadRequest),
              });
            }

            return editPharmacologicalTherapySuccess({
              therapy: therapyResult,
              patientId: onGoingEncounter.patientId,
              encounterId: onGoingEncounter.encounterId,
            });
          }),
          catchError((error: ApiError) => {
            return of(editPharmacologicalTherapyFailure({ error }));
          })
        );
      }),
      catchError((error: ApiError) => {
        return of(editPharmacologicalTherapyFailure({ error }));
      })
    );
  });

  editTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          PharmacologicalTherapiesActions.EditPharmacologicalTherapySuccess
        ),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.editTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  editTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          PharmacologicalTherapiesActions.EditPharmacologicalTherapyFailure
        ),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.editTherapyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /************************ DELETE THERAPY ************************/

  deleteTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          PharmacologicalTherapiesActions.ConfirmDeletePharmacologicalTherapySuccess
        ),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.deleteTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  deletePrescriptionPharmacologicalTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          PharmacologicalTherapiesActions.ConfirmDeletePharmacologicalTherapyFailure
        ),
        map(
          ({
            error,
            therapyId,
          }: {
            error: ApiError | undefined;
            therapyId: string | undefined;
          }) => {
            console.error(
              'Error in deleting therapy: ',
              error,
              'therapy: ',
              therapyId
            );

            const message = error ? errorMessage(error) : undefined;
            return this.snackBar.showSnackbar(
              message ??
                this.translocoService.translate(
                  'therapies.notifications.deleteTherapyFailure'
                ),
              this.translocoService.translate('common.buttons.close'),
              'error-snackbar',
              SNACK_BAR_DEBOUNCE_TIME_MS
            );
          }
        )
      );
    },
    { dispatch: false }
  );

  /************************ SUSPEND THERAPY ************************/

  suspendTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: PharmacologicalTherapiesActions.ConfirmSuspension;
        motivation: string;
      }>(PharmacologicalTherapiesActions.ConfirmSuspension),
      concatLatestFrom(() => [
        this.store.select(selectOngoingEncounterAndPatientIds),
        this.store.select(selectPharmacologicalTherapyCandidateForSuspension),
      ]),
      exhaustMap(([{ motivation }, onGoingEncounter, therapy]) => {
        if (!therapy || !onGoingEncounter) {
          return of(
            pharmacologicalTherapySuspensionFailure({
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }
        return this.api
          .suspendPharmacologicalTherapyPrescription(therapy?.id, motivation)
          .pipe(
            map((therapyResult) => {
              return pharmacologicalTherapySuspensionSuccess({
                therapy: therapyResult,
                patientId: onGoingEncounter.patientId,
                encounterId: onGoingEncounter.encounterId,
              });
            }),
            catchError((error: ApiError) => {
              return of(pharmacologicalTherapySuspensionFailure({ error }));
            })
          );
      }),
      catchError((error: ApiError) => {
        return of(pharmacologicalTherapySuspensionFailure({ error }));
      })
    );
  });

  suspendTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PharmacologicalTherapiesActions.SuspensionSuccess),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.suspendTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  suspendTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PharmacologicalTherapiesActions.SuspensionFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.suspendTherapyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );
  /************************ CONSTRUCTOR ************************/

  constructor(
    private actions$: Actions,
    private store: Store,
    private snackBar: SnackbarService,
    private translocoService: TranslocoService,
    private api: PharmacologicalTherapiesWebApi
  ) {}
}
