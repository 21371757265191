/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a domain resource.
 */
export interface DomainResource { 
    /**
     * Indicates whether the domain resource is active or inactive.
     */
    active: boolean;
    /**
     * Key associated with the domain resource.
     */
    domainKey: string;
    /**
     * Value associated with the domain resource.
     */
    domainValue: string;
    /**
     * Unique identifier for the domain resource.
     */
    id: number;
}

