export class AppRoutes {
  private static separator = '/';

  static Parameters = {
    departmentId: 'departmentId',
    patientId: 'patientId',
  };

  static Paths = {
    Root: '',
    Departments: 'departments',
    Patients: 'patients',
    Loading: 'loading',
    Problems: 'problems',
    ClinicalNotes: 'clinical-notes',
    NotesValidation: 'validation',
    NurseRecords: 'nurse-records',
    NursingGantt: 'gantt',
    Procedures: 'procedures',
    Therapies: 'therapies',
    TherapiesAdministration: 'therapies-administration',
    TherapeuticalConfirmation: 'therapeutical-confirmation',
    PharmacologicalTherapies: 'pharmacological',
    NutritionalTherapies: 'nutritional',
    InstrumentalTherapies: 'instrumental',
    TransfusionalTherapies: 'transfusional',
    Anamnesis: 'anamnesis',
    FamilyAnamnesis: 'family-anamnesis',
    NewBorn: 'new-born',
    Pregnancy: 'pregnancy',
  };

  static departmentsRoot = [this.Paths.Root, this.Paths.Departments].join(
    this.separator
  );

  static departmentsLoadingPage = [
    this.departmentsRoot,
    this.Paths.Loading,
  ].join(this.separator);

  static departmentBasePath = (departmentId: string): string =>
    [this.departmentsRoot, departmentId].join(this.separator);

  static patientsLoadingPage = (departmentId: string): string =>
    [this.departmentBasePath(departmentId), this.Paths.Loading].join(
      this.separator
    );

  static patientsList = (departmentId: string): string =>
    [this.departmentBasePath(departmentId), this.Paths.Patients].join(
      this.separator
    );

  static patientBasePath = (departmentId: string, patientId: string): string =>
    [this.patientsList(departmentId), patientId].join(this.separator);

  static patientDashboard = (departmentId: string, patientId: string): string =>
    [this.patientBasePath(departmentId, patientId), this.Paths.Problems].join(
      this.separator
    );

  static patientDataLoadingPage = (
    departmentId: string,
    patientId: string
  ): string =>
    [this.patientBasePath(departmentId, patientId), this.Paths.Loading].join(
      this.separator
    );
}
