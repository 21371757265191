import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService } from 'natea-components';
import { catchError, delay, exhaustMap, map, of, switchMap } from 'rxjs';
import {
  ApiError,
  errorMessage,
  localError,
} from '../../../shared/entities/errors';
import {
  NO_ACTION,
  SNACK_BAR_DEBOUNCE_TIME_MS,
} from '../../../shared/utils/constants';
import { PatientAndEncounterIds } from '../../patients/store/patients.reducers';
import { selectOngoingEncounterAndPatientIds } from '../../patients/store/patients.selectors';
import {
  ProcedureMovementFormData,
  ProceduresExecutionFormData,
  ProceduresProgrammingFormData,
  ProceduresTerminationFormData,
} from '../components/procedures-detail-form/form-data/procedures-detail-form-data';
import {
  ProcedureDevice,
  ProcedureLocation,
  ProcedureRoute,
} from '../entities/procedure-data';
import { procedureOccurrencesListMock } from '../mocks/procedures-list.mock';
import ProceduresWebApi from '../webapi/procedures-web-api';
import {
  ProceduresActions,
  cancelProcedureExecutionSuccess,
  clearProcedureExecutionTemporaryFormData,
  clearProcedureProgrammingTemporaryFormData,
  clearProcedureTerminationTemporaryFormData,
  createMovementFailure,
  createMovementSuccess,
  createProcedureFailure,
  createProcedureSuccess,
  deleteMovement,
  deleteMovementFailure,
  deleteMovementSuccess,
  deleteProcedureSuccess,
  loadProcedureDevicesFailure,
  loadProcedureDevicesSuccess,
  loadProcedureLocationsFailure,
  loadProcedureLocationsSuccess,
  loadProcedureRoutesSuccess,
  saveProcedureExecutionStepSuccess,
  saveProcedureProgrammingStepSuccess,
  saveProcedureTerminationStepSuccess,
  stopProcedureRunSuccess,
  updateMovementFailure,
  updateMovementSuccess,
} from './procedures.actions';
import { selectSelectedMovementForDeletionIds } from './procedures.selectors';

@Injectable()
export class ProceduresEffects {
  loadProcedures$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProceduresActions.LoadProcedures),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      exhaustMap(([, onGoingEncounterIds]) =>
        of({
          type: ProceduresActions.LoadProceduresSuccess,
          patientId: onGoingEncounterIds?.patientId,
          encounterId: onGoingEncounterIds?.encounterId,
          data: procedureOccurrencesListMock(), // TODO real data
        }).pipe(delay(100))
      ),
      catchError((error: ApiError) =>
        of({
          type: ProceduresActions.LoadProceduresFailure,
          error,
        })
      )
    );
  });

  loadProceduresFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProceduresActions.LoadProceduresFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'procedures.notifications.loadProceduresFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /************************ CREATE PROCEDURE ************************/

  createProcedure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: ProceduresActions.CreateProcedure;
        data: ProceduresProgrammingFormData;
      }>(ProceduresActions.CreateProcedure),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      exhaustMap(([action, onGoingEncounterIds]) => {
        const { data } = action;
        if (!onGoingEncounterIds) {
          // It shouldn't happen
          return of(
            createProcedureFailure({
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }

        const { patientId, encounterId } = onGoingEncounterIds;

        return this.api
          .createProcedureOccurrence({
            patientId,
            encounterId,
            formData: data,
          })
          .pipe(
            map((newProcedure) => {
              return createProcedureSuccess({
                patientId: onGoingEncounterIds.patientId,
                encounterId: onGoingEncounterIds.encounterId,
                procedure: newProcedure,
              });
            })
          );
      }),
      catchError((error) => {
        return of(createProcedureFailure(error));
      })
    );
  });

  createProcedureSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProceduresActions.CreateProcedureSuccess),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'procedures.notifications.createProcedureSuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  createProcedureFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProceduresActions.CreateProcedureFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'procedures.notifications.createProcedureFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /************************ CREATE MOVEMENT ************************/

  createMovement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: ProceduresActions.CreateMovement;
        occurrenceId: string;
        movementData: ProcedureMovementFormData;
      }>(ProceduresActions.CreateMovement),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      exhaustMap(([action, onGoingEncounterIds]) => {
        const { movementData, occurrenceId } = action;

        if (!onGoingEncounterIds) {
          // It shouldn't happen
          return of(
            createMovementFailure({
              occurrenceId,
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }

        /* Only for special cases where there is the need to pass some action params
         * to the failure action
         */
        try {
          return this.api
            .createProcedureMovement(occurrenceId, movementData)
            .pipe(
              map((newMovement) => {
                return createMovementSuccess({
                  occurrenceId,
                  patientId: onGoingEncounterIds.patientId,
                  encounterId: onGoingEncounterIds.encounterId,
                  movement: newMovement,
                });
              })
            );
        } catch (error) {
          return of(
            createMovementFailure({
              occurrenceId,
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }
      })
    );
  });

  createMovementSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProceduresActions.CreateMovementSuccess),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'procedures.notifications.createMovementSuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  createMovementFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProceduresActions.CreateMovementFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'procedures.notifications.createMovementFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /************************ UPDATE MOVEMENT ************************/

  updateMovement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: ProceduresActions.UpdateMovement;
        movementId: string;
        occurrenceId: string;
        movementData: ProcedureMovementFormData;
      }>(ProceduresActions.UpdateMovement),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      exhaustMap(([action, onGoingEncounterIds]) => {
        const { occurrenceId, movementData, movementId } = action;

        if (!onGoingEncounterIds) {
          return of(
            updateMovementFailure({
              movementId,
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }

        /* Only for special cases where there is the need to pass some action params
         * to the failure action
         */
        try {
          return this.api
            .updateProcedureMovement(movementId, movementData)
            .pipe(
              map((newMovement) => {
                return updateMovementSuccess({
                  occurrenceId,
                  movementId,
                  patientId: onGoingEncounterIds.patientId,
                  encounterId: onGoingEncounterIds.encounterId,
                  movement: newMovement,
                });
              })
            );
        } catch (error) {
          return of(
            updateMovementFailure({
              movementId,
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }
      })
    );
  });

  updateMovementSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProceduresActions.UpdateMovementSuccess),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'procedures.notifications.updateMovementSuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  updateMovementFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProceduresActions.UpdateMovementFailure),
        map((error) => {
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'procedures.notifications.updateMovementFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  /************************ DELETE MOVEMENT ************************/

  deleteMovementConfirmed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProceduresActions.ConfirmMovementDeletion),
      concatLatestFrom(() =>
        this.store.select(selectSelectedMovementForDeletionIds)
      ),
      map(([, movement]) => {
        return movement
          ? deleteMovement({
              movementId: movement.movementId,
              occurrenceId: movement.occurrenceId,
            })
          : { type: NO_ACTION };
      })
    );
  });

  deleteMovement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: ProceduresActions.DeleteMovement;
        movementId: string;
        occurrenceId: string;
      }>(ProceduresActions.DeleteMovement),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      exhaustMap(([action, onGoingEncounterIds]) => {
        const { occurrenceId, movementId } = action;

        if (!onGoingEncounterIds) {
          return of(
            deleteMovementFailure({
              movementId,
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }

        /* Only for special cases where there is the need to pass some action params
         * to the failure action
         */
        try {
          return this.api.deleteProcedureMovement(movementId).pipe(
            map(() => {
              return deleteMovementSuccess({
                occurrenceId,
                movementId,
                patientId: onGoingEncounterIds.patientId,
                encounterId: onGoingEncounterIds.encounterId,
              });
            })
          );
        } catch (error) {
          return of(
            deleteMovementFailure({
              movementId,
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }
      })
    );
  });

  deleteMovementSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProceduresActions.DeleteMovementSuccess),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'procedures.notifications.deleteMovementSuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  deleteMovementFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProceduresActions.DeleteMovementFailure),
        map((error) => {
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'procedures.notifications.deleteMovementFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  /************************ SAVE PROCEDURE STEPS ************************/

  saveProcedureProgramming$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProceduresActions.SaveProcedureProgrammingStep),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      map(
        ([{ procedureId, data }, onGoingEncounterIds]: [
          { procedureId: string; data: ProceduresProgrammingFormData },
          PatientAndEncounterIds | undefined
        ]) => {
          return saveProcedureProgrammingStepSuccess({
            patientId: onGoingEncounterIds?.patientId ?? '',
            encounterId: onGoingEncounterIds?.encounterId ?? '',
            procedureId,
            data,
          });
        }
      )
    );
  });

  saveProcedureExecution$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProceduresActions.SaveProcedureExecutionStep),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      map(
        ([{ procedureId, data }, onGoingEncounterIds]: [
          { procedureId: string; data: ProceduresExecutionFormData },
          PatientAndEncounterIds | undefined
        ]) => {
          return saveProcedureExecutionStepSuccess({
            patientId: onGoingEncounterIds?.patientId ?? '',
            encounterId: onGoingEncounterIds?.encounterId ?? '',
            procedureId,
            data,
          });
        }
      )
    );
  });

  saveProcedureTermination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProceduresActions.SaveProcedureTerminationStep),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      map(
        ([{ procedureId, data }, onGoingEncounterIds]: [
          { procedureId: string; data: ProceduresTerminationFormData },
          PatientAndEncounterIds | undefined
        ]) => {
          return saveProcedureTerminationStepSuccess({
            patientId: onGoingEncounterIds?.patientId ?? '',
            encounterId: onGoingEncounterIds?.encounterId ?? '',
            procedureId,
            data,
          });
        }
      )
    );
  });

  clearTemporaryFormData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProceduresActions.ClearTemporaryFormData),
      switchMap(() =>
        of(
          clearProcedureProgrammingTemporaryFormData(),
          clearProcedureExecutionTemporaryFormData(),
          clearProcedureTerminationTemporaryFormData()
        )
      )
    );
  });

  /************************ CANCEL PROCEDURE STEPS ************************/

  deleteProcedure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProceduresActions.DeleteProcedure),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      map(
        ([{ procedureId }, onGoingEncounterIds]: [
          { procedureId: string },
          PatientAndEncounterIds | undefined
        ]) => {
          return deleteProcedureSuccess({
            patientId: onGoingEncounterIds?.patientId ?? '',
            encounterId: onGoingEncounterIds?.encounterId ?? '',
            procedureId,
          });
        }
      )
    );
  });

  stopProcedureRun$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProceduresActions.StopProcedureRun),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      map(
        ([{ procedureId }, onGoingEncounterIds]: [
          { procedureId: string },
          PatientAndEncounterIds | undefined
        ]) => {
          return stopProcedureRunSuccess({
            patientId: onGoingEncounterIds?.patientId ?? '',
            encounterId: onGoingEncounterIds?.encounterId ?? '',
            procedureId,
          });
        }
      )
    );
  });

  cancelProcedureExecution$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProceduresActions.CancelProcedureExecution),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      map(
        ([{ procedureId }, onGoingEncounterIds]: [
          { procedureId: string },
          PatientAndEncounterIds | undefined
        ]) => {
          return cancelProcedureExecutionSuccess({
            patientId: onGoingEncounterIds?.patientId ?? '',
            encounterId: onGoingEncounterIds?.encounterId ?? '',
            procedureId,
          });
        }
      )
    );
  });

  /************************ AUXILIARY DATA **********************/

  loadDevices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProceduresActions.LoadDevices),
      exhaustMap(() => {
        return this.api.loadDevices().pipe(
          map((devices: ProcedureDevice[]) => {
            return loadProcedureDevicesSuccess({ devices });
          })
        );
      }),
      catchError((error: ApiError) => {
        return of(loadProcedureDevicesFailure({ error }));
      })
    );
  });

  loadLocations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProceduresActions.LoadLocations),
      exhaustMap(() => {
        return this.api.loadLocations().pipe(
          map((locations: ProcedureLocation[]) => {
            return loadProcedureLocationsSuccess({ locations });
          })
        );
      }),
      catchError((error: ApiError) => {
        return of(loadProcedureLocationsFailure({ error }));
      })
    );
  });

  loadRoutes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProceduresActions.LoadRoutes),
      exhaustMap(() => {
        return this.api.loadRoutes().pipe(
          map((routes: ProcedureRoute[]) => {
            return loadProcedureRoutesSuccess({ routes });
          })
        );
      }),
      catchError((error: ApiError) => {
        return of(loadProcedureLocationsFailure({ error }));
      })
    );
  });

  /************************ CONSTRUCTOR ************************/

  constructor(
    private actions$: Actions,
    private store: Store,
    private snackBar: SnackbarService,
    private translocoService: TranslocoService,
    private api: ProceduresWebApi
  ) {}
}
