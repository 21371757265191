/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a movement within the management context of a procedure.
 */
export interface ProcedureManagementMovementResource { 
    /**
     * Additional notes regarding the execution of the movement.
     */
    additionalNotes: string;
    /**
     * Timestamp indicating when the movement was executed.
     */
    executedAt: string;
    /**
     * Indicates whether the patient was treated during the execution of the movement.
     */
    hasPatientBeenTreated: boolean;
    /**
     * Unique identifier of the movement.
     */
    id: string;
    /**
     * Depth in centimeters of the action of the medical device used during the execution of the movement.
     */
    medicalDeviceActionDepthInCm: number;
    /**
     * Unique identifier of the medical device action performed during the execution of the movement.
     */
    medicalDeviceActionId: string;
}

