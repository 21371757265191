import {
  GeneralResponseListStoreItemResource,
  StoreItemResource,
} from '../generated/api';

const getUserStorageItemsResponse =
  (): GeneralResponseListStoreItemResource => {
    return {
      payload: mockedBlackBoardNotes,
    };
  };
export const mockedBlackBoardNotes: StoreItemResource[] = [
  {
    id: '9e6c326b-8eba-4ffb-a0d5-09cb5b653d98',
    item: {
      metadata: {
        description: `
        La sogni da ah verra fiore agita. Sensitive ero andarmene pur ove fanciullo curiosita crescente. Otri ando ama col anno dove sera. Cairo verro chi credo gorgo udi.
        Sei giu cambieremo guardavamo bellissima meraviglia par lei.
        Cicatrice arrestare voi sollevano par frenetico scoprirvi com.
        Sii mio vai gli preferisti cancellato necessario coraggiose.
        Ove perfette somiglia tre sgomento.
        Stanchezza ci si so lineamento lo sopraffare indicibile sorridendo.
        Mokattam ai ritrovar spremere di traversa navigato. Battera fremito spasimo le arteria ex oh.
        Principio desiderio passarono vi se. Improvviso scomparire resistenza in no toglieremo ricordarmi ah ai.
        So spezzare ricevuto raccogli profonde ad
        Stanchezza ci si so lineamento lo sopraffare indicibile sorridendo.
        Mokattam ai ritrovar spremere di traversa navigato. Battera fremito spasimo le arteria ex oh.
        Principio desiderio passarono vi se. Improvviso scomparire resistenza in no toglieremo ricordarmi ah ai.
        So spezzare ricevuto raccogli profonde ad
    `,
      },
    },
    createdAt: '2023-07-23T12:13:33Z',

    itemVersion: '1',
    //userId: '28e50824-fe54-450d-bdfc-23d7a004299c',
  },

  {
    id: '2136e5db-0ec0-49ee-b55b-510f5a51e3b3',
    item: {
      metadata: {
        description: `
      Nervi cigli di farai oblio buone le ti veste. Fanciullo lavorando ha ho melagrani osservava rivederci si strappato da. Punge tardi verra al in passa ed te.
      Comprendi ch po distrutta statuario. Col ascoltami rammarico oltremare ama. Forse sta bel campo andro sapro. Salvata su seconda divieto ritrovi ai.
      Evocare bianche lontane dominio tu bisogna oh. In se napoleone splendori oh rivederla da splendido.
      Cui preme nel debbo dalbo tutto. Alpe temo qui mio acre gli leva alta. Noi pensieroso chi trasognato trapassato tuo volgendosi perfezione uno.
      Cara non fara sai nego reni tipo sete. Oro pare vedo naso far nei sole. Chiedere profonda ben ora gia stridore. Potessero ve apparenze desiderio lo curiosita accendeva.
     Tre amo hai gli polsi pensi sento.
  `,
        entityFrom: 'clinical-notes',
      },
    },
    createdAt: '2023-07-23T12:12:33Z',
    // itemType: 'lavagna',
    itemVersion: '1',
    //userId: '28e50824-fe54-450d-bdfc-23d7a004299c',
  },

  {
    id: 'a6733456-80b1-4175-a372-4e4ceae093da',
    item: {
      metadata: {
        description: `
        Dal tuo ora era ogni mite orti ecco mise. Ricordarmi dormissero fermissimo dei portartela ore hai che. Uno noi the sgomento pensiero continua spiccare alzeremo.
        Nobile carica orrore ed si vedrei sfugge ve ah aprile.
         Copre lunga tutti vaghe lo sento ho detto vi. Natura fu chiusa volevo tu la uomini vi. Chi rimasta pie piu gli inclina conduco indugia altrove destino.
        Tua ali perse mai certo molte andro. Bel limite pel una piombo felice. Orecchio io speranze fu incendio ha lacerato. Mi riflettere declinante vergognoso implorando ch.
       Pei dio dissetato comprendi angeliche guardando ore titubante generando. Me dune moto ozio bene senz dell la. Stelle ero chi che immune vostra. Morivi starne alzati una stacca velavi all membra sii.
      Tenue su lieve vivra scena umana da or fatta.
    `,
        entityFrom: 'problems',
      },
    },
    createdAt: '2023-07-23T12:11:33Z',
    //itemType: 'lavagna',
    itemVersion: '1',
    // userId: '28e50824-fe54-450d-bdfc-23d7a004299c',
  },

  {
    id: '8ce8ebdf-55ec-4c11-8b85-80aa4cdc999d',
    item: {
      metadata: {
        description: `
        Dal tuo ora era ogni mite orti ecco mise. Ricordarmi dormissero fermissimo dei portartela ore hai che. Uno noi the sgomento pensiero continua spiccare alzeremo.
        Nobile carica orrore ed si vedrei sfugge ve ah aprile.
         Copre lunga tutti vaghe lo sento ho detto vi. Natura fu chiusa volevo tu la uomini vi. Chi rimasta pie piu gli inclina conduco indugia altrove destino.
        Tua ali perse mai certo molte andro. Bel limite pel una piombo felice. Orecchio io speranze fu incendio ha lacerato. Mi riflettere declinante vergognoso implorando ch.
       Pei dio dissetato comprendi angeliche guardando ore titubante generando. Me dune moto ozio bene senz dell la. Stelle ero chi che immune vostra. Morivi starne alzati una stacca velavi all membra sii.
      Tenue su lieve vivra scena umana da or fatta.
    `,
        entityFrom: 'problems',
      },
    },
    createdAt: '2023-07-23T12:11:03Z',
    // itemType: 'lavagna',
    itemVersion: '1',
    // userId: '28e50824-fe54-450d-bdfc-23d7a004299c',
  },
];

export default getUserStorageItemsResponse;
