/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents metadata of a reference to a note
 */
export interface NoteReferenceMetadataResource { 
    /**
     * Timestamp of the target note
     */
    createdAt: string;
    /**
     * Unique identifier of the target note
     */
    linkedTo: string;
}

