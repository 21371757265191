import { Sort } from '@angular/material/sort';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { Pagination } from '../../../shared/entities/pagination';
import { PatientEncounter } from '../interfaces/patient-encounter';
import { PatientProblem } from '../interfaces/patient-problem';
import { ProblemType } from '../interfaces/problem-type';
import { ProblemsFilter } from '../interfaces/problems-filter';
import { ProblemsVisualization } from '../problems.constants';
import {
  createPatientProblem,
  createPatientProblemFailure,
  createPatientProblemSuccess,
  deletePatientProblem,
  deletePatientProblemSuccess,
  hideProblemLegendModal,
  hideProblemModal,
  loadAllProblemData,
  loadAllProblemDataFailure,
  loadAllProblemDataSuccess,
  loadAllProblems,
  loadAllProblemsAborted,
  loadAllProblemsFailure,
  loadAllProblemsSuccess,
  loadMoreProblemData,
  problemDeletionAborted,
  problemDeletionRequired,
  reloadAllProblemDataStarted,
  showCreateProblemModal,
  showEditProblemModal,
  showProblemLegendModal,
  showViewProblemModal,
  sortProblems,
  switchVisualization,
  toggleProblemsFilter,
  updatePatientProblem,
  updatePatientProblemSuccess,
  updateProblemDataSearchString,
} from './problems.actions';

// State in which the modal used to create or edit a problem can be (if it's hidden the state is undefined)
export type ProblemModalState =
  | {
      mode: 'create';
    }
  | {
      mode: 'edit';
      problem: PatientProblem;
    }
  | {
      mode: 'view';
      problem: PatientProblem;
    };

export interface ProblemsState {
  patientId: string;
  visualization: ProblemsVisualization;
  encounters?: PatientEncounter[];
  problemTypes?: ProblemType[];
  currentEncounter?: PatientEncounter;
  // problems?: PatientProblem[];
  filter: ProblemsFilter;

  isLoadingProblems: boolean;
  isLoadingProblemData: boolean;
  isCreatingOrEditingProblem: boolean;

  problemsDataPagination?: Pagination;
  problemsDataSearchString?: string;
  selectedProblemData?: ProblemType;

  problemModalState?: ProblemModalState;

  problemToDelete?: PatientProblem;

  sort?: Sort;

  showProblemLegend: boolean;
}

export const initialProblemsState: ProblemsState = {
  patientId: '',
  visualization: ProblemsVisualization.Timeline,
  encounters: undefined,
  problemTypes: undefined,
  isLoadingProblems: false,
  isLoadingProblemData: false,
  isCreatingOrEditingProblem: false,
  filter: {
    showClosed: true,
    showOpened: true,
    showMedical: true,
    showNursing: false, // TODO test both cases
  },

  showProblemLegend: false,
};

export const problemsReducer: ActionReducer<ProblemsState, Action> =
  createReducer(
    initialProblemsState,
    on(
      switchVisualization,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        visualization:
          state.visualization === ProblemsVisualization.Timeline
            ? ProblemsVisualization.Table
            : ProblemsVisualization.Timeline,
      })
    ),

    on(
      reloadAllProblemDataStarted,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        problemTypes: [],
        isLoadingProblemData: true,
        problemsDataPagination: undefined,
        selectedProblemData: undefined,
      })
    ),
    on(
      loadAllProblemData,
      (
        state: ProblemsState,
        { searchString }: { searchString?: string }
      ): ProblemsState => ({
        ...state,
        isLoadingProblemData: true,
        selectedProblemData: undefined,
        problemsDataSearchString: searchString,
      })
    ),
    on(
      loadMoreProblemData,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        isLoadingProblemData: true,
      })
    ),
    on(
      loadAllProblemDataSuccess,
      (
        state: ProblemsState,
        {
          problemType,
          pagination,
        }: { problemType: ProblemType[]; pagination?: Pagination }
      ): ProblemsState => ({
        ...state,
        problemTypes: [...(state.problemTypes ?? []), ...problemType],
        problemsDataPagination: pagination,
        isLoadingProblemData: false,
      })
    ),
    on(
      loadAllProblemDataFailure,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        problemTypes: [],
        isLoadingProblemData: false,
      })
    ),

    on(
      updateProblemDataSearchString,
      (
        state: ProblemsState,
        { searchString }: { searchString?: string }
      ): ProblemsState => ({
        ...state,
        problemsDataSearchString: searchString,
      })
    ),

    on(
      loadAllProblems,
      (
        state: ProblemsState,
        { patientId }: { patientId: string }
      ): ProblemsState => ({
        ...state,
        patientId,
        isLoadingProblems: true,
      })
    ),
    on(
      loadAllProblemsAborted,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        isLoadingProblems: false,
      })
    ),
    on(
      loadAllProblemsSuccess,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        isLoadingProblems: false,
      })
    ),
    on(
      loadAllProblemsFailure,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        isLoadingProblems: false,
      })
    ),
    on(
      createPatientProblem,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        isCreatingOrEditingProblem: true,
      })
    ),
    on(
      createPatientProblemSuccess,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        problemModalState: undefined,
        isCreatingOrEditingProblem: false,
      })
    ),
    on(
      createPatientProblemFailure,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        isCreatingOrEditingProblem: false,
      })
    ),

    on(
      updatePatientProblemSuccess,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        problemModalState: undefined,
        isCreatingOrEditingProblem: false,
      })
    ),

    on(
      problemDeletionRequired,
      (
        state: ProblemsState,
        { problem }: { problem: PatientProblem }
      ): ProblemsState => ({
        ...state,
        problemToDelete: problem,
      })
    ),
    on(
      problemDeletionAborted,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        problemToDelete: undefined,
      })
    ),
    on(
      deletePatientProblem,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        isCreatingOrEditingProblem: true,
      })
    ),

    on(
      deletePatientProblemSuccess,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        problemToDelete: undefined,
        isCreatingOrEditingProblem: false,
        problemModalState: undefined,
      })
    ),
    on(
      toggleProblemsFilter,
      (state: ProblemsState, { key }): ProblemsState => ({
        ...state,
        filter: {
          ...state.filter,
          [key]: !state.filter?.[key],
        },
      })
    ),
    on(
      sortProblems,
      (state: ProblemsState, { sort }): ProblemsState => ({
        ...state,
        sort,
      })
    ),
    on(
      showCreateProblemModal,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        problemModalState: {
          mode: 'create',
        },
      })
    ),
    on(
      showEditProblemModal,
      (
        state: ProblemsState,
        { problemToEdit }: { problemToEdit: PatientProblem }
      ): ProblemsState => ({
        ...state,
        problemModalState: {
          mode: 'edit',
          problem: problemToEdit,
        },
      })
    ),
    on(
      showViewProblemModal,
      (
        state: ProblemsState,
        { problemToView }: { problemToView: PatientProblem }
      ): ProblemsState => ({
        ...state,
        problemModalState: {
          mode: 'view',
          problem: problemToView,
        },
      })
    ),
    on(
      hideProblemModal,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        problemModalState: undefined,
      })
    ),
    on(
      updatePatientProblem,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        isCreatingOrEditingProblem: true,
      })
    ),

    on(
      showProblemLegendModal,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        showProblemLegend: true,
      })
    ),

    on(
      hideProblemLegendModal,
      (state: ProblemsState): ProblemsState => ({
        ...state,
        showProblemLegend: false,
      })
    )
  );
