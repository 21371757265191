import {
  NgxMatCalendarView,
  NgxMatDateAdapter,
  NgxMatDatetimePickerModule,
  NgxMatDatetimepicker,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { NgxMatDatepickerInputEvent } from '@angular-material-components/datetime-picker/lib/datepicker-input-base';
import { BooleanInput } from '@angular/cdk/coercion';
import { CommonModule, formatDate } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import {
  DatepickerDropdownPositionX,
  DatepickerDropdownPositionY,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { NateaIconsModule } from '../../icons/natea-icons.module';
import { ButtonComponent, ButtonVariants } from '../button/button.component';
import { FieldErrorComponent } from '../field/field-error/field-error.component';
import { FieldComponent } from '../field/field.component';
import { CustomDateAdapter } from './date-time-picker-service/custom-date-adapter';

@Component({
  selector: 'natea-date-time-picker',
  standalone: true,

  providers: [
    { provide: NgxMatDateAdapter, useClass: CustomDateAdapter },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateTimePickerComponent,
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatInputModule,
    NgxMatTimepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    NgxMatDatetimePickerModule,
    ButtonComponent,
    TranslocoModule,
    NateaIconsModule,
    FieldErrorComponent,
  ],
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent
  extends FieldComponent
  implements ControlValueAccessor
{
  ButtonVariants = ButtonVariants;

  date: Date = new Date();
  isAddClass = true;

  @Input() disableMinute = false;
  @Input() inputValue: Date | null = null;
  @Input() placeholder = '';

  @Input() stepHour = 1;
  @Input() touchUi: BooleanInput = false;
  @Input() stepMinute = 1;

  @Input() isError = false;

  @Input() showSeconds = false;

  @Input() defaultTime: number[] = [
    this.date.getHours(),
    this.date.getMinutes(),
    this.date.getSeconds(),
  ];

  @Input() stepSecond = 1;

  @Input() maxDate?: Date;
  @Input() minDate?: Date;

  @Input() enableMeridian = false;

  @Input() hideTime = false;

  @Input() showSpinners = true;

  @Input() color: ThemePalette;

  @Input() xPosition!: DatepickerDropdownPositionX;

  @Input() yPosition!: DatepickerDropdownPositionY;

  @Output() opened: EventEmitter<void> = new EventEmitter<void>();

  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  @Output() valueChange: EventEmitter<Date | null> =
    new EventEmitter<Date | null>();

  @Output() dateInput: EventEmitter<
    NgxMatDatepickerInputEvent<Date, Date | null>
  > = new EventEmitter<NgxMatDatepickerInputEvent<Date, Date | null>>();

  @Output() viewChanged: EventEmitter<NgxMatCalendarView> =
    new EventEmitter<NgxMatCalendarView>();

  @Output() monthSelected: EventEmitter<string> = new EventEmitter<string>();

  @Output() yearSelected: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('picker', {})
  picker!: NgxMatDatetimepicker<Date>;
  @ViewChild('dateInput', {})
  inputField!: ElementRef<HTMLInputElement>;

  onReset = (): void => {
    const newValue: Date = new Date();

    this.inputValue = newValue;

    this.dateChangeHandler(newValue);

    this.picker.close();
  };

  private dateChangeHandler = (newValue: Date | null): void => {
    if (this.onChange) {
      this.onChange(newValue);
    }
    this.valueChange.emit(newValue);
  };

  onChange!: (val: Date | null) => void;

  onTouched!: () => void;

  markAsTouched = (): void => {
    if (this.onTouched) {
      this.onTouched();
    }
  };

  writeValue(data: Date | null): void {
    this.inputValue = data;
    if (this.inputField?.nativeElement) {
      if (!data) {
        this.inputField.nativeElement.value = '';
      } else {
        this.inputField.nativeElement.value = data
          ? formatDate(data, 'dd/MM/yyyy HH:mm', 'it-IT') // TODO localization?
          : '';
      }
    }
  }

  registerOnChange(fn: (val: Date | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState = (isDisabled: boolean): void => {
    this.isDisabled = isDisabled;
  };

  onDateChanged = (
    event: NgxMatDatepickerInputEvent<Date, Date | null>
  ): void => {
    this.dateChangeHandler(event.value);
  };

  onOutFocus = (event: Event): void => {
    this.isAddClass = !!(event.target as HTMLInputElement).value;
  };

  onDateInput = (
    event: NgxMatDatepickerInputEvent<Date, Date | null>
  ): void => {
    this.markAsTouched();
    this.dateInput.emit(event);
    this.isAddClass = !!event.target.value;
  };

  onOpened = (): void => {
    // this.markAsTouched();
    this.opened.emit();
  };

  onClosed = (): void => {
    this.markAsTouched();
    this.closed.emit();
  };

  onViewChanged = (view: NgxMatCalendarView): void => {
    this.markAsTouched();
    this.viewChanged.emit(view);
  };

  onMonthSelected = (date: string): void => {
    this.markAsTouched();
    this.monthSelected.emit(date);
  };

  onYearSelected = (year: string): void => {
    this.markAsTouched();
    this.yearSelected.emit(year);
  };
}
