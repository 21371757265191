import {
  DetectedProblemCreateRequestResource,
  DetectedProblemPatchRequest,
  DetectedProblemResource,
  EncounterResource,
  ProblemCatalogEntryResource,
} from '../../../core/generated/api';
import { UserAdapters } from '../../../core/api/adapters/user.adapters';
import { ProfessionalCategory } from '../../../shared/entities/professional-category';
import {
  formatDateTimeToServer,
  parseDateTimeFromServer,
} from '../../../shared/utils/adapter-utils';
import { ProblemFormData } from '../interfaces/new-problem-form';
import { PatientEncounter } from '../interfaces/patient-encounter';
import { PatientProblem } from '../interfaces/patient-problem';
import { ProblemType } from '../interfaces/problem-type';

export class ProblemsAdapters {
  public static problemDataBeToFe(
    problem: ProblemCatalogEntryResource
  ): ProblemType {
    return {
      id: problem.id,
      name: problem.name, // TODO mandatory
      description: problem.description,
      valid: problem.isEnabled,
      problemClass:
        problem.problemClass == 'I'
          ? ProfessionalCategory.Nursing
          : ProfessionalCategory.Medical,
    };
  }

  public static patientProblemBeToFe(
    patientId: string,
    detection: DetectedProblemResource
  ): PatientProblem {
    return {
      id: detection.id,
      patientId: patientId,
      encounterId: detection.startEncounterId,
      problemType: this.problemTypeBeToFe(detection.problemCatalogEntry),
      problemDescription: detection.problemCatalogEntry.name ?? '',
      problemClass:
        detection.problemCatalogEntry.problemClass === 'M'
          ? ProfessionalCategory.Medical
          : ProfessionalCategory.Nursing, // TODO will be replaced by domain
      startDate: new Date(detection.startDate),
      endDate: detection.endDate ? new Date(detection.endDate) : undefined,
      note: detection.additionalNotes,
      createdBy: UserAdapters.userInfoBeToFe(detection.createdBy),
      updatedBy: detection.updatedBy
        ? UserAdapters.userInfoBeToFe(detection.updatedBy)
        : undefined,
      insertDate: detection.createdAt
        ? new Date(detection.createdAt)
        : undefined,
      updateDate: detection.updatedAt
        ? new Date(detection.updatedAt)
        : undefined,
      editableFromLoggedUser: detection.permissions.canUpdate,
      cancelableFromLoggedUser: detection.permissions.canDelete,
    };
  }

  public static patientProblemFeToCreate(
    problem: PatientProblem
  ): DetectedProblemCreateRequestResource {
    return {
      additionalNotes: problem.note ?? '',
      problemCatalogEntryId: problem.problemType.id,
      startDate: problem.startDate.toISOString(),
    };
  }

  public static patientProblemFormToCreate(
    formData: ProblemFormData
  ): DetectedProblemCreateRequestResource {
    if (!formData.problemType || !formData.startDate || !formData.problemClass)
      throw new Error('params are mandatory');

    return {
      additionalNotes: formData.note ?? '',
      problemCatalogEntryId: formData.problemType.id,
      startDate: formatDateTimeToServer(formData.startDate),
    };
  }

  public static patientEncounterBeToFe(
    encounter: EncounterResource
  ): PatientEncounter {
    return {
      id: encounter.id,
      patientId: encounter.patient.id,
      nosographicId: encounter.transfer.hospitalId,
      startDate: parseDateTimeFromServer(encounter.startDate),
      departmentId: encounter.transfer.departmentId,
      endDate: encounter.endDate ? new Date(encounter.endDate) : undefined,
    };
  }

  public static patientProblemFeToUpdate(
    problem: ProblemFormData
  ): DetectedProblemPatchRequest {
    const patchRequests: DetectedProblemPatchRequest = {
      description: problem.note ?? '',
      startDate: problem.startDate
        ? formatDateTimeToServer(problem.startDate)
        : '',
      endDate: problem.endDate ? formatDateTimeToServer(problem.endDate) : '',
      problemId: problem.problemType?.id,
    };

    return patchRequests;
  }

  public static problemTypeFeToBe(
    problemType: ProblemType
  ): ProblemCatalogEntryResource {
    return {
      id: problemType.id,
      description: problemType.description,
      name: problemType.name,
      isEnabled: problemType.valid,
      problemClass: problemType.problemClass,
    };
  }

  public static problemTypeBeToFe(
    problemResource: ProblemCatalogEntryResource
  ): ProblemType {
    return {
      id: problemResource.id,
      description: problemResource.description,
      name: problemResource.name,
      valid: problemResource.isEnabled,
      problemClass:
        problemResource.problemClass == 'M'
          ? ProfessionalCategory.Medical
          : ProfessionalCategory.Nursing, // TODO will be replaced by domain
    };
  }
}
