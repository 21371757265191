import { createAction, props } from '@ngrx/store';
import { Config } from '../config';

export enum ConfigActions {
  Load = '[Config] Load Configuration',
}

export const loadConfig = createAction(
  ConfigActions.Load,
  props<{ config: Config }>()
);
