export * from './administrationRouteCatalog-api.service';
import { AdministrationRouteCatalogApiService } from './administrationRouteCatalog-api.service';
export * from './clinicalNote-api.service';
import { ClinicalNoteApiService } from './clinicalNote-api.service';
export * from './department-api.service';
import { DepartmentApiService } from './department-api.service';
export * from './detectedProblem-api.service';
import { DetectedProblemApiService } from './detectedProblem-api.service';
export * from './domain-api.service';
import { DomainApiService } from './domain-api.service';
export * from './encounter-api.service';
import { EncounterApiService } from './encounter-api.service';
export * from './medicalDeviceCatalog-api.service';
import { MedicalDeviceCatalogApiService } from './medicalDeviceCatalog-api.service';
export * from './problemCatalog-api.service';
import { ProblemCatalogApiService } from './problemCatalog-api.service';
export * from './procedure-api.service';
import { ProcedureApiService } from './procedure-api.service';
export * from './procedureCatalog-api.service';
import { ProcedureCatalogApiService } from './procedureCatalog-api.service';
export * from './userStorage-api.service';
import { UserStorageApiService } from './userStorage-api.service';
export const APIS = [AdministrationRouteCatalogApiService, ClinicalNoteApiService, DepartmentApiService, DetectedProblemApiService, DomainApiService, EncounterApiService, MedicalDeviceCatalogApiService, ProblemCatalogApiService, ProcedureApiService, ProcedureCatalogApiService, UserStorageApiService];
