import { Injectable } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import { randomId } from '../../../../shared/utils/utils';
import {
  TherapyDurationType,
  TherapyOccurrenceState,
} from '../../common/entities/therapy-occurrence';
import { BloodComponent } from '../entities/blood-component';
import { TransfusionalTherapy } from '../entities/transfusional-therapy';
import { TransfusionalTherapyFormData } from '../form/transfusional-therapy-form-data';
import {
  baseTransfusionalTherapyMock,
  bloodComponentsMock,
} from '../mocks/transfusional-therapy.mocks';

@Injectable({
  providedIn: 'root',
})
export class TrasfusionalTherapiesWebAPI {
  public createTransfusionalTherapy = (
    data: TransfusionalTherapyFormData
  ): Observable<TransfusionalTherapy> => {
    return of(mockTransfusionalTherapy(data)).pipe(delay(1000));
  };

  public editTransfusionalTherapy = (
    data: TransfusionalTherapyFormData,
    id: string
  ): Observable<TransfusionalTherapy> => {
    return of(mockTransfusionalTherapy(data, id)).pipe(delay(1000));
  };
  // TODO define with BE

  public downloadTypes(): Observable<BloodComponent[]> {
    return of(bloodComponentsMock);
  }

  public suspendTransfusionalTherapy({
    justification,
    patientId,
    prescription,
  }: {
    justification: string;
    patientId: string;
    prescription: TransfusionalTherapy;
  }): Observable<TransfusionalTherapy> {
    return of({
      ...baseTransfusionalTherapyMock,
      id: prescription.id,
      canBeSuspended: false,
      occurrences: [
        ...prescription?.occurrences.map((occ) => ({
          ...occ,
          state: TherapyOccurrenceState.Suspended,
        })),
      ],
    }).pipe(delay(1000));
  }

  public abortTransfusionalTherapy({
    justification,
    patientId,
    prescription,
  }: {
    justification: string;
    patientId: string;
    prescription: TransfusionalTherapy;
  }): Observable<TransfusionalTherapy> {
    return of({
      ...baseTransfusionalTherapyMock,
      id: prescription.id,
      canBeAborted: false,
      occurrences: [
        ...prescription?.occurrences.map((occ) => ({
          ...occ,
          state: TherapyOccurrenceState.Aborted,
        })),
      ],
    }).pipe(delay(1000));
  }

  deleteTransfusionalTherapy(therapyId: string): Observable<void> {
    return of(undefined).pipe(delay(1000));
  }
}

const mockTransfusionalTherapy = (
  data: TransfusionalTherapyFormData,
  id?: string
) => {
  const generatedId = randomId();
  const therapy: TransfusionalTherapy = {
    ...data,
    name: data.bloodComponent?.data?.name ?? '',
    speedMlSecond: data.speed ?? 0,
    bloodComponent: data.bloodComponent!.data!,
    dose: data.dose ?? 0,
    id: id ?? generatedId,
    catalogId: randomId(),
    createdBy: {
      id: randomId(),
      firstName: 'Mario',
      lastName: 'Rossi',
    },
    creationDate: new Date(),
    patientId: randomId(),
    encounterId: randomId(),
    durationMinutes: data.duration?.minutes ?? 0,
    insertionDate: new Date(),
    prescriptionDate: data.prescriptionDate ?? new Date(),
    programmingDate: data.programmingDate ?? new Date(),
    repetitions: data.repetitions?.data ?? undefined,
    forDays: data.forDays ?? 0,
    notes: data.notes ?? '',
    updatedBy: undefined,
    bsa: data.bsa ?? 0,
    patientWeightKg: data.weightKg ?? 0,
    occurrences: [
      {
        id: randomId(),
        prescriptionId: generatedId,
        state: TherapyOccurrenceState.Prescribed,
        durationType: TherapyDurationType.Instant,
        isValid: true,
        weightKg: '10',
        programmingDate: data.programmingDate ?? new Date(),
      },
    ],
  };
  return therapy;
};
