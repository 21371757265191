import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_LOCALE,
  ThemePalette,
} from '@angular/material/core';
import {
  DateRange,
  DatepickerDropdownPositionX,
  DatepickerDropdownPositionY,
  MatCalendarView,
  MatDateRangePicker,
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoService } from '@ngneat/transloco';
import { DateTime } from 'luxon';
import { NateaIconsModule } from '../../icons/natea-icons.module';
import { ButtonComponent, ButtonVariants } from '../button/button.component';
import { CustomDateAdapterService } from '../date-picker/date-picker-service/custom-date-adapter.service';

@Component({
  selector: 'natea-date-range-picker',
  standalone: true,
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapterService,
    },
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    ButtonComponent,
    NateaIconsModule,
  ],
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent<D, S> implements OnInit {
  @ViewChild('picker', { read: MatDateRangePicker<Date | null> })
  picker!: MatDateRangePicker<Date | null>;

  @Input() hintLabel?: string;
  @Input() label = '';
  @Input() isDisabled = false;
  @Input() inputValue: Date | null = null;
  @Input() placeholder = '';
  @Input() required = false;
  @Input() placeholderEndDate = '';
  @Input() isError = false;
  @Input() isErrorEndDate = false;
  @Input() maxDate: Date | null = null;
  @Input() minDate: Date | null = null;
  @Input() color: ThemePalette;

  @Input() startView: 'month' | 'year' | 'multi-year' = 'month';

  @Input() touchUi = false;
  @Input() xPosition!: DatepickerDropdownPositionX;

  @Input() yPosition!: DatepickerDropdownPositionY;

  @Input() errorStateMatcher!: ErrorStateMatcher;
  @Input() inputValueEndDate: Date | null = null;

  @Input() requiredEndDate = false;

  @Input() disabledEndDate = false;

  @Input() alignHint: 'start' | 'end' = 'start';
  @Input() hintLabelEndDate = '';
  @Input() isEndDateDisabled = false;
  @Input() errorStateMatcherDateEnd!: ErrorStateMatcher;

  @Input() maxEndDate: Date | null = null;

  @Input() minEndDate: Date | null = null;
  @Input() disabledDatePicker = false;
  @Input() calendarHeaderComponent!: ComponentType<unknown>;
  @Input() isOpened = false;
  @Input() panelClass: string | string[] = '';
  @Input() restoreFocus = false;
  @Input() buttonLabelCancel = 'cancel';
  @Input() buttonLabelApply = 'apply';

  @Output() startDateChanged: EventEmitter<{
    date: Date | null;
    isRestore: boolean;
  }> = new EventEmitter<{ date: Date | null; isRestore: boolean }>();

  @Output() endDateChanged: EventEmitter<{
    date: Date | null;
    isRestore: boolean;
  }> = new EventEmitter<{ date: Date | null; isRestore: boolean }>();

  @Output() dateInputStart: EventEmitter<
    MatDatepickerInputEvent<Date, DateRange<Date>>
  > = new EventEmitter<MatDatepickerInputEvent<Date, DateRange<Date>>>();

  @Output() dateInputEnd: EventEmitter<
    MatDatepickerInputEvent<Date, DateRange<Date>>
  > = new EventEmitter<MatDatepickerInputEvent<Date, DateRange<Date>>>();

  @Output() opened: EventEmitter<Event> = new EventEmitter<Event>();

  @Output() viewChanged: EventEmitter<MatCalendarView> =
    new EventEmitter<MatCalendarView>();

  @Output() monthSelected: EventEmitter<Date> = new EventEmitter<Date>();

  @Output() yearSelected: EventEmitter<string> = new EventEmitter<string>();

  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  @Output() apply: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  variantColor: ButtonVariants = ButtonVariants.SECONDARY;
  isAddClass = false;
  isRestoringStartDate = false;
  isRestoringEndDate = false;
  dateFormat: string;
  dateSeparators: { [key: number]: string } = {};

  constructor(
    private _adapter: DateAdapter<Date>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private translator: TranslocoService
  ) {
    this.dateFormat = this.translator.translate('common.dateParseFormat');
    for (let i = 0; i < this.dateFormat.length; i++) {
      if (!this.dateFormat[i].match(/[a-z]/i)) {
        this.dateSeparators[i] = this.dateFormat[i];
      }
    }
  }

  ngOnInit(): void {
    this._locale = navigator.language;
    this._adapter.setLocale(this._locale);
  }

  restore = (): void => {
    this.isRestoringStartDate = true;
    this.isRestoringEndDate = true;
    this.picker.select(null);
    this.cancel.emit();
  };

  onEndDateChanged = (
    element: MatDatepickerInputEvent<Date, DateRange<Date>>
  ): void => {
    this.onDateChanged(element, false);
  };

  onStartDateChanged = (
    element: MatDatepickerInputEvent<Date, DateRange<Date>>
  ): void => {
    this.onDateChanged(element, true);
  };

  private getRestoring = (isStart: boolean): boolean =>
    isStart ? this.isRestoringStartDate : this.isRestoringEndDate;

  private resetRestoring = (isStart: boolean): void => {
    if (isStart) {
      this.isRestoringStartDate = false;
    } else {
      this.isRestoringEndDate = false;
    }
  };

  private onDateChanged = (
    element: MatDatepickerInputEvent<Date, DateRange<Date>>,
    isStart: boolean
  ): void => {
    const emitter: EventEmitter<{ date: Date | null; isRestore: boolean }> =
      isStart ? this.startDateChanged : this.endDateChanged;
    if (this.getRestoring(isStart)) {
      this.resetRestoring(isStart);
      emitter.emit({ date: null, isRestore: true });
    } else if (element.value) {
      emitter.emit({ date: element.value, isRestore: false });
    } else {
      const stringDate: string = (
        element.targetElement as HTMLInputElement
      ).value.trim();
      if (!stringDate) {
        emitter.emit({ date: null, isRestore: false });
      } else if (
        stringDate.length ===
        this.dateFormat.length - Object.keys(this.dateSeparators).length
      ) {
        let i = 0;
        let separatorsUsed = 0;
        let fullDate = '';
        while (i < this.dateFormat.length) {
          if (this.dateSeparators[i]) {
            fullDate += this.dateSeparators[i];
            separatorsUsed++;
          } else {
            fullDate += stringDate[i - separatorsUsed];
          }
          i++;
        }
        const date: DateTime = DateTime.fromFormat(fullDate, this.dateFormat);
        if (date.isValid) {
          emitter.emit({ date: date.toJSDate(), isRestore: false });
        } else {
          emitter.emit({ date: null, isRestore: false });
        }
      } else {
        emitter.emit({ date: null, isRestore: false });
      }
    }

    this.isAddClass = !!element.value;
  };
}
