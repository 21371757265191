import { Injectable } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import { ProfessionalCategory } from '../../../../shared/entities/professional-category';
import { randomId } from '../../../../shared/utils/utils';
import {
  TherapyDurationType,
  TherapyOccurrenceState,
} from '../../common/entities/therapy-occurrence';
import { InstrumentalTherapy } from '../entities/instrumental-therapy';
import { InstrumentalTherapyType } from '../entities/instrumental-therapy-type';
import { InstrumentalTherapyFormData } from '../form/instrumental-therapy-form-data';
import { baseInstrumentalTherapyMock } from '../mocks/instrumental-therapy.mocks';
import { AbortInstrumentalTherapyRequest } from './instrumental-therapies.requests';

@Injectable({
  providedIn: 'root',
})
export class InstrumentalTherapiesWebAPI {
  // TODO define with BE

  public downloadTypes(): Observable<InstrumentalTherapyType[]> {
    return of([
      // RLE
      {
        id: randomId(),
        name: 'Fototerapia',

        isContinuous: true,

        parameters: [],
      },
      {
        id: randomId(),
        name: 'NCPAP',

        isContinuous: true,

        parameters: [],
      },
      {
        id: randomId(),
        name: 'Ventilazione standard',

        isContinuous: false,

        parameters: [
          {
            id: randomId(),
            name: 'Peep',
            measureUnit: {
              id: randomId(),
              name: 'cm/H2O',
              shortDescription: 'cm/H2O',
              symbol: 'cm/H2O',
            },
          },
          {
            id: randomId(),
            name: 'FIO₂',
            measureUnit: {
              id: randomId(),
              name: 'um',
              shortDescription: '%',
              symbol: 'um',
            },
          },
          {
            id: randomId(),
            name: 'Frequenza',
            measureUnit: {
              id: randomId(),
              name: 'um',
              shortDescription: 'atti/min',
              symbol: 'um',
            },
          },
          {
            id: randomId(),
            name: 'Tempo inspiratorio',
            measureUnit: {
              id: randomId(),
              name: 'um',
              shortDescription: 'sec',
              symbol: 'um',
            },
          },
          {
            id: randomId(),
            name: 'Tempo espiratorio',
            measureUnit: {
              id: randomId(),
              name: 'secondi',
              shortDescription: 'sec',
              symbol: 'um',
            },
          },
          {
            id: randomId(),
            name: 'Picco delle pressione inspiratoria',
            measureUnit: {
              id: randomId(),
              name: 'um',
              shortDescription: 'cm/H2O',
              symbol: 'cm/H2O',
            },
          },
        ],
      },
      {
        id: randomId(),
        name: 'Ventilazione HFO',

        isContinuous: true,

        parameters: [],
      },
    ]).pipe(delay(1000));
  }

  public createInstrumentalTherapy = (
    data: InstrumentalTherapyFormData
  ): Observable<InstrumentalTherapy> => {
    return of(mockInstrumentalTherapy(data)).pipe(delay(1000));
  };

  public editInstrumentalTherapy = (
    data: InstrumentalTherapyFormData,
    occurrenceId: string,
    therapyId: string
  ): Observable<InstrumentalTherapy> => {
    return of(mockInstrumentalTherapy(data, occurrenceId, therapyId)).pipe(
      delay(1000)
    );
  };

  // Suspend
  public suspendInstrumentalTherapyPrescription(
    therapy: InstrumentalTherapy,
    motivation: string
  ): Observable<InstrumentalTherapy> {
    return of({
      ...therapy,
      occurrences: [
        ...therapy.occurrences.map((occ) => ({
          ...occ,
          state: TherapyOccurrenceState.Suspended,
        })),
      ],
    }).pipe(
      delay(1000)
      // switchMap(() => throwError(() => new Error('My Error')))
    );
  }

  public deleteInstrumentalTherapyPrescription(
    prescriptionId: string,
    patientId: string
  ): Observable<void> {
    return of(undefined).pipe(delay(1000));
  }

  public abortInstrumentalTherapyPrescription({
    encounterId,
    motivation,
    patientId,
    prescription,
  }: AbortInstrumentalTherapyRequest): Observable<InstrumentalTherapy> {
    return of({
      ...baseInstrumentalTherapyMock,
      id: prescription.id,
      occurrences: [
        ...prescription.occurrences.map((occ) => ({
          ...occ,
          state: TherapyOccurrenceState.Aborted,
        })),
      ],
    }).pipe(delay(1000));
  }
}

const mockInstrumentalTherapy = (
  data: InstrumentalTherapyFormData,
  occurrenceId?: string,
  id?: string
) => {
  const generatedId = randomId();
  const therapy: InstrumentalTherapy = {
    ...data,
    specificationDuration: data.specificationDuration ?? false,
    id: id ?? generatedId,
    catalogId: randomId(),
    createdBy: {
      id: randomId(),
      firstName: 'Mario',
      lastName: 'Rossi',
    },
    canBeEdited: false,
    creationDate: new Date(),
    patientId: randomId(),
    encounterId: randomId(),
    durationMinutes: data.duration?.minutes ?? 0,
    insertionDate: new Date(),
    prescriptionDate: data.prescriptionDate ?? new Date(),
    problem: {
      id: randomId(),
      name: data.referencedProblem?.data?.name ?? '',
      valid: true,
      problemClass:
        data.referencedProblem?.data?.problemClass ??
        ProfessionalCategory.Medical,
    },
    programmingDate: data.programmingDate ?? new Date(),
    name: data.therapy?.data?.name ?? '',
    params: data.parameters,
    repetition: data.repetition?.data ?? undefined,
    forDays: data.forDays ?? 0,
    notes: data.notes ?? '',
    updatedBy: undefined,
    bsa: data.bsa ?? 1.15,
    patientWeightKg: data.weightKg ?? 2.3,
    type: {
      id: randomId(),
      isContinuous: true,
      name: data.therapy?.data?.name ?? '',
      parameters: data.therapy?.data ? [...data.therapy?.data?.parameters] : [],
    },
    occurrences: [
      {
        id: occurrenceId ?? randomId(),
        prescriptionId: id ?? generatedId,
        state: TherapyOccurrenceState.Prescribed,
        durationType: TherapyDurationType.Instant,
        isValid: true,
        weightKg: '10',
        programmingDate: data.programmingDate ?? new Date(),
      },
    ],
  };
  return therapy;
};
