import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectConfig } from '../../../core/config/store/config.selectors';
import { LoginData } from './interfaces/login-data';
import { LoginState } from './login.reducers';

export const selectLogin = createFeatureSelector<LoginState>('login');

export const selectLoginData = createSelector(
  selectLogin,
  (state: LoginState): LoginData => state.data
);

export const selectShowDemoLoginButtons = createSelector(
  selectConfig,
  (state) => state?.showDemoLoginButtons ?? false
);
