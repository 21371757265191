import { formatDate } from '@angular/common';
import { randomBool } from '../../shared/utils/utils';
import { GeneralResponseListEncounterResource } from '../generated/api';
import {
  randomChoice,
  randomDateDaysAfter,
  randomDateDaysBefore,
  randomDateYearsBefore,
} from './utils';
import { mockEncounterDay } from '../../shared/utils/mock-utils'; // RLE

const hasOngoingEncounter: boolean = randomBool();

const names: string[] = [
  'John',
  'Richard',
  'Jack',
  'James',
  'Robert',
  'Michael',
  'William',
  'David',
  'Sue',
  'Ellen',
  'Donna',
  'Margaret',
  'Jenna',
  'Patricia',
];

const surnames: string[] = [
  'Doe',
  'Smith',
  'Johnson',
  'Williams',
  'Jones',
  'Brown',
  'Davis',
  'Miller',
  'Wilson',
  'Moore',
  'Taylor',
  'Anderson',
  'Thomas',
  'Jackson',
  'Roe',
];

export const getEncounterMockedResponse = (
  patientId: string,
  count = 4,
  encounterId?: string
): GeneralResponseListEncounterResource => {
  let startDate:  Date = mockEncounterDay(1);
  let endDate: Date = randomDateDaysAfter(2, 3, startDate);
  const payload = [...Array(count).keys()].map((i: number) => {
    const id = 'encounter' + (i + 1);
    const encounterData = {
      id,
      note: 'note',
      departmentName: '',
      patient: {
        id: patientId,
        firstName: randomChoice(names),
        lastName: randomChoice(surnames),
        birthDate: randomDateYearsBefore(0, 2).toISOString(),
      },
      parent: {
        id: '1',
        firstName: randomChoice(names),
        lastName: randomChoice(surnames),
        birthDate: randomDateYearsBefore(18, 45).toISOString(),
      },
      transfer: {
        departmentId: '1',
        encounterId: id,
        enteredAt: randomDateDaysBefore(2, 20).toISOString(),
        hospitalId: '1',
        id: '1',
        place: 'Reparto',
      },
      startDate: formatDate(startDate, 'yyyy-MM-dd', 'it-IT'),
      endDate: i === 0 ? undefined : formatDate(endDate, 'yyyy-MM-dd', 'it-IT'),
      departmentId: '1',
    };
    endDate = randomDateDaysBefore(10, 20, startDate);
    startDate = randomDateDaysBefore(2, 5, endDate);
    return encounterData;
  });

  const ret = {
    pagination: {
      itemPerPage: 10,
      pageNumber: 1,
      totalItemNumber: 10,
      totalPageNumber: 1,
    },
    payload,
  };

  if (encounterId) {
    ret.payload = [ret.payload[0]];
    ret.payload[0].transfer.encounterId = ret.payload[0].id = encounterId;
  }
  ret.pagination.totalItemNumber = ret.payload.length;
  return ret;
};

const fiveDaysAgo = (): Date => new Date(Date.now() - 5 * 24 * 60 * 60 * 1000);

export default getEncounterMockedResponse;
