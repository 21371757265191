import { createAction, props } from '@ngrx/store';
import { ApiError } from 'src/app/shared/entities/errors';

import { formStatus } from '../entities/status-form.enum';
import { NewBornData } from '../form/new-born-form-data';
import { NewBornFactSheetCatalogs } from '../entities/general-catalog';

export enum NewBornActions {
  LoadAllFactSheetNewBorn = '[NewBorn] Load All NewBorn tabs',
  LoadAllFactSheetNewBornSuccess = '[NewBorn] Load All FactSheet NewBorn Success',
  LoadAllFactSheetNewBornCatalogs = '[NewBorn] Load All FactSheet NewBorn Catalogs',
  CheckValidTab = '[NewBorn] Check Valid Tab',

  LoadAllFactSheetNewBornFailure = '[NewBorn] Load All FactSheet NewBorn Failure',
  SaveFactSheetNewBorn = '[NewBorn] Save FactSheet NewBorn',
  SaveFactSheetNewBornSuccess = '[NewBorn] Save FactSheet NewBorn Success',
  SaveFactSheetNewBornFailure = '[NewBorn] Save FactSheet NewBorn Failure',
  LoadAllFactSheetNewBornCatalogsSuccess = '[NewBorn] Load All FactSheet NewBorn Catalogs Success',
  LoadAllFactSheetNewBornCatalogsFailure = '[NewBorn] Load All FactSheet NewBorn Catalogs Failure',
}

export const loadAllFactSheetNewBorn = createAction(
  NewBornActions.LoadAllFactSheetNewBorn
);

export const loadAllFactSheetNewBornSuccess = createAction(
  NewBornActions.LoadAllFactSheetNewBornSuccess,

  props<{
    factSheet?: NewBornData;
    patientId: string;
    encounterId: string;
  }>()
);

export const checkValidTab = createAction(
  NewBornActions.CheckValidTab,
  props<{ tabId: number; statusForm: formStatus }>()
);

export const saveFactSheetNewBorn = createAction(
  NewBornActions.SaveFactSheetNewBorn,
  props<{
    factSheet: NewBornData;
  }>()
);

export const saveFactSheetNewBornSuccess = createAction(
  NewBornActions.SaveFactSheetNewBornSuccess,
  props<{
    factSheet: NewBornData;
    patientId: string;
    encounterId: string;
  }>()
);

export const loadAllFactSheetNewBornFailure = createAction(
  NewBornActions.LoadAllFactSheetNewBornFailure,
  props<{ error: ApiError }>()
);

export const saveFactSheetNewBornFailure = createAction(
  NewBornActions.SaveFactSheetNewBornFailure,
  props<{ error: ApiError }>()
);

export const loadAllFactSheetNewBornCatalogs = createAction(
  NewBornActions.LoadAllFactSheetNewBornCatalogs
);

export const loadAllFactSheetNewBornCatalogsSuccess = createAction(
  NewBornActions.LoadAllFactSheetNewBornCatalogsSuccess,
  props<{ catalogs: NewBornFactSheetCatalogs }>()
);

export const loadAllFactSheetNewBornCatalogsFailure = createAction(
  NewBornActions.LoadAllFactSheetNewBornCatalogsFailure,
  props<{ error: ApiError }>()
);
