import { RouterReducerState, getRouterSelectors } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DepartmentData, DepartmentsState } from './departments.reducers';

export enum UserDepartmentsStatus {
  NO_DEPARTMENTS = 0,
  SINGLE_DEPARTMENT = 1,
  MULTI_DEPARTMENTS = 2,
}

export interface UserDepartmentsStatusId {
  status: UserDepartmentsStatus;
  departmentId: string;
}

const selectRouterState = createFeatureSelector<RouterReducerState>('router');
const { selectRouteParams } = getRouterSelectors(selectRouterState);

export const selectDepartmentsState =
  createFeatureSelector<DepartmentsState>('departments');

export const selectDepartmentDataLoaded = createSelector(
  selectDepartmentsState,
  (departmentsState: DepartmentsState): boolean =>
    departmentsState.departmentsLoaded
);

export const selectDepartmentsList = createSelector(
  selectDepartmentsState,
  (departmentsState: DepartmentsState): DepartmentData[] | undefined =>
    departmentsState.departments
);

export const selectCurrentDepartmentId = createSelector(
  selectRouteParams,
  ({ departmentId }): string => departmentId
);

export const selectCurrentDepartment = createSelector(

  
  selectDepartmentsList,
  selectCurrentDepartmentId,
  (
    departments: DepartmentData[] | undefined,
    departmentId: string
  ): DepartmentData | undefined =>
    departments?.find(
      (department: DepartmentData): boolean => department.id === departmentId
    )
);

export const selectDepartmentsStatus = createSelector(
  selectDepartmentDataLoaded,
  selectDepartmentsList,
  (
    loaded: boolean,
    departments: DepartmentData[] | undefined
  ): UserDepartmentsStatusId => {
    if (!loaded || !departments) {
      return { status: UserDepartmentsStatus.NO_DEPARTMENTS, departmentId: '' };
    }
    switch (departments.length) {
      case 0:
        return {
          status: UserDepartmentsStatus.NO_DEPARTMENTS,
          departmentId: '',
        };
      case 1:
        return {
          status: UserDepartmentsStatus.SINGLE_DEPARTMENT,
          departmentId: departments[0].id,
        };
      default:
        return {
          status: UserDepartmentsStatus.MULTI_DEPARTMENTS,
          departmentId: '',
        };
    }
  }
);
