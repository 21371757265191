import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import {
  BaseModalComponent,
  ButtonComponent,
  DatePickerComponent,
  DateTimePickerComponent,
  DropdownComponent,
  FolderTabComponent,
  InputComponent,
  NateaIconsModule,
  SlideToggleComponent,
  TabItemComponent,
} from 'natea-components';
import { BlackboardNotesTableComponent } from './components/blackboard-notes-table/blackboard-notes-table.component';
import { SubHeaderPatientDataItemComponent } from './components/headers/sub-header-patient-data-item/sub-header-patient-data-item.component';
import { SubHeaderComponent } from './components/headers/sub-header/sub-header.component';
import { ConfirmationExitWithoutSavingComponent } from './components/modals/confirmation-exit-without-saving/confirmation-exit-without-saving.component';
import { PatientResultTableComponent } from './components/modals/search-patients-modal/components/patients-result-table/patients-result-table.component';
import { SearchPatientsModalComponent } from './components/modals/search-patients-modal/search-patients-modal.component';
import { MoreItemsPillComponent } from './components/more-items-pill/more-items-pill.component';
import { BasePageComponent } from './components/page/base-page/base-page.component';
import { SidebarItemComponent } from './components/sidebar/sidebar-item/sidebar-item.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { LocaleProviderService } from './services/locale-provider.service';
import { LetDirective } from '@ngrx/component';
import { MultiSectionTimelineComponent } from './components/multi-section-timeline/multi-section-timeline.component';
import { MultiSectionTimelineItemComponent } from './components/multi-section-timeline/multi-section-timeline-item/multi-section-timeline-item.component';
import { MultiSectionTimelineHeaderComponent } from './components/multi-section-timeline/multi-section-timeline-header/multi-section-timeline-header.component';
import { LogComponent } from './components/log-viewer/log/log.component';
import { MatSortModule } from '@angular/material/sort';
import { LegendModalComponent } from './components/modals/legend-modal/legend-modal.component';
import { TextAndOptionalIconComponent } from './components/modals/legend-modal/components/text-and-optional-icon/text-and-optional-icon.component';
import { TableLegendComponent } from './components/modals/legend-modal/components/table-legend/table-legend.component';
import { TimelinedBaseSectionComponent } from './components/timelined-base-section/timelined-base-section.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NamePipe } from './pipes/name.pipe';
import { TimelineLineChartComponent } from './components/timeline-line-chart/timeline-line-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { MainHeaderComponent } from './components/main-header/main-header.component';

@NgModule({
  declarations: [
    LocalizedDatePipe,
    MoreItemsPillComponent,
    SubHeaderComponent,
    SubHeaderPatientDataItemComponent,
    SidebarComponent,
    BasePageComponent,
    SidebarItemComponent,
    ClickOutsideDirective,
    ConfirmationExitWithoutSavingComponent,
    BlackboardNotesTableComponent,
    SearchPatientsModalComponent,
    PatientResultTableComponent,
    MultiSectionTimelineComponent,
    MultiSectionTimelineItemComponent,
    MultiSectionTimelineHeaderComponent,
    LogComponent,
    LegendModalComponent,
    TextAndOptionalIconComponent,
    TableLegendComponent,
    TimelinedBaseSectionComponent,
    NamePipe,
    TimelineLineChartComponent,
    MainHeaderComponent,
  ],
  imports: [
    BaseModalComponent,
    ButtonComponent,
    CommonModule,
    DropdownComponent,
    FormsModule,
    InputComponent,
    DatePickerComponent,
    DateTimePickerComponent,
    MatTableModule,
    NateaIconsModule,
    ReactiveFormsModule,
    SlideToggleComponent,
    TranslocoModule,
    RouterModule,
    LetDirective,
    FolderTabComponent,
    MatSortModule,
    MatTabsModule,
    TabItemComponent,
    NgChartsModule,
  ],
  exports: [
    LocalizedDatePipe,
    MoreItemsPillComponent,
    SubHeaderComponent,
    SidebarComponent,
    BasePageComponent,
    ClickOutsideDirective,
    ConfirmationExitWithoutSavingComponent,
    BlackboardNotesTableComponent,
    SearchPatientsModalComponent,
    PatientResultTableComponent,
    MultiSectionTimelineComponent,
    LogComponent,
    LegendModalComponent,
    TimelinedBaseSectionComponent,
    TimelineLineChartComponent,
    NamePipe,
  ],
  providers: [LocaleProviderService],
})
export class SharedModule {}
