import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  ApiError,
  ApiErrorLevel,
  ApiFieldError,
} from '../../shared/entities/errors';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('[ErrorInterceptor] - ', error);
        const result = error.error;
        const apiError: ApiError = {
          code: error.status,
          correlationId: result?.correlationId ?? '',
          datetime: result ? new Date(result.datetime) : new Date(),
          errors:
            result?.errors?.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (e: any): ApiFieldError => ({
                code: e.code,
                message: e.message,
                type: e.type,
                level: e.level as ApiErrorLevel,
                field: e.field,
              })
            ) ?? [],
        };

        return throwError(() => apiError);
      })
    );
  }
}
