import { DatePipe, formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService } from 'natea-components';
import { catchError, exhaustMap, map, of, switchMap } from 'rxjs';
import { ApiError, errorMessage } from '../../../shared/entities/errors';
import { LocaleProviderService } from '../../../shared/services/locale-provider.service';
import { NO_ACTION } from '../../../shared/utils/constants';
import { selectShownEncounterProblems } from '../../patients/store/clinical-data/problems/patients-data.problems.selectors';
import { PatientActions } from '../../patients/store/patients.actions';
import { selectOngoingEncounterAndPatientIds } from '../../patients/store/patients.selectors';
import { ProblemFormData } from '../interfaces/new-problem-form';
import { PatientEncounter } from '../interfaces/patient-encounter';
import { PatientProblem } from '../interfaces/patient-problem';
import { ProblemsWebAPI } from '../webapis/problems.webapi';
import {
  ProblemActions,
  loadAllProblemDataFailure,
  loadAllProblemDataSuccess,
  loadAllProblems,
  loadProblemLogSuccess,
} from './problems.actions';
import { selectProblemsSearchStringAndPagination } from './problems.selectors';

const SNACK_BAR_DEBOUNCE_TIME_MS = 3000;

@Injectable()
export class ProblemsEffects {
  triggerProblemsLoadAfterEncountersDownloaded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: PatientActions.LoadEncountersSuccess;
        encounters: PatientEncounter[];
        patientId: string;
      }>(PatientActions.LoadEncountersSuccess),
      exhaustMap(({ encounters, patientId }) => {
        const ongoingEncounterId = encounters[0].id;
        return of(
          ongoingEncounterId
            ? loadAllProblems({ patientId, encounterId: ongoingEncounterId })
            : { type: NO_ACTION }
        );
      })
    );
  });

  loadAllProblems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProblemActions.LoadAllProblems),
      concatLatestFrom(() => this.store.select(selectShownEncounterProblems)),
      exhaustMap(([{ encounterId, patientId }, problems]) => {
        if (problems != undefined && problems.length > 0) {
          // If the problems are already loaded, we don't need to load them again
          return of({
            type: ProblemActions.LoadAllProblemsAborted,
          });
        }
        return this.problemApi.getProblemsForPatient(patientId).pipe(
          map((problems: PatientProblem[]) => ({
            type: ProblemActions.LoadAllProblemsSuccess,
            problems,
            patientId,
          })),
          catchError((error: ApiError) => {
            console.error('[ProblemEffects] - loadAllProblems', error);
            this.snackBar.showSnackbar(
              errorMessage(error) ??
              this.translocoService.translate('problems.errorDownloading'),
              this.translocoService.translate('problems.close'),
              'error-snackbar',
              SNACK_BAR_DEBOUNCE_TIME_MS
            );
            return of({
              type: ProblemActions.LoadAllProblemsFailure,
              error,
              patientId,
              encounterId,
            });
          })
        );
      })
    );
  });

  loadAllProblemData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProblemActions.LoadAllProblemData),
      exhaustMap(({ searchString }: { searchString: string }) =>
        this.problemApi.getProblemData(searchString).pipe(
          map(({ data, pagination }) => ({
            type: ProblemActions.LoadAllProblemDataSuccess,
            problemType: data,
            pagination: pagination,
          })),
          catchError((error: ApiError) =>
            of({
              type: ProblemActions.LoadAllProblemDataFailure,
              payload: { error },
            })
          )
        )
      )
    );
  });

  loadMoreProblemData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProblemActions.LoadMoreProblemData),
      concatLatestFrom(() =>
        this.store.select(selectProblemsSearchStringAndPagination)
      ),
      switchMap(([, { searchString, pagination }]) => {
        return this.problemApi
          .getProblemData(
            searchString,
            pagination ? pagination.pageNumber + 1 : 1
          )
          .pipe(
            map((result) =>
              loadAllProblemDataSuccess({
                problemType: result.data,
                pagination: result.pagination,
              })
            ),
            catchError((error: ApiError) =>
              of(loadAllProblemDataFailure({ error }))
            )
          );
      })
    );
  });

  createPatientProblem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProblemActions.CreatePatientProblem),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      switchMap(([{ problem }, onGoingEncounterIds]) =>
        this.problemApi
          .createProblemForPatient(
            onGoingEncounterIds?.patientId ?? '',
            onGoingEncounterIds?.encounterId ?? '',
            problem
          )
          .pipe(
            map((problem: PatientProblem) => {
              this.snackBar.showSnackbar(
                this.translocoService.translate('problems.successFullyCreated'),
                this.translocoService.translate('problems.close'),
                'success-snackbar',
                SNACK_BAR_DEBOUNCE_TIME_MS,
                'bottom'
              );
              return {
                type: ProblemActions.CreatePatientProblemSuccess,
                problem,
              };
            })
          )
          .pipe(
            catchError((error: ApiError) => {
              this.snackBar.showSnackbar(
                errorMessage(error) ??
                this.translocoService.translate('problems.errorCreating'),
                this.translocoService.translate('problems.close'),
                'error-snackbar',
                SNACK_BAR_DEBOUNCE_TIME_MS
              );
              return of({
                type: ProblemActions.CreatePatientProblemFailure,
                payload: { error },
              });
            })
          )
      )
    );
  });

  updatePatientProblem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProblemActions.UpdatePatientProblem),
      exhaustMap(
        ({
          problem,
          problemId,
          patientId,
        }: {
          problem: ProblemFormData;
          problemId: string;
          patientId: string;
        }) => {
          return this.problemApi
            .updateProblemForPatient(patientId, problem, problemId)
            .pipe(
              map((problem: PatientProblem) => {
                this.snackBar.showSnackbar(
                  this.translocoService.translate(
                    'problems.successFullyUpdated',
                    {
                      date: problem.insertDate
                        ? formatDate(
                          problem.insertDate,
                          'MM/dd/yyyy',
                          this.localeProviderService.locale.value
                        )
                        : '',
                    }
                  ),
                  this.translocoService.translate('problems.close'),
                  'success-snackbar',
                  SNACK_BAR_DEBOUNCE_TIME_MS
                );

                return {
                  type: ProblemActions.UpdatePatientProblemSuccess,
                  problem,
                };
              }),
              catchError((error: ApiError) => {
                this.snackBar.showSnackbar(
                  errorMessage(error) ??
                  this.translocoService.translate('problems.errorUpdating'),
                  this.translocoService.translate('problems.close'),
                  'error-snackbar',
                  SNACK_BAR_DEBOUNCE_TIME_MS
                );
                return of({
                  type: ProblemActions.UpdatePatientProblemFailure,
                  error,
                });
              })
            );
        }
      )
    );
  });

  deletePatientProblem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProblemActions.DeletePatientProblem),
      exhaustMap(({ problem }: { problem: PatientProblem }) =>
        this.problemApi
          .deleteProblemForPatient(problem.patientId ?? '', problem.id)
          .pipe(
            map(() => {
              this.snackBar.showSnackbar(
                this.translocoService.translate(
                  'problems.successFullyDeleted',
                  {
                    date: problem.insertDate
                      ? this.datePipe.transform(
                        problem.insertDate,
                        'dd/MM/yyyy - HH:mm'
                      )
                      : '',
                  }
                ),
                this.translocoService.translate('problems.close'),
                'success-snackbar',
                SNACK_BAR_DEBOUNCE_TIME_MS
              );

              return {
                type: ProblemActions.DeletePatientProblemSuccess,
                problem,
              };
            }),
            catchError((error: ApiError) => {
              this.snackBar.showSnackbar(
                errorMessage(error) ??
                this.translocoService.translate('common.errors.generic'),
                this.translocoService.translate('problems.close'),
                'error-snackbar',
                SNACK_BAR_DEBOUNCE_TIME_MS
              );
              return of({
                type: ProblemActions.DeletePatientProblemFailure,
                error,
              });
            })
          )
      )
    );
  });

  loadProblemLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProblemActions.LoadProblemLog),
      exhaustMap(({ problemId }: { problemId: string }) => {
        return of(
          loadProblemLogSuccess({
            problemId,
            log: {
              events: [],
            },
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private problemApi: ProblemsWebAPI,
    private snackBar: SnackbarService,
    private store: Store,
    private translocoService: TranslocoService,
    private localeProviderService: LocaleProviderService,
    private datePipe: DatePipe
  ) { }
}
