<ng-container *ngIf="isAnyData">
  <div class="patients-container" *transloco="let t; read: 'patient'">
    <div class="table-heading">
      <div class="list-info">
        <div class="department-name">{{ departmentName }}</div>
        <div class="occupation">
          {{ t("list.occupation", { totalBeds, occupiedBeds }) }}
        </div>
      </div>
      <div class="multi-actions" [ngSwitch]="isDesktopSized">
        <natea-button
          *ngSwitchCase="true"
          leftIcon="pdf"
          [color]="buttonVariants.SECONDARY"
          [label]="t('list.export')"
          [disabled]="(isExportingPatients$ | async) ?? false"
          (buttonClick)="exportPatients()"
        ></natea-button>
        <button type="button" *ngSwitchCase="false" class="export-button">
          <natea-icons fill="#038095" name="pdf"></natea-icons>
        </button>
      </div>
    </div>
    <div
      #patientsTable
      class="patients-table-container"
      role="grid"
      (scroll)="checkOverflow()"
    >
      <table
        mat-table
        [dataSource]="patients"
        matSort
        (matSortChange)="sortTable($event)"
        multiTemplateDataRows
      >
        <caption>
          {{
            t("list.tableCaption")
          }}
        </caption>
        <ng-container matColumnDef="expand" sticky>
          <th mat-header-cell *matHeaderCellDef tabindex="-1"></th>
          <td mat-cell *matCellDef="let row" tabindex="-1">
            <button
              *ngIf="row.note"
              type="button"
              class="row-expand"
              (click)="togglePatientNote(row)"
              [attr.aria-expanded]="isNoteExpanded(row)"
              [attr.aria-controls]="noteRowId(row)"
              [attr.aria-label]="
                isNoteExpanded(row)
                  ? t('list.hidePatientNote')
                  : t('list.showPatientNote')
              "
            >
              <natea-icons
                class="icon arrow-icon"
                [class.expanded]="isNoteExpanded(row)"
                name="arrow-right"
              ></natea-icons>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="patientName" sticky>
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            tabindex="-1"
            role="button"
          >
            {{ t("list.header.patientName") }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="selectPatient.emit(element)"
            class="clickable-cell"
            role="button"
          >
            {{ t("list.patientName", element) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="birthDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            tabindex="-1"
            role="button"
          >
            {{ t("list.header.birthDate") }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="selectPatient.emit(element)"
            role="button"
            class="clickable-cell"
          >
            <!-- TODO localizedDate?? -->
            {{ element.birthDate | date : "dd/MM/yyyy" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="patientWeight">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            tabindex="-1"
            role="button"
          >
            {{ t("list.header.patientWeight") }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="selectPatient.emit(element)"
            role="button"
            class="clickable-cell"
          >
            {{ element.measurements?.weight }}
          </td>
        </ng-container>
        <ng-container matColumnDef="gestationalAge">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            tabindex="-1"
            role="button"
          >
            {{ t("list.header.gestationalAge") }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="selectPatient.emit(element)"
            class="clickable-cell"
            role="button"
          >
            {{ element.gestationalAge }}
          </td>
        </ng-container>
        <ng-container matColumnDef="encounterStartDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            tabindex="-1"
            role="button"
          >
            {{ t("list.header.encounterStartDate") }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="selectPatient.emit(element)"
            class="clickable-cell"
            role="button"
          >
            <!-- TODO localizedDate?? -->
            {{ element.encounterStartDate | localizedDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="currentBed">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            tabindex="-1"
            role="button"
          >
            {{ t("list.header.currentBed") }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="selectPatient.emit(element)"
            class="clickable-cell"
            role="button"
          >
            {{ element.currentBed }}
          </td>
        </ng-container>
        <ng-container matColumnDef="warnings" stickyEnd>
          <th mat-header-cell *matHeaderCellDef tabindex="-1">
            {{ t("list.header.warnings") }}
          </th>
          <td mat-cell *matCellDef="let element" tabindex="-1">
            <span class="warning" *ngFor="let warning of rowWarnings(element)">
              <a *ngIf="warning.color"
                ><natea-icons
                  class="warning-icon"
                  [fill]="warning.color"
                  [name]="warning.icon"
                ></natea-icons
              ></a>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="spacer">
          <td mat-cell *matCellDef="let element"></td>
        </ng-container>
        <ng-container matColumnDef="expandedRow">
          <td
            mat-cell
            class="patient-note"
            *matCellDef="let element"
            [attr.colspan]="displayedColumns.length - 1"
          >
            <div class="container-note">
              <div class="note">
                {{ element.note || t("list.noPatientNote") }}
              </div>
              <div class="mother-filed">
                {{ t("mother") }}:{{ " " + shownName(element.parent) }} ({{
                  element.parent.birthDate | date : "dd/MM/yyyy"
                }})
              </div>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="table-header-row"
        ></tr>
        <tr
          class="patient-data-row"
          mat-row
          [class]="rowEvenOddClass(row)"
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
        <tr
          [id]="noteRowId(row)"
          class="patient-note-row"
          [class]="rowEvenOddClass(row)"
          mat-row
          *matRowDef="let row; columns: ['spacer', 'expandedRow']"
          [@expandNote]="isNoteExpanded(row) ? 'expanded' : 'collapsed'"
        ></tr>
      </table>
      <natea-cc-more-items-pill
        [visible]="isOverflowing"
      ></natea-cc-more-items-pill>
    </div>
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'patient'">
  <div *ngIf="!isAnyData" class="empty">
    {{ t("list.noResults") }}
  </div>
</ng-container>
