import { Component, Input } from '@angular/core';

@Component({
  selector: 'natea-field',
  template: ``,
  styles: [],
})
export class FieldComponent {
  @Input() errorMessages?: string[] = undefined;

  @Input() isRequired = false;
  @Input() isDisabled = false;

  @Input() fillParentWidth? = true;

  /***
   * By default, mat-input has a width of 180px.
   * If you want to apply a width of auto to the input, set this to true.
   */
  @Input() autoWidth = false;

  @Input() label = '';

  @Input() hintLabel?: string = '';
}
