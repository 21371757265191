import { NutritionalTherapyCatalogs } from '../entities/nutritional-therapy-catalogs';

export const nutritionalTherapiesCatalogsMocks: NutritionalTherapyCatalogs = {
  feedingTypes: [
    {
      id: '1',
      name: 'Enteral',
    },
    {
      id: '2',
      name: 'Parenteral',
    },
  ],

  breastMilkTypes: [ // RLE
    {
      id: '1',
      name: 'Formula',
      requiresFormulaMotivation: true,
    },
    {
      id: '2',
      name: 'Latte di banca',
      requiresFormulaMotivation: false,
    },
    {
      id: '3',
      name: 'Materno',
      requiresFormulaMotivation: false,
    },
    {
      id: '4',
      name: 'Materno / formula',
      requiresFormulaMotivation: true,
    },
  ],

  milkBrands: [
    {
      id: '1',
      name: 'Nan',
    },
    {
      id: '2',
      name: 'Plasmon',
    },
  ],

  milkFormulaMotivations: [ // RLE
    {
      id: '1',
      name: 'Allergia',
    },
  ],

  feedingMethods: [
    {
      id: '1',
      name: 'Biberon',
      hasLength: true,
    },
    {
      id: '2',
      name: 'Naturale',
      hasLength: false,
    },
  ],
  supplements: [
    {
      id: '1',
      name: 'Integratore 1',
    },
    {
      id: '2',
      name: 'Integratore 2',
    },
  ],
  parenteralRepetitions: [
    {
      id: '1',
      name: 'Una volta al giorno - ore 16:00',
    },
    {
      id: '2',
      name: 'Una volta al giorno - ore 11:00',
    },
    {
      id: '3',
      name: 'Due volte al giorno - ore 11:00, 16:00',
    },
  ],
  parenteralBags: [
    {
      id: '1',
      name: 'Sacca 1',
      contributions: [
        {
          id: '1',
          name: 'Calorie (Kcal/kg)',
          parenteral: 1,
          enteral: 2,
          medicine: 3,
          total: 6,
        },
        {
          id: '2',
          name: 'Glucidi (g/kg)',
          parenteral: 1,
          enteral: 2,
          medicine: 3,
          total: 6,
        },
        {
          id: '3',
          name: 'Prot. amminoacidi (g/kg)',
          parenteral: 1,
          enteral: 2,
          medicine: 3,
          total: 6,
        },
        {
          id: '4',
          name: 'Grassi/lipidi (g/kg)',
          parenteral: 1,
          enteral: 2,
          medicine: 3,
          total: 6,
        },
        {
          id: '5',
          name: 'Potassio (mEq/kg)',
          parenteral: 1,
          enteral: 2,
          medicine: 3,
          total: 6,
        },
        {
          id: '6',
          name: 'Magnesio (mEq/kg)',
          parenteral: 1,
          enteral: 2,
          medicine: 3,
          total: 6,
        },
        {
          id: '7',
          name: 'Calcio (mg/kg)',
          parenteral: 1,
          enteral: 2,
          medicine: 3,
          total: 6,
        },
      ],
    },
    {
      id: '2',
      name: 'Sacca 2',
      contributions: [
        {
          id: '1',
          name: 'Calorie (Kcal/kg)',
          parenteral: 1,
          enteral: 2,
          medicine: 3,
          total: 6,
        },
        {
          id: '2',
          name: 'Glucidi (g/kg)',
          parenteral: 1,
          enteral: 2,
          medicine: 3,
          total: 6,
        },
        {
          id: '3',
          name: 'Prot. amminoacidi (g/kg)',
          parenteral: 1,
          enteral: 2,
          medicine: 3,
          total: 6,
        },
        {
          id: '4',
          name: 'Grassi/lipidi (g/kg)',
          parenteral: 1,
          enteral: 2,
          medicine: 3,
          total: 6,
        },
        {
          id: '5',
          name: 'Potassio (mEq/kg)',
          parenteral: 1,
          enteral: 2,
          medicine: 3,
          total: 6,
        },
        {
          id: '6',
          name: 'Magnesio (mEq/kg)',
          parenteral: 1,
          enteral: 2,
          medicine: 3,
          total: 6,
        },
        {
          id: '7',
          name: 'Calcio (mg/kg)',
          parenteral: 1,
          enteral: 2,
          medicine: 3,
          total: 6,
        },
      ],
    },
  ],
};
