import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import {
  MultisectionItemIconConfig,
  ProcedureOccurrenceDurationType,
} from '../models/multi-section-timeline-instance';
import {
  PositionedInstantOccurrence,
  PositionedProlongedOccurrence,
  PositionedRecurringOccurrence,
  TimelinePositionedInstance,
  TimelinePositionedOccurrence,
} from '../models/positioned-timeline-item';

@Component({
  selector: 'natea-cc-multi-section-timeline-item',
  templateUrl: './multi-section-timeline-item.component.html',
  styleUrls: ['./multi-section-timeline-item.component.scss'],
})
export class MultiSectionTimelineItemComponent<T> {
  @Input() set instance(value: TimelinePositionedInstance<T>) {
    this.updateDotsAndLines(value);
    this.depth = value.maxCollisionDepth ?? 0;
  }

  @Input() dateRange!: Date[];

  @Input() showWarning = false;

  @Output() itemSelected: EventEmitter<T> = new EventEmitter<T>();

  @HostBinding('style.--items-count') get itemsCount(): string {
    return `${this.dateRange.length}`;
  }

  @HostBinding('style.--depth') get styleDepth(): string {
    return `${(this.depth ?? 0) + 1}`;
  }

  depth = 0;

  dots: TimelineDot<T>[] = [];
  flowLines: TimelineLine<T>[] = [];
  infiniteFlowLines: TimelineFlowInfiniteLine[] = [];
  singleLines: TimelineLine<void>[] = [];
  rangeLines: TimelineLine<void>[] = [];

  private updateDotsAndLines = (value: TimelinePositionedInstance<T>): void => {
    value.occurrences.forEach(this.addOccurrence);
  };

  private addOccurrence = (
    occurrence: TimelinePositionedOccurrence<T>
  ): void => {
    if (occurrence.rangeStartPercentage && occurrence.rangeWidth) {
      this.rangeLines.push({
        startPosition: occurrence.rangeStartPercentage,
        width: occurrence.rangeWidth,
        depth: occurrence.collisionDepth ?? 0,
      });
    }
    if (occurrence.type === ProcedureOccurrenceDurationType.Instant) {
      this.addInstantOccurrence(occurrence);
    } else if (occurrence.type === ProcedureOccurrenceDurationType.Prolonged) {
      this.addProlongedOccurrence(occurrence);
    } else if (occurrence.type === ProcedureOccurrenceDurationType.Recurring) {
      this.addRecurringOccurrence(occurrence);
    }
  };

  private addInstantOccurrence = (
    occurrence: PositionedInstantOccurrence<T>
  ): void => {
    const leftPosition: number | undefined = occurrence.leftPositionPercentage;
    if (leftPosition) {
      this.dots.push({
        position: leftPosition,
        datetime: occurrence.executionDate,
        icon: occurrence.icon,
        data: occurrence.data,
        depth: occurrence.collisionDepth ?? 0,
      });
    }
  };

  private addProlongedOccurrence = (
    occurrence: PositionedProlongedOccurrence<T>
  ): void => {
    const leftPosition: number | undefined = occurrence.leftPositionPercentage;
    if (leftPosition) {
      this.dots.push({
        position: leftPosition,
        datetime: occurrence.executionDate,
        icon: occurrence.startIcon,
        data: occurrence.data,
        depth: occurrence.collisionDepth ?? 0,
        hasTimeLabelAbove: true
      });
      if (occurrence.width) {
        const rightPosition: number = leftPosition + occurrence.width;

        if (occurrence.terminationDate) {
          this.dots.push({
            position: rightPosition,
            datetime: occurrence.terminationDate ?? new Date(),
            icon: occurrence.endIcon,
            data: occurrence.data,
            depth: occurrence.collisionDepth ?? 0,
          });

          this.flowLines.push({
            startPosition: leftPosition,
            width: occurrence.width,
            data: occurrence.data,
            depth: occurrence.collisionDepth ?? 0,
          });
        } else {
          this.infiniteFlowLines.push({
            startPosition: leftPosition,
            depth: occurrence.collisionDepth ?? 0,
          });
        }
      }
    }
  };

  private addRecurringOccurrence = (
    occurrence: PositionedRecurringOccurrence<T>
  ): void => {
    occurrence.occurrences.forEach(this.addOccurrence);
    if (occurrence.leftPositionPercentage && occurrence.width) {
      this.singleLines.push({
        startPosition: occurrence.leftPositionPercentage,
        width: occurrence.width,
        depth: occurrence.collisionDepth ?? 0,
      });
    }
  };

  onItemClicked = (item: T | undefined): void => {
    if (!item) return;
    this.itemSelected.emit(item);
  };
}

interface TimelineDot<T> {
  position: number;
  datetime: Date;
  icon?: MultisectionItemIconConfig;
  data?: T;
  depth: number;
  hasTimeLabelAbove?: boolean;
}

interface TimelineLine<T> {
  startPosition: number;
  width: number;
  data?: T;
  depth: number;
}

interface TimelineFlowInfiniteLine {
  startPosition: number;
  depth: number;
}
