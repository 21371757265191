import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Padding } from '../../models/padding';
import { datePercentageInRange } from '../../utils/date-utils';
import {
  MultiSectionTimelineInstance,
  MultisectionTimelineInstantOccurrence,
  MultisectionTimelineOccurrence,
  MultisectionTimelineProlongedOccurrence,
  MultisectionTimelineRecurringOccurrence,
  ProcedureOccurrenceDurationType,
} from './models/multi-section-timeline-instance';
import {
  PositionedInstantOccurrence,
  PositionedProlongedOccurrence,
  PositionedRecurringOccurrence,
  TimelinePositionedInstance,
  TimelinePositionedOccurrence,
} from './models/positioned-timeline-item';
import {
  CollisionsFinder,
  CollisionsFinderResult,
} from './utils/collisions-finder';

// Update items recurrently in order to always be up to date with the current time
const updateTimeInterval: number = environment.production
  ? 1000 * 60
  : 1000 * 60 * 60;

@Component({
  selector: 'natea-cc-multi-section-timeline',
  templateUrl: './multi-section-timeline.component.html',
  styleUrls: ['./multi-section-timeline.component.scss'],
})
export class MultiSectionTimelineComponent<T> implements OnInit, OnChanges {
  @Input() startDate?: Date;
  @Input() endDate?: Date;
  @Input() hasIcon = false;

  @Input() instances!: MultiSectionTimelineInstance<T>[];

  @Input() showDaysFooter = false;

  @Input() timelineColumnPaddings?: Padding;

  @Input() initialScrollDate: Date | null = new Date();

  @Input() tabIndex = 0;

  @Output() dateRangeChange: EventEmitter<Date[]> = new EventEmitter<Date[]>();
  @Output() itemSelected: EventEmitter<T> = new EventEmitter<T>();
  @Output() scrollChange: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('timelineColumn', {
    static: true,
  })
  timelineColumn!: ElementRef<HTMLElement>;

  @HostListener('scroll', ['$event']) private scroll(event: Event) {
    const container: Element = event.target as Element;

    const timelineColumnWidth: number =
      this.timelineColumn.nativeElement.clientWidth;

    const timeSpan: number = this.maxDate.getTime() - this.minDate.getTime();
    const scrollPercentage: number = container.scrollLeft / timelineColumnWidth;

    this.leftBorderDate = new Date(
      this.minDate.getTime() + timeSpan * scrollPercentage
    );

    this.scrollChange.emit();
  }

  constructor(private hostRef: ElementRef) {}

  /******************* Graphical items properties ********************/

  // Midnight of the first day of the timeline
  minDate!: Date;
  // 23:59:59 of the last day of the timeline
  maxDate!: Date;

  // Calculated field with the list of all days between minDate and maxDate
  dateRange: Date[] = [];

  todayLineLeftPosition: number | undefined;

  /**
   * List of all instances/occurrences, each row is an instance and each cell is an occurrence.
   * Each occurrence has the information needed to render it on the timeline (left position, width)
   * */
  positionedInstances: TimelinePositionedInstance<T>[] = [];

  /**
   * Contains, for each line, the minimum date that if not visible in the timeline must show a warning to the user
   */
  minWarningDates: Map<string, Date> = new Map();

  /**
   * The date/time corresponding to the left border of the timeline. In other words, the first visible date/time in the timeline.
   */
  leftBorderDate?: Date;

  /******************* Lifecycle ********************/

  ngOnInit(): void {
    setInterval(() => {
      this.updateAllItems();
    }, updateTimeInterval);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['tabIndex'] && changes['tabIndex'].currentValue === 0) ||
      changes['initialScrollDate']
    ) {
      this.updateAllItems();

      const scrollDate = this.initialScrollDate;

      if (
        scrollDate &&
        this.minDate <= scrollDate &&
        this.maxDate > scrollDate
      ) {
        setTimeout(() => {
          this.scrollToDate(scrollDate);
        });
      }
    }
    if (changes['instances']) {
      this.updatePositionedItems();
      this.updateCollisions();
    }
  }

  /************************* Graphical items positioning  **************************/

  private scrollToDate = (date: Date): void => {
    // Ensure that the date is at the start of the day
    date.setHours(0);

    const scrollPercentage: number | undefined = datePercentageInRange(
      date,
      // Start of the minDate
      new Date(
        this.minDate.getFullYear(),
        this.minDate.getMonth(),
        this.minDate.getDate(),
        0
      ),
      // End of the maxDate
      new Date(
        this.maxDate.getFullYear(),
        this.maxDate.getMonth(),
        this.maxDate.getDate(),
        23,
        59,
        59
      )
    );

    const scrollRef = this.hostRef.nativeElement;

    if (scrollPercentage != undefined) {
      // Get the available scroll space
      const mainColumnScrollWidth = scrollRef.scrollWidth;
      const mainColumnOffsetWidth = scrollRef.offsetWidth;
      // Used to center the date in the timeline
      const centerOffset = (mainColumnOffsetWidth ?? 0) / 2;

      const scrollLeftPx =
        (scrollPercentage * mainColumnScrollWidth) / 100 - centerOffset;
      scrollRef.scrollLeft = scrollLeftPx;
    }
  };

  // Update all items when the timespan changes or after a determined amount of time

  private updateAllItems = (): void => {
    this.updateRange();
    this.updateTodayLinePosition();
    this.updatePositionedItems();
  };

  private updateRange = (): void => {
    const newMinDate: Date = this.startDate
      ? new Date(this.startDate)
      : new Date();
    newMinDate.setHours(0, 0, 0, 0);
    this.minDate = newMinDate;

    // If no end date is provided, set it to 3 days from now
    const newMaxDate: Date = new Date(
      this.endDate?.getTime() ?? Date.now() + 3 * 24 * 60 * 60 * 1000
    );
    newMaxDate.setHours(23, 59, 59, 999);
    this.maxDate = newMaxDate;

    this.dateRange = this.getDateRange(this.minDate, this.maxDate);
    this.dateRangeChange.emit([this.minDate, this.maxDate]);
  };

  private getDateRange = (startDate: Date, endDate: Date): Date[] => {
    const result: Date[] = [];
    let currentDate: Date = new Date(startDate.getTime());

    while (currentDate <= endDate) {
      result.push(currentDate);
      currentDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
    }

    return result;
  };

  private updateTodayLinePosition = (): void => {
    const todayStart: Date = new Date();
    todayStart.setHours(0, 0, 0, 0);

    const todayEnd: Date = new Date();
    todayEnd.setHours(23, 59, 59, 999);

    this.todayLineLeftPosition = datePercentageInRange(
      new Date(),
      this.minDate,
      this.maxDate
    );
  };

  /********** Timeline items positions  **********/

  private updatePositionedItems = (): void => {
    this.minWarningDates.clear();
    this.positionedInstances = this.instances.map(
      (
        instance: MultiSectionTimelineInstance<T>
      ): TimelinePositionedInstance<T> => {
        return {
          id: instance.id,
          label: instance.label,

          occurrences: instance.occurrences.map(
            (
              occurrence: MultisectionTimelineOccurrence<T>
            ): TimelinePositionedOccurrence<T> =>
              this.positionedOccurrence(occurrence, instance.id)
          ),
          iconName: instance?.iconName,
        };
      }
    );
  };

  private positionedOccurrence = (
    occurrence: MultisectionTimelineOccurrence<T>,
    instanceId: string
  ): TimelinePositionedOccurrence<T> => {
    const rangeData: {
      rangeStartPercentage?: number;
      rangeWidth?: number;
    } = {};
    if (occurrence.rangeStartDate && occurrence.rangeEndDate) {
      rangeData.rangeStartPercentage = datePercentageInRange(
        occurrence.rangeStartDate,
        this.minDate,
        this.maxDate
      );
      const rangeEndPercentage: number | undefined = datePercentageInRange(
        occurrence.rangeEndDate,
        this.minDate,
        this.maxDate
      );
      rangeData.rangeWidth =
        rangeData.rangeStartPercentage && rangeEndPercentage
          ? rangeEndPercentage - rangeData.rangeStartPercentage
          : undefined;
    }
    if (occurrence.type === ProcedureOccurrenceDurationType.Instant) {
      return {
        ...rangeData,
        ...this.positionedInstantOccurrence(occurrence, instanceId),
      };
    } else if (occurrence.type === ProcedureOccurrenceDurationType.Prolonged) {
      return {
        ...rangeData,
        ...this.positionedProlongedOccurrence(occurrence, instanceId),
      };
    } else if (occurrence.type === ProcedureOccurrenceDurationType.Recurring) {
      return {
        ...rangeData,
        ...this.positionedRecurringOccurrence(occurrence, instanceId),
      };
    } else {
      throw new Error('Unknown occurrence type');
    }
  };

  private positionedInstantOccurrence = (
    occurrence: MultisectionTimelineInstantOccurrence<T>,
    instanceId: string
  ): PositionedInstantOccurrence<T> => {
    if (occurrence.icon?.isImportant) {
      this.updateWarningDates(occurrence.executionDate, instanceId);
    }
    return {
      ...occurrence,
      leftPositionPercentage: datePercentageInRange(
        occurrence.executionDate,
        this.minDate,
        this.maxDate
      ),
    };
  };

  private positionedProlongedOccurrence = (
    occurrence: MultisectionTimelineProlongedOccurrence<T>,
    instanceId: string
  ): PositionedProlongedOccurrence<T> => {
    if (occurrence.startIcon?.isImportant) {
      this.updateWarningDates(occurrence.executionDate, instanceId);
    } else if (occurrence.terminationDate && occurrence.endIcon?.isImportant) {
      this.updateWarningDates(occurrence.terminationDate, instanceId);
    }

    const leftPosition: number | undefined = datePercentageInRange(
      occurrence.executionDate,
      this.minDate,
      this.maxDate
    );

    const finishDate: Date = occurrence.terminationDate ?? new Date();

    const endPosition: number | undefined = datePercentageInRange(
      finishDate,
      this.minDate,
      this.maxDate
    );

    const width: number | undefined =
      leftPosition && endPosition ? endPosition - leftPosition : undefined;

    return {
      ...occurrence,
      leftPositionPercentage: leftPosition,
      width,
    };
  };

  private positionedRecurringOccurrence = (
    occurrence: MultisectionTimelineRecurringOccurrence<T>,
    instanceId: string
  ): PositionedRecurringOccurrence<T> => {
    let minOccurrenceDate: Date | undefined = undefined;
    let maxOccurrenceDate: Date | undefined = undefined;

    occurrence.occurrences.forEach(
      (
        occurrence:
          | MultisectionTimelineInstantOccurrence<T>
          | MultisectionTimelineProlongedOccurrence<T>
      ) => {
        if (occurrence.type === ProcedureOccurrenceDurationType.Instant) {
          if (
            !minOccurrenceDate ||
            occurrence.executionDate < minOccurrenceDate
          ) {
            minOccurrenceDate = occurrence.executionDate;
          }
          if (
            !maxOccurrenceDate ||
            occurrence.executionDate > maxOccurrenceDate
          ) {
            maxOccurrenceDate = occurrence.executionDate;
          }
        } else if (
          occurrence.type === ProcedureOccurrenceDurationType.Prolonged
        ) {
          if (
            !minOccurrenceDate ||
            occurrence.executionDate < minOccurrenceDate
          ) {
            minOccurrenceDate = occurrence.executionDate;
          }
          if (
            occurrence.terminationDate &&
            (!maxOccurrenceDate ||
              occurrence.terminationDate > maxOccurrenceDate)
          ) {
            maxOccurrenceDate = occurrence.terminationDate;
          }
        }
      }
    );

    const leftPosition: number | undefined = minOccurrenceDate
      ? datePercentageInRange(minOccurrenceDate, this.minDate, this.maxDate)
      : undefined;

    const endPosition: number | undefined = maxOccurrenceDate
      ? datePercentageInRange(maxOccurrenceDate, this.minDate, this.maxDate)
      : undefined;
    const width: number | undefined =
      leftPosition && endPosition ? endPosition - leftPosition : undefined;

    return {
      ...occurrence,
      leftPositionPercentage: minOccurrenceDate
        ? datePercentageInRange(minOccurrenceDate, this.minDate, this.maxDate)
        : undefined,
      width,
      occurrences: occurrence.occurrences.map(
        (
          occurrence:
            | MultisectionTimelineInstantOccurrence<T>
            | MultisectionTimelineProlongedOccurrence<T>
        ):
          | PositionedInstantOccurrence<T>
          | PositionedProlongedOccurrence<T> => {
          if (occurrence.type === ProcedureOccurrenceDurationType.Instant) {
            return this.positionedInstantOccurrence(occurrence, instanceId);
          } else if (
            occurrence.type === ProcedureOccurrenceDurationType.Prolonged
          ) {
            return this.positionedProlongedOccurrence(occurrence, instanceId);
          }
          throw new Error('Not admitted occurrence type');
        }
      ),
    };
  };

  private updateCollisions = (): void => {
    this.positionedInstances.forEach(this.updateInstanceCollisions);
  };

  private updateInstanceCollisions = (
    instance: TimelinePositionedInstance<T>
  ): void => {
    const collisionsFinder: CollisionsFinder = new CollisionsFinder();
    const result: CollisionsFinderResult =
      collisionsFinder.findMultisectionItemCollisions(instance);
    instance.maxCollisionDepth = result.maxCollisionDepth;
  };

  /********** Warnings management  **********/

  private updateWarningDates = (date: Date, instanceId: string): void => {
    const minWarningDate: Date | undefined =
      this.minWarningDates.get(instanceId);
    if (!minWarningDate || date < minWarningDate) {
      this.minWarningDates.set(instanceId, date);
    }
  };

  showWarning = (instanceId: string): boolean => {
    const minWarningDate: Date | undefined =
      this.minWarningDates.get(instanceId);
    return (
      (minWarningDate &&
        this.leftBorderDate &&
        minWarningDate < this.leftBorderDate) ??
      false
    );
  };

  /********** open detail section  **********/

  onItemSelected = (itemSelected: T): void => {
    this.itemSelected.emit(itemSelected);
  };
}
