import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, of, take } from 'rxjs';
import { ApiError } from '../../../shared/entities/errors';
import { DomainsWebAPI } from '../webapis/domains.webapi';
import {
  DomainsActions,
  downloadCoreDomainsAndCatalogsFailure,
  downloadCoreDomainsAndCatalogsSuccess,
} from './domains.actions';

@Injectable()
export class DomainsEffects {
  loadCoreDomainsAndCatalogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DomainsActions.DownloadCoreDomainsAndCatalogs),
      exhaustMap(() => {
        return this.api
          .getDomains()
          .pipe(take(1))
          .pipe(
            map((result) => {
              return downloadCoreDomainsAndCatalogsSuccess({
                domainsAndCatalogs: result,
              });
            })
          )
          .pipe(
            catchError((error: ApiError) =>
              of(downloadCoreDomainsAndCatalogsFailure({ error }))
            )
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private api: DomainsWebAPI,
  ) { }
}
