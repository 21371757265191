import { createAction, props } from '@ngrx/store';

export enum TherapiesActions {
  ShowLegendModal = '[Therapies] Show Legend Modal',
  HideLegendModal = '[Therapies] Hide Legend Modal',
  ShowSecondHeaderLine = '[Therapies] Show Second Header Line',
}

export const showTherapiesLegendModal = createAction(
  TherapiesActions.ShowLegendModal
);

export const hideTherapiesLegendModal = createAction(
  TherapiesActions.HideLegendModal
);

export const showSecondHeaderLine = createAction(
  TherapiesActions.ShowSecondHeaderLine,
  props<{ show: boolean }>()
);
