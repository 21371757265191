import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { ApiError } from '../../../../shared/entities/errors';
import { SelectedTherapyOccurrenceIds } from '../../common/entities/selected-therapy';
import {
  EnteralTherapy,
  NutritionalTherapy,
  ParenteralTherapy,
} from '../entities/nutritional-therapy';
import { NutritionalTherapyCatalogs } from '../entities/nutritional-therapy-catalogs';
import { NutritionalParenteralBag } from '../entities/nutritional-therapy-type';
import {
  NutritionalEnteralFormData,
  NutritionalParenteralFormData,
  NutritionalTherapyFormData,
} from '../form/nutritional-therapy-form-data';

export enum NutritionalTherapiesActions {
  LoadNutritionalTherapies = '[NutritionalTherapies] Load NutritionalTherapies',
  LoadNutritionalTherapiesSuccess = '[NutritionalTherapies] Load NutritionalTherapies Success',
  LoadNutritionalTherapiesFailure = '[NutritionalTherapies] Load NutritionalTherapies Failure',

  SortNutritionalTherapies = '[NutritionalTherapies] Sort',

  SelectOccurrence = '[NutritionalTherapies] Select Occurrence',
  DeselectOccurrence = '[NutritionalTherapies] Deselect Occurrence',

  ShowCreateNewModalEnteral = '[NutritionalTherapies] Show Create New Modal Enteral',
  ShowCreateNewModalParenteral = '[NutritionalTherapies] Show Create New Modal Parenteral',
  HideCreateNewModal = '[NutritionalTherapies] Hide Create New Modal',

  CreateNutritionalTherapy = '[NutritionalTherapies] Create NutritionalTherapy',
  CreateNutritionalTherapySuccess = '[NutritionalTherapies] Create NutritionalTherapy Success',
  CreateNutritionalTherapyFailure = '[NutritionalTherapies] Create NutritionalTherapy Failure',

  CreateEnteralTherapy = '[NutritionalTherapies] Create EnteralTherapy',
  CreateEnteralTherapySuccess = '[NutritionalTherapies] Create EnteralTherapy Success',
  CreateEnteralTherapyFailure = '[NutritionalTherapies] Create EnteralTherapy Failure',

  EditNutritionalTherapy = '[NutritionalTherapies] Edit NutritionalTherapy',
  EditNutritionalTherapySuccess = '[NutritionalTherapies] Edit NutritionalTherapy Success',
  EditNutritionalTherapyFailure = '[NutritionalTherapies] Edit NutritionalTherapy Failure',

  EditEnteralTherapy = '[NutritionalTherapies] Edit EnteralTherapy',
  EditEnteralTherapySuccess = '[NutritionalTherapies] Edit EnteralTherapy Success',
  EditEnteralTherapyFailure = '[NutritionalTherapies] Edit EnteralTherapy Failure',

  EditParenteralTherapy = '[NutritionalTherapies] Edit ParenteralTherapy',
  EditParenteralTherapySuccess = '[NutritionalTherapies] Edit ParenteralTherapy Success',
  EditParenteralTherapyFailure = '[NutritionalTherapies] Edit ParenteralTherapy Failure',

  SelectedNutritionalTherapyFromTimeline = '[NutritionalTherapies] Selected NutritionalTherapy From Timeline',
  TabIndexNutritionalTherapyChanged = '[NutritionalTherapies] Tab Index NutritionalTherapy Changed',

  DownloadTypes = '[NutritionalTherapies] Download Types',
  DownloadTypesSuccess = '[NutritionalTherapies] Download Types Success',
  DownloadTypesFailure = '[NutritionalTherapies] Download Types Failure',

  ConfirmDeleteNutritionalTherapy = '[NutritionalTherapies] Confirm Delete NutritionalTherapy',
  HideDeleteConfirmationModal = '[NutritionalTherapies] Hide Delete Confirmation Modal',
  ShowDeleteConfirmationModal = '[NutritionalTherapies] Show Delete Confirmation Modal',

  ConfirmDeleteNutritionalTherapyFailure = '[NutritionalTherapies] Confirm Delete NutritionalTherapy Failure',
  ConfirmDeleteNutritionalTherapySuccess = '[NutritionalTherapies] Confirm Delete NutritionalTherapy Success',
  ShowConfirmAbortModal = '[NutritionalTherapies] Show Confirm Abort Modal',
  HideConfirmAbortModal = '[NutritionalTherapies] Hide Confirm Abort Modal',

  ConfirmAbortNutritionalTherapy = '[NutritionalTherapies] Confirm Abort NutritionalTherapy',
  ConfirmAbortNutritionalTherapyFailure = '[NutritionalTherapies] Confirm Abort NutritionalTherapy Failure',
  ConfirmAbortNutritionalTherapySuccess = '[NutritionalTherapies] Confirm Abort NutritionalTherapy Success',

  SelectSuspensionCandidate = '[NutritionalTherapies] Select Suspension Candidate',
  AbortSuspension = '[NutritionalTherapies] Abort Suspension',
  ConfirmSuspension = '[NutritionalTherapies] Confirm Suspension',
  SuspensionSuccess = '[NutritionalTherapies] Suspension Success',
  SuspensionFailure = '[NutritionalTherapies] Suspension Failure',

  ShowNutritionalConfirmSuspendModal = '[NutritionalTherapies] Show Nutritional Confirm Suspend Modal',
  HideNutritionalConfirmSuspendModal = '[NutritionalTherapies] Hide Nutritional Confirm Suspend Modal',
  ConfirmSuspendNutritionalTherapy = '[NutritionalTherapies] Confirm Suspend Nutritional Therapy',

  SelectParenteralBag = '[NutritionalTherapies] Select Parenteral Bag',
  ClearSelectedParenteralBag = '[NutritionalTherapies] Clear Selected Parenteral Bag',
}

export const loadNutritionalTherapies = createAction(
  NutritionalTherapiesActions.LoadNutritionalTherapies
);

export const loadNutritionalTherapiesSuccess = createAction(
  NutritionalTherapiesActions.LoadNutritionalTherapiesSuccess,
  props<{
    therapies: NutritionalTherapy[];
    patientId: string;
    encounterId: string;
  }>()
);

export const loadNutritionalTherapiesFailure = createAction(
  NutritionalTherapiesActions.LoadNutritionalTherapiesFailure,
  props<{ error: ApiError }>()
);

export const sortNutritionalTherapies = createAction(
  NutritionalTherapiesActions.SortNutritionalTherapies,
  props<{ sort?: Sort }>()
);

export const selectNutritionalTherapy = createAction(
  NutritionalTherapiesActions.SelectOccurrence,
  // props<{ therapy: NutritionalTherapy }>()
  props<{ selectedTherapyOccurrenceIds: SelectedTherapyOccurrenceIds }>()
);

export const deselectNutritionalTherapy = createAction(
  NutritionalTherapiesActions.DeselectOccurrence
);

export const showCreateNewEnteralTherapyModal = createAction(
  NutritionalTherapiesActions.ShowCreateNewModalEnteral
);
export const showCreateNewParenteralTherapyModal = createAction(
  NutritionalTherapiesActions.ShowCreateNewModalParenteral
);

export const hideCreateNewNutritionalTherapyModal = createAction(
  NutritionalTherapiesActions.HideCreateNewModal
);

export const createNutritionalTherapy = createAction(
  NutritionalTherapiesActions.CreateNutritionalTherapy,
  props<{
    therapy: NutritionalTherapyFormData;
  }>()
);

export const createNutritionalTherapySuccess = createAction(
  NutritionalTherapiesActions.CreateNutritionalTherapySuccess,
  props<{
    therapy: NutritionalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const createNutritionalTherapyFailure = createAction(
  NutritionalTherapiesActions.CreateNutritionalTherapyFailure,
  props<{ error: ApiError }>()
);

export const createEnteralTherapy = createAction(
  NutritionalTherapiesActions.CreateNutritionalTherapy,
  props<{
    therapy: NutritionalEnteralFormData;
  }>()
);

export const createEnteralTherapySuccess = createAction(
  NutritionalTherapiesActions.CreateNutritionalTherapySuccess,
  props<{
    therapy: NutritionalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const createEnteralTherapyFailure = createAction(
  NutritionalTherapiesActions.CreateNutritionalTherapyFailure,
  props<{ error: ApiError }>()
);

// Download therapy types

export const downloadNutritionalTherapyCatalogs = createAction(
  NutritionalTherapiesActions.DownloadTypes
);

export const downloadNutritionalTherapyCatalogsSuccess = createAction(
  NutritionalTherapiesActions.DownloadTypesSuccess,
  props<{
    catalogs: NutritionalTherapyCatalogs;
  }>()
);

export const downloadNutritionalTherapyCatalogsFailure = createAction(
  NutritionalTherapiesActions.DownloadTypesFailure,
  props<{ error: ApiError }>()
);

// Edit therapy

export const editNutritionalTherapy = createAction(
  NutritionalTherapiesActions.EditNutritionalTherapy,
  props<{
    therapyId: string;
    therapy: NutritionalTherapyFormData;
  }>()
);

export const editNutritionalTherapySuccess = createAction(
  NutritionalTherapiesActions.EditNutritionalTherapySuccess,
  props<{
    therapy: NutritionalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const editNutritionalTherapyFailure = createAction(
  NutritionalTherapiesActions.EditNutritionalTherapyFailure,
  props<{ error: ApiError }>()
);

export const editEnteralTherapy = createAction(
  NutritionalTherapiesActions.EditEnteralTherapy,
  props<{
    therapyId: string;
    occurrenceId: string;
    therapy: NutritionalEnteralFormData;
  }>()
);

export const editEnteralTherapySuccess = createAction(
  NutritionalTherapiesActions.EditEnteralTherapySuccess,
  props<{
    therapy: EnteralTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const editEnteralTherapyFailure = createAction(
  NutritionalTherapiesActions.EditEnteralTherapyFailure,
  props<{ error: ApiError }>()
);

// Timeline

export const selectedNutritionalTherapyFromTimeline = createAction(
  NutritionalTherapiesActions.SelectedNutritionalTherapyFromTimeline,
  props<{ selectedTherapy: SelectedTherapyOccurrenceIds }>()
);

// Delete

export const showDeleteNutritionalTherapyConfirmationModal = createAction(
  NutritionalTherapiesActions.ShowDeleteConfirmationModal
);

export const confirmDeleteNutritionalTherapy = createAction(
  NutritionalTherapiesActions.ConfirmDeleteNutritionalTherapy
);

export const confirmDeleteNutritionalTherapySuccess = createAction(
  NutritionalTherapiesActions.ConfirmDeleteNutritionalTherapySuccess,
  props<{
    therapyId: string;
    patientId: string;
    encounterId: string;
  }>()
);

export const confirmDeleteNutritionalTherapyFailure = createAction(
  NutritionalTherapiesActions.ConfirmDeleteNutritionalTherapyFailure,
  props<{ error: ApiError }>()
);

// Abort

export const showConfirmAbortNutritionalModal = createAction(
  NutritionalTherapiesActions.ShowConfirmAbortModal
);

export const confirmAbortNutritionalModal = createAction(
  NutritionalTherapiesActions.ConfirmAbortNutritionalTherapy,
  props<{ justification: string }>()
);

export const confirmAbortNutritionalTherapySuccess = createAction(
  NutritionalTherapiesActions.ConfirmAbortNutritionalTherapySuccess,
  props<{
    therapy: NutritionalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const hideConfirmAbortNutritionalModal = createAction(
  NutritionalTherapiesActions.HideConfirmAbortModal
);

export const hideConfirmDeleteNutritionalModal = createAction(
  NutritionalTherapiesActions.HideDeleteConfirmationModal
);

export const confirmAbortNutritionalTherapyFailure = createAction(
  NutritionalTherapiesActions.ConfirmAbortNutritionalTherapyFailure,
  props<{ error: ApiError }>()
);
// Suspension

export const nutritionalTherapySuspensionSuccess = createAction(
  NutritionalTherapiesActions.SuspensionSuccess,
  props<{
    therapy: NutritionalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const tabIndexNutritionalTherapyChanged = createAction(
  NutritionalTherapiesActions.TabIndexNutritionalTherapyChanged,
  props<{ tabIndex: number }>()
);

export const showNutritionalConfirmSuspendModal = createAction(
  NutritionalTherapiesActions.ShowNutritionalConfirmSuspendModal
);

export const hideNutritionalConfirmSuspendModal = createAction(
  NutritionalTherapiesActions.HideNutritionalConfirmSuspendModal
);

export const confirmSuspendNutritionalTherapy = createAction(
  NutritionalTherapiesActions.ConfirmSuspendNutritionalTherapy,
  props<{ justification: string }>()
);

export const confirmSuspendNutritionalTherapyFailure = createAction(
  NutritionalTherapiesActions.SuspensionFailure,
  props<{ error: ApiError }>()
);

export const selectNutritionalParenteralBag = createAction(
  NutritionalTherapiesActions.SelectParenteralBag,
  props<{ bag: NutritionalParenteralBag; formId: string }>()
);

export const clearSelectedParenteralBag = createAction(
  NutritionalTherapiesActions.ClearSelectedParenteralBag
);

export const editParenteralTherapy = createAction(
  NutritionalTherapiesActions.EditParenteralTherapy,
  props<{
    therapy: NutritionalParenteralFormData;
  }>()
);

export const editParenteralTherapySuccess = createAction(
  NutritionalTherapiesActions.EditParenteralTherapySuccess,
  props<{
    therapy: ParenteralTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const editParenteralTherapyFailure = createAction(
  NutritionalTherapiesActions.EditParenteralTherapyFailure,
  props<{ error: ApiError }>()
);
