import { Sort } from '@angular/material/sort';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import {
  ProceduresExecutionFormData,
  ProceduresProgrammingFormData,
  ProceduresTerminationFormData,
} from '../components/procedures-detail-form/form-data/procedures-detail-form-data';
import {
  ProcedureDevice,
  ProcedureLocation,
  ProcedureRoute,
} from '../entities/procedure-data';
import {
  abortMovementDeletion,
  cancelProcedureExecutionSuccess,
  changeProcedureTab,
  clearProcedureExecutionTemporaryFormData,
  clearProcedureProgrammingTemporaryFormData,
  clearProcedureTerminationTemporaryFormData,
  createMovement,
  createMovementFailure,
  createMovementSuccess,
  createProcedure,
  createProcedureFailure,
  createProcedureSuccess,
  deleteMovement,
  deleteMovementFailure,
  deleteMovementSuccess,
  deleteProcedureSuccess,
  deselectOccurrence,
  hideCreateNewProcedureModal,
  hideProcedureLegendState,
  loadProcedureDevicesSuccess,
  loadProcedureLocationsSuccess,
  loadProcedureRoutesSuccess,
  loadProcedures,
  loadProceduresFailure,
  loadProceduresSuccess,
  saveProcedureExecutionStepSuccess,
  saveProcedureProgrammingStepSuccess,
  saveProcedureTerminationStepSuccess,
  selectMovementForDeletion,
  selectOccurrence,
  selectedProcedureOccurrenceFromTimeline,
  showCreateNewProcedureModal,
  showProcedureLegendState,
  sortProcedures,
  stopProcedureRunSuccess,
  updateMovement,
  updateMovementFailure,
  updateMovementSuccess,
} from './procedures.actions';

export interface ProceduresUiState {
  isLoadingData: boolean;
  sort?: Sort;
  selectedOccurrenceId?: string;

  // Ids of the procedures with a new movement being created
  occurrencesWithMovementsInCreation: string[];

  // Ids of the movements that are being updated
  movementsInUpdate: string[];

  // Ids of the movements that are being deleted
  movementsInDeletion: string[];

  // Movement for which the user has pressed the delete button. It's used to show the confirmation dialog before the actual deletion
  selectedMovementForDeletion?: {
    movementId: string;
    occurrenceId: string;
  };

  showCreateNewModal: boolean;
  isCreatingNew: boolean;

  showProceduresLegendStateModal: boolean;

  tabIndex: number;
  selectedProcedureOccurrenceInTable?: boolean;
  isTabChanging?: boolean;

  currentProgrammingData: ProceduresProgrammingFormData | undefined;
  currentExecutionData: ProceduresExecutionFormData | undefined;
  currentTerminationData: ProceduresTerminationFormData | undefined;

  availableDevices: ProcedureDevice[];
  availableRoutes: ProcedureRoute[];
  availableLocations: ProcedureLocation[];
}

export const initialProceduresUiState: ProceduresUiState = {
  isLoadingData: false,
  occurrencesWithMovementsInCreation: [],
  movementsInUpdate: [],
  movementsInDeletion: [],
  showCreateNewModal: false,
  isCreatingNew: false,
  showProceduresLegendStateModal: false,
  tabIndex: 0,
  selectedProcedureOccurrenceInTable: false,
  isTabChanging: false,
  currentProgrammingData: undefined,
  currentExecutionData: undefined,
  currentTerminationData: undefined,
  availableDevices: [],
  availableRoutes: [],
  availableLocations: [],
};

export const proceduresUiReducer: ActionReducer<ProceduresUiState, Action> =
  createReducer(
    initialProceduresUiState,
    on(
      loadProcedures,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        isLoadingData: true,
      })
    ),
    on(
      loadProceduresSuccess,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        isLoadingData: false,
      })
    ),
    on(
      loadProceduresFailure,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        isLoadingData: false,
      })
    ),
    on(
      sortProcedures,
      (state: ProceduresUiState, { sort }): ProceduresUiState => ({
        ...state,
        sort,
      })
    ),
    on(
      selectOccurrence,
      (state: ProceduresUiState, { occurrenceId }): ProceduresUiState => ({
        ...state,
        selectedOccurrenceId: occurrenceId,
        selectedProcedureOccurrenceInTable: true,
        currentExecutionData: undefined,
        currentProgrammingData: undefined,
        currentTerminationData: undefined,
        isTabChanging: false,
      })
    ),
    on(
      deselectOccurrence,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        selectedOccurrenceId: undefined,
        isTabChanging: undefined,
        selectedProcedureOccurrenceInTable: undefined,
      })
    ),

    // Movement creation
    on(
      createMovement,
      (
        state: ProceduresUiState,
        { occurrenceId: procedureId }
      ): ProceduresUiState => ({
        ...state,
        occurrencesWithMovementsInCreation: [
          ...state.occurrencesWithMovementsInCreation,
          procedureId,
        ],
      })
    ),
    on(
      createMovementSuccess,
      (state: ProceduresUiState, { occurrenceId }): ProceduresUiState => ({
        ...state,
        occurrencesWithMovementsInCreation:
          state.occurrencesWithMovementsInCreation.filter(
            (id) => id !== occurrenceId
          ),
      })
    ),
    on(
      createMovementFailure,
      (
        state: ProceduresUiState,
        { occurrenceId: procedureId }
      ): ProceduresUiState => ({
        ...state,
        occurrencesWithMovementsInCreation:
          state.occurrencesWithMovementsInCreation.filter(
            (id) => id !== procedureId
          ),
      })
    ),

    // Movement update

    on(
      updateMovement,
      (state: ProceduresUiState, { movementId }): ProceduresUiState => ({
        ...state,
        movementsInUpdate: [...state.movementsInUpdate, movementId],
      })
    ),
    on(
      updateMovementSuccess,
      (state: ProceduresUiState, { movementId }): ProceduresUiState => ({
        ...state,
        movementsInUpdate: state.movementsInUpdate.filter(
          (id) => id !== movementId
        ),
      })
    ),
    on(
      updateMovementFailure,
      (state: ProceduresUiState, { movementId }): ProceduresUiState => ({
        ...state,
        movementsInUpdate: state.movementsInUpdate.filter(
          (id) => id !== movementId
        ),
      })
    ),

    // Movement deletion

    on(
      selectMovementForDeletion,
      (
        state: ProceduresUiState,
        { movementId, occurrenceId }
      ): ProceduresUiState => ({
        ...state,
        selectedMovementForDeletion: { movementId, occurrenceId },
      })
    ),
    on(
      abortMovementDeletion,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        selectedMovementForDeletion: undefined,
      })
    ),

    on(
      deleteMovement,
      (state: ProceduresUiState, { movementId }): ProceduresUiState => ({
        ...state,
        movementsInDeletion: [...state.movementsInDeletion, movementId],
      })
    ),
    on(
      deleteMovementSuccess,
      (state: ProceduresUiState, { movementId }): ProceduresUiState => ({
        ...state,
        movementsInDeletion: state.movementsInDeletion.filter(
          (id) => id !== movementId
        ),
      })
    ),
    on(
      deleteMovementFailure,
      (state: ProceduresUiState, { movementId }): ProceduresUiState => ({
        ...state,
        movementsInDeletion: state.movementsInDeletion.filter(
          (id) => id !== movementId
        ),
      })
    ),

    on(
      deleteProcedureSuccess,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        selectedOccurrenceId: undefined,
      })
    ),

    // Create new

    on(
      showCreateNewProcedureModal,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        showCreateNewModal: true,
      })
    ),

    on(
      hideCreateNewProcedureModal,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        showCreateNewModal: false,
      })
    ),

    on(
      createProcedure,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        isCreatingNew: true,
      })
    ),
    on(
      createProcedureSuccess,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        isCreatingNew: false,
        showCreateNewModal: false,
      })
    ),
    on(
      createProcedureFailure,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        isCreatingNew: false,
      })
    ),

    on(
      showProcedureLegendState,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        showProceduresLegendStateModal: true,
      })
    ),

    on(
      hideProcedureLegendState,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        showProceduresLegendStateModal: false,
      })
    ),
    on(
      changeProcedureTab,

      (state: ProceduresUiState, { tab }): ProceduresUiState => {
        if (tab === 0) {
          return {
            ...state,
            tabIndex: tab,
            selectedOccurrenceId: undefined,
            selectedProcedureOccurrenceInTable: undefined,
            isTabChanging: undefined,
          };
        } else {
          return {
            ...state,
            tabIndex: tab,
          };
        }
      }
    ),
    on(
      selectedProcedureOccurrenceFromTimeline,
      (
        state: ProceduresUiState,
        { selectedOccurrenceId }
      ): ProceduresUiState => ({
        ...state,
        tabIndex: 1,
        selectedOccurrenceId,
        selectedProcedureOccurrenceInTable: false,
        currentExecutionData: undefined,
        currentProgrammingData: undefined,
        currentTerminationData: undefined,
        isTabChanging: state.tabIndex !== 1,
      })
    ),

    on(
      saveProcedureProgrammingStepSuccess,
      (
        state: ProceduresUiState,
        { data }: { data: ProceduresProgrammingFormData }
      ): ProceduresUiState => ({
        ...state,
        currentProgrammingData: data,
      })
    ),

    on(
      saveProcedureExecutionStepSuccess,
      (
        state: ProceduresUiState,
        { data }: { data: ProceduresExecutionFormData }
      ): ProceduresUiState => ({
        ...state,
        currentExecutionData: data,
      })
    ),

    on(
      saveProcedureTerminationStepSuccess,
      (
        state: ProceduresUiState,
        { data }: { data: ProceduresTerminationFormData }
      ): ProceduresUiState => ({
        ...state,
        currentTerminationData: data,
      })
    ),

    on(
      deleteProcedureSuccess,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        currentTerminationData: undefined,
        currentExecutionData: undefined,
        currentProgrammingData: undefined,
      })
    ),

    on(
      stopProcedureRunSuccess,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        currentTerminationData: undefined,
        currentExecutionData: undefined,
      })
    ),

    on(
      cancelProcedureExecutionSuccess,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        currentTerminationData: undefined,
      })
    ),

    on(
      clearProcedureProgrammingTemporaryFormData,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        currentProgrammingData: undefined,
      })
    ),

    on(
      clearProcedureExecutionTemporaryFormData,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        currentExecutionData: undefined,
      })
    ),

    on(
      clearProcedureTerminationTemporaryFormData,
      (state: ProceduresUiState): ProceduresUiState => ({
        ...state,
        currentTerminationData: undefined,
      })
    ),

    on(
      loadProcedureDevicesSuccess,
      (state: ProceduresUiState, { devices }): ProceduresUiState => ({
        ...state,
        availableDevices: devices,
      })
    ),

    on(
      loadProcedureRoutesSuccess,
      (state: ProceduresUiState, { routes }): ProceduresUiState => ({
        ...state,
        availableRoutes: routes,
      })
    ),

    on(
      loadProcedureLocationsSuccess,
      (state: ProceduresUiState, { locations }): ProceduresUiState => ({
        ...state,
        availableLocations: locations,
      })
    )
  );
