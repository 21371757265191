import {
  MultisectionItemIconConfig,
  MultisectionItemIconState,
} from '../../../../shared/components/multi-section-timeline/models/multi-section-timeline-instance';
import { TherapyOccurrenceState } from '../entities/therapy-occurrence';

export const therapiesGetOccurrenceState = (
  occurrenceState: TherapyOccurrenceState
): MultisectionItemIconConfig | undefined => {
  switch (occurrenceState) {
    case TherapyOccurrenceState.Prepared:
      return { name: 'check', state: MultisectionItemIconState.Success };
    case TherapyOccurrenceState.Administered:
      return { name: 'double-check', state: MultisectionItemIconState.Success };
    case TherapyOccurrenceState.Prescribed:
      return { name: 'pause', state: MultisectionItemIconState.Neutral };
    case TherapyOccurrenceState.InExecution:
      return { name: 'play', state: MultisectionItemIconState.Neutral }; // Should not be possible for instant occurrences
    case TherapyOccurrenceState.Aborted:
      return { name: 'cross-bold', state: MultisectionItemIconState.Error };
    case TherapyOccurrenceState.Suspended:
      return { name: 'hand-stop', state: MultisectionItemIconState.Error };
    default:
      console.error(
        `Unexpected occurrence state ${occurrenceState} for instant occurrence`
      );
      return undefined;
  }
};
