import { Sort } from '@angular/material/sort';
import { TranslocoService } from '@ngneat/transloco';
import { OptionsItem } from 'natea-components';
import { PatientData } from '../../features/patients/store/patients.reducers';
import { PatientProblem } from '../../features/problems/interfaces/patient-problem';
import { ProblemType } from '../../features/problems/interfaces/problem-type';
import { ProblemsFilter } from '../../features/problems/interfaces/problems-filter';
import { ProfessionalCategory } from '../entities/professional-category';
import { UserRole, shownName } from '../entities/user';
import {
  CategoryTranslationMap,
  getCategoryTranslationsMap,
} from './translation-utils';
import { compareDates } from './utils';

export const createProblemsFilterCallback =
  (filter: ProblemsFilter) =>
  (problem: PatientProblem): boolean => {
    if (!filter.showClosed && problem.endDate) {
      return false;
    }
    if (!filter.showOpened && !problem.endDate) {
      return false;
    }
    if (
      !filter.showMedical &&
      problem.problemClass === ProfessionalCategory.Medical
    ) {
      return false;
    }
    if (
      !filter.showNursing &&
      problem.problemClass === ProfessionalCategory.Nursing
    ) {
      return false;
    }
    return true;
  };

export const createProblemComparator = (
  transloco: TranslocoService,
  sort?: Sort
) => {
  if (!sort || sort?.direction === '') {
    return () => 0;
  }
  const categoryTranslationsMap: CategoryTranslationMap =
    getCategoryTranslationsMap(transloco);
  return (a: PatientProblem, b: PatientProblem): number => {
    const modifier: number = sort.direction === 'asc' ? 1 : -1;

    switch (sort.active) {
      case 'endDate':
        return compareDates(a.endDate, b.endDate, new Date()) * modifier;

      case 'insertDate':
        return compareDates(a.insertDate, b.insertDate, new Date()) * modifier;

      case 'startDate':
        return compareDates(a.startDate, b.startDate) * modifier;

      case 'problemDescription':
        return (
          (a.problemDescription ?? '').localeCompare(
            b.problemDescription ?? ''
          ) * modifier
        );

      case 'userInsertId':
        return (
          shownName(a.createdBy).localeCompare(shownName(b.createdBy)) *
          modifier
        );

      case 'problemType':
        const classA: string =
          categoryTranslationsMap.get(a.problemClass) ?? '';
        const classB: string =
          categoryTranslationsMap.get(b.problemClass) ?? '';

        return classA.localeCompare(classB) * modifier;
      default:
        return 0;
    }
  };
};

export const createPatientsComparator = (
  transloco: TranslocoService,
  sort?: Sort
): ((a: PatientData, b: PatientData) => number) => {
  if (!sort || !sort.active || !sort.direction) {
    return () => 0;
  }
  return (a: PatientData, b: PatientData): number => {
    const modifier: number = sort.direction === 'asc' ? 1 : -1;
    const patientNameTranslateId = 'patient.list.patientName';
    switch (sort.active) {
      case 'patientName':
        return (
          transloco
            .translate(patientNameTranslateId, a)
            .localeCompare(transloco.translate(patientNameTranslateId, b)) *
          modifier
        );
      case 'birthDate':
        return compareDates(a.birthDate, b.birthDate) * modifier;
      case 'patientWeight':
        return (
          ((a.measurements?.weight ?? 0) - (b.measurements?.weight ?? 0)) *
          modifier
        );
      case 'gestationalAge':
        return ((a.gestationalAge ?? 0) - (b.gestationalAge ?? 0)) * modifier;
      case 'encounterStartDate':
        return (
          compareDates(a.encounterStartDate, b.encounterStartDate) * modifier
        );
      case 'currentBed':
        return (
          (a.currentBed ?? '').localeCompare(b.currentBed ?? '') * modifier
        );
      default:
        return 0;
    }
  };
};

export const optionItemFromProblemType = (
  problemType: ProblemType
): OptionsItem<ProblemType> => ({
  id: problemType.id,
  label: problemType.name,
  data: problemType,
});

export const optionItemFromEntityClass = (
  category: ProfessionalCategory,
  translocoService: TranslocoService
): OptionsItem<ProfessionalCategory> => {
  return {
    id: category,
    label: translocoService.translate(
      'problems.problemType.' +
        (category === ProfessionalCategory.Medical ? 'medical' : 'nurse')
    ),
    data: category,
  };
};

export const problemClassFromUserRole = (
  role?: UserRole
): ProfessionalCategory | undefined => {
  switch (role) {
    case UserRole.Doctor:
    case UserRole.DoctorStudent:
      return ProfessionalCategory.Medical;
    case UserRole.Nurse:
      return ProfessionalCategory.Nursing;
    default:
      return undefined;
  }
};
