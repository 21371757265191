/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import { UserRole } from '../../shared/entities/user';
import { ConfigService } from '../config/config.service';
import {
  BASE_PATH,
  ClinicalNoteApiService,
  ClinicalNoteAssessRequestResource,
  ClinicalNoteCreateRequestResource,
  Configuration,
  GeneralResponseNoteDeletedResource,
  GeneralResponseNoteResource,
  NotePermissionResource,
  NotePutRequest,
  SearchResponseListNoteResource,
  UserInfoResource,
} from '../generated/api';
import { generateRandomNumber } from './create-new-item-storage-mocked';
import { createClinicalNotesMock } from './get-clinical-notes-mocked-response';
import { problemsNames } from './get-detected-problems-mock';
import updateClinicalNoteMockedResponse from './update-clinical-note-mocked-response';

@Injectable({
  providedIn: 'root',
})
export class MockedClinicalNoteApiService extends ClinicalNoteApiService {
  constructor(
    protected override httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration,
    private config: ConfigService
  ) {
    super(httpClient, basePath, configuration);
  }

  userInfo: UserInfoResource = {
    id: generateRandomNumber().toString(),
    firstName: genereRandomFirstName(),
    lastName: genereRandomLastName(),
  };

  userForDemo = {
    id: 'demo-id',
    firstName: 'Maurizio',
    lastName: 'verdi',
    role: UserRole.Doctor,
  };

  permissions: NotePermissionResource = {
    canAssess: true,
    canUpdate: true,
    canDelete: true,
  };
  override assessClinicalNote(
    noteId: string,
    noteAssessRequest: ClinicalNoteAssessRequestResource
  ): Observable<any> {
    const result: GeneralResponseNoteResource = {
      payload: {
        createdBy: this.userForDemo,
        createdAt: new Date().toISOString(),
        encounterId: '',
        id: noteId,
        permission: this.permissions,
        status: noteAssessRequest.isApproved ? 'valid' : 'rejected',
        additionalNotes: '',
        observedAt: new Date().toISOString(),
        type: 'M',
        version: 1,
        rejectionReason: noteAssessRequest.rejectionReason ?? '',
      },
    };
    return of(result).pipe(delay(2000));
  }

  public override getClinicalNotes(
    patientId: string,
    encounterId: string
  ): Observable<any> {
    const result: SearchResponseListNoteResource = {
      payload: createClinicalNotesMock(
        patientId,
        encounterId,
        // this.config.mockClinicalNoteCount
        5 // RLE TBD
      ),
    };
    return of(result);
  }

  public override putNote(
    noteId: string,
    notePutRequest: NotePutRequest
  ): Observable<any> {
    updateClinicalNoteMockedResponse(noteId, notePutRequest);
    return of({}).pipe(delay(2000));
  }

  public override deleteNote(noteId: string): Observable<any> {
    const result: GeneralResponseNoteDeletedResource = {};
    return of(result);
  }

  public override createClinicalNoteForPatientEncounter(
    patientId: string,
    encounterId: string,
    createNoteResourceRequest: ClinicalNoteCreateRequestResource
  ): Observable<any> {
    const findProblemById = problemsNames.find(
      (problem) => problem.id === createNoteResourceRequest.detectedProblemId
    );
    const result: GeneralResponseNoteResource = {
      payload: {
        createdBy: this.userForDemo,
        createdAt: new Date().toISOString(),
        encounterId: patientId,
        id: createNoteResourceRequest.detectedProblemId ?? 'test',
        assessedAt: new Date().toISOString(),
        permission: this.permissions,
        status: 'approved',
        additionalNotes: createNoteResourceRequest.additionalNotes,
        observedAt: createNoteResourceRequest.observedAt,
        type: createNoteResourceRequest.type === 'M' ? 'M' : 'I',
        version: 1,
        rejectionReason: 'test',
        detectedProblem: {
          id: createNoteResourceRequest.detectedProblemId ?? '',
          createdAt: new Date().toISOString(),
          problemCatalogEntry: {
            id: createNoteResourceRequest.detectedProblemId ?? '',
            name: findProblemById
              ? findProblemById.name
              : 'distress respiratorio',
            isEnabled: true,
            problemClass: 'M',
          },
          patientId: patientId,
          createdBy: this.userInfo,
          permissions: this.permissions,
          startDate: new Date().toISOString(),
        },
      },
    };
    return of(result).pipe(delay(1000));
  }
}

export function genereRandomFirstName(): string {
  const firstNames = ['Luca', 'Giulia', 'Alessio', 'Sofia', 'Marco', 'Elena'];

  const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];

  return firstName;
}

export function genereRandomLastName(): string {
  const lastNames = ['Rossi', 'Bianchi', 'Ferrari', 'Ricci', 'Conti', 'Marini'];

  const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];

  return lastName;
}
