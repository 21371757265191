import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TherapiesUiState } from './therapies.reducer';

export const selectTherapies =
  createFeatureSelector<TherapiesUiState>('therapiesUi');

export const selectTherapiesShowLegendModal = createSelector(
  selectTherapies,
  (state: TherapiesUiState): boolean => state.showLegendModal
);

export const selectTherapiesShowSecondHeaderLine = createSelector(
  selectTherapies,
  (state: TherapiesUiState): boolean => state.showSecondHeaderLine
);
