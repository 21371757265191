import { OptionsItem } from 'natea-components';
import { MultiSectionTimelineInstance } from '../../../../shared/components/multi-section-timeline/models/multi-section-timeline-instance';
import { TherapiesTableItem } from '../../common/entities/therapies-table-item';
import { TherapyOccurrence } from '../../common/entities/therapy-occurrence';
import { mapTherapyPrescriptionToTimelineOccurrence } from '../../common/utils/therapy-timeline-mapper';
import { InstrumentalTherapy } from '../entities/instrumental-therapy';
import { InstrumentalTherapyType } from '../entities/instrumental-therapy-type';

export const mapInstumentalTherapiesToTimelineItems = (
  therapies: InstrumentalTherapy[]
): MultiSectionTimelineInstance<TherapyOccurrence>[] => {
  const typesMap = new Map<string, InstrumentalTherapyType>();
  const therapiesMap = new Map<string, InstrumentalTherapy[]>();

  therapies?.forEach((therapy) => {
    const therapyId = therapy.type.id;
    if (!therapiesMap.has(therapyId)) {
      therapiesMap.set(therapyId, [therapy]);
      typesMap.set(therapyId, therapy.type);
    } else {
      therapiesMap.get(therapyId)?.push(therapy);
    }
  });
  return Array.from(therapiesMap.keys()).map((typeId) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const type = typesMap.get(typeId)!;
    return {
      id: typeId,
      label: type.name,
      occurrences:
        therapiesMap.get(typeId)?.map((therapy) => {
          const result = mapTherapyPrescriptionToTimelineOccurrence(therapy);
          return result;
        }) ?? [],
    };
  });
};

export const mapInstrumentalTherapyOccurrenceToTableItem = (
  occurrence: TherapyOccurrence,
  therapy: InstrumentalTherapy
): TherapiesTableItem => ({
  id: occurrence.id,
  therapyId: therapy.id,
  durationType: occurrence.durationType,
  state: occurrence.state,
  programmingDate: occurrence.programmingDate,
  executionDate: occurrence.executionDate,
  prescriptionDate: therapy.prescriptionDate,
  createdBy: therapy.createdBy,
  name: therapy.type.name,
});

export const optionItemFromInstrumentalTherapyType = (
  type: InstrumentalTherapyType
): OptionsItem<InstrumentalTherapyType> => ({
  id: type.id,
  label: type.name,
  data: type,
});
