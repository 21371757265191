import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService } from 'natea-components';
import { catchError, delay, exhaustMap, map, of } from 'rxjs';
import {
  ApiError,
  errorMessage,
  localError,
} from '../../../../shared/entities/errors';
import { SNACK_BAR_DEBOUNCE_TIME_MS } from '../../../../shared/utils/constants';
import { selectOngoingEncounterAndPatientIds } from '../../../patients/store/patients.selectors';
import { InstrumentalTherapyFormData } from '../form/instrumental-therapy-form-data';
import { instrumentalTherapiesListMock } from '../mocks/instrumental-therapy.mocks';
import { InstrumentalTherapiesWebAPI } from '../webapis/instrumental-therapies.webapi';
import {
  InstrumentalTherapiesActions,
  confirmAbortInstrumentalTherapySuccess,
  confirmDeleteInstrumentalTherapyFailure,
  confirmDeleteInstrumentalTherapySuccess,
  createInstrumentalTherapyFailure,
  createInstrumentalTherapySuccess,
  downloadInstrumentalTherapyTypesFailure,
  downloadInstrumentalTherapyTypesSuccess,
  editInstrumentalTherapyFailure,
  editInstrumentalTherapySuccess,
  instrumentalTherapySuspensionFailure,
  instrumentalTherapySuspensionSuccess,
  loadInstrumentalTherapiesFailure,
  loadInstrumentalTherapiesSuccess,
} from './instrumental-therapies.actions';
import {
  selectInstrumentalTherapyCandidateForDeletion,
  selectInstrumentalTherapyCandidateForSuspension,
  selectIsInstrumentalTherapySelected,
} from './instrumental-therapies.selectors';

@Injectable()
export class InstrumentalTherapiesEffects {
  load$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InstrumentalTherapiesActions.LoadInstrumentalTherapies),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      exhaustMap(([, onGoingEncounterIds]) =>
        onGoingEncounterIds
          ? of(
              loadInstrumentalTherapiesSuccess({
                patientId: onGoingEncounterIds?.patientId,
                encounterId: onGoingEncounterIds?.encounterId,
                therapies: instrumentalTherapiesListMock(), // TODO real data
              })
            ).pipe(delay(100))
          : of(
              loadInstrumentalTherapiesFailure({
                error: localError(HttpStatusCode.BadRequest),
              })
            )
      ),
      catchError((error: ApiError) =>
        of({
          type: InstrumentalTherapiesActions.LoadInstrumentalTherapiesFailure,
          error,
        })
      )
    );
  });

  loadInstrumentalTherapiesFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(InstrumentalTherapiesActions.LoadInstrumentalTherapiesFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.loadTherapiesFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  downloadInstrumentalTherapiesFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(InstrumentalTherapiesActions.LoadInstrumentalTherapiesFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.loadTherapiesFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /******************** TYPES  ********************/

  loadInstrumentalTherapiesTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InstrumentalTherapiesActions.DownloadTypes),
      exhaustMap(() =>
        this.api.downloadTypes().pipe(
          map((types) => downloadInstrumentalTherapyTypesSuccess({ types })),
          catchError((error: ApiError) =>
            of(downloadInstrumentalTherapyTypesFailure({ error }))
          )
        )
      )
    );
  });

  loadInstrumentalTherapiesTypesFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(InstrumentalTherapiesActions.DownloadTypesFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.loadTherapiesTypesFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /************************ CREATE THERAPY ************************/

  createTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InstrumentalTherapiesActions.CreateInstrumentalTherapy),
      concatLatestFrom(() => {
        return this.store.select(selectOngoingEncounterAndPatientIds);
      }),
      exhaustMap(([{ therapy }, onGoingEncounter]) => {
        return this.api.createInstrumentalTherapy(therapy).pipe(
          map((therapyResult) => {
            if (!onGoingEncounter) {
              return createInstrumentalTherapyFailure({
                error: localError(HttpStatusCode.BadRequest),
              });
            }

            return createInstrumentalTherapySuccess({
              therapy: therapyResult,
              patientId: onGoingEncounter.patientId,
              encounterId: onGoingEncounter.encounterId,
            });
          }),
          catchError((error: ApiError) => {
            return of(createInstrumentalTherapyFailure({ error }));
          })
        );
      }),
      catchError((error: ApiError) => {
        return of(createInstrumentalTherapyFailure({ error }));
      })
    );
  });

  createTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(InstrumentalTherapiesActions.CreateInstrumentalTherapySuccess),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.createTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  createTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(InstrumentalTherapiesActions.CreateInstrumentalTherapyFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.createTherapyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /************************ EDIT THERAPY ************************/

  editTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: InstrumentalTherapiesActions.EditInstrumentalTherapy;
        therapyId: string;
        occurrenceId: string;
        therapy: InstrumentalTherapyFormData;
      }>(InstrumentalTherapiesActions.EditInstrumentalTherapy),
      concatLatestFrom(() => {
        return [
          this.store.select(selectOngoingEncounterAndPatientIds),
          this.store.select(selectIsInstrumentalTherapySelected),
        ];
      }),
      exhaustMap(([{ therapy, occurrenceId, therapyId }, onGoingEncounter]) => {
        return this.api
          .editInstrumentalTherapy(therapy, occurrenceId, therapyId)
          .pipe(
            map((therapyResult) => {
              if (!onGoingEncounter) {
                return editInstrumentalTherapyFailure({
                  error: localError(HttpStatusCode.BadRequest),
                });
              }

              return editInstrumentalTherapySuccess({
                therapy: therapyResult,
                patientId: onGoingEncounter.patientId,
                encounterId: onGoingEncounter.encounterId,
              });
            }),
            catchError((error: ApiError) => {
              return of(editInstrumentalTherapyFailure({ error }));
            })
          );
      }),
      catchError((error: ApiError) => {
        return of(editInstrumentalTherapyFailure({ error }));
      })
    );
  });

  editTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(InstrumentalTherapiesActions.EditInstrumentalTherapySuccess),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.editTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  editTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(InstrumentalTherapiesActions.EditInstrumentalTherapyFailure),
        map((error: ApiError) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.editTherapyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /************************ DELETE THERAPY ************************/

  deleteTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InstrumentalTherapiesActions.ConfirmDeleteInstrumentalTherapy),
      concatLatestFrom(() => [
        this.store.select(selectInstrumentalTherapyCandidateForDeletion),
        this.store.select(selectOngoingEncounterAndPatientIds),
      ]),
      exhaustMap(([, therapy, onGoingEncounter]) => {
        if (!therapy) {
          return of(
            instrumentalTherapySuspensionFailure({
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }
        return this.api
          .deleteInstrumentalTherapyPrescription(therapy?.id, therapy.patientId)
          .pipe(
            map(() => {
              return confirmDeleteInstrumentalTherapySuccess({
                therapyId: therapy.id,
                patientId: onGoingEncounter?.patientId ?? '',
                encounterId: onGoingEncounter?.encounterId ?? '',
              });
            }),
            catchError((error: ApiError) => {
              return of(confirmDeleteInstrumentalTherapyFailure({ error }));
            })
          );
      }),
      catchError((error: ApiError) => {
        return of(confirmDeleteInstrumentalTherapyFailure({ error }));
      })
    );
  });

  deleteTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          InstrumentalTherapiesActions.ConfirmDeleteInstrumentalTherapySuccess
        ),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.deleteInstrumentalTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  deletePrescriptionInstrumentalTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          InstrumentalTherapiesActions.ConfirmDeleteInstrumentalTherapyFailure
        ),
        map(
          ({
            error,
            therapyId,
          }: {
            error: ApiError | undefined;
            therapyId: string | undefined;
          }) => {
            console.error(
              'Error in deleting therapy: ',
              error,
              'therapy: ',
              therapyId
            );

            const message = error ? errorMessage(error) : undefined;

            return this.snackBar.showSnackbar(
              message ??
                this.translocoService.translate(
                  'therapies.notifications.deleteInstrumentalTherapyFailure'
                ),
              this.translocoService.translate('common.buttons.close'),
              'error-snackbar',
              SNACK_BAR_DEBOUNCE_TIME_MS
            );
          }
        )
      );
    },
    { dispatch: false }
  );

  /************************ SUSPEND THERAPY ************************/

  suspendTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: InstrumentalTherapiesActions.ConfirmSuspension;
        motivation: string;
      }>(InstrumentalTherapiesActions.ConfirmSuspension),
      concatLatestFrom(() => [
        this.store.select(selectOngoingEncounterAndPatientIds),
        this.store.select(selectInstrumentalTherapyCandidateForSuspension),
      ]),
      exhaustMap(([{ motivation }, onGoingEncounter, therapy]) => {
        if (!therapy || !onGoingEncounter) {
          return of(
            instrumentalTherapySuspensionFailure({
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }
        return this.api
          .suspendInstrumentalTherapyPrescription(therapy, motivation)
          .pipe(
            map((therapyResult) => {
              return instrumentalTherapySuspensionSuccess({
                therapy: therapyResult,
                patientId: onGoingEncounter.patientId,
                encounterId: onGoingEncounter.encounterId,
              });
            }),
            catchError((error: ApiError) => {
              return of(instrumentalTherapySuspensionFailure({ error }));
            })
          );
      }),
      catchError((error: ApiError) => {
        return of(instrumentalTherapySuspensionFailure({ error }));
      })
    );
  });

  suspendTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(InstrumentalTherapiesActions.SuspensionSuccess),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.suspendTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  suspendTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(InstrumentalTherapiesActions.SuspensionFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.suspendTherapyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /************************ ABORT THERAPY ************************/

  abortTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InstrumentalTherapiesActions.ConfirmAbortInstrumentalTherapy),
      concatLatestFrom(() => [
        this.store.select(selectOngoingEncounterAndPatientIds),
        this.store.select(selectInstrumentalTherapyCandidateForDeletion),
      ]),
      exhaustMap(([motivation, onGoingEncounter, therapy]) => {
        if (!therapy || !onGoingEncounter) {
          return of(
            instrumentalTherapySuspensionFailure({
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }
        return this.api
          .abortInstrumentalTherapyPrescription({
            prescription: therapy,
            patientId: onGoingEncounter.patientId,
            encounterId: onGoingEncounter.encounterId,
            motivation,
          })
          .pipe(
            map((therapyResult) => {
              return confirmAbortInstrumentalTherapySuccess({
                therapy: therapyResult,
                patientId: onGoingEncounter.patientId,
                encounterId: onGoingEncounter.encounterId,
              });
            }),
            catchError((error: ApiError) => {
              return of(instrumentalTherapySuspensionFailure({ error }));
            })
          );
      })
    );
  });

  abortTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          InstrumentalTherapiesActions.ConfirmAbortInstrumentalTherapySuccess
        ),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.abortInstrumentalTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },

    { dispatch: false }
  );

  abortTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          InstrumentalTherapiesActions.ConfirmAbortInstrumentalTherapyFailure
        ),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.abortInstrumentalTherapyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /******************** CONSTRUCTOR  ********************/

  constructor(
    private actions$: Actions,
    private store: Store,
    private snackBar: SnackbarService,
    private translocoService: TranslocoService,
    private api: InstrumentalTherapiesWebAPI
  ) {}
}
