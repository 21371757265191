/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a deleted detected problem resource
 */
export interface DetectedProblemDeletedResource { 
    /**
     * Unique identifier of the deleted detected problem
     */
    id: string;
    /**
     * Timestamp of when the deleted detected problem was initially started
     */
    startDate: string;
}

