<natea-base-modal
  *transloco="let t; read: 'log'"
  [isComplexHeader]="true"
  (closeModal)="onCloseModal()"
  [modalSize]="modalSize"
  [secondary]="true"
>
  <h1 complex-header>
    <div class="title">
      <span class="log">{{ t("log") }}</span> - <span>{{ logTitle }}</span>
    </div>

    <div>
      <natea-icons
        name="close"
        class="icon"
        buttonType="button"
        (iconClick)="onCloseModal()"
      ></natea-icons>
    </div>
  </h1>

  <section>
    <div class="container-cards">
      <div class="date-and-type-table">
        <ng-container *ngrxLet="dataSource$ as dataSource">
          <table
            *transloco="let t; read: 'log'"
            matSort
            mat-table
            [dataSource]="dataSource"
            [matSortActive]="activeSort.active"
            [matSortDirection]="activeSort.direction"
            (matSortChange)="onSortChanged($event)"
          >
            <caption>
              {{
                t("logCaption")
              }}
            </caption>

            <ng-container matColumnDef="date">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="date-header"
              >
                <div class="date-header">{{ t("date") }}</div>
              </th>
              <td mat-cell *matCellDef="let row">
                <div class="date-cell">
                  {{ row.date | localizedDate }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="type-header">{{ t("type") }}</div>
              </th>
              <td mat-cell *matCellDef="let row">
                <div class="type-cell">
                  {{ row.type }}
                </div>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="columnsToDisplay; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: columnsToDisplay; let index = index"
              [class.selected]="row === selectedEntity"
              (click)="selectEntity(row)"
            ></tr>
          </table>
        </ng-container>
      </div>

      <div class="log-data">
        <div *ngFor="let field of fields">
          <span>{{ field.key }}</span
          >: <span [class.update-value]="field.changed">{{ field.value }}</span>
        </div>
      </div>
    </div>

    <footer>
      <natea-button
        [label]="t('close')"
        (buttonClick)="onCloseModal()"
        buttonType="button"
      ></natea-button>
    </footer>
  </section>
</natea-base-modal>
