<section>
  <p *ngFor="let texts of textsAndOptionalIcons">
    <span *ngIf="texts.iconName">
      <natea-icons
        [name]="texts.iconName"
        class="icon"
        [title]="texts.iconTitle"
        [fill]="texts.iconColor || defaultColor"
      ></natea-icons>
    </span>
    <span class="text" [class.hasIcon]="!!texts.iconName">{{
      texts.text
    }}</span>
  </p>
</section>
