<ng-container *transloco="let t; read: 'problems.patientProblem'">
  <natea-base-modal
    [modalTitle]="t('deleteProblem')"
    (closeModal)="closeModal.emit()"
    *ngIf="showModal"
    [modalSize]="modalSize"
  >
    <div class="modal-body">
      <span>
        {{
          t("deleteProblemConfirmation", {
            problemDescription: problem.problemType.name
          })
        }}
      </span>
      <span> {{ t("relativeTo", { pazienteName: pazienteShownName }) }}</span>
    </div>

    <footer class="delete-footer">
      <natea-button
        [label]="t('delete')"
        [color]="buttonVariants.PRIMARY"
        [disabled]="isDeletingProblem"
        (buttonClick)="deleteAndCloseModal.emit()"
      ></natea-button>
      <natea-button
        class="cancel-button"
        [label]="t('cancel')"
        [disabled]="isDeletingProblem"
        [color]="buttonVariants.SECONDARY"
        (buttonClick)="closeModal.emit()"
      ></natea-button>
    </footer>
  </natea-base-modal>
</ng-container>
