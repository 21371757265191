import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SwitchItem, matchMediaDesktopSize } from 'natea-components';

@Component({
  selector: 'natea-cc-problems-section-header',
  templateUrl: './problems-section-header.component.html',
  styleUrls: ['./problems-section-header.component.scss'],
})
export class ProblemsSectionHeaderComponent implements OnInit, OnDestroy {
  @Input() selectedSection: SwitchItem = 'left';

  @Input() startDate?: Date;
  @Input() endDate?: Date;

  @Output() addProblem: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleSection: EventEmitter<SwitchItem> =
    new EventEmitter<SwitchItem>();
  @Output() moveToDate: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() showInfo: EventEmitter<void> = new EventEmitter<void>();

  public isExpanded = false;
  public isDesktopSized = false;

  private desktopSizeMatcher: MediaQueryList = matchMediaDesktopSize(window);

  ngOnInit(): void {
    this.isDesktopSized = this.desktopSizeMatcher.matches;
    this.desktopSizeMatcher.addEventListener(
      'change',
      this.onDesktopSizeChanged
    );
  }
  ngOnDestroy(): void {
    this.desktopSizeMatcher.removeEventListener(
      'change',
      this.onDesktopSizeChanged
    );
  }

  onDesktopSizeChanged = (event: MediaQueryListEvent): void => {
    this.isDesktopSized = event.matches;
  };

  onSelectedSectionChanged = (item: SwitchItem): void => {
    this.toggleSection.emit(item);
  };

  onCreateNewProblem(): void {
    this.addProblem.emit();
  }

  onDatePicked(date: Date | null): void {
    if (date) {
      this.moveToDate.emit(date);
    }
  }

  toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
  }
}
