import { OptionsItem } from 'natea-components';
import { MultisectionTimelineOccurrence } from '../../../../shared/components/multi-section-timeline/models/multi-section-timeline-instance';
import { TherapiesTableItem } from '../../common/entities/therapies-table-item';
import { TherapyOccurrence } from '../../common/entities/therapy-occurrence';
import { tableIconForTherapyInstantOccurrence } from '../../common/utils/therapy-icon-mapper';
import { mapTherapyPrescriptionToTimelineOccurrence } from '../../common/utils/therapy-timeline-mapper';
import { PharmacologicalTherapy } from '../entities/pharmacological-therapy';
import {
  PharmacologicalTherapyMode,
  PharmacologicalTherapyType,
} from '../entities/pharmacological-therapy-type';

export const mapPharmacologicalTherapyPrescriptionToTimelineOccurrence = (
  prescription: PharmacologicalTherapy
): MultisectionTimelineOccurrence<TherapyOccurrence> => {
  const atNeedData: {
    rangeStartDate?: Date;
    rangeEndDate?: Date;
  } = {};
  if (prescription.administerAtNeed && prescription.forDays > 0) {
    atNeedData.rangeStartDate = prescription.programmingDate;
    atNeedData.rangeEndDate = new Date(
      prescription.programmingDate.getTime() +
        prescription.forDays * 24 * 60 * 60 * 1000
    );
  }
  return {
    ...atNeedData,
    ...mapTherapyPrescriptionToTimelineOccurrence(prescription),
  };
};

export const mapPharmacologicalTherapyOccurrenceToTableItem = (
  occurrence: TherapyOccurrence,
  therapy: PharmacologicalTherapy
): TherapiesTableItem => ({
  id: occurrence.id,
  therapyId: therapy.id,
  name: therapy.therapyType.description,
  prescriptionDate: therapy.prescriptionDate,
  executionDate: occurrence.executionDate,
  programmingDate: occurrence.programmingDate,
  durationType: occurrence.durationType,
  state: occurrence.state,
  createdBy: therapy.createdBy,
  icon: tableIconForTherapyInstantOccurrence(
    occurrence.state,
    occurrence.programmingDate
  ),
});

export const optionItemFromPharmacologicalTherapyType = (
  type: PharmacologicalTherapyType
): OptionsItem<PharmacologicalTherapyType> => ({
  id: type.id,
  label: type.name,
  data: type,
});

export const optionItemFromPharmacologicalTherapyMode = (
  mode: PharmacologicalTherapyMode
): OptionsItem<PharmacologicalTherapyMode> => ({
  id: mode.id,
  label: mode.name,
  data: mode,
});
