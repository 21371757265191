/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Request object for assessing a clinical note.
 */
export interface ClinicalNoteAssessRequestResource { 
    /**
     * Indicates whether the clinical note has been approved or not.
     */
    isApproved: boolean;
    /**
     * The reason for rejection of the clinical note, if applicable.
     */
    rejectionReason?: string;
}

