import { OptionsItem } from 'natea-components';
import {
  MultisectionTimelineInstantOccurrence,
  MultisectionTimelineProlongedOccurrence,
} from '../../../shared/components/multi-section-timeline/models/multi-section-timeline-instance';
import { User } from '../../../shared/entities/user';
import { ProblemType } from '../../problems/interfaces/problem-type';
import { Procedure } from './procedure';
import {
  ProcedureDevice,
  ProcedureLocation,
  ProcedureRoute,
} from './procedure-data';
import { ProcedureOccurrenceMovement } from './procedure-movement';

export enum ProcedureOccurrenceState {
  Planned = 0,
  Running = 1,
  Executed = 2,
}

/**
 * Information common to the occurrences of both types (instant and prolonged)
 */
export interface ProcedureOccurrenceBase {
  id: string;
  state: ProcedureOccurrenceState;
  procedure: Procedure;
  problem: ProblemType;
  prescriptionDate: Date | null;
  programmingDate: Date | null;
  movements?: ProcedureOccurrenceMovement[];
  adverseEvents: string | null;
  executor: OptionsItem<User> | null;
  device: ProcedureDevice | null;
  lot: string | null;
  insertion: ProcedureRoute | null;
  place: ProcedureLocation | null;
  insertionLength: number | null;
  notes: string | null;
  programmingStepChanged?: boolean;
  frSize: number | null;
}

export interface ProcedureInstantOccurrence
  extends MultisectionTimelineInstantOccurrence,
    ProcedureOccurrenceBase {}

export interface ProcedureProlongedOccurrence
  extends MultisectionTimelineProlongedOccurrence,
    ProcedureOccurrenceBase {}

export type ProcedureOccurrence =
  | ProcedureInstantOccurrence
  | ProcedureProlongedOccurrence;
