import { createAction, props } from '@ngrx/store';
import { ApiError } from '../../../shared/entities/errors';
import { GetDomainsResponse } from '../webapis/domains.webapi';

export enum DomainsActions {
  DownloadCoreDomainsAndCatalogs = '[Domains] Download core domains and catalogs',
  DownloadCoreDomainsAndCatalogsSuccess = '[Domains] Download core domains and catalogs success',
  DownloadCoreDomainsAndCatalogsFailure = '[Domains] Download core domains and catalogs failure',
}

export const downloadCoreDomainsAndCatalogs = createAction(
  DomainsActions.DownloadCoreDomainsAndCatalogs
);

export const downloadCoreDomainsAndCatalogsSuccess = createAction(
  DomainsActions.DownloadCoreDomainsAndCatalogsSuccess,
  props<{ domainsAndCatalogs: GetDomainsResponse }>()
);

export const downloadCoreDomainsAndCatalogsFailure = createAction(
  DomainsActions.DownloadCoreDomainsAndCatalogsFailure,
  props<{ error: ApiError }>()
);
