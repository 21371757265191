export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export const rangeSize = (range: DateRange): number => {
  return range.endDate.getTime() - range.startDate.getTime();
};

export const areRangesOverlapping = (
  range1: DateRange,
  range2: DateRange
): boolean => {
  return (
    range1.startDate <= range2.endDate && range1.endDate >= range2.startDate
  );
};
