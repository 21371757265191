import { ReducerTypes, on } from '@ngrx/store';
import { PatientsState, updateEncounterData } from '../../patients.reducers';
import { loadAllPregnancyCardSuccess } from 'src/app/features/anamnesis/pregnancy/store/pregnancy.actions';

export const patientDataPregnancyCardReducerFunctions: ReducerTypes<
  PatientsState,
  [never]
>[] = [
  on(
    loadAllPregnancyCardSuccess,
    (state, { pregnancyCard, patientId, encounterId }) => {
      return updateEncounterData(state, { patientId, encounterId }, () => ({
        pregnancyCard: {
          ...pregnancyCard,
        },
      }));
    }
  ),
];
