import { randomId } from '../../../../shared/utils/utils';
import { TherapyPrescription } from '../entities/therapy';
import { TherapyRepetition } from '../entities/therapy-repetition';

export const baseTherapyMock: TherapyPrescription<unknown> = {
  id: randomId(),

  patientId: randomId(),
  encounterId: randomId(),

  catalogId: randomId(),

  name: 'Therapy 1 description',

  prescriptionDate: new Date(),

  programmingDate: new Date(),

  cancellationDate: undefined,

  suspensionDate: undefined,

  occurrences: [],

  // Technical fields about the creation or edit of the entity

  createdBy: {
    id: randomId(),
    firstName: 'Mario',
    lastName: 'Rossi',
  },
  creationDate: new Date(),

  updatedBy: undefined,
  lastUpdateDate: undefined,

  insertionDate: new Date(Date.now() - 10000 * 60 * 60 * 24 * 7),
  canBeEdited: true,
};

export const therapyRepetitionsMock: TherapyRepetition[] = [
  {
    id: randomId(),
    intervalSpanMinutes: 60,
    label: 'Ogni ora',
  },
  {
    id: randomId(),
    intervalSpanMinutes: 6 * 60,
    label: 'Ogni 6 ore',
  },
  {
    id: randomId(),
    intervalSpanMinutes: 12 * 60,
    label: 'Ogni 12 ore',
  },
  {
    id: randomId(),
    intervalSpanMinutes: 24 * 60,
    label: '1 volta al giorno',
  },
];
