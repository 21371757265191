import {
  NateaIconsRegistry,
  nateaIconsAdd,
  nateaIconsAnamnesi,
  nateaIconsAnamnesiFamiliare,
  nateaIconsAnamnesiGravidanza,
  nateaIconsAnamnesiNeonato,
  nateaIconsAnamnesiRianimazionePrimaria,
  nateaIconsAnamnesiTravaglioEParto,
  nateaIconsArrowDown,
  nateaIconsArrowDownward,
  nateaIconsArrowLeft,
  nateaIconsArrowRight,
  nateaIconsArrowUp,
  nateaIconsBin,
  nateaIconsCalendar,
  nateaIconsCartellaInfermieristica,
  nateaIconsChart,
  nateaIconsCheck,
  nateaIconsCheckInscritto,
  nateaIconsCircleInfo,
  nateaIconsCircleStop,
  nateaIconsClock,
  nateaIconsClose,
  nateaIconsConfermaTerapeutica,
  nateaIconsConsulente,
  nateaIconsControllo,
  nateaIconsCrossBold,
  nateaIconsDashboard,
  nateaIconsDatiAntropometrici,
  nateaIconsDoubleCheck,
  nateaIconsEdit,
  nateaIconsEditFull,
  nateaIconsEnterale,
  nateaIconsEntrateEUscite,
  nateaIconsEsami,
  nateaIconsEvaluationDiaryNote,
  nateaIconsEvaluationNote,
  nateaIconsExclamationMark,
  nateaIconsEye,
  nateaIconsFace,
  nateaIconsFilter,
  nateaIconsGanttInfermieristico,
  nateaIconsHandScroll,
  nateaIconsHandStop,
  nateaIconsHospital,
  nateaIconsIncolla,
  nateaIconsInfermiere,
  nateaIconsList,
  nateaIconsLog,
  nateaIconsLogout,
  nateaIconsMedico,
  nateaIconsModificato,
  nateaIconsMonitoraggioInSalaParto,
  nateaIconsMouseScroll,
  nateaIconsNoVisibility,
  nateaIconsNoteDiDiario,
  nateaIconsNoteDiDiarioValidazione,
  nateaIconsParenterale,
  nateaIconsPatologie,
  nateaIconsPause,
  nateaIconsPdf,
  nateaIconsPlaceholderIcona,
  nateaIconsPlay,
  nateaIconsPrescrizione,
  nateaIconsPreset,
  nateaIconsProcedure,
  nateaIconsPulisci,
  nateaIconsRiepilogoClinico,
  nateaIconsRiferimento,
  nateaIconsScreeningNeonatale,
  nateaIconsSearch,
  nateaIconsStatusKo,
  nateaIconsStop,
  nateaIconsStoricoPaziente,
  nateaIconsTabella,
  nateaIconsTerapie,
  nateaIconsTerapieFarmacologiche,
  nateaIconsTerapieNutrizionali,
  nateaIconsTerapieStrumentali,
  nateaIconsTerapieTrasfusionali,
  nateaIconsTerminazione,
  nateaIconsTimeline,
  nateaIconsToggleOff,
  nateaIconsToggleOn,
  nateaIconsValidatedNotesArchive,
  nateaIconsViewOn,
  nateaIconsVisibility,
} from 'natea-components';

export const registerIcons = (nateaIconRegistry: NateaIconsRegistry): void => {
  nateaIconRegistry.registerIcons([
    nateaIconsAdd,
    nateaIconsAnamnesi,
    nateaIconsAnamnesiFamiliare,
    nateaIconsAnamnesiGravidanza,
    nateaIconsAnamnesiNeonato,
    nateaIconsAnamnesiRianimazionePrimaria,
    nateaIconsAnamnesiTravaglioEParto,
    nateaIconsArrowDown,
    nateaIconsArrowDownward,
    nateaIconsArrowLeft,
    nateaIconsArrowRight,
    nateaIconsArrowUp,
    nateaIconsBin,
    nateaIconsCalendar,
    nateaIconsCartellaInfermieristica,
    nateaIconsChart,
    nateaIconsCheck,
    nateaIconsCheckInscritto,
    nateaIconsCircleInfo,
    nateaIconsCircleStop,
    nateaIconsClock,
    nateaIconsClose,
    nateaIconsConfermaTerapeutica,
    nateaIconsConsulente,
    nateaIconsControllo,
    nateaIconsCrossBold,
    nateaIconsDashboard,
    nateaIconsDatiAntropometrici,
    nateaIconsDoubleCheck,
    nateaIconsEdit,
    nateaIconsEditFull,
    nateaIconsEnterale,
    nateaIconsEntrateEUscite,
    nateaIconsEsami,
    nateaIconsEvaluationDiaryNote,
    nateaIconsEvaluationNote,
    nateaIconsExclamationMark,
    nateaIconsEye,
    nateaIconsFace,
    nateaIconsFilter,
    nateaIconsGanttInfermieristico,
    nateaIconsHandScroll,
    nateaIconsHandStop,
    nateaIconsHospital,
    nateaIconsIncolla,
    nateaIconsInfermiere,
    nateaIconsList,
    nateaIconsLog,
    nateaIconsLogout,
    nateaIconsMedico,
    nateaIconsModificato,
    nateaIconsMonitoraggioInSalaParto,
    nateaIconsMouseScroll,
    nateaIconsNoteDiDiario,
    nateaIconsNoteDiDiarioValidazione,
    nateaIconsNoVisibility,
    nateaIconsParenterale,
    nateaIconsPatologie,
    nateaIconsPause,
    nateaIconsPdf,
    nateaIconsPlaceholderIcona,
    nateaIconsPlay,
    nateaIconsPrescrizione,
    nateaIconsPreset,
    nateaIconsProcedure,
    nateaIconsPulisci,
    nateaIconsRiepilogoClinico,
    nateaIconsRiferimento,
    nateaIconsScreeningNeonatale,
    nateaIconsSearch,
    nateaIconsStatusKo,
    nateaIconsStop,
    nateaIconsStoricoPaziente,
    nateaIconsTabella,
    nateaIconsTerapie,
    nateaIconsTerapieFarmacologiche,
    nateaIconsTerapieNutrizionali,
    nateaIconsTerapieStrumentali,
    nateaIconsTerapieTrasfusionali,
    nateaIconsTerminazione,
    nateaIconsTimeline,
    nateaIconsToggleOff,
    nateaIconsToggleOn,
    nateaIconsValidatedNotesArchive,
    nateaIconsViewOn,
    nateaIconsVisibility,
  ]);
};
