<ng-container *ngIf="{ selected: selectedPatient$ | async } as patient">
  <section
    [class.adapt-padding]="!isDesktopSized"
    *transloco="let t; read: 'common.mainHeader'"
  >
    <div class="left-section">
      <div class="left-section-column">
        <div class="label-and-icon">
          <div>
            <p class="title-label">
              {{
                (selectedDepartment | async)?.name ?? t("noDepartmentSelected")
              }}
            </p>
            <natea-button
              class="icon"
              class="back-button"
              [color]="color"
              [size]="buttonSize"
              [label]="backButtonLabel"
              [leftIcon]="icon"
              (buttonClick)="goToDepartments()"
            ></natea-button>
          </div>
          <div class="icon-and-dropdown">
            <div><natea-icons name="face" class="icon"></natea-icons></div>
            <natea-dropdown
              class="dropdown"
              [class]="'label-primary'"
              [formControl]="selectControl"
              [class.dropdown-1920-screen]="isDesktopSized"
              [class.dropdown-768-screen]="!isDesktopSized"
              [items]="(patientsList | async) ?? []"
              [label]="t('dropdownLabel')"
              (selectedItemChange)="selectPatient($event)"
            ></natea-dropdown>
          </div>
        </div>
      </div>

      <natea-cc-sub-header-patient-data-item
        *ngIf="isDesktopSized"
        [class.patient-data-1920-screen]="isDesktopSized"
        [patient]="patient?.selected ?? undefined"
      ></natea-cc-sub-header-patient-data-item>
    </div>

    <div *ngIf="isDesktopSized" class="add-empty-space"></div>

    <div
      class="end-section"
      nateaCcClickOutside
      (clickOutside)="clickOutside($event)"
    >
      <button
        matRipple
        type="button"
        [disabled]="isNotNotesFromClipboard$ | async"
        (click)="
          showBlackboardNotesTabletVersion = !showBlackboardNotesTabletVersion
        "
      >
        <natea-icons
          name="incolla"
          class="icon"
          [class.isDisabled]="isNotNotesFromClipboard$ | async"
        ></natea-icons>
      </button>
      <natea-cc-blackboard-notes-table
        class="blackboard-notes-tablet-version"
        [showPasteIcon]="false"
        *ngIf="
          !(isNotNotesFromClipboard$ | async) &&
          showBlackboardNotesTabletVersion
        "
      ></natea-cc-blackboard-notes-table>

      <div class="divider"></div>

      <button
        matRipple
        type="button"
        (click)="pdfButton.emit()"
        [disabled]="true"
      >
        <natea-icons name="pdf" class="icon"></natea-icons>
      </button>
    </div>

    <div
      class="end-bloc"
      nateaCcClickOutside
      (clickOutside)="showBlackboardNotes = false"
    >
      <button
        matRipple
        (click)="toggleBlackboardNotes($event)"
        [disabled]="isNotNotesFromClipboard$ | async"
        class="lavagna-appunti"
        type="button"
      >
        <natea-icons
          name="incolla"
          class="icon"
          [class.add-margin-to-icon]="isDesktopSized"
          [class.isDisabled]="isNotNotesFromClipboard$ | async"
        ></natea-icons>

        <span [class.isDisabled]="isNotNotesFromClipboard$ | async">
          {{ t("blackboardNotes") }}</span
        >
      </button>

      <div class="divider">
        <natea-cc-blackboard-notes-table
          [class.blackboard-notes-version--1280-screen]="
            isClinicalNotesPage && (isMenuOpened$ | async)
          "
          *ngIf="!(isNotNotesFromClipboard$ | async) && showBlackboardNotes"
          [showPasteIcon]="false"
        ></natea-cc-blackboard-notes-table>
      </div>

      <div class="end-section-1920">
        <button
          matRipple
          type="button"
          [disabled]="true"
          (click)="pdfButton.emit()"
        >
          <natea-icons
            name="pdf"
            class="icon"
            [class.margin-icon-screen-768]="!isDesktopSized"
            [class.add-margin-to-icon]="isDesktopSized"
          ></natea-icons>
          <span> {{ t("exportFolder") }}</span>
        </button>
      </div>
    </div>
  </section>

  <div class="header-divider" *ngIf="!isDesktopSized"></div>

  <natea-cc-sub-header-patient-data-item
    *ngIf="!isDesktopSized"
    [class.patient-data-768-screen]="!isDesktopSized"
    [patient]="patient?.selected ?? undefined"
  >
  </natea-cc-sub-header-patient-data-item>
</ng-container>
