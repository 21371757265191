import { TranslocoService } from '@ngneat/transloco';
import { ProfessionalCategory } from '../entities/professional-category';

export const getCategoryTranslation = (
  category: ProfessionalCategory,
  transloco: TranslocoService
): string => {
  switch (category) {
    case ProfessionalCategory.Medical:
      return transloco.translate('common.domain.doctor');
    case ProfessionalCategory.Nursing:
      return transloco.translate('common.domain.nurse');
  }
};

// Map containing internationalized category names. Particolarly useful for sorting.
export type CategoryTranslationMap = Map<ProfessionalCategory, string>;

export const getCategoryTranslationsMap = (
  transloco: TranslocoService
): CategoryTranslationMap => {
  const map = new Map<ProfessionalCategory, string>();
  map.set(
    ProfessionalCategory.Medical,
    getCategoryTranslation(ProfessionalCategory.Medical, transloco)
  );
  map.set(
    ProfessionalCategory.Nursing,
    getCategoryTranslation(ProfessionalCategory.Nursing, transloco)
  );
  return map;
};
