import { createAction, props } from '@ngrx/store';
import { ApiError } from '../../../../shared/entities/errors';
import { PatientDataNursingGanttState } from '../../../patients/store/clinical-data/nursing-gantt/patients-data.nursing-gantt.state';

export enum NursingGanttActions {
  LoadGanttData = '[NursingGantt] Load Gantt Data',
  LoadGanttDataSuccess = '[NursingGantt] Load Gantt Data Success',
  LoadGanttDataFailure = '[NursingGantt] Load Gantt Data Failure',
}

export const loadGanttData = createAction(NursingGanttActions.LoadGanttData);

export const loadGanttDataSuccess = createAction(
  NursingGanttActions.LoadGanttDataSuccess,
  props<{
    data: PatientDataNursingGanttState;
    patientId: string;
    encounterId: string;
  }>()
); // Todo the type should be changed

export const loadGanttDataFailure = createAction(
  NursingGanttActions.LoadGanttDataFailure,
  props<{ error: ApiError }>()
);
