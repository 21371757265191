/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Request body for patching an existing detected problem
 */
export interface DetectedProblemPatchRequest { 
    /**
     * Textual content to replace the description of the existing detected problem
     */
    description?: string;
    /**
     * Timestamp to replace the end time of the existing detected problem
     */
    endDate?: string;
    /**
     * Unique identifier of a problem-type to replace the problem-type of the existing detected problem
     */
    problemId?: string;
    /**
     * Timestamp to replace the start time of the existing detected problem
     */
    startDate?: string;
}

