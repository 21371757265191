import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import {
  selectForcedOpenMenuGroups,
  selectMenuBadgesMap,
  selectOpenedMenuGroups,
} from '../../../../features/users/store/users.selectors';
import { enabledSidebarItems } from '../data/sidebar-data';
import { SidebarItem, SidebarItemGroup } from '../models/sidebar-item';

@Component({
  selector: 'natea-cc-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss'],
})
export class SidebarItemComponent {
  @Input() item!: SidebarItem;

  @Input() compact!: boolean;
  @Input() accordionExpanded = false;

  @Input() nestingLevel = 0;

  @Input() basePath!: string;
  @Input() currentPath!: string;

  @Output() itemSelected: EventEmitter<SidebarItemSelectedEvent> =
    new EventEmitter<SidebarItemSelectedEvent>();

  @HostBinding('style.--nesting-level') get nestingLevelVar(): number {
    return this.nestingLevel;
  }
  @HostBinding('class') get classes(): string {
    return `${this.item.type} level-${this.nestingLevel}`;
  }

  isExpanded$ = this.store
    .select(selectOpenedMenuGroups)
    .pipe(map((ids) => ids.includes(this.item.id)));

  isForcedOpen$ = this.store
    .select(selectForcedOpenMenuGroups)
    .pipe(map((ids) => ids.includes(this.item.id)));

  badgeCount$ = this.store
    .select(selectMenuBadgesMap)
    .pipe(map((map) => map.get(this.item.id)));

  get selected() {
    return this.currentPath === `${this.basePath}/${this.item.path}`;
  }

  get selectedGroup() {
    return (
      this.item.type === 'group' &&
      this.item.path &&
      this.currentPath.startsWith(`${this.basePath}/${this.item.path}`)
    );
  }

  get enabled(): boolean {
    return enabledSidebarItems.has(this.item.id);
  }

  constructor(private store: Store) {}

  onClick = () => {
    if (this.enabled) {
      this.itemSelected.emit({ item: this.item, enclosingGroups: [] });
    }
  };

  onChildClick = ({ item, enclosingGroups }: SidebarItemSelectedEvent) => {
    if (this.item.type === 'group') {
      this.itemSelected.emit({
        item,
        enclosingGroups: [this.item, ...enclosingGroups],
      });
    }
  };
}

export interface SidebarItemSelectedEvent {
  item: SidebarItem;

  // Chain of groups that contains the item (from the root to the leaf)
  enclosingGroups: SidebarItemGroup[];
}
