import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService } from 'natea-components';
import { catchError, exhaustMap, map, of } from 'rxjs';
import {
  ApiError,
  errorMessage,
  localError,
} from '../../../../shared/entities/errors';
import { SNACK_BAR_DEBOUNCE_TIME_MS } from '../../../../shared/utils/constants';
import { selectOngoingEncounterAndPatientIds } from '../../../patients/store/patients.selectors';
import { ParenteralTherapy } from '../entities/nutritional-therapy';
import { NutritionalEnteralFormData } from '../form/nutritional-therapy-form-data';
import { NutritionalTherapiesWebAPI } from '../webapi/nutritional-therapies.webapi';
import {
  NutritionalTherapiesActions,
  confirmAbortNutritionalTherapyFailure,
  confirmAbortNutritionalTherapySuccess,
  confirmDeleteNutritionalTherapyFailure,
  confirmDeleteNutritionalTherapySuccess,
  downloadNutritionalTherapyCatalogsFailure,
  downloadNutritionalTherapyCatalogsSuccess,
  editEnteralTherapyFailure,
  editEnteralTherapySuccess,
  editParenteralTherapySuccess,
  loadNutritionalTherapiesFailure,
  loadNutritionalTherapiesSuccess,
} from './nutritional-therapies.actions';
import {
  selectCandidaTherapyForAbort,
  selectNutritionalTherapyCandidateForDeletion,
  selectSelectedNutritionalTherapyIds,
} from './nutritional-therapies.selectors';

@Injectable()
export class NutritionalTherapiesEffects {
  loadNutritionalTherapies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NutritionalTherapiesActions.LoadNutritionalTherapies),
      concatLatestFrom(() => {
        return this.store.select(selectOngoingEncounterAndPatientIds);
      }),
      exhaustMap(([, onGoingEncounterIds]) => {
        if (onGoingEncounterIds) {
          return this.api
            .loadNutritionalTherapies(
              onGoingEncounterIds?.encounterId,
              onGoingEncounterIds?.patientId
            )
            .pipe(
              map((therapies) =>
                loadNutritionalTherapiesSuccess({
                  patientId: onGoingEncounterIds?.patientId,
                  encounterId: onGoingEncounterIds?.encounterId,
                  therapies,
                })
              ),
              catchError((error: ApiError) =>
                of(loadNutritionalTherapiesFailure({ error }))
              )
            );
        } else {
          return of(
            loadNutritionalTherapiesFailure({
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }
      }),
      catchError((error: ApiError) =>
        of({
          type: NutritionalTherapiesActions.LoadNutritionalTherapiesFailure,
          error,
        })
      )
    );
  });

  confirmDeleteNutritionalTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NutritionalTherapiesActions.ConfirmDeleteNutritionalTherapy),
      concatLatestFrom(() => [
        this.store.select(selectNutritionalTherapyCandidateForDeletion),
        this.store.select(selectOngoingEncounterAndPatientIds),
      ]),

      exhaustMap(([, therapy, onGoingEncounter]) => {
        if (!therapy || !onGoingEncounter) {
          return of(
            confirmDeleteNutritionalTherapyFailure({
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }
        return this.api
          .deleteNutritionalTherapyPrescription(therapy?.id, therapy.patientId)
          .pipe(
            map(() => {
              return confirmDeleteNutritionalTherapySuccess({
                therapyId: therapy.id,
                patientId: onGoingEncounter?.patientId ?? '',
                encounterId: onGoingEncounter?.encounterId ?? '',
              });
            }),
            catchError((error: ApiError) => {
              return of(confirmDeleteNutritionalTherapyFailure({ error }));
            })
          );
      })
    );
  });

  confirmDeleteNutritionalTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          NutritionalTherapiesActions.ConfirmDeleteNutritionalTherapyFailure
        ),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.deleteNutritionalTherapyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  confirmDeleteNutritionalTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          NutritionalTherapiesActions.ConfirmDeleteNutritionalTherapySuccess
        ),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.deleteNutritionalTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  loadNutritionalTherapiesFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NutritionalTherapiesActions.LoadNutritionalTherapiesFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.loadTherapiesFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /******************** TYPES ********************/

  loadTrasfusionalTherapiesTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NutritionalTherapiesActions.DownloadTypes),
      exhaustMap(() =>
        this.api.downloadCatalogs().pipe(
          map((catalogs) =>
            downloadNutritionalTherapyCatalogsSuccess({ catalogs })
          ),
          catchError((error: ApiError) =>
            of(downloadNutritionalTherapyCatalogsFailure({ error }))
          )
        )
      )
    );
  });

  loadTrasfusionalTherapiesTypesFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NutritionalTherapiesActions.DownloadTypesFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.loadTherapiesTypesFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  abortNutritionalTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NutritionalTherapiesActions.ConfirmAbortNutritionalTherapy),
      concatLatestFrom(() => [
        this.store.select(selectOngoingEncounterAndPatientIds),
        this.store.select(selectCandidaTherapyForAbort),
      ]),
      exhaustMap(([message, onGoingEncounter, therapy]) => {
        if (!onGoingEncounter || !therapy) {
          return of(
            confirmAbortNutritionalTherapyFailure({
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }
        return this.api
          .abortNutritionalTherapy(
            onGoingEncounter.encounterId,
            onGoingEncounter.patientId,
            message,
            therapy
          )
          .pipe(
            map((result) => {
              return confirmAbortNutritionalTherapySuccess({
                therapy: result,
                patientId: onGoingEncounter.patientId,
                encounterId: onGoingEncounter.encounterId,
              });
            }),
            catchError((error: ApiError) => {
              return of(confirmDeleteNutritionalTherapyFailure({ error }));
            })
          );
      })
    );
  });

  abortNutritionalTherapFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          NutritionalTherapiesActions.ConfirmAbortNutritionalTherapyFailure
        ),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.abortNutritionalTherapyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },

    { dispatch: false }
  );

  abortNutritionalTherapSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          NutritionalTherapiesActions.ConfirmAbortNutritionalTherapySuccess
        ),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.abortNutritionalTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  saveEnteralTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: NutritionalTherapiesActions.EditEnteralTherapy;
        therapyId: string;
        occurrenceId: string;
        therapy: NutritionalEnteralFormData;
      }>(NutritionalTherapiesActions.EditEnteralTherapy),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      exhaustMap(([{ therapyId, occurrenceId, therapy }, onGoingEncounter]) => {
        if (!onGoingEncounter) {
          return of(
            loadNutritionalTherapiesFailure({
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }
        return this.api
          .editEnteralTherapy({
            encounterId: onGoingEncounter.encounterId,
            patientId: onGoingEncounter.patientId,
            therapy,
            therapyId,
            occurrenceId,
          })
          .pipe(
            map((result) => {
              return editEnteralTherapySuccess({
                patientId: onGoingEncounter.patientId,
                encounterId: onGoingEncounter.encounterId,
                therapy: result,
              });
            }),
            catchError((error: ApiError) => {
              return of(loadNutritionalTherapiesFailure({ error }));
            })
          );
      })
    );
  });

  editEnteralTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NutritionalTherapiesActions.EditEnteralTherapySuccess),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.editEnteralTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  editEnteralTherapFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NutritionalTherapiesActions.EditEnteralTherapyFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.editEnteralTherapyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },

    { dispatch: false }
  );

  editParenteralTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NutritionalTherapiesActions.EditParenteralTherapy),
      concatLatestFrom(() => [
        this.store.select(selectSelectedNutritionalTherapyIds),
        this.store.select(selectOngoingEncounterAndPatientIds),
      ]),
      exhaustMap(
        ([{ therapy }, { selectedOccurrenceId }, onGoingEncounter]) => {
          if (!onGoingEncounter || !selectedOccurrenceId) {
            return of(
              editEnteralTherapyFailure({
                error: localError(HttpStatusCode.BadRequest),
              })
            );
          }
          return this.api
            .editParenteralTherapy({
              therapy,
              encounterId: onGoingEncounter.encounterId,
              patientId: onGoingEncounter.patientId,
              therapyId: selectedOccurrenceId.therapyId,
              occurrence: selectedOccurrenceId.occurrenceId,
            })
            .pipe(
              map((result: ParenteralTherapy) => {
                return editParenteralTherapySuccess({
                  patientId: onGoingEncounter.patientId,
                  encounterId: onGoingEncounter.encounterId,
                  therapy: result,
                });
              }),
              catchError((error: ApiError) => {
                return of(editEnteralTherapyFailure({ error }));
              })
            );
        }
      )
    );
  });

  editParenteralTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NutritionalTherapiesActions.EditParenteralTherapySuccess),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.editParenteralTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  editParenteralTherapFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NutritionalTherapiesActions.EditParenteralTherapyFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.editParenteralTherapyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },

    { dispatch: false }
  );

  /******************** CONSTRUCTOR ********************/

  constructor(
    private actions$: Actions,
    private store: Store,
    private snackBar: SnackbarService,
    private translocoService: TranslocoService,
    private api: NutritionalTherapiesWebAPI
  ) {}
}
