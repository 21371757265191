export enum ProcedureOccurrenceMovementType {
  Inserted = 'inserted',
  Removed = 'removed',
  NotApplicable = '', // RLE
}

/**
 * Each of the movements executed during a procedure's occurrence
 */
export interface ProcedureOccurrenceMovement {
  id: string;
  executionDate: Date;
  type: ProcedureOccurrenceMovementType;
  cm: number;
  medicated: boolean;
  notes: string;
}
