import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import {
  addIntervention,
  checkValidityPreviousPregnancyCard,
  checkValidityProphylaxisCard,
  hideModalIntervention,
  pregnancyEstimatedDate,
  removeIntervention,
  showModalIntervention,
} from './pregnancy.actions';

import { PrenatalInterventionData } from '../forms/pranatal-intervention-form-data';

export interface PregnancyCardUiState {
  isSaving: boolean;
  estimatedDate?: Date;
  isProphylaxisValid: boolean;
  isPreviousPregnancyValid: boolean;
  showInterventionModal: boolean;
  interventions: PrenatalInterventionData[];
}

export const iniTialPregnancyCardUiState: PregnancyCardUiState = {
  isSaving: false,
  isProphylaxisValid: false,
  isPreviousPregnancyValid: false,
  showInterventionModal: false,
  interventions: [],
};

export const pregnancyCardUiReducer: ActionReducer<
  PregnancyCardUiState,
  Action
> = createReducer(
  iniTialPregnancyCardUiState,
  on(
    pregnancyEstimatedDate,
    (state: PregnancyCardUiState, { date }): PregnancyCardUiState => ({
      ...state,
      estimatedDate: date,
    })
  ),
  on(
    checkValidityProphylaxisCard,
    (state: PregnancyCardUiState, { isValid }): PregnancyCardUiState => ({
      ...state,
      isProphylaxisValid: isValid,
    })
  ),
  on(
    checkValidityPreviousPregnancyCard,
    (state: PregnancyCardUiState, { isValid }): PregnancyCardUiState => ({
      ...state,
      isPreviousPregnancyValid: isValid,
    })
  ),

  on(
    showModalIntervention,
    (state: PregnancyCardUiState): PregnancyCardUiState => ({
      ...state,
      showInterventionModal: true,
    })
  ),
  on(
    hideModalIntervention,
    (state: PregnancyCardUiState): PregnancyCardUiState => ({
      ...state,
      showInterventionModal: false,
    })
  ),
  on(
    addIntervention,
    (state: PregnancyCardUiState, { intervention }): PregnancyCardUiState => ({
      ...state,
      interventions: [...state.interventions, intervention],
      showInterventionModal: false,
    })
  ),
  on(
    removeIntervention,
    (
      state: PregnancyCardUiState,
      { intervention, index }
    ): PregnancyCardUiState => ({
      ...state,
      interventions: state.interventions.filter(
        (i, interventionIndex) => interventionIndex !== index
      ),
    })
  )
);
