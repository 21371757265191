import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService } from 'natea-components';
import { catchError, delay, exhaustMap, map, of } from 'rxjs';
import {
  ApiError,
  errorMessage,
  localError,
} from '../../../../shared/entities/errors';
import { SNACK_BAR_DEBOUNCE_TIME_MS } from '../../../../shared/utils/constants';
import { selectOngoingEncounterAndPatientIds } from '../../../patients/store/patients.selectors';
import { transfusionalTherapiesListMock } from '../mocks/transfusional-therapy.mocks';
import {
  TransfusionalTherapiesActions,
  confirmAbortTransfusionalTherapyFailure,
  confirmAbortTransfusionalTherapySuccess,
  confirmDeleteTransfusionalTherapyFailure,
  confirmDeleteTransfusionalTherapySuccess,
  confirmSuspendTransfusionalTherapyFailure,
  createTransfusionalTherapyFailure,
  createTransfusionalTherapySuccess,
  downloadTransfusionalTherapyTypesFailure,
  downloadTransfusionalTherapyTypesSuccess,
  editTransfusionalTherapyFailure,
  editTransfusionalTherapySuccess,
  loadTransfusionalTherapiesFailure,
  loadTransfusionalTherapiesSuccess,
  transfusionalTherapySuspensionSuccess,
} from './transfusional-therapies.actions';
import { TrasfusionalTherapiesWebAPI } from '../webapi/transfusional-therapies.webapi';
import { TransfusionalTherapyFormData } from '../form/transfusional-therapy-form-data';
import { TransfusionalTherapy } from '../entities/transfusional-therapy';
import {
  selectGetCandidateForDeleteTherapyId,
  selectTransfusionalTherapyCandidateForAbort,
  selectTransfusionalTherapyCandidateForSuspend,
} from './transfusional-therapies.selectors';

@Injectable()
export class TransfusionalTherapiesEffects {
  load$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransfusionalTherapiesActions.LoadTransfusionalTherapies),
      concatLatestFrom(() => {
        return this.store.select(selectOngoingEncounterAndPatientIds);
      }),
      exhaustMap(([, onGoingEncounterIds]) => {
        const mocks: TransfusionalTherapy[] = transfusionalTherapiesListMock();
        return onGoingEncounterIds
          ? of(
              loadTransfusionalTherapiesSuccess({
                patientId: onGoingEncounterIds?.patientId,
                encounterId: onGoingEncounterIds?.encounterId,
                therapies: mocks, // TODO real data
              })
            ).pipe(delay(100))
          : of(
              loadTransfusionalTherapiesFailure({
                error: localError(HttpStatusCode.BadRequest),
              })
            );
      }),
      catchError((error: ApiError) => {
        return of({
          type: TransfusionalTherapiesActions.LoadTransfusionalTherapiesFailure,
          error,
        });
      })
    );
  });

  loadTransfusionalTherapiesFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TransfusionalTherapiesActions.LoadTransfusionalTherapiesFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.loadTherapiesFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /******************** TYPES  ********************/

  loadTrasfusionalTherapiesTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransfusionalTherapiesActions.DownloadTypes),
      exhaustMap(() =>
        this.api.downloadTypes().pipe(
          map((types) => downloadTransfusionalTherapyTypesSuccess({ types })),
          catchError((error: ApiError) =>
            of(downloadTransfusionalTherapyTypesFailure({ error }))
          )
        )
      )
    );
  });

  loadTrasfusionalTherapiesTypesFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TransfusionalTherapiesActions.DownloadTypesFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.loadTherapiesTypesFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /************************ CREATE THERAPY ************************/

  createTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransfusionalTherapiesActions.CreateTransfusionalTherapy),
      concatLatestFrom(() => {
        return this.store.select(selectOngoingEncounterAndPatientIds);
      }),
      exhaustMap(([{ therapy }, onGoingEncounter]) => {
        return this.api.createTransfusionalTherapy(therapy).pipe(
          map((therapyResult) => {
            if (!onGoingEncounter) {
              return createTransfusionalTherapyFailure({
                error: localError(HttpStatusCode.BadRequest),
              });
            }

            return createTransfusionalTherapySuccess({
              therapy: therapyResult,
              patientId: onGoingEncounter.patientId,
              encounterId: onGoingEncounter.encounterId,
            });
          }),
          catchError((error: ApiError) => {
            return of(createTransfusionalTherapyFailure({ error }));
          })
        );
      }),
      catchError((error: ApiError) => {
        return of(createTransfusionalTherapyFailure({ error }));
      })
    );
  });

  createTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TransfusionalTherapiesActions.CreateTransfusionalTherapySuccess),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.createTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  createTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TransfusionalTherapiesActions.CreateTransfusionalTherapyFailure),
        map((error) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.createTherapyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /************************ EDIT THERAPY ************************/

  editTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: TransfusionalTherapiesActions.EditTransfusionalTherapy;
        therapyId: string;
        therapy: TransfusionalTherapyFormData;
      }>(TransfusionalTherapiesActions.EditTransfusionalTherapy),
      concatLatestFrom(() => {
        return this.store.select(selectOngoingEncounterAndPatientIds);
      }),
      exhaustMap(([{ therapy, therapyId }, onGoingEncounter]) => {
        return this.api.editTransfusionalTherapy(therapy, therapyId).pipe(
          map((therapyResult) => {
            if (!onGoingEncounter) {
              return editTransfusionalTherapyFailure({
                error: localError(HttpStatusCode.BadRequest),
              });
            }

            return editTransfusionalTherapySuccess({
              therapy: therapyResult,
              patientId: onGoingEncounter.patientId,
              encounterId: onGoingEncounter.encounterId,
            });
          }),
          catchError((error: ApiError) => {
            return of(editTransfusionalTherapyFailure({ error }));
          })
        );
      }),
      catchError((error: ApiError) => {
        return of(editTransfusionalTherapyFailure({ error }));
      })
    );
  });

  editTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TransfusionalTherapiesActions.EditTransfusionalTherapySuccess),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.editTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  editTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TransfusionalTherapiesActions.EditTransfusionalTherapyFailure),
        map((error: ApiError) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.editTherapyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /************************ SUSPEND THERAPY ************************/

  suspendTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransfusionalTherapiesActions.ConfirmSuspendTransfusionalTherapy),
      concatLatestFrom(() => {
        return [
          this.store.select(selectOngoingEncounterAndPatientIds),
          this.store.select(selectTransfusionalTherapyCandidateForSuspend),
        ];
      }),
      exhaustMap(([{ justification }, onGoingEncounter, prescription]) => {
        if (!onGoingEncounter || !prescription) {
          return of(
            confirmSuspendTransfusionalTherapyFailure({
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }
        return this.api
          .suspendTransfusionalTherapy({
            justification,
            patientId: onGoingEncounter.patientId,
            prescription,
          })
          .pipe(
            map((therapyResult) => {
              if (!onGoingEncounter) {
                return confirmSuspendTransfusionalTherapyFailure({
                  error: localError(HttpStatusCode.BadRequest),
                });
              }

              return transfusionalTherapySuspensionSuccess({
                therapy: therapyResult,
                patientId: onGoingEncounter.patientId,
                encounterId: onGoingEncounter.encounterId,
              });
            }),
            catchError((error: ApiError) => {
              return of(confirmSuspendTransfusionalTherapyFailure({ error }));
            })
          );
      })
    );
  });

  suspendTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TransfusionalTherapiesActions.SuspensionSuccess),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.suspendTransfusionSuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  suspendTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TransfusionalTherapiesActions.SuspensionFailure),
        map((error: ApiError) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.suspendTransfusionFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /**************** Abort ***************************/

  abortTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransfusionalTherapiesActions.ConfirmAbortTransfusionalTherapy),
      concatLatestFrom(() => {
        return [
          this.store.select(selectOngoingEncounterAndPatientIds),
          this.store.select(selectTransfusionalTherapyCandidateForAbort),
        ];
      }),
      exhaustMap(([{ justification }, onGoingEncounter, prescription]) => {
        if (!onGoingEncounter || !prescription) {
          return of(
            confirmAbortTransfusionalTherapyFailure({
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }
        return this.api
          .abortTransfusionalTherapy({
            justification,
            patientId: onGoingEncounter.patientId,
            prescription,
          })
          .pipe(
            map((therapyResult) => {
              return confirmAbortTransfusionalTherapySuccess({
                therapy: therapyResult,
                patientId: onGoingEncounter.patientId,
                encounterId: onGoingEncounter.encounterId,
              });
            }),
            catchError((error: ApiError) => {
              return of(confirmAbortTransfusionalTherapyFailure({ error }));
            })
          );
      })
    );
  });

  abortTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          TransfusionalTherapiesActions.ConfirmAbortTransfusionalTherapySuccess
        ),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.abortTransfusionSuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  abortTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          TransfusionalTherapiesActions.ConfirmAbortTransfusionalTherapyFailure
        ),
        map((error: ApiError) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.abortTransfusionFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /********************** DELETE **************************/

  deleteTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransfusionalTherapiesActions.ConfirmDeleteTransfusionalTherapy),
      concatLatestFrom(() => {
        return [
          this.store.select(selectOngoingEncounterAndPatientIds),
          this.store.select(selectGetCandidateForDeleteTherapyId),
        ];
      }),
      exhaustMap(([, onGoingEncounter, therapyId]) => {
        if (!onGoingEncounter || !therapyId) {
          return of(
            confirmDeleteTransfusionalTherapyFailure({
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        }
        return this.api.deleteTransfusionalTherapy(therapyId).pipe(
          map(() => {
            return confirmDeleteTransfusionalTherapySuccess({
              patientId: onGoingEncounter.patientId,
              encounterId: onGoingEncounter.encounterId,
              therapyId,
            });
          }),
          catchError((error: ApiError) => {
            return of(confirmDeleteTransfusionalTherapyFailure({ error }));
          })
        );
      })
    );
  });

  deleteTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          TransfusionalTherapiesActions.ConfirmDeleteTransfusionalTherapySuccess
        ),
        map(() =>
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.deleteTransfusionalTherapySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  deleteTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          TransfusionalTherapiesActions.ConfirmDeleteTransfusionalTherapyFailure
        ),
        map((error: ApiError) =>
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'therapies.notifications.deleteTherapyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          )
        )
      );
    },
    { dispatch: false }
  );

  /******************** CONSTRUCTOR ********************/

  constructor(
    private actions$: Actions,
    private store: Store,
    private snackBar: SnackbarService,
    private translocoService: TranslocoService,
    private api: TrasfusionalTherapiesWebAPI
  ) {}
}
