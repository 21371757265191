import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NateaIconsModule } from '../../icons/natea-icons.module';
import { FieldErrorComponent } from '../field/field-error/field-error.component';
import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'natea-time-picker',
  standalone: true,

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TimePickerComponent,
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    NateaIconsModule,
    MatFormFieldModule,
    FieldErrorComponent,
  ],
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent
  extends FieldComponent
  implements ControlValueAccessor
{
  readonly date = new Date();
  @Input() disabled = false;
  @Input() showSpinners = false;
  @Input() stepHour = 1;
  @Input() stepMinute = 1;
  @Input() stepSecond: number | undefined = undefined;
  @Input() showSeconds = false;

  @Output() timeChange = new EventEmitter<string | null>();

  @Output() timeInput = new EventEmitter<string | undefined>();

  isAddClass = true;
  // @Input() label = 'Durata prevista(hh:mm)';

  onChange!: (val: string | null) => void;

  onTouched!: () => void;

  inputValue: string | undefined = '';

  onTimeChanged = (event: Event): void => {
    const time = (event.target as HTMLInputElement).value;

    if (this.onChange) {
      this.onChange(time);
    }
    this.timeChange.emit(time);
  };

  markAsTouched = (): void => {
    if (this.onTouched) {
      this.onTouched();
    }
  };

  onTimeInput = (event: Event): void => {
    this.markAsTouched();

    const time = (event.target as HTMLInputElement).value;

    this.inputValue = time;
    this.timeInput.emit(
      (event.target as HTMLInputElement).value === null
        ? undefined
        : (event.target as HTMLInputElement).value
    );
    this.isAddClass = !!time;
  };

  writeValue(date: Date | null): void {
    this.inputValue = date?.getHours() + ':' + date?.getMinutes();
  }
  registerOnChange(fn: (val: string | null) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState = (isDisabled: boolean): void => {
    this.isDisabled = isDisabled;
  };
}
