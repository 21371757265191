import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { TherapyRepetition } from '../../../features/therapies/common/entities/therapy-repetition';
import { NutritionalCategory } from '../../../features/therapies/nutritional-therapies/entities/nutritional-therapy-type';
import { DomainItem } from '../../../shared/entities/domain-item';
import { ApiError } from '../../../shared/entities/errors';
import {
  downloadCoreDomainsAndCatalogsFailure,
  downloadCoreDomainsAndCatalogsSuccess,
} from './domains.actions';

export type NutritionalCategoriesDomainMap = Map<
  NutritionalCategory,
  DomainItem
>;

export interface DomainsState {
  therapyRepetitions?: TherapyRepetition[];
  nutritionalCategories: NutritionalCategoriesDomainMap;
  domainsDownloaded: boolean;
  coombsTest?: DomainItem[];
  milkingTime?: DomainItem[];
  withdrawal?: DomainItem[];
  uterineTransfer?: DomainItem[];
  emissionUrine?: DomainItem[];
  emissionMeconium?: DomainItem[];
  surfacente?: DomainItem[];
  bloodGroup?: DomainItem[];
  rhFactor?: DomainItem[];
  pharmacy?: DomainItem[];
  vitaminK?: DomainItem[];
  route?: DomainItem[];
  echocardiogram?: DomainItem[];
  menstrualCycle?: DomainItem[];
  obstetricVisits?: DomainItem[];
  pregnancyCareTechniques?: DomainItem[];
  downloadError?: ApiError;
}

export const initialDomainState: DomainsState = {
  domainsDownloaded: false,
  nutritionalCategories: new Map<
    NutritionalCategory,
    DomainItem<NutritionalCategory>
  >(),
};

export const domainsReducer: ActionReducer<DomainsState, Action> =
  createReducer(
    initialDomainState,
    on(
      downloadCoreDomainsAndCatalogsSuccess,
      (state: DomainsState, { domainsAndCatalogs }): DomainsState => ({
        ...state,
        therapyRepetitions: domainsAndCatalogs.therapyRepetitions,
        nutritionalCategories: domainsAndCatalogs.nutritionalCategoriesMap,
        coombsTest: domainsAndCatalogs.commonDomaine['directCoombsTest'],
        milkingTime: domainsAndCatalogs.commonDomaine['milkingTime'],
        withdrawal: domainsAndCatalogs.commonDomaine['withdrawal'],
        uterineTransfer: domainsAndCatalogs.commonDomaine['uterineTransfer'],
        emissionUrine: domainsAndCatalogs.commonDomaine['emissionUrine'],
        emissionMeconium: domainsAndCatalogs.commonDomaine['emissionMeconium'],
        surfacente: domainsAndCatalogs.commonDomaine['surfacente'],
        bloodGroup: domainsAndCatalogs.commonDomaine['bloodGroup'],
        rhFactor: domainsAndCatalogs.commonDomaine['rhFactor'],
        pharmacy: domainsAndCatalogs.commonDomaine['pharmacy'],
        vitaminK: domainsAndCatalogs.commonDomaine['vitaminK'],
        route: domainsAndCatalogs.commonDomaine['route'],
        echocardiogram: domainsAndCatalogs.commonDomaine['echocardiogram'],
        menstrualCycle: domainsAndCatalogs.commonDomaine['menstrualCycle'],
        obstetricVisits: domainsAndCatalogs.commonDomaine['obstetricVisits'],
        pregnancyCareTechniques:
          domainsAndCatalogs.commonDomaine['pregnancyCareTechniques'],
        domainsDownloaded: true,
      })
    ),
    on(
      downloadCoreDomainsAndCatalogsFailure,
      (state: DomainsState, { error }): DomainsState => ({
        ...state,
        downloadError: error,
      })
    )
  );
