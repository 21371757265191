import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  ChartDataset,
  ChartOptions,
  LineControllerChartOptions,
  Point,
  ScatterDataPoint,
} from 'chart.js';
import { DatePipe } from '@angular/common';
import { mockEncounterDay } from '../../../shared/utils/mock-utils'; // RLE

@Component({
  selector: 'natea-cc-timeline-line-chart',
  templateUrl: './timeline-line-chart.component.html',
  styleUrls: ['./timeline-line-chart.component.scss'],
})
export class TimelineLineChartComponent implements OnChanges {
  @Input() startDate?: Date = new Date();
  @Input() endDate?: Date = new Date();

  @Input() minY!: number;
  @Input() maxY!: number;
  @Input() stepSizeY!: number;

  data: ChartDataset<'line', (number | Point | null)[]>[] = [];
  lineChartOptions?: ChartOptions<'line'>;
  otherOptions: LineControllerChartOptions = {
    showLine: false,
    spanGaps: true,
  };
  constructor(private datePipe: DatePipe) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['startDate'] || changes['endDate']) {
      this.data = [
        {
          pointBackgroundColor: '#ffffff',
          pointBorderColor: '#038095',
          label: 'a',
          data: randomData(
            this.startDate ?? new Date(),
            this.endDate ?? new Date()
          ),
        },
      ];

      this.lineChartOptions = this.createLineChartOptions();
    }
  }

  private createLineChartOptions = (
    showPoints = true
  ): ChartOptions<'line'> => {
    const minTime = (this.startDate ?? new Date()).getTime();
    const maxTime = (this.endDate ?? new Date()).getTime();
    return {
      layout: {
        padding: {
          left: -10,
          right: -100,
          top: 0,
          bottom: 0,
        },
      },
      elements: {
        point: {
          radius: showPoints ? 10 : 0,
        },
        line: {
          borderColor: '#038095',
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          min: this.minY,
          max: this.maxY,

          ticks: {
            stepSize: this.stepSizeY,
            display: false,
          },
        },
        line: {
          axis: 'y',
          display: false,
        },
        x: {
          type: 'linear',
          min: minTime,
          max: maxTime,
          ticks: {
            stepSize: 24 * 60 * 60 * 1000,
            display: false,

            font: {
              family: 'Cairo',
              size: 12,
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },

        tooltip: {
          enabled: true,
          intersect: false,
          yAlign: 'bottom',
          mode: 'index',
          backgroundColor: '#038095',
          padding: 12,
          displayColors: false,
          caretPadding: 12,
          callbacks: {
            title: () => '',

            label: (context) => {
              const value = context.parsed.y;
              return value.toFixed(3) + 'kg'; // TODO unit to be passed as parameter
            },
          },
        },
      },
    };
  };
}

const randomData = (startDate: Date, endDate: Date) => {
  const random = (min: number, max: number) =>
    Math.random() * (max - min) + min;

  const data: ScatterDataPoint[] = [];

/*****
  let currentDate = startDate;
  let currentValue = 1.8;
  while (currentDate <= endDate) {
    const nextValue = random(currentValue, currentValue + 0.02);
    data.push({
      x: currentDate.getTime() + 12 * 60 * 60 * 1000,
      y: nextValue, //randomStep(nextValue, nextValue + 0.5, 0.1),
    });

    currentValue = nextValue;
    currentDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
  }
*****/
data.push({x: mockEncounterDay(1, 10).getTime(), y: 2.15 });
data.push({x: mockEncounterDay(2, 10).getTime(), y: 2.10 });
data.push({x: mockEncounterDay(3, 10).getTime(), y: 2.04 });
data.push({x: mockEncounterDay(4, 10).getTime(), y: 2.01 });
data.push({x: mockEncounterDay(5, 10).getTime(), y: 2.06 });
data.push({x: mockEncounterDay(6, 10).getTime(), y: 2.11 });
data.push({x: mockEncounterDay(7, 10).getTime(), y: 2.15 });
data.push({x: mockEncounterDay(8, 10).getTime(), y: 2.19 });
data.push({x: mockEncounterDay(9, 10).getTime(), y: 2.26 });
data.push({x: mockEncounterDay(10, 10).getTime(), y: 2.38 });

return data;
};
