/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Request object for creating a new clinical note.
 */
export interface ClinicalNoteCreateRequestResource { 
    /**
     * Additional notes about the clinical note.
     */
    additionalNotes: string;
    /**
     * Unique identifier for a related clinical note, if applicable.
     */
    clinicalNoteRefId?: string;
    /**
     * Unique identifier for the detected problem that this clinical note refers to, if applicable.
     */
    detectedProblemId?: string;
    /**
     * Timestamp of the observation of the pediatric patient.
     */
    observedAt: string;
    /**
     * The domain key for the type of clinical note.
     */
    type: string;
}

