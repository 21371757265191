import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { EntityLog } from '../../../shared/entities/entity-log';
import { ApiError } from '../../../shared/entities/errors';
import { Pagination } from '../../../shared/entities/pagination';
import { ProblemFormData } from '../interfaces/new-problem-form';
import { PatientProblem } from '../interfaces/patient-problem';
import { ProblemType } from '../interfaces/problem-type';
import { ProblemsFilter } from '../interfaces/problems-filter';

export enum ProblemActions {
  SwitchVisualization = '[Problems] Switch Visualization',
  ReloadAllProblemDataStarted = '[Problems] Reload All Problem Data Started',
  LoadAllProblemData = '[Problems] Load All Problem Data',
  LoadAllProblemDataSuccess = '[Problems] Load All Problem Data Success',
  LoadAllProblemDataFailure = '[Problems] Load All Problem Data Failure',
  LoadMoreProblemData = '[Problems] Load More Problem Data',
  UpdateProblemDataSearchString = '[Problems] Update Problem Data Search String',
  LoadAllProblems = '[Problems] Load All Problems',
  LoadAllProblemsSuccess = '[Problems] Load All Problems Success',
  LoadAllProblemsAborted = '[Problems] Load All Problems Aborted',
  LoadAllProblemsFailure = '[Problems] Load All Problems Failure',
  LoadMoreProblems = '[Problems] Load More Problems',
  CreatePatientProblem = '[Problems] Create Patient Problem',
  CreatePatientProblemSuccess = '[Problems] Create Patient Problem Success',
  CreatePatientProblemFailure = '[Problems] Create Patient Problem Failure',
  UpdatePatientProblem = '[Problems] Update Patient Problem',
  UpdatePatientProblemSuccess = '[Problems] Update Patient Problem Success',
  UpdatePatientProblemFailure = '[Problems] Update Patient Problem Failure',
  ProblemDeletionRequired = '[Problems] Problem Deletion Required',
  ProblemDeletionAborted = '[Problems] Problem Deletion Aborted',
  DeletePatientProblem = '[Problems] Delete Patient Problem',
  DeletePatientProblemSuccess = '[Problems] Delete Patient Problem Success',
  DeletePatientProblemFailure = '[Problems] Delete Patient Problem Failure',
  ToggleFilter = '[Problems] Toggle Filter',
  SortProblems = '[Problems] Sort',
  ShowCreateProblemModal = '[Problems] Show Create Modal',
  ShowEditProblemModal = '[Problems] Show Edit Modal',
  ShowViewProblemModal = '[Problems] Show View Problem Modal',
  HideProblemModal = '[Problems] Hide Problem Modal',
  LoadProblemLog = '[Problems] Load Problem Log',
  LoadProblemLogSuccess = '[Problems] Load Problem Log Success',
  LoadProblemLogFailure = '[Problems] Load Problem Log Failure',

  OpenProblemInfo = '[Problems] Open Problem Info',

  ShowProBlemLegendModal = '[Problems] Show Problem Legend Modal',
  HideProblemLegendModal = '[Problems] Hide Problem Legend Modal',
}

export const switchVisualization = createAction(
  ProblemActions.SwitchVisualization
);

export const reloadAllProblemDataStarted = createAction(
  ProblemActions.ReloadAllProblemDataStarted
);

export const loadAllProblemData = createAction(
  ProblemActions.LoadAllProblemData,
  props<{ searchString?: string }>()
);

export const loadMoreProblemData = createAction(
  ProblemActions.LoadMoreProblemData
);

export const loadAllProblemDataSuccess = createAction(
  ProblemActions.LoadAllProblemDataSuccess,
  props<{ problemType: ProblemType[]; pagination?: Pagination }>()
);

export const loadAllProblemDataFailure = createAction(
  ProblemActions.LoadAllProblemDataFailure,
  props<{ error: ApiError }>()
);
export const updateProblemDataSearchString = createAction(
  ProblemActions.UpdateProblemDataSearchString,
  props<{ searchString?: string }>()
);

export const loadAllProblems = createAction(
  ProblemActions.LoadAllProblems,
  props<{ patientId: string; encounterId?: string }>()
);

export const loadAllProblemsSuccess = createAction(
  ProblemActions.LoadAllProblemsSuccess,
  props<{
    problems: PatientProblem[];
    patientId: string;
  }>()
);

export const loadAllProblemsFailure = createAction(
  ProblemActions.LoadAllProblemsFailure,
  props<{ error: ApiError; patientId: string; encounterId: string }>()
);

export const loadAllProblemsAborted = createAction(
  ProblemActions.LoadAllProblemsAborted
);

export const createPatientProblem = createAction(
  ProblemActions.CreatePatientProblem,
  props<{ problem: ProblemFormData }>()
);

export const createPatientProblemSuccess = createAction(
  ProblemActions.CreatePatientProblemSuccess,
  props<{ problem: PatientProblem }>()
);

export const createPatientProblemFailure = createAction(
  ProblemActions.CreatePatientProblemFailure,
  props<{ error: ApiError }>()
);

export const updatePatientProblem = createAction(
  ProblemActions.UpdatePatientProblem,
  props<{ problem: ProblemFormData; problemId: string; patientId: string }>()
);

export const updatePatientProblemSuccess = createAction(
  ProblemActions.UpdatePatientProblemSuccess,
  props<{ problem: PatientProblem }>()
);

export const updatePatientProblemFailure = createAction(
  ProblemActions.UpdatePatientProblemFailure,
  props<{ error: ApiError }>()
);

export const problemDeletionRequired = createAction(
  ProblemActions.ProblemDeletionRequired,
  props<{ problem: PatientProblem }>()
);

export const problemDeletionAborted = createAction(
  ProblemActions.ProblemDeletionAborted
);

export const deletePatientProblem = createAction(
  ProblemActions.DeletePatientProblem,
  props<{ problem: PatientProblem }>()
);

export const deletePatientProblemSuccess = createAction(
  ProblemActions.DeletePatientProblemSuccess,
  props<{ problem: PatientProblem }>()
);

export const deletePatientProblemFailure = createAction(
  ProblemActions.DeletePatientProblemFailure,
  props<{ error: ApiError }>()
);

export const toggleProblemsFilter = createAction(
  ProblemActions.ToggleFilter,
  props<{ key: keyof ProblemsFilter }>()
);

export const sortProblems = createAction(
  ProblemActions.SortProblems,
  props<{ sort?: Sort }>()
);

export const showCreateProblemModal = createAction(
  ProblemActions.ShowCreateProblemModal
);

export const showEditProblemModal = createAction(
  ProblemActions.ShowEditProblemModal,
  props<{ problemToEdit: PatientProblem }>()
);

export const showViewProblemModal = createAction(
  ProblemActions.ShowViewProblemModal,
  props<{ problemToView: PatientProblem }>()
);

export const hideProblemModal = createAction(ProblemActions.HideProblemModal);

export const loadProblemLog = createAction(
  ProblemActions.LoadProblemLog,
  props<{ problemId: string }>()
);

export const loadProblemLogSuccess = createAction(
  ProblemActions.LoadProblemLogSuccess,
  props<{ problemId: string; log: EntityLog }>()
);

export const loadProblemLogFailure = createAction(
  ProblemActions.LoadProblemLogFailure,
  props<{ error: ApiError }>()
);

export const showProblemLegendModal = createAction(
  ProblemActions.ShowProBlemLegendModal
);

export const hideProblemLegendModal = createAction(
  ProblemActions.HideProblemLegendModal
);
