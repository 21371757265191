import { createAction, props } from '@ngrx/store';
import { ApiError } from '../../../../shared/entities/errors';
import { TherapyToConfirm } from '../entities/therapy-to-confirm';

export enum TherapiesConfirmationActions {
  LoadTherapiesToConfirm = '[Therapies Confirmation] Load Therapies To Confirm',
  LoadTherapiesToConfirmSuccess = '[Therapies Confirmation] Load Therapies To Confirm Success',
  LoadTherapiesToConfirmFailure = '[Therapies Confirmation] Load Therapies To Confirm Failure',

  ConfirmTherapy = '[Therapies Confirmation] Confirm Therapy',
  ConfirmTherapySuccess = '[Therapies Confirmation] Confirm Therapy Success',
  ConfirmTherapyFailure = '[Therapies Confirmation] Confirm Therapy Failure',

  ConfirmAllTherapies = '[Therapies Confirmation] Confirm All Therapies',
  ConfirmAllTherapiesSuccess = '[Therapies Confirmation] Confirm All Therapies Success',
  ConfirmAllTherapiesFailure = '[Therapies Confirmation] Confirm All Therapies Failure',
}

/************************* THERAPIES DOWNLOAD *************************/

export const loadTherapiesToConfirm = createAction(
  TherapiesConfirmationActions.LoadTherapiesToConfirm
);

export const loadTherapiesToConfirmSuccess = createAction(
  TherapiesConfirmationActions.LoadTherapiesToConfirmSuccess,
  props<{
    therapies: TherapyToConfirm[];
    patientId: string;
    encounterId: string;
  }>()
);

export const loadTherapiesToConfirmFailure = createAction(
  TherapiesConfirmationActions.LoadTherapiesToConfirmFailure,
  props<{ error: ApiError }>()
);

/************************* CONFIRMATION *************************/

export const confirmTherapy = createAction(
  TherapiesConfirmationActions.ConfirmTherapy,
  props<{ therapyId: string }>()
);

export const confirmTherapySuccess = createAction(
  TherapiesConfirmationActions.ConfirmTherapySuccess,
  props<{ therapyId: string; patientId: string; encounterId: string }>()
);

export const confirmTherapyFailure = createAction(
  TherapiesConfirmationActions.ConfirmTherapyFailure,
  props<{ therapyId: string; error: ApiError }>()
);

/************************* CONFIRM ALL *************************/

export const confirmAllTherapies = createAction(
  TherapiesConfirmationActions.ConfirmAllTherapies
);

export const confirmAllTherapiesSuccess = createAction(
  TherapiesConfirmationActions.ConfirmAllTherapiesSuccess,
  props<{ therapyIds: string[]; patientId: string; encounterId: string }>()
);

export const confirmAllTherapiesFailure = createAction(
  TherapiesConfirmationActions.ConfirmAllTherapiesFailure,
  props<{ therapyIds: string[]; error: ApiError }>()
);
