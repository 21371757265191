import { ReducerTypes, on } from '@ngrx/store';
import { PatientsState, updateEncounterData } from '../../patients.reducers';
import {
  insetNewPathologySuccess,
  loadAllFamilyAnamnesisSuccess,
  removeAllFamilyAnamnesisSuccess,
  removePathologySuccess,
  saveAllFamilyAnamnesisSuccess,

} from 'src/app/features/anamnesis/family-anamnesis/store/family-anamnesis.actions';

export const patientDataFamilyAnamnesisReducerFunctions: ReducerTypes<
  PatientsState,
  [never]
>[] = [
  on(
    saveAllFamilyAnamnesisSuccess,
    (state, { allPathologies, patientId, encounterId }) => {
      return updateEncounterData(state, { patientId, encounterId }, () => ({
        allPathologiesInFamily: allPathologies,
      }));
    }
  ),

  on(
    loadAllFamilyAnamnesisSuccess,

    (state, { allPathologies, patientId, encounterId }) => {
      return updateEncounterData(state, { patientId, encounterId }, () => ({
        allPathologiesInFamily: allPathologies,
      }));
    }
  ),

  on(
    removePathologySuccess,
    (state, { allFamilyPathologies, patientId, encounterId }) => {
      return updateEncounterData(state, { patientId, encounterId }, () => ({
        allPathologiesInFamily: allFamilyPathologies,
      }));
    }
  ),

  on(
    insetNewPathologySuccess,
    (state, { pathology, patientId, encounterId }) => {
      return updateEncounterData(state, { patientId, encounterId }, () => ({
        allPathologiesInFamily: pathology,
      }));
    }
  ),

  on(
    removeAllFamilyAnamnesisSuccess,
    (state, { allFamilyPathologies, patientId, encounterId }) => {
      return updateEncounterData(state, { patientId, encounterId }, () => ({
        allPathologiesInFamily: {
          pathologies: [],
          hasNoPathology: undefined,
          note: allFamilyPathologies.note,
        },
      }));
    }
  ),
];
