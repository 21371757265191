import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutes } from '../../shared/routes/routes';
import { DepartmentsLoaderComponent } from './components/departments-loader/departments-loader.component';
import { DepartmentsComponent } from './departments.component';
import { departmentsCountGuard } from './guards/departments-count.guard';

const routes: Routes = [
  {
    path: '',
    component: DepartmentsComponent,
    canActivate: [departmentsCountGuard],
  },
  {
    path: 'loading',
    component: DepartmentsLoaderComponent,
  },
  {
    path: `:${AppRoutes.Parameters.departmentId}`,
    loadChildren: () =>
      import('../patients/patients.module').then((m) => m.PatientsModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DepartmentsRoutingModule {}
