import { Sort } from '@angular/material/sort';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SearchPatientResultInterface } from '../../../shared/components/modals/search-patients-modal/interface/search-result';
import { Patient } from '../../../shared/entities/patient';
import { ClinicalNote } from '../../clinical-note/interfaces/clinical-note';
import {
  exportPatients,
  exportPatientsFailure,
  exportPatientsSuccess,
  hideEncounter,
  hideSearchPatientModal,
  loadAllPatientsSuccess,
  loadEncountersSuccess,
  searchPatient,
  searchPatientFailure,
  searchPatientSuccess,
  showEncounter,
  showSearchPatientModal,
  sortPatients,
} from '../../patients/store/patients.actions';
import { PatientEncounter } from '../../problems/interfaces/patient-encounter';
import { PatientProblem } from '../../problems/interfaces/patient-problem';
import { ProcedureOccurrence } from '../../procedures/entities/procedure-occurrence';
import { InstrumentalTherapy } from '../../therapies/instrumental-therapies/entities/instrumental-therapy';
import { NutritionalTherapy } from '../../therapies/nutritional-therapies/entities/nutritional-therapy';
import { PharmacologicalTherapy } from '../../therapies/pharmacological-therapies/entities/pharmacological-therapy';
import { TransfusionalTherapy } from '../../therapies/transfusional-therapies/entities/transfusional-therapy';
import { patientNotesReducerFunctions } from './clinical-data/clinical-notes/patients-data.clinical-notes.reducer-functions';
import { patientNursingGanttReducerFunctions } from './clinical-data/nursing-gantt/patients-data.nursing-gantt.reducer-functions';
import { PatientDataNursingGanttState } from './clinical-data/nursing-gantt/patients-data.nursing-gantt.state';
import { patientProblemsReducerFunctions } from './clinical-data/problems/patients-data.problems.reducer-functions';
import { patientProceduresReducerFunctions } from './clinical-data/procedures/patients-data.procedures.reducer-functions';
import { patientInstrumentalTherapiesReducerFunctions } from './clinical-data/therapies/patients-data.instrumental-therapies.reducer-functions';
import { patientNutritionalTherapiesReducerFunctions } from './clinical-data/therapies/patients-data.nutritional-therapies.reducer-functions';
import { patientPharmacologicalTherapiesReducerFunctions } from './clinical-data/therapies/patients-data.pharmacological-therapies.reducer-functions';
import { patientTransfusionalTherapiesReducerFunctions } from './clinical-data/therapies/patients-data.transfusional-therapies.reducer-functions';
import { TherapyToConfirm } from '../../therapies/therapies-confirmation/entities/therapy-to-confirm';
import { patientTherapiesToConfirmReducerFunctions } from './clinical-data/therapies/patients-data.therapies-to-confirm.reducer-functions';

import { patientNewBornFactSheetReducerFunctions } from './clinical-data/anamnesis/patients-data.newBorn-fact-sheet.reducer-functions';

import { AllFamilyPathologies } from '../../anamnesis/family-anamnesis/entities/all-family-pathologies';
import { patientDataFamilyAnamnesisReducerFunctions } from './clinical-data/anamnesis/patients-data-family-anamnesis.reducer-functions';
import { PregnancyCardData } from '../../anamnesis/pregnancy/forms/pregnancy-card-form-data';
import { patientDataPregnancyCardReducerFunctions } from './clinical-data/anamnesis/patients-data-pregnancy-card.reducer-functions';
import { NewBornData } from '../../anamnesis/new-born/form/new-born-form-data';

/**************************** DATA MODELS *****************************/

export interface EncounterData extends PatientEncounter {
  notes?: ClinicalNote[];
  nursingGantt?: PatientDataNursingGanttState;
  procedureOccurrences?: ProcedureOccurrence[];

  pharmacologicalTherapies?: PharmacologicalTherapy[];
  instrumentalTherapies?: InstrumentalTherapy[];
  nutritionalTherapies?: NutritionalTherapy[];
  transfusionalTherapies?: TransfusionalTherapy[];

  therapiesToConfirm?: TherapyToConfirm[];
  newBornFactSheet?: NewBornData;
  //newBornTabs?: NewBornFormData;
  allPathologiesInFamily?: AllFamilyPathologies;
  pregnancyCard?: PregnancyCardData;
}

export interface PatientData extends Patient {
  encounters: EntityState<EncounterData>;
  encountersLoaded: boolean;
  visibleEncounters: EntityState<string>;
  shownEncounterId?: string;

  problems?: PatientProblem[];
}

export type EncountersState = EntityState<EncounterData>;
export interface PatientsState extends EntityState<PatientData> {
  patientsLoaded: boolean;

  showSearchPatientModal: boolean;
  isSearchingPatients: boolean;
  searchPatientResult: SearchPatientResultInterface[];

  sort?: Sort;
  isExportingPatientsList: boolean;
}

export const encountersDataAdapter: EntityAdapter<EncounterData> =
  createEntityAdapter<EncounterData>();
export const patientDataAdapter: EntityAdapter<PatientData> =
  createEntityAdapter<PatientData>();
export const visibleEncountersDataAdapter: EntityAdapter<string> =
  createEntityAdapter<string>({ selectId: (id: string): string => id });

export const initialPatientsState: PatientsState =
  patientDataAdapter.getInitialState({
    patientsLoaded: false,
    isSearchingPatients: false,
    showSearchPatientModal: false,
    searchPatientResult: [],
    isExportingPatientsList: false,
  });

export const initialEncountersState: EncountersState =
  encountersDataAdapter.getInitialState({});

export const initialVisibleEncountersState: EntityState<string> =
  visibleEncountersDataAdapter.getInitialState();

/**************************** REDUCER *****************************/

export const patientsDataReducer = createReducer(
  initialPatientsState,
  on(
    // TODO It should probably be necessary to remove all patients of the current department before adding the new ones
    loadAllPatientsSuccess,
    (state: PatientsState, { patients }: { patients: Patient[] }) => {
      return {
        ...patientDataAdapter.upsertMany(
          patients.map((patient: Patient) => {
            return {
              ...patient,
              encounters: { ...initialEncountersState },
              encountersLoaded: false,
              visibleEncounters: { ...initialVisibleEncountersState },
            };
          }),
          state
        ),

        patientsLoaded: true,
      };
    }
  ),
  on(
    sortPatients,
    (state: PatientsState, { sort }: { sort: Sort }): PatientsState => ({
      ...state,
      sort,
    })
  ),
  on(
    showEncounter,
    (
      state: PatientsState,
      { patientId, encounterId }: { patientId: string; encounterId: string }
    ): PatientsState => {
      return patientDataAdapter.updateOne(
        {
          id: patientId,
          changes: {
            shownEncounterId: encounterId,
            visibleEncounters: visibleEncountersDataAdapter.addOne(
              encounterId,
              state.entities[patientId]?.visibleEncounters ?? {
                ...initialVisibleEncountersState,
              }
            ),
          },
        },
        state
      );
    }
  ),

  on(
    hideEncounter,
    (state: PatientsState, { patientId, encounterId }): PatientsState => {
      return patientDataAdapter.updateOne(
        {
          id: patientId,
          changes: {
            visibleEncounters: visibleEncountersDataAdapter.removeOne(
              encounterId,
              state.entities[patientId]?.visibleEncounters ?? {
                ...initialVisibleEncountersState,
              }
            ),
          },
        },
        state
      );
    }
  ),
  on(
    loadEncountersSuccess,
    (state: PatientsState, { encounters, patientId }) => {
      const patientEncounters: PatientEncounter[] = encounters.filter(
        (encounter: PatientEncounter): boolean =>
          encounter.patientId === patientId
      );
      const shownEncounterId: string | undefined = patientEncounters[0]?.id;

      return patientDataAdapter.updateOne(
        {
          id: patientId,
          changes: {
            encounters: encountersDataAdapter.addMany(patientEncounters, {
              ...initialEncountersState,
            }),
            encountersLoaded: true,
            ...(shownEncounterId != null
              ? {
                  visibleEncounters: visibleEncountersDataAdapter.addOne(
                    shownEncounterId,
                    state.entities[patientId]?.visibleEncounters ?? {
                      ...initialVisibleEncountersState,
                    }
                  ),
                }
              : {}),
            shownEncounterId,
          },
        },
        state
      );
    }
  ),
  on(
    searchPatient,
    (state: PatientsState): PatientsState => ({
      ...state,
      isSearchingPatients: true,
    })
  ),
  on(
    searchPatientSuccess,
    (
      state: PatientsState,
      { result }: { result: SearchPatientResultInterface[] }
    ): PatientsState => ({
      ...state,
      isSearchingPatients: false,
      searchPatientResult: [...result],
    })
  ),
  on(
    searchPatientFailure,
    (state: PatientsState): PatientsState => ({
      ...state,
      isSearchingPatients: false,
    })
  ),
  on(
    showSearchPatientModal,
    (state: PatientsState): PatientsState => ({
      ...state,
      showSearchPatientModal: true,
    })
  ),
  on(
    hideSearchPatientModal,
    (state: PatientsState): PatientsState => ({
      ...state,
      showSearchPatientModal: false,
      searchPatientResult: [],
    })
  ),
  on(
    exportPatients,
    (state: PatientsState): PatientsState => ({
      ...state,
      isExportingPatientsList: true,
    })
  ),
  on(
    exportPatientsSuccess,
    (state: PatientsState): PatientsState => ({
      ...state,
      isExportingPatientsList: false,
    })
  ),
  on(
    exportPatientsFailure,
    (state: PatientsState): PatientsState => ({
      ...state,
      isExportingPatientsList: false,
    })
  ),

  ...patientProblemsReducerFunctions,
  ...patientNursingGanttReducerFunctions,
  ...patientNotesReducerFunctions,
  ...patientProceduresReducerFunctions,
  ...patientInstrumentalTherapiesReducerFunctions,
  ...patientNutritionalTherapiesReducerFunctions,
  ...patientTransfusionalTherapiesReducerFunctions,
  ...patientPharmacologicalTherapiesReducerFunctions,
  ...patientTherapiesToConfirmReducerFunctions,
  ...patientNewBornFactSheetReducerFunctions,
  ...patientDataFamilyAnamnesisReducerFunctions,
  ...patientDataPregnancyCardReducerFunctions
);

/**************************** UTILS *****************************/

type UpdatePatientCallback = (patient?: PatientData) => Partial<PatientData>;

export const updatePatientData = (
  state: PatientsState,
  patientId: string,
  changesFn: UpdatePatientCallback
) => {
  return patientDataAdapter.updateOne(
    {
      id: patientId,
      changes: changesFn(state.entities[patientId]),
    },
    state
  );
};

type UpdateEncounterCallback = (
  encounter?: EncounterData
) => Partial<EncounterData>;

export const updateEncounterData = (
  state: PatientsState,
  patientAndEncounterIds: PatientAndEncounterIds,
  changesFn: UpdateEncounterCallback
) => {
  const { patientId, encounterId } = patientAndEncounterIds;
  return patientDataAdapter.mapOne(
    {
      id: patientId,
      map: (patientData) => ({
        ...patientData,
        encounters: encountersDataAdapter.updateOne(
          {
            id: encounterId,
            changes: changesFn(patientData.encounters.entities[encounterId]),
          },
          patientData.encounters
        ),
      }),
    },
    state
  );
};

export interface PatientAndEncounterIds {
  patientId: string;
  encounterId: string;
}

export interface PatientAndVisibleEncounterIds {
  patientId: string;
  visibleEncounterIds: string[];
}
