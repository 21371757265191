/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a person details.
 */
export interface PersonResource { 
    /**
     * Date of birth of the person.
     */
    birthDate: string;
    /**
     * First name of the person.
     */
    firstName: string;
    /**
     * Unique identifier for the person.
     */
    id: string;
    /**
     * Last name of the person.
     */
    lastName: string;
}

