import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslocoModule } from '@ngneat/transloco';
import { StoreModule } from '@ngrx/store';
import {
  ButtonComponent,
  FullPageLoaderComponent,
  NateaIconsModule,
} from 'natea-components';
import { SharedModule } from '../../shared/shared.module';
import { PatientsListHeaderComponent } from './components/patients-list-header/patients-list-header.component';
import { PatientsListComponent } from './components/patients-list/patients-list.component';
import { PatientsLoaderComponent } from './components/patients-loader/patients-loader.component';
import { PatientsRoutingModule } from './patients-routing.module';
import { PatientsComponent } from './patients.component';
import { selectedPatientReducersMap } from './store/selected-patient/selected-patient.reducers';

@NgModule({
  declarations: [
    PatientsListComponent,
    PatientsLoaderComponent,
    PatientsListHeaderComponent,
    PatientsComponent,
  ],
  imports: [
    CommonModule,
    PatientsRoutingModule,
    TranslocoModule,
    FullPageLoaderComponent,
    MatTableModule,
    MatSortModule,
    SharedModule,
    StoreModule.forFeature('patient', selectedPatientReducersMap, {}),
    NateaIconsModule,
    ButtonComponent,
  ],
  exports: [PatientsListHeaderComponent],
})
export class PatientsModule {}
