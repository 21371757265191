import { createAction, props } from '@ngrx/store';
import { Department } from '../../../shared/entities/department';
import { ApiError } from '../../../shared/entities/errors';

export enum DepartmentsActions {
  LoadDepartments = '[Departments] Load DepartmentsList',
  LoadDepartmentsSuccess = '[Departments] Load DepartmentsList Success',
  LoadDepartmentsFailure = '[Departments] Load DepartmentsList Fail',
}

export const loadDepartments = createAction(DepartmentsActions.LoadDepartments);

export const loadDepartmentsSuccess = createAction(
  DepartmentsActions.LoadDepartmentsSuccess,
  props<{ departments: Department[] }>()
);

export const loadDepartmentsFailure = createAction(
  DepartmentsActions.LoadDepartmentsFailure,
  props<{ error: ApiError }>()
);
