/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Request body for creating a new item.
 */
export interface CreateItemRequest { 
    /**
     * The item to be stored.
     */
    item: { [key: string]: object; };
    /**
     * The version with which the item will be stored.
     */
    itemVersion: string;
}

