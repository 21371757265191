import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteActivatedEvent,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NateaIconsModule } from '../../icons/natea-icons.module';
import { HighlightLetterMatchedPipe } from '../../pipes/highlight-letter-matched.pipe';
import { DropdownItemComponent } from '../../shared/dropdown-item/dropdown-item.component';
import { OptionsItem } from '../../shared/interfaces/options-item';
import { FieldComponent } from '../field/field.component';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'natea-auto-complete',
  standalone: true,
  imports: [
    CommonModule,
    NateaIconsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    HighlightLetterMatchedPipe,
    ReactiveFormsModule,
    DropdownItemComponent,
    LoaderComponent,
  ],
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AutoCompleteComponent,
      multi: true,
    },
  ],
})
export class AutoCompleteComponent<T>
  extends FieldComponent
  implements OnDestroy, OnChanges, ControlValueAccessor
{
  /**
   * If true, the received list is filtered by the input value locally. Otherwise the entire items list is always shown
   */
  @Input() filterList = false;

  @Input() set options(list: OptionsItem<T>[]) {
    const currentItems: OptionsItem<T>[] = this._renderedOptions;
    if (
      list.length <= currentItems.length ||
      currentItems.find(
        (item: OptionsItem<T>, index: number): boolean =>
          item.id !== list[index]?.id
      )
    ) {
      this._renderedOptions = list;
    } else {
      // New list is longer than current list and all initial items are the same
      list.slice(currentItems.length).forEach((item: OptionsItem<T>) => {
        this._renderedOptions.push(item);
      });
    }

    this.onOptionsChanged();
  }

  @Input() autoSelectActiveOption = true;
  @Input() autoActiveFirstOption!: boolean;

  @Input('aria-labelledby') ariaLabelledby = '';
  @Input() placeHolder = '';
  @Input() class: string | string[] = '';
  @Input() showSearchIcon = true;
  @Input() isLoadingMore = false;
  @Input() canLoadMore = true;

  @Input() inputValue = '';

  @Input() set selectedOption(selectedOption: OptionsItem<T> | undefined) {
    this._selectedOption = selectedOption;
    this.onOptionsChanged();
  }

  @Input() panelWidth!: string | number;

  @Output() scrollBottomReached: EventEmitter<void> = new EventEmitter<void>();

  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() opened: EventEmitter<void> = new EventEmitter<void>();
  @Output() optionActivated: EventEmitter<MatAutocompleteActivatedEvent> =
    new EventEmitter<MatAutocompleteActivatedEvent>();

  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();

  isAddClass = false;

  toHighlight = '';

  _selectedOption?: OptionsItem<T>;

  onChange!: (val: OptionsItem<T> | null) => void;

  onTouched!: () => void;

  private thresholdPercent = 0.8;

  _renderedOptions: OptionsItem<T>[] = [];
  get renderedOptions(): OptionsItem<T>[] {
    const searchString = this.toHighlight.toLowerCase();
    const {
      _renderedOptions: options,
      _selectedOption,
    }: AutoCompleteComponent<T> = this;

    let result = options;

    if (this.filterList && searchString) {
      result = options.filter((option: OptionsItem<T>): boolean =>
        option.label.toLowerCase().includes(searchString)
      );
    }

    if (
      _selectedOption &&
      !searchString &&
      !result.find(
        (option: OptionsItem<T>): boolean => option.id === _selectedOption.id
      )
    ) {
      return [...result, _selectedOption];
    }
    return result;
  }

  @ViewChild('autoComplete', {
    static: false,
    read: MatAutocomplete,
  })
  autoComplete!: MatAutocomplete;

  @ViewChild('valueContainer') valueContainer!: ElementRef<HTMLInputElement>;

  /******************** CONTROL VALUE ACCESSOR *********************/

  writeValue(obj: OptionsItem<T>): void {
    this._selectedOption = obj;
    this.onOptionsChanged();
  }

  registerOnChange(fn: (val: OptionsItem<T> | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /******************** LIFECYCLE *********************/

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedOption']) {
      this.onOptionsChanged();
    }
  }

  ngOnDestroy(): void {
    this.removeScrollEventListener();
  }

  /******************** CALLBACKS *********************/

  private onOptionsChanged = (): void => {
    if (this._selectedOption) {
    }
  };

  focusOut = (event: Event): void => {
    this.isAddClass = !!(event.target as HTMLInputElement).value;
  };

  registerPanelOpen = (): void => {
    this.onTouched();
    setTimeout(() => {
      this.removeScrollEventListener();
      this.autoComplete.panel.nativeElement.addEventListener(
        'scroll',
        this.onScroll.bind(this)
      );
    }, 0);
    this.opened.emit();
  };

  removeEventListenerAfterClosed = (): void => {
    this.removeScrollEventListener();
    this.closed.emit();
  };

  private removeScrollEventListener(): void {
    if (this.autoComplete?.panel) {
      this.autoComplete.panel.nativeElement.removeEventListener(
        'scroll',
        this.onScroll
      );
    }
  }

  onScroll(event: Event): void {
    if (
      !this.canLoadMore ||
      this.thresholdPercent === undefined ||
      this.isLoadingMore
    ) {
      // Nothing to do
    } else {
      const target: HTMLElement = event.target as HTMLElement;
      const threshold: number =
        (this.thresholdPercent * 100 * target.scrollHeight) / 100;
      const current: number = target.scrollTop + target.clientHeight;
      if (current > threshold) {
        this.scrollBottomReached.emit();
      }
    }
  }

  displayWith = (option?: OptionsItem<T> | string | null): string => {
    if (option === undefined || option === null) {
      return '';
    } else if (typeof option === 'string') {
      return option;
    } else {
      return option.label;
    }
  };

  onInput(event: Event): void {
    const newValue: string = (event.target as HTMLInputElement).value;
    this.toHighlight = newValue;
    this.valueChanged.emit(newValue);
    this.isAddClass = !!(event.target as HTMLInputElement).value;
    event.stopPropagation();
  }

  onOptionSelected = (event: MatAutocompleteSelectedEvent): void => {
    this.selectedOption = event.option.value as OptionsItem<T>;
    this.onChange(event.option.value as OptionsItem<T>);
  };

  onFocus = (): void => {
    this.valueContainer.nativeElement?.select();
  };
}
