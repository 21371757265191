<natea-base-modal
  [modalTitle]="t('searchPatient')"
  (closeModal)="onCloseModal()"
  class="search-patients-modal"
  [modalSize]="modalSize"
  *transloco="let t; read: 'patient.search'"
>
  <form
    [formGroup]="searchPatientForm"
    (ngSubmit)="onSearchPatient()"
    *ngIf="{
    disableButtons: (results$ | async) ??  [],
  } as context"
  >
    <div class="modal-content">
      <natea-input
        class="form-control"
        [label]="t('surname')"
        formControlName="surname"
        [fillParentWidth]="isSearchPatientForm"
      ></natea-input>
      <natea-input
        class="form-control"
        [label]="t('name')"
        formControlName="name"
        [fillParentWidth]="isSearchPatientForm"
      ></natea-input>
      <natea-input
        class="form-control"
        [label]="t('surnameNameMother')"
        formControlName="motherName"
        [fillParentWidth]="isSearchPatientForm"
      ></natea-input>
      <natea-slide-toggle
        class="form-control"
        labelPosition="before"
        [label]="t('includeDischarged')"
        formControlName="includeDischarged"
        [fillParentWidth]="isSearchPatientForm"
      ></natea-slide-toggle>
      <natea-date-picker
        class="form-control"
        [label]="t('dateBirth')"
        formControlName="dateBirth"
        [fillParentWidth]="isSearchPatientForm"
      ></natea-date-picker>
      <natea-input
        class="form-control"
        [label]="t('gestationalAgeMin')"
        formControlName="gestationalAgeMin"
        [fillParentWidth]="isSearchPatientForm"
        [type]="'number'"
      ></natea-input>
      <natea-input
        class="form-control"
        [label]="t('gestationalAgeMax')"
        formControlName="gestationalAgeMax"
        [fillParentWidth]="isSearchPatientForm"
        [type]="'number'"
      ></natea-input>
      <natea-input
        class="form-control"
        [label]="t('weightMin')"
        formControlName="weightMin"
        [fillParentWidth]="isSearchPatientForm"
        [type]="'number'"
      ></natea-input>
      <natea-input
        class="form-control"
        [label]="t('weightMax')"
        formControlName="weightMax"
        [fillParentWidth]="isSearchPatientForm"
        [type]="'number'"
      ></natea-input>
    </div>
    <div class="modal-footer">
      <div class="reset-action">
        <natea-button
          buttonType="reset"
          [label]="t('reset')"
          [color]="ButtonVariants.TERTIARY"
          [disabled]="!searchPatientForm.dirty"
        >
        </natea-button>
      </div>
      <div class="cancel-submit-actions">
        <natea-button
          (buttonClick)="onCloseModal()"
          [color]="ButtonVariants.SECONDARY"
          [label]="t('cancel')"
          [disabled]="!formActionsEnabled"
        >
        </natea-button>
        <natea-button
          buttonType="submit"
          [label]="t('search')"
          [disabled]="!formActionsEnabled || !searchPatientForm.valid"
          [showLoader]="(isSearchingPatients$ | async) ?? false"
        >
        </natea-button>
      </div>
    </div>
  </form>
  <div *ngIf="isAnyResults" class="modal-result">
    <natea-cc-patients-result-table
      [results]="(results$ | async) ?? []"
      (navigateToPatient)="onNavigate($event)"
      (closed)="onCloseModal()"
    >
    </natea-cc-patients-result-table>
  </div>
</natea-base-modal>
