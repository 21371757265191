import { HttpStatusCode } from '@angular/common/http';

export enum ApiErrorLevel {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export enum FieldErrorCodes {
  Timeout = 'timeout',
}

export interface ApiFieldError {
  code: string | FieldErrorCodes;
  message: string;
  type: string;
  level: ApiErrorLevel;
  field: string;
}

export interface ApiError {
  code: number;
  correlationId: string;
  datetime: Date;
  errors: ApiFieldError[];
}

export const errorMessage = (apiError: ApiError): string | undefined => {
  return apiError.errors?.at(0)?.message ?? undefined;
};

export const localError = (
  errorCode: HttpStatusCode,
  errorMessage?: string
): ApiError => ({
  code: errorCode,
  correlationId: 'local-FE-error',
  datetime: new Date(),
  errors: errorMessage
    ? [
        {
          code: errorCode.toString(),
          message: errorMessage,
          type: '',
          level: ApiErrorLevel.ERROR,
          field: '',
        },
      ]
    : [],
});
