<div class="container">
  <div class="sub-container" [class.text-pill-box-with-box]="hasBox">
    <section
      [ngClass]="currentClasses"
      class="container-text-pill"
      [class.container-text-pill-box]="showBox"
      [class.container-text-pill-with-box]="hasBox"
    >
      <natea-icons *ngIf="iconName" [name]="iconName"></natea-icons>
      <p>{{ label }}</p>
    </section>
    <div>
      <natea-icons
        *ngIf="hasBox"
        name="arrow-down"
        [class.arrow-right]="!showBox"
        (iconClick)="toggleBox()"
      ></natea-icons>
    </div>
  </div>

  <div *ngIf="showBox" class="main-box" [ngClass]="currentClasses">
    <ng-content></ng-content>
  </div>
</div>
