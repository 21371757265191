import { DateRange, areRangesOverlapping } from '../../../models/date-range';
import { ProcedureOccurrenceDurationType } from '../models/multi-section-timeline-instance';
import {
  PositionedRecurringOccurrence,
  TimelinePositionedInstance,
  TimelinePositionedOccurrence,
} from '../models/positioned-timeline-item';
import {
  dateRangeFromInstantOccurrence,
  dateRangeFromProlongedOccurrence,
  dateRangeFromRecurringOccurrence,
} from './timeline-items-ranges';

export interface CollisionsFinderResult {
  instance: TimelinePositionedInstance<unknown>;
  maxCollisionDepth: number;
}

export class CollisionsFinder {
  maxCollisionDepth = 0;

  findMultisectionItemCollisions = (
    instance: TimelinePositionedInstance<unknown>
  ): CollisionsFinderResult => {
    this.maxCollisionDepth = 0;

    let itemsToCheck = instance.occurrences
      .map((occurrence) => this.mapOccurrenceToCollisionItem(occurrence))
      .filter((item): item is CollisionItem => !!item);

    while (itemsToCheck.length > 0) {
      this.sortItemsToCheck(itemsToCheck);

      const collidingItems = new Set<CollisionItem>();

      this.findItemsCollisions(itemsToCheck, collidingItems);

      itemsToCheck = [...collidingItems.values()];
    }

    instance.occurrences.forEach((occurrence) => {
      if (
        occurrence.type === ProcedureOccurrenceDurationType.Recurring &&
        occurrence.collisionDepth
      ) {
        this.updateRecurringItemChildrenCollisionDepth(occurrence);
      }
    });

    return {
      instance,
      maxCollisionDepth: this.maxCollisionDepth,
    };
  };

  private findItemsCollisions = (
    itemsToCheck: CollisionItem[],
    collidingItems: Set<CollisionItem>
  ) => {
    for (let i = 0; i < itemsToCheck.length - 1; i++) {
      for (let j = i + 1; j < itemsToCheck.length; j++) {
        if (
          !collidingItems.has(itemsToCheck[j]) &&
          areRangesOverlapping(itemsToCheck[i].range, itemsToCheck[j].range)
        ) {
          collidingItems.add(itemsToCheck[j]);
          const newCollisionDepth =
            (itemsToCheck[j].instance.collisionDepth ?? 0) + 1;
          if (newCollisionDepth > this.maxCollisionDepth) {
            this.maxCollisionDepth = newCollisionDepth;
          }
          itemsToCheck[j].instance.collisionDepth = newCollisionDepth;
        }
      }
    }
  };

  private updateRecurringItemChildrenCollisionDepth = (
    recurringOccurrence: PositionedRecurringOccurrence<unknown>
  ) => {
    recurringOccurrence.occurrences.forEach((occurrence) => {
      occurrence.collisionDepth = recurringOccurrence.collisionDepth;
    });
  };

  sortItemsToCheck = (itemsToCheck: CollisionItem[]) => {
    itemsToCheck.sort((a, b) => {
      return a.range.startDate.getTime() - b.range.startDate.getTime();
    });
  };

  mapOccurrenceToCollisionItem = (
    occurrence: TimelinePositionedOccurrence<unknown>
  ): CollisionItem | undefined => {
    if (occurrence.type === ProcedureOccurrenceDurationType.Instant) {
      return {
        range: dateRangeFromInstantOccurrence(occurrence),
        instance: occurrence,
      };
    } else if (occurrence.type === ProcedureOccurrenceDurationType.Prolonged) {
      return {
        range: dateRangeFromProlongedOccurrence(occurrence),
        instance: occurrence,
      };
    } else if (occurrence.type === ProcedureOccurrenceDurationType.Recurring) {
      const range = dateRangeFromRecurringOccurrence(occurrence);

      if (!range) {
        return undefined;
      }
      return {
        range,
        instance: occurrence,
      };
    }
    return undefined;
  };
}

interface CollisionItem {
  range: DateRange;
  instance: TimelinePositionedOccurrence<unknown>;
}
