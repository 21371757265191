<natea-base-modal
  [modalTitle]="title"
  (closeModal)="close()"
  *transloco="let t; read: 'common.buttons'"
  [modalSize]="modalSize"
>
  <div [class.text-center]="alignText == 'center'" *ngIf="isSimpleText">
    {{ TextData }}
  </div>

  <natea-cc-text-and-optional-icon
    *ngIf="isTextAndIcon"
    [textsAndOptionalIcons]="dataSource"
  ></natea-cc-text-and-optional-icon>
  <natea-cc-table-legend
    *ngIf="isTable"
    [showHeader]="false"
    [dataSource]="dataSource || []"
  ></natea-cc-table-legend>
  <footer *ngIf="showFooter">
    <natea-button
      buttonType="button"
      (buttonClick)="close()"
      [label]="t('close')"
    ></natea-button>
  </footer>
</natea-base-modal>
