/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import {
  DetectedProblemApiService,
  DetectedProblemCreateRequestResource,
  DetectedProblemPatchRequest,
  DetectedProblemResource,
  GeneralResponseDetectedProblemResource,
  SearchResponseListDetectedProblemResource,
} from '../generated/api';
import { createDetectedProblemsMock } from './get-detected-problems-mock';
import getProblemsMockedResponse from './get-problems-mocked-response';

@Injectable({
  providedIn: 'root',
})
export class MockedProblemiRilevatiApiService extends DetectedProblemApiService {
  public override searchPatientDetectedProblems(
    patientId: string
  ): Observable<any> {
    const result: SearchResponseListDetectedProblemResource = {
      payload: createDetectedProblemsMock(patientId),
    };
    return of(result).pipe(delay(10));
  }

  public override patchDetectedProblem(
    detectedProblemId: string,
    detectedProblemPatchRequest: DetectedProblemPatchRequest,
    observe?: any,
    reportProgress?: boolean,
    options?:
      | {
          httpHeaderAccept?: '*/*' | undefined;
          context?: HttpContext | undefined;
        }
      | undefined
  ): Observable<any> {
    const payload: DetectedProblemResource = {
      createdBy: {
        id: '1',
        firstName: 'Giuseppe',
        lastName: 'Manzoni',
      },
      patientId: '1',
      additionalNotes: detectedProblemPatchRequest.description ?? '',
      endDate: detectedProblemPatchRequest.endDate ?? '',
      id: detectedProblemId,
      createdAt: new Date().toISOString(),
      permissions: {
        canDelete: true,
        canUpdate: true,
      },
      startDate: detectedProblemPatchRequest.startDate ?? '',
      problemCatalogEntry:
        getProblemsMockedResponse(1, undefined, 10000).payload?.find(
          (problem) => problem.id === detectedProblemPatchRequest.problemId
        ) ?? mockedProblemCatalogEntry,
      startEncounterId: '1',
    };
    const result: GeneralResponseDetectedProblemResource = {
      payload,
    };
    return of(result).pipe(delay(2000));
  }

  public override createDetectedProblemToPatientEncounter(
    patientId: string,
    startEncounterId: string,
    detectedProblemCreateRequestResource: DetectedProblemCreateRequestResource,
    observe?: any,
    reportProgress?: boolean,
    options?:
      | {
          httpHeaderAccept?: 'application/json' | undefined;
          context?: HttpContext | undefined;
        }
      | undefined
  ): Observable<any> {
    const result: GeneralResponseDetectedProblemResource = {
      payload: {
        id: '1',
        patientId: '1',

        additionalNotes: 'description',
        // IN ISO FORMAT
        startDate: detectedProblemCreateRequestResource.startDate,

        /*encounter: {
          // departmentId?: string;
          // endDate?: string;
          // id?: string;
          // nosographicId?: string;
          // patientId?: string;
          // startDate?: string;
          startDate: '2023-01-01T00:00:00.000Z',
        },*/
        createdAt: new Date().toISOString(),
        problemCatalogEntry:
          getProblemsMockedResponse(1, undefined, 10000).payload?.find(
            (problem) =>
              problem.id ===
              detectedProblemCreateRequestResource.problemCatalogEntryId
          ) ?? mockedProblemCatalogEntry,

        startEncounterId: '1',
        createdBy: {
          id: '1',
          firstName: 'Giuseppe',
          lastName: 'Manzoni',
        },
        permissions: {
          canDelete: true,
          canUpdate: true,
        },
      },
    };
    return of(result).pipe(delay(2000));
  }

  override deleteDetectedProblem(detectedProblemId: string): Observable<any> {
    return of({}).pipe(delay(2000));
  }
}

const mockedProblemCatalogEntry = {
  id: '1',
  isEnabled: true,
  name: 'name',
  description: 'description',
  problemClass: 'M',
};
