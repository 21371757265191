import { Injectable } from '@angular/core';
import { Observable, map, throwError } from 'rxjs';
import {
  ClinicalNoteApiService,
  NoteResource,
  SearchResponseListNoteResource,
} from '../../../core/generated/api';
import { ClinicalNote } from '../interfaces/clinical-note';
import { NewNoteFormValues } from '../interfaces/new-note-form';
import { ClinicalNotesAdapters } from './clinical-notes.adapters';

@Injectable({
  providedIn: 'root',
})
export class ClinicalNotesWebAPI {
  constructor(private clinicalNoteService: ClinicalNoteApiService) {}

  public getNotesForPatient(
    patientId: string,
    encounterId: string,
    startDate?: string,
    endDate?: string,
    description?: string,
    authorId?: string,
    itemPage?: number,
    type?: string,
    status?: string[],
    page?: number
  ): Observable<ClinicalNote[]> {
    return this.clinicalNoteService
      .getClinicalNotes(
        patientId,
        encounterId,
        authorId,
        type,
        status,
        startDate,
        endDate,
        description,
        itemPage,
        page
      )
      .pipe(
        map(
          (notes: SearchResponseListNoteResource) =>
            notes.payload?.map((note: NoteResource) =>
              ClinicalNotesAdapters.clinicalNoteBeToFe(note)
            ) ?? []
        )
      );
  }

  public createClinicalNote(
    note: NewNoteFormValues,
    patientId?: string,
    encounterId?: string
  ): Observable<ClinicalNote> {
    if (!patientId || !encounterId) {
      throw Error('Problem not created');
    }
    return this.clinicalNoteService
      .createClinicalNoteForPatientEncounter(
        patientId,
        encounterId,
        ClinicalNotesAdapters.ClinicalNoteFeToCreate(note)
      )
      .pipe(
        map((result) => {
          if (!result.payload) {
            throw Error('Problem not created');
          }
          return ClinicalNotesAdapters.clinicalNoteBeToFe(result.payload);
        })
      );
  }

  public getClinicalNotebyId(clinicalNoteId: string): Observable<ClinicalNote> {
    return this.clinicalNoteService
      .getClinicalNoteById(clinicalNoteId)
      .pipe(
        map((result) =>
          ClinicalNotesAdapters.clinicalNoteBeToFe(result.payload!)
        )
      );
  }

  public updateClinicalNote(
    clinicalNote: ClinicalNote
  ): Observable<ClinicalNote> {
    const clinicalNoteId = clinicalNote.id;

    if (!clinicalNoteId) {
      return throwError(() => new Error('Problem id is mandatory')); // TODO correct error type
    }
    return this.clinicalNoteService
      .putNote(
        clinicalNoteId,
        ClinicalNotesAdapters.clinicalNoteFeToUpdate(clinicalNote) // TODO non è stata validata perché manca la definizione nello swagger
      )
      .pipe(map(() => clinicalNote));
  }

  public deleteClinicalNote(
    patientId: string,
    clinicalNoteiId: string
  ): Observable<string> {
    return this.clinicalNoteService
      .deleteNote(clinicalNoteiId)
      .pipe(map(() => clinicalNoteiId));
  }
}
