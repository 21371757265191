import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import {
  Translation,
  TranslocoLoader,
  TranslocoModule,
  TranslocoService,
  provideTransloco,
} from '@ngneat/transloco';
import { Observable, forkJoin, map } from 'rxjs';
import { environment } from '../environments/environment';

// Preloading translations

export function preLoadTranslations(transloco: TranslocoService) {
  return function (): Observable<Translation> {
    return transloco.load('it');
  };
}

export const preLoad = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: preLoadTranslations,
  deps: [TranslocoService],
};

// Loader

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private translationFiles = [
    'anamnesis',
    'board',
    'clinicalNote',
    'log',
    'common',
    'problems',
    'clinicalNote',
    'encounter',
    'sidebar',
    'departments',
    'login',
    'patient',
    'nurseRecords',
    'procedures',
    'therapies',
  ];

  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    // return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    return this.getAllTranslations(lang);
  }

  /***
   * Could be used to split the translation file into multiple files
   */
  getAllTranslations(lang: string) {
    // Define the URL for the directory containing the JSON files
    const directoryUrl = `/assets/i18n/${lang}`;
    const requests = this.translationFiles.map((fileName) =>
      this.http.get(`${directoryUrl}/${fileName}.json`)
    );

    return forkJoin(requests).pipe(
      map((responses) => {
        // Merge the content of all JSON files into a single translation object
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return responses.reduce((translations: any, response, index) => {
          const fileName = this.translationFiles[index].replace('.json', ''); // Get the translation key from the file name
          translations[fileName] = response;
          return translations;
        }, {});
      })
    );
  }
}

// Module setup

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['it'],
        defaultLang: 'it',
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
    preLoad,
  ],
})
export class TranslocoRootModule {}
