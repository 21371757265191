import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  MatSelect,
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';
import { NateaIconsName } from '../../icons/natea-icon';
import { NateaIconsModule } from '../../icons/natea-icons.module';
import { OptionsItem } from '../../shared/interfaces/options-item';
import { isScrollAtBottom } from '../../utils/scroll';
import { FieldErrorComponent } from '../field/field-error/field-error.component';
import { FieldComponent } from '../field/field.component';
import { LoaderComponent } from '../loader/loader.component';

// TODO manage correctly the accessibility of the label, since Chrome is notifying that it is not accessible since the for attribute is missing

@Component({
  standalone: true,
  selector: 'natea-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DropdownComponent, multi: true },
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    NateaIconsModule,
    LoaderComponent,
    ReactiveFormsModule,
    FieldErrorComponent,
  ],
})
export class DropdownComponent<T>
  extends FieldComponent
  implements OnChanges, ControlValueAccessor
{
  @ViewChild('select', { static: false }) selectElement?: MatSelect;

  isAddClass = false;

  @Input() leftIcon?: NateaIconsName;

  @Input() class: string | string[] = '';

  @Input() items: OptionsItem<T>[] = [];

  @Input() isLink = false;

  @Input() isLoadingMore = false;

  @Output() selectedItemChange: EventEmitter<OptionsItem<T> | undefined> =
    new EventEmitter<OptionsItem<T> | undefined>();
  @Output() scrollBottomReached: EventEmitter<void> = new EventEmitter<void>();

  selectedItem?: OptionsItem<T>;

  selectedValue = '';

  hasValue = false;

  control: FormControl = new FormControl();

  get fieldClass() {
    return `field ${this.class} ${this.isLink ? 'link' : ''}`;
  }

  get renderedItems(): OptionsItem<T>[] {
    const { items: options, selectedItem: selectedOption } = this;
    if (
      selectedOption &&
      !options.find(
        (option: OptionsItem<T>): boolean => option.id === selectedOption.id
      )
    ) {
      return [...options, selectedOption];
    }
    return options;
  }
  onChange!: (val: OptionsItem<T> | null) => void;

  onTouched!: () => void;

  markAsTouched = (): void => {
    if (this.onTouched) {
      this.onTouched();
    }
  };

  writeValue(obj: OptionsItem<T>): void {
    this.selectedItem = obj;
  }

  registerOnChange(fn: (val: OptionsItem<T> | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState = (isDisabled: boolean): void => {
    this.isDisabled = isDisabled;
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['leftIcon']?.currentValue) {
      if (this.leftIcon) {
        this.hasValue = true;
      }
    }
  }

  public onValueChange(selection: MatSelectChange): void {
    const itemId = selection.value;

    const item = this.items.find(
      (item: OptionsItem<T>): boolean => item.id === itemId
    );
    if (item) {
      this.selectedItem = item;
      this.selectedValue = item.triggerLabel ?? item.label;
      if (this.onChange) {
        this.onChange(item);
      }
      this.isAddClass = true;
    }
    this.selectedItemChange.emit(item);

    setTimeout(() => {
      if (this.items.some((item: OptionsItem<T>): boolean => !!item.iconName)) {
        const element =
          this.selectElement?._elementRef.nativeElement.querySelector(
            '.mat-mdc-select > .mat-mdc-select-trigger > .mat-mdc-select-value > .mat-mdc-select-value-text'
          );

        if (element) {
          element.style.paddingLeft = '1.5rem';
        }
      }
    }, 0);

    if (itemId) {
      this.hasValue = true;
    }
  }

  onOpenedChange(isOpened: boolean): void {
    const nativeElement = this.selectElement?.panel?.nativeElement;
    if (isOpened) {
      this.markAsTouched();
      nativeElement?.addEventListener('scroll', this.scrollListener);
    } else {
      nativeElement?.removeEventListener('scroll', this.scrollListener);
    }
  }

  private scrollListener = (): void => {
    if (isScrollAtBottom(this.selectElement?.panel?.nativeElement)) {
      this.scrollBottomReached.emit();
    }
  };

  attributeDisplay = (attribute1: unknown, attribute2: unknown): boolean =>
    attribute1 === attribute2;
}
