import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService } from 'natea-components';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { localError } from '../../../../shared/entities/errors';
import { SNACK_BAR_DEBOUNCE_TIME_MS } from '../../../../shared/utils/constants';
import { selectOngoingEncounterAndPatientIds } from '../../../patients/store/patients.selectors';
import { TherapiesConfirmationWebAPI } from '../webapis/therapies-confirmation.webapi';
import {
  TherapiesConfirmationActions,
  confirmAllTherapies,
  confirmAllTherapiesFailure,
  confirmAllTherapiesSuccess,
  confirmTherapy,
  confirmTherapyFailure,
  confirmTherapySuccess,
  loadTherapiesToConfirm,
  loadTherapiesToConfirmFailure,
  loadTherapiesToConfirmSuccess,
} from './therapies-confirmation.actions';
import { selectConfirmableTherapies } from './therapies-confirmation.selectors';

@Injectable()
export class TherapiesConfirmationEffects {
  /************************* THERAPIES DOWNLOAD *************************/

  loadTherapiesToConfirm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadTherapiesToConfirm),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      exhaustMap(([, onGoingEncounterIds]) => {
        if (!onGoingEncounterIds)
          return of(
            loadTherapiesToConfirmFailure({
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        const { patientId, encounterId } = onGoingEncounterIds;
        return this.therapiesConfirmationApi
          .getTherapiesToConfirm({ patientId, encounterId })
          .pipe(
            map((therapies) =>
              loadTherapiesToConfirmSuccess({
                therapies,
                patientId,
                encounterId,
              })
            ),
            catchError((error) => of(loadTherapiesToConfirmFailure({ error })))
          );
      })
    );
  });

  loadTherapiesToConfirmFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loadTherapiesToConfirmFailure),
        map(() => {
          this.snackbarService.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.loadTherapiesToConfirmFailure'
            ),
            this.translocoService.translate('problems.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS,
            'bottom'
          );
        })
      );
    },
    { dispatch: false }
  );

  /************************* CONFIRMATION *************************/

  confirmTherapy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(confirmTherapy),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      exhaustMap(([{ therapyId }, onGoingEncounterIds]) => {
        if (!onGoingEncounterIds)
          return of(
            confirmTherapyFailure({
              therapyId,
              error: localError(HttpStatusCode.BadRequest),
            })
          );
        const { patientId, encounterId } = onGoingEncounterIds;
        return this.therapiesConfirmationApi
          .confirmTherapy({
            therapyId,
          })
          .pipe(
            map(() => {
              return confirmTherapySuccess({
                therapyId,
                patientId,
                encounterId,
              });
            }),
            catchError((error) => {
              return of(
                confirmTherapyFailure({
                  therapyId,
                  error,
                })
              );
            })
          );
      })
    );
  });

  confirmTherapySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TherapiesConfirmationActions.ConfirmTherapySuccess),
        map(() => {
          this.snackbarService.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.therapyConfirmSuccess'
            ),
            this.translocoService.translate('problems.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS,
            'bottom'
          );
        })
      );
    },
    { dispatch: false }
  );

  confirmTherapyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TherapiesConfirmationActions.ConfirmTherapyFailure),
        map(() => {
          this.snackbarService.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.therapyConfirmFailure'
            ),
            this.translocoService.translate('problems.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS,
            'bottom'
          );
        })
      );
    },
    { dispatch: false }
  );

  /************************* CONFIRM ALL *************************/

  confirmAllTherapies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(confirmAllTherapies),
      concatLatestFrom(() => [
        this.store.select(selectOngoingEncounterAndPatientIds),
        this.store.select(selectConfirmableTherapies),
      ]),
      exhaustMap(([{}, onGoingEncounterIds, therapies]) => {
        const therapyIds = therapies?.map((therapy) => therapy.id) ?? [];

        if (!onGoingEncounterIds || !therapyIds || therapyIds.length === 0)
          return of(
            confirmAllTherapiesFailure({
              therapyIds,
              error: localError(HttpStatusCode.BadRequest),
            })
          );

        const { patientId, encounterId } = onGoingEncounterIds;

        return this.therapiesConfirmationApi
          .confirmTherapies({
            therapyIds,
          })
          .pipe(
            map(() => {
              return confirmAllTherapiesSuccess({
                therapyIds,
                patientId,
                encounterId,
              });
            }),
            catchError((error) => {
              return of(
                confirmAllTherapiesFailure({
                  therapyIds,
                  error,
                })
              );
            })
          );
      })
    );
  });

  confirmAllTherapiesSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(confirmAllTherapiesSuccess),
        map(() => {
          this.snackbarService.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.therapyConfirmAllSuccess'
            ),
            this.translocoService.translate('problems.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS,
            'bottom'
          );
        })
      );
    },
    { dispatch: false }
  );

  confirmAllTherapiesFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(confirmAllTherapiesFailure),
        map(() => {
          this.snackbarService.showSnackbar(
            this.translocoService.translate(
              'therapies.notifications.therapyConfirmAllFailure'
            ),
            this.translocoService.translate('problems.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS,
            'bottom'
          );
        })
      );
    },
    { dispatch: false }
  );

  /************************* CONSTRUCTOR *************************/

  constructor(
    private actions$: Actions,
    private store: Store,
    private therapiesConfirmationApi: TherapiesConfirmationWebAPI,
    private snackbarService: SnackbarService,
    private translocoService: TranslocoService
  ) {}
}
