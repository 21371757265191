import { Injectable } from '@angular/core';
import { Observable, of, delay } from 'rxjs';
import { therapiesToConfirmMock } from '../mocks/therapies-confirmation.mocks';
import { TherapyToConfirm } from '../entities/therapy-to-confirm';

@Injectable({
  providedIn: 'root',
})
export class TherapiesConfirmationWebAPI {
  // TODO define with BE
  public getTherapiesToConfirm({}: {
    patientId: string;
    encounterId: string;
  }): Observable<TherapyToConfirm[]> {
    return of(therapiesToConfirmMock).pipe(delay(1000));
  }

  confirmTherapy({ therapyId }: { therapyId: string }): Observable<boolean> {
    return of(true).pipe(delay(1000));
    // return defer(() => {
    //   throw new Error('Not implemented');
    // }).pipe(delay(1000));
  }
  confirmTherapies({
    therapyIds,
  }: {
    therapyIds: string[];
  }): Observable<boolean> {
    return of(true).pipe(delay(1000));
    // return defer(() => {
    //   throw new Error('Not implemented');
    // }).pipe(delay(1000));
  }
}
