import { NewBornData } from '../form/new-born-form-data';

const notes =
  'Montes euismod nullam bibendum ultricies duis imperdiet lacinia nibh justo porttitor. Pellentesque urna blandit vehicula potenti neque conubia. In lobortis vulputate hendrerit aenean habitant consectetur elit mattis eleifend eget.';

export const factSheetNewBornMock = (): NewBornData => ({
  auxologicalData: {
    weightInCentimeter: '2.6',
    withInCentimeter: '49',
    headCircumference: '35',
  },
  general: {
    directCoombsTest: { key: '1', label: 'Debolmente positivo' },
    noteOnDirectCoombsTest: notes,
    firstborn: true,
    delayedClamping: false,
    timeInSec: { key: '1', label: '10' },
    milking: true,
    collection: { key: '1', label: 'Sì' },
    uterineTransfer: { key: '1', label: 'Sì' },
    emissionUrine: { key: '1', label: 'Sì' },
    emissionMeconium: { key: '1', label: 'Sì' },
    surfacente: { key: '1', label: 'Sì' },
    surfacenteDate: new Date('2024-05-01'),
  },

  blood: {
    group: { key: '1', label: 'A' },
    rhFactor: { key: '1', label: 'Rh+' },
    collectionGroup: true,
  },

  occultProphylaxis: {
    executionDate: new Date('2024-05-01'),
    pharmacy: {
      key: '1',
      label: "unguento oftalmico di tetraciclina idrocloridrata all'1%",
    },
    author: {
      id: '123',
      firstName: 'Michele',
      lastName: 'Verdi',
    },

    executed: true,
  },

  vitaminK: {
    executionDate: new Date('2024-05-01'),
    vitaminK: { key: '1', label: '1 mg' },
    route: { key: '1', label: 'Orale' },
    executed: true,
    author: {
      id: '123',
      firstName: 'Michele',
      lastName: 'Verdi',
    },
  },
  note: notes,

  completeSheet: true,
  percentileWeight: '50',
  percentileLength: '65',
  percentileHeadCircumference: '75',
});
