import { createAction, props } from '@ngrx/store';
import { TherapyOccurrenceState } from '../../../../therapies/common/entities/therapy-occurrence';

export enum InstrumentalTherapiesAdministrationAction {
  FilterTherapiesState = '[Instrumental Therapies Administration] Filter Therapies State',
  FilterTherapiesDate = '[Instrumental Therapies Administration] Filter Therapies Date',
}

export const filterTherapiesState = createAction(
  InstrumentalTherapiesAdministrationAction.FilterTherapiesState,

  props<{
    therapyOccurrenceState?: TherapyOccurrenceState;
  }>()
);

export const filterTherapiesDate = createAction(
  InstrumentalTherapiesAdministrationAction.FilterTherapiesDate,

  props<{ date?: Date }>()
);
