import { Sort } from '@angular/material/sort';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { DomainItem } from '../../../../shared/entities/domain-item';
import { SelectedTherapyOccurrenceIds } from '../../common/entities/selected-therapy';
import { PharmacologicalTherapyCancelSuspend } from '../entities/pharmacological-therapy-cancel-suspend';
import {
  PharmacologicalTherapyMode,
  PharmacologicalTherapyType,
} from '../entities/pharmacological-therapy-type';
import {
  abortPharmacologicalTherapySuspension,
  closeConfirmCancelPharmacologicalTherapyModal,
  closeConfirmDeletePharmacologicalTherapyModal,
  confirmCancelPharmacologicalTherapyFailure,
  confirmCancelPharmacologicalTherapyModal,
  confirmCancelPharmacologicalTherapySuccess,
  confirmDeletePharmacologicalTherapyFailure,
  confirmDeletePharmacologicalTherapyModal,
  confirmDeletePharmacologicalTherapySuccess,
  confirmPharmacologicalTherapySuspension,
  createPharmacologicalTherapy,
  createPharmacologicalTherapyFailure,
  createPharmacologicalTherapySuccess,
  deselectPharmacologicalTherapyOccurrence,
  downloadCatalogsFailure,
  downloadPharmacologicalTherapiesCatalogs,
  downloadPharmacologicalTherapiesCatalogsSuccess,
  editPharmacologicalTherapy,
  editPharmacologicalTherapyFailure,
  editPharmacologicalTherapySuccess,
  hideCreateNewPharmacologicalTherapyModal,
  loadPharmacologicalTherapies,
  loadPharmacologicalTherapiesFailure,
  loadPharmacologicalTherapiesSuccess,
  pharmacologicalTherapySuspensionFailure,
  pharmacologicalTherapySuspensionSuccess,
  pharmacologicalTherapyTabChanged,
  selectPharmacologicalTherapyOccurrence,
  selectPharmacologicalTherapySuspensionCandidate,
  selectedTherapyOccurrenceFromTimeline,
  showConfirmCancelTherapyOccurrenceModal,
  showConfirmDeleteTherapyOccurrenceModal,
  showCreateNewPharmacologicalTherapyModal,
  sortPharmacologicalTherapies,
} from './pharmacological-therapies.actions';

export interface PharmacologicalTherapiesCatalogs {
  therapyTypes: PharmacologicalTherapyType[];
  modes: PharmacologicalTherapyMode[];
  routes: DomainItem[];
  diluents: DomainItem[];
}

export interface PharmacologicalTherapiesUiState {
  isLoadingData: boolean;
  sort?: Sort;
  selectedTherapyOccurrence?: SelectedTherapyOccurrenceIds;
  selectedTherapyOccurrenceInTable?: boolean;
  changingTab?: boolean;

  candidateForDeletionId?: string;

  showCreateNewModal: boolean;
  isCreatingNew: boolean;
  isUpdating: boolean;
  tabIndex: number;

  isDownloadingCatalogs: boolean;
  catalogs?: PharmacologicalTherapiesCatalogs;

  deleteTherapyOccurrenceId?: string;
  pharmacologicalTherapyCancel: PharmacologicalTherapyCancelSuspend | undefined;
  isButtonDisabled: boolean;

  candidateForSuspensionId?: string;
  isSuspensionRunning: boolean;
}

export const initialPharmacologicalTherapiesUiState: PharmacologicalTherapiesUiState =
  {
    isLoadingData: false,
    showCreateNewModal: false,
    isCreatingNew: false,
    isUpdating: false,
    tabIndex: 0,

    isDownloadingCatalogs: false,
    deleteTherapyOccurrenceId: undefined,
    pharmacologicalTherapyCancel: undefined,
    isButtonDisabled: false,

    isSuspensionRunning: false,
  };

export const pharmacologicalTherapiesUiReducer: ActionReducer<
  PharmacologicalTherapiesUiState,
  Action
> = createReducer(
  initialPharmacologicalTherapiesUiState,
  on(
    loadPharmacologicalTherapies,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isLoadingData: true,
    })
  ),
  on(
    loadPharmacologicalTherapiesSuccess,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isLoadingData: false,
    })
  ),
  on(
    loadPharmacologicalTherapiesFailure,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isLoadingData: false,
    })
  ),
  on(
    sortPharmacologicalTherapies,
    (
      state: PharmacologicalTherapiesUiState,
      { sort }
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      sort,
    })
  ),
  on(
    selectPharmacologicalTherapyOccurrence,
    (
      state: PharmacologicalTherapiesUiState,
      { therapyId, occurrenceId }
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      selectedTherapyOccurrence: {
        therapyId,
        occurrenceId,
      },
      selectedTherapyOccurrenceInTable: true,
      changingTab: false,
    })
  ),

  on(
    deselectPharmacologicalTherapyOccurrence,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      selectedTherapyOccurrence: undefined,
      selectedTherapyOccurrenceInTable: undefined,
      changingTab: undefined,
    })
  ),

  on(
    showCreateNewPharmacologicalTherapyModal,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      showCreateNewModal: true,
    })
  ),
  on(
    hideCreateNewPharmacologicalTherapyModal,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      showCreateNewModal: false,
    })
  ),

  on(
    createPharmacologicalTherapy,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isCreatingNew: true,
    })
  ),

  on(
    createPharmacologicalTherapySuccess,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isCreatingNew: false,
      showCreateNewModal: false,
    })
  ),
  on(
    createPharmacologicalTherapyFailure,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isCreatingNew: false,
    })
  ),

  on(
    editPharmacologicalTherapy,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isUpdating: true,
    })
  ),

  on(
    editPharmacologicalTherapySuccess,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isUpdating: false,
    })
  ),

  on(
    editPharmacologicalTherapyFailure,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isUpdating: false,
    })
  ),

  on(
    pharmacologicalTherapyTabChanged,
    (
      state: PharmacologicalTherapiesUiState,
      { tabIndex }
    ): PharmacologicalTherapiesUiState => {
      if (tabIndex === 0) {
        return {
          ...state,
          tabIndex,
          selectedTherapyOccurrence: undefined,
          selectedTherapyOccurrenceInTable: undefined,
          changingTab: undefined,
        };
      }
      return { ...state, tabIndex };
    }
  ),
  on(
    selectedTherapyOccurrenceFromTimeline,
    (
      state: PharmacologicalTherapiesUiState,
      { selectedTherapyOccurrenceIds }
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      selectedTherapyOccurrence: selectedTherapyOccurrenceIds,
      selectedTherapyOccurrenceInTable: false,
      changingTab: state.tabIndex !== 1,
      tabIndex: 1,
    })
  ),

  // Catalogs download

  on(
    downloadPharmacologicalTherapiesCatalogs,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isDownloadingCatalogs: true,
    })
  ),

  on(
    downloadPharmacologicalTherapiesCatalogsSuccess,
    (
      state: PharmacologicalTherapiesUiState,
      { catalogs }
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isDownloadingCatalogs: false,
      catalogs,
    })
  ),

  on(
    downloadCatalogsFailure,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isDownloadingCatalogs: false,
    })
  ),

  on(
    showConfirmDeleteTherapyOccurrenceModal,
    (
      state: PharmacologicalTherapiesUiState,
      { therapyId }
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      deleteTherapyOccurrenceId: therapyId,
    })
  ),

  on(
    closeConfirmDeletePharmacologicalTherapyModal,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      deleteTherapyOccurrenceId: undefined,
    })
  ),

  on(
    confirmDeletePharmacologicalTherapyModal,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isButtonDisabled: true,
    })
  ),

  on(
    confirmDeletePharmacologicalTherapySuccess,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      deleteTherapyOccurrenceId: undefined,
      isButtonDisabled: false,
    })
  ),
  on(
    confirmDeletePharmacologicalTherapyFailure,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isButtonDisabled: false,
    })
  ),

  on(
    selectPharmacologicalTherapySuspensionCandidate,
    (
      state: PharmacologicalTherapiesUiState,
      { therapyId }
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      candidateForSuspensionId: therapyId,
    })
  ),
  on(
    confirmPharmacologicalTherapySuspension,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isSuspensionRunning: true,
    })
  ),
  on(
    abortPharmacologicalTherapySuspension,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isSuspensionRunning: false,
      candidateForSuspensionId: undefined,
    })
  ),
  on(
    pharmacologicalTherapySuspensionSuccess,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isSuspensionRunning: false,
      candidateForSuspensionId: undefined,
    })
  ),
  on(
    pharmacologicalTherapySuspensionFailure,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isSuspensionRunning: false,
    })
  ),

  on(
    confirmCancelPharmacologicalTherapyModal,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isButtonDisabled: true,
    })
  ),

  on(
    confirmCancelPharmacologicalTherapySuccess,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      pharmacologicalTherapyCancel: undefined,
      isButtonDisabled: false,
    })
  ),
  on(
    confirmCancelPharmacologicalTherapyFailure,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      isButtonDisabled: false,
    })
  ),

  on(
    closeConfirmCancelPharmacologicalTherapyModal,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      pharmacologicalTherapyCancel: undefined,
    })
  ),

  on(
    showConfirmCancelTherapyOccurrenceModal,
    (
      state: PharmacologicalTherapiesUiState,
      { therapy }
    ): PharmacologicalTherapiesUiState => ({
      ...state,
      pharmacologicalTherapyCancel: therapy,
    })
  )
);
