import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, take } from 'rxjs';
import { Config } from './config';
import { loadConfig } from './store/config.actions';

const DEFAULT_MOCK_COUNT = 5;

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private _config?: Config;

  loadConfigResult?: Promise<boolean>;

  constructor(private http: HttpClient, private store: Store) {
    // this.loadConfigResult = this.loadAppConfig();
  }

  loadAppConfig = async (): Promise<boolean> => {
    if (!this.loadConfigResult) {
      this.loadConfigResult = new Promise(async (resolve) => {
        this._config = await firstValueFrom(
          this.http.get<Config>('config.json').pipe(take(1))
        );

        this.store.dispatch(loadConfig({ config: this._config }));

        resolve(true);
      });
    }
    return this.loadConfigResult;
  };

  get hospitalName(): string {
    return this._config?.hospitalName ?? '';
  }

  get startupPage(): string {
    return this._config?.startupPage ?? '';
  }

  get baseUrl(): string {
    return this._config?.baseUrl ?? '';
  }

  get apiBaseUrl(): string {
    return this._config ? this._config.baseUrl + '/api' : '';
  }

  get authRealm(): string {
    return this._config?.authRealm ?? '';
  }

  get showDemoLoginButtons(): boolean {
    return this._config?.showDemoLoginButtons ?? false;
  }

  get mockDepartmentCount(): number {
    return this._config?.mock?.departmentCount ?? DEFAULT_MOCK_COUNT;
  }

  get mockPatientCount(): number {
    return this._config?.mock?.patientCount ?? DEFAULT_MOCK_COUNT;
  }

  get mockEncounterCount(): number {
    return this._config?.mock?.encounterCount ?? DEFAULT_MOCK_COUNT;
  }

  get mockProblemCount(): number {
    return this._config?.mock?.problemCount ?? DEFAULT_MOCK_COUNT;
  }

  get mockClinicalNoteCount(): number {
    return this._config?.mock?.clinicalNoteCount ?? DEFAULT_MOCK_COUNT;
  }
}
