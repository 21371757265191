import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'natea-cc-patients-list-header',
  templateUrl: './patients-list-header.component.html',
  styleUrls: ['./patients-list-header.component.scss'],
})
export class PatientsListHeaderComponent {
  @Input() hospitalName = '';
  @Input() departmentName = '';
  @Input() multipleDepartments = false;
  @Input() isDesktopSized = false;
  @Output() searchPatient: EventEmitter<void> = new EventEmitter<void>();
}
