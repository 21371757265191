import { UserRole } from '../../../../shared/entities/user';
import { ProfessionalCategory } from '../../../../shared/entities/professional-category';
import { randomId } from '../../../../shared/utils/utils';
import { mockEncounterDay } from '../../../../shared/utils/mock-utils';
import {
  TherapyDurationType,
  TherapyOccurrenceState,
} from '../../common/entities/therapy-occurrence';
import { baseTherapyMock } from '../../common/mocks/therapy.mock';
import { BloodComponent } from '../entities/blood-component';
import { TransfusionalTherapy } from '../entities/transfusional-therapy';

const randomInt = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const bloodComponentsMock: BloodComponent[] = [ // RLE
  { id: '1', name: 'Emazie concentrate', description: '' },
//  { id: '5', name: 'Granulociti', description: '' },
  { id: '3', name: 'Piastrine concentrate', description: '' },
  { id: '4', name: 'Plasma criopreservato', description: '' },
  { id: '5', name: 'Plasma', description: '' },
  { id: '6', name: 'Sangue intero', description: '' },
];

//const therapyFullBloodType =  {},

export const transfusionalTherapiesListMock = (): TransfusionalTherapy[] => {
  const createList = (): TransfusionalTherapy[] => {
    return [
      /* {
        ...baseTransfusionalTherapyMock,
        prescriptionDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 2),
        programmingDate: new Date(Date.now() - 2000 * 60 * 60 * 24 * 2),
        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Globuli rossi',
        canBeAborted: true,
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],

        canBeSuspended: false,

        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Aborted,
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
            executionDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 2),
            programmingDate: new Date(Date.now() - 3000 * 60 * 60 * 24 * 2),
            terminationDate: new Date(Date.now() - 2300 * 60 * 60 * 24 * 2),
          },
        ],
      },*/

      {
        ...baseTransfusionalTherapyMock,
        prescriptionDate: mockEncounterDay(5, 12), // RLE
        creationDate:  mockEncounterDay(5, 12), // RLE
        programmingDate: mockEncounterDay(7, 12, 16), // RLE   
        insertionDate:  mockEncounterDay(7, 12, 10), // RLE

        id: randomId(),
        patientWeightKg: 1.8,
        bsa: randomInt(2, 8),
        name: 'Sangue intero',
        canBeAborted: true,
        problem: {
          id: '2',
          name: 'Prematurità minore di 28 settimane',
          description: 'Prematurità minore di 28 settimane',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: 240,
        speedMlSecond: 50,
        bloodComponent: bloodComponentsMock[4],

        canBeSuspended: false,
        dose: 200,

        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
            programmingDate: mockEncounterDay(7, 16, 0), // RLE
            executionDate: mockEncounterDay(7, 16, 0), // RLE
            terminationDate: mockEncounterDay(7, 20, 30), // RLE
            duration: 240,
            administration: {
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
              description: '',
              dosage: '200',
              executionDate: mockEncounterDay(7, 16, 0), // RLE

              expectedDuration: 240,
              infusionPump: {
                key: '',
                label: '',
              },
              velocity: 50,
              administrationWay: {
                key: 'CVC',
                label: 'CVC',
              },
              effectiveDuration: 240,
              checkerDoctor: {
                id: randomId(),
                firstName: 'Maurizio',
                lastName: 'verdi',
              },
              terminationDate: mockEncounterDay(7, 20, 30), // RLE
              checkerNurse: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
              isDoctorCompatibilityTest: true,
              isDoctorBloodBagIntact: true,
              isNurseCompatibilityTest: true,
              isNurseBloodBagIntact: true,
              administrationType: {
                key: '',
                label: '',
              },
              applicationMedical: {
                key: '23',
                label: '',
              },
              therapyExecutor: {
                id: randomId(),
                firstName: 'Maurizio',
                lastName: 'verdi',
                role: UserRole.Doctor,
              },

              adverseReactions: {
                key: '1',
                label: 'Nessuna',
              },
            },
          },
        ],
      },

      {
        ...baseTransfusionalTherapyMock,
        prescriptionDate: mockEncounterDay(6, 11), // RLE
        creationDate:  mockEncounterDay(6, 11), // RLE
        programmingDate: mockEncounterDay(7, 12, 16), // RLE   
        insertionDate:  mockEncounterDay(7, 12, 10), // RLE

        id: randomId(),
        patientWeightKg: 2.3,
        bsa: 1.15,
        name: 'Sangue intero',
        canBeAborted: true,
        problem: {
          id: '2',
          name: 'Prematurità minore di 28 settimane',
          description: 'Prematurità minore di 28 settimane',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: 240,
        speedMlSecond: 50,
        bloodComponent: bloodComponentsMock[4],

        canBeSuspended: false,
        dose: 200,

        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Suspended,
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
            programmingDate: mockEncounterDay(8, 16, 0), // RLE
            executionDate: mockEncounterDay(8, 16, 0), // RLE
            duration: 240,
          },
        ],
      },

      /* {
        ...baseTransfusionalTherapyMock,
        canBeAborted: true,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        prescriptionDate: new Date(Date.now() - 1500 * 60 * 60 * 24 * 2),
        programmingDate: new Date(Date.now() - 2500 * 60 * 60 * 24 * 2),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        canBeSuspended: false,

        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Suspended,
            programmingDate: new Date(Date.now() - 2200 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 2700 * 60 * 60 * 24),
            executionDate: new Date(Date.now() - 2000 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],

        name: 'Piastrine',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
      },
      {
        ...baseTransfusionalTherapyMock,
        id: randomId(),
        canBeAborted: true,
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Piastrine',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],

        canBeSuspended: true,

          occurrences: [
            {
              id: randomId(),
              prescriptionId: randomId(),
              state: TherapyOccurrenceState.InExecution,
              programmingDate: new Date(Date.now() - 1500 * 60 * 60 * 24),
              terminationDate: new Date(Date.now() + 2200 * 60 * 60 * 24),
              executionDate: new Date(Date.now() - 1200 * 60 * 60 * 24),
              durationType: TherapyDurationType.Prolonged,
              isValid: true,
              weightKg: '10',
             // posologyDose: 10,
             // posologyDoseMl: 20,
            //  posologyTotalDose: 30,
              administration: {
                description: 'Apiplus 500mg',
                dosage: '25',
                administeredBy: {
                  id: randomId(),
                  firstName: 'Mario',
                  lastName: 'bianchi',
                },
                executionDate: new Date(Date.now() - 1000 * 60 * 60 * 24),
                infusionPump: {
                  key: randomId(),
                  label: 'Valor',
                },
                administrationType: {
                  key: randomId(),
                  label: 'EV',
                },
                administrationWay: {
                  key: randomId(),
                  label: 'IV',
                },
                applicationMedical: {
                  key: randomId(),
                  label: 'Medico',
                },
                velocity: 10,
                expectedDuration: 100,
                effectiveDuration: 90,
                checkerDoctor: {
                  id: randomId(),
                  firstName: 'Mario',
                  lastName: 'bianchi',
                },
                isDoctorCompatibilityTest: true,
                isDoctorBloodBagIntact: true,
                checkerNurse: {
                  id: randomId(),
                  firstName: 'Mario',
                  lastName: 'rossi',
                },
                isNurseCompatibilityTest: true,
                isNurseBloodBagIntact: true,
                therapyExecutor: {
                  id: randomId(),
                  firstName: 'Mario',
                  lastName: 'verdi',
                  role: UserRole.Doctor,

                },
                adverseReactions: {
                  key: randomId(),
                  label: 'Nessuna',
                },
                note: 'Note 1',

              },

           //  /* repetition: {
           //     key: randomId(),
             //   label: '7 volte al giorno',
            //  },
            //  forDays: 10,
           // },

          ],
      }*/

      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: 2.3,
        bsa: 1.15,
        name: 'Plasma',
        prescriptionDate: mockEncounterDay(5, 12), // RLE
        creationDate:  mockEncounterDay(5, 12), // RLE
        programmingDate: mockEncounterDay(7, 12, 16), // RLE   
        insertionDate:  mockEncounterDay(7, 12, 10), // RLE
        problem: {
          id: '2',
          name: 'Ittero in prematuro',
          description: '',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        bloodComponent: bloodComponentsMock[3],
        durationMinutes: 240,
        speedMlSecond: 25,
        dose: 100,

        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(6, 16, 0), // RLE
            executionDate: mockEncounterDay(6, 17, 15), // RLE
            terminationDate: mockEncounterDay(6, 20, 20), // RLE
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
            administration: {
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
              description: '',
              dosage: '200',
              executionDate: mockEncounterDay(6, 17, 15), // RLE

              expectedDuration: 240,
              infusionPump: {
                key: 'iv',
                label: '',
              },
              velocity: 50,
              administrationWay: {
                key: 'CVC',
                label: 'CVC',
              },
              effectiveDuration: 240,
              checkerDoctor: {
                id: randomId(),
                firstName: 'Maurizio',
                lastName: 'verdi',
              },
              terminationDate: mockEncounterDay(6, 20, 20), // RLE
              checkerNurse: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
              isDoctorCompatibilityTest: true,
              isDoctorBloodBagIntact: true,
              isNurseCompatibilityTest: true,
              isNurseBloodBagIntact: true,
              administrationType: {
                key: 'CVC',
                label: '',
              },
              applicationMedical: {
                key: '23',
                label: '',
              },
              therapyExecutor: {
                id: randomId(),
                firstName: 'Maurizio',
                lastName: 'verdi',
                role: UserRole.Doctor,
              },

              adverseReactions: {
                key: '1',
                label: 'Nessuna',
              },
            },
          },
        ],
      },

      {
        ...baseTransfusionalTherapyMock,
        name: 'Plasma',
        prescriptionDate: mockEncounterDay(8, 12), // RLE
        creationDate:  mockEncounterDay(8, 12), // RLE
        programmingDate: mockEncounterDay(9, 12, 16), // RLE   
        insertionDate:  mockEncounterDay(9, 12, 10), // RLE

        canBeEdited: false,

        bloodComponent: bloodComponentsMock[3],

        patientWeightKg: 2.3,

        bsa: 1,
        id: randomId(),
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(9, 19, 0), // RLE
            executionDate: mockEncounterDay(9, 20, 0), // RLE
            terminationDate: mockEncounterDay(9, 23, 10), // RLE
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '2.30',
            duration: undefined,
          },

          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: mockEncounterDay(10, 20, 0), // RLE
            executionDate: mockEncounterDay(10, 21, 0), // RLE
            terminationDate: mockEncounterDay(10, 23, 0), // RLE
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '2.30',
            duration: undefined,
          },
        ],
        durationMinutes: 0,
      },

      /* {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Plasma',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
        canBeAborted: true,

          occurrences: [
            {
              id: randomId(),

              prescriptionId: randomId(),
              state: TherapyOccurrenceState.Administered,
              programmingDate: new Date(Date.now() - 1500 * 60 * 60 * 24),
              terminationDate: new Date(Date.now() + 2000 * 60 * 60 * 24),
              executionDate: new Date(Date.now() - 1000 * 60 * 60 * 24),
              durationType: TherapyDurationType.Prolonged,
              isValid: true,
              weightKg: '10',
          //    durationType: TherapyDurationType.Prolonged,
           //   bsa: 0.5,
            //  posologyDose: 10,
           //   posologyDoseMl: 20,
         //     posologyTotalDose: 30,
              administration: {
                description: 'Apiplus 500mg',
                executionDate: new Date(Date.now() - 1000 * 60 * 60 * 24),
                terminationDate: new Date(Date.now() + 2000 * 60 * 60 * 24),
                infusionPump: {
                  key: randomId(),
                  label: 'Valor',
                },
                administrationType: {
                  key: randomId(),
                  label: 'EV',
                },
                administrationWay: {
                  key: randomId(),
                  label: 'IV',
                },
                applicationMedical: {
                  key: randomId(),
                  label: 'Medico',
                },
                velocity: 10,
                expectedDuration: 100,
                effectiveDuration: 90,
                checkerDoctor: {
                  id: randomId(),
                  firstName: 'Mario',
                  lastName: 'bianchi',
                  role: UserRole.Nurse,
                },
                isDoctorCompatibilityTest: true,
                isDoctorBloodBagIntact: true,
                checkerNurse: {
                  id: randomId(),
                  firstName: 'Mario',
                  lastName: 'rossi',
                },
                isNurseCompatibilityTest: true,
                isNurseBloodBagIntact: true,
                therapyExecutor: {
                  id: randomId(),
                  firstName: 'Mario',
                  lastName: 'verdi',
                },
                adverseReactions: {
                  key: randomId(),
                  label: 'Nessuna',
                },
                note: 'Note 1',
                dosage: '25',
                administeredBy: {
                  id: randomId(),
                  firstName: 'Mario',
                  lastName: 'bianchi',
                },
              },

           // /*  repetition: {
               // key: randomId(),
               // label: '7 volte al giorno',
              //},
           //   forDays: 10,
            },

          ],

      },*/
      /*   {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Proteine plasmatiche',
        canBeSuspended: true,
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],

        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.SentToPreparation,
            programmingDate: new Date(Date.now() - 1500 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 3000 * 60 * 60 * 24),
            executionDate: new Date(Date.now() - 1000 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Acqua',
        prescriptionDate: new Date(Date.now() - 1500 * 60 * 60 * 24 * 2),
        programmingDate: new Date(Date.now() - 1700 * 60 * 60 * 24 * 2),
        canBeSuspended: true,

        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],

        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.InExecution,
            programmingDate: new Date(Date.now() - 1500 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 2200 * 60 * 60 * 24),
            executionDate: new Date(Date.now() - 1400 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        canBeAborted: true,
        name: 'Acqua',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],

        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.InExecution,
            programmingDate: new Date(Date.now() - 500 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 2200 * 60 * 60 * 24),
            executionDate: new Date(Date.now() - 400 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Elettroliti',
        prescriptionDate: new Date(Date.now() - 1200 * 60 * 60 * 24 * 2),
        programmingDate: new Date(Date.now() - 1800 * 60 * 60 * 24 * 2),
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],

        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.InExecution,
            programmingDate: new Date(Date.now() - 900 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 1200 * 60 * 60 * 24),
            executionDate: new Date(Date.now() - 800 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Elettroliti',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],

        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.InExecution,
            programmingDate: new Date(Date.now() - 900 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 1200 * 60 * 60 * 24),
            executionDate: new Date(Date.now() - 600 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Ormoni',
        prescriptionDate: new Date(Date.now() - 500 * 60 * 60 * 24 * 2),
        programmingDate: new Date(Date.now() - 700 * 60 * 60 * 24 * 2),
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),

        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.InExecution,

            programmingDate: new Date(Date.now() - 900 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 1200 * 60 * 60 * 24),
            executionDate: new Date(Date.now() - 500 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Ormoni',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Vitamine',
        prescriptionDate: new Date(Date.now() - 2500 * 60 * 60 * 24 * 2),
        programmingDate: new Date(Date.now() - 2000 * 60 * 60 * 24 * 2),
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),

        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.InExecution,
            programmingDate: new Date(Date.now() - 900 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 1200 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Vitamine',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Anticorpi',
        prescriptionDate: new Date(Date.now() - 1500 * 60 * 60 * 24 * 2),
        programmingDate: new Date(Date.now() - 2800 * 60 * 60 * 24 * 2),
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Anticorpi',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Lipidi',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },

      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'glucosio',
        prescriptionDate: new Date(Date.now() - 2500 * 60 * 60 * 24 * 2),
        programmingDate: new Date(Date.now() - 1900 * 60 * 60 * 24 * 2),
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
      },
      {
        ...baseTransfusionalTherapyMock,
        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'glucosio',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },

      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Enzimi',
        prescriptionDate: new Date(Date.now() - 2700 * 60 * 60 * 24 * 2),
        programmingDate: new Date(Date.now() - 1700 * 60 * 60 * 24 * 2),
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
      },

      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Enzimi',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },

      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Fattori di coagulazione',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },

      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Albumina',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },

      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Globuline',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },

      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Fibrinogeno',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },

      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Complementi del sistema immunitario',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Emoglobina',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Metaboliti (prodotti di scarto)',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Citochine',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Ossigeno',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Anidride carbonica',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Ferro',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Rame',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Zinco',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },
      {
        ...baseTransfusionalTherapyMock,
        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Calcio',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },
      {
        ...baseTransfusionalTherapyMock,
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),

        id: randomId(),
        name: 'Potassio',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },
      {
        ...baseTransfusionalTherapyMock,

        id: randomId(),
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        name: 'Sodio',
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },
      {
        ...baseTransfusionalTherapyMock,
        id: randomId(),
        name: 'Magnesio',
        patientWeightKg: randomInt(2, 8),
        bsa: randomInt(2, 8),
        problem: {
          id: '2',
          name: 'Cefalea',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        durationMinutes: randomInt(15, 600),
        speedMlSecond: randomInt(10, 100),
        bloodComponent:
          bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
      },*/
    ];
  };

  const list = createList();
  list.forEach((item) => {
    const id = randomId();
    item.id = id;
    item.occurrences.forEach((occurrence) => {
      occurrence.id = randomId();
      occurrence.prescriptionId = id;
    });
  });

  return list;
};

export const baseTransfusionalTherapyMock: TransfusionalTherapy = {
  ...baseTherapyMock,
  canBeDeleted: true,
  id: randomId(),
  name: 'Magnesio',
  patientWeightKg: 2.3,
  bsa: 1.15,
  dose: randomInt(2, 8),
  problem: {
    id: '2',
    name: 'Cefalea',
    description: 'Mal di testa',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  durationMinutes: randomInt(15, 600),
  speedMlSecond: randomInt(10, 100),
  bloodComponent:
    bloodComponentsMock[randomInt(0, bloodComponentsMock.length - 1)],
  occurrences: [],
};
