import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, throwError } from 'rxjs';
import {
  ClinicalNoteApiService,
  GeneralResponseNoteResource,
} from '../../../../core/generated/api';
import { localError } from '../../../../shared/entities/errors';
import { ClinicalNote } from '../../interfaces/clinical-note';
import { NewNoteFormValues } from '../../interfaces/new-note-form';
import { ClinicalNotesAdapters } from '../../webapis/clinical-notes.adapters';
import { NotesValidationAdapters } from './notes-validation.adapters';

@Injectable({
  providedIn: 'root',
})
export class NotesValidationWebAPI {
  constructor(private clinicalNoteService: ClinicalNoteApiService) {}

  validateClinicalNote(note: NewNoteFormValues): Observable<ClinicalNote> {
    const validationNote = NotesValidationAdapters.validationNoteFeToBE(note);
    const noteAssessRequest = {
      isApproved: validationNote.isApproved,
      rejectionReason: validationNote.rejectionReason,
    };

    return this.clinicalNoteService
      .assessClinicalNote(validationNote.id, noteAssessRequest)
      .pipe(
        map((note: GeneralResponseNoteResource) => {
          if (!note.payload) {
            throw new Error('Missing payload');
          }

          return ClinicalNotesAdapters.clinicalNoteBeToFe(note.payload);
        })
      );
  }

  ValidationUpdateClinicalNote(
    clinicalNote: ClinicalNote
  ): Observable<ClinicalNote> {
    const clinicalNoteId = clinicalNote.id;

    if (!clinicalNoteId) {
      return throwError(() => localError(HttpStatusCode.BadRequest));
    }
    return this.clinicalNoteService
      .putNote(
        clinicalNoteId,
        NotesValidationAdapters.clinicalNoteFeToUpdate(clinicalNote) // TODO non è stata validata perché manca la definizione nello swagger
      )
      .pipe(map(() => clinicalNote));
  }
}
