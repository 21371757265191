import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { ApiError } from '../../../../shared/entities/errors';
import { SelectedTherapyOccurrenceIds } from '../../common/entities/selected-therapy';
import { InstrumentalTherapy } from '../entities/instrumental-therapy';
import { InstrumentalTherapyType } from '../entities/instrumental-therapy-type';
import { InstrumentalTherapyFormData } from '../form/instrumental-therapy-form-data';

export enum InstrumentalTherapiesActions {
  LoadInstrumentalTherapies = '[InstrumentalTherapies] Load InstrumentalTherapies',
  LoadInstrumentalTherapiesSuccess = '[InstrumentalTherapies] Load InstrumentalTherapies Success',
  LoadInstrumentalTherapiesFailure = '[InstrumentalTherapies] Load InstrumentalTherapies Failure',

  SortInstrumentalTherapies = '[InstrumentalTherapies] Sort',

  SelectOccurrence = '[InstrumentalTherapies] Select Occurrence',
  DeselectOccurrence = '[InstrumentalTherapies] Deselect Occurrence',

  ShowCreateNewModal = '[InstrumentalTherapies] Show Create New Modal',
  HideCreateNewModal = '[InstrumentalTherapies] Hide Create New Modal',

  CreateInstrumentalTherapy = '[InstrumentalTherapies] Create InstrumentalTherapy',
  CreateInstrumentalTherapySuccess = '[InstrumentalTherapies] Create InstrumentalTherapy Success',
  CreateInstrumentalTherapyFailure = '[InstrumentalTherapies] Create InstrumentalTherapy Failure',
  InstrumentalTherapySelectFromTimeline = '[InstrumentalTherapies] InstrumentalTherapy Select From Timeline',
  InstrumentalTherapyTabChange = '[InstrumentalTherapies] InstrumentalTherapy Tab Change',

  DownloadTypes = '[InstrumentalTherapies] Download Types',
  DownloadTypesSuccess = '[InstrumentalTherapies] Download Types Success',
  DownloadTypesFailure = '[InstrumentalTherapies] Download Types Failure',

  EditInstrumentalTherapy = '[InstrumentalTherapies] Edit InstrumentalTherapy',
  EditInstrumentalTherapySuccess = '[InstrumentalTherapies] Edit InstrumentalTherapy Success',
  EditInstrumentalTherapyFailure = '[InstrumentalTherapies] Edit InstrumentalTherapy Failure',

  SelectedTherapyOccurrenceFromTimeline = '[InstrumentalTherapies] Selected Therapy Occurrence From Timeline',

  SelectSuspensionCandidate = '[InstrumentalTherapies] Select Suspension Candidate',
  AbortSuspension = '[InstrumentalTherapies] Abort Suspension',
  ConfirmSuspension = '[InstrumentalTherapies] Confirm Suspension',
  SuspensionSuccess = '[InstrumentalTherapies] Suspension Success',
  SuspensionFailure = '[InstrumentalTherapies] Suspension Failure',
  CloseSuspensionModal = '[InstrumentalTherapies] Close Suspension Modal',

  ConfirmDeleteInstrumentalTherapy = '[InstrumentalTherapies] Confirm Delete InstrumentalTherapy',
  HideDeleteConfirmationModal = '[InstrumentalTherapies] Hide Delete Confirmation Modal',
  ShowDeleteConfirmationModal = '[InstrumentalTherapies] Show Delete Confirmation Modal',

  ConfirmDeleteInstrumentalTherapyFailure = '[InstrumentalTherapies] Confirm Delete InstrumentalTherapy Failure',
  ConfirmDeleteInstrumentalTherapySuccess = '[InstrumentalTherapies] Confirm Delete InstrumentalTherapy Success',
  ShowConfirmAbortModal = '[InstrumentalTherapies] Show Confirm Abort Modal',
  HideConfirmAbortModal = '[InstrumentalTherapies] Hide Confirm Abort Modal',

  ConfirmAbortInstrumentalTherapy = '[InstrumentalTherapies] Confirm Abort InstrumentalTherapy',
  ConfirmAbortInstrumentalTherapyFailure = '[InstrumentalTherapies] Confirm Abort InstrumentalTherapy Failure',
  ConfirmAbortInstrumentalTherapySuccess = '[InstrumentalTherapies] Confirm Abort InstrumentalTherapy Success',
}

export const loadInstrumentalTherapies = createAction(
  InstrumentalTherapiesActions.LoadInstrumentalTherapies
);

export const loadInstrumentalTherapiesSuccess = createAction(
  InstrumentalTherapiesActions.LoadInstrumentalTherapiesSuccess,
  props<{
    therapies: InstrumentalTherapy[];
    patientId: string;
    encounterId: string;
  }>()
);

export const loadInstrumentalTherapiesFailure = createAction(
  InstrumentalTherapiesActions.LoadInstrumentalTherapiesFailure,
  props<{ error: ApiError }>()
);

export const sortInstrumentalTherapies = createAction(
  InstrumentalTherapiesActions.SortInstrumentalTherapies,
  props<{ sort?: Sort }>()
);

export const selectInstrumentalTherapyOccurrence = createAction(
  InstrumentalTherapiesActions.SelectOccurrence,
  props<{ therapyId: string; occurrenceId: string }>()
);

export const deselectInstrumentalTherapyOccurrence = createAction(
  InstrumentalTherapiesActions.DeselectOccurrence
);

export const showCreateNewInstrumentalTherapyModal = createAction(
  InstrumentalTherapiesActions.ShowCreateNewModal
);
export const hideCreateNewInstrumentalTherapyModal = createAction(
  InstrumentalTherapiesActions.HideCreateNewModal
);

export const createInstrumentalTherapy = createAction(
  InstrumentalTherapiesActions.CreateInstrumentalTherapy,
  props<{
    therapy: InstrumentalTherapyFormData;
  }>()
);

export const createInstrumentalTherapySuccess = createAction(
  InstrumentalTherapiesActions.CreateInstrumentalTherapySuccess,
  props<{
    therapy: InstrumentalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const createInstrumentalTherapyFailure = createAction(
  InstrumentalTherapiesActions.CreateInstrumentalTherapyFailure,
  props<{ error: ApiError }>()
);

export const selectInstrumentalTherapyFromTimeline = createAction(
  InstrumentalTherapiesActions.InstrumentalTherapySelectFromTimeline,
  props<{ selectedTherapyOccurrenceIds: SelectedTherapyOccurrenceIds }>()
);

export const instrumentalTherapyTabChange = createAction(
  InstrumentalTherapiesActions.InstrumentalTherapyTabChange,
  props<{ tabIndex: number }>()
);

export const downloadInstrumentalTherapyTypes = createAction(
  InstrumentalTherapiesActions.DownloadTypes
);

export const downloadInstrumentalTherapyTypesSuccess = createAction(
  InstrumentalTherapiesActions.DownloadTypesSuccess,
  props<{ types: InstrumentalTherapyType[] }>()
);

export const downloadInstrumentalTherapyTypesFailure = createAction(
  InstrumentalTherapiesActions.DownloadTypesFailure,
  props<{ error: ApiError }>()
);

export const editInstrumentalTherapy = createAction(
  InstrumentalTherapiesActions.EditInstrumentalTherapy,
  props<{
    therapyId: string;
    occurrenceId: string;
    therapy: InstrumentalTherapyFormData;
  }>()
);

export const editInstrumentalTherapySuccess = createAction(
  InstrumentalTherapiesActions.EditInstrumentalTherapySuccess,
  props<{
    therapy: InstrumentalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const editInstrumentalTherapyFailure = createAction(
  InstrumentalTherapiesActions.EditInstrumentalTherapyFailure,
  props<{ error: ApiError }>()
);

export const selectInstrumentalTherapySuspensionCandidate = createAction(
  InstrumentalTherapiesActions.SelectSuspensionCandidate,
  props<{ therapyId: string }>()
);

export const abortInstrumentalTherapySuspension = createAction(
  InstrumentalTherapiesActions.AbortSuspension
);

export const confirmInstrumentalTherapySuspension = createAction(
  InstrumentalTherapiesActions.ConfirmSuspension,
  props<{ motivation: string }>()
);

export const instrumentalTherapySuspensionSuccess = createAction(
  InstrumentalTherapiesActions.SuspensionSuccess,
  props<{
    therapy: InstrumentalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const instrumentalTherapySuspensionFailure = createAction(
  InstrumentalTherapiesActions.SuspensionFailure,
  props<{ error: ApiError }>()
);

export const confirmDeleteInstrumentalTherapy = createAction(
  InstrumentalTherapiesActions.ConfirmDeleteInstrumentalTherapy
);

export const showDeleteConfirmationModal = createAction(
  InstrumentalTherapiesActions.ShowDeleteConfirmationModal
);

export const hideDeleteConfirmationModal = createAction(
  InstrumentalTherapiesActions.HideDeleteConfirmationModal
);

export const confirmDeleteInstrumentalTherapySuccess = createAction(
  InstrumentalTherapiesActions.ConfirmDeleteInstrumentalTherapySuccess,
  props<{
    therapyId: string;
    patientId: string;
    encounterId: string;
  }>()
);

export const confirmDeleteInstrumentalTherapyFailure = createAction(
  InstrumentalTherapiesActions.ConfirmDeleteInstrumentalTherapyFailure,
  props<{ error: ApiError }>()
);

export const showConfirmAbortInstrumentalTherapyModal = createAction(
  InstrumentalTherapiesActions.ShowConfirmAbortModal
);

export const hideConfirmAbortModal = createAction(
  InstrumentalTherapiesActions.HideConfirmAbortModal
);

export const confirmAbortInstrumentalTherapy = createAction(
  InstrumentalTherapiesActions.ConfirmAbortInstrumentalTherapy,
  props<{ motivation: string }>()
);

export const confirmAbortInstrumentalTherapySuccess = createAction(
  InstrumentalTherapiesActions.ConfirmAbortInstrumentalTherapySuccess,
  props<{
    therapy: InstrumentalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const closeSuspensionModal = createAction(
  InstrumentalTherapiesActions.CloseSuspensionModal
);
