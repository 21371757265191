import { createAction, props } from '@ngrx/store';
import { PregnancyCardData } from '../forms/pregnancy-card-form-data';

import { PrenatalInterventionData } from '../forms/pranatal-intervention-form-data';

export enum PregnancyCardActions {
  LoadAllPregnancyCard = '[PregnancyCard] Load All Pregnancy tabs',
  LoadAllPregnancyCardSuccess = '[PregnancyCard] Load All FactSheet Pregnancy Success',
  LoadAllPregnancyCatalogs = '[PregnancyCard] Load All FactSheet Pregnancy Catalogs',
  LoadAllPregnancyCardFailure = '[PregnancyCard] Load All FactSheet Pregnancy Failure',
  SaveFactSheetPregnancy = '[PregnancyCard] Save FactSheet Pregnancy',
  SavePregnancySuccess = '[PregnancyCard] Save FactSheet Pregnancy Success',
  SavePregnancyFailure = '[PregnancyCard] Save FactSheet Pregnancy Failure',
  LoadAllPregnancyCatalogsSuccess = '[PregnancyCard] Load All FactSheet Pregnancy Catalogs Success',
  LoadAllPregnancyCatalogsFailure = '[PregnancyCard] Load All FactSheet Pregnancy Catalogs Failure',

  CheckValidityProphylaxisCard = '[PregnancyCard] Check Validity Prophylaxis Card',

  CheckValidityPreviousPregnancyCard = '[PregnancyCard] Check Validity Previous Pregnancy Card',

  EstimatedDate = '[PregnancyCard] Estimated Date',

  ShowModalIntervention = '[PregnancyCard] Show Modal Intervention',
  HideModalIntervention = '[PregnancyCard] Hide Modal Intervention',

  AddIntervention = '[PregnancyCard] Add Intervention',

  RemoveIntervention = '[PregnancyCard] Remove Intervention',
}

export const loadAllPregnancyCard = createAction(
  PregnancyCardActions.LoadAllPregnancyCard
);

export const loadAllPregnancyCardSuccess = createAction(
  PregnancyCardActions.LoadAllPregnancyCardSuccess,
  props<{
    pregnancyCard: PregnancyCardData;
    patientId: string;
    encounterId: string;
  }>()
);

export const pregnancyEstimatedDate = createAction(
  PregnancyCardActions.EstimatedDate,
  props<{ date: Date }>()
);

export const checkValidityProphylaxisCard = createAction(
  PregnancyCardActions.CheckValidityProphylaxisCard,
  props<{ isValid: boolean }>()
);

export const checkValidityPreviousPregnancyCard = createAction(
  PregnancyCardActions.CheckValidityPreviousPregnancyCard,
  props<{ isValid: boolean }>()
);

export const showModalIntervention = createAction(
  PregnancyCardActions.ShowModalIntervention
);

export const hideModalIntervention = createAction(
  PregnancyCardActions.HideModalIntervention
);

export const addIntervention = createAction(
  PregnancyCardActions.AddIntervention,
  props<{ intervention: PrenatalInterventionData }>()
);

export const removeIntervention = createAction(
  PregnancyCardActions.RemoveIntervention,
  props<{ intervention: PrenatalInterventionData; index: number }>()
);
