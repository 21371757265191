<span
  class="start-hour-label hour-label"
  [class.compact]="compact"
  *ngIf="startDate && showStart"
  >{{ startDate | date : "HH:mm" }}</span
>
<div
  class="icon-wrapper"
  [class]="problemTypeClass"
  *ngIf="showStart && !compact"
>
  <natea-icons name="play" class="icon start-icon"></natea-icons>
</div>

<div class="line" [class]="problemTypeClass"></div>

<span
  class="pill natea-tooltip"
  [class.natea-tooltip]="compact"
  [class]="problemTypeClass"
  [class.fill]="compact"
  *ngIf="showPill || compact"
>
  <natea-icons
    name="clock"
    class="clock-icon"
    *ngIf="endDate && !compact"
  ></natea-icons>
  <span class="label" *ngIf="!compact"
    >{{ endDate ? "" : onGoingPrefix }}{{ label }}</span
  >
  <natea-tooltip
    *ngIf="compact"
    [label]="label"
    [side]="tooltipSide"
  ></natea-tooltip>
</span>

<div class="line" [class]="problemTypeClass"></div>

<div
  class="icon-wrapper"
  [class]="problemTypeClass"
  *ngIf="showEnd && !compact"
>
  <natea-icons name="stop" class="icon end-icon"></natea-icons>
</div>
<span
  class="end-hour-label hour-label"
  [class.compact]="compact"
  *ngIf="endDate"
  >{{ endDate | date : "HH:mm" }}</span
>
