import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ButtonVariants,
  NateaIconsName,
  OptionsItem,
  matchMediaDesktopSize,
} from 'natea-components';
import { Observable, tap } from 'rxjs';
import { selectIsNotNotesFromClipboard } from '../../../../features/board/store/board.selectors';
import { selectCurrentDepartment } from '../../../../features/departments/store/departments.selectors';
import { goToPatient } from '../../../../features/patients/store/patients.actions';
import {
  selectPatientsAsDropdownItems,
  selectSelectedPatient,
} from '../../../../features/patients/store/patients.selectors';
import { selectIsMenuOpened } from '../../../../features/users/store/users.selectors';
import { Department } from '../../../entities/department';
import { Patient } from '../../../entities/patient';
import { AppRoutes } from '../../../routes/routes';
import { optionItemFromPatient } from '../../../utils/utils';
@Component({
  selector: 'natea-cc-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent implements OnInit, OnDestroy {
  @Input() color: ButtonVariants = ButtonVariants.SECONDARY;
  @Input() label?: string;
  @Input() backButtonLabel = 'REPARTO';
  @Input() icon: NateaIconsName = 'arrow-left';

  @Output() pdfButton: EventEmitter<void> = new EventEmitter<void>();

  @Output() blackboardNotes: EventEmitter<void> = new EventEmitter<void>();

  isNotNotesFromClipboard$: Observable<boolean> = this.store.select(
    selectIsNotNotesFromClipboard
  );

  selectControl: FormControl<OptionsItem<Patient> | null> =
    new FormControl<OptionsItem<Patient> | null>(null);

  showBlackboardNotes = false;

  showBlackboardNotesTabletVersion = false;

  isMenuOpened$: Observable<boolean> = this.store.select(selectIsMenuOpened);

  public isDesktopSized = false;
  public get buttonSize(): 'small' | 'large' {
    // return this.isDesktopSized ? 'large' : 'small';
    return 'large';
  }

  private desktopSizeMatcher: MediaQueryList = matchMediaDesktopSize(window);

  selectedDepartment: Observable<Department | undefined> = this.store.select(
    selectCurrentDepartment
  );
  isClinicalNotesPage: boolean = this.router.url.includes('clinical-notes');
  patientsList: Observable<OptionsItem<string>[] | undefined> =
    this.store.select(selectPatientsAsDropdownItems);
  selectedPatient$: Observable<Patient | undefined> = this.store
    .select(selectSelectedPatient)
    .pipe(
      tap((patient: Patient | undefined) => {
        this.selectControl.setValue(
          patient ? optionItemFromPatient(patient) : null
        );
      })
    );

  readonly items: OptionsItem<string>[] = [
    {
      id: '1',
      label: 'Reparto',
    },
    {
      id: '2',
      label: 'mario rossi',
    },
    {
      id: '3',
      label: 'pippo maria',
    },
  ];

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isDesktopSized = this.desktopSizeMatcher.matches;

    this.desktopSizeMatcher.addEventListener(
      'change',

      this.onDesktopSizeChanged
    );
  }

  ngOnDestroy(): void {
    this.desktopSizeMatcher.removeEventListener(
      'change',

      this.onDesktopSizeChanged
    );
  }

  goToDepartments = (): void => {
    this.router.navigate(['departments']);
  };

  onDesktopSizeChanged = (event: MediaQueryListEvent): void => {
    this.isDesktopSized = event.matches;
  };

  selectPatient = (patientItem?: OptionsItem<string>): void => {
    const departmentId: string | null = this.route.snapshot.paramMap.get(
      AppRoutes.Parameters.departmentId
    );
    this.store.dispatch(
      goToPatient({ departmentId, patientId: patientItem?.id ?? '' })
    );
  };

  clickOutside = (event: Event): void => {
    event.stopPropagation();
    // event.preventDefault(); // TODO check if ok to disable
    this.showBlackboardNotesTabletVersion = false;
  };

  toggleBlackboardNotes = (event: Event): void => {
    event.stopPropagation();
    event.preventDefault();
    this.showBlackboardNotes = !this.showBlackboardNotes;
  };
}
