import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import {
  ButtonComponent,
  InputComponent,
  NateaIconsModule,
} from 'natea-components';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    InputComponent,
    ButtonComponent,
    TranslocoModule,
    ReactiveFormsModule,
    NateaIconsModule,
  ],
})
export class LoginModule {}
