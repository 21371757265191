import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FieldErrorComponent } from '../field/field-error/field-error.component';
import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'natea-textarea',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    ReactiveFormsModule,
    FieldErrorComponent,
  ],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: TextareaComponent, multi: true },
  ],
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent
  extends FieldComponent
  implements ControlValueAccessor, OnChanges
{
  @Input() resizable = true;
  @Input() textareaIsFilled = false;
  @Input() rows = 5;
  @Input() outValue = '';

  @Output() valueChanges: EventEmitter<string> = new EventEmitter<string>();

  value: string | null = null;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['outValue'] && changes['outValue'].currentValue) {
      this.value = this.outValue;
    }
  }

  onChange!: (val: string | null) => void;

  onTouched!: () => void;

  writeValue = (val: string): void => {
    this.value = val;
    this.cdr.detectChanges();
  };

  registerOnChange = (fn: (val: string | null) => void): void => {
    this.onChange = fn;
  };

  registerOnTouched = (fn: () => void): void => {
    this.onTouched = fn;
  };

  setDisabledState = (isDisabled: boolean): void => {
    this.isDisabled = isDisabled;
  };

  onInput = (event: Event): void => {
    const target: HTMLTextAreaElement = event.target as HTMLTextAreaElement;
    if (this.onChange) {
      this.value = target.value;
      this.onChange(target.value);
    }
    this.valueChanges.emit(target.value);
  };

  onBlurred = (): void => {
    this.markAsTouched();
  };

  markAsTouched = (): void => {
    if (this.onTouched) {
      this.onTouched();
    }
  };
}
