import { generateRandomInt } from '../../../../core/mocks/get-problems-mocked-response';
import { DepartmentData } from '../departments.reducers';

const TOTAL_DEPARTMENTS = 50;

/*export const mockDepartmentsList: () => DepartmentData[] = () => {
  return [...Array(departmentsNames.length).keys()].map((i) => {
    const id: string = (i + 1).toString();
    const totalBeds: number = generateRandomInt(10, 15);
    const occupiedBeds: number = generateRandomInt(8, totalBeds);
    const percentageOccupied: number = Math.round(
      (occupiedBeds / totalBeds) * 100
    );
    const colorOccupied: string =
      percentageOccupied < 70
        ? 'green'
        : percentageOccupied < 90
        ? 'yellow'
        : 'red';
    return {
      id,
      name: departmentsNames[i], // `Department ${id}`,
      totalBeds,
      occupiedBeds,
      description: `Department ${id} description`,
      percentageOccupied,
      colorOccupied,
    };
  });
};*/

export const percentageOccupied = (occupiedBeds: number, totalBeds: number) => {
  return Math.round((occupiedBeds / totalBeds) * 100);
}

const totalBeds = 42;
const occupiedBedsForTIN = 5;
const totalBedsForTIN = 10;

const percentageOccupiedForTIN = percentageOccupied(occupiedBedsForTIN, 10);
const colorOccupied = (percentage: number) => {
  return percentage < 70 ? 'green' : percentage < 90 ? 'yellow' : 'red';
}

const colorOccupiedTIN: string = percentageOccupiedForTIN < 70 ? 'green' : percentageOccupiedForTIN < 90 ? 'yellow' : 'red';

/*export const mockDepartmentsList: () => DepartmentData[] = () => {
  return [...Array(departmentsNames.length).keys()].map((i) => {
    const id: string = (i + 1).toString();
   
    const occupiedBeds: number = generateRandomInt(8, totalBeds);
    const percentageOccupied: number = Math.round(
      (occupiedBeds / totalBeds) * 100
    );
    const colorOccupied: string =
      percentageOccupied < 70
        ? 'green'
        : percentageOccupied < 90
        ? 'yellow'
        : 'red';
    return {
      id,
      name: departmentsNames[i], // `Department ${id}`,
      totalBeds: departmentsNames[i]=== 'TIN' ? totalBedsForTIN :  32,
      occupiedBeds: departmentsNames[i]=== 'TIN' ? 5 :  generateRandomInt(32, totalBeds),
      description: `Department ${id} description`,
      percentageOccupied: departmentsNames[i]=== 'TIN' ? percentageOccupiedForTIN : percentageOccupied,
      colorOccupied,
    };
  });
}*/

export const mockDepartmentsList: DepartmentData[] = [
  {
    id: '1',
    name: 'Nido',
    totalBeds: 32,
    occupiedBeds: 27,
    description: `Department ${1} description`,
    percentageOccupied: percentageOccupied(25, 32),
    colorOccupied: colorOccupied(percentageOccupied(25, 32)),
  },
  {
    id: '2',
    name: 'Patologia neonatale',
    totalBeds: 32,
    occupiedBeds: 26,
    description: `Department ${2} description`,
    percentageOccupied: percentageOccupied(26, 32),
    colorOccupied: colorOccupied(percentageOccupied(26, 32)),
  },
  {
    id: '3',
    name: 'TIN',
    totalBeds: 10,
    occupiedBeds: 10,
    description: `Department ${3} description`,
    percentageOccupied: percentageOccupied(10, 10),
    colorOccupied: colorOccupied(percentageOccupied(10, 10)),
  },


  {
    id: '4',
    name: 'Pediatria',
    totalBeds: 32,
    occupiedBeds: 27,
    description: `Department ${4} description`,
    percentageOccupied: percentageOccupied(27, 32),
    colorOccupied: colorOccupied(percentageOccupied(27, 32)),
  },
  {
    id: '5',
    name: 'Pediatria sez. trapianti',
    totalBeds: 32,
    occupiedBeds: 28,
    description: `Department ${5} description`,
    percentageOccupied: percentageOccupied(28, 32),
    colorOccupied: colorOccupied(percentageOccupied(28, 32))
  },
 

]

const departmentsNames = [
  'Nido',
  'Patologia neonatale',
  'TIN',
  'Pediatria',
  'Pediatria sez. trapianti',
];
