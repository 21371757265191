import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ButtonVariants, SizeType } from 'natea-components';
import { Department } from '../../../../shared/entities/department';
import { AppRoutes } from '../../../../shared/routes/routes';

@Component({
  selector: 'natea-cc-departments-table',
  templateUrl: './departments-table.component.html',
  styleUrls: ['./departments-table.component.scss'],
})
export class DepartmentsTableComponent implements OnChanges, AfterViewInit {
  buttonVariants = ButtonVariants;

  @Input() hospitalName!: string;
  @Input() departments: Department[] = [];
  @ViewChild('departmentsTable') departmentsTable!: ElementRef;

  isAnyData = true;

  // sortedDepartments: PatientProblem[] = [];
  showModal = false;

  desktopModal: SizeType = SizeType.LARGE;
  tabletModal: SizeType = SizeType.MEDIUM;

  initialSelection: Department[] = [];

  isOverflowing = false;

  displayedColumns: string[] = ['color', 'name', 'placesOccupied'];

  constructor(private router: Router) {}

  ngAfterViewInit(): void {
    setTimeout(this.checkOverflow);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['departments']?.currentValue) {
      if (!this.departments.length) {
        this.isAnyData = false;
      } else {
        this.isAnyData = true;
      }
    }
    setTimeout(this.checkOverflow);
  }

  checkOverflow = (): void => {
    this.isOverflowing =
      this.departmentsTable.nativeElement.offsetHeight +
        this.departmentsTable.nativeElement.scrollTop <
      this.departmentsTable.nativeElement.scrollHeight;
  };

  navigate = (departmentId: string): void => {
    this.router.navigateByUrl(AppRoutes.departmentBasePath(departmentId));
  };
}
