import { TherapyOccurrenceState } from '../entities/therapy-occurrence';

export const isNotExecuted = (
  occurrenceState: TherapyOccurrenceState,
  programmingDate: Date
): boolean => {
  return (
    programmingDate.getTime() < Date.now() &&
    (occurrenceState === TherapyOccurrenceState.Prescribed ||
      occurrenceState === TherapyOccurrenceState.SentToPreparation ||
      occurrenceState === TherapyOccurrenceState.Prepared)
  );
};
