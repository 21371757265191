import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { SidebarItemId } from '../../../shared/components/sidebar/models/sidebar-item';
import { User, UserRole } from '../../../shared/entities/user';
import {
  loginDemoAsNotStructuredDoctor,
  loginDemoAsNurse,
  loginDemoAsStructuredDoctor,
  loginDemoAsTutorDoctor,
  loginSuccess,
} from '../../login/store/login.actions';
import {
  notStructuredDoctorPermissions,
  nursePermissions,
  structuredDoctorPermissions,
  tutorDoctorPermissions,
} from '../data/user-permissions';
import { toggleMenu, toggleMenuGroupOpened } from './users.actions';

interface UserPreferences {
  isMenuOpened: boolean;
  expandedMenuGroups: SidebarItemId[];
}

export interface UserPermissions {
  canCreateNotes: boolean;
  canValidateNotes: boolean;
  areNotesAutoValidated: boolean;
  canConfirmTherapies: boolean;
}

export interface UserState {
  user?: User;
  username: string;
  preferences: UserPreferences;
  permissions?: UserPermissions;
}

export const initialUserState: UserState = {
  username: '',
  user: {
    id: 'demo-id',
    firstName: 'Maurizio',
    lastName: 'verdi',
    role: UserRole.Doctor,
  },
  preferences: {
    isMenuOpened: true,
    expandedMenuGroups: [],
  },
};

export const userReducer: ActionReducer<UserState, Action> = createReducer(
  initialUserState,
  on(
    toggleMenu,
    (state: UserState): UserState => ({
      ...state,
      preferences: {
        ...state.preferences,
        isMenuOpened: !state.preferences.isMenuOpened,
      },
    })
  ),
  on(
    toggleMenuGroupOpened,
    (state: UserState, { itemId }: { itemId: SidebarItemId }): UserState => ({
      ...state,
      preferences: {
        ...state.preferences,
        expandedMenuGroups: state.preferences.expandedMenuGroups.includes(
          itemId
        )
          ? state.preferences.expandedMenuGroups.filter((id) => id !== itemId)
          : [...state.preferences.expandedMenuGroups, itemId],
      },
    })
  ),

  on(
    loginDemoAsTutorDoctor,
    (state: UserState, { user }): UserState => ({
      ...state,
      user: user,
      permissions: tutorDoctorPermissions,
    })
  ),
  on(
    loginDemoAsStructuredDoctor,
    (state: UserState, { user }): UserState => ({
      ...state,
      user,
      permissions: structuredDoctorPermissions,
    })
  ),
  on(
    loginDemoAsNotStructuredDoctor,
    (state: UserState, { user }): UserState => ({
      ...state,
      user,
      permissions: notStructuredDoctorPermissions,
    })
  ),
  on(
    loginDemoAsNurse,
    (state: UserState): UserState => ({
      ...state,
      user: state.user
        ? {
            ...state.user,
            role: UserRole.Doctor,
          }
        : undefined,
      permissions: nursePermissions,
    })
  ),

  on(
    loginSuccess,
    (state: UserState, { user }: { user: User }): UserState => ({
      ...state,
      user,
    })
  )
);
