/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a pagination resource.
 */
export interface PaginationResource { 
    /**
     * The number of items displayed per page.
     */
    itemsPerPage: number;
    /**
     * The current page number in the pagination.
     */
    pageNumber: number;
    /**
     * The total number of items across all pages.
     */
    totalItemNumber: number;
    /**
     * The total number of pages available in the pagination.
     */
    totalPageNumber: number;
}

