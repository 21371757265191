import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FactSheetUiState } from './new-born.reducers';
import { NewBornData } from '../form/new-born-form-data';

export const selectNewBornUiState =
  createFeatureSelector<FactSheetUiState>('factSheet');

export const selectLoadAllBornTabs = createSelector(
  selectNewBornUiState,

  (state) => {
    return state.newBornData;
  }
);

export const selectPercentileData = createSelector(
  selectLoadAllBornTabs,
  (tabs: NewBornData | undefined) => {
    /* const tab = tabs.find((t) => t.id === NewBornSelectedTab.AuxologicalData);
    if (!tab) {
      return;
    }*/
    /* const data = tab?.data['auxologicalData'] as any;
    if (!data) {
      console.error('No auxological data found');
      return;
    }*/
    /*const percentileWeight: string = data?.percentileWeight;

    const percentileLength: string = data['percentileLength'];
    const percentileHeadCircumference: string =
      data['percentileHeadCircumference'];*/
    return {
      percentileWeight: tabs?.percentileWeight ?? '',
      percentileLength: tabs?.percentileLength ?? '',
      percentileHeadCircumference: tabs?.percentileHeadCircumference ?? '',
    };
  }
);

export const selectIsDataLoaded = createSelector(
  selectNewBornUiState,
  (state) => state.isLoadDataSuccess
);

export const selectIsDataSaving = createSelector(
  selectNewBornUiState,
  (state) => state.isSaving
);

export const selectDirectCoombsTestCatalog = createSelector(
  selectNewBornUiState,
  (state) => state.directCoombsTest
);

export const selectTimeInSecCatalog = createSelector(
  selectNewBornUiState,
  (state) => state.timeInSec
);

export const selectCollectionCatalog = createSelector(
  selectNewBornUiState,
  (state) => state.collection
);

export const selectUterineTransferCatalog = createSelector(
  selectNewBornUiState,
  (state) => state.uterineTransfer
);

export const selectEmissionUrineCatalog = createSelector(
  selectNewBornUiState,
  (state) => state.emissionUrine
);

export const selectEmissionMeconiumCatalog = createSelector(
  selectNewBornUiState,
  (state) => state.emissionMeconium
);

export const selectSurfacenteCatalog = createSelector(
  selectNewBornUiState,
  (state) => state.surfacente
);

export const selectGroupCatalog = createSelector(
  selectNewBornUiState,
  (state) => state.group
);

export const selectRhFactorCatalog = createSelector(
  selectNewBornUiState,
  (state) => state.rhFactor
);

export const selectPharmacyCatalog = createSelector(
  selectNewBornUiState,
  (state) => state.pharmacy
);

export const selectVitaminKCatalog = createSelector(
  selectNewBornUiState,
  (state) => state.vitaminK
);

export const selectRouteCatalog = createSelector(
  selectNewBornUiState,
  (state) => state.route
);
