<div *transloco="let t; read: 'common'" class="timeline-header">
  <span *ngFor="let date of dateRange" class="cell date full-date">
    <ng-container *ngIf="isToday(date); else notToday">
      <span class="today">{{ t("today") }}</span>
    </ng-container>
    <ng-template #notToday>{{
      date | localizedDate : LocalizedDateFormat.WeekdayDateMonth
    }}</ng-template>
  </span>
  <div *ngFor="let date of dateRange" class="timeline-header-borders"></div>
</div>
