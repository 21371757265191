import { BehaviorSubject } from 'rxjs';
export class LocaleProviderService {
  readonly locale = new BehaviorSubject<string>(navigator.language);

  constructor() {
    this.locale.next(navigator.language);
  }

  setLocale(locale: string) {
    this.locale.next(locale);
  }

  $get() {
    return {
      locale: this.locale,
    };
  }
}
