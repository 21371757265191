import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';

export enum LogActions {
  ShowLog = '[Log] Show Log',
  HideLog = '[Log] Hide Log',
  SortLog = '[Log] Sort Log',
}

export const showLog = createAction(
  LogActions.ShowLog,
  props<{ creationDate: Date }>()
);

export const hideLog = createAction(LogActions.HideLog);

export const sortLog = createAction(
  LogActions.SortLog,
  props<{ sort?: Sort }>()
);
