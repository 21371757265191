import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FamilyAnamnesisUiState } from './family-anamnesis.reducers';
import { selectLoadAllFamilyPathologies } from 'src/app/features/patients/store/clinical-data/anamnesis/patients-data-family-anamnesis.selectors';
import { AllFamilyPathologies } from '../entities/all-family-pathologies';

export const selectFamilyAnamnesisUiState =
  createFeatureSelector<FamilyAnamnesisUiState>('familyAnamnesis');

export const selectFamilyAnamnesisPathologies = createSelector(
  selectLoadAllFamilyPathologies,
  (allFamilyPathologies: AllFamilyPathologies | undefined) => {
    return allFamilyPathologies?.pathologies ?? [];
  }
);

export const selectFamilyAnamnesisShowModalInsertNewPathology = createSelector(
  selectFamilyAnamnesisUiState,
  (state: FamilyAnamnesisUiState) => state.showModalInsertNewPathology
);

export const selectIsToggleDisabled = createSelector(
  selectLoadAllFamilyPathologies,
  (allFamilyPathologies: AllFamilyPathologies | undefined): boolean => {
    if (allFamilyPathologies) {
      return allFamilyPathologies.pathologies.length > 0;
    } else {
      return false;
    }
  }
);

export const selectGeneralNotesOnFamilyAnamnesis = createSelector(
  selectLoadAllFamilyPathologies,
  selectFamilyAnamnesisUiState,
  (
    allFamilyPathologies: AllFamilyPathologies | undefined,
    state: FamilyAnamnesisUiState
  ) => {
    if (state.generalNotes) {
      return state.generalNotes;
    } else {
      return allFamilyPathologies?.note;
    }
  }
);

export const selectHasNoPathologies = createSelector(
  selectFamilyAnamnesisUiState,
  (state: FamilyAnamnesisUiState) => state.hasNoPathologies
);

export const selectFamilyAnamnesisShowWarningModalRemoveAll = createSelector(
  selectFamilyAnamnesisUiState,
  (state: FamilyAnamnesisUiState) => state.warningMessageEnum
);

export const selectFamilyAnamnesisShowLoaderFullPage = createSelector(
  selectFamilyAnamnesisUiState,
  (state: FamilyAnamnesisUiState) => state.showLoaderFullPage
);

export const selectFamilyAnamnesisShowLoader = createSelector(
  selectFamilyAnamnesisUiState,
  (state: FamilyAnamnesisUiState) => state.showLoader
);
