import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ButtonVariants } from 'natea-components';
import { shownName } from '../../../../../entities/user';
import { shortDateString } from '../../../../../utils/date-utils';
import {
  SearchPatientEncounterInterface,
  SearchPatientResultInterface,
  SearchPatientResultRow,
} from '../../interface/search-result';

@Component({
  selector: 'natea-cc-patients-result-table',
  templateUrl: './patients-result-table.component.html',
  styleUrls: ['./patients-result-table.component.scss'],
})
export class PatientResultTableComponent implements AfterViewInit, OnChanges {
  buttonVariants = ButtonVariants;

  @Input() results: SearchPatientResultInterface[] = [];
  @Output() navigateToPatient: EventEmitter<PatientSelectedEvent> =
    new EventEmitter<PatientSelectedEvent>();
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('patientResults') patientResults!: ElementRef;

  dataSource: SearchPatientResultRow[] = [];

  selectedRowPatient?: PatientSelectedEvent = {
    departmentId: '',
    patientId: '',
  };

  isOverflowing = false;
  colorVariantSecondary: ButtonVariants = ButtonVariants.SECONDARY;

  displayedColumns: string[] = [
    'patient',
    'dateOfBirth',
    'gestationalAge',
    'weight',
    'motherName',
    'department',
    'encounter',
  ];

  constructor(private translator: TranslocoService) {}

  ngAfterViewInit(): void {
    setTimeout(this.checkOverflow);
  }

  ngOnChanges(): void {
    this.dataSource = this.results.reduce(
      (
        acc: SearchPatientResultRow[],
        row: SearchPatientResultInterface,
        i: number
      ) => {
        const encounters: SearchPatientEncounterInterface[] =
          row.encounters ?? [];
        return [
          ...acc,
          ...encounters.map(
            (
              encounter: SearchPatientEncounterInterface,
              j: number
            ): SearchPatientResultRow => ({
              id: row.id,
              evenRow: i % 2 !== 0,
              groupStart: j === 0,
              groupEnd: j === encounters.length - 1,
              name: j === 0 ? row.name : '',
              birthDate: j === 0 ? row.birthDate : undefined,
              parent: j === 0 && row.parent ? shownName(row.parent) : undefined,
              gestationalAge: j === 0 ? row.gestationalAge : undefined,
              weight: j === 0 ? row.weight : undefined,
              encounter: {
                ...encounter,
                departmentName:
                  j === 0 ||
                  encounters[j - 1].departmentId !== encounter.departmentId
                    ? encounter.departmentName
                    : '',
              },
            })
          ),
        ];
      },
      []
    );
    setTimeout(this.checkOverflow);
  }

  checkOverflow = (): void => {
    this.isOverflowing =
      this.patientResults.nativeElement.offsetHeight +
        this.patientResults.nativeElement.scrollTop <
      this.patientResults.nativeElement.scrollHeight;
  };

  navigate = (event: SearchPatientResultRow): void => {
    this.navigateToPatient.emit({
      departmentId: event.encounter.departmentId,
      patientId: event.id,
      encounterId: event.encounter.id,
    });
  };

  closeModal = (): void => {
    this.closed.emit();
  };

  encounterLinkText = (encounter: SearchPatientEncounterInterface): string => {
    return this.translator.translate(
      encounter.endDate
        ? 'patient.search.closedEncounter'
        : 'patient.search.currentEncounter',
      {
        startDate: shortDateString(encounter.startDate),
        endDate: encounter.endDate ? shortDateString(encounter.endDate) : null,
      }
    );
  };
}

export interface PatientSelectedEvent {
  departmentId: string;
  patientId: string;
  encounterId?: string;
}
