<natea-cc-base-page
  [hasSidebar]="false"
  [complexContent]="false"
  *transloco="let t; read: 'departments'"
  headerContentAlign="start"
>
  <natea-cc-patients-list-header
    sub-header
    [hospitalName]="hospitalName"
    [isDesktopSized]="isDesktopSized"
    (searchPatient)="onSearchPatient()"
  ></natea-cc-patients-list-header>
  <h3 header-content>{{ t("selectDepartments") }}</h3>

  <div main-content class="problem-main-content">
    <natea-cc-departments-table
      [hospitalName]="hospitalName"
      [departments]="(departmentsData$ | async) ?? []"
    ></natea-cc-departments-table>
    <ng-container modals>
      <natea-cc-search-patients
        *ngIf="showSearchPatientModal$ | async"
      ></natea-cc-search-patients>
    </ng-container>
  </div>
</natea-cc-base-page>
