import { createAction, props } from '@ngrx/store';
import { ApiError } from '../../../shared/entities/errors';
import { User } from '../../../shared/entities/user';
import { LoginData } from './interfaces/login-data';

export enum LoginActions {
  LoginData = '[Login] Login Data',
  LoginSuccess = '[Login] Login Success',
  LoginFail = '[Login] Login Fail',
  DemoTutorDoctorLogin = '[Login] Demo Tutor Doctor Login',
  DemoStructuredDoctorLogin = '[Login] Demo Structured Doctor Login',
  DemoNotStructuredDoctorLogin = '[Login] Demo Not Structured Doctor Login',
  DemoNurseLogin = '[Login] Demo Nurse Login',
}

export const loginData = createAction(
  LoginActions.LoginData,
  props<{ data: LoginData }>()
);

export const loginSuccess = createAction(
  LoginActions.LoginSuccess,
  props<{ user: User }>()
);

export const loginFailure = createAction(
  LoginActions.LoginFail,
  props<{ error: ApiError }>()
);

export const loginDemoAsTutorDoctor = createAction(
  LoginActions.DemoTutorDoctorLogin,
  props<{ user: User }>()
);

export const loginDemoAsStructuredDoctor = createAction(
  LoginActions.DemoStructuredDoctorLogin,
  props<{ user: User }>()
);

export const loginDemoAsNotStructuredDoctor = createAction(
  LoginActions.DemoNotStructuredDoctorLogin,
  props<{ user: User }>()
);

export const loginDemoAsNurse = createAction(
  LoginActions.DemoNurseLogin,
  props<{ user: User }>()
);
