import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SnackbarService } from 'natea-components';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { AuthManager } from '../../../core/api/auth/auth-manager';
import { errorMessage } from '../../../shared/entities/errors';
import { UserRole } from '../../../shared/entities/user';
import { AppRoutes } from '../../../shared/routes/routes';
import { SNACK_BAR_DEBOUNCE_TIME_MS } from '../../../shared/utils/constants';
import { LoginData } from './interfaces/login-data';
import { LoginActions, loginFailure, loginSuccess } from './login.actions';

@Injectable()
export class LoginEffects {
  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.LoginData),
      exhaustMap(({ data }: { data: LoginData }) => {
        return this.authManager.login(data).pipe(
          map((user) => {
            // TODO this is a temporary for the demo, remove it later
            if (!user.role) {
              user.role = UserRole.Doctor;
            }
            return loginSuccess({ user });
          }),
          catchError((error) => {
            return of(loginFailure({ error }));
          })
        );
      })
    );
  });

  loginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginActions.LoginSuccess),
        exhaustMap(() => {
          this.router.navigateByUrl(AppRoutes.departmentsRoot);
          return of({});
        })
      );
    },
    { dispatch: false }
  );

  loginFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginActions.LoginFail),
        map((error) => {
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate('login.loginFailed'),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private authManager: AuthManager,
    private translocoService: TranslocoService,
    private snackBar: SnackbarService
  ) {}
}
