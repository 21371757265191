<div>
  <mat-radio-group
    [class.vertically]="alignVertically"
    [color]="color"
    [value]="value"
    [disabled]="disabled"
    [required]="required"
    [name]="name"
    [labelPosition]="labelPosition"
    [id]="id"
  >
    <mat-radio-button
      *ngFor="let item of items"
      [value]="item.id"
      [checked]="item.id === value"
      (change)="onChanged($event)"
      >{{ item.label }}
    </mat-radio-button>
  </mat-radio-group>
</div>
