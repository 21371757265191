import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { TherapyOccurrenceState } from '../../../../therapies/common/entities/therapy-occurrence';
import {
  checkFilterTherapiesDate,
  filterTherapiesAdministrationState,
  filterTherapiesDate,
} from './pharmacological-therapies-administration.action';

export interface PharmacologicalTherapiesAdministrationUiState {
  filterAdministrationTherapyState?: TherapyOccurrenceState;

  filterAdministrationTherapyDate?: Date;
}

export const PharmacologicalTherapiesAdministrationInitialState: PharmacologicalTherapiesAdministrationUiState =
  {
    filterAdministrationTherapyState: undefined,
    filterAdministrationTherapyDate: undefined,
  };

export const pharmacologicalTherapiesAdministrationUiReducer: ActionReducer<
  PharmacologicalTherapiesAdministrationUiState,
  Action
> = createReducer(
  PharmacologicalTherapiesAdministrationInitialState,

  on(
    filterTherapiesAdministrationState,
    (
      state: PharmacologicalTherapiesAdministrationUiState,
      { therapyOccurrenceState }
    ): PharmacologicalTherapiesAdministrationUiState => ({
      ...state,
      filterAdministrationTherapyState: therapyOccurrenceState,
    })
  ),
  on(
    filterTherapiesDate,
    (
      state: PharmacologicalTherapiesAdministrationUiState,
      { date }
    ): PharmacologicalTherapiesAdministrationUiState => ({
      ...state,
      filterAdministrationTherapyDate: date,
    })
  ),
  on(
    checkFilterTherapiesDate,
    (
      state: PharmacologicalTherapiesAdministrationUiState
    ): PharmacologicalTherapiesAdministrationUiState => {
      if (state.filterAdministrationTherapyDate) {
        return { ...state, filterAdministrationTherapyDate: undefined };
      }
      return { ...state };
    }
  )
);
