import { ReducerTypes, on } from '@ngrx/store';
import { ProcedureOccurrenceDurationType } from '../../../../../shared/components/multi-section-timeline/models/multi-section-timeline-instance';
import {
  ProcedureOccurrence,
  ProcedureOccurrenceState,
} from '../../../../procedures/entities/procedure-occurrence';
import {
  cancelProcedureExecutionSuccess,
  createMovementSuccess,
  createProcedureSuccess,
  deleteMovementSuccess,
  deleteProcedureSuccess,
  loadProceduresSuccess,
  saveProcedureExecutionStepSuccess,
  saveProcedureProgrammingStepSuccess,
  saveProcedureTerminationStepSuccess,
  stopProcedureRunSuccess,
  updateMovementSuccess,
} from '../../../../procedures/store/procedures.actions';
import {
  EncounterData,
  PatientsState,
  updateEncounterData,
} from '../../patients.reducers';

export const patientProceduresReducerFunctions: ReducerTypes<
  PatientsState,
  [never]
>[] = [
  on(loadProceduresSuccess, (state, { data, patientId, encounterId }) => {
    return updateEncounterData(state, { patientId, encounterId }, () => ({
      procedureOccurrences: data,
    }));
  }),

  on(
    saveProcedureProgrammingStepSuccess,
    (
      state: PatientsState,
      {
        patientId,
        encounterId,
        procedureId,
      }: {
        patientId: string;
        encounterId: string;
        procedureId: string;
      }
    ): PatientsState => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData: EncounterData | undefined) => ({
          procedureOccurrences: (encounterData?.procedureOccurrences ?? []).map(
            (occurrence: ProcedureOccurrence): ProcedureOccurrence => {
              const ret: ProcedureOccurrence = { ...occurrence };
              if (occurrence.id === procedureId) {
                ret.programmingStepChanged = true;
              }
              return ret;
            }
          ),
        })
      );
    }
  ),

  on(
    saveProcedureExecutionStepSuccess,
    (
      state: PatientsState,
      {
        patientId,
        encounterId,
        procedureId,
      }: {
        patientId: string;
        encounterId: string;
        procedureId: string;
      }
    ): PatientsState => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData: EncounterData | undefined) => ({
          procedureOccurrences: (encounterData?.procedureOccurrences ?? []).map(
            (occurrence: ProcedureOccurrence): ProcedureOccurrence => {
              const ret: ProcedureOccurrence = { ...occurrence };
              if (occurrence.id === procedureId) {
                ret.programmingStepChanged = false;
                if (ret.type === ProcedureOccurrenceDurationType.Instant) {
                  ret.state = ProcedureOccurrenceState.Executed;
                  ret.terminationDate = ret.executionDate;
                } else {
                  ret.state = ProcedureOccurrenceState.Running;
                }
              }
              return ret;
            }
          ),
        })
      );
    }
  ),

  on(
    saveProcedureTerminationStepSuccess,
    (
      state: PatientsState,
      {
        patientId,
        encounterId,
        procedureId,
      }: {
        patientId: string;
        encounterId: string;
        procedureId: string;
      }
    ): PatientsState => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData: EncounterData | undefined) => ({
          procedureOccurrences: (encounterData?.procedureOccurrences ?? []).map(
            (occurrence: ProcedureOccurrence): ProcedureOccurrence => {
              const ret = { ...occurrence };
              if (occurrence.id === procedureId) {
                ret.programmingStepChanged = false;
                ret.state = ProcedureOccurrenceState.Executed;
                ret.terminationDate = ret.executionDate;
              }
              return ret;
            }
          ),
        })
      );
    }
  ),

  on(
    deleteProcedureSuccess,
    (
      state: PatientsState,
      {
        patientId,
        encounterId,
        procedureId,
      }: {
        patientId: string;
        encounterId: string;
        procedureId: string;
      }
    ): PatientsState => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData: EncounterData | undefined) => ({
          procedureOccurrences: (
            encounterData?.procedureOccurrences ?? []
          ).filter(
            (occurrence: ProcedureOccurrence): boolean =>
              occurrence.id !== procedureId
          ),
        })
      );
    }
  ),

  on(
    stopProcedureRunSuccess,
    (
      state: PatientsState,
      {
        patientId,
        encounterId,
        procedureId,
      }: {
        patientId: string;
        encounterId: string;
        procedureId: string;
      }
    ): PatientsState => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData: EncounterData | undefined) => ({
          procedureOccurrences: (encounterData?.procedureOccurrences ?? []).map(
            (occurrence: ProcedureOccurrence): ProcedureOccurrence => {
              if (occurrence.id === procedureId) {
                return {
                  ...occurrence,
                  programmingStepChanged: false,
                  state: ProcedureOccurrenceState.Planned,
                  executor: null,
                  device: null,
                  lot: null,
                  insertion: null,
                  place: null,
                  insertionLength: null,
                  notes: null,
                };
              }
              return occurrence;
            }
          ),
        })
      );
    }
  ),

  on(
    cancelProcedureExecutionSuccess,
    (
      state: PatientsState,
      {
        patientId,
        encounterId,
        procedureId,
      }: {
        patientId: string;
        encounterId: string;
        procedureId: string;
      }
    ): PatientsState => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData: EncounterData | undefined) => ({
          procedureOccurrences: (encounterData?.procedureOccurrences ?? []).map(
            (occurrence: ProcedureOccurrence): ProcedureOccurrence => {
              if (occurrence.id === procedureId) {
                const state: ProcedureOccurrenceState =
                  occurrence.type ===
                    ProcedureOccurrenceDurationType.Prolonged &&
                  occurrence.state === ProcedureOccurrenceState.Executed
                    ? ProcedureOccurrenceState.Running
                    : ProcedureOccurrenceState.Planned;

                const ret = {
                  ...occurrence,
                  state,
                  programmingStepChanged: false,
                  adverseEvents: null,
                  movements: [],
                  ...(state === ProcedureOccurrenceState.Planned
                    ? {
                        executor: null,
                        device: null,
                        lot: null,
                        insertion: null,
                        place: null,
                        insertionLength: null,
                        notes: null,
                      }
                    : {}),
                };

                return ret;
              }
              return occurrence;
            }
          ),
        })
      );
    }
  ),

  on(createProcedureSuccess, (state, { procedure, patientId, encounterId }) => {
    return updateEncounterData(
      state,
      { patientId, encounterId },
      (encounterData) => ({
        procedureOccurrences: [
          ...(encounterData?.procedureOccurrences ?? []),
          procedure,
        ],
      })
    );
  }),

  /**************************** MOVEMENTS ****************************/

  on(
    createMovementSuccess,
    (state, { occurrenceId, patientId, encounterId, movement }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          procedureOccurrences: encounterData?.procedureOccurrences?.map(
            (occurrence) => {
              if (occurrence.id === occurrenceId) {
                return {
                  ...occurrence,
                  movements: occurrence.movements
                    ? [...occurrence.movements, movement]
                    : [movement],
                };
              }
              return occurrence;
            }
          ),
        })
      );
    }
  ),
  on(
    updateMovementSuccess,
    (state, { movementId, occurrenceId, patientId, encounterId, movement }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          procedureOccurrences: encounterData?.procedureOccurrences?.map(
            (occurrence) => {
              if (occurrence.id === occurrenceId) {
                return {
                  ...occurrence,
                  movements: occurrence.movements?.map((m) =>
                    m.id === movementId ? movement : m
                  ),
                };
              }
              return occurrence;
            }
          ),
        })
      );
    }
  ),
  on(
    deleteMovementSuccess,
    (state, { movementId, occurrenceId, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          procedureOccurrences: encounterData?.procedureOccurrences?.map(
            (occurrence) => {
              if (occurrence.id === occurrenceId) {
                return {
                  ...occurrence,
                  movements: occurrence.movements?.filter(
                    (m) => m.id !== movementId
                  ),
                };
              }
              return occurrence;
            }
          ),
        })
      );
    }
  ),
];
