import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { OptionsItem } from 'natea-components';
import {
  loadAllFactSheetNewBorn,
  loadAllFactSheetNewBornCatalogsSuccess,
  loadAllFactSheetNewBornFailure,
  loadAllFactSheetNewBornSuccess,
  saveFactSheetNewBorn,
  saveFactSheetNewBornSuccess,
} from './new-born.actions';
import { DomainItem } from 'src/app/shared/entities/domain-item';
import { NewBornData } from '../form/new-born-form-data';

export interface FactSheetUiState {
  isSaving: boolean;
  newBornData?: NewBornData;
  isLoadDataSuccess: boolean;
  directCoombsTest: OptionsItem<DomainItem>[];
  timeInSec: OptionsItem<DomainItem>[];
  collection: OptionsItem<DomainItem>[];
  uterineTransfer: OptionsItem<DomainItem>[];
  emissionUrine: OptionsItem<DomainItem>[];
  emissionMeconium: OptionsItem<DomainItem>[];
  surfacente: OptionsItem<DomainItem>[];
  group: OptionsItem<DomainItem>[];
  rhFactor: OptionsItem<DomainItem>[];
  pharmacy: OptionsItem<DomainItem>[];
  vitaminK: OptionsItem<DomainItem>[];
  route: OptionsItem<DomainItem>[];
}

export const iniTialFactSheetUiState: FactSheetUiState = {
  isSaving: false,

  isLoadDataSuccess: true,
  directCoombsTest: [],
  timeInSec: [],
  collection: [],
  uterineTransfer: [],
  emissionUrine: [],
  emissionMeconium: [],
  surfacente: [],
  group: [],
  rhFactor: [],
  pharmacy: [],
  vitaminK: [],
  route: [],
};

export const factSheetUiReducer: ActionReducer<FactSheetUiState, Action> =
  createReducer(
    iniTialFactSheetUiState,
    on(
      loadAllFactSheetNewBorn,
      (state: FactSheetUiState): FactSheetUiState => ({
        ...state,
        isLoadDataSuccess: true,
      })
    ),
    on(
      loadAllFactSheetNewBornSuccess,
      (state: FactSheetUiState, { factSheet }): FactSheetUiState => ({
        ...state,
        newBornData: factSheet,
        isLoadDataSuccess: false,
      })
    ),
    /* on(checkValidTab, (state: FactSheetUiState, { tabId, statusForm }) => {
      const tabs = state.tabs;

      return { ...state, tabs };
    }),*/
    on(
      saveFactSheetNewBornSuccess,
      (state: FactSheetUiState, { factSheet }): FactSheetUiState => ({
        ...state,
        isSaving: false,
        newBornData: factSheet,
      })
    ),
    on(
      loadAllFactSheetNewBornFailure,
      (state: FactSheetUiState): FactSheetUiState => ({
        ...state,
        isLoadDataSuccess: true,
      })
    ),

    on(
      saveFactSheetNewBorn,
      (state: FactSheetUiState): FactSheetUiState => ({
        ...state,
        isSaving: true,
      })
    ),
    on(
      loadAllFactSheetNewBornCatalogsSuccess,
      (state: FactSheetUiState, { catalogs }): FactSheetUiState => {
        return {
          ...state,
          directCoombsTest: catalogs['general']['directCoombsTest'],
          timeInSec: catalogs['general']['timeInSec'],
          collection: catalogs['general']['collection'],
          uterineTransfer: catalogs['general']['uterineTransfer'],
          emissionUrine: catalogs['general']['emissionUrine'],
          emissionMeconium: catalogs['general']['emissionMeconium'],
          surfacente: catalogs['general']['surfacente'],
          group: catalogs['blood']['group'],
          rhFactor: catalogs['blood']['rhFactor'],
          pharmacy: catalogs['occultProphylaxis']['pharmacy'],
          vitaminK: catalogs['vitaminK']['vitaminK'],
          route: catalogs['vitaminK']['route'],
        };
      }
    )
  );
