import { CommonDomaine } from '../interface/common-domaine.interface';

export const commonDomaine: CommonDomaine = {
  directCoombsTest: [
    { key: '1', label: 'Debolmente positivo' },
    { key: '2', label: 'Positivo' },
    { key: '0', label: 'Negativo' },
    { key: '3', label: 'Fortemente negativo' },
  ],
  milkingTime: [
    { key: '1', label: '30-60' },
    { key: '2', label: '60-120' },
    { key: '3', label: '120-180' },
  ],
  withdrawal: [
    { key: '1', label: 'Sì' },
    { key: '0', label: 'No' },
  ],
  uterineTransfer: [
    { key: '1', label: 'Sì' },
    { key: '0', label: 'No' },
  ],
  emissionUrine: [
    { key: '1', label: 'Sì' },
    { key: '0', label: 'No' },
  ],
  emissionMeconium: [
    { key: '1', label: 'Sì' },
    { key: '0', label: 'No' },
  ],
  surfacente: [
    { key: '1', label: 'Sì' },
    { key: '0', label: 'No' },
  ],

  bloodGroup: [
    { key: '1', label: 'A' },
    { key: '2', label: 'B' },
    { key: '3', label: 'AB' },
    { key: '4', label: 'O' },
  ],
  rhFactor: [
    { key: '1', label: 'Rh+' },
    { key: '2', label: 'Rh-' },
  ],

  pharmacy: [
    { key: '6', label: 'Ofloxacina' },
    {
      key: '3',
      label: 'Soluzione di iodio-povidrone al 2.4% a base acquosa',
    },
    { key: '4', label: "Soluzione di nitrato d'argento all' 1%" },
    { key: '5', label: "Unguento oftalmico di cloramfenicolo all'1% " },
    { key: '2', label: 'Unguento oftalmico di eritromicina allo 0,5%' },
    {
      key: '1',
      label: "Unguento oftalmico di tetraciclina idrocloridrata all'1%",
    },
  ],

  vitaminK: [
    { key: '1', label: '1 mg' },
    { key: '2', label: '2 mg' },
    { key: '3', label: '3 mg' },
    { key: '4', label: '4 mg' },
  ],
  route: [
    { key: '1', label: 'Orale' },
    { key: '2', label: 'Intramuscolare' },
    { key: '3', label: 'Intravenosa' },
  ],

  echocardiogram: [
    { key: '1', label: 'Normale' },
    { key: '2', label: 'Patologico' },
  ],

  menstrualCycle: [
    { key: '1', label: 'Regolare' },
    { key: '2', label: 'Irregolare' },
  ],
  obstetricVisits: [
    { key: '0', label: '0' },
    { key: '1', label: '<3' },
    { key: '2', label: '3 a 5' },
    { key: '3', label: '>5' },
    { key: '4', label: 'NC' },
  ],

  pregnancyCareTechniques: [
    { key: '1', label: 'Solo trattamento farmacologico' },
    { key: '2', label: 'FIVET' },
    { key: '3', label: 'GIFT' },
    { key: '4', label: 'ICSI' },
    { key: '5', label: 'IUI' },
    { key: '6', label: 'Altro' }
  ],
};
