import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  EncounterApiService,
  EncounterResource,
  GeneralResponseListEncounterResource,
} from '../../../core/generated/api';
import { PatientEncounter } from '../../problems/interfaces/patient-encounter';
import { ProblemsAdapters } from '../../problems/webapis/problems.adapters';

@Injectable({
  providedIn: 'root',
})
export class EncounterWebApi {
  constructor(private encounterService: EncounterApiService) {}
  public getEncountersForPatient(
    patientId: string
  ): Observable<PatientEncounter[]> {
    return this.encounterService.getPatientEncounters(patientId).pipe(
      map((encounters: GeneralResponseListEncounterResource) => {
        return (
          encounters.payload?.map((encounter: EncounterResource) =>
            ProblemsAdapters.patientEncounterBeToFe(encounter)
          ) ?? []
        );
      })
    );
  }
}
