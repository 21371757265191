import { RouterReducerState, getRouterSelectors } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectCurrentDepartment } from '../../features/departments/store/departments.selectors';
import { selectSelectedPatient } from '../../features/patients/store/patients.selectors';
import { AppRoutes } from './routes';

const selectRouterState = createFeatureSelector<RouterReducerState>('router');
const { selectUrl } = getRouterSelectors(selectRouterState);

export const selectCurrentUrl = createSelector(selectUrl, (url) => url);

export const selectSelectedPatientBasePath = createSelector(
  selectCurrentDepartment,
  selectSelectedPatient,
  (department, patient) => {
    if (!department || !patient) {
      return undefined;
    }
    return AppRoutes.patientBasePath(department.id, patient.id);
  }
);
