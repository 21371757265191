import { of } from 'rxjs';
import { DepartmentsWebApi } from '../../webapis/departments-web-api';
import { mockDepartmentsList } from './mock-departments';
import { Injectable } from '@angular/core';

@Injectable()
export class MockedDepartmentsWebApi extends DepartmentsWebApi {
  override getDepartments() {
    return of(mockDepartmentsList);
  }
}
