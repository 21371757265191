import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import {
  ButtonVariants,
  OptionsItem,
  TabItem,
  matchMediaDesktopSize,
} from 'natea-components';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest,
  distinctUntilChanged,
  map,
  take,
} from 'rxjs';
import {
  hideLog,
  showLog,
} from '../../shared/components/log-viewer/store/log.actions';
import { selectShowLog } from '../../shared/components/log-viewer/store/log.selectors';
import { TextsAndOptionsIcons } from '../../shared/components/modals/legend-modal/models/legend-content';
import { ProfessionalCategory } from '../../shared/entities/professional-category';
import { createProblemComparator } from '../../shared/utils/optional-item-entity-utils';
import { BoardContent } from '../board/interfaces/board-content';
import { saveBoardContentToBoard } from '../board/store/board.actions';
import {
  hideEncounterForSelectedPatient,
  showEncounterForSelectedPatient,
} from '../patients/store/patients.actions';
import { PatientData } from '../patients/store/patients.reducers';
import {
  selectEncounterTabs,
  selectSelectedPatient,
  selectShownEncounter,
  selectShownEncounterIds,
} from '../patients/store/patients.selectors';
import { EditProblemParams } from './components/problem-insert-new-problem-modal/problem-insert-new-problem-modal.component';
import { ProblemFormData } from './interfaces/new-problem-form';
import { PatientProblem } from './interfaces/patient-problem';
import { ProblemType } from './interfaces/problem-type';
import { ProblemsFilter } from './interfaces/problems-filter';
import { ProblemsVisualization } from './problems.constants';
import {
  createPatientProblem,
  deletePatientProblem,
  hideProblemLegendModal,
  hideProblemModal,
  loadAllProblems,
  problemDeletionAborted,
  problemDeletionRequired,
  showCreateProblemModal,
  showEditProblemModal,
  showProblemLegendModal,
  showViewProblemModal,
  sortProblems,
  switchVisualization,
  updatePatientProblem,
} from './store/problems.actions';
import {
  selectAllProblemTypes,
  selectIsAppliedFilter,
  selectIsFilterNotApplied,
  selectProblemToDelete,
  selectProblemsFiltered,
  selectProblemsIsCreatingOrEditing,
  selectProblemsSort,
  selectProblemsVisualization,
  selectShowProblemLegend,
  selectShowProblemModal,
  selectShowProblemsLoader,
} from './store/problems.selectors';

@Component({
  selector: 'natea-cc-problems',
  templateUrl: './problems.component.html',
  styleUrls: ['./problems.component.scss'],
})
export class ProblemsComponent implements OnInit, OnDestroy {
  ProfessionalCategory = ProfessionalCategory;
  buttonVariants = ButtonVariants;
  ProblemsVisualization = ProblemsVisualization;

  readonly legendData: TextsAndOptionsIcons[] = [
    {
      text: this.translocoService.translate('problems.legend.startedProblem'),
      iconName: 'play',
    },

    {
      text: this.translocoService.translate('problems.legend.closedProblem'),
      iconName: 'stop',
      iconTitle: 'stop',
    },

    {
      text: this.translocoService.translate('problems.legend.medicalProblem'),
      iconName: 'medico',
    },
    {
      text: this.translocoService.translate('problems.legend.nurseProblem'),
      iconName: 'infermiere',
    },
    {
      text: this.translocoService.translate('problems.legend.updateProblema'),
      iconName: 'edit',
    },
    {
      text: this.translocoService.translate('problems.legend.deleteProblema'),
      iconName: 'bin',
    },

    {
      text: this.translocoService.translate(
        'problems.legend.elapsTimeFromStart'
      ),
      iconName: 'clock',
    },

    {
      text: this.translocoService.translate(
        'problems.legend.infoRelativeToProblem'
      ),
      iconName: 'circle-info',
    },
  ];

  showLog$ = this.store.select(selectShowLog);

  showProblemLegend$: Observable<boolean> = this.store.select(
    selectShowProblemLegend
  );

  problemsData: ProblemType[] = [];

  filteredProblems$: Observable<PatientProblem[] | undefined> =
    this.store.select(selectProblemsFiltered);

  canAddPaddingBottomToProblemContainer = false;

  isAppliedFilter$: Observable<boolean> = this.store.select(
    selectIsAppliedFilter
  );

  isFilterNotApplied$: Observable<boolean> = this.store.select(
    selectIsFilterNotApplied
  );

  isLoadingData$ = this.store.select(selectShowProblemsLoader);

  selectedSection$ = this.store.select(selectProblemsVisualization);

  private sort: Observable<Sort | undefined> =
    this.store.select(selectProblemsSort);
  private problemTypes: Observable<ProblemType[] | undefined> =
    this.store.select(selectAllProblemTypes);

  sortedProblems$: Observable<PatientProblem[]> = combineLatest([
    this.filteredProblems$,
    this.sort,
  ]).pipe(
    map(
      ([problemsList, sort]: [
        PatientProblem[] | undefined,
        Sort | undefined
      ]): PatientProblem[] => {
        const sortComparator: (a: PatientProblem, b: PatientProblem) => number =
          createProblemComparator(this.translocoService, sort);

        const sortedProblems: PatientProblem[] | undefined =
          problemsList?.slice();
        sortedProblems?.sort(sortComparator);
        return sortedProblems ?? [];
      }
    )
  );

  showProblemModal$ = this.store.select(selectShowProblemModal);

  problemNameOptions: OptionsItem<ProblemType>[] = [];

  isDeleteModal = false;

  problemToEdit!: PatientProblem;

  selectedPatient$: Observable<PatientData | undefined> = this.store.select(
    selectSelectedPatient
  );

  problemToDelete$ = this.store.select(selectProblemToDelete);

  getProblemsFilter$: Observable<ProblemsFilter> =
    new Observable<ProblemsFilter>();

  showModal = false;
  isSave = false;
  showDeleteModal = false;

  encounterTabs$: Observable<{
    tabs: TabItem[];
    activeIndex: number | null;
  }> = this.store.select(selectEncounterTabs);

  isFormTouchedAndDuty = false;

  private desktopSizeMatcher: MediaQueryList = matchMediaDesktopSize(window);
  isDesktopSized = false;

  logTitle = '';

  /**
   * Subject used to notify to the timeline component that the value of the
   * selected date has changed
   */
  dateChangedSubject: Subject<Date> = new Subject<Date>();

  shownEncounter$ = this.store.select(selectShownEncounter);
  shownEncounterIds$ = this.store.select(selectShownEncounterIds);
  isDeletingProblem$: Observable<boolean> = this.store.select(
    selectProblemsIsCreatingOrEditing
  );

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store,
    private translocoService: TranslocoService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    console.log('ProblemsComponent ngOnInit');
    this.encounterTabs$.pipe(take(1)).subscribe((tabs) => {
      console.log('tabs', tabs);
    });
    this.encounterTabs$.subscribe((tabs) => {
      console.log('tabs', tabs);
    });
    this.subscriptions.push(
      this.shownEncounterIds$
        .pipe(
          distinctUntilChanged(
            (a, b) =>
              a?.encounterId === b?.encounterId && a?.patientId === b?.patientId
          )
        )
        .subscribe((shownEncounterIds) => {
          console.log('shownEncounterIds', shownEncounterIds);
          if (shownEncounterIds) {
            const { encounterId, patientId } = shownEncounterIds;

            // Load problems every time that the encounter changes
            this.store.dispatch(
              loadAllProblems({
                patientId: patientId,
                encounterId: encounterId,
              })
            );
          }
        })
    );

    this.subscriptions.push(
      this.problemTypes.subscribe((data: ProblemType[] | undefined) => {
        data
          ?.map(
            (problemType: ProblemType): OptionsItem<ProblemType> => ({
              id: problemType.id,
              label: problemType.name,
            })
          )
          .forEach((item: OptionsItem<ProblemType>) => {
            if (
              !this.problemNameOptions.find(
                (option: OptionsItem<ProblemType>): boolean =>
                  option.id === item.id
              )
            ) {
              this.problemNameOptions.push(item);
            }
          });
      })
    );

    this.isDesktopSized = this.desktopSizeMatcher.matches;

    this.desktopSizeMatcher.addEventListener(
      'change',
      this.onDesktopSizeChanged
    );
  }

  ngOnDestroy(): void {
    while (this.subscriptions.length > 0) {
      this.subscriptions.pop()?.unsubscribe();
    }
  }

  switchVisual = (): void => {
    this.store.dispatch(switchVisualization());
  };

  onCreateProblem = () => {
    this.store.dispatch(showCreateProblemModal());
  };

  onEditProblem = (problem: PatientProblem): void => {
    this.showModal = true;
    this.problemToEdit = problem;

    this.store.dispatch(
      showEditProblemModal({
        problemToEdit: problem,
      })
    );
  };

  onDeleteProblem = (problem: PatientProblem): void => {
    this.store.dispatch(problemDeletionRequired({ problem }));
  };

  onSaveNewProblem = (newProblem: ProblemFormData): void => {
    this.store.dispatch(
      createPatientProblem({
        problem: newProblem,
      })
    );
  };

  onUpdateProblem = ({
    formData,
    problemId,
    patientId,
  }: EditProblemParams): void => {
    this.store.dispatch(
      updatePatientProblem({
        problem: formData,
        problemId,
        patientId,
      })
    );
  };

  moveToDate = (date: Date): void => {
    this.dateChangedSubject.next(date);
  };

  private onDesktopSizeChanged = (event: MediaQueryListEvent): void => {
    this.isDesktopSized = event.matches;
  };

  deleteProblemConfirmed = (problem: PatientProblem): void => {
    this.deleteProblem(problem);
  };

  onDeleteNewProblemFromInfo = (problem: PatientProblem): void => {
    this.deleteProblem(problem);
  };

  onDeleteNewProblemFromEdit = (problem: PatientProblem): void => {
    this.deleteProblem(problem);
  };
  onDeleteProblemAborted = (): void => {
    this.store.dispatch(problemDeletionAborted());
  };

  private deleteProblem = (problem: PatientProblem): void => {
    this.store.dispatch(deletePatientProblem({ problem }));
    this.showDeleteModal = false;
  };

  onConsultationProblem = (problem: PatientProblem): void => {
    this.store.dispatch(showViewProblemModal({ problemToView: problem }));
  };

  onSortChanged = (sort?: Sort): void => {
    this.store.dispatch(sortProblems({ sort }));
  };

  cancel = (canCancel: boolean): void => {
    if (canCancel) {
      this.isFormTouchedAndDuty = canCancel;
      this.showModal = true;
    } else {
      this.hideModal();
    }
  };

  confirmDelete = () => this.hideModal();

  private hideModal = () => {
    this.store.dispatch(hideProblemModal());
    this.isFormTouchedAndDuty = false;
  };

  onCopyBoard = (problem: Partial<PatientProblem>): void => {
    if (problem.note) {
      const content: BoardContent = {
        // userId: 'user1', // TODO real id
        id: '',
        description: problem.note,
        creationDate: problem.startDate,
        entityFrom: 'problems',
      };
      this.store.dispatch(saveBoardContentToBoard({ content }));
    }
  };

  onSelectedEncounter = (tab: TabItem) => {
    this.store.dispatch(
      showEncounterForSelectedPatient({
        encounterId: tab.id as string,
      })
    );
  };

  onClosedEncounter = (tab: TabItem) => {
    this.store.dispatch(
      hideEncounterForSelectedPatient({
        encounterId: tab.id as string,
      })
    );
  };

  // UTIL

  now = () => new Date();

  closeLog = () => {
    this.store.dispatch(hideLog());
  };

  onLogProblem = (problem: PatientProblem): void => {
    this.store.dispatch(showLog({ creationDate: problem.startDate }));
    const date = this.datePipe.transform(problem.startDate, 'dd/MM/yyyy');
    const time = this.datePipe.transform(problem.startDate, 'hh:mm');

    this.logTitle = ` ${
      problem.problemDescription
    } ${this.translocoService.translate('log.ofThe')}  ${date} ${time}`;
  };

  showLegendModal = () => {
    this.store.dispatch(showProblemLegendModal());
  };

  closeLegendModal = () => {
    this.store.dispatch(hideProblemLegendModal());
  };
}
