import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import {
  loadAllClinicalNotes,
  loadAllClinicalNotesSuccess,
  loadClinicalDateRange,
  loadFilterNote,
  createClinicalNoteSuccess,
  sortClinicalNotes,
  createClinicalNote,
  createClinicalNoteFailure,
  showCreateClinicalNoteModal,
  showEditClinicalNoteModal,
  showViewClinicalNoteModal,
  hideClinicalNoteModal,
  deleteClinicalNoteSuccess,
  loadAllClinicalNotesFailure,
  updateClinicalNoteSuccess,
  clinicalNotePressedSave,
  updateClinicalNote,
  updateClinicalNoteFailure,
  showLinkedNote,
  hideLinkedNote,
  showHeaderModalInfo,
  hideHeaderModalInfo,
  clinicalNoteShownExtHeaderLine,
} from './clinical-note.actions';
import { ClinicalNoteFilter } from '../interfaces/clinical-note-filter';
import { ClinicalNote } from '../interfaces/clinical-note';
import { DateRangeFilter } from '../interfaces/date-filter';
import { Sort } from '@angular/material/sort';
import { ShowLogClinicalNote } from '../../../shared/components/log-viewer/models/show-log-clinical-note';

export type ClinicalNoteModalState =
  | {
      mode: 'create';
    }
  | {
      mode: 'edit';
      clinicalNote: ClinicalNote;
    }
  | {
      mode: 'view';
      clinicalNote: ClinicalNote;
    }
  | {
      mode: 'consultation';
      clinicalNote: ClinicalNote;
    }
  | {
      mode: 'validation';
      clinicalNote: ClinicalNote;
    }
  | {
      mode: 'validatedAndArchived';
      clinicalNote: ClinicalNote;
    };

export interface ClinicalNoteUiState {
  clinicalNoteFilter: ClinicalNoteFilter;
  isLoadingNotes: boolean;
  dateRangeFilter: DateRangeFilter;
  sort?: Sort;
  clinicalNoteModalState?: ClinicalNoteModalState;
  linkedNotesToShow?: ClinicalNote[];
  isSavePressed: boolean;
  showHederModalInfo: boolean;
  showLogClinicalNoteModal?: ShowLogClinicalNote;
  expendSubHeader: boolean;
}

export const clinicalNoteInitialState: ClinicalNoteUiState = {
  clinicalNoteFilter: {
    showMedical: true,
    showNursing: true, // TODO only the one of the user's type
  },
  isLoadingNotes: false,
  dateRangeFilter: {
    startDate: undefined,
    endDate: undefined,
  },
  showHederModalInfo: false,
  sort: { active: 'date', direction: 'desc' },
  isSavePressed: false,
  showLogClinicalNoteModal: undefined,
  expendSubHeader: false,
};

export const clinicalNoteReducer: ActionReducer<ClinicalNoteUiState, Action> =
  createReducer(
    clinicalNoteInitialState,
    on(
      loadFilterNote,
      (state: ClinicalNoteUiState, { filter }): ClinicalNoteUiState => ({
        ...state,
        clinicalNoteFilter: filter,
      })
    ),
    on(
      loadAllClinicalNotes,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
        isLoadingNotes: true,
      })
    ),

    on(
      loadClinicalDateRange,
      (state: ClinicalNoteUiState, { dateFilter }): ClinicalNoteUiState => ({
        ...state,
        dateRangeFilter: dateFilter,
      })
    ),
    on(
      loadAllClinicalNotesSuccess,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
        isLoadingNotes: false,
      })
    ),
    on(
      loadAllClinicalNotesFailure,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
      })
    ),

    on(
      createClinicalNote,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
        isSavePressed: true,
      })
    ),
    on(
      createClinicalNoteSuccess,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
        clinicalNoteModalState: undefined,
        isSavePressed: false,
      })
    ),
    on(
      createClinicalNoteFailure,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
        isSavePressed: false,
      })
    ),

    on(
      loadAllClinicalNotesSuccess,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
        isLoadingNotes: false,
      })
    ),
    on(
      sortClinicalNotes,
      (state: ClinicalNoteUiState, { sort }): ClinicalNoteUiState => ({
        ...state,
        sort,
      })
    ),
    on(
      deleteClinicalNoteSuccess,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
      })
    ),
    on(
      showCreateClinicalNoteModal,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
        clinicalNoteModalState: {
          mode: 'create',
        },
      })
    ),
    on(
      showEditClinicalNoteModal,
      (
        state: ClinicalNoteUiState,
        { clinicalNoteToEdit }: { clinicalNoteToEdit: ClinicalNote }
      ): ClinicalNoteUiState => ({
        ...state,
        clinicalNoteModalState: {
          mode: 'edit',
          clinicalNote: clinicalNoteToEdit,
        },
      })
    ),
    on(
      showViewClinicalNoteModal,
      (
        state: ClinicalNoteUiState,
        { clinicalNoteToView }: { clinicalNoteToView: ClinicalNote }
      ): ClinicalNoteUiState => ({
        ...state,
        clinicalNoteModalState: {
          mode: 'view',
          clinicalNote: clinicalNoteToView,
        },
      })
    ),

    on(
      hideClinicalNoteModal,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
        clinicalNoteModalState: undefined,
      })
    ),
    on(
      updateClinicalNoteSuccess,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
        clinicalNoteModalState: undefined,
        isSavePressed: false,
      })
    ),

    on(
      updateClinicalNoteFailure,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => {
        let clinicalNote!: ClinicalNote;
        if (state.clinicalNoteModalState?.mode === 'edit') {
          clinicalNote = state.clinicalNoteModalState.clinicalNote;
        }
        return {
          ...state,
          clinicalNoteModalState: {
            mode: 'edit',
            clinicalNote: clinicalNote,
          },
          isSavePressed: false,
        };
      }
    ),
    on(
      clinicalNotePressedSave,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
        isSavePressed: true,
      })
    ),
    on(
      updateClinicalNote,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
        isSavePressed: true,
      })
    ),
    on(
      showLinkedNote,
      (state: ClinicalNoteUiState, { note }): ClinicalNoteUiState => ({
        ...state,
        linkedNotesToShow: [...(state.linkedNotesToShow ?? []), note],
      })
    ),
    on(
      hideLinkedNote,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
        // Current list without last item
        linkedNotesToShow: state.linkedNotesToShow?.slice(
          0,
          state.linkedNotesToShow.length - 1
        ),
      })
    ),
    on(
      showHeaderModalInfo,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
        showHederModalInfo: true,
      })
    ),

    on(
      hideHeaderModalInfo,
      (state: ClinicalNoteUiState): ClinicalNoteUiState => ({
        ...state,
        showHederModalInfo: false,
      })
    ),

    on(
      clinicalNoteShownExtHeaderLine,
      (state: ClinicalNoteUiState, { show }): ClinicalNoteUiState => ({
        ...state,
        expendSubHeader: show,
      })
    )
  );
