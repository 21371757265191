import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { NateaIconsName } from '../../icons/natea-icon';
import { NateaIconsModule } from '../../icons/natea-icons.module';
import { LoaderComponent, LoaderVariants } from '../loader/loader.component';

export enum ButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

@Component({
  selector: 'natea-button',
  standalone: true,
  imports: [CommonModule, NateaIconsModule, MatRippleModule, LoaderComponent],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  LoaderVariants = LoaderVariants;

  @Input() disabled = false;
  @Input() tabindex?: number;
  @Input() iconTitle?: string = '';
  @Input() rightIcon?: NateaIconsName;
  @Input() centerIcon?: NateaIconsName;
  @Input() isError = false;

  /***
   * If true, show a circular progress instead of the label
   *  */
  @Input() showLoader = false;

  @Input() leftIcon?: NateaIconsName;
  @Input() buttonType: 'button' | 'submit' | 'reset' = 'button';
  @Input() label = '';
  @Input() color: ButtonVariants = ButtonVariants.PRIMARY;
  @Input() size: 'small' | 'medium' | 'large' = 'large';

  @Output() buttonClick: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();

  onClick = (event: MouseEvent) => {
    if (!this.showLoader) {
      this.buttonClick.emit(event);
    }
  };
}
