<ng-container>
  <div class="container">
    <table mat-table [dataSource]="dataSource">
      <caption></caption>

      <ng-container [matColumnDef]="displayedColumns[0]">
        <th mat-header-cell *matHeaderCellDef>
          {{ displayedColumns[0] }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div
            class="text-and-icon first-column"
            *ngIf="element.iconName && element.text"
          >
            <natea-icons
              *ngIf="element.iconName"
              [name]="element.iconName"
              [fill]="element.iconColor || defaultColor"
              class="icon"
            ></natea-icons>
            <span>{{ element.text }}</span>
          </div>

          <div
            class="text-without-icon first-column"
            *ngIf="!element.iconName && element.text"
          >
            <span>{{ element.text }}</span>
          </div>

          <div
            class="only-icon first-column"
            *ngIf="element.iconName && !element.text"
          >
            <natea-icons
              [name]="element.iconName"
              [fill]="element.iconColor || defaultColor"
              class="icon"
            ></natea-icons>
          </div>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="displayedColumns[1]">
        <th mat-header-cell *matHeaderCellDef>
          {{ displayedColumns[1] }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="second-colunm">
            <!--<natea-icons
              *ngIf="element.iconName"
              [name]="element.iconName"
              [fill]="element.iconColor || defaultColor"
              class="icon"
            ></natea-icons>-->
            <span>{{ element.secondColumnText }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container *ngIf="showHeader">
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</ng-container>
