import { createAction, props } from '@ngrx/store';
import { deleteBoardNoteType } from '../../../shared/components/blackboard-notes-table/blackboard-notes-table.component';
import { ApiError } from '../../../shared/entities/errors';
import { BoardContent } from '../interfaces/board-content';
import { BoardItemDelete } from '../interfaces/board-item-delete';
import { BoardUserData } from '../interfaces/board-user-type';

export enum BoardActions {
  LoadBoardContents = '[Board] Load Board Contents',
  LoadBoardContentsSuccess = '[Board] Load Board Contents Success',
  LoadBoardContentsFailure = '[Board] Load Board Contents Failure',
  SaveBoardContentToBoard = '[Board] Save Board Content To Board',
  SaveBoardContentToBoardSuccess = '[Board] Save Board Content To Board Success',
  SaveBoardContentToBoardFailure = '[Board] Save Board Content To Board Failure',
  DeleteSingleBoardNote = '[Board] delete single note board item',
  DeleteSingleNoteBoardSuccess = '[Board] delete single note board item success',
  DeleteBoardItems = '[Board] delete all board items',
  DeleteAllBoardNotesSuccess = '[Board] delete all board items success',
  DeletionBoardNoteFailure = '[Board] deletion  board note failure',
  DeleteSingleBoardNoteFailure = '[Board] delete single board note item failure',
  IsAtLeastOneNoteFromClipboardOpened = '[Board] is at least one note from clipboard opened',
  ShowDeletionBoardModal = '[Board] show deletion board note modal',
  HideDeletionBoardModal = '[Board] hide deletion board note modal',
}

export const loadBoardContents = createAction(
  BoardActions.LoadBoardContents,
  props<{ data: BoardUserData }>()
);

export const loadBoardContentsSuccess = createAction(
  BoardActions.LoadBoardContentsSuccess,
  props<{ boardContents: BoardContent[] }>()
);

export const loadBoardContentsFailure = createAction(
  BoardActions.LoadBoardContentsFailure,
  props<{ error: ApiError }>()
);

export const saveBoardContentToBoard = createAction(
  BoardActions.SaveBoardContentToBoard,
  props<{ content: BoardContent }>()
);

export const saveBoardContentToBoardSuccess = createAction(
  BoardActions.SaveBoardContentToBoardSuccess,
  props<{ content: BoardContent }>()
);

export const saveBoardContentToBoardFailure = createAction(
  BoardActions.SaveBoardContentToBoardFailure,
  props<{ error: ApiError }>()
);

export const deleteSingleBoardNote = createAction(
  BoardActions.DeleteSingleBoardNote,
  props<{ itemDeleted: BoardItemDelete }>()
);

export const deleteSingleBoardSuccess = createAction(
  BoardActions.DeleteSingleNoteBoardSuccess,
  props<{ id: string }>()
);

export const deleteBoardItems = createAction(
  BoardActions.DeleteBoardItems,
  props<{ data: BoardUserData }>()
);

export const deleteAllBoarNoteSuccess = createAction(
  BoardActions.DeleteAllBoardNotesSuccess
);

export const deleteBoardItemsFailure = createAction(
  BoardActions.DeletionBoardNoteFailure
);

export const isAtLeastOneNoteFromClipboardOpened = createAction(
  BoardActions.IsAtLeastOneNoteFromClipboardOpened,
  props<{ isAtLeastOneNoteFromClipboardOpened: boolean }>()
);

export const showDeletionBoardNoteModal = createAction(
  BoardActions.ShowDeletionBoardModal,
  props<{ deleteType: deleteBoardNoteType }>()
);

export const hideDeletionBoardNoteModal = createAction(
  BoardActions.HideDeletionBoardModal,
  props<{ isShowDeletionBoardModal: boolean }>()
);
