import { Component, Input } from '@angular/core';
import {
  DisplayedColumns,
  TextsAndOptionsIcons,
} from '../../models/legend-content';

@Component({
  selector: 'natea-cc-table-legend',
  templateUrl: './table-legend.component.html',
  styleUrls: ['./table-legend.component.scss'],
})
export class TableLegendComponent {
  @Input() showHeader = true;
  @Input() dataSource: TextsAndOptionsIcons[] = [];

  @Input() displayedColumns: DisplayedColumns = ['value1', 'value2'];

  defaultColor = '#038095';
}
