import { generateRandomInt } from '../../../../core/mocks/get-problems-mocked-response';
import {
  SearchPatientEncounterInterface,
  SearchPatientResultInterface,
} from '../../../../shared/components/modals/search-patients-modal/interface/search-result';
import { Patient, WarningStatus } from '../../../../shared/entities/patient';
import { mockEncounterDay } from '../../../../shared/utils/mock-utils'; // RLE
import { randomDate } from '../../../clinical-note/webapis/clinical-notes.adapters';

const TOTAL_PATIENTS = 10;
const TOTAL_MATCHING_PATIENTS = 5;

// RLE
const firstFemaleNames = [
  'Maria',
  'Giuseppina',
  'Luigia',
  'Giovanna',
  'Antonietta',
  'Angela',
  'Francesca',
  'Vincenzina',
  'Piera',
  'Deborah',
  'Domenica',
  'Pasqualina',
  'Gennarina',
  'Carmina',
];
// RLE

// RLE
const currentBeds = [
  'A1-01',
  'A1-02',
  'A1-03',
  'A2-04',
  'A2-05',
  'A2-06',
  'A3-07',
  'A3-08',
  'A3-09',
  'A4-10',
  'A4-11',
  'A4-12',
  'A5-13',
  'A5-14',
  'A5-15',
  'A6-16',
  'A6-17',
  'A6-18',
  'A7-19',
  'A7-20',
  'A7-21',
  'A8-22',
  'A8-23',
  'A8-24',
  'A9-25',
  'A9-26',
  'A9-27',
  'B1-28',
  'B1-29',
  'B1-30',
  'B2-31',
  'B2-32',
];
// RLE

const firstNames = [
  'Mario',
  'Giuseppe',
  'Luigi',
  'Giovanni',
  'Antonio',
  'Angelo',
  'Francesco',
  'Vincenzo',
  'Pietro',
  'Salvatore',
  'Domenico',
  'Pasquale',
  'Gennaro',
  'Carmine',
];

const lastNames = [
  'Rossi',
  'Bianchi',
  'Ferrari',
  'Esposito',
  'Romano',
  'Colombo',
  'Ricci',
  'Marino',
  'Greco',
  'Bruno',
  'Gallo',
  'Conti',
  'De Luca',
  'Mancini',
  'Costa',
];

const firstOtherNames = [
  'Mario',
  'Bianchi',
  'Esposito',
  'Russo',
  'De Luca',
  'Ferrari',
  'Romano',
  'Conti',
  'Moretti',
  'Ricci',
  'Barbieri',
  'Rizzo',
  'Giuliani',
  'Marini',
  'De Santis',
  'Ferri',
  'Greco',
  'Caruso',
  'Fiore',
  'Gatti',
  'Ferrara',
  'Lombardi',
  'Pellegrini',
  'De Angelis',
  'Vitale',
  'Marchetti',
  'Sanna',
  'Bellini',
  'Rossetti',
  'Rosso',
  'Battaglia',
  'Martini',
];

const lastOtherNames = [
  'Rossi',
  'Giorgia',
  'Matteo',
  'Francesca',
  'Luca',
  'Chiara',
  'Alessandro',
  'Martina',
  'Giovanni',
  'Sofia',
  'Andrea',
  'Valentina',
  'Simone',
  'Elena',
  'Francesco',
  'Giulia',
  'Davide',
  'Laura',
  'Paolo',
  'Sara',
  'Nicola',
  'Giulia',
  'Lorenzo',
  'Federica',
  'Fabio',
  'Serena',
  'Alessio',
  'Michela',
  'Mattia',
  'Eleonora',
  'Giovanni',
  'Sofia',
];

const SAMPLE_PATIENTS: Patient[] = [
  {
    id: 'patient3',
    firstName: 'Mario',
    lastName: 'Rossi',
    birthDate: new Date(
      new Date().getFullYear() - 2,
      new Date().getMonth() - 2,
      new Date().getDate() - 5
    ),
    currentBed: 'A5-22',
    measurements: {
      eg: '27+3',
      epcEc: '0 | 0 | 20',
      weight: 2.15,
      lastWeight: 2.38,
      lastWeightDate: new Date(2024, 1, 4),
      weightLoss: parseInt(`${(1 - 2.15 / 2.38) * 100}`),
    },
    parent: {
      firstName: 'Maria',
      lastName: 'Bianchi',
      birthDate: new Date(
        new Date().getFullYear() - 30,
        new Date().getMonth() - 2,
        new Date().getDate() - 5
      ),
      id: 'patient4',
    },
    gestationalAge: 25,
    weight: 2.3,
    departmentId: '1',
    encounterStartDate: new Date(2024, 1, 3),
    note: '',
  },
  {
    id: 'patient2',
    firstName: 'jack',
    lastName: 'Rossi',
    // 1 year, two months, 3 days ago
    birthDate: new Date(
      new Date().getFullYear() - 1,
      new Date().getMonth() - 2,
      new Date().getDate() - 3
    ),
    currentBed: 'A5-23',
    measurements: {
      eg: '27+3',
      epcEc: '5 | 12 | 2020',
      weight: 2.15,
      lastWeight: 2.38,
      lastWeightDate: new Date(2024, 1, 4),
      weightLoss: parseInt(`${(1 - 2.15 / 2.38) * 100}`),
    },
    parent: {
      firstName: 'Maria',
      lastName: 'Bianchi',
      birthDate: new Date(
        new Date().getFullYear() - 30,
        new Date().getMonth() - 2,
        new Date().getDate() - 5
      ),
      id: 'patient3',
    },
    gestationalAge: 24,
    weight: 1.6,
    departmentId: '1',
    encounterStartDate: new Date(2024, 1, 3),
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac ante sed justo consectetur eleifend. Suspendisse et lacinia ante. Aenean eleifend orci sed dolor vulputate consectetur. Nullam id augue magna. Aliquet sed nunc id, tristique ornare ante. Duis nec placerat urna, vel consectetur lectus. Donec et metus erat. Integer sit amet enim viverra, sodales tortor dapibus, volutpat mauris. Morbi pretium dapibus ligula, id suscipit magna.',
  },
];

const SAMPLE_ENCOUNTERS: SearchPatientEncounterInterface[] = [
  {
    id: 'encounter1',
    departmentId: '1',
    departmentName: 'Pediatria',
    startDate: new Date(2020, 10, 20),
    visible: true,
  },
  {
    id: 'encounter2',
    departmentId: '1',
    departmentName: 'Pediatria',
    startDate: new Date(2020, 10, 10),
    endDate: new Date(2020, 10, 15),
    visible: true,
  },
  {
    id: 'encounter3',
    departmentId: '2',
    departmentName: 'Neonatologia',
    startDate: new Date(2020, 10, 1),
    endDate: new Date(2020, 10, 5),
    visible: false,
  },
];

export const mockPatientsResultList = (): SearchPatientResultInterface[] => {
  return [...Array(TOTAL_MATCHING_PATIENTS).keys()].map((i) => ({
    ...SAMPLE_PATIENTS[i % SAMPLE_PATIENTS.length],
    id: `patient${i}`,
    name: `${firstNames[i]} ${lastNames[i]}`,
    weight: 1.84,
    encounters: [...SAMPLE_ENCOUNTERS],
  }));
};

export const mockPatientsList = (departmentId: string): Patient[] => {
  switch (departmentId) {
    case '1':
      //        return mockPatientsListNido(departmentId);
      return mockPatientsVariableList(departmentId, 27); // RLE
    case '2':
      //        return mockPatientsListNewBornPathology(departmentId);
      return mockPatientsVariableList(departmentId, 22); // RLE
    case '3':
      //        return mockPatientsListTIN(departmentId);
      return mockPatientsVariableList(departmentId, 10); // RLE
    case '4':
      //        return mockPatientsListPediatria(departmentId);
      return mockPatientsVariableList(departmentId, 30); // RLE
    case '5':
      //        return mockPatientsListSezTrapianti(departmentId);
      return mockPatientsVariableList(departmentId, 28); // RLE
    default:
      console.error('Department not found');
      return [];
  }

  /*return [...Array(TOTAL_PATIENTS).keys()].map((i) => ({
    ...SAMPLE_PATIENTS[i % SAMPLE_PATIENTS.length],
    id: `patient${i}`,
    firstName: `${firstNames[i]}`,
    lastName: `${lastNames[i]}`,
    birthDate: isMarioIndex(i)
      ? new Date(2024, 1, 1)
      : randomDate(new Date(2010, 0, 1), new Date(2020, 0, 1)),

    measurements: {
      eg: isMarioIndex(i) ? '27+3' : `25 + ${i + 2}`,
      epcEc: isMarioIndex(i) ? '30' : '45',
      weight: isMarioIndex(i)
        ? 1.84
        : ((1.6 + i * 0.1).toFixed(2) as unknown as number),
      lastWeight: isMarioIndex(i)
        ? 2.06
        : ((1.6 + generateRandomInt(1, 3) * 0.1).toFixed(
            2
          ) as unknown as number),
      lastWeightDate: isMarioIndex(i)
        ? new Date(2024, 1, 21)
        : new Date(2020, 11, 3),
    },

    departmentId,
    warnings: {
      therapy: getRandomWarningStatus(),
      procedure: getRandomWarningStatus(),
      nursing: getRandomWarningStatus(),
      nutrition: getRandomWarningStatus(),
      diagnosis: getRandomWarningStatus(),
      screening: getRandomWarningStatus(),
    },
  }));*/
};

// RLE
const mockPatientsVariableList = (
  departmentId: string,
  patients: number
): Patient[] => {
  return [...Array(patients).keys()].map((i) => ({
    ...SAMPLE_PATIENTS[i % SAMPLE_PATIENTS.length],
    parent: {
      firstName: `${firstFemaleNames[i]}`,
      lastName: `${lastNames[i]}`,
      birthDate: randomDate(new Date(1990, 0, 1), new Date(1995, 0, 1)),
      id: `patient${i}`,
    },
    id: `patient${i}`,
    firstName: `${firstOtherNames[i]}`,
    lastName: `${lastOtherNames[i]}`,
    birthDate: isMarioIndex(i)
      ? mockEncounterDay() // RLE
      : randomDate(mockEncounterDay(2), mockEncounterDay(6)), // RLE
    currentBed: `${currentBeds[i]}`, // RLE
    measurements: {
      eg: isMarioIndex(i) ? '27+3' : `25 + ${i + 2}`,
      epcEc: isMarioIndex(i) ? '30' : '45',
      weight: isMarioIndex(i)
        ? 2.15
        : ((1.6 + generateRandomInt(1, 6) * 0.1).toFixed(
            2
          ) as unknown as number), // RLE
      lastWeight: isMarioIndex(i)
        ? 2.38
        : ((1.8 + generateRandomInt(1, 6) * 0.1).toFixed(
            2
          ) as unknown as number), // RLE
      lastWeightDate: isMarioIndex(i)
        ? mockEncounterDay(0) // RLE
        : mockEncounterDay(10), // RLE
      weightLoss: parseInt(`${(1 - 2.15 / 2.38) * 100}`),
    },

    encounterStartDate: isMarioIndex(i)
      ? mockEncounterDay(4) // RLE
      : randomDate(mockEncounterDay(2), mockEncounterDay(6)), // RLE

    departmentId,
    warnings: {
      therapy: getRandomWarningStatus(),
      procedure: getRandomWarningStatus(),
      nursing: getRandomWarningStatus(),
      nutrition: getRandomWarningStatus(),
      diagnosis: getRandomWarningStatus(),
      screening: getRandomWarningStatus(),
    },
  }));
};
// RLE

/**********
const mockPatientsListPediatria = (departmentId: string): Patient[] => {
  return [...Array(30).keys()].map((i) => ({
    ...SAMPLE_PATIENTS[i % SAMPLE_PATIENTS.length],
    id: `patient${i}`,
    firstName: `${firstOtherNames[i]}`,
    lastName: `${lastOtherNames[i]}`,
    birthDate: isMarioIndex(i)
      ? new Date(2024, 1, 1)
      : randomDate(new Date(2010, 0, 1), new Date(2020, 0, 1)),

    measurements: {
      eg: isMarioIndex(i) ? '27+3' : `25 + ${i + 2}`,
      epcEc: isMarioIndex(i) ? '30' : '45',
      weight: isMarioIndex(i)
        ? 1.84
        : ((1.6 + i * 0.1).toFixed(2) as unknown as number),
      lastWeight: isMarioIndex(i)
        ? 2.3
        : ((1.6 + generateRandomInt(1, 3) * 0.1).toFixed(
            2
          ) as unknown as number),
      lastWeightDate: isMarioIndex(i)
        ? new Date(2024, 1, 21)
        : new Date(2024, 1, 23),
    },
    encounterStartDate: isMarioIndex(i)
      ? new Date(2024, 1, 1)
      : randomDate(new Date(2024, 1, 1), new Date(2024, 1, 20)),

    departmentId,
    warnings: {
      therapy: getRandomWarningStatus(),
      procedure: getRandomWarningStatus(),
      nursing: getRandomWarningStatus(),
      nutrition: getRandomWarningStatus(),
      diagnosis: getRandomWarningStatus(),
      screening: getRandomWarningStatus(),
    },
  }));
};

const mockPatientsListNewBornPathology = (departmentId: string): Patient[] => {
  return [...Array(22).keys()].map((i) => ({
    ...SAMPLE_PATIENTS[i % SAMPLE_PATIENTS.length],
    parent: {
      firstName: `${firstNames[i]}`,
      lastName: `${lastNames[i]}`,
      birthDate: randomDate(new Date(1990, 0, 1), new Date(1995, 0, 1)),
      id: `patient${i}`,
    },
    id: `patient${i}`,
    firstName: `${firstOtherNames[i]}`,
    lastName: `${lastOtherNames[i]}`,
    birthDate: isMarioIndex(i)
      ? new Date(2024, 1, 1)
      : randomDate(new Date(2010, 0, 1), new Date(2020, 0, 1)),

    measurements: {
      eg: isMarioIndex(i) ? '27+3' : `25 + ${i + 2}`,
      epcEc: isMarioIndex(i) ? '30' : '45',
      weight: isMarioIndex(i)
        ? 1.84
        : ((1.6 + i * 0.1).toFixed(2) as unknown as number),
      lastWeight: isMarioIndex(i)
        ? 2.3
        : ((1.6 + generateRandomInt(1, 3) * 0.1).toFixed(
            2
          ) as unknown as number),
      lastWeightDate: isMarioIndex(i)
        ? new Date(2024, 1, 21)
        : new Date(2024, 1, 23),
    },
    encounterStartDate: isMarioIndex(i)
      ? new Date(2024, 1, 1)
      : randomDate(new Date(2024, 1, 1), new Date(2024, 1, 20)),

    departmentId,
    warnings: {
      therapy: getRandomWarningStatus(),
      procedure: getRandomWarningStatus(),
      nursing: getRandomWarningStatus(),
      nutrition: getRandomWarningStatus(),
      diagnosis: getRandomWarningStatus(),
      screening: getRandomWarningStatus(),
    },
  }));
};

const mockPatientsListTIN = (departmentId: string): Patient[] => {
  return [...Array(10).keys()].map((i) => ({
    ...SAMPLE_PATIENTS[i % SAMPLE_PATIENTS.length],
    parent: {
      firstName: `${firstOtherNames[i]}`,
      lastName: `${lastOtherNames[i]}`,
      birthDate: randomDate(new Date(1990, 0, 1), new Date(1995, 0, 1)),
      id: `patient${i}`,
    },
    id: `patient${i}`,
    firstName: `${firstNames[i]}`,
    lastName: `${lastNames[i]}`,
    birthDate: isMarioIndex(i)
      ? new Date(2024, 1, 1)
      : randomDate(new Date(2024, 1, 1), new Date(2024, 1, 20)),

    measurements: {
      eg: isMarioIndex(i) ? '27+3' : `25 + ${i + 2}`,
      epcEc: isMarioIndex(i) ? '30' : '45',
      weight: isMarioIndex(i)
        ? 1.84
        : ((1.6 + i * 0.1).toFixed(2) as unknown as number),
      lastWeight: isMarioIndex(i)
        ? 2.3
        : ((1.6 + generateRandomInt(1, 3) * 0.1).toFixed(
            2
          ) as unknown as number),
      lastWeightDate: isMarioIndex(i)
        ? new Date(2024, 1, 21)
        : new Date(2024, 1, 23),
    },
    encounterStartDate: isMarioIndex(i)
      ? new Date(2024, 1, 1)
      : randomDate(new Date(2024, 1, 1), new Date(2024, 1, 20)),

    departmentId,
    warnings: {
      therapy: getRandomWarningStatus(),
      procedure: getRandomWarningStatus(),
      nursing: getRandomWarningStatus(),
      nutrition: getRandomWarningStatus(),
      diagnosis: getRandomWarningStatus(),
      screening: getRandomWarningStatus(),
    },
  }));
};

const mockPatientsListNido = (departmentId: string): Patient[] => {
  return [...Array(27).keys()].map((i) => ({
    ...SAMPLE_PATIENTS[i % SAMPLE_PATIENTS.length],
    parent: {
      firstName: `${firstNames[i]}`,
      lastName: `${lastNames[i]}`,
      birthDate: randomDate(new Date(1990, 0, 1), new Date(1995, 0, 1)),
      id: `patient${i}`,
    },
    id: `patient${i}`,
    firstName: `${firstOtherNames[i]}`,
    lastName: `${lastOtherNames[i]}`,
    birthDate: isMarioIndex(i)
      ? new Date(2024, 1, 1)
      : randomDate(new Date(2024, 1, 1), new Date(2024, 1, 2)),

    measurements: {
      eg: isMarioIndex(i) ? '27+3' : `25 + ${i + 2}`,
      epcEc: isMarioIndex(i) ? '30' : '45',
      weight: isMarioIndex(i)
        ? 1.84
        : ((1.6 + i * 0.1).toFixed(2) as unknown as number),
      lastWeight: isMarioIndex(i)
        ? 2.3
        : ((1.6 + generateRandomInt(1, 3) * 0.1).toFixed(
            2
          ) as unknown as number),
      lastWeightDate: isMarioIndex(i)
        ? new Date(2024, 1, 21)
        : new Date(2024, 1, 23),
    },

    encounterStartDate: isMarioIndex(i)
      ? new Date(2024, 1, 1)
      : randomDate(new Date(2024, 1, 1), new Date(2024, 1, 20)),

    departmentId,
    warnings: {
      therapy: getRandomWarningStatus(),
      procedure: getRandomWarningStatus(),
      nursing: getRandomWarningStatus(),
      nutrition: getRandomWarningStatus(),
      diagnosis: getRandomWarningStatus(),
      screening: getRandomWarningStatus(),
    },
  }));
};

const mockPatientsListSezTrapianti = (departmentId: string): Patient[] => {
  return [...Array(28).keys()].map((i) => ({
    ...SAMPLE_PATIENTS[i % SAMPLE_PATIENTS.length],
    parent: {
      firstName: `${firstNames[i]}`,
      lastName: `${lastNames[i]}`,
      birthDate: randomDate(new Date(1990, 0, 1), new Date(1995, 0, 1)),
      id: `patient${i}`,
    },
    id: `patient${i}`,
    firstName: `${firstOtherNames[i]}`,
    lastName: `${firstOtherNames[i]}`,
    birthDate: isMarioIndex(i)
      ? new Date(2024, 1, 1)
      : randomDate(new Date(2024, 1, 1), new Date(2024, 1, 2)),

    measurements: {
      eg: isMarioIndex(i) ? '27+3' : `25 + ${i + 2}`,
      epcEc: isMarioIndex(i) ? '30' : '45',
      weight: isMarioIndex(i)
        ? 1.84
        : ((1.6 + i * 0.1).toFixed(2) as unknown as number),
      lastWeight: isMarioIndex(i)
        ? 2.3
        : ((1.6 + generateRandomInt(1, 3) * 0.1).toFixed(
            2
          ) as unknown as number),
      lastWeightDate: isMarioIndex(i)
        ? new Date(2024, 1, 21)
        : new Date(2024, 1, 23),
    },
    encounterStartDate: isMarioIndex(i)
      ? new Date(2024, 1, 1)
      : randomDate(new Date(2024, 1, 1), new Date(2024, 1, 20)),

    departmentId,
    warnings: {
      therapy: getRandomWarningStatus(),
      procedure: getRandomWarningStatus(),
      nursing: getRandomWarningStatus(),
      nutrition: getRandomWarningStatus(),
      diagnosis: getRandomWarningStatus(),
      screening: getRandomWarningStatus(),
    },
  }));
};
**********/

const getRandomWarningStatus = (): WarningStatus => {
  const values: WarningStatus[] = Object.values(WarningStatus);
  return values[Math.floor(Math.random() * values.length)];
};

export const isMarioIndex = (i: number): boolean => {
  return firstNames[i] === 'Mario';
};
