import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { LoginData } from './interfaces/login-data';

export interface LoginState {
  data: LoginData;
}

export const loginInitialState: LoginState = {
  data: {
    username: '',
    password: '',
  },
};

export const loginReducer: ActionReducer<LoginState, Action> =
  createReducer(loginInitialState);
