import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import {
  hideTherapiesLegendModal,
  showSecondHeaderLine,
  showTherapiesLegendModal,
} from './therapies.actions';

export interface TherapiesUiState {
  showLegendModal: boolean;
  showSecondHeaderLine: boolean;
}

export const initialTherapiesUiState: TherapiesUiState = {
  showLegendModal: false,
  showSecondHeaderLine: false,
};

export const therapiesUiReducer: ActionReducer<TherapiesUiState, Action> =
  createReducer(
    initialTherapiesUiState,

    on(
      showTherapiesLegendModal,
      (state: TherapiesUiState): TherapiesUiState => ({
        ...state,
        showLegendModal: !state.showSecondHeaderLine,
      })
    ),

    on(
      hideTherapiesLegendModal,
      (state: TherapiesUiState): TherapiesUiState => ({
        ...state,
        showLegendModal: false,
      })
    ),

    on(
      showSecondHeaderLine,
      (state: TherapiesUiState, { show }): TherapiesUiState => ({
        ...state,
        showSecondHeaderLine: show,
      })
    )
  );
