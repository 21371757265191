import { ReducerTypes, on } from '@ngrx/store';
import { validateClinicalNoteSuccess } from '../../../../clinical-note/notes-validation/store/notes-validation.actions';
import {
  createClinicalNoteSuccess,
  deleteClinicalNoteSuccess,
  loadAllClinicalNotesFailure,
  loadAllClinicalNotesSuccess,
  updateClinicalNoteSuccess,
} from '../../../../clinical-note/store/clinical-note.actions';
import { PatientsState, updateEncounterData } from '../../patients.reducers';

export const patientNotesReducerFunctions: ReducerTypes<
  PatientsState,
  [never]
>[] = [
  on(
    loadAllClinicalNotesSuccess,
    (state, { notes, patientId, encounterId }) => {
      return updateEncounterData(state, { patientId, encounterId }, () => ({
        notes: notes.filter((note) => note.encounterId === encounterId),
      }));
    }
  ),

  on(loadAllClinicalNotesFailure, (state, { patientId, encounterId }) => {
    return updateEncounterData(state, { patientId, encounterId }, () => ({
      notes: undefined,
    }));
  }),

  on(createClinicalNoteSuccess, (state, { note, patientId, encounterId }) => {
    return updateEncounterData(
      state,
      { patientId, encounterId },
      (encounterData) => ({
        notes: [...(encounterData?.notes ?? []), note],
      })
    );
  }),

  on(updateClinicalNoteSuccess, (state, { note, patientId, encounterId }) =>
    updateEncounterData(state, { patientId, encounterId }, (encounterData) => ({
      notes: encounterData?.notes?.map((n) => (n.id === note.id ? note : n)),
    }))
  ),

  on(
    validateClinicalNoteSuccess,
    (state, { clinicalNoteToValidate: note, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          notes: encounterData?.notes?.map((n) =>
            n.id === note.id ? note : n
          ),
        })
      );
    }
  ),

  on(
    deleteClinicalNoteSuccess,
    (state, { clinicalNoteId, patientId, encounterId }) =>
      updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          ...state,
          notes: encounterData?.notes?.filter((n) => n.id !== clinicalNoteId),
        })
      )
  ),
];
