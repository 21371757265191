import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import {
  loadGanttData,
  loadGanttDataFailure,
  loadGanttDataSuccess,
} from './nursing-gantt.actions';

export interface NursingGanttUiState {
  isLoadingData: boolean;
}

export const initialNursingGanttUiState: NursingGanttUiState = {
  isLoadingData: false,
};
export const nursingGanttUiReducer: ActionReducer<NursingGanttUiState, Action> =
  createReducer(
    initialNursingGanttUiState,
    on(
      loadGanttData,
      (state: NursingGanttUiState): NursingGanttUiState => ({
        ...state,
        isLoadingData: true,
      })
    ),
    on(
      loadGanttDataSuccess,
      (state: NursingGanttUiState): NursingGanttUiState => ({
        ...state,
        isLoadingData: false,
      })
    ),
    on(
      loadGanttDataFailure,
      (state: NursingGanttUiState): NursingGanttUiState => ({
        ...state,
        isLoadingData: false,
      })
    )
  );
