import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../config/config.service';
import {
  BASE_PATH,
  Configuration,
  EncounterApiService,
} from '../generated/api';
import getEncounterMockedResponse from './get-encounter-mocked-response';

@Injectable({
  providedIn: 'root',
})
export class MockedEncounterApiService extends EncounterApiService {
  constructor(
    protected override httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration,
    private config: ConfigService
  ) {
    super(httpClient, basePath, configuration);
  }

  public override getPatientEncounters(
    patientId: string,
    observe?: any,
    reportProgress?: boolean,
    options?:
      | {
          httpHeaderAccept?: 'application/json' | undefined;
          context?: HttpContext | undefined;
        }
      | undefined
  ): Observable<any> {
    return of(
      getEncounterMockedResponse(patientId,  this.config.mockEncounterCount)
    );
  }
}

// public override getEncounters(
//   patientId: string,
//   encounterId?: string,
//   itemPage?: number,
//   page?: number,
//   observe: any = 'body',
//   reportProgress = false,
//   options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
// ): Observable<any> {
//   return ;
// }
