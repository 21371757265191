import { Pipe, PipeTransform } from '@angular/core';
import { Person } from '../entities/person';
import { shownName } from '../entities/user';

@Pipe({
  name: 'name',
})
export class NamePipe implements PipeTransform {
  transform(person: Person): unknown {
    return shownName(person);
  }
}
