import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FieldErrorComponent } from './components/field/field-error/field-error.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NateaIconsModule } from './icons/natea-icons.module';
import { NateaComponentsComponent } from './natea-components.component';

@NgModule({
  declarations: [NateaComponentsComponent],
  providers: [],
  imports: [
    NateaIconsModule,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
    FormsModule,
    NgxMatTimepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    LoaderComponent,
    ReactiveFormsModule,
    FieldErrorComponent,
    CommonModule,
  ],
  exports: [NateaComponentsComponent],
})
export class NateaComponentsModule {}
