import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, delay, iif, of } from 'rxjs';
import { ProfessionalCategory } from '../../../../shared/entities/professional-category';
import { randomId } from '../../../../shared/utils/utils';
import {
  TherapyDurationType,
  TherapyOccurrenceState,
} from '../../common/entities/therapy-occurrence';
import {
  EnteralTherapy,
  NutritionalTherapy,
  ParenteralTherapy,
} from '../entities/nutritional-therapy';
import { NutritionalTherapyCatalogs } from '../entities/nutritional-therapy-catalogs';
import { NutritionalParenteralContribution } from '../entities/nutritional-therapy-type';
import {
  NutritionParenteralContributionFormData,
  NutritionalEnteralFormData,
  NutritionalParenteralFormData,
} from '../form/nutritional-therapy-form-data';
import { nutritionalTherapiesCatalogsMocks } from '../mocks/nutritional-therapy-catalogs.mocks';
import {
  baseEnteralTherapyMock,
  baseParenteralTherapyMock,
  nutritionalTherapiesListMock,
} from '../mocks/nutritional-therapy.mocks';

@Injectable({
  providedIn: 'root',
})
export class NutritionalTherapiesWebAPI {
  constructor(private trasloco: TranslocoService) {}
  public deleteNutritionalTherapyPrescription(
    id: string,
    patientId: string
  ): Observable<undefined> {
    return of(undefined).pipe(delay(100));
  }

  public loadNutritionalTherapies(
    patientId: string,
    encounterId: string
  ): Observable<NutritionalTherapy[]> {
    const nutritionalTherapy: NutritionalTherapy[] =
      nutritionalTherapiesListMock().map((therapy: NutritionalTherapy) => ({
        ...therapy,
        // type: {
        //   ...therapy.type,
        //   name: this.trasloco.translate(
        //     `therapies.nutritional.table.${therapy.type.name}`
        //   ),
        // },
        // category: {
        //   ...therapy.category,
        //   name: this.trasloco.translate(
        //     `therapies.nutritional.milkCategory.${therapy.category.name}`
        //   ),
        // },
      }));

    return of(nutritionalTherapy).pipe(delay(100));
  }

  public downloadCatalogs(): Observable<NutritionalTherapyCatalogs> {
    return of(nutritionalTherapiesCatalogsMocks);
  }

  public loadMothersMilkAvailable(patientId: string): Observable<number> {
    return of(200).pipe(delay(1000));
  }

  public editEnteralTherapy({
    therapy,
    encounterId,
    therapyId,
    patientId,
    occurrenceId,
  }: {
    therapy: NutritionalEnteralFormData;
    encounterId: string;
    therapyId: string;
    patientId: string;
    occurrenceId: string;
  }): Observable<EnteralTherapy> {
    return of(
      mockNutritionalTherapy(
        therapy,
        encounterId,
        therapyId,
        patientId,
        occurrenceId
      )
    ).pipe(delay(1000));
  }

  editParenteralTherapy(arg: {
    therapy: NutritionalParenteralFormData;
    encounterId: string;
    patientId: string;
    therapyId: string;
    occurrence: string;
  }): Observable<ParenteralTherapy> {
    const parenteralTherapy: ParenteralTherapy = {
      ...arg.therapy,
      contributions: arg.therapy.contributions.map(
        (
          contribution: NutritionParenteralContributionFormData
        ): NutritionalParenteralContribution => ({
          ...contribution,
          id: contribution.id ?? '',
          name: contribution.name ?? '',
          parenteral: contribution.parenteral ?? 0,
          enteral: contribution.enteral ?? 0,
          medicine: contribution.medicine ?? 0,
          total: contribution.total ?? 0,
        })
      ),
      id: arg.therapyId,
      category: 'parenteral',
      categoryLabel: 'Parenteral',
      patientId: arg.patientId,
      encounterId: arg.encounterId,
      catalogId: 'catalogId',
      osmolarity: arg.therapy.osmolarity ?? 0,
      totalVolume: arg.therapy.totalVolume ?? 0,
      forDays: arg.therapy.forDays ?? 0,
      weight: arg.therapy.weight ?? 0,
      name: 'name',
      insertionDate: new Date(),
      prescriptionDate: new Date(),
      createdBy: {
        id: randomId(),
        lastName: 'Maurizio',
        firstName: 'Verdi',
      },

      problem: {
        id: arg.therapy.referencedProblem?.id ?? '',
        name: arg.therapy.referencedProblem?.data?.id ?? '',
        valid: arg.therapy.referencedProblem?.data?.valid ?? false,
        problemClass:
          arg.therapy.referencedProblem?.data?.problemClass ??
          ProfessionalCategory.Medical,
      },
      programmingDate: new Date(),
      duration: arg.therapy.duration ?? 0,
      repetition: arg.therapy.repetition?.data ?? {
        id: '',
        label: '',
        intervalSpanMinutes: 0,
      },

      occurrences: [
        {
          durationType: TherapyDurationType.Prolonged,
          duration: 0,
          state: TherapyOccurrenceState.Prepared,
          id: arg.occurrence,
          prescriptionId: arg.therapyId,
          isValid: true,
          weightKg: '10',
          programmingDate: new Date(),
        },
      ],
      creationDate: new Date(),
    };

    /*return throwError(() => new Error(`This is error number`)).pipe(
      delay(1000)
    );*/

    return of(parenteralTherapy).pipe(delay(1000));
  }

  public abortNutritionalTherapy(
    enconterId: string,
    patientId: string,
    therapyId: string,
    therapy: NutritionalTherapy
  ): Observable<NutritionalTherapy> {
    return iif(
      () => therapy.category === 'enteral',
      of({
        ...baseEnteralTherapyMock,
        id: therapy.id,
        canBeAborted: false,
        canBeEdited: false,
        occurrences: [
          ...therapy?.occurrences.map((occ) => ({
            ...occ,
            state: TherapyOccurrenceState.Aborted,
            administration: undefined,
            additionalInfo: undefined,
          })),
        ],
      }),
      of({
        ...baseParenteralTherapyMock,
        id: therapy.id,
        canBeAborted: false,
        canBeEdited: false,
        occurrences: [
          ...(therapy?.occurrences ?? []).map((occ) => ({
            ...occ,
            state: TherapyOccurrenceState.Aborted,
            administration: undefined,
            additionalInfo: undefined,
          })),
        ],
      })
    ).pipe(delay(1000));
  }
}

export const mockNutritionalTherapy = (
  therapy: NutritionalEnteralFormData,
  encounterId: string,
  therapyId: string,
  patientId: string,
  occurrenceId: string
) => {
  const generatedId = randomId();
  const therapyEnteral: EnteralTherapy = {
    ...therapy,
    id: therapyId ?? generatedId,
    canBeEdited: true,
    problem: {
      id: therapy.referencedProblem?.id ?? '',
      name: therapy.referencedProblem?.data?.id ?? '',
      valid: therapy.referencedProblem?.data?.valid ?? false,
      problemClass:
        therapy.referencedProblem?.data?.problemClass ??
        ProfessionalCategory.Medical,
    },
    creationDate: new Date(),
    occurrences: [
      {
        durationType: TherapyDurationType.Prolonged,
        duration: 0,
        state: TherapyOccurrenceState.Prepared,
        id: occurrenceId ?? randomId(),
        prescriptionId: therapyId ?? generatedId,
        isValid: true,
        weightKg: '10',
        programmingDate: new Date(),
      },
    ],

    programmingDate: new Date(),

    entries: therapy.entries.map((entry) => ({
      milkType: {
        id: entry?.milkType?.id ?? '',
        name: entry.milkType?.label ?? '',
        requiresFormulaMotivation:
          !!entry.milkType?.data?.requiresFormulaMotivation,
      },
      milkBrand: {
        id: entry.milkBrand?.id ?? '',
        name: entry.milkBrand?.label ?? '',
      },
      milkFormulaMotivation: {
        id: entry.milkFormulaMotivation?.id ?? '',
        name: entry.milkFormulaMotivation?.label ?? '',
      },

      feedingMethod: {
        id: entry.feedingMethod?.id ?? '',
        name: entry.feedingMethod?.label ?? '',
        hasLength: !!entry.feedingMethod?.data?.hasLength,
      },

      durationMinutes: entry.durationMinutes?.minutes ?? 0,
      speed: entry.speed ?? 0,
      mealsCount: entry.mealsCount ?? 0,
      doseForMeal: entry.doseForMeal ?? 0,
      supplements: entry.supplements.map((supplement) => ({
        supplementType: {
          id: supplement.supplementType?.id ?? '',
          name: supplement.supplementType?.label ?? '',
        },
        doseGrams: supplement.doseGrams ?? 0,
        doseGramsForHundredMilliliters:
          supplement.doseGramsForHundredMilliliters ?? 0,
      })),
    })),
    notes: therapy.notes,
    totalMeals: therapy.totalMeals,
    totalMilkToAdminister: therapy.totalMilkToAdminister,
    availableBreastMilk: therapy.availableBreastMilk,
    feedingType: {
      id: therapy.feedingType?.id ?? '',
      name: therapy.feedingType?.label ?? '',
    },
    category: 'enteral',
    categoryLabel: 'Enteral',
    patientId: patientId,
    encounterId: encounterId,
    catalogId: 'catalogId',
    name: 'name',
    insertionDate: new Date(),
    prescriptionDate: new Date(),
    createdBy: {
      id: randomId(),
      lastName: 'Maurizio',
      firstName: 'Verdi',
    },
  };

  return therapyEnteral;
};

/*const nutritionalParenteralTherapieMock = (
  enconterId: string,
  patientId: string,
  therapyId: string,
  therapy: NutritionalTherapy
) => {
};*/
