import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { ApiError } from '../../../shared/entities/errors';
import {
  ProcedureMovementFormData,
  ProceduresExecutionFormData,
  ProceduresProgrammingFormData,
  ProceduresTerminationFormData,
} from '../components/procedures-detail-form/form-data/procedures-detail-form-data';
import {
  ProcedureDevice,
  ProcedureLocation,
  ProcedureRoute,
} from '../entities/procedure-data';
import { ProcedureOccurrenceMovement } from '../entities/procedure-movement';
import { ProcedureOccurrence } from '../entities/procedure-occurrence';

export enum ProceduresActions {
  LoadProcedures = '[Procedures] Load Procedures',
  LoadProceduresSuccess = '[Procedures] Load Procedures Success',
  LoadProceduresFailure = '[Procedures] Load Procedures Failure',

  SortProcedures = '[Procedures] Sort',

  SelectOccurrence = '[Procedures] Select Occurrence',
  DeselectOccurrence = '[Procedures] Deselect Occurrence',

  CreateMovement = '[Procedures] Create Movement',
  CreateMovementSuccess = '[Procedures] Create Movement Success',
  CreateMovementFailure = '[Procedures] Create Movement Failure',

  UpdateMovement = '[Procedures] Update Movement',
  UpdateMovementSuccess = '[Procedures] Update Movement Success',
  UpdateMovementFailure = '[Procedures] Update Movement Failure',

  SelectMovementForDeletion = '[Procedures] Select Movement For Deletion',
  AbortMovementDeletion = '[Procedures] Abort Movement Deletion',
  ConfirmMovementDeletion = '[Procedures] Confirm Movement Deletion',
  DeleteMovement = '[Procedures] Delete Movement',
  DeleteMovementSuccess = '[Procedures] Delete Movement Success',
  DeleteMovementFailure = '[Procedures] Delete Movement Failure',

  ShowCreateNewModal = '[Procedures] Show Create New Modal',
  HideCreateNewModal = '[Procedures] Hide Create New Modal',

  CreateProcedure = '[Procedures] Create Procedure',
  CreateProcedureSuccess = '[Procedures] Create Procedure Success',
  CreateProcedureFailure = '[Procedures] Create Procedure Failure',

  SaveProcedureProgrammingStep = '[Procedures] Save Procedure Programming Step',
  SaveProcedureProgrammingStepSuccess = '[Procedures] Save Procedure Programming Step Success',
  SaveProcedureProgrammingStepFailure = '[Procedures] Save Procedure Programming Step Failure',

  SaveProcedureExecutionStep = '[Procedures] Save Procedure Execution Step',
  SaveProcedureExecutionStepSuccess = '[Procedures] Save Procedure Execution Step Success',
  SaveProcedureExecutionStepFailure = '[Procedures] Save Procedure Execution Step Failure',

  SaveProcedureTerminationStep = '[Procedures] Save Procedure Termination Step',
  SaveProcedureTerminationStepSuccess = '[Procedures] Save Procedure Termination Step Success',
  SaveProcedureTerminationStepFailure = '[Procedures] Save Procedure Termination Step Failure',

  DeleteProcedure = '[Procedures] Delete Procedure',
  DeleteProcedureSuccess = '[Procedures] Delete Procedure Success',
  DeleteProcedureFailure = '[Procedures] Delete Procedure Failure',

  StopProcedureRun = '[Procedures] Stop Procedure Run',
  StopProcedureRunSuccess = '[Procedures] Stop Procedure Run Success',
  StopProcedureRunFailure = '[Procedures] Stop Procedure Run Failure',

  CancelProcedureExecution = '[Procedures] Cancel Procedure Execution',
  CancelProcedureExecutionSuccess = '[Procedures] Cancel Procedure Execution Success',
  CancelProcedureExecutionFailure = '[Procedures] Cancel Procedure Execution Failure',

  ShowProcedureLegendState = '[Procedures] Show Procedure Legend State',
  HideProcedureLegendState = '[Procedures] Hide Procedure Legend State',
  SelectedProcedureOccurrenceFromTimeline = '[Procedures] Selected Procedure Occurrence From Timeline',
  ChangeProcedureTab = '[Procedures] Change Procedure Tab',

  LoadDevices = '[Procedures] Load Devices',
  LoadDevicesSuccess = '[Procedures] Load Devices Success',
  LoadDevicesFailure = '[Procedures] Load Devices Failure',

  LoadRoutes = '[Procedures] Load Routes',
  LoadRoutesSuccess = '[Procedures] Load Routes Success',
  LoadRoutesFailure = '[Procedures] Load Routes Failure',

  LoadLocations = '[Procedures] Load Locations',
  LoadLocationsSuccess = '[Procedures] Load Locations Success',
  LoadLocationsFailure = '[Procedures] Load Locations Failure',

  ClearTemporaryFormData = '[Procedures] Clear Temporary Form Data',
  ClearProgrammingTemporaryFormData = '[Procedures] Clear Programming Temporary Form Data',
  ClearExecutionTemporaryFormData = '[Procedures] Clear Execution Temporary Form Data',
  ClearTerminationTemporaryFormData = '[Procedures] Clear Termination Temporary Form Data',
}

export const loadProcedures = createAction(ProceduresActions.LoadProcedures);

export const loadProceduresSuccess = createAction(
  ProceduresActions.LoadProceduresSuccess,
  props<{
    data: ProcedureOccurrence[];
    patientId: string;
    encounterId: string;
  }>()
); // Todo the type should be changed

export const loadProceduresFailure = createAction(
  ProceduresActions.LoadProceduresFailure,
  props<{ error: ApiError }>()
);

export const sortProcedures = createAction(
  ProceduresActions.SortProcedures,
  props<{ sort?: Sort }>()
);

export const selectOccurrence = createAction(
  ProceduresActions.SelectOccurrence,
  props<{ occurrenceId: string }>()
);

export const deselectOccurrence = createAction(
  ProceduresActions.DeselectOccurrence
);

// Movement creation

export const createMovement = createAction(
  ProceduresActions.CreateMovement,
  props<{ occurrenceId: string; movementData: ProcedureMovementFormData }>()
);

export const createMovementSuccess = createAction(
  ProceduresActions.CreateMovementSuccess,
  props<{
    occurrenceId: string;
    patientId: string;
    encounterId: string;
    movement: ProcedureOccurrenceMovement;
  }>()
);

export const createMovementFailure = createAction(
  ProceduresActions.CreateMovementFailure,
  props<{
    occurrenceId: string;
    error: ApiError;
  }>()
);

// Movement update

export const updateMovement = createAction(
  ProceduresActions.UpdateMovement,
  props<{
    movementId: string;
    occurrenceId: string;
    movementData: ProcedureMovementFormData;
  }>()
);

export const updateMovementSuccess = createAction(
  ProceduresActions.UpdateMovementSuccess,
  props<{
    movementId: string;
    occurrenceId: string;
    patientId: string;
    encounterId: string;
    movement: ProcedureOccurrenceMovement;
  }>()
);

export const updateMovementFailure = createAction(
  ProceduresActions.UpdateMovementFailure,
  props<{ movementId: string; error: ApiError }>()
);

// Movement deletion

export const selectMovementForDeletion = createAction(
  ProceduresActions.SelectMovementForDeletion,
  props<{ movementId: string; occurrenceId: string }>()
);

export const abortMovementDeletion = createAction(
  ProceduresActions.AbortMovementDeletion
);

export const confirmMovementDeletion = createAction(
  ProceduresActions.ConfirmMovementDeletion
);

export const deleteMovement = createAction(
  ProceduresActions.DeleteMovement,
  props<{ movementId: string; occurrenceId: string }>()
);

export const deleteMovementSuccess = createAction(
  ProceduresActions.DeleteMovementSuccess,
  props<{
    movementId: string;
    occurrenceId: string;
    patientId: string;
    encounterId: string;
  }>()
);

export const deleteMovementFailure = createAction(
  ProceduresActions.DeleteMovementFailure,
  props<{ movementId: string; error: ApiError }>()
);

// Create new

export const showCreateNewProcedureModal = createAction(
  ProceduresActions.ShowCreateNewModal
);

export const hideCreateNewProcedureModal = createAction(
  ProceduresActions.HideCreateNewModal
);

export const createProcedure = createAction(
  ProceduresActions.CreateProcedure,
  props<{ data: ProceduresProgrammingFormData }>()
);

export const createProcedureSuccess = createAction(
  ProceduresActions.CreateProcedureSuccess,
  props<{
    patientId: string;
    encounterId: string;
    procedure: ProcedureOccurrence;
  }>()
);

export const createProcedureFailure = createAction(
  ProceduresActions.CreateProcedureFailure,
  props<{ error: ApiError }>()
);

export const saveProcedureProgrammingStep = createAction(
  ProceduresActions.SaveProcedureProgrammingStep,
  props<{ procedureId: string; data: ProceduresProgrammingFormData }>()
);

export const saveProcedureProgrammingStepSuccess = createAction(
  ProceduresActions.SaveProcedureProgrammingStepSuccess,
  props<{
    patientId: string;
    encounterId: string;
    procedureId: string;
    data: ProceduresProgrammingFormData;
  }>()
);

export const saveProcedureProgrammingStepFailure = createAction(
  ProceduresActions.SaveProcedureProgrammingStepFailure,
  props<{ error: ApiError }>()
);

export const saveProcedureExecutionStep = createAction(
  ProceduresActions.SaveProcedureExecutionStep,
  props<{ procedureId: string; data: ProceduresExecutionFormData }>()
);

export const saveProcedureExecutionStepSuccess = createAction(
  ProceduresActions.SaveProcedureExecutionStepSuccess,
  props<{
    patientId: string;
    encounterId: string;
    procedureId: string;
    data: ProceduresExecutionFormData;
  }>()
);

export const saveProcedureExecutionStepFailure = createAction(
  ProceduresActions.SaveProcedureExecutionStepFailure,
  props<{ error: ApiError }>()
);

export const saveProcedureTerminationStep = createAction(
  ProceduresActions.SaveProcedureTerminationStep,
  props<{ procedureId: string; data: ProceduresTerminationFormData }>()
);

export const saveProcedureTerminationStepSuccess = createAction(
  ProceduresActions.SaveProcedureTerminationStepSuccess,
  props<{
    patientId: string;
    encounterId: string;
    procedureId: string;
    data: ProceduresTerminationFormData;
  }>()
);

export const saveProcedureTerminationStepFailure = createAction(
  ProceduresActions.SaveProcedureTerminationStepFailure,
  props<{ error: ApiError }>()
);

export const deleteProcedure = createAction(
  ProceduresActions.DeleteProcedure,
  props<{ procedureId: string }>()
);

export const deleteProcedureSuccess = createAction(
  ProceduresActions.DeleteProcedureSuccess,
  props<{
    patientId: string;
    encounterId: string;
    procedureId: string;
  }>()
);

export const deleteProcedureFailure = createAction(
  ProceduresActions.DeleteProcedureFailure,
  props<{ error: ApiError }>()
);

export const stopProcedureRun = createAction(
  ProceduresActions.StopProcedureRun,
  props<{ procedureId: string }>()
);

export const stopProcedureRunSuccess = createAction(
  ProceduresActions.StopProcedureRunSuccess,
  props<{
    patientId: string;
    encounterId: string;
    procedureId: string;
  }>()
);

export const stopProcedureRunFailure = createAction(
  ProceduresActions.StopProcedureRunFailure,
  props<{ error: ApiError }>()
);

export const cancelProcedureExecution = createAction(
  ProceduresActions.CancelProcedureExecution,
  props<{ procedureId: string }>()
);

export const cancelProcedureExecutionSuccess = createAction(
  ProceduresActions.CancelProcedureExecutionSuccess,
  props<{
    patientId: string;
    encounterId: string;
    procedureId: string;
  }>()
);

export const cancelProcedureExecutionFailure = createAction(
  ProceduresActions.CancelProcedureExecutionFailure,
  props<{ error: ApiError }>()
);

export const showProcedureLegendState = createAction(
  ProceduresActions.ShowProcedureLegendState
);
export const hideProcedureLegendState = createAction(
  ProceduresActions.HideProcedureLegendState
);

export const selectedProcedureOccurrenceFromTimeline = createAction(
  ProceduresActions.SelectedProcedureOccurrenceFromTimeline,
  props<{ selectedOccurrenceId: string }>()
);

export const changeProcedureTab = createAction(
  ProceduresActions.ChangeProcedureTab,
  props<{ tab: number }>()
);

export const loadProcedureDevices = createAction(ProceduresActions.LoadDevices);

export const loadProcedureDevicesSuccess = createAction(
  ProceduresActions.LoadDevicesSuccess,
  props<{ devices: ProcedureDevice[] }>()
);

export const loadProcedureDevicesFailure = createAction(
  ProceduresActions.LoadDevicesFailure,
  props<{ error: ApiError }>()
);

export const loadProcedureLocations = createAction(
  ProceduresActions.LoadLocations
);

export const loadProcedureLocationsSuccess = createAction(
  ProceduresActions.LoadLocationsSuccess,
  props<{ locations: ProcedureLocation[] }>()
);

export const loadProcedureLocationsFailure = createAction(
  ProceduresActions.LoadLocationsFailure,
  props<{ error: ApiError }>()
);

export const loadProcedureRoutes = createAction(ProceduresActions.LoadRoutes);

export const loadProcedureRoutesSuccess = createAction(
  ProceduresActions.LoadRoutesSuccess,
  props<{ routes: ProcedureRoute[] }>()
);

export const loadProcedureRoutesFailure = createAction(
  ProceduresActions.LoadRoutesFailure,
  props<{ error: ApiError }>()
);

export const clearProcedureTemporaryFormData = createAction(
  ProceduresActions.ClearTemporaryFormData
);

export const clearProcedureProgrammingTemporaryFormData = createAction(
  ProceduresActions.ClearProgrammingTemporaryFormData
);

export const clearProcedureExecutionTemporaryFormData = createAction(
  ProceduresActions.ClearExecutionTemporaryFormData
);

export const clearProcedureTerminationTemporaryFormData = createAction(
  ProceduresActions.ClearTerminationTemporaryFormData
);
