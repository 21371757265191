<natea-base-modal
  [modalTitle]="t('attention')!"
  (closeModal)="cancel.emit($event)"
  class="confirm-modal"
  [modalSize]="modalSize"
  *transloco="let t; read: 'clinicalNote.confirmDeleteNote'"
>
  <div class="modal-content">
    <p>{{ message }}</p>
  </div>
  <div class="modal-footer">
    <natea-button
      (buttonClick)="cancel.emit($event)"
      [color]="colorVariantSecondary"
      [label]="t('cancel')"
      [disabled]="isButtonDisabled"
    >
    </natea-button>
    <natea-button
      (buttonClick)="confirmDeleteClick($event)"
      [label]="t('confirm')"
      [disabled]="isButtonDisabled"
    >
    </natea-button>
  </div>
</natea-base-modal>
