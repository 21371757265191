/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ClinicalNoteAssessRequestResource } from '../model/clinicalNoteAssessRequestResource';
// @ts-ignore
import { ClinicalNoteCreateRequestResource } from '../model/clinicalNoteCreateRequestResource';
// @ts-ignore
import { ErrorResponse } from '../model/errorResponse';
// @ts-ignore
import { GeneralResponseListLogResourceClinicalNoteLogEntryResource } from '../model/generalResponseListLogResourceClinicalNoteLogEntryResource';
// @ts-ignore
import { GeneralResponseNoteDeletedResource } from '../model/generalResponseNoteDeletedResource';
// @ts-ignore
import { GeneralResponseNoteResource } from '../model/generalResponseNoteResource';
// @ts-ignore
import { GeneralResponseNoteStatusCountsResource } from '../model/generalResponseNoteStatusCountsResource';
// @ts-ignore
import { NotePutRequest } from '../model/notePutRequest';
// @ts-ignore
import { SearchResponseListNoteResource } from '../model/searchResponseListNoteResource';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ClinicalNoteApiService {

    protected basePath = 'http://localhost:8090/nateaccpl/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * assess clinical note
     * This operation allows the assessment of a clinical note using its unique identifier.
     * @param clinicalNoteId Unique identifier of the clinical note to be assessed.
     * @param clinicalNoteAssessRequestResource Request object for assessing a clinical note.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assessClinicalNote(clinicalNoteId: string, clinicalNoteAssessRequestResource: ClinicalNoteAssessRequestResource, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<GeneralResponseNoteResource>;
    public assessClinicalNote(clinicalNoteId: string, clinicalNoteAssessRequestResource: ClinicalNoteAssessRequestResource, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseNoteResource>>;
    public assessClinicalNote(clinicalNoteId: string, clinicalNoteAssessRequestResource: ClinicalNoteAssessRequestResource, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseNoteResource>>;
    public assessClinicalNote(clinicalNoteId: string, clinicalNoteAssessRequestResource: ClinicalNoteAssessRequestResource, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<any> {
        if (clinicalNoteId === null || clinicalNoteId === undefined) {
            throw new Error('Required parameter clinicalNoteId was null or undefined when calling assessClinicalNote.');
        }
        if (clinicalNoteAssessRequestResource === null || clinicalNoteAssessRequestResource === undefined) {
            throw new Error('Required parameter clinicalNoteAssessRequestResource was null or undefined when calling assessClinicalNote.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/clinical-note/${this.configuration.encodeParam({name: "clinicalNoteId", value: clinicalNoteId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/assess`;
        return this.httpClient.request<GeneralResponseNoteResource>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: clinicalNoteAssessRequestResource,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create clinical note for patient encounter
     * This operation creates a new clinical note and associates it with the pediatric patient encounter.
     * @param patientId Unique identifier for the patient associated with a hospitalization to which the clinical note must be added.
     * @param encounterId Unique identifier for the hospitalization to which the clinical note must be added.
     * @param clinicalNoteCreateRequestResource Request object for creating a new clinical note.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createClinicalNoteForPatientEncounter(patientId: string, encounterId: string, clinicalNoteCreateRequestResource: ClinicalNoteCreateRequestResource, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseNoteResource>;
    public createClinicalNoteForPatientEncounter(patientId: string, encounterId: string, clinicalNoteCreateRequestResource: ClinicalNoteCreateRequestResource, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseNoteResource>>;
    public createClinicalNoteForPatientEncounter(patientId: string, encounterId: string, clinicalNoteCreateRequestResource: ClinicalNoteCreateRequestResource, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseNoteResource>>;
    public createClinicalNoteForPatientEncounter(patientId: string, encounterId: string, clinicalNoteCreateRequestResource: ClinicalNoteCreateRequestResource, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling createClinicalNoteForPatientEncounter.');
        }
        if (encounterId === null || encounterId === undefined) {
            throw new Error('Required parameter encounterId was null or undefined when calling createClinicalNoteForPatientEncounter.');
        }
        if (clinicalNoteCreateRequestResource === null || clinicalNoteCreateRequestResource === undefined) {
            throw new Error('Required parameter clinicalNoteCreateRequestResource was null or undefined when calling createClinicalNoteForPatientEncounter.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (encounterId !== undefined && encounterId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>encounterId, 'encounterId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/clinical-note`;
        return this.httpClient.request<GeneralResponseNoteResource>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: clinicalNoteCreateRequestResource,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete the clinical note specified by ID
     * This operation deletes a clinical note by its unique identifier. If the clinical note is not found, an error response will be returned.
     * @param noteId ID of the note to be deleted.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteNote(noteId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<GeneralResponseNoteDeletedResource>;
    public deleteNote(noteId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseNoteDeletedResource>>;
    public deleteNote(noteId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseNoteDeletedResource>>;
    public deleteNote(noteId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<any> {
        if (noteId === null || noteId === undefined) {
            throw new Error('Required parameter noteId was null or undefined when calling deleteNote.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/clinical-notes/${this.configuration.encodeParam({name: "noteId", value: noteId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<GeneralResponseNoteDeletedResource>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get clinical note by id
     * This operation retrieves a specific clinical note by its unique identifier.
     * @param clinicalNoteId The unique identifier for the clinical note.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClinicalNoteById(clinicalNoteId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseNoteResource>;
    public getClinicalNoteById(clinicalNoteId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseNoteResource>>;
    public getClinicalNoteById(clinicalNoteId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseNoteResource>>;
    public getClinicalNoteById(clinicalNoteId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clinicalNoteId === null || clinicalNoteId === undefined) {
            throw new Error('Required parameter clinicalNoteId was null or undefined when calling getClinicalNoteById.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/clinical-note/${this.configuration.encodeParam({name: "clinicalNoteId", value: clinicalNoteId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<GeneralResponseNoteResource>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get clinical note log
     * This operation retrieves the log entries associated with a clinical note identified by its ID.
     * @param clinicalNoteId Unique identifier of the clinical note for which log entries are to be retrieved.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClinicalNoteLogEntries(clinicalNoteId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseListLogResourceClinicalNoteLogEntryResource>;
    public getClinicalNoteLogEntries(clinicalNoteId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseListLogResourceClinicalNoteLogEntryResource>>;
    public getClinicalNoteLogEntries(clinicalNoteId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseListLogResourceClinicalNoteLogEntryResource>>;
    public getClinicalNoteLogEntries(clinicalNoteId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (clinicalNoteId === null || clinicalNoteId === undefined) {
            throw new Error('Required parameter clinicalNoteId was null or undefined when calling getClinicalNoteLogEntries.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/clinical-note/${this.configuration.encodeParam({name: "clinicalNoteId", value: clinicalNoteId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/log`;
        return this.httpClient.request<GeneralResponseListLogResourceClinicalNoteLogEntryResource>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get clinical notes
     * This operation retrieves clinical notes that belong to the specified patient encounter. Additional filters can be applied to refine the results, and they are combined using an AND logic.
     * @param patientId Unique identifier for the patient associated with the hospitalization being searched for clinical notes.
     * @param encounterId Unique identifier for the hospitalization being searched for clinical notes.
     * @param createdById Unique identifier for the user who created clinical notes to be searched, if applicable.
     * @param type The domain key for the type of clinical notes to be searched, if applicable.
     * @param status The domain keys (as a list) for the status of the clinical notes to be searched, if applicable.
     * @param startDate The start date for the search of clinical notes, if applicable.
     * @param endDate The end date for the search of clinical notes, if applicable.
     * @param partialText The partial text to be included in the additional notes of clinical notes, if applicable.
     * @param itemPage The number of items per page.
     * @param page The page number of the result set.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClinicalNotes(patientId: string, encounterId: string, createdById?: string, type?: string, status?: Array<string>, startDate?: string, endDate?: string, partialText?: string, itemPage?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SearchResponseListNoteResource>;
    public getClinicalNotes(patientId: string, encounterId: string, createdById?: string, type?: string, status?: Array<string>, startDate?: string, endDate?: string, partialText?: string, itemPage?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SearchResponseListNoteResource>>;
    public getClinicalNotes(patientId: string, encounterId: string, createdById?: string, type?: string, status?: Array<string>, startDate?: string, endDate?: string, partialText?: string, itemPage?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SearchResponseListNoteResource>>;
    public getClinicalNotes(patientId: string, encounterId: string, createdById?: string, type?: string, status?: Array<string>, startDate?: string, endDate?: string, partialText?: string, itemPage?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling getClinicalNotes.');
        }
        if (encounterId === null || encounterId === undefined) {
            throw new Error('Required parameter encounterId was null or undefined when calling getClinicalNotes.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (encounterId !== undefined && encounterId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>encounterId, 'encounterId');
        }
        if (createdById !== undefined && createdById !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdById, 'createdById');
        }
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }
        if (status) {
            status.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'status');
            })
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (partialText !== undefined && partialText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>partialText, 'partialText');
        }
        if (itemPage !== undefined && itemPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>itemPage, 'itemPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/clinical-notes`;
        return this.httpClient.request<SearchResponseListNoteResource>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * count clinical notes by status within a patient encounter
     * This endpoint counts the number of clinical notes for each status within the context of a patient encounter.  The count is performed on all clinical notes within the encounter if no author ID is provided.  The count is specific to clinical notes created by a specific author if an author ID is provided.
     * @param patientId The unique ID of the patient for whom clinical notes are being counted within the context of one of its encounters.
     * @param encounterId The ID of one of the encounters associated with the specified patient, whose clinical notes are to be counted.
     * @param authorId The ID of the user whose clinical notes are being counted. If provided, the count will be specific to clinical notes created by this user.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNoteStatusCounts(patientId: string, encounterId: string, authorId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<GeneralResponseNoteStatusCountsResource>;
    public getNoteStatusCounts(patientId: string, encounterId: string, authorId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseNoteStatusCountsResource>>;
    public getNoteStatusCounts(patientId: string, encounterId: string, authorId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseNoteStatusCountsResource>>;
    public getNoteStatusCounts(patientId: string, encounterId: string, authorId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling getNoteStatusCounts.');
        }
        if (encounterId === null || encounterId === undefined) {
            throw new Error('Required parameter encounterId was null or undefined when calling getNoteStatusCounts.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (encounterId !== undefined && encounterId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>encounterId, 'encounterId');
        }
        if (authorId !== undefined && authorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>authorId, 'authorId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/clinical-notes/status-counts`;
        return this.httpClient.request<GeneralResponseNoteStatusCountsResource>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update the clinical note specified by id
     * Use the clinical note identified by its unique identifier as the origin to create a replacement note, modifying only the fields with non-null values, and then replace the original note with the new one.
     * @param noteId The unique identifier of the clinical note used as the origin for the update.
     * @param notePutRequest The fields that can be updated for the creation of the replacement clinical note.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putNote(noteId: string, notePutRequest: NotePutRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<GeneralResponseNoteResource>;
    public putNote(noteId: string, notePutRequest: NotePutRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseNoteResource>>;
    public putNote(noteId: string, notePutRequest: NotePutRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseNoteResource>>;
    public putNote(noteId: string, notePutRequest: NotePutRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<any> {
        if (noteId === null || noteId === undefined) {
            throw new Error('Required parameter noteId was null or undefined when calling putNote.');
        }
        if (notePutRequest === null || notePutRequest === undefined) {
            throw new Error('Required parameter notePutRequest was null or undefined when calling putNote.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/clinical-notes/${this.configuration.encodeParam({name: "noteId", value: noteId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<GeneralResponseNoteResource>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: notePutRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
