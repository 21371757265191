import { NateaIconsName } from 'natea-components';
import { Patient } from './patient';
import { Person } from './person';

export enum UserRole {
  Doctor = 'M',
  // DoctorTutor = 'DT',
  DoctorStudent = 'DS',
  Nurse = 'I',
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  role?: UserRole; // TODO check if role is mandatory
}

export const shownName = (
  user: User | Patient | Person,
  surnameBefore = false
): string => {
  return surnameBefore
    ? `${user.lastName} ${user.firstName}`
    : `${user.firstName} ${user.lastName}`;
};

export const iconForUserRole = (
  role?: UserRole
): NateaIconsName | undefined => {
  switch (role) {
    case UserRole.Doctor:
      return 'medico';
    case UserRole.DoctorStudent:
      return 'medico';
    case UserRole.Nurse:
      return 'infermiere';
    default:
      return undefined;
  }
};
