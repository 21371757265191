import { Injectable } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import {
  CreateItemRequest,
  GeneralResponseListStoreItemDeletedResource,
  UserStorageApiService,
} from '../generated/api';
import createNewItemStorageMocked from './create-new-item-storage-mocked';
import deleteUserStorageMockedItemResponse from './delete-user-storage-mocked-item-response';
import getUserStorageItemsResponse, {
  mockedBlackBoardNotes,
} from './get-user-storage-mocked-items-response';
import { HttpContext, HttpResponse, HttpEvent } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MockedUserStorageApiService extends UserStorageApiService {
  override getUserStoreItems(
    userId: string,
    objectType: string
  ): Observable<any> {
    return of(getUserStorageItemsResponse()).pipe(delay(2000));
  }

  override deleteUserStoreItem(
    userId: string,
    objectType: string,
    storeItemId: string
  ): Observable<any> {
    return of(
      deleteUserStorageMockedItemResponse(userId, objectType, storeItemId)
    ).pipe(delay(2000));
  }

  /* override deleteItem(
    userId: string,
    objectType: string,
    storeItemId: string
  ): Observable<any> {
    return throwError(() => '').pipe(delay(2000));
  }*/

  override clearUserStorage(userId: string): Observable<any> {
    return of(' ').pipe(delay(2000));
  }

  override createUserStoreItem(
    userId: string,
    objectType: string,
    patientId: string,
    createItemRequest: CreateItemRequest
  ): Observable<any> {
    const response = createNewItemStorageMocked(
      userId,
      objectType,
      createItemRequest
    );

    if (response.payload) {
      mockedBlackBoardNotes.push(response.payload);
      return of(response).pipe(delay(2000));
    } else {
      return of(response).pipe(delay(2000));
    }
  }
}
