<mat-tab-group (selectedTabChange)="tabChange($event)">
  <div *ngFor="let tab of tabs; let i = index">
    <mat-tab>
      <ng-template mat-tab-label>
        <natea-icons
          [name]="tab.iconName"
          class="icon"
          [class.selected]="selectedIndex == i"
        ></natea-icons>
        <span class="label">{{ tab.label }}</span>
      </ng-template>
    </mat-tab>
  </div>
</mat-tab-group>
