import { Sort } from '@angular/material/sort';
import { createSelector } from '@ngrx/store';
import { OptionsItem } from 'natea-components';
import { MultiSectionTimelineInstance } from '../../../../shared/components/multi-section-timeline/models/multi-section-timeline-instance';
import { selectShownEncounterInstrumentalTherapies } from '../../../patients/store/clinical-data/therapies/patients-data.instrumental-therapies.selectors';
import { selectInstrumentalTherapiesUiState } from '../../../patients/store/selected-patient/selected-patient.selectors';
import {
  TherapyOccurrence,
  TherapyOccurrenceSearchResultItem,
} from '../../common/entities/therapy-occurrence';
import { mapTherapyPrescriptionToTimelineOccurrence } from '../../common/utils/therapy-timeline-mapper';
import {
  InstrumentalTherapy,
  InstrumentalTherapyOccurrence,
} from '../entities/instrumental-therapy';
import { SelectedTherapyItem } from '../entities/instrumental-therapy-item-selected';
import { InstrumentalTherapyType } from '../entities/instrumental-therapy-type';
import { mapInstrumentalTherapyOccurrenceToTableItem } from '../utils/instrumental-therapies-utils';
import { InstrumentalTherapiesUiState } from './instrumental-therapies.reducer';
import { instrumentalTherapySort } from '../utils/instrumental-therapy-sort';
import { TherapiesTableItem } from '../../common/entities/therapies-table-item';

export const selectIsLoadingInstrumentalTherapies = createSelector(
  selectInstrumentalTherapiesUiState,
  (state: InstrumentalTherapiesUiState): boolean =>
    state.isLoadingData && state.isLoadingTypes
);

export const selectInstrumentalTherapiesSort = createSelector(
  selectInstrumentalTherapiesUiState,
  (state: InstrumentalTherapiesUiState): Sort | undefined => state.sort
);

export const selectShowCreateNewInstrumentalTherapyModal = createSelector(
  selectInstrumentalTherapiesUiState,
  (state: InstrumentalTherapiesUiState): boolean => state.showCreateNewModal
);

export const selectIsCreatingNewInstrumentalTherapy = createSelector(
  selectInstrumentalTherapiesUiState,
  (state: InstrumentalTherapiesUiState): boolean => state.isCreatingNew
);

export const selectSelectedInstrumentalTherapyIds = createSelector(
  selectInstrumentalTherapiesUiState,
  (state: InstrumentalTherapiesUiState): SelectedTherapyItem => {
    return {
      selectedOccurrenceId: state.selectedTherapyOccurrence,
      fromTable: state.selectedTherapyOccurrenceInTable,
      changingTab: state.changingTab,
    };
  }
);

export const selectIsInstrumentalTherapySelected = createSelector(
  selectSelectedInstrumentalTherapyIds,
  (occurrenceIds?: SelectedTherapyItem): boolean =>
    occurrenceIds?.selectedOccurrenceId != undefined
);

export const selectSelectedInstrumentalTherapyPrescription = createSelector(
  selectSelectedInstrumentalTherapyIds,
  selectShownEncounterInstrumentalTherapies,
  (
    selectedOccurrenceIds: SelectedTherapyItem | undefined,
    therapies: InstrumentalTherapy[] | undefined
  ): InstrumentalTherapy | undefined =>
    therapies?.find(
      (therapy) =>
        therapy.id === selectedOccurrenceIds?.selectedOccurrenceId?.therapyId
    )
);

export const selectSelectedInstrumentalTherapyOccurrence = createSelector(
  selectSelectedInstrumentalTherapyIds,
  selectSelectedInstrumentalTherapyPrescription,
  (
    ids?: SelectedTherapyItem,
    prescription?: InstrumentalTherapy
  ): InstrumentalTherapyOccurrence | undefined =>
    prescription?.occurrences.find(
      (occurrence) => occurrence.id === ids?.selectedOccurrenceId?.occurrenceId
    )
);

export const selectInstrumentalTherapyCandidateForDeletion = createSelector(
  selectInstrumentalTherapiesUiState,
  selectShownEncounterInstrumentalTherapies,
  (
    state: InstrumentalTherapiesUiState,
    therapies: InstrumentalTherapy[] | undefined
  ): InstrumentalTherapy | undefined =>
    therapies?.find(
      (therapy) => therapy.id === state.selectedTherapyOccurrence?.therapyId
    )
);

// Shown encounter therapies as timeline items
export const selectInstrumentalTherapiesAsTimelineItems = createSelector(
  selectShownEncounterInstrumentalTherapies,
  (
    therapies: InstrumentalTherapy[] | undefined
  ): MultiSectionTimelineInstance<TherapyOccurrence>[] | undefined => {
    if (!therapies) return undefined;
    const typesMap = new Map<string, InstrumentalTherapyType>();
    const therapiesMap = new Map<string, InstrumentalTherapy[]>();

    therapies?.forEach((therapy) => {
      const therapyId = therapy.type.id;
      if (!therapiesMap.has(therapyId)) {
        therapiesMap.set(therapyId, [therapy]);
        typesMap.set(therapyId, therapy.type);
      } else {
        therapiesMap.get(therapyId)?.push(therapy);
      }
    });
    return Array.from(therapiesMap.keys()).map((typeId) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const type = typesMap.get(typeId)!;
      return {
        id: typeId,
        label: type.name,
        occurrences:
          therapiesMap.get(typeId)?.map((therapy) => {
            const result = mapTherapyPrescriptionToTimelineOccurrence(therapy);
            return result;
          }) ?? [],
      };
    });
  }
);

export const selectInstrumentalTherapiesAsTableItems = createSelector(
  selectShownEncounterInstrumentalTherapies,
  selectInstrumentalTherapiesSort,
  (
    therapies?: InstrumentalTherapy[],
    sort?: Sort
  ): TherapiesTableItem[] | undefined => {
    const comparator = instrumentalTherapySort(sort);
    const items = therapies?.reduce((acc, therapy) => {
      return acc.concat(
        therapy.occurrences.map((occurrence) =>
          mapInstrumentalTherapyOccurrenceToTableItem(occurrence, therapy)
        )
      );
    }, [] as TherapiesTableItem[]);
    return items?.sort(comparator);
  }
);

export const selectInstrumentalTherapiesSelectedTabindex = createSelector(
  selectInstrumentalTherapiesUiState,
  (state: InstrumentalTherapiesUiState): number => state.tabIndex
);

export const selectInstrumentalTherapiesAsSearchResultItems = createSelector(
  selectInstrumentalTherapiesAsTableItems,
  (
    items: TherapiesTableItem[] | undefined
  ): TherapyOccurrenceSearchResultItem[] | undefined => {
    return items?.map((item) => ({
      id: item.id,
      prescriptionId: item.therapyId,
      ingredient: item.name,
      author: item.createdBy,
      prescriptionDate: item.prescriptionDate,
      state: item.state,
      durationType: item.durationType,
      programmingDate: item.programmingDate,
      executionDate: item.executionDate,
    }));
  }
);
export const selectInstrumentalTherapyTypes = createSelector(
  selectInstrumentalTherapiesUiState,
  (
    state: InstrumentalTherapiesUiState
  ): InstrumentalTherapyType[] | undefined => state.therapyTypes
);

export const selectInstrumentalTherapyTypesAsOptionItems = createSelector(
  selectInstrumentalTherapyTypes,
  (
    types: InstrumentalTherapyType[] | undefined
  ): OptionsItem<InstrumentalTherapyType>[] | undefined =>
    types?.map((t) => ({
      id: t.id,
      label: t.name,
      data: t,
    }))
);

export const selectInstrumentalTherapyCandidateForSuspension = createSelector(
  selectInstrumentalTherapiesUiState,
  selectShownEncounterInstrumentalTherapies,
  (
    state: InstrumentalTherapiesUiState,
    therapies?: InstrumentalTherapy[]
  ): InstrumentalTherapy | undefined => {
    return state.candidateForSuspensionId
      ? therapies?.find(
          (therapy) => therapy.id === state.candidateForSuspensionId
        )
      : undefined;
  }
);

export const selectIsInstrumentalTherapySuspensionRunning = createSelector(
  selectInstrumentalTherapiesUiState,
  (state: InstrumentalTherapiesUiState): boolean => state.isSuspensionRunning
);

export const selectIsShowDeleteConfirmationModal = createSelector(
  selectInstrumentalTherapiesUiState,
  (state: InstrumentalTherapiesUiState): boolean =>
    state.isShowDeleteConfirmationModal
);

export const selectIsDeleteRunning = createSelector(
  selectInstrumentalTherapiesUiState,
  (state: InstrumentalTherapiesUiState): boolean => state.isDeleteRunning
);

export const selectIsShowConfirmAbortModal = createSelector(
  selectInstrumentalTherapiesUiState,
  (state: InstrumentalTherapiesUiState): boolean =>
    state.isShowConfirmAbortModal
);

export const selectIsRequestConfirmAbortTherapyRunning = createSelector(
  selectInstrumentalTherapiesUiState,
  (state: InstrumentalTherapiesUiState): boolean =>
    state.isRequestAbortTherapyRunning
);

export const selectInstrumentalTherapyAbortionCandidate = createSelector(
  selectInstrumentalTherapiesUiState,
  (state: InstrumentalTherapiesUiState): string | undefined =>
    state.candidateForSuspensionId
);

export const selectIsUpdatingInstrumentalTherapy = createSelector(
  selectInstrumentalTherapiesUiState,
  (state: InstrumentalTherapiesUiState): boolean | undefined => state.isUpdating
);

export const selectShowSuspendModal = createSelector(
  selectInstrumentalTherapiesUiState,
  (state: InstrumentalTherapiesUiState): boolean => state.showSuspendModal
);

export const selectIsConfirmSuspendRunning = createSelector(
  selectInstrumentalTherapiesUiState,
  (state: InstrumentalTherapiesUiState): boolean => state.isSuspensionRunning
);
