import {
  ProcedureDevice,
  ProcedureLocation,
  ProcedureRoute,
} from '../entities/procedure-data';

export const mockDevices = (): ProcedureDevice[] => {
  return [
    {
      id: '1',
      description: 'PREMICATH 27 G',
    },
    {
      id: '2',
      description: 'MEDEX MEDICAL 27 G',
    },
    {
      id: '3',
      description: 'VYGON EPICUTANEO - CAVA',
    },
    {
      id: '4',
      description: 'VYGON FR 2,5',
    },
    { id: '5', description: 'AGOCANNULA 22 G' },
  ];
};

export const mockLocations = (): ProcedureLocation[] => {
  return [
    {
      id: '1',
      shortDescription: 'Sovratr.',
      description: 'Sovratrocheale',
    },
    {
      id: '2',
      shortDescription: 'Cef.DX',
      description: 'Cefalica DX',
    },
    {
      id: '3',
      shortDescription: 'Art.rad.DX',
      description: 'Arteria radiale DX',
    },
  ];
};

export const mockRoutes = (): ProcedureRoute[] => {
  return [
    {
      id: 'AC',
      description: 'ARTERIA CENTRALE',
    },
    {
      id: 'CV',
      description: 'CATETERE VESCICALE',
    },
    {
      id: 'OT',
      description: 'OROTRACHEALE',
    },
  ];
};
