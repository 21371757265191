import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, map } from 'rxjs';
import { AppRoutes } from '../../../shared/routes/routes';
import {
  UserDepartmentsStatus,
  UserDepartmentsStatusId,
  selectDepartmentDataLoaded,
  selectDepartmentsStatus,
} from '../store/departments.selectors';

export function departmentsCountGuard(): Observable<boolean | UrlTree> {
  const store: Store = inject(Store);
  const router: Router = inject(Router);
  return combineLatest([
    store.select(selectDepartmentDataLoaded),
    store.select(selectDepartmentsStatus),
  ]).pipe(
    map(
      ([loaded, status]: [boolean, UserDepartmentsStatusId]):
        | boolean
        | UrlTree => {
        if (!loaded) {
          router.navigateByUrl(AppRoutes.departmentsLoadingPage, {
            skipLocationChange: true,
          });
          return false;
        }
        if (status.status === UserDepartmentsStatus.MULTI_DEPARTMENTS) {
          return true;
        }
        return router.parseUrl(
          AppRoutes.departmentBasePath(status.departmentId)
        );
      }
    )
  );
}
