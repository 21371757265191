<natea-cc-multi-section-timeline-header
  [dateRange]="dateRange"
></natea-cc-multi-section-timeline-header>

<div class="shader"></div>
<div class="labels-column">
  <h6
    *ngFor="let instance of positionedInstances"
    [style.--depth]="(instance.maxCollisionDepth ?? 0) + 1"
    [class.has-icon]="hasIcon"
  >
    <span class="icon-wrapper" *ngIf="hasIcon && instance.iconName">
      <natea-icons [name]="instance.iconName" class="icon"></natea-icons>
    </span>
    <div>
      <span class="label">{{ instance.label }}</span>
    </div>
  </h6>
</div>
<div
  #timelineColumn
  class="timeline-column"
  [style.padding-right]="timelineColumnPaddings?.right"
  [style.padding-left]="timelineColumnPaddings?.left"
  [style.padding-top]="timelineColumnPaddings?.top"
  [style.padding-bottom]="timelineColumnPaddings?.bottom"
>
  <natea-cc-multi-section-timeline-item
    *ngFor="let instance of positionedInstances"
    [dateRange]="dateRange"
    [instance]="instance"
    [showWarning]="showWarning(instance.id)"
    (itemSelected)="onItemSelected($event)"
  ></natea-cc-multi-section-timeline-item>

  <div class="day-lines"></div>
  <div class="today-line" [style.left.%]="todayLineLeftPosition"></div>
  <ng-content select="[bottom-section]"></ng-content>

  <natea-cc-multi-section-timeline-header
    *ngIf="showDaysFooter"
    [leftPadded]="false"
    [dateRange]="dateRange"
  ></natea-cc-multi-section-timeline-header>
  <div class="shader bottom-shader" *ngIf="showDaysFooter"></div>
</div>
