import { NateaIconsName } from 'natea-components';

export enum SidebarItemId {
  ClinicalRecap = 'clinicalRecap', // Riepilogo clinico
  NursingRecord = 'nursingRecord', // Cartella infermieristica
  NursingGantt = 'nursingGantt', // Gantt infermieristico
  TherapyAdministration = 'therapyAdministration', // Somministrazione terapie
  TherapeuticalConfirmation = 'therapeuticalConfirmation', // Conferma terapeutica
  PharmacologicalTherapiesAdministration = 'pharmacologicalTherapiesAdministration', // Terapie farmacologiche
  TransfusionTherapiesAdministration = 'transfusionTherapiesAdministration', // Terapie trasfusionali
  NutritionalTherapiesAdministration = 'nutritionalTherapiesAdministration', // Terapie nutrizionali
  InstrumentalTherapiesAdministration = 'instrumentalTherapiesAdministration', // Terapie strumentali
  IncomeAndOutcome = 'incomeAndOutcome', // Entrate/uscite
  NursingAssessments = 'nursingAssessments', // Valutazioni infermieristiche
  AnthropometricData = 'anthropometricData', // Dati antropometrici
  ClinicalNotes = 'clinicalNotes', // Note di diario
  ClinicalNotesValidation = 'clinicalNotesValidation', // Validazione Note di diario
  Problems = 'problems', // Problemi
  Therapies = 'therapies', // Terapie
  PharmacologicalTherapies = 'pharmacologicalTherapies', // Farmacologiche
  TransfusionTherapies = 'transfusionTherapies', // Trasfusionali
  NutritionalTherapies = 'nutritionalTherapies', // Nutrizionali
  InstrumentalTherapies = 'instrumentalTherapies', // Strumentali
  Procedures = 'procedures', // Procedure
  DiagnosticTests = 'diagnosticTests', // Esami diagnostici
  Consultations = 'consultations', // Consulenze
  NeonatalScreenings = 'neonatalScreenings', // Screening neonatali
  Anamnesis = 'anamnesis', // Anamnesi
  FamilyAnamnesis = 'familyAnamnesis', // Anamnesi familiare
  Pregnancy = 'pregnancy', // Gravidanza
  LaborAndDelivery = 'laborAndDelivery', // Travaglio e parto
  Newborn = 'newborn', // Neonato
  PrimaryResuscitation = 'primaryResuscitation', // Rianimazione primaria
  DeliveryRoomMonitoring = 'deliveryRoomMonitoring', // Monitoraggio in sala parto
  ClinicalRecord = 'clinicalRecord', // Fascicolo clinico
}

interface SidebarItemBase {
  id: SidebarItemId;
  icon: NateaIconsName;
  type: 'group' | 'leaf' | 'footer';
  path: string;
}

export interface SidebarItemGroup extends SidebarItemBase {
  children: SidebarItem[];
  type: 'group';

  // This is used to determine if the group has a reachable section associated
  navigable?: boolean;
}

export interface SidebarItemLeaf extends SidebarItemBase {
  type: 'leaf';
}

export interface SidebarItemFooter extends SidebarItemBase {
  type: 'footer';
}

export type SidebarItem =
  | SidebarItemGroup
  | SidebarItemLeaf
  | SidebarItemFooter;
