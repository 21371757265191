import { DepartmentResource } from '../../../core/generated/api';
import { Department } from '../../../shared/entities/department';

export class DepartmentsAdapters {
  public static departmentBeToFe(department: DepartmentResource): Department {
    return {
      ...department,
    };
  }
}
