import { TherapiesTableItem } from '../../common/entities/therapies-table-item';
import { TherapyOccurrence } from '../../common/entities/therapy-occurrence';
import { tableIconForTherapyInstantOccurrence } from '../../common/utils/therapy-icon-mapper';
import { TransfusionalTherapy } from '../entities/transfusional-therapy';

export const mapTransfusionalTherapyOccurrenceToTableItem = (
  occurrence: TherapyOccurrence,
  therapy: TransfusionalTherapy
): TherapiesTableItem => {
  return {
    id: occurrence.id,
    therapyId: therapy.id,
    name: therapy.name,
    durationType: occurrence.durationType,
    state: occurrence.state,
    programmingDate: occurrence.programmingDate,
    executionDate: occurrence.executionDate,
    prescriptionDate: therapy.prescriptionDate,
    createdBy: therapy.createdBy,
    icon: tableIconForTherapyInstantOccurrence(
      occurrence.state,
      occurrence.programmingDate
    ),
  };
};
