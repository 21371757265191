import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export type SnackbarType =
  | 'error-snackbar'
  | 'success-snackbar'
  | 'warning-snackbar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  showSnackbar(
    message: string,
    action: string,
    type: SnackbarType,
    duration?: number,
    position?: 'top' | 'bottom'
  ): void {
    this.snackBar.open(message, action, {
      panelClass: type,
      duration: duration,
      verticalPosition: position,
    });
  }

  dismissSnackbar(): void {
    this.snackBar.dismiss();
  }
}
