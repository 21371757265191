<!--
    <section>
    <label for="time">{{ label }}</label>
    <div>
      <natea-icons name="clock" class="icon"></natea-icons>
  
      <ngx-mat-timepicker
        id="time"
        [formControl]="dateControl"
        [disabled]="disabled"
        [showSpinners]="showSpinners"
        [stepHour]="stepHour"
        [stepMinute]="stepMinute"
        [stepSecond]="stepSecond"
        [showSeconds]="showSeconds"
      >
      </ngx-mat-timepicker>
    </div>
  </section>
-->

<mat-form-field
  [class.no-hint]="hintLabel === undefined"
  [class.fill-width]="fillParentWidth"
  [class.auto-width]="autoWidth"
  [class.no-hint]="hintLabel === undefined && errorMessages === undefined"
  [class.warn]="errorMessages !== undefined"
>
  <mat-hint class="warn" *ngIf="errorMessages !== undefined">
    <natea-field-error [errorMessages]="errorMessages"></natea-field-error>
  </mat-hint>

  <mat-label *ngIf="label" [class.input-filled]="isAddClass">{{
    label
  }}</mat-label>
  <div>
    <input
      matInput
      type="time"
      [value]="inputValue"
      (change)="onTimeChanged($event)"
      (input)="onTimeInput($event)"
      [step]="stepSecond"
      [disabled]="isDisabled"
    />
    <natea-icons name="clock" class="icon"></natea-icons>
  </div>

  <mat-hint *ngIf="hintLabel !== undefined" [class.disabled]="isDisabled">{{
    hintLabel
  }}</mat-hint>
</mat-form-field>
