import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PregnancyCardData } from '../forms/pregnancy-card-form-data';

@Injectable({
  providedIn: 'root',
})
export class PregnancyCardWebApiWebApi {
  getAllPregnancyCard(): Observable<PregnancyCardData> {
    return of({
      currentPregnancy: {
        lastMenstrualPeriod: new Date(),
        id: '1',
        estimatedDueDate: new Date(),
        gestationalAgeCalculated: '00sett + 00gg',
        gestationalClinicalAgeInWeek: undefined,
        gestationalClinicalAgeInDay: '23',
        indirectCoombsTest: { key: '1', label: 'Debolmente positivo' },
        midwivesVisibility: { key: '1', label: 'Sì' },
        midwivesFolder: 'Sì',
        ecrofraphicsGestationalAge: '24',
        iugr: true,
        pregnancyCurrentNotes:
          'Montes euismod nullam bibendum ultricies duis imperdiet lacinia nibh justo porttitor. Pellentesque urna blandit vehicula potenti neque conubia. In lobortis vulputate hendrerit aenean habitant consectetur elit mattis eleifend eget.',
        menstrualCycle: { key: '1', label: 'Regolare' },
        UM: false,
        USByWeek20: true,
        correspondentAmerorrhoea: true,
        firstFetalMovements: 'Lorem ',
        pregnancyWeight: '80',
        weightGain: '8',
      },
      pregnancy: {
        id: '2',
        physiological: '1',
        pregnancyAssistanceTechnique: {
          key: '1',
          label: 'Solo tratamento farm. ',
        },

        problemsDetected:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
      },
      prophylaxisData: {
        id: '3',
        executed: false,
        glucococorticoids: true,
        others: true,
        completed: true,
        doseNumber: 2,
        lastDoseDate: new Date(),
      },

      previous: {
        id: '4',
        stillborn: 0,
        caesareanSections: 0,
        spontaenousAbortions: 0,
        IVGAbortions: 0,
        pretermPregnancy: 0,
        pregnancyTerm: 0,
        liveMale: 0,
        liveFemale: 0,
        allAlive: 0,
        deadMales: 0,
        deadFemales: 0,
        allDead: 0,
        detectedProblems:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
      },
      completedCard: false,
      note: 'Montes euismod nullam bibendum ultricies duis imperdiet lacinia nibh justo porttitor. Pellentesque urna blandit vehicula potenti neque conubia. In lobortis vulputate hendrerit aenean habitant consectetur elit mattis eleifend eget.',
    });
  }
}
