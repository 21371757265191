import { OptionsItem } from 'natea-components';
import { DomainItem } from 'src/app/shared/entities/domain-item';

export const mapsDomainToOptionsItem = (
  domain: DomainItem<unknown>
): OptionsItem<DomainItem<unknown>> => ({
  id: domain.key,
  label: domain.label,
  data: domain,
});
