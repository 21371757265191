import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { Observable, map } from 'rxjs';
import { LoginData } from '../../../features/login/store/interfaces/login-data';
import { localError } from '../../../shared/entities/errors';
import { User } from '../../../shared/entities/user';
import { ConfigService } from '../../config/config.service';
import { LoginResponse } from './models/login-response';
import { BASE_PATH } from '../../generated/api';

@Injectable({
  providedIn: 'root',
})
export class AuthManager {
  private _accessToken?: string;
  private _refreshToken?: string;

  get isLoggedIn(): boolean {
    return !!this._accessToken;
  }

  get token(): string | undefined {
    return this._accessToken;
  }

  get refreshToken(): string | undefined {
    return this._refreshToken;
  }

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    @Optional() @Inject(BASE_PATH) private basePath: string | string[]
  ) {}

  login = ({ username, password }: LoginData): Observable<User> => {
    const url = `${this.basePath}${this.configService.authRealm}/protocol/openid-connect/token`;

    const params = new URLSearchParams({
      grant_type: 'password',
      client_id: 'natea-login',
      username,
      password,
    });

    return this.http
      .post<LoginResponse>(url, params.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .pipe(
        map((data) => {
          this._accessToken = data.access_token;
          this._refreshToken = data.refresh_token;

          const user = this.userFromAccessToken(data.access_token);

          if (!user) {
            throw localError(HttpStatusCode.ExpectationFailed);
          }

          return user;
        })
      );
  };

  private userFromAccessToken = (accessToken: string): User | undefined => {
    const userData = jwtDecode(accessToken);

    const username =
      'preferred_username' in userData
        ? userData['preferred_username']?.toString()
        : undefined;
    const firstName =
      'given_name' in userData ? (userData['given_name'] as string) : undefined;
    const lastName =
      'family_name' in userData
        ? (userData['family_name'] as string)
        : undefined;

    if (username && firstName && lastName) {
      return {
        id: username,
        firstName,
        lastName,
      };
    }

    return undefined;
  };
}
