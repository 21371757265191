import { createFeatureSelector, createSelector } from '@ngrx/store';
import { deleteBoardNoteType } from '../../../shared/components/blackboard-notes-table/blackboard-notes-table.component';
import { BoardContent } from '../interfaces/board-content';
import { BoardState } from './board.reducers';

export const selectBoard = createFeatureSelector<BoardState>('board');

export const selectBoardNoteContents = createSelector(
  selectBoard,
  (state: BoardState): BoardContent[] => state.boardContents
);

export const selectIsNotNotesFromClipboard = createSelector(
  selectBoardNoteContents,
  (boardContents: BoardContent[]): boolean => boardContents.length === 0
);

export const selectIsAtLeastOneNoteFromClipboardOpened = createSelector(
  selectBoard,
  (state: BoardState): boolean => state.isAtLeastOneNoteFromClipboardOpened
);

export const selectIsShowConfirmDeletionBoardNoteModal = createSelector(
  selectBoard,
  (state: BoardState): deleteBoardNoteType | undefined =>
    state.showDeletionBoardNoteModalType
);

export const selectIsDeleteAllBoardNoteFailed = createSelector(
  selectBoard,
  (state: BoardState): boolean => state.isDeleteAllBoardNoteFailed
);

export const selectIsCopyToClipboardRunning = createSelector(
  selectBoard,
  (state: BoardState): boolean => state.isCopyToClipboardRunning
);
