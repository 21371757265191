import {
  GeneralResponseNoteDeletedResource,
  StoreItemResource,
} from '../generated/api';
import { mockedBlackBoardNotes } from './get-user-storage-mocked-items-response';

export const deleteUserStorageMockedItemResponse = (
  userId: string,
  objectType: string,
  storeItemId: string
): GeneralResponseNoteDeletedResource => {
  const idFined: StoreItemResource | undefined = mockedBlackBoardNotes.find(
    (item) => item.id === storeItemId
  );

  if (idFined) {
    const id: string = idFined.id;
    return { payload: { id } };
  } else {
    return {};
  }
};

export default deleteUserStorageMockedItemResponse;
