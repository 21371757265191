/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a department resource.
 */
export interface DepartmentResource { 
    /**
     * A description of the department.
     */
    description: string;
    /**
     * Unique identifier for the department.
     */
    id: string;
    /**
     * The name of the department.
     */
    name: string;
    /**
     * The number of occupied beds in the department.
     */
    occupiedBeds: number;
    /**
     * The total number of beds in the department.
     */
    totalBeds: number;
}

