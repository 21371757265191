import {
  MultisectionTimelineInstantOccurrence,
  MultisectionTimelineOccurrence,
  MultisectionTimelineProlongedOccurrence,
  ProcedureOccurrenceDurationType,
} from '../../../../shared/components/multi-section-timeline/models/multi-section-timeline-instance';
import { TherapyPrescription } from '../entities/therapy';
import {
  TherapyDurationType,
  TherapyOccurrence,
} from '../entities/therapy-occurrence';
import {
  timelineIconForTherapyInstantOccurrence,
  timelineIconsForTherapyProlongedOccurrence,
} from './therapy-icon-mapper';

export const mapTherapyPrescriptionToTimelineOccurrence = (
  prescription: TherapyPrescription<unknown>
): MultisectionTimelineOccurrence<TherapyOccurrence> => {
  if (prescription.occurrences.length === 1) {
    return mapTherapyOccurrenceToTimelineOccurrence(
      prescription.occurrences[0]
    );
  } else {
    return {
      type: ProcedureOccurrenceDurationType.Recurring,
      occurrences: prescription.occurrences.map(
        mapTherapyOccurrenceToTimelineOccurrence
      ),
    };
  }
};

export const mapTherapyOccurrenceToTimelineOccurrence = (
  occurrence: TherapyOccurrence
):
  | MultisectionTimelineProlongedOccurrence<TherapyOccurrence>
  | MultisectionTimelineInstantOccurrence<TherapyOccurrence> => {
  const startDate = occurrence.executionDate ?? occurrence.programmingDate;
  if (occurrence.durationType === TherapyDurationType.Instant) {
    return {
      id: occurrence.id,
      type: ProcedureOccurrenceDurationType.Instant,
      executionDate: startDate,
      icon: timelineIconForTherapyInstantOccurrence(
        occurrence.state,
        occurrence.programmingDate
      ),
      data: occurrence,
    };
  } else {
    const { startIcon, endIcon } = timelineIconsForTherapyProlongedOccurrence(
      occurrence.state,
      occurrence.programmingDate
    );

    const endDate =
      occurrence.terminationDate ??
      new Date(startDate.getTime() + (occurrence.duration ?? 0));
    return {
      id: occurrence.id,
      type: ProcedureOccurrenceDurationType.Prolonged,
      executionDate: startDate,
      terminationDate: endDate,
      startIcon,
      endIcon,
      data: occurrence,
    };
  }
};
