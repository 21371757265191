import { Injectable } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import { FamilyAnamnesis } from '../entities/family-anamnesis';

import { AllFamilyPathologies } from '../entities/all-family-pathologies';

@Injectable({
  providedIn: 'root',
})
export class FamilyAnamnesisWebApi {
  loadAllFamilyAnamnesis(
    familyAnamnesis: FamilyAnamnesis[],
    patientId: string,
    encounterId: string
  ): Observable<AllFamilyPathologies> {
    return of({
      pathologies: familyAnamnesis,
      hasNoPathology: true,
      note: 'This is a note',
    }).pipe(delay(1000));
  }
  removeAllFamilyAnamnesis(): Observable<void> {
    return of(void 0).pipe(delay(1000));
  }

  removeFamilyAnamnesisPathology(
    pathologyId: string,
    allPathologies?: AllFamilyPathologies
  ): Observable<AllFamilyPathologies | undefined> {
    if (!allPathologies) {
      return of(void 0);
    }

    const pathologies = allPathologies.pathologies.filter(
      (pathology) => pathology.pathology.id !== pathologyId
    );

    const pathologiesCopy = {
      ...allPathologies,
      pathologies,
    };
    return of(pathologiesCopy).pipe(delay(1000));
  }

  saveAllFamilyAnamnesis(
    allFamilyPathologies: AllFamilyPathologies,
    hasNoPathologies?: boolean
  ): Observable<AllFamilyPathologies> {
    return of(allFamilyPathologies).pipe(delay(1000));
  }

  insetNewPathology(
    pathology: FamilyAnamnesis,
    familyAnamnesis?: AllFamilyPathologies
  ): Observable<AllFamilyPathologies> {
    if (!familyAnamnesis) {
      return of({
        pathologies: [pathology],
        hasNoPathology: false,
      }).pipe(delay(1000));
    }

    return of({
      ...familyAnamnesis,
      pathologies: [pathology, ...familyAnamnesis.pathologies],
      hasNoPathology: false,
    }).pipe(delay(1000));
  }
}
