import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';

@Component({
  selector: 'natea-slide-toggle',
  standalone: true,
  imports: [CommonModule, MatSlideToggleModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SlideToggleComponent,
      multi: true,
    },
  ],
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
})
export class SlideToggleComponent implements ControlValueAccessor, OnChanges {
  @Input('aria-describedby') ariaDescribedby = '';
  @Input('aria-label') ariaLabel: string | null = null;
  @Input('aria-labelledby') ariaLabelledby: string | null = null;
  @Input() checked = false;
  @Input() disableRipple = false;
  @Input() id = '';
  @Input() labelPosition: 'before' | 'after' = 'after';
  @Input() name = '';
  @Input() label = '';
  @Input() required = false;
  @Input() fillParentWidth = true;
  @Input() isDisabled = false;

  @Output() changed: EventEmitter<MatSlideToggleChange> =
    new EventEmitter<MatSlideToggleChange>();

  @Output() toggled: EventEmitter<void> = new EventEmitter<void>();

  disabled = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isDisabled'] !== undefined && changes['isDisabled'] !== null) {
      this.disabled = this.isDisabled;
    }
  }

  onChange!: (val: boolean | null) => void;
  onTouched!: () => void;

  markAsTouched = (): void => {
    if (this.onTouched) {
      this.onTouched();
    }
  };

  writeValue = (data: boolean): void => {
    this.checked = data;
  };

  registerOnChange = (fn: (val: boolean | null) => void): void => {
    this.onChange = fn;
  };

  registerOnTouched = (fn: () => void): void => {
    this.onTouched = fn;
  };

  setDisabledState = (disabled: boolean): void => {
    this.disabled = disabled;
  };

  onToggleChange = (): void => {
    this.markAsTouched();
    this.toggled.emit();
  };

  onChanged = (event: MatSlideToggleChange): void => {
    if (this.onChange) {
      this.onChange(event.checked);
      this.checked = event.checked;
    }
    this.changed.emit(event);
  };
}
