<ng-container *ngIf="isAnyData">
  <div class="problems-container" *transloco="let t; read: 'problems'">
    <div class="multi-actions">
      <natea-button
        [color]="buttonVariants.SECONDARY"
        leftIcon="circle-stop"
        [label]="t('patientProblem.closeProblem')"
        [disabled]="!canTerminateProblems()"
        (buttonClick)="terminateProblems()"
      ></natea-button>
      <natea-button
        [color]="buttonVariants.SECONDARY"
        leftIcon="bin"
        [label]="t('patientProblem.deleteProblem')"
        [disabled]="!canDeleteProblems()"
        (buttonClick)="deleteProblems()"
      ></natea-button>
    </div>
    <div
      #problemsTable
      class="problems-table-container"
      role="grid"
      (scroll)="checkOverflow()"
    >
      <table
        mat-table
        [dataSource]="problems"
        matSort
        (matSortChange)="sortTable($event)"
      >
        <caption>
          {{
            t("problemsTableCaption")
          }}
        </caption>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef tabindex="-1">
            <natea-checkbox
              [checked]="selection.hasValue() && isAllSelected()"
              [isIndeterminate]="selection.hasValue() && !isAllSelected()"
              (click)="$event.stopPropagation()"
              (checkboxChange)="$event ? toggleAllRows() : null"
            ></natea-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" tabindex="-1">
            <natea-checkbox
              [checked]="selection.isSelected(row)"
              (click)="$event.stopPropagation()"
              (checkboxChange)="$event ? selection.toggle(row) : null"
            ></natea-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            tabindex="-1"
            role="button"
          >
            {{ t("tableHeaders.endDate") }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="consultation.emit(element)"
            class="clickable-cell"
            role="button"
          >
            <!-- TODO localizedDate?? -->
            {{
              element.endDate
                ? (element.endDate | date : "dd/MM/yyyy hh:mm")
                : t("currentProblem")
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="problemDescription">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            tabindex="-1"
            role="button"
          >
            {{ t("tableHeaders.problemDescription") }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="consultation.emit(element)"
            role="button"
            class="clickable-cell"
          >
            {{ element.problemDescription }}
          </td>
        </ng-container>
        <ng-container matColumnDef="startDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            tabindex="-1"
            role="button"
          >
            {{ t("tableHeaders.startDate") }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="consultation.emit(element)"
            role="button"
            class="clickable-cell"
          >
            <!-- TODO localizedDate?? -->
            {{ element.startDate | date : "dd/MM/yyyy hh:mm" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="problemType">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            tabindex="-1"
            role="button"
          >
            {{ t("tableHeaders.problemType") }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="consultation.emit(element)"
            class="clickable-cell"
            role="button"
          >
            {{
              element.problemClass
                ? t("problemType." + element.problemClass)
                : "?"
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="userInsertId">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            tabindex="-1"
            role="button"
          >
            {{ t("tableHeaders.userInsertId") }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="consultation.emit(element)"
            class="clickable-cell"
            role="button"
          >
            {{ userShownName(element.createdBy) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef tabindex="-1">
            {{ t("tableHeaders.duration") }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="consultation.emit(element)"
            class="clickable-cell durationItem"
            role="button"
          >
            <natea-icons
              class="icon clock-icon"
              name="clock"
              *ngIf="element.endDate"
            ></natea-icons>
            {{ problemDuration(element) | async }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th
            class="row-actions"
            mat-header-cell
            *matHeaderCellDef
            tabindex="-1"
          ></th>
          <td
            class="row-actions"
            mat-cell
            *matCellDef="let element"
            tabindex="-1"
          >
            <button type="button" class="problem-action" (click)="info.emit()">
              <natea-icons class="icon" name="circle-info"></natea-icons>
            </button>
            <button
              type="button"
              class="problem-action"
              (click)="edit.emit(element)"
              [disabled]="!element.editableFromLoggedUser"
            >
              <natea-icons class="icon" name="edit"></natea-icons>
            </button>
            <button
              type="button"
              class="problem-action"
              [disabled]="!element.cancelableFromLoggedUser"
              (click)="deleteProblem.emit(element)"
            >
              <natea-icons class="icon" name="bin"></natea-icons>
            </button>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="table-header-row"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <natea-cc-more-items-pill
        [visible]="isOverflowing"
      ></natea-cc-more-items-pill>
    </div>
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'common'">
  <div *ngIf="!isAnyData && isAppliedFilter" class="empty">
    {{ t("noResults") }}
  </div>
  <div *ngIf="!isAppliedFilter" class="empty">
    {{ t("empty") }}
  </div>
</ng-container>
