import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, delay, of } from 'rxjs';
import { TherapyRepetition } from '../../../features/therapies/common/entities/therapy-repetition';
import { therapyRepetitionsMock } from '../../../features/therapies/common/mocks/therapy.mock';
import { nutritionalCategoriesMap } from '../../../features/therapies/nutritional-therapies/utils/nutritional-therapies-utils';
import { NutritionalCategoriesDomainMap } from '../store/domains.reducer';
import { CommonDomaine } from '../interface/common-domaine.interface';
import { commonDomaine } from '../mocks/common-domaine-mock';

@Injectable({
  providedIn: 'root',
})
export class DomainsWebAPI {
  public getDomains(): Observable<GetDomainsResponse> {
    return of({
      therapyRepetitions: therapyRepetitionsMock,
      nutritionalCategoriesMap: nutritionalCategoriesMap(this.translocoService),
      commonDomaine,
    }).pipe(delay(10));
  }

  constructor(private translocoService: TranslocoService) {}
}

export interface GetDomainsResponse {
  therapyRepetitions: TherapyRepetition[];
  nutritionalCategoriesMap: NutritionalCategoriesDomainMap;
  commonDomaine: CommonDomaine;
}
