<div
  *ngFor="let dot of dots"
  class="symbol dot"
  [style.left.%]="dot.position"
  [style.--symbol-depth]="dot.depth"
  role="button"
  (click)="onItemClicked(dot.data)"
>
  <natea-icons
    *ngIf="dot.icon"
    class="icon"
    [name]="dot.icon.name"
    [class]="dot.icon.state"
  ></natea-icons>
</div>

<span
  *ngFor="let dot of dots"
  class="time"
  [class.above]="dot.hasTimeLabelAbove"
  [style.left.%]="dot.position"
  [style.--symbol-depth]="dot.depth"
  >{{ dot.datetime | date : "HH:mm" }}</span
>

<div
  *ngFor="let line of flowLines"
  class="symbol flow-line"
  [style.left.%]="line.startPosition"
  [style.width.%]="line.width"
  [style.--symbol-depth]="line.depth"
  role="button"
  (click)="onItemClicked(line.data)"
></div>

<div
  *ngFor="let infiniteLine of infiniteFlowLines"
  class="symbol infinite-flow-line"
  [style.left.%]="infiniteLine.startPosition"
  [style.--symbol-depth]="infiniteLine.depth"
></div>

<div
  *ngFor="let line of singleLines"
  class="symbol single-line"
  [style.left.%]="line.startPosition"
  [style.width.%]="line.width"
  [style.--symbol-depth]="line.depth"
></div>

<div
  *ngFor="let line of rangeLines"
  class="symbol range-line"
  [style.left.%]="line.startPosition"
  [style.width.%]="line.width"
  [style.--symbol-depth]="line.depth"
></div>

<div class="warning" [class.hidden]="!showWarning"></div>
