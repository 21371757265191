import { NutritionalTherapyTableOccurrence } from '../components/nutritional-therapies-table/nutritional-therapies-table.component';
import {
  NutritionalTherapy,
  NutritionalTherapyOccurrence,
} from '../entities/nutritional-therapy';

export const mapNutritionalTherapiesOccurrenceToTableItems = (
  occurrence: NutritionalTherapyOccurrence,
  therapy: NutritionalTherapy
): NutritionalTherapyTableOccurrence => ({
  therapyId: occurrence.prescriptionId,
  id: occurrence.id,
  programmingDate: occurrence.programmingDate,
  typology: therapy.category,
  feedType: therapy.category === 'enteral' ? therapy.feedingType.name : '',
  state: occurrence.state,
  durationType: occurrence.durationType,
  prescriptionDate: therapy.prescriptionDate,
  executionDate: occurrence.executionDate,
  author: therapy.createdBy,
});

export const mapNutritionalTherapyPrescriptionToTableItems = (
  therapy: NutritionalTherapy,
  occurrence: NutritionalTherapyOccurrence
): NutritionalTherapyTableOccurrence => ({
  therapyId: therapy.id,
  id: therapy.id,
  programmingDate: therapy.programmingDate,
  typology: therapy.category,
  feedType: therapy.category === 'enteral' ? therapy.feedingType.name : '',
  state: occurrence.state,
  durationType: occurrence.durationType,
  prescriptionDate: therapy.prescriptionDate,
  executionDate: occurrence.executionDate,
  author: therapy.createdBy,
});
