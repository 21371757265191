import { TranslocoService } from '@ngneat/transloco';
import { OptionsItem } from 'natea-components';
import { DomainItem } from '../../../../shared/entities/domain-item';
import {
  BreastMilkType,
  FeedingMethod,
  FeedingType,
  MilkBrand,
  MilkFormulaMotivation,
  NutritionalCategory,
  NutritionalParenteralBag,
  NutritionalParenteralRepetition,
  NutritionalSupplementType,
} from '../entities/nutritional-therapy-type';

/****************** OPTION ITEMS ******************/

export const optionItemFromFeedingType = (
  feedingType: FeedingType
): OptionsItem<FeedingType> => ({
  id: feedingType.id,
  label: feedingType.name,
  data: feedingType,
});

export const optionItemFromBreastMilkType = (
  breastMilkType: BreastMilkType
): OptionsItem<BreastMilkType> => ({
  id: breastMilkType.id,
  label: breastMilkType.name,
  data: breastMilkType,
});

export const optionItemFromMilkBrand = (
  milkBrand: MilkBrand
): OptionsItem<MilkBrand> => ({
  id: milkBrand.id,
  label: milkBrand.name,
  data: milkBrand,
});

export const optionItemFromMilkFormulaMotivation = (
  milkFormulaMotivation: MilkFormulaMotivation
): OptionsItem<MilkFormulaMotivation> => ({
  id: milkFormulaMotivation.id,
  label: milkFormulaMotivation.name,
  data: milkFormulaMotivation,
});

export const optionItemFromFeedingMethod = (
  feedingMethod: FeedingMethod
): OptionsItem<FeedingMethod> => ({
  id: feedingMethod.id,
  label: feedingMethod.name,
  data: feedingMethod,
});

export const optionItemFromSupplementType = (
  supplementType: NutritionalSupplementType
): OptionsItem<NutritionalSupplementType> => ({
  id: supplementType.id,
  label: supplementType.name,
  data: supplementType,
});

export const optionItemFromParenteralRepetition = (
  repetition: NutritionalParenteralRepetition
): OptionsItem<NutritionalParenteralRepetition> => ({
  id: repetition.id,
  label: repetition.name,
  data: repetition,
});

export const optionItemFromParenteralBag = (
  bag: NutritionalParenteralBag
): OptionsItem<NutritionalParenteralBag> => ({
  id: bag.id,
  label: bag.name,
  data: bag,
});

/****************** CATEGORIES MAP ******************/

export const nutritionalCategoriesMap = (
  translocoService: TranslocoService
): Map<NutritionalCategory, DomainItem> => {
  const nutritionalCategories = new Map<NutritionalCategory, DomainItem>();

  nutritionalCategories.set('enteral', {
    key: 'enteral',
    label: translocoService.translate('therapies.nutritional.enteral'),
  });

  nutritionalCategories.set('parenteral', {
    key: 'parenteral',
    label: translocoService.translate('therapies.nutritional.parenteral'),
  });

  return nutritionalCategories;
};
