import { NateaIconsName } from 'natea-components';

export enum MultisectionItemIconState {
  Neutral = 'neutral',
  Success = 'success',
  Error = 'error',
}

export interface MultisectionItemIconConfig {
  name: NateaIconsName;
  state?: MultisectionItemIconState;
  isImportant?: boolean;
}

export enum ProcedureOccurrenceDurationType {
  Instant = 'instant',
  Prolonged = 'prolonged',
  Recurring = 'recurring',
}

// Occurrence happening in a single instant (eg: an injection)
export interface MultisectionTimelineInstantOccurrence<T = void> {
  id: string;
  type: ProcedureOccurrenceDurationType.Instant;
  rangeStartDate?: Date;
  rangeEndDate?: Date;

  executionDate: Date;
  terminationDate?: Date;

  icon?: MultisectionItemIconConfig;

  data?: T;
}

// Occurrence happening in a single instant (eg: a drip)
export interface MultisectionTimelineProlongedOccurrence<T = void> {
  id: string;
  type: ProcedureOccurrenceDurationType.Prolonged;
  rangeStartDate?: Date;
  rangeEndDate?: Date;

  executionDate: Date;
  terminationDate?: Date;

  startIcon?: MultisectionItemIconConfig;
  endIcon?: MultisectionItemIconConfig;

  data?: T;
}

export interface MultisectionTimelineRecurringOccurrence<T = void> {
  type: ProcedureOccurrenceDurationType.Recurring;
  rangeStartDate?: Date;
  rangeEndDate?: Date;
  occurrences: (
    | MultisectionTimelineInstantOccurrence<T>
    | MultisectionTimelineProlongedOccurrence<T>
  )[];
}

// Every occurrence of an instance of the multisection timeline
export type MultisectionTimelineOccurrence<T = void> =
  | MultisectionTimelineInstantOccurrence<T>
  | MultisectionTimelineProlongedOccurrence<T>
  | MultisectionTimelineRecurringOccurrence<T>;

// Every row of the multisection timeline
export interface MultiSectionTimelineInstance<T = void> {
  id: string;
  label: string;
  occurrences: MultisectionTimelineOccurrence<T>[];
  iconName?: NateaIconsName;
}
