<ng-container *transloco="let t; read: 'problems'">
  <h2 class="add-margin">{{ t("title") }}</h2>
  <natea-button
    class="new-problem-button"
    size="large"
    [label]="t('createNewButton')"
    (buttonClick)="onCreateNewProblem()"
  ></natea-button>

  <div class="divider"></div>

  <natea-switch
    leftIcon="timeline"
    rightIcon="list"
    size="large"
    [selectedItem]="selectedSection"
    (selectedIndexChange)="onSelectedSectionChanged($event)"
  ></natea-switch>

  <div class="divider"></div>

  <natea-icons
    name="arrow-down"
    class="icon expand-icon"
    [class.expanded]="isExpanded"
    (iconClick)="toggleExpanded()"
  ></natea-icons>

  <div class="tablet-break"></div>

  <div class="second-row-tablet" [class.compressed]="!isExpanded">
    <natea-date-picker
      (dateChange)="onDatePicked($event)"
      [placeholder]="t('moveToDate')"
      [minDate]="startDate ?? null"
      [maxDate]="endDate ?? null"
      [hintLabel]="undefined"
    ></natea-date-picker>
    <div class="divider" *ngIf="!isDesktopSized"></div>
    <natea-icons
      *ngIf="!isDesktopSized"
      name="circle-info"
      class="icon"
      (iconClick)="showInfo.emit()"
    >
    </natea-icons>

    <div class="divider" *ngIf="isDesktopSized"></div>

    <natea-icons
      *ngIf="isDesktopSized"
      name="circle-info"
      class="icon info-icon"
      (iconClick)="showInfo.emit()"
    ></natea-icons>
  </div>
</ng-container>
