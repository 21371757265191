import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { ApiError } from '../../../../shared/entities/errors';
import { ClinicalNote } from '../../interfaces/clinical-note';
import { NewNoteFormValues } from '../../interfaces/new-note-form';

export enum NotesValidationActions {
  SortChanged = '[Notes Validation] Sort Changed',
  ShowValidationClinicalNoteModal = '[Notes Validation] Show Validation ClinicalNote Modal',
  ShowValidatedAndArchivedClinicalNoteModal = '[Notes Validation] Show Validated And Archived ClinicalNote Modal',
  HideValidationClinicalNoteModal = '[Notes Validation] Hide Validation ClinicalNote Modal',
  ShowViewValidationClinicalNoteModal = '[Notes Validation] Show View Validation ClinicalNote Modal',
  ShowEditValidationClinicalNoteModal = '[Notes Validation] Show Edit Validation ClinicalNote Modal',
  EvaluateClinicalNote = '[Notes Validation] Evaluate ClinicalNote',
  ValidateClinicalNoteSuccess = '[Notes Validation] Validate ClinicalNote Success',
  ValidateClinicalNoteFailure = '[Notes Validation] Validate ClinicalNote Failure',
  ValidateClinicalNoteSavePressed = '[Notes Validation] Validate ClinicalNote Save Pressed',
  HideLinkedNote = '[Notes Validation] Hide Linked Note',
}

export const sortNotesValidation = createAction(
  NotesValidationActions.SortChanged,
  props<{ sort?: Sort }>()
);

export const showValidationClinicalNoteModal = createAction(
  NotesValidationActions.ShowValidationClinicalNoteModal,
  props<{ clinicalNoteToValidate: ClinicalNote }>()
);

export const showViewValidationClinicalNoteModal = createAction(
  NotesValidationActions.ShowViewValidationClinicalNoteModal,
  props<{ clinicalNoteToView: ClinicalNote }>()
);

export const hideValidationClinicalNoteModal = createAction(
  NotesValidationActions.HideValidationClinicalNoteModal
);

export const showEditValidationClinicalNoteModal = createAction(
  NotesValidationActions.ShowEditValidationClinicalNoteModal,
  props<{ clinicalNoteToEdit: ClinicalNote }>()
);

export const evaluateClinicalNote = createAction(
  NotesValidationActions.EvaluateClinicalNote,
  props<{ clinicalNoteToEvaluate: NewNoteFormValues }>()
);

export const validateClinicalNoteSuccess = createAction(
  NotesValidationActions.ValidateClinicalNoteSuccess,
  props<{
    clinicalNoteToValidate: ClinicalNote;
    isNoteValidated: boolean;
    patientId: string;
    encounterId: string;
  }>()
);

export const validateClinicalNoteSavePressed = createAction(
  NotesValidationActions.ValidateClinicalNoteSavePressed
);

export const validateClinicalNoteFailure = createAction(
  NotesValidationActions.ValidateClinicalNoteFailure,
  props<{ error: ApiError }>()
);

export const showValidatedAndArchivedClinicalNoteModal = createAction(
  NotesValidationActions.ShowValidatedAndArchivedClinicalNoteModal,
  props<{ validatedNote: ClinicalNote }>()
);

export const validationHideLinkedNote = createAction(
  NotesValidationActions.HideLinkedNote
);
