import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService } from 'natea-components';
import { catchError, combineLatestWith, exhaustMap, map, of } from 'rxjs';
import { ApiError, errorMessage } from '../../../shared/entities/errors';
import { SNACK_BAR_DEBOUNCE_TIME_MS } from '../../../shared/utils/constants';
import { selectOngoingEncounterAndPatientIds } from '../../patients/store/patients.selectors';
import { selectCanUserValidateNotes } from '../../users/store/users.selectors';
import { ClinicalNote } from '../interfaces/clinical-note';
import { ClinicalNotesWebAPI } from '../webapis/clinical-notes.webapi';
import { ClinicalNoteActions } from './clinical-note.actions';

@Injectable()
export class ClinicalNoteEffects {
  loadAllClinicalNotes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClinicalNoteActions.LoadAllClinicalNotes),
      exhaustMap(
        ({
          patientId,
          encounterId,
        }: {
          patientId: string;
          encounterId: string;
        }) =>
          this.clinicalNotesWebAPI
            .getNotesForPatient(patientId, encounterId)
            .pipe(
              map((notes) => ({
                type: ClinicalNoteActions.LoadAllClinicalNotesSuccess,
                notes,
                patientId,
                encounterId,
              })),
              catchError((error: ApiError) =>
                of({
                  type: ClinicalNoteActions.LoadAllClinicalNotesFailure,
                  error,
                  patientId,
                  encounterId,
                })
              )
            )
      )
    );
  });

  loadClinicalNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClinicalNoteActions.LoadClinicalNote),
      exhaustMap(({ cliniaclNoteId }: { cliniaclNoteId: string }) =>
        this.clinicalNotesWebAPI.getClinicalNotebyId(cliniaclNoteId).pipe(
          map((note) => ({
            type: ClinicalNoteActions.LoadClinicalNoteSuccess,
            note,
          })),
          catchError((error: ApiError) =>
            of({
              type: ClinicalNoteActions.LoadClinicalNoteFailure,
              payload: { error },
            })
          )
        )
      )
    );
  });

  createClinicalNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClinicalNoteActions.CreateClinicalNote),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      exhaustMap(([{ note }, onGoingEncounterIds]) => {
        const patientId = onGoingEncounterIds?.patientId;
        const encounterId = onGoingEncounterIds?.encounterId;
        return this.clinicalNotesWebAPI
          .createClinicalNote(note, patientId, encounterId)
          .pipe(
            map((resultNote: ClinicalNote) => {
              return {
                type: ClinicalNoteActions.CreateClinicalNoteSuccess,
                note: resultNote,
                patientId,
                encounterId,
              };
            }),

            catchError((error: ApiError) => {
              return of({
                type: ClinicalNoteActions.CreateClinicalNoteFailure,
                payload: { error },
              });
            })
          );
      })
    );
  });

  updateClinicalNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClinicalNoteActions.UpdateClinicalNote),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      exhaustMap(([{ note }, onGoingEncounterIds]) =>
        this.clinicalNotesWebAPI.updateClinicalNote(note).pipe(
          map((newNote: ClinicalNote) => {
            return {
              type: ClinicalNoteActions.UpdateClinicalNoteSuccess,
              note: newNote,
              patientId: onGoingEncounterIds?.patientId,
              encounterId: onGoingEncounterIds?.encounterId,
            };
          }),
          catchError((error: ApiError) => {
            return of({
              type: ClinicalNoteActions.UpdateClinicalNoteFailure,
              payload: { error },
            });
          })
        )
      )
    );
  });

  deleteClinicalNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClinicalNoteActions.DeleteClinicalNote),
      exhaustMap(
        ({ patientId, problemId }: { patientId: string; problemId: string }) =>
          this.clinicalNotesWebAPI
            .deleteClinicalNote(patientId, problemId)
            .pipe(
              map((problemId: string) => {
                this.snackBar.showSnackbar(
                  this.translocoService.translate(
                    'clinicalNote.successFullyDeleted'
                  ),
                  this.translocoService.translate('clinicalNote.close'),
                  'success-snackbar',
                  SNACK_BAR_DEBOUNCE_TIME_MS
                );

                return {
                  type: ClinicalNoteActions.DeleteClinicalNoteSuccess,
                  problemId,
                };
              }),
              catchError((error: ApiError) => {
                this.snackBar.showSnackbar(
                  errorMessage(error) ??
                    this.translocoService.translate('common.errors.generic'),
                  this.translocoService.translate('clinicalNote.close'),
                  'error-snackbar',
                  SNACK_BAR_DEBOUNCE_TIME_MS
                );
                return of({
                  type: ClinicalNoteActions.DeleteClinicalNoteFailure,
                  payload: { error },
                });
              })
            )
      )
    );
  });

  saveClinicalNoteFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ClinicalNoteActions.CreateClinicalNoteFailure),
        map((error: ApiError) => {
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate('clinicalNote.errorCreating'),
            this.translocoService.translate('clinicalNote.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  updateClinicalNoteFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ClinicalNoteActions.UpdateClinicalNoteFailure),
        map((error) => {
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate('clinicalNote.errorUpdating'),
            this.translocoService.translate('clinicalNote.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  updateClinicalNoteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ClinicalNoteActions.UpdateClinicalNoteSuccess),
        map((clinicalNote: { type: string; note: ClinicalNote }) => {
          const date = this.datePipe.transform(
            clinicalNote.note.observationDate,
            'dd/MM/yyyy - HH:mm'
          );
          this.snackBar.showSnackbar(
            this.translocoService.translate(
              'clinicalNote.successFullyUpdated',
              { date }
            ),
            this.translocoService.translate('clinicalNote.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  deleteClinicalNoteFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ClinicalNoteActions.DeleteClinicalNoteFailure),
        map((error) => {
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate('clinicalNote.errorDeleting'),
            this.translocoService.translate('clinicalNote.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  createClinicalNoteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ClinicalNoteActions.CreateClinicalNoteSuccess),
        combineLatestWith(this.store.select(selectCanUserValidateNotes)),
        map(([{ note }, canUserValidateNotes]) => {
          if (canUserValidateNotes) {
            this.snackBar.showSnackbar(
              this.translocoService.translate(
                'clinicalNote.successFullyCreated'
              ),
              this.translocoService.translate('clinicalNote.close'),
              'success-snackbar',
              SNACK_BAR_DEBOUNCE_TIME_MS
            );
          } else {
            const clinicalNote = note as ClinicalNote;

            const date = new Date(clinicalNote.observationDate);
            const formattedDate = this.datePipe.transform(
              date,
              'dd/MM/yyyy - HH:mm'
            );

            this.snackBar.showSnackbar(
              this.translocoService.translate(
                'clinicalNote.successStudentDoctorCreateNote',
                {
                  date: formattedDate,
                }
              ),
              this.translocoService.translate('clinicalNote.close'),
              'warning-snackbar',
              SNACK_BAR_DEBOUNCE_TIME_MS
            );
          }
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private clinicalNotesWebAPI: ClinicalNotesWebAPI,
    private snackBar: SnackbarService,
    private translocoService: TranslocoService,
    private store: Store,
    private datePipe: DatePipe
  ) {}
}
