import { EncounterResource } from '../../../core/generated/api';
import { SearchPatientResultInterface } from '../../../shared/components/modals/search-patients-modal/interface/search-result';
import { Patient } from '../../../shared/entities/patient';
import { parseDateTimeFromServer } from '../../../shared/utils/adapter-utils';

export class PatientAdapters {
  public static patientToSearchPatient(
    patient: Patient[]
  ): SearchPatientResultInterface[] {
    const result: SearchPatientResultInterface[] = patient.map((patient) => {
      return {
        id: patient.id,
        departmentId: patient.departmentId,
        name: patient.firstName + ' ' + patient.lastName,
        birthDate: patient.birthDate,
        parent: patient.parent,
        gestationalAge: patient.gestationalAge,
        weight: patient.weight,
      };
    });

    return result;
  }

  public static patientFromEncounterResource(
    encounter: EncounterResource
  ): Patient {
    return {
      id: encounter.patient.id,
      departmentId: encounter.transfer.departmentId,
      firstName: encounter.patient.firstName,
      lastName: encounter.patient.lastName,
      birthDate: parseDateTimeFromServer(encounter.patient.birthDate),
      currentBed: encounter.transfer.place,
      parent: {
        id: encounter.parent.id,
        firstName: encounter.parent.firstName,
        lastName: encounter.parent.lastName,
        birthDate: parseDateTimeFromServer(encounter.parent.birthDate),
      },
    };
  }
}

//   public static ClinicalNoteFeToBe(problem: ClinicalNote): ProblemDetected {
//     return {
//       id: problem?.id,
//       patientId: problem.patientId,
//       encounterId: problem.encounterId,
//       problemId: problem.problemId,
//       problemDescription: problem.problemDescription,
//       problemClass: problem.problemClass,
//       startDate: problem.startDate
//         ? problem.startDate.toISOString()
//         : undefined,
//       endDate: problem.endDate ? problem.endDate.toISOString() : undefined,
//       note: problem.note,
//       userInsId: problem.userInsertId,
//       userUpdId: problem.userUpdateId,
//       insDate: problem.insertDate
//         ? problem.insertDate.toISOString()
//         : undefined,
//       updDate: problem.updateDate
//         ? problem.updateDate.toISOString()
//         : undefined,
//     };
//   }
