<ng-container *ngrxLet="selectedSection$ as selectedSection">
  <ng-container *ngrxLet="shownEncounter$ as shownEncounter">
    <natea-cc-base-page *transloco="let t; read: 'problems.patientProblem'">
      <natea-cc-sub-header sub-header></natea-cc-sub-header>
      <natea-cc-problems-section-header
        header-content
        [selectedSection]="
          selectedSection === ProblemsVisualization.Timeline ? 'left' : 'right'
        "
        [startDate]="shownEncounter?.startDate"
        [endDate]="shownEncounter?.endDate ?? now()"
        (toggleSection)="switchVisual()"
        (addProblem)="onCreateProblem()"
        (moveToDate)="moveToDate($event)"
        (showInfo)="showLegendModal()"
      ></natea-cc-problems-section-header>

      <div main-content class="problem-main-content">
        <natea-folder-tab
          [isMainContentScrollable]="false"
          [tabs]="((encounterTabs$ | async) || {}).tabs ?? []"
          [activeIndex]="((encounterTabs$ | async) || {}).activeIndex ?? null"
          (tabSelected)="onSelectedEncounter($event)"
          (tabClosed)="onClosedEncounter($event)"
        >
          <natea-cc-problems-filter filter></natea-cc-problems-filter>
          <div
            class="main-container"
            [class.not-data-to-show]="
              canAddPaddingBottomToProblemContainer ||
              (isFilterNotApplied$ | async)
            "
          >
            <natea-cc-problems-timeline
              *ngIf="selectedSection === ProblemsVisualization.Timeline"
              [startDate]="shownEncounter?.startDate"
              [endDate]="shownEncounter?.endDate"
              [problems]="(filteredProblems$ | async) ?? []"
              [selectedDate]="dateChangedSubject"
              (deleteProblem)="onDeleteProblem($event)"
              [isAppliedFilter]="(isAppliedFilter$ | async) ?? false"
              (edit)="onEditProblem($event)"
              (consultation)="onConsultationProblem($event)"
              (canAddPaddingBottomToProblemContainer)="
                canAddPaddingBottomToProblemContainer = $event
              "
            ></natea-cc-problems-timeline>

            <natea-cc-problems-table
              *ngIf="selectedSection === ProblemsVisualization.Table"
              [problems]="(sortedProblems$ | async) ?? []"
              [isAppliedFilter]="(isAppliedFilter$ | async) ?? false"
              (deleteProblem)="onDeleteProblem($event)"
              (edit)="onEditProblem($event)"
              (consultation)="onConsultationProblem($event)"
              (sortChanged)="onSortChanged($event)"
            ></natea-cc-problems-table>
          </div>
        </natea-folder-tab>

        <ng-container modals>
          <ng-container *transloco="let legendT; read: 'problems.legend'">
            <natea-cc-legend-modal
              [title]="legendT('legendTitle')"
              [dataSource]="legendData"
              [isTextAndIcon]="true"
              *ngIf="showProblemLegend$ | async"
              (OnClose)="closeLegendModal()"
            ></natea-cc-legend-modal>
          </ng-container>

          <natea-cc-problem-insert-new-problem-modal
            *ngIf="showProblemModal$ | async"
            [title]="t('newProblem')"
            (closeModal)="cancel($event)"
            (saveNewProblem)="onSaveNewProblem($event)"
            (updateProblem)="onUpdateProblem($event)"
            (copyBoard)="onCopyBoard($event)"
            (logProblem)="onLogProblem($event)"
          ></natea-cc-problem-insert-new-problem-modal>

          <natea-cc-delete-problem-modal
            *ngIf="problemToDelete$ | async as problemToDelete"
            [showModal]="!!problemToDelete"
            [title]="t('deleteProblem')"
            [problem]="problemToDelete"
            [paziente]="(selectedPatient$ | async) ?? undefined"
            [isDeletingProblem]="(isDeletingProblem$ | async) ?? false"
            (closeModal)="onDeleteProblemAborted()"
            (deleteAndCloseModal)="deleteProblemConfirmed(problemToDelete)"
          >
          </natea-cc-delete-problem-modal>
          <natea-cc-confirmation-exit-without-saving
            (cancel)="isFormTouchedAndDuty = false"
            (confirmDelete)="confirmDelete()"
            [message]="t('formInterruptionConfirmMessage')"
            *ngIf="isFormTouchedAndDuty"
          >
          </natea-cc-confirmation-exit-without-saving>

          <natea-full-page-loader
            *ngIf="(isLoadingData$ | async) ?? false"
          ></natea-full-page-loader>

          <ng-container *ngIf="showLog$ | async as log">
            <natea-cc-log
              [logDate]="log"
              (closeLog)="closeLog()"
              [logTitle]="logTitle"
            ></natea-cc-log>
          </ng-container>
        </ng-container>
      </div>
    </natea-cc-base-page>
  </ng-container>
</ng-container>
