import { Injectable, inject } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { TranslocoService } from '@ngneat/transloco';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class CustomDateAdapterService extends NativeDateAdapter {
  private translate: TranslocoService = inject(TranslocoService);
  private readonly parseFormat: string = this.translate.translate(
    'common.dateParseFormat'
  );

  override format(date: Date, displayFormat: Object): string {
    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }

  override parse(value: any): Date | null {
    if (typeof value == 'number') {
      return new Date(value);
    }

    if (!value) {
      return null;
    }

    return DateTime.fromFormat(value, this.parseFormat).toJSDate();
  }
}
