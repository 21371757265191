import { createSelector } from '@ngrx/store';
import { ClinicalNote } from '../../../../clinical-note/interfaces/clinical-note';
import {
  selectOngoingEncounter,
  selectShownEncounter,
} from '../../patients.selectors';

export const selectShownEncounterNotes = createSelector(
  selectShownEncounter,
  (encounter): ClinicalNote[] | undefined => encounter?.notes
);

export const selectOngoingEncounterNotes = createSelector(
  selectOngoingEncounter,
  (encounter): ClinicalNote[] | undefined => encounter?.notes
);

export const selectShownEncounterNotesToApprove = createSelector(
  selectShownEncounterNotes,
  // TODO will probably be refactored after story refinement
  (notes): ClinicalNote[] | undefined =>
    notes?.filter((note) => note.isApproved === undefined)
);

export const selectOngoingEncounterNotesToApprove = createSelector(
  selectOngoingEncounterNotes,
  // TODO will probably be refactored after story refinement
  (notes): ClinicalNote[] | undefined =>
    notes?.filter((note) => note.isApproved === undefined)
);

export const selectShownEncounterEvaluatedNotes = createSelector(
  selectShownEncounterNotes,
  // TODO will surely be refactored after story refinement
  (notes): ClinicalNote[] | undefined =>
    notes?.filter((note) => note.isApproved !== undefined)
);
