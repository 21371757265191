import {
  CreateItemRequest,
  GeneralResponseStoreItemResource,
} from '../generated/api';

const set = new Set<number>();

export function generateRandomNumber(): number {
  const min = 1;
  const max = 10000;

  const generatedNumber = Math.floor(Math.random() * (max - min + 1)) + min;

  while (set.has(generatedNumber)) {
    return generateRandomNumber();
  }

  set.add(generatedNumber);

  return generatedNumber;
}

const createNewItemStorageMocked = (
  userId: string,
  objectType: string,
  createItemRequest: CreateItemRequest
): GeneralResponseStoreItemResource => {
  return {
    payload: {
      id: `${generateRandomNumber()}`,
      createdAt: new Date().toISOString(),
      item: createItemRequest.item,
      itemVersion: createItemRequest.itemVersion,
    },
  };
};

export default createNewItemStorageMocked;
