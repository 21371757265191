import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import {
  ProcedureMovementFormData,
  ProceduresProgrammingFormData,
} from '../components/procedures-detail-form/form-data/procedures-detail-form-data';
import {
  ProcedureDevice,
  ProcedureLocation,
  ProcedureRoute,
} from '../entities/procedure-data';
import { ProcedureOccurrenceMovement } from '../entities/procedure-movement';
import { ProcedureOccurrence } from '../entities/procedure-occurrence';
import {
  mockDevices,
  mockLocations,
  mockRoutes,
} from '../mocks/procedures-data.mock';
import {
  mockNewMovement,
  mockNewOccurrence,
} from '../mocks/procedures-list.mock';

@Injectable({
  providedIn: 'root',
})
export default class ProceduresWebApi {
  constructor(private httpClient: HttpClient) {}

  public createProcedureOccurrence({
    patientId,
    encounterId,
    formData,
  }: {
    patientId: string;
    encounterId: string;
    formData: ProceduresProgrammingFormData;
  }): Observable<ProcedureOccurrence> {
    return of(mockNewOccurrence(formData)).pipe(delay(1000));
  }

  public createProcedureMovement(
    occurrenceId: string,
    formData: ProcedureMovementFormData
  ): Observable<ProcedureOccurrenceMovement> {
    return of(mockNewMovement(formData)).pipe(delay(1000));
  }

  public updateProcedureMovement(
    movementId: string,
    formData: ProcedureMovementFormData
  ): Observable<ProcedureOccurrenceMovement> {
    return of(mockNewMovement(formData));
  }

  public deleteProcedureMovement(movementId: string): Observable<boolean> {
    return of(true).pipe(delay(1000));
  }

  public loadDevices(): Observable<ProcedureDevice[]> {
    return of(mockDevices()).pipe(delay(300));
  }

  public loadRoutes(): Observable<ProcedureRoute[]> {
    return of(mockRoutes()).pipe(delay(300));
  }

  public loadLocations(): Observable<ProcedureLocation[]> {
    return of(mockLocations()).pipe(delay(300));
  }
}
