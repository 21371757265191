/*****
const firstEncounterDay: number = -10;

export function DEMO_firstEncounterDay(): number {
  if (DEMO_useMockData() == true) {
    return firstEncounterDay
  }
  else {
    return 0
  }
}

export function DEMO_useMockData(): boolean {
  return true
}
*****/

export function mockEncounterDay(encounterDay: number = 0, hours: number = 0, minutes: number = 0): Date {
  let pastDate = new Date();
  // Il ricovero è 10 giorni indietro
  pastDate.setDate(pastDate.getDate() - 10 + encounterDay);
  pastDate.setHours(hours, minutes)
  return(pastDate);
}
// RLE
