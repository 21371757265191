import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormControlStatus,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import {
  ButtonVariants,
  NateaIconsName,
  OptionsItem,
  SizeType,
  matchMediaDesktopSize,
} from 'natea-components';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest,
  debounceTime,
  map,
  take,
} from 'rxjs';
import { ProfessionalCategory } from '../../../../shared/entities/professional-category';
import { User, UserRole } from '../../../../shared/entities/user';
import { DEBOUNCE_TIME_MS } from '../../../../shared/utils/constants';
import {
  optionItemFromEntityClass,
  optionItemFromProblemType,
  problemClassFromUserRole,
} from '../../../../shared/utils/optional-item-entity-utils';
import { optionItemFromUser } from '../../../../shared/utils/utils';
import { selectIsCopyToClipboardRunning } from '../../../board/store/board.selectors';
import { selectLoggedUser } from '../../../users/store/users.selectors';
import {
  NewProblemForm,
  ProblemFormData,
} from '../../interfaces/new-problem-form';
import { PatientProblem } from '../../interfaces/patient-problem';
import { ProblemType } from '../../interfaces/problem-type';
import {
  loadAllProblemData,
  loadMoreProblemData,
  problemDeletionRequired,
  reloadAllProblemDataStarted,
} from '../../store/problems.actions';
import { ProblemModalState } from '../../store/problems.reducers';
import {
  selectAllProblemTypesAsOptionItems,
  selectProblemModalState,
  selectProblemsHasMoreProblemData,
  selectProblemsIsCreatingOrEditing,
  selectProblemsIsLoadingProblemData,
} from '../../store/problems.selectors';

@Component({
  selector: 'natea-cc-problem-insert-new-problem-modal',
  templateUrl: './problem-insert-new-problem-modal.component.html',
  styleUrls: ['./problem-insert-new-problem-modal.component.scss'],
})
export class ProblemInsertNewProblemModalComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() problemNameItems: OptionsItem<ProblemType>[] = [];

  @Input() selectedProblem: PatientProblem | null = null;

  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() title = '';

  @Input() isDisabledTextarea = true;

  @Output() saveNewProblem: EventEmitter<ProblemFormData> =
    new EventEmitter<ProblemFormData>();

  @Output() updateProblem: EventEmitter<EditProblemParams> =
    new EventEmitter<EditProblemParams>();

  @Output() cancelNewProblemModal: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() copyBoard: EventEmitter<Partial<PatientProblem>> = new EventEmitter<
    Partial<PatientProblem>
  >();

  @Output() logProblem: EventEmitter<PatientProblem> =
    new EventEmitter<PatientProblem>();

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(): void {
    this.cancelModal();
  }

  isCopyToBoardPress$: Observable<boolean> = this.store.select(
    selectIsCopyToClipboardRunning
  );

  private desktopSizeMatcher: MediaQueryList = matchMediaDesktopSize(window);

  modalDesktopSize: SizeType = SizeType.LARGE;
  modalTabletSize: SizeType = SizeType.XLARGE;
  isDesktopSized = false;
  selectedItemTypology: OptionsItem<string> = { id: '1', label: 'typology1' };

  searchSubject$: Subject<string> = new Subject<string>();
  debouncedSearch$: Observable<string> = this.searchSubject$.pipe(
    debounceTime(DEBOUNCE_TIME_MS)
  );
  debouncedSearchSubscription?: Subscription;

  dropdownItems: OptionsItem<string>[] = [];

  updateEndDate: Date | null = null;

  minEndDate?: Date;

  disabledForm = true;

  private formSubscription?: Subscription;

  createNewProblemForm: FormGroup<NewProblemForm>;

  maxDate: Date = new Date();

  buttonVariants = ButtonVariants;

  isLoadingProblemsData$: Observable<boolean> = this.store.select(
    selectProblemsIsLoadingProblemData
  );

  hasMoreProblemData$: Observable<boolean> = this.store.select(
    selectProblemsHasMoreProblemData
  );

  isCreatingOrUpdatingProblem$: Observable<boolean> = this.store.select(
    selectProblemsIsCreatingOrEditing
  );

  modalState$: Observable<ProblemModalState | undefined> = this.store.select(
    selectProblemModalState
  );

  loggedUser$: Observable<User | undefined> =
    this.store.select(selectLoggedUser);

  problemType$: Observable<OptionsItem<ProblemType>[]> = this.store
    .select(selectAllProblemTypesAsOptionItems)
    .pipe(
      // eslint-disable-next-line @ngrx/avoid-mapping-selectors
      map((list: OptionsItem<ProblemType>[]): OptionsItem<ProblemType>[] => {
        return list;
      })
    );

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private translocoService: TranslocoService
  ) {
    this.createNewProblemForm = this.createInitialForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['selectedProblem']?.currentValue !==
      changes['selectedProblem']?.previousValue
    ) {
      this.minEndDate = this.selectedProblem?.startDate;
    }
  }

  ngOnInit(): void {
    this.initProblemData();
    this.isDesktopSized = this.desktopSizeMatcher.matches;
    this.desktopSizeMatcher.addEventListener(
      'change',
      this.onDesktopSizeChanged
    );

    combineLatest([this.modalState$, this.loggedUser$]).subscribe(
      ([modalState, loggedUser]: [
        ProblemModalState | undefined,
        User | undefined
      ]) => {
        this.onNewState(modalState, loggedUser);
      }
    );
  }

  ngOnDestroy(): void {
    this.desktopSizeMatcher.removeEventListener(
      'change',
      this.onDesktopSizeChanged
    );

    this.formSubscription?.unsubscribe();
    this.debouncedSearchSubscription?.unsubscribe();
  }

  get iconFromUserRole(): NateaIconsName | undefined {
    const role: UserRole | undefined =
      this.createNewProblemForm.controls.userInsertId.value?.data?.role;
    if (!role) {
      return undefined;
    } else if (role === UserRole.Doctor) {
      return 'medico';
    } else {
      return 'infermiere';
    }
  }

  /* ******************* FORM ******************* */

  private onNewState = (
    state: ProblemModalState | undefined,
    loggedUser: User | undefined
  ): void => {
    if (!state || state.mode === 'create') {
      this.createNewProblemForm = this.createInitialForm(loggedUser);
    } else if (state.mode === 'edit' || state.mode === 'view') {
      this.createNewProblemForm = this.createFormFromProblem(
        state.problem,
        state.mode === 'view',
        loggedUser
      );
    }

    this.formSubscription?.unsubscribe();
    this.formSubscription = this.createNewProblemForm.statusChanges.subscribe(
      (status: FormControlStatus) => {
        this.disabledForm = status === 'INVALID' || state?.mode === 'view';
      }
    );

    this.createNewProblemForm.updateValueAndValidity();
  };

  private createInitialForm = (user?: User): FormGroup<NewProblemForm> => {
    const problemClass: ProfessionalCategory | undefined =
      problemClassFromUserRole(user?.role);

    return this.fb.nonNullable.group<NewProblemForm>({
      problemType: new FormControl(null, [Validators.required]),
      problemClass: new FormControl(
        {
          value: problemClass
            ? optionItemFromEntityClass(problemClass, this.translocoService)
            : null,
          disabled: true,
        },
        [Validators.required]
      ), // TODO real problem class
      userInsertId: new FormControl(
        { value: user ? optionItemFromUser(user) : null, disabled: true },
        [Validators.required]
      ), // TODO real user id
      startDate: new FormControl(new Date(), [Validators.required]),
      endDate: new FormControl({ value: null, disabled: true }),
      note: new FormControl(''),
    });
  };

  private createFormFromProblem = (
    problem: PatientProblem,
    isReadonly: boolean,
    loggedUser: User | undefined
  ): FormGroup<NewProblemForm> =>
    this.fb.nonNullable.group<NewProblemForm>({
      userInsertId: new FormControl(
        {
          value: this.insertUser(isReadonly, loggedUser, problem.createdBy),
          disabled: true,
        },
        [Validators.required]
      ),
      problemClass: new FormControl(
        {
          value: optionItemFromEntityClass(
            problem.problemClass,
            this.translocoService
          ),
          disabled: true,
        },
        [Validators.required]
      ),
      problemType: new FormControl(
        {
          value: optionItemFromProblemType(problem.problemType),
          disabled: true,
        },
        [Validators.required]
      ),
      startDate: new FormControl({
        value: problem.startDate,
        disabled: true,
      }),
      endDate: new FormControl({
        value: problem.endDate ?? null,
        disabled: isReadonly,
      }),
      note: new FormControl({
        value: problem.note ?? '',
        disabled: true,
      }),
    });

  /* ******************* CALLBACKS ******************* */

  onCopyBoard = (): void => {
    this.copyBoard.emit(this.getDataObject());
  };

  onStop = (): void => {
    this.updateEndDate = new Date();
    this.createNewProblemForm.controls.endDate.patchValue(this.updateEndDate);
  };

  getDataObject = (): Partial<PatientProblem> => {
    const newProblem: Partial<{
      problemType: OptionsItem<ProblemType> | null;
      userInsertId: OptionsItem<User> | null;
      problemClass: OptionsItem<ProfessionalCategory> | null;
      startDate: Date | null;
      endDate: Date | null;
      note: string | null;
    }> = this.createNewProblemForm.getRawValue();

    return {
      problemType: newProblem.problemType?.data ?? undefined,
      problemClass: newProblem.problemClass?.data ?? undefined,
      createdBy: newProblem.userInsertId?.data ?? undefined,
      startDate: newProblem.startDate
        ? new Date(newProblem.startDate)
        : undefined,
      endDate: newProblem.endDate ? new Date(newProblem.endDate) : undefined,
      note: newProblem.note ?? undefined,
    };
  };

  onSave = (): void => {
    const {
      startDate,
      problemType,
      problemClass,
    }: {
      problemType: OptionsItem<ProblemType> | null;
      problemClass: OptionsItem<ProfessionalCategory> | null;
      startDate: Date | null;
    } = this.createNewProblemForm.getRawValue();

    if (startDate && problemType?.data && problemClass?.data) {
      const formData: ProblemFormData = {
        ...this.createNewProblemForm.value,
        userInsertId: this.createNewProblemForm.value.userInsertId?.data?.id,
        problemClass: problemClass.data,
        problemType: problemType.data,
        startDate: startDate,
      };

      this.modalState$
        .pipe(take(1))
        .subscribe((state: ProblemModalState | undefined) => {
          if (!state) {
            return;
          } else if (state.mode === 'edit') {
            this.updateProblem.emit({
              problemId: state.problem.id,
              formData,
              patientId: state.problem.patientId,
            });
          } else {
            this.saveNewProblem.emit(formData);
          }
        });
    } else {
      this.createNewProblemForm.updateValueAndValidity();
    }
  };

  private onDesktopSizeChanged = (event: MediaQueryListEvent): void => {
    this.isDesktopSized = event.matches;
  };

  /******************* PROBLEM DATA *******************/

  onProblemTypesScrollEndReached = (): void => {
    this.store
      .select(selectProblemsIsLoadingProblemData)
      .pipe(take(1))
      .subscribe((isLoading: boolean) => {
        if (!isLoading) {
          this.store.dispatch(loadMoreProblemData());
        }
      });
  };

  private initProblemData = (): void => {
    this.debouncedSearchSubscription = this.debouncedSearch$.subscribe(
      (searchValue: string) => {
        return this.store.dispatch(
          loadAllProblemData({ searchString: searchValue })
        );
      }
    );
  };

  onProblemTypeSearchStringChanged = (value: string | null): void => {
    this.store.dispatch(reloadAllProblemDataStarted());
    this.searchSubject$.next(value ?? '');
  };

  getNowDate = (): Date => {
    return new Date();
  };

  cancelModal = (): void => {
    const isFormDirty: boolean =
      this.createNewProblemForm.dirty && this.createNewProblemForm.touched;

    this.cancelNewProblemModal.emit(isFormDirty);
    this.closeModal.emit(isFormDirty);
  };

  onProblemDataDescriptionChanged = (searchValue: string): void => {
    this.searchSubject$.next(searchValue);
    this.createNewProblemForm.controls.problemType.patchValue(null);
  };

  onDeleteProblem = (problem: PatientProblem): void => {
    this.store.dispatch(problemDeletionRequired({ problem }));
  };

  onLog = (problem: PatientProblem): void => {
    this.logProblem.emit(problem);
  };

  setSubTitleByMode = (mode: 'view' | 'edit' | 'create'): string => {
    if (mode === 'create') {
      return this.translocoService.translate('problems.newProblemSubTitle');
    } else if (mode === 'edit') {
      return this.translocoService.translate('problems.problemUpdateSubtitle');
    } else {
      return '';
    }
  };

  private insertUser = (
    isViewMode: boolean,
    user?: User,
    creatorNote?: User
  ): OptionsItem<User> | null => {
    if ((isViewMode && !creatorNote) || (!isViewMode && !user)) {
      return null;
    } else if (isViewMode && creatorNote) {
      return optionItemFromUser(creatorNote);
    } else if (user) {
      return optionItemFromUser(user);
    }
    return null;
  };
}

export interface EditProblemParams {
  problemId: string;
  formData: ProblemFormData;
  patientId: string;
}
