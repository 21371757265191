import { CommonModule } from '@angular/common';
import {
  AfterContentChecked,
  Component,
  ElementRef,
  Input,
} from '@angular/core';

export enum TooltipComponentVariant {
  Standard = 'standard',
  Dark = 'dark',
}

export enum TooltipSide {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
}

const parentClass = 'natea-tooltip-container';

@Component({
  selector: 'natea-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class TooltipComponent implements AfterContentChecked {
  TooltipComponentVariant = TooltipComponentVariant;
  TooltipSide = TooltipSide;

  @Input() label = '';
  @Input() variant = TooltipComponentVariant.Standard;
  @Input() side = TooltipSide.Top;

  constructor(private elRef: ElementRef) {}

  ngAfterContentChecked(): void {
    this.elRef.nativeElement.parentElement.classList.add(parentClass);
  }
}
