import { createSelector } from '@ngrx/store';
import { selectShownEncounterTherapiesToConfirm } from '../../../patients/store/clinical-data/therapies/patients-data.therapies-to-confirm.selectors';
import { selectTherapiesConfirmationUiState } from '../../../patients/store/selected-patient/selected-patient.selectors';
import { TherapyToConfirm } from '../entities/therapy-to-confirm';

const selectUiState = selectTherapiesConfirmationUiState;
const selectData = selectShownEncounterTherapiesToConfirm;

export const selectIsLoadingTherapiesToConfirm = createSelector(
  selectUiState,
  (state): boolean => state.isLoadingData
);

export const selectConfirmableTherapies = createSelector(
  selectData,
  (data): TherapyToConfirm[] | undefined =>
    data?.filter((therapy) => therapy.isConfirmable)
);

export const selectTherapiesToConfirmInTheFuture = createSelector(
  selectData,
  (data): TherapyToConfirm[] | undefined =>
    data?.filter((therapy) => !therapy.isConfirmable)
);

export const selectTherapiesBeingConfirmed = createSelector(
  selectUiState,
  (state): string[] => state.therapiesBeingConfirmed
);

export const selectIsConfirmingAllTherapies = createSelector(
  selectUiState,
  (state): boolean => state.isConfirmingAllTherapies
);
