import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService } from 'natea-components';
import { catchError, delay, exhaustMap, map, of, switchMap } from 'rxjs';
import { ApiError, errorMessage } from '../../../shared/entities/errors';
import { AppRoutes } from '../../../shared/routes/routes';
import { SNACK_BAR_DEBOUNCE_TIME_MS } from '../../../shared/utils/constants';
import { EncounterWebApi } from '../../encounters/encounter-webapi/encounter-web-api';
import { encounterSorter } from '../../encounters/utils/encounter-utils';
import { PatientEncounter } from '../../problems/interfaces/patient-encounter';
import { loadAllProblems } from '../../problems/store/problems.actions';
import { hideTherapiesLegendModal } from '../../therapies/common/store/therapies.actions';
import { PatientsWebApi } from '../webapi/patient-web-api';
import { mockPatientsResultList } from './mock/mock-patients';
import {
  PatientActions,
  exportPatientsFailure,
  exportPatientsSuccess,
  goToPatientFailure,
  goToPatientSuccess,
  hideEncounter,
  loadAllPatientsFailure,
  loadAllPatientsSuccess,
  loadEncounters,
  loadEncountersFailure,
  loadEncountersSuccess,
  searchPatientFailure,
  searchPatientSuccess,
  showEncounter,
} from './patients.actions';
import { selectSelectedPatientId } from './patients.selectors';

@Injectable()
export class PatientsEffects {
  loadPatients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatientActions.LoadAll),
      exhaustMap(({ departmentId }: { departmentId: string }) => {
        return this.patientsApi.getPatientsList([departmentId]).pipe(
          map((patients) => {
            return loadAllPatientsSuccess({ patients });
          }),
          catchError((error: ApiError) => of(loadAllPatientsFailure({ error })))
        );
      })
    );
  });

  loadAllPatientsFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PatientActions.LoadAllFail),
        map(({ error }) => {
          this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate('common.errors.generic'),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
          return of(loadAllPatientsFailure({ error }));
        })
      );
    },
    { dispatch: false }
  );

  loadMandatoryData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatientActions.LoadMandatoryData),
      switchMap(({ patientId }: { patientId: string }) => {
        return [loadEncounters({ patientId })];
      })
    );
  });

  loadEncounters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatientActions.LoadEncounters),
      exhaustMap(({ patientId }: { patientId: string }) => {
        return this.encountersApi.getEncountersForPatient(patientId).pipe(
          map((encounters: PatientEncounter[]) => {
            encounters.sort(encounterSorter);
            return loadEncountersSuccess({
              encounters,
              patientId,
            });
          }),
          catchError((error: ApiError) => {
            this.snackBar.showSnackbar(
              errorMessage(error) ??
                this.translocoService.translate('common.errors.generic'),
              this.translocoService.translate('common.buttons.close'),
              'error-snackbar',
              SNACK_BAR_DEBOUNCE_TIME_MS
            );
            return of(
              loadEncountersFailure({
                error,
              })
            );
          })
        );
      })
    );
  });

  /*************** MANDATORY PATIENT DATA ******************
   * Download all patient data that is required to view the patient's
   * section of the application.
   */

  downloadPatientProblems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatientActions.LoadEncountersSuccess),
      switchMap(({ patientId }) => {
        return of(loadAllProblems({ patientId }));
      })
    );
  });

  /*************** END MANDATORY PATIENT DATA ******************/

  showEncounterForSelectedPatient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatientActions.ShowEncounterForSelectedPatient),
      concatLatestFrom(() => this.store.select(selectSelectedPatientId)),
      switchMap(([{ encounterId }, patientId]) => {
        return of(
          showEncounter({
            patientId,
            encounterId,
          })
        );
      })
    );
  });

  hideEncounterForSelectedPatient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatientActions.HideEncounterForSelectedPatient),
      concatLatestFrom(() => this.store.select(selectSelectedPatientId)),
      switchMap(([{ encounterId }, patientId]) => {
        return of(
          hideEncounter({
            patientId,
            encounterId,
          })
        );
      })
    );
  });

  searchPatient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatientActions.SearchPatient),
      exhaustMap(() =>
        of({})
          .pipe(delay(10)) // TODO remove this delay and implement real API call
          .pipe(
            map(() =>
              searchPatientSuccess({ result: mockPatientsResultList() })
            )
          )
      ),
      catchError((error: ApiError) => of(searchPatientFailure({ error })))
    );
  });

  goToPatient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatientActions.GoToPatient),
      exhaustMap(
        ({
          departmentId,
          patientId,
        }: {
          departmentId: string | null;
          patientId: string;
          encounterId?: string;
        }) => {
          if (departmentId && patientId) {
            history.pushState(null, '', 'departments/' + departmentId);
            this.router.navigateByUrl(
              AppRoutes.patientDashboard(departmentId, patientId),
              {}
            );
          }
          return of(goToPatientSuccess(), hideTherapiesLegendModal());
        }
      ),
      catchError((error: ApiError) => of(goToPatientFailure({ error })))
    );
  });

  exportPatients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatientActions.ExportPatients),
      exhaustMap(() => {
        return of(exportPatientsSuccess()).pipe(delay(1000)); // TODO
      }),
      catchError((error: ApiError) => {
        this.snackBar.showSnackbar(
          errorMessage(error) ??
            this.translocoService.translate('common.errors.generic'),
          this.translocoService.translate('common.buttons.close'),
          'error-snackbar',
          SNACK_BAR_DEBOUNCE_TIME_MS
        );
        return of(exportPatientsFailure({ error }));
      })
    );
  });

  constructor(
    private store: Store,
    private actions$: Actions,
    private router: Router,
    private encountersApi: EncounterWebApi,
    private patientsApi: PatientsWebApi,
    private snackBar: SnackbarService,
    private translocoService: TranslocoService
  ) {}
}
