<natea-cc-base-page
  *transloco="let t"
  [isCollapseSubHeader]="(showSecondHeaderLine$ | async) ?? false"
>
  <natea-cc-sub-header sub-header></natea-cc-sub-header>

  <ng-content header-content select="[header-content]"></ng-content>
  <natea-folder-tab
    main-content
    class="encounters-container"
    [isMainContentScrollable]="false"
    [tabs]="((encounterTabs$ | async) || {}).tabs ?? []"
    [activeIndex]="((encounterTabs$ | async) || {}).activeIndex ?? null"
    (tabSelected)="onSelectedEncounter($event)"
    (tabClosed)="onClosedEncounter($event)"
  >
    <!-- Tabs -->
    <mat-tab-group
      class="full-height-tab-group"
      [selectedIndex]="selectedTabIndex"
      (selectedIndexChange)="onTabChanged($event)"
      dynamicHeight
    >
      <!-- Timeline  -->
      <mat-tab>
        <ng-template mat-tab-label>
          <natea-tab-item
            [iconName]="'timeline'"
            [label]="t('common.tabs.timelineItemLabel')"
            [isSelected]="selectedTabIndex === 0"
          ></natea-tab-item>
        </ng-template>
        <ng-content select="[timeline-section]"></ng-content>
      </mat-tab>
      <!-- Table  -->
      <mat-tab>
        <ng-template mat-tab-label>
          <natea-tab-item
            [iconName]="'tabella'"
            [label]="t('common.tabs.detailItemLabel')"
            [isSelected]="selectedTabIndex === 1"
          ></natea-tab-item>
        </ng-template>
        <ng-content select="[detail]"></ng-content>
      </mat-tab>
      <!-- /Table  -->
    </mat-tab-group>
  </natea-folder-tab>
  <ng-content modals select="[modals]"></ng-content>
</natea-cc-base-page>
