<ng-container *transloco="let t; read: 'problems.newProblems'">
  <ng-container
    *ngIf="{
      disableButtons: (isCreatingOrUpdatingProblem$ | async) ?? false,
    } as context"
  >
    <natea-base-modal
      *ngIf="modalState$ | async as modalState"
      [modalTitle]="
        modalState.mode !== 'create'
          ? modalState.problem.problemType.name
          : t('newProblemTitle')
      "
      [modalSubtitle]="setSubTitleByMode(modalState.mode)"
      (closeModal)="cancelModal()"
      [modalSize]="isDesktopSized ? modalDesktopSize : modalTabletSize"
    >
      <form [formGroup]="createNewProblemForm" (ngSubmit)="onSave()">
        <div class="user-type">
          <natea-dropdown
            class="dropdown-left"
            formControlName="problemClass"
            ngDefaultControl
            [label]="t('typology')"
            [isRequired]="true"
          >
          </natea-dropdown>
          <natea-dropdown
            formControlName="userInsertId"
            ngDefaultControl
            [label]="t('user')"
            [isRequired]="true"
            [leftIcon]="iconFromUserRole"
          >
          </natea-dropdown>
        </div>

        <natea-auto-complete
          ngDefaultControl
          formControlName="problemType"
          class="grayscale70"
          [label]="t('problemDescription')"
          [options]="(problemType$ | async) || []"
          [isLoadingMore]="(isLoadingProblemsData$ | async) ?? false"
          [canLoadMore]="(hasMoreProblemData$ | async) ?? true"
          [selectedOption]="
            createNewProblemForm.controls.problemType.value ?? undefined
          "
          (scrollBottomReached)="onProblemTypesScrollEndReached()"
          (valueChanged)="onProblemDataDescriptionChanged($event)"
        ></natea-auto-complete>

        <div class="date-hour">
          <natea-date-time-picker
            [label]="t('startDate')"
            formControlName="startDate"
            [maxDate]="maxDate"
            ngDefaultControl
          >
          </natea-date-time-picker>

          <natea-date-time-picker
            [label]="t('endDate')"
            formControlName="endDate"
            [minDate]="minEndDate"
            [maxDate]="getNowDate()"
            [inputValue]="updateEndDate"
            ngDefaultControl
          >
          </natea-date-time-picker>
          <div class="stop-button">
            <natea-button
              leftIcon="stop"
              [label]="t('stop')"
              [disabled]="
                modalState.mode === 'create' || modalState.mode === 'view'
              "
              (buttonClick)="onStop()"
            ></natea-button>
          </div>
        </div>
        <div class="note-wrapper">
          <span> {{ t("note") }} </span>
          <textarea rows="9" formControlName="note"> </textarea>
        </div>

        <footer>
          <div>
            <natea-button
              *ngIf="modalState.mode === 'edit' || modalState.mode === 'view'"
              [label]="t('delete')"
              [color]="buttonVariants.TERTIARY"
              [isError]="true"
              [disabled]="context.disableButtons"
              (buttonClick)="onDeleteProblem(modalState.problem)"
            ></natea-button>

            <natea-button
              *ngIf="modalState.mode === 'edit' || modalState.mode === 'view'"
              [label]="t('log')"
              [color]="buttonVariants.TERTIARY"
              (buttonClick)="onLog(modalState.problem)"
            >
            </natea-button>

            <natea-button
              [class.copy-board]="
                modalState.mode === 'edit' ||
                modalState.mode === 'view' ||
                modalState.mode === 'create'
              "
              [label]="t('pasteboard')"
              [color]="buttonVariants.TERTIARY"
              (buttonClick)="onCopyBoard()"
              [showLoader]="(isCopyToBoardPress$ | async) ?? false"
              [disabled]="
                !createNewProblemForm.controls.note.value ||
                context.disableButtons
              "
            >
            </natea-button>
          </div>

          <div>
            <natea-button
              [color]="buttonVariants.SECONDARY"
              [label]="t('cancel')"
              (buttonClick)="cancelModal()"
              [disabled]="context.disableButtons"
            ></natea-button>
            <natea-button
              [label]="t('save')"
              buttonType="submit"
              class="save"
              [showLoader]="(isCreatingOrUpdatingProblem$ | async) ?? false"
              [disabled]="
                disabledForm ||
                context.disableButtons ||
                (modalState.mode === 'edit' &&
                  !createNewProblemForm.controls.endDate.value)
              "
              (buttonClick)="onSave()"
            ></natea-button>
          </div>
        </footer>
      </form>
    </natea-base-modal>
  </ng-container>
</ng-container>
