import { Component, HostBinding, Input } from '@angular/core';
import { LocalizedDateFormat } from '../../../pipes/localized-date.pipe';

@Component({
  selector: 'natea-cc-multi-section-timeline-header',
  templateUrl: './multi-section-timeline-header.component.html',
  styleUrls: ['./multi-section-timeline-header.component.scss'],
})
export class MultiSectionTimelineHeaderComponent {
  @Input() dateRange!: Date[];
  @Input() leftPadded = true;

  @HostBinding('class.left-padded') get leftPadding(): boolean {
    return this.leftPadded;
  }

  LocalizedDateFormat = LocalizedDateFormat;

  isToday = (date: Date): boolean => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };
}
