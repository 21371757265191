import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { NateaIconsName } from '../../icons/natea-icon';
import { NateaIconsModule } from '../../icons/natea-icons.module';

export type SwitchItem = 'left' | 'right';
export type SwitchSize = 'large' | 'small' | 'auto';

@Component({
  selector: 'natea-switch',
  standalone: true,
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  imports: [CommonModule, NateaIconsModule],
})
export class SwitchComponent {
  @Input() leftIcon!: NateaIconsName;
  @Input() leftIconAlt!: string;
  @Input() rightIcon!: NateaIconsName;
  @Input() rightIconAlt!: string;

  @Input() selectedItem: SwitchItem = 'left';

  @Input() size: SwitchSize = 'auto';

  @Output() selectedIndexChange: EventEmitter<SwitchItem> =
    new EventEmitter<SwitchItem>();

  @HostBinding('class') get class(): string {
    return this.size;
  }
}
