/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a problem catalog entry.
 */
export interface ProblemCatalogEntryResource { 
    /**
     * The description of the problem catalog entry, if applicable.
     */
    description?: string;
    /**
     * Unique identifier for the problem catalog entry.
     */
    id: string;
    /**
     * Indicates if the problem catalog entry is currently enabled within the application.
     */
    isEnabled: boolean;
    /**
     * The name of the problem catalog entry.
     */
    name: string;
    /**
     * The class of problem catalog entry.
     */
    problemClass: string;
}

