import { ReducerTypes, on } from '@ngrx/store';
import {
  confirmAbortTransfusionalTherapySuccess,
  confirmDeleteTransfusionalTherapySuccess,
  createTransfusionalTherapySuccess,
  editTransfusionalTherapySuccess,
  loadTransfusionalTherapiesSuccess,
  transfusionalTherapySuspensionSuccess,
} from '../../../../therapies/transfusional-therapies/store/transfusional-therapies.actions';
import { PatientsState, updateEncounterData } from '../../patients.reducers';

export const patientTransfusionalTherapiesReducerFunctions: ReducerTypes<
  PatientsState,
  [never]
>[] = [
  on(
    loadTransfusionalTherapiesSuccess,
    (state, { therapies, patientId, encounterId }) => {
      return updateEncounterData(state, { patientId, encounterId }, () => ({
        transfusionalTherapies: therapies,
      }));
    }
  ),

  on(
    createTransfusionalTherapySuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          transfusionalTherapies: [
            ...(encounterData?.transfusionalTherapies ?? []),
            therapy,
          ],
        })
      );
    }
  ),
  on(
    confirmDeleteTransfusionalTherapySuccess,
    (state, { therapyId, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          transfusionalTherapies: [
            ...(encounterData?.transfusionalTherapies ?? []).filter(
              (therapy) => therapy.id !== therapyId
            ),
          ],
        })
      );
    }
  ),

  on(
    confirmAbortTransfusionalTherapySuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => {
          return {
            transfusionalTherapies: [
              ...(encounterData?.transfusionalTherapies ?? []).map((t) =>
                t.id !== therapy.id ? t : therapy
              ),
            ],
          };
        }
      );
    }
  ),

  on(
    transfusionalTherapySuspensionSuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => {
          return {
            transfusionalTherapies: [
              ...(encounterData?.transfusionalTherapies ?? []).map((t) =>
                t.id !== therapy.id ? t : therapy
              ),
            ],
          };
        }
      );
    }
  ),

  on(
    editTransfusionalTherapySuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => {
          return {
            transfusionalTherapies: [
              ...(encounterData?.transfusionalTherapies ?? []).map((t) =>
                t.id !== therapy.id ? t : therapy
              ),
            ],
          };
        }
      );
    }
  ),
];
