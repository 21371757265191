import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Patient } from '../../../../shared/entities/patient';
import { PatientsWebApi } from '../../webapi/patient-web-api';
import { mockPatientsList } from './mock-patients';

@Injectable()
export class MockedPatientsWebApi extends PatientsWebApi {
  public override getPatientsList(
    departmentsIds: string[]
  ): Observable<Patient[]> {
    return of(
      mockPatientsList(departmentsIds.length > 0 ? departmentsIds[0] : '')
    );
  }
}
