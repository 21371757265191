import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import {
  DepartmentsActions,
  loadDepartmentsFailure,
  loadDepartmentsSuccess,
} from './departments.actions';
import { DepartmentsWebApi } from '../webapis/departments-web-api';

@Injectable()
export class DepartmentsEffects {
  loadDepartments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DepartmentsActions.LoadDepartments),
      exhaustMap(() =>
        this.departmentsWebApi.getDepartments().pipe(
          map((result) =>
            loadDepartmentsSuccess({
              departments: result,
            })
          ),
          catchError((error) => {
            return of(loadDepartmentsFailure(error));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private departmentsWebApi: DepartmentsWebApi
  ) {}
}
