<div class="container" *transloco="let t; read: 'board'">
  <div class="clean">
    <natea-button
      (buttonClick)="clear($event)"
      [label]="t('cleanBlackBoard')"
      [color]="buttonSecondary"
      [leftIcon]="'pulisci'"
    ></natea-button>
  </div>

  <div class="table-container">
    <table [attr.aria-label]="t('tableCaption')">
      <thead>
        <tr>
          <th class="accordion-column-header"></th>
          <th class="content-column-header">{{ t("tableHeaderContent") }}</th>
          <th class="creator-column-header">{{ t("tableHeaderCreator") }}</th>
          <th class="creation-date-column-header">
            {{ t("tableHeaderCreationDate") }}
          </th>
          <th class="actions-column-header"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let note of getNotes$ | async as notes; let i = index"
          class="row-container"
        >
          <td class="accordion-cell">
            <natea-icons
              (iconClick)="toggleNote(note, $event)"
              [class.arrow-down]="openedBoardNotesIds.has(note.id)"
              class="accordion-icon icon"
              name="arrow-right"
            ></natea-icons>
          </td>

          <td class="content-cell">
            <span *ngIf="!openedBoardNotesIds.has(note.id)">
              {{ note.description }}
            </span>
            <div *ngIf="openedBoardNotesIds.has(note.id)" class="full-text">
              {{ note.description }}
            </div>
          </td>
          <td class="creator-cell">{{ t(note.entityFrom) }}</td>
          <td class="date">
            <span>{{
              note.creationDate ? (note.creationDate | localizedDate) : ""
            }}</span>
          </td>
          <td>
            <div
              [class.icons-cell]="showPasteIcon"
              [class.icon-cell]="!showPasteIcon"
            >
              <button
                type="button"
                (click)="paste($event, note)"
                *ngIf="showPasteIcon"
              >
                <natea-icons name="incolla" class="icon"></natea-icons>
              </button>

              <button type="button" (click)="delete($event, note, notes)">
                <natea-icons name="bin" class="icon"></natea-icons>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <natea-cc-confirmation-exit-without-saving
    *ngIf="(isShowDeletionBoardNoteModal$ | async) === 'all'"
    (confirmDelete)="confirmCleanAllItems($event)"
    [isButtonDisabled]="
      (isDeleteAllBoardNoteFailed$ | async) === true
        ? false
        : isConfirmRemoveAllBoardNoteIsDisabled
    "
    [message]="t('cleanAllBlackBoardMessage')"
    (cancel)="cancelClearAllItems($event)"
  ></natea-cc-confirmation-exit-without-saving>

  <natea-cc-confirmation-exit-without-saving
    *ngIf="(isShowDeletionBoardNoteModal$ | async) === 'single'"
    (confirmDelete)="confirmDelete($event)"
    [message]="t('cleanSingleBlackBoardMessage')"
    [isButtonDisabled]="
      (isDeleteAllBoardNoteFailed$ | async) === true
        ? false
        : isConfirmRemoveAllBoardNoteIsDisabled
    "
    (cancel)="cancelClearSingleItem($event)"
  ></natea-cc-confirmation-exit-without-saving>
</div>
