import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'natea-checkbox',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxComponent,
      multi: true,
    },
  ],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements ControlValueAccessor, OnChanges {
  @Input() disabled = false;
  @Input() checked = false;

  @Input() isIndeterminate = false;

  @Input() label?: string = '';

  @Input() required = false;
  @Input() value = '';
  @Input() id = '';

  @Input() color: ThemePalette = 'primary';

  @Input() labelPosition: 'before' | 'after' = 'after';

  @Output() indeterminateChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() checkboxChange: EventEmitter<MatCheckboxChange> =
    new EventEmitter<MatCheckboxChange>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isDisabled'] && changes['isDisabled'].currentValue) {
      this.disabled = true;
    }
  }

  onChange!: (val: boolean | null) => void;
  onTouched!: () => void;

  markAsTouched = (): void => {
    if (this.onTouched) {
      this.onTouched();
    }
  };

  writeValue = (data: boolean): void => {
    this.checked = data;
  };

  registerOnChange = (fn: (val: boolean | null) => void): void => {
    this.onChange = fn;
  };

  registerOnTouched = (fn: () => void): void => {
    this.onTouched = fn;
  };

  setDisabledState = (disabled: boolean): void => {
    this.disabled = disabled;
  };

  onChanged = (event: MatCheckboxChange): void => {
    this.value = event.source.value;

    if (this.onChange) {
      this.onChange(event.checked);
      this.checked = event.checked;
    }
    this.checkboxChange.emit(event);
  };

  onIntermediateChange = (changed: boolean): void => {
    this.indeterminateChange.emit(changed);
  };

  onClick(event: MouseEvent): void {
    event.stopPropagation();
  }
}
