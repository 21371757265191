<ng-container *transloco="let t; read: 'patient'">
  <nav class="location" [ngSwitch]="!!departmentName">
    <ol>
      <li
        *ngIf="!multipleDepartments"
        class="hospital-name"
        [class.no-department]="!departmentName"
        [attr.aria-current]="departmentName ? null : 'page'"
      >
        <natea-icons
          [fill]="departmentName ? '#4d4d4d' : '#038095'"
          class="hospital-icon"
          name="hospital"
        ></natea-icons
        >{{ hospitalName }}
      </li>
      <li *ngIf="multipleDepartments && departmentName" class="hospital-name">
        <a routerLink="/departments"
          ><natea-icons
            fill="#038095"
            class="hospital-icon"
            name="hospital"
          ></natea-icons>
          {{ hospitalName }}</a
        >
      </li>
      <li
        *ngSwitchCase="true"
        aria-current="page"
        class="department-name"
        [class.multiple-departments]="multipleDepartments"
      >
        <span>{{ departmentName }}</span>
      </li>
    </ol>
  </nav>
  <button
    type="button"
    *transloco="let t; read: 'patient'"
    (click)="searchPatient.emit()"
  >
    <natea-icons fill="#038095" class="search-icon" name="search"></natea-icons>
    <span *ngIf="isDesktopSized">{{ t("search.searchPatient") }}</span>
  </button>
</ng-container>
