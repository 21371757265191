<ng-container *transloco="let t; read: 'departments'">
  <div class="departments-container">
    <div class="hospital-name">
      <h3>{{ hospitalName }}</h3>
    </div>
    <div
      #departmentsTable
      class="departments-table-container"
      role="grid"
      (scroll)="checkOverflow()"
    >
      <table mat-table [dataSource]="departments" matSort>
        <caption>
          {{
            hospitalName
          }}
        </caption>
        <ng-container matColumnDef="color">
          <th mat-header-cell *matHeaderCellDef tabindex="-1"></th>
          <td
            mat-cell
            *matCellDef="let element"
            tabindex="-1"
            [class.isGood]="element.colorOccupied == 'green'"
            [class.isWarning]="element.colorOccupied == 'yellow'"
            [class.isCritical]="element.colorOccupied == 'red'"
          ></td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            tabindex="-1"
            role="button"
          >
            {{ t("tableHeaders.nameDepartment") }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="clickable-cell"
            role="button"
          >
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="placesOccupied">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            tabindex="-1"
            role="button"
          >
            {{ t("tableHeaders.placesOccupied") }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            role="button"
            class="clickable-cell"
          >
            {{ element.occupiedBeds }} / {{ element.totalBeds }} -
            {{ element.percentageOccupied }}%
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="table-header-row"
        ></tr>
        <tr
          (click)="navigate(row.id)"
          click
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
      <natea-cc-more-items-pill
        [visible]="isOverflowing"
      ></natea-cc-more-items-pill>
    </div>
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'departments'">
  <div *ngIf="!isAnyData" class="empty">
    {{ t("noResults") }}
  </div>
</ng-container>
