import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  StoreItemDeletedResource,
  UserStorageApiService,
} from '../../../core/generated/api';
import { BoardContent, BoardType } from '../interfaces/board-content';
import { BoardUserData } from '../interfaces/board-user-type';
import { BoardAdapters } from './board.adapters';

@Injectable({
  providedIn: 'root',
})
export class BoardWebAPI {
  constructor(private userStorageService: UserStorageApiService) {}
  public getBoardContent(
    userId: string,
    objectType: string,
    storageType: string
  ): Observable<BoardContent[]> {
    return this.userStorageService
      .getUserStoreItems(userId, objectType, storageType)
      .pipe(map((response) => BoardAdapters.boardGetDataBeToFe(response)));
  }

  deleteItem(
    id: string,
    patientId: string,
    objectOrigine: BoardType,
    itemId: string,
    storageType: string
  ): Observable<StoreItemDeletedResource | undefined> {
    const { userId, originEntityType, storedItemId } =
      BoardAdapters.boardDataFeToBeDelete(id, objectOrigine, itemId);
    return this.userStorageService
      .deleteUserStoreItem(userId, patientId, originEntityType, storedItemId)
      .pipe(
        map((response) => {
          if (response) {
            return response.payload;
          } else {
            return;
          }
        })
      );
  }

  deleteAll(
    userData: BoardUserData,
    patientId: string
  ): Observable<StoreItemDeletedResource[]> {
    return this.userStorageService
      .clearUserStorage(userData.userId, patientId, userData.originEntityType)
      .pipe(map((response) => BoardAdapters.boardDeleteItemsBeToFe(response)));
  }

  public saveBoardContent(
    boardContent: BoardContent,
    userId: string
  ): Observable<BoardContent | undefined> {
    const { itemType, createItemRequest } =
      BoardAdapters.boardSaveContentFeToBe(boardContent);

    return this.userStorageService
      .createUserStoreItem(userId, '', itemType, createItemRequest)
      .pipe(map((response) => BoardAdapters.boardSaveContentBeToFe(response)));
  }
}
