import { ReducerTypes, on } from '@ngrx/store';
import {
  confirmAbortNutritionalTherapySuccess,
  confirmDeleteNutritionalTherapySuccess,
  createNutritionalTherapySuccess,
  editEnteralTherapySuccess,
  editParenteralTherapySuccess,
  loadNutritionalTherapiesSuccess,
} from '../../../../therapies/nutritional-therapies/store/nutritional-therapies.actions';
import { PatientsState, updateEncounterData } from '../../patients.reducers';

export const patientNutritionalTherapiesReducerFunctions: ReducerTypes<
  PatientsState,
  [never]
>[] = [
  on(
    loadNutritionalTherapiesSuccess,
    (state, { therapies, patientId, encounterId }) => {
      return updateEncounterData(state, { patientId, encounterId }, () => ({
        nutritionalTherapies: therapies,
      }));
    }
  ),

  on(
    createNutritionalTherapySuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          nutritionalTherapies: [
            ...(encounterData?.nutritionalTherapies ?? []),
            therapy,
          ],
        })
      );
    }
  ),

  on(
    confirmDeleteNutritionalTherapySuccess,
    (state, { therapyId, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          nutritionalTherapies: [
            ...(encounterData?.nutritionalTherapies ?? []).filter(
              (therapy) => therapy.id !== therapyId
            ),
          ],
        })
      );
    }
  ),

  on(
    confirmAbortNutritionalTherapySuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          nutritionalTherapies: [
            ...(encounterData?.nutritionalTherapies ?? []).map((t) =>
              t.id !== therapy.id ? t : therapy
            ),
          ],
        })
      );
    }
  ),

  on(
    editEnteralTherapySuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          nutritionalTherapies: [
            ...(encounterData?.nutritionalTherapies ?? []).map((t) =>
              t.id !== therapy.id ? t : therapy
            ),
          ],
        })
      );
    }
  ),

  on(
    editParenteralTherapySuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          nutritionalTherapies: [
            ...(encounterData?.nutritionalTherapies ?? []).map((t) =>
              t.id !== therapy.id ? t : therapy
            ),
          ],
        })
      );
    }
  ),
];
