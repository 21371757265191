import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CheckboxComponent } from '../../components/checkbox/checkbox.component';
import { NateaIconsName } from '../../icons/natea-icon';
import { NateaIconsModule } from '../../icons/natea-icons.module';

@Component({
  selector: 'natea-dropdown-item',
  standalone: true,
  imports: [CommonModule, NateaIconsModule, CheckboxComponent],
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss'],
})
export class DropdownItemComponent {
  @Input() value = 'test';
  @Input() iconName: NateaIconsName = 'bin';
  @Input() iconTitle!: string;
  @Input() fill?: string;
  @Input() iconClass?: string;
  @Input() width?: string;
  @Input() height?: string;
  @Input() hasCheckbox = false;
  @Input() checked = false;
  @Input() disabled = false;
  @Input() checkboxLabel = '';
  @Input() checkboxLabelPosition: 'before' | 'after' = 'after';
}
