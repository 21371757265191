import { Sort } from '@angular/material/sort';
import { createSelector } from '@ngrx/store';
import { OptionsItem } from 'natea-components';
import { MultiSectionTimelineInstance } from '../../../shared/components/multi-section-timeline/models/multi-section-timeline-instance';
import { optionItemFromProblemType } from '../../../shared/utils/optional-item-entity-utils';
import { selectShownEncounterProcedureOccurrences } from '../../patients/store/clinical-data/procedures/patients-data.procedures.selectors';
import { selectProceduresUiState } from '../../patients/store/selected-patient/selected-patient.selectors';
import {
  ProceduresExecutionFormData,
  ProceduresProgrammingFormData,
  ProceduresTerminationFormData,
} from '../components/procedures-detail-form/form-data/procedures-detail-form-data';
import { OccurrencesTableItem } from '../components/procedures-table/procedures-table.component';
import { Procedure } from '../entities/procedure';
import {
  ProcedureDevice,
  ProcedureLocation,
  ProcedureRoute,
} from '../entities/procedure-data';
import { ProcedureOccurrenceMovement } from '../entities/procedure-movement';
import { ProcedureOccurrence } from '../entities/procedure-occurrence';
import { ProcedureSelectedItem } from '../entities/procedure-selected-item';
import { createProceduresTableItemsComparator } from '../utils/procedures-sort';
import {
  optionItemFromProcedure,
  optionItemFromProcedureDevice,
  optionItemFromProcedureLocation,
  optionItemFromProcedureRoute,
  terminationDateFromProcedureOccurrence,
} from '../utils/procedures-utils';
import { ProceduresUiState } from './procedures.reducer';

export const selectProceduresState = selectProceduresUiState;
export const selectProceduresData = selectShownEncounterProcedureOccurrences;

export const selectIsLoadingProcedures = createSelector(
  selectProceduresState,
  (state: ProceduresUiState): boolean => state.isLoadingData
);

export const selectProceduresAsTimelineInstances = createSelector(
  selectProceduresData,
  (
    occurrences?: ProcedureOccurrence[]
  ): MultiSectionTimelineInstance<ProcedureOccurrence>[] | undefined => {
    if (!occurrences) return undefined;

    const proceduresMap = new Map<Procedure, ProcedureOccurrence[]>();
    occurrences?.forEach((occurrence) => {
      const procedure = occurrence.procedure;
      if (!proceduresMap.has(procedure)) {
        proceduresMap.set(procedure, [occurrence]);
      } else {
        proceduresMap.get(procedure)?.push(occurrence);
      }
    });
    return Array.from(proceduresMap.keys()).map((procedure) => ({
      id: procedure.id,
      label: procedure.description,
      occurrences:
        proceduresMap.get(procedure)?.map((procedure) => ({
          ...procedure,
          data: procedure,
        })) ?? [],
    }));
  }
);

export const selectProceduresSort = createSelector(
  selectProceduresUiState,
  (state: ProceduresUiState): Sort | undefined => state.sort
);

export const selectOccurrencesAsTableItems = createSelector(
  selectProceduresData,
  selectProceduresSort,
  (
    occurrences?: ProcedureOccurrence[],
    sort?: Sort
  ): OccurrencesTableItem[] | undefined => {
    const comparator = createProceduresTableItemsComparator(sort);
    const items = occurrences?.map((occurrence) => ({
      procedure: occurrence.procedure.description,
      procedureId: occurrence.procedure.id,
      problem: occurrence.problem.name,
      executionDate: occurrence.executionDate,
      terminationDate: occurrence.terminationDate,
      state: occurrence.state,
      occurrenceId: occurrence.id,
    }));
    return items?.sort(comparator);
  }
);

export const selectSelectedOccurrence = createSelector(
  selectProceduresUiState,
  selectProceduresData,
  (
    state: ProceduresUiState,
    occurrences?: ProcedureOccurrence[]
  ): ProcedureSelectedItem | undefined => {
    const { selectedOccurrenceId } = state;
    if (!selectedOccurrenceId) {
      return undefined;
    }

    const occurrence = occurrences?.find(
      (occurrence) => occurrence.id === selectedOccurrenceId
    );

    return {
      occurrence: occurrence ?? undefined,
      fromTable: state.selectedProcedureOccurrenceInTable,
      changingTab: state.isTabChanging,
    };
  }
);

export const selectOccurrencesWithMovementsInCreation = createSelector(
  selectProceduresUiState,
  (state: ProceduresUiState): string[] =>
    state.occurrencesWithMovementsInCreation
);

export const selectMovementsInUpdate = createSelector(
  selectProceduresUiState,
  (state: ProceduresUiState): string[] => state.movementsInUpdate
);

// Return the ids of the movement selected for deletion and the occurrence it belongs to
export const selectSelectedMovementForDeletionIds = createSelector(
  selectProceduresUiState,
  (
    state: ProceduresUiState
  ): { movementId: string; occurrenceId: string } | undefined => {
    const { selectedMovementForDeletion } = state;
    if (!selectedMovementForDeletion) {
      return undefined;
    }

    const { movementId, occurrenceId } = selectedMovementForDeletion;
    return { movementId, occurrenceId };
  }
);

// Return the instance of the movement selected for deletion

export const selectSelectedMovementForDeletion = createSelector(
  selectSelectedMovementForDeletionIds,
  selectProceduresData,
  (
    ids: { movementId: string; occurrenceId: string } | undefined,
    occurrences?: ProcedureOccurrence[]
  ): ProcedureOccurrenceMovement | undefined => {
    if (!ids) return undefined;

    const { movementId, occurrenceId } = ids;
    return occurrences
      ?.find((occurrence) => occurrence.id === occurrenceId)
      ?.movements?.find((movement) => movement.id === movementId);
  }
);

export const selectMovementsInDeletion = createSelector(
  selectProceduresUiState,
  (state: ProceduresUiState): string[] => state.movementsInDeletion
);

// Create new

export const selectShowCreateNewProcedureModal = createSelector(
  selectProceduresUiState,
  (state: ProceduresUiState): boolean => state.showCreateNewModal
);

export const selectIsCreatingNewProcedure = createSelector(
  selectProceduresUiState,
  (state: ProceduresUiState): boolean => state.isCreatingNew
);

// TODO will be replaced by domain API
export const selectAllProcedures = createSelector(
  selectProceduresData,
  (occurrences?: ProcedureOccurrence[]): Procedure[] | undefined => {
    const proceduresSet = new Set<Procedure>();
    occurrences?.forEach((occurrence) => {
      proceduresSet.add(occurrence.procedure);
    });
    return Array.from(proceduresSet);
  }
);

export const selectAllProceduresAsOptionItems = createSelector(
  selectAllProcedures,
  (procedures?: Procedure[]): OptionsItem<Procedure>[] | undefined => {
    return procedures?.map((procedure) => ({
      id: procedure.id,
      label: procedure.description,
      data: procedure,
    }));
  }
);

export const selectProceduresLegendStateModal = createSelector(
  selectProceduresUiState,
  (state: ProceduresUiState): boolean => state.showProceduresLegendStateModal
);

export const selectProceduresCesChangeTab = createSelector(
  selectProceduresUiState,
  (state: ProceduresUiState): number => state.tabIndex
);

export const selectProceduresCurrentProgrammingForm = createSelector(
  selectProceduresUiState,
  selectSelectedOccurrence,
  (
    state: ProceduresUiState,
    occurrenceData: ProcedureSelectedItem | undefined
  ): {
    occurrence: ProcedureOccurrence | undefined;
    form: ProceduresProgrammingFormData;
  } => ({
    occurrence: occurrenceData?.occurrence,
    form: {
      ...occurrenceData?.occurrence,
      ...state.currentProgrammingData,
      notes:
        state.currentProgrammingData?.notes ??
        occurrenceData?.occurrence?.procedure.description ??
        undefined,
      procedure:
        state.currentProgrammingData?.procedure ??
        optionItemFromProcedure(occurrenceData?.occurrence?.procedure),
      problem:
        state.currentProgrammingData?.problem ??
        (occurrenceData?.occurrence?.problem
          ? optionItemFromProblemType(occurrenceData?.occurrence?.problem)
          : null),
    } as ProceduresProgrammingFormData,
  })
);

export const selectProceduresCurrentExecutionForm = createSelector(
  selectProceduresUiState,
  selectSelectedOccurrence,
  (
    state: ProceduresUiState,
    occurrenceData: ProcedureSelectedItem | undefined
  ): {
    occurrence: ProcedureOccurrence | undefined;
    form: ProceduresExecutionFormData;
  } => ({
    occurrence: occurrenceData?.occurrence,
    form: {
      ...occurrenceData?.occurrence,
      ...state.currentExecutionData,
      device:
        state.currentExecutionData?.device ??
        optionItemFromProcedureDevice(occurrenceData?.occurrence?.device),
      place:
        state.currentExecutionData?.place ??
        optionItemFromProcedureLocation(occurrenceData?.occurrence?.place),
      insertion:
        state.currentExecutionData?.insertion ??
        optionItemFromProcedureRoute(occurrenceData?.occurrence?.insertion),
    } as ProceduresExecutionFormData,
  })
);

export const selectProceduresCurrentTerminationForm = createSelector(
  selectProceduresUiState,
  selectSelectedOccurrence,
  (
    state: ProceduresUiState,
    occurrenceData: ProcedureSelectedItem | undefined
  ): {
    occurrence: ProcedureOccurrence | undefined;
    form: ProceduresTerminationFormData;
  } => ({
    occurrence: occurrenceData?.occurrence,
    form: {
      ...occurrenceData?.occurrence,
      ...state.currentTerminationData,
      terminationDate:
        state.currentTerminationData?.terminationDate ??
        terminationDateFromProcedureOccurrence(occurrenceData?.occurrence),
    } as ProceduresTerminationFormData,
  })
);

export const selectProcedureAvailableDevices = createSelector(
  selectProceduresUiState,
  (state: ProceduresUiState): ProcedureDevice[] => state.availableDevices
);

export const selectProcedureAvailableLocations = createSelector(
  selectProceduresUiState,
  (state: ProceduresUiState): ProcedureLocation[] => state.availableLocations
);

export const selectProcedureAvailableRoutes = createSelector(
  selectProceduresUiState,
  (state: ProceduresUiState): ProcedureRoute[] => state.availableRoutes
);
