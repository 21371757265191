import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonVariants, SizeType } from 'natea-components';

@Component({
  selector: 'natea-cc-confirmation-exit-without-saving',
  templateUrl: './confirmation-exit-without-saving.component.html',
  styleUrls: ['./confirmation-exit-without-saving.component.scss'],
})
export class ConfirmationExitWithoutSavingComponent {
  @Input() message = '';
  @Input() isButtonDisabled = false;
  @Output() cancel: EventEmitter<Event> = new EventEmitter();
  @Output() confirmDelete: EventEmitter<Event> = new EventEmitter();

  colorVariantSecondary: ButtonVariants = ButtonVariants.SECONDARY;
  modalSize: SizeType = SizeType.SMALL;

  confirmDeleteClick = (event: Event) => {
    this.confirmDelete.emit(event);
  };
}
