import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

@Injectable()
export class PharmacologicalTherapiesAdministrationEffects {
  /*  filterTherapiesState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(filterTherapiesState),
      withLatestFrom(this.strore.select(selectSelectedPatient)),
      map(([action, patient]) => {
        return loadPharmacologicalTherapies({
          patientId: patient?.id,
          date: action.id,
        });
      })
    )
  );*/
  constructor(private actions$: Actions, private strore: Store) {}
}
