import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import {
  MatProgressSpinnerModule,
  ProgressSpinnerMode,
} from '@angular/material/progress-spinner';

export enum LoaderVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

@Component({
  selector: 'natea-loader',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() color: LoaderVariants = LoaderVariants.PRIMARY;

  @Input() diameter = 100;

  @Input() value!: number;

  @Input() strokeWidth = 10;

  @Input() mode: ProgressSpinnerMode = 'indeterminate';

  @HostBinding('class') get class(): string {
    return this.color;
  }
}
