import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  NewBornActions,
  saveFactSheetNewBornSuccess,
} from './new-born.actions';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { selectOngoingEncounterAndPatientIds } from 'src/app/features/patients/store/patients.selectors';
import { FactSheetNewBornWebApi } from '../webapi/fact-sheet-new-born-webapi';
import {
  ApiError,
  ApiErrorLevel,
  errorMessage,
} from 'src/app/shared/entities/errors';
import { NewBornData } from '../form/new-born-form-data';
import { selectPercentileData } from './new-born.selectors';
import { TranslocoService } from '@ngneat/transloco';
import { SnackbarService } from 'natea-components';
import { SNACK_BAR_DEBOUNCE_TIME_MS } from 'src/app/shared/utils/constants';
import { downloadCatalogsFailure } from 'src/app/features/therapies/pharmacological-therapies/store/pharmacological-therapies.actions';

@Injectable()
export class FactSheetEffects {
  loadAllFactSheet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewBornActions.LoadAllFactSheetNewBorn),

      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      exhaustMap(([, onGoingEncounterIds]) => {
        const patientId = onGoingEncounterIds?.patientId;
        const encounterId = onGoingEncounterIds?.encounterId;
        return this.factSheetNewBornWebApi
          .getAllFactSheet()

          .pipe(
            map((factSheet?: NewBornData) => {
              return {
                type: NewBornActions.LoadAllFactSheetNewBornSuccess,
                factSheet,
                patientId,
                encounterId,
              };
            }),

            catchError((error: ApiError) => {
              return of({
                type: NewBornActions.LoadAllFactSheetNewBornFailure,
                payload: { error },
              });
            })
          );
      })
    );
  });

  loadAllFactSheetFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NewBornActions.LoadAllFactSheetNewBornFailure),
        map(() => {
          return this.snackBar.showSnackbar(
            this.translocoService.translate(
              'anamnesis.notifications.loadFactSheetNewBornFailure'
            ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  loadAllFactSheetSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewBornActions.LoadAllFactSheetNewBornSuccess),
      map(() => ({
        type: NewBornActions.LoadAllFactSheetNewBornCatalogs,
      }))
    );
  });

  loadAllFactSheetCatalogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewBornActions.LoadAllFactSheetNewBornCatalogs),
      exhaustMap(() => {
        return this.factSheetNewBornWebApi.downloadCatalog().pipe(
          map((catalogs) => {
            return {
              type: NewBornActions.LoadAllFactSheetNewBornCatalogsSuccess,
              catalogs,
            };
          }),

          catchError((error: ApiError) => {
            return of({
              type: NewBornActions.LoadAllFactSheetNewBornCatalogsFailure,
              error,
            });
          })
        );
      })
    );
  });

  saveFactSheet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: NewBornActions.SaveFactSheetNewBorn;
        factSheet: NewBornData;
      }>(NewBornActions.SaveFactSheetNewBorn),

      concatLatestFrom(() => [
        this.store.select(selectPercentileData),
        this.store.select(selectOngoingEncounterAndPatientIds),
      ]),
      exhaustMap(([factSheetNewBorn, percentileValue, patientData]) => {
        if (!patientData) {
          const error: ApiError = {
            code: 400,
            correlationId: '',
            datetime: new Date(),
            errors: [
              {
                code: '400',
                message: 'Patient data not found',
                type: '',
                level: ApiErrorLevel.ERROR,
                field: '',
              },
            ],
          };
          return of({
            type: NewBornActions.SaveFactSheetNewBornFailure,
            error,
          });
        }
        return this.factSheetNewBornWebApi
          .saveFactSheet(factSheetNewBorn.factSheet, percentileValue)
          .pipe(
            map((factSheet) => {
              return saveFactSheetNewBornSuccess({
                factSheet,
                patientId: patientData.patientId,
                encounterId: patientData.encounterId,
              });
            }),

            catchError((error: ApiError) => {
              return of({
                type: NewBornActions.SaveFactSheetNewBornFailure,
                error,
              });
            })
          );
      })
    );
  });

  saveFactSheetSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NewBornActions.SaveFactSheetNewBornSuccess),
        map(() => {
          return this.snackBar.showSnackbar(
            this.translocoService.translate(
              'anamnesis.notifications.saveFactSheetNewBornSuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  saveFactSheetFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<{
          type: NewBornActions.SaveFactSheetNewBornFailure;
          error: ApiError;
        }>(NewBornActions.SaveFactSheetNewBornFailure),
        map(({ error }) => {
          return this.snackBar.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'anamnesis.notifications.saveFactSheetNewBorFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  downloadCatalogs$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(downloadCatalogsFailure),
        map(() => {
          return this.snackBar.showSnackbar(
            this.translocoService.translate(
              'anamnesis.notifications.downloadCatalogsFailure'
            ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );
  constructor(
    private actions$: Actions,
    private store: Store,
    private snackBar: SnackbarService,
    private translocoService: TranslocoService,
    private factSheetNewBornWebApi: FactSheetNewBornWebApi
  ) {}
}
