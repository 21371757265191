import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
  MatTooltipModule,
} from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import {
  AutoCompleteComponent,
  BaseModalComponent,
  ButtonComponent,
  CheckboxComponent,
  DatePickerComponent,
  DateTimePickerComponent,
  DropdownComponent,
  DropdownItemComponent,
  FolderTabComponent,
  FullPageLoaderComponent,
  NateaIconsModule,
  SwitchComponent,
  TooltipComponent,
} from 'natea-components';
import { SharedModule } from '../../shared/shared.module';
import { DeleteProblemModalComponent } from './components/delete-problem-modal/delete-problem-modal.component';
import { ProblemInsertNewProblemModalComponent } from './components/problem-insert-new-problem-modal/problem-insert-new-problem-modal.component';
import { ProblemsFilterComponent } from './components/problems-filter/problems-filter.component';
import { ProblemsSectionHeaderComponent } from './components/problems-section-header/problems-section-header.component';
import { ProblemsTableComponent } from './components/problems-table/problems-table.component';
import { ProblemTimelineItemComponent } from './components/problems-timeline-item/problems-timeline-item.component';
import { ProblemsTimelineComponent } from './components/problems-timeline/problems-timeline.component';
import { ProblemsRoutingModule } from './problems-routing.module';
import { ProblemsComponent } from './problems.component';

export const OtherOptions: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchGestures: 'auto',
  position: 'before',
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
};

@NgModule({
  declarations: [
    ProblemsComponent,
    ProblemsSectionHeaderComponent,
    ProblemTimelineItemComponent,
    ProblemsTimelineComponent,
    ProblemsFilterComponent,
    ProblemsTableComponent,
    DeleteProblemModalComponent,
    ProblemInsertNewProblemModalComponent,
  ],
  imports: [
    CommonModule,
    ProblemsRoutingModule,
    TranslocoModule,
    ButtonComponent,
    SwitchComponent,
    TooltipComponent,
    DatePickerComponent,
    NateaIconsModule,
    DropdownComponent,
    FolderTabComponent,
    DropdownItemComponent,
    CheckboxComponent,
    BaseModalComponent,
    FullPageLoaderComponent,
    MatTooltipModule,
    ReactiveFormsModule,
    AutoCompleteComponent,
    MatTableModule,
    MatSortModule,
    DateTimePickerComponent,
    NgxMatNativeDateModule,
    SharedModule,
    RouterModule,
    LetDirective,
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: OtherOptions },
    DatePipe,
  ],
})
export class ProblemsModule {}
