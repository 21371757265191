<ng-container *transloco="let t; read: 'patient.search'">
  <div class="table-result">
    <div class="result">
      <h3>{{ t("result", results) }}</h3>
    </div>
    <div class="patient-result-container">
      <div
        #patientResults
        class="patient-result-table-container"
        role="grid"
        (scroll)="checkOverflow()"
      >
        <table mat-table [dataSource]="dataSource">
          <caption>
            {{
              t("result")
            }}
          </caption>
          <ng-container matColumnDef="patient" sticky>
            <th mat-header-cell *matHeaderCellDef tabindex="-1" role="button">
              {{ t("tableHeaders.patient") }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              tabindex="-1"
              [class]="element.evenRow ? 'even-row' : 'odd-row'"
              [class.group-start]="element.groupStart"
              [class.group-end]="element.groupEnd"
            >
              {{ element.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="dateOfBirth">
            <th mat-header-cell *matHeaderCellDef tabindex="-1" role="button">
              {{ t("tableHeaders.dateBirth") }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [class]="element.evenRow ? 'even-row' : 'odd-row'"
              [class.group-start]="element.groupStart"
              [class.group-end]="element.groupEnd"
            >
              {{ element.birthDate ? (element.birthDate | localizedDate) : "" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="gestationalAge">
            <th mat-header-cell *matHeaderCellDef tabindex="-1" role="button">
              {{ t("tableHeaders.gestationalAge") }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [class]="element.evenRow ? 'even-row' : 'odd-row'"
              [class.group-start]="element.groupStart"
              [class.group-end]="element.groupEnd"
            >
              {{ element.gestationalAge }}
            </td>
          </ng-container>
          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef tabindex="-1" role="button">
              {{ t("tableHeaders.weight") }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [class]="element.evenRow ? 'even-row' : 'odd-row'"
              [class.group-start]="element.groupStart"
              [class.group-end]="element.groupEnd"
            >
              {{ element.weight }}
            </td>
          </ng-container>
          <ng-container matColumnDef="department">
            <th mat-header-cell *matHeaderCellDef tabindex="-1" role="button">
              {{ t("tableHeaders.department") }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [class]="element.evenRow ? 'even-row' : 'odd-row'"
              [class.group-start]="element.groupStart"
              [class.group-end]="element.groupEnd"
            >
              {{ element.encounter.departmentName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="motherName">
            <th mat-header-cell *matHeaderCellDef tabindex="-1" role="button">
              {{ t("tableHeaders.surnameNameMother") }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [class]="element.evenRow ? 'even-row' : 'odd-row'"
              [class.group-start]="element.groupStart"
              [class.group-end]="element.groupEnd"
            >
              {{ element.parent }}
            </td>
          </ng-container>
          <ng-container matColumnDef="encounter" stickyEnd>
            <th mat-header-cell *matHeaderCellDef>
              {{ t("tableHeaders.encounters") }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [class]="element.evenRow ? 'even-row' : 'odd-row'"
              [class.group-start]="element.groupStart"
              [class.group-end]="element.groupEnd"
            >
              <span
                class="encounter"
                [class.current-encounter]="!element.encounter.endDate"
              >
                <button
                  type="button"
                  *ngIf="element.encounter.visible"
                  (click)="navigate(element)"
                >
                  {{ encounterLinkText(element.encounter) }}
                </button>
                <ng-container *ngIf="!element.encounter.visible">{{
                  encounterLinkText(element.encounter)
                }}</ng-container>
              </span>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="table-header-row"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{
              selectedPatient:
                selectedRowPatient?.patientId == row.id &&
                selectedRowPatient?.departmentId == row.departmentId
            }"
          ></tr>
        </table>
        <natea-cc-more-items-pill
          [visible]="isOverflowing"
        ></natea-cc-more-items-pill>
      </div>
    </div>
  </div>
  <div class="table-footer">
    <natea-button
      (buttonClick)="closeModal()"
      [color]="colorVariantSecondary"
      [label]="t('close')"
    >
    </natea-button>
  </div>
</ng-container>
