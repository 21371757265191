import { OptionsItem } from 'natea-components';
import { optionItemFromProblemType } from '../../../shared/utils/optional-item-entity-utils';
import { PatientProblem } from '../interfaces/patient-problem';
import { ProblemType } from '../interfaces/problem-type';

export const getOngoingProblems = (
  problems: PatientProblem[] | undefined
): OptionsItem<ProblemType>[] | undefined => {
  if (problems === undefined) {
    return undefined;
  }

  const typesMap = problems?.reduce(
    (map: Map<string, OptionsItem<ProblemType>>, problem) => {
      if (!map.has(problem.problemType.id) && !problem.endDate) {
        map.set(
          problem.problemType.id,
          optionItemFromProblemType(problem.problemType)
        );
      }
      return map;
    },
    new Map<string, OptionsItem<ProblemType>>()
  );
  return Array.from(typesMap.values());
};
