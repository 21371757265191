/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Request object for creating and updating the termination context of a procedure.
 */
export interface ProcedureTerminationContextRequestResource { 
    /**
     * Additional notes regarding the adverse events following the termination of the procedure, if applicable.
     */
    adverseEvents?: string;
    /**
     * Timestamp indicating when the procedure was terminated.
     */
    terminatedAt: string;
}

