/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a clinical note log entry resource type.
 */
export interface ClinicalNoteLogEntryResource { 
    /**
     * Textual content of the clinical note.
     */
    additionalNotes: string;
    /**
     * Timestamp when the clinical note was assessed, if applicable.
     */
    assessedAt?: string;
    /**
     * Unique identifier for a related clinical note, if applicable.
     */
    clinicalNoteRefId?: string;
    /**
     * Timestamp when the clinical note was created.
     */
    createdAt: string;
    /**
     * Unique identifier for the user who created the clinical note.
     */
    createdById: string;
    /**
     * Unique identifier for the detected problem that this clinical note refers to, if applicable.
     */
    detectedProblemId?: string;
    /**
     * Unique identifier for the hospitalization that contains this clinical note.
     */
    encounterId: string;
    /**
     * Unique identifier for the clinical note.
     */
    id: string;
    /**
     * Timestamp of the user\'s observation of the pediatric patient.
     */
    observedAt: string;
    /**
     * The reason for rejection of the clinical note, if applicable.
     */
    rejectionReason?: string;
    /**
     * The application-domain encoded status of the clinical note.
     */
    status: string;
    /**
     * The application-domain encoded type of the clinical note.
     */
    type: string;
    /**
     * Numerical indicator: \'1\' for the original clinical note, higher values for replacements.
     */
    version: number;
}

