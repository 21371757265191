import {
  CreateItemRequest,
  GeneralResponseListStoreItemDeletedResource,
  GeneralResponseListStoreItemResource,
  GeneralResponseStoreItemResource,
  StoreItemDeletedResource,
} from '../../../core/generated/api';
import {
  BoardContent,
  BoardType,
  OriginType,
} from '../interfaces/board-content';
import { BoardItemDelete } from '../interfaces/board-item-delete';
import { BoardOrigin } from '../interfaces/board-note-content-view';

export interface BoardNoteToSave {
  itemType: string;
  createItemRequest: CreateItemRequest;
}

export class BoardAdapters {
  static boardGetDataBeToFe(
    storageNote: GeneralResponseListStoreItemResource
  ): BoardContent[] {
    if (storageNote.payload) {
      return storageNote.payload.map((item) => {
        const metadata = item.item['metadata'];
        const description =
          metadata && 'description' in metadata
            ? (metadata.description as string)
            : '';
        const entityFrom =
          metadata && 'entityFrom' in metadata
            ? (metadata.entityFrom as OriginType)
            : 'problems';

        return {
          id: item.id,
          description: description,
          creationDate: new Date(item.createdAt),
          entityFrom,
        };
      });
    } else {
      return [];
    }
  }

  static boardDataFeToBeDelete(
    userId: string,
    objectType: BoardType,
    storedItemId: string
  ): BoardItemDelete {
    return {
      userId,
      originEntityType: objectType,
      storedItemId,
    };
  }

  static boardDeleteItemsBeToFe(
    response: GeneralResponseListStoreItemDeletedResource
  ): StoreItemDeletedResource[] {
    return response.payload ?? [];
  }

  static boardSaveContentFeToBe(boardContent: BoardContent): BoardNoteToSave {
    const createItemRequest: CreateItemRequest = {
      item: { metadata: boardContent },
      itemVersion: '1', // TODO ask where to take this value
    };
    return {
      itemType: 'pasteboard',
      createItemRequest,
    };
  }

  static boardSaveContentBeToFe(
    response: GeneralResponseStoreItemResource
  ): BoardContent | undefined {
    if (response.payload) {
      const description =
        (response.payload.item['metadata'] as any).description ?? '';

      if (typeof response.payload.item['from'] === 'string') {
        if (response.payload.item['from'] === 'clinical-notes') {
          origin = BoardOrigin.NoteCliniche;
        } else {
          origin = BoardOrigin.Problems;
        }
      }

      return {
        //userId: response.payload.userId,
        description: description,
        id: response.payload.id,
        creationDate: new Date(response.payload.createdAt),
        entityFrom: (response.payload.item['metadata'] as any).entityFrom,
      };
    } else {
      return;
    }
  }
}
