import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService } from 'natea-components';
import { catchError, exhaustMap, map, of, switchMap } from 'rxjs';
import { ApiError } from '../../../shared/entities/errors';
import { SNACK_BAR_DEBOUNCE_TIME_MS } from '../../../shared/utils/constants';
import { selectProblemsUiState } from '../../patients/store/selected-patient/selected-patient.selectors';
import { BoardContent } from '../interfaces/board-content';
import { BoardItemDelete } from '../interfaces/board-item-delete';
import { BoardUserData } from '../interfaces/board-user-type';
import { BoardWebAPI } from '../webapis/board.webapi';
import { BoardActions } from './board.actions';
@Injectable()
export class BoardEffects {
  loadBoardContent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoardActions.LoadBoardContents),
      exhaustMap(({ data }: { data: BoardUserData }) => {
        return this.boardWebAPI
          .getBoardContent(data.userId, '', data.originEntityType)
          .pipe(
            map((boardContents: BoardContent[]) => ({
              type: BoardActions.LoadBoardContentsSuccess,
              boardContents,
            })),
            catchError((error: ApiError) =>
              of({
                type: BoardActions.LoadBoardContentsFailure,
                error,
              })
            )
          );
      })
    );
  });

  saveBoardContent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoardActions.SaveBoardContentToBoard),
      exhaustMap(({ content }: { content: BoardContent }) => {
        return this.boardWebAPI.saveBoardContent(content, '').pipe(
          map((content: BoardContent | undefined) => ({
            type: BoardActions.SaveBoardContentToBoardSuccess,
            content,
          })),
          catchError((error: ApiError) =>
            of({
              type: BoardActions.SaveBoardContentToBoardFailure,
              error,
            })
          )
        );
      })
    );
  });

  saveBoardContentSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BoardActions.SaveBoardContentToBoardSuccess),
        map(() => {
          this.notiService.showSnackbar(
            this.translocoService.translate('board.successfullyAdded'),
            this.translocoService.translate('problems.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS,
            'bottom'
          );
        })
      );
    },
    { dispatch: false }
  );

  saveBoardContentFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BoardActions.SaveBoardContentToBoardFailure),
        map(() => {
          this.notiService.showSnackbar(
            this.translocoService.translate('board.errorAdding'),
            this.translocoService.translate('problems.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS,
            'bottom'
          );
        })
      );
    },
    { dispatch: false }
  );

  deleteBoardItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoardActions.DeleteSingleBoardNote),
      exhaustMap(({ itemDeleted }: { itemDeleted: BoardItemDelete }) => {
        return this.boardWebAPI
          .deleteItem(
            itemDeleted.userId,
            '',
            'pasteboard',
            itemDeleted.originEntityType,
            itemDeleted.storedItemId
          )
          .pipe(
            map((response) => ({
              type: BoardActions.DeleteSingleNoteBoardSuccess,
              id: response?.id,
            })),
            catchError((error: ApiError) =>
              of({
                type: BoardActions.DeletionBoardNoteFailure,
                error,
              })
            )
          );
      })
    );
  });

  deleteBoardContentFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BoardActions.DeleteSingleBoardNoteFailure),
        map(() => {
          return of(
            this.notiService.showSnackbar(
              this.translocoService.translate('board.errorDeletingItem'),
              this.translocoService.translate('problems.close'),
              'error-snackbar',
              SNACK_BAR_DEBOUNCE_TIME_MS,
              'bottom'
            )
          );
        })
      );
    },
    { dispatch: false }
  );

  deleteBoardContentSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BoardActions.DeleteSingleNoteBoardSuccess),
        map(() => {
          this.notiService.showSnackbar(
            this.translocoService.translate('board.successfullyDeleted'),
            this.translocoService.translate('problems.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS,
            'bottom'
          );
        })
      );
    },
    { dispatch: false }
  );

  deleteAllBoardNotes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoardActions.DeleteBoardItems),
      concatLatestFrom(() => this.store.select(selectProblemsUiState)),
      switchMap(([{ data }, patient]) => {
        return this.boardWebAPI.deleteAll(data, patient.patientId).pipe(
          map((response) => ({
            type: BoardActions.DeleteAllBoardNotesSuccess,
            response,
          })),
          catchError((error: ApiError) =>
            of({
              type: BoardActions.DeletionBoardNoteFailure,
              error,
            })
          )
        );
      })
    );
  });

  deleteBoardItemsSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BoardActions.DeleteAllBoardNotesSuccess),
        map(() => {
          this.notiService.showSnackbar(
            this.translocoService.translate('board.successfullyDeletedItems'),
            this.translocoService.translate('problems.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS,
            'bottom'
          );
        })
      );
    },
    { dispatch: false }
  );

  deleteBoardItemsFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BoardActions.DeletionBoardNoteFailure),
        map(() => {
          this.notiService.showSnackbar(
            this.translocoService.translate('board.errorDeleting'),
            this.translocoService.translate('problems.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS,
            'bottom'
          );
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private boardWebAPI: BoardWebAPI,
    private notiService: SnackbarService,
    private translocoService: TranslocoService,
    private store: Store
  ) {}
}
