<mat-form-field
  class="natea-input-field"
  [color]="isError ? 'warn' : undefined"
  [class.fill-width]="fillParentWidth"
  [class.auto-width]="autoWidth"
  [class.no-hint]="hintLabel === undefined && errorMessages === undefined"
  [class.warn]="errorMessages !== undefined"
>
  <mat-label [class.disabled]="isDisabled" [class.input-filled]="isAddClass">{{
    label
  }}</mat-label>

  <natea-icons
    matPrefix
    *ngIf="leftIcon"
    [name]="leftIcon"
    class="icon left-icon"
    (iconClick)="onLeftIconClick($event)"
    [class.disabled]="isDisabled"
    [class.not-clickable]="!this.leftIconClick.observed"
  ></natea-icons>

  <input
    matInput
    class="natea-input"
    [type]="type"
    [placeholder]="placeholder"
    [value]="inputValue"
    [attr.maxlength]="maxLength !== undefined ? maxLength : null"
    [attr.min]="min !== undefined ? min : null"
    [attr.max]="max !== undefined ? max : null"
    (input)="inputChanged($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (keydown)="onKeyDown($event)"
    [disabled]="isDisabled"
    *ngIf="type !== 'duration'"
  />

  <input
    matInput
    class="natea-input"
    [type]="'text'"
    [placeholder]="placeholder"
    (input)="inputChanged($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (keydown)="onKeyDown($event)"
    [disabled]="isDisabled"
    *ngIf="type === 'duration'"
    nateaDurationPicker
  />

  <natea-icons
    matSuffix
    *ngIf="rightIcon"
    class="icon right-icon"
    [class.not-clickable]="!this.leftIconClick.observed"
    [class.disabled]="isDisabled"
    [name]="rightIcon"
    (iconClick)="onRightIconClick($event)"
  ></natea-icons>

  <mat-hint
    *ngIf="hintLabel !== undefined && errorMessages === undefined"
    class="assistive-text"
    [class.warn]="isError"
    [class.selected]="hasFocus"
    [class.disabled]="isDisabled"
  >
    {{ hintLabel }}
  </mat-hint>

  <mat-hint class="warn" *ngIf="errorMessages !== undefined">
    <natea-field-error [errorMessages]="errorMessages"></natea-field-error>
  </mat-hint>
</mat-form-field>
