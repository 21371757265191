<nav (document:click)="onClick($event)" *transloco="let t">
  <!-- <div class="left-part" [class.has-sibling]="filter.innerHTML.trim()"> -->
  <div class="left-part" [class.has-sibling]="hasSibling()">
    <ng-container *ngFor="let tab of tabs; let i = index">
      <ng-container *ngIf="tab.isChecked">
        <div class="tab" [class.disable-all-tabs]="isTabsDisabled">
          <div class="container-text" [class.active]="i === activeIndex">
            <div
              *ngIf="(i === activeIndex && hasDote) || hasAlwaysDote"
              class="dote"
              [class.required-field-has-value]="tab.isRequiredFieldHasValue"
            >
              &#9679;
            </div>
            <a (click)="showAndSwitchToTab(i)">
              {{ t(tab.label, tab.data) }} {{ filterContent }}
            </a>

            <button type="button" (click)="closeTabItem(i)" *ngIf="hasCloseTab">
              <natea-icons
                *ngIf="i !== 0"
                name="close"
                class="icon"
                width="0.625rem"
                height="0.625rem"
              ></natea-icons>
            </button>
          </div>
          <div class="curve-corner" [class.active]="i === activeIndex"></div>
        </div>
      </ng-container>
    </ng-container>
    <div
      #dropdownContainer
      class="add-container"
      *ngIf="tabs.length > 1 && isCloseBle"
    >
      <button type="button" (click)="dropDownToggle()">
        <div class="add">
          <natea-icons
            name="add"
            class="icon icon-add"
            [fill]="grayScale70"
          ></natea-icons>
        </div>
        <div class="curve-corner open-tab"></div>
      </button>
      <div class="dropdown-container" *ngIf="showDropDown">
        <natea-checkbox
          *ngFor="let recovery of tabs.slice(1); let i = index"
          [label]="t(recovery.label, recovery.data)"
          [checked]="recovery.isChecked"
          [value]="recovery.id.toString()"
          (checkboxChange)="onCheckboxChange($event, i + 1)"
        ></natea-checkbox>
      </div>
    </div>
  </div>
  <div #filter class="filter">
    <ng-content select="[filter]"></ng-content>
  </div>
</nav>

<main [class.scrollable]="isMainContentScrollable">
  <ng-content></ng-content>
</main>
