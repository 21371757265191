/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  ProblemCatalogApiService,
  SearchResponseListProblemCatalogEntryResource,
} from '../generated/api';
import getProblemsMockedResponse from './get-problems-mocked-response';

@Injectable({
  providedIn: 'root',
})
export class MockedProblemsDataApiService extends ProblemCatalogApiService {
  public override getProblemCatalog(
    partialName?: string | undefined,
    itemPage?: number | undefined,
    page?: number | undefined,
    observe?: any,
    reportProgress?: boolean,
    options?:
      | {
          httpHeaderAccept?: 'application/json' | undefined;
          context?: HttpContext | undefined;
        }
      | undefined
  ): Observable<any> {
    const result: SearchResponseListProblemCatalogEntryResource =
      getProblemsMockedResponse(page, partialName);
    return of(result);
    //.pipe(delay(2000));
  }
}
