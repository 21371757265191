import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NateaIconsModule } from '../../../icons/natea-icons.module';
import { LoaderComponent } from '../../loader/loader.component';
import { FieldErrorComponent } from '../field-error/field-error.component';
import { FieldComponent } from '../field.component';

@Component({
  standalone: true,
  selector: 'natea-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    NateaIconsModule,
    LoaderComponent,
    ReactiveFormsModule,
    FieldErrorComponent,
  ],
})
export class FormFieldComponent extends FieldComponent {
  @Input() fieldClass?: string;
  @Input() isFilled = false;
}
