import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'natea-field-error',
  standalone: true,
  templateUrl: './field-error.component.html',
  styleUrls: ['./field-error.component.scss'],
  imports: [MatFormFieldModule, CommonModule],
})
export class FieldErrorComponent {
  @Input() errorMessages?: string[];
}
