import { createSelector } from '@ngrx/store';
import { PatientProblem } from '../../../../problems/interfaces/patient-problem';
import { isPatientProblemContainedInEncounter } from '../../../../problems/utils/problem-utils';
import {
  selectOngoingEncounter,
  selectSelectedPatientData,
  selectShownEncounter,
} from '../../patients.selectors';

export const selectPatientProblems = createSelector(
  selectSelectedPatientData,
  (state): PatientProblem[] | undefined => state?.problems
);

export const selectOngoingEncounterProblems = createSelector(
  selectSelectedPatientData,
  selectOngoingEncounter,
  (patientData, encounter): PatientProblem[] | undefined =>
    encounter
      ? patientData?.problems?.filter(
          (problem) =>
            problem.encounterId === encounter?.id ||
            (problem.patientId === patientData?.id &&
              isPatientProblemContainedInEncounter(problem, encounter))
        )
      : undefined
);

export const selectShownEncounterProblems = createSelector(
  selectSelectedPatientData,
  selectShownEncounter,
  (patientData, encounter): PatientProblem[] | undefined => {
    const result = encounter
      ? patientData?.problems?.filter(
          (problem) =>
            problem.encounterId === encounter.id ||
            (problem.patientId === patientData?.id &&
              isPatientProblemContainedInEncounter(problem, encounter))
        )
      : undefined;
    return result;
  }
);
