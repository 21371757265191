import { createAction, props } from '@ngrx/store';
import { FamilyAnamnesis } from '../entities/family-anamnesis';
import { ApiError } from 'src/app/shared/entities/errors';
import { AllFamilyPathologies } from '../entities/all-family-pathologies';

export enum FamilyAnamnesisActions {
  LoadAllFamilyAnamnesis = '[Family Anamnesis] Load All Family Anamnesis',
  LoadAllFamilyAnamnesisSuccess = '[Family Anamnesis] Load All Family Anamnesis Success',
  LoadAllFamilyAnamnesisFailure = '[Family Anamnesis] Load All Family Anamnesis Failure',
  InsetNewPathology = '[Family Anamnesis] Insert New Pathology',
  ShownModalInsertNewPathology = '[Family Anamnesis] Show Modal Insert New Pathology',
  HideModalInsertNewPathology = '[Family Anamnesis] Hide Modal Insert New Pathology',
  RemovePathology = '[Family Anamnesis] Remove Pathology',
  RemovePathologySuccess = '[Family Anamnesis] Remove Pathology Success',
  RemovePathologyFailure = '[Family Anamnesis] Remove Pathology Failure',

  EmptyPathologies = '[Family Anamnesis] Empty Pathologies',

  RemoveAllFamilyAnamnesis = '[Family Anamnesis] Remove All Family Anamnesis',
  RemoveAllFamilyAnamnesisSuccess = '[Family Anamnesis] Remove All Family Anamnesis Success',
  RemoveAllFamilyAnamnesisFailure = '[Family Anamnesis] Remove All Family Anamnesis Failure',

  ShowWarningModalRemoveAll = '[Family Anamnesis] Show Warning Modal Remove All',
  HideWarningModalRemoveAll = '[Family Anamnesis] Hide Warning Modal Remove All',
  SaveAllFamilyAnamnesis = '[Family Anamnesis] Save All Family Anamnesis',
  SaveAllFamilyAnamnesisSuccess = '[Family Anamnesis] Save All Family Anamnesis Success',
  SaveAllFamilyAnamnesisFailure = '[Family Anamnesis] Save All Family Anamnesis Failure',
  GeneralFamilyAnamnesisNotes = '[Family Anamnesis] General Family Anamnesis Notes',
  CheckDiseaseExistence = '[Family Anamnesis] Check Disease Existence',
  DiseaseExist = '[Family Anamnesis] Disease Exist',
  UpdateFamilyAnamnesisPathology = '[Family Anamnesis] Update Family Anamnesis Pathology',
  InsetNewPathologySuccess = '[Family Anamnesis] Insert New Pathology Success',
  InsetNewPathologyFailure = '[Family Anamnesis] Insert New Pathology Failure',
}

export const loadAllFamilyAnamnesis = createAction(
  FamilyAnamnesisActions.LoadAllFamilyAnamnesis
);

export const insetNewFamilyAnamnesis = createAction(
  FamilyAnamnesisActions.InsetNewPathology,
  props<{ pathology: FamilyAnamnesis }>()
);

export const shownModalInsertNewPathology = createAction(
  FamilyAnamnesisActions.ShownModalInsertNewPathology
);

export const hideModalInsertNewPathology = createAction(
  FamilyAnamnesisActions.HideModalInsertNewPathology
);

export const removePathology = createAction(
  FamilyAnamnesisActions.RemovePathology,
  props<{ id: string }>()
);

export const removePathologySuccess = createAction(
  FamilyAnamnesisActions.RemovePathologySuccess,
  props<{
    allFamilyPathologies?: AllFamilyPathologies;
    patientId: string;
    encounterId: string;
  }>()
);

export const removePathologyFailure = createAction(
  FamilyAnamnesisActions.RemovePathologyFailure,
  props<{ error: ApiError }>()
);

export const emptyPathologies = createAction(
  FamilyAnamnesisActions.EmptyPathologies,
  props<{ hasPathology: boolean }>()
);

export const removeAllFamilyAnamnesis = createAction(
  FamilyAnamnesisActions.RemoveAllFamilyAnamnesis
);

export const removeAllFamilyAnamnesisSuccess = createAction(
  FamilyAnamnesisActions.RemoveAllFamilyAnamnesisSuccess,
  props<{
    patientId: string;
    encounterId: string;
    allFamilyPathologies: AllFamilyPathologies;
  }>()
);

export const removeAllFamilyAnamnesisFailure = createAction(
  FamilyAnamnesisActions.RemoveAllFamilyAnamnesisFailure,
  props<{ error: ApiError }>()
);

export const showWarningModalRemoveAll = createAction(
  FamilyAnamnesisActions.ShowWarningModalRemoveAll
);

export const hideWarningModalRemoveAll = createAction(
  FamilyAnamnesisActions.HideWarningModalRemoveAll
);

export const saveAllFamilyAnamnesis = createAction(
  FamilyAnamnesisActions.SaveAllFamilyAnamnesis,
  props<{
    allFamilyPathologies: AllFamilyPathologies;
  }>()
);

export const saveAllFamilyAnamnesisSuccess = createAction(
  FamilyAnamnesisActions.SaveAllFamilyAnamnesisSuccess,
  props<{
    allPathologies: AllFamilyPathologies;
    patientId: string;
    encounterId: string;
  }>()
);

export const loadAllFamilyAnamnesisSuccess = createAction(
  FamilyAnamnesisActions.LoadAllFamilyAnamnesisSuccess,
  props<{
    allPathologies: AllFamilyPathologies;
    patientId: string;
    encounterId: string;
  }>()
);

export const loadAllFamilyAnamnesisFailure = createAction(
  FamilyAnamnesisActions.LoadAllFamilyAnamnesisFailure,
  props<{ error: ApiError }>()
);

export const generalFamilyAnamnesisNote = createAction(
  FamilyAnamnesisActions.GeneralFamilyAnamnesisNotes,
  props<{ note: string }>()
);

export const checkDiseaseExistence = createAction(
  FamilyAnamnesisActions.CheckDiseaseExistence,
  props<{ pathology: FamilyAnamnesis }>()
);

export const insetNewPathologySuccess = createAction(
  FamilyAnamnesisActions.InsetNewPathologySuccess,
  props<{
    pathology: AllFamilyPathologies;
    patientId: string;
    encounterId: string;
  }>()
);

export const insetNewPathologyFailure = createAction(
  FamilyAnamnesisActions.InsetNewPathologyFailure,
  props<{ error: ApiError }>()
);

export const diseaseExist = createAction(FamilyAnamnesisActions.DiseaseExist);
