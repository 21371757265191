import { createSelector } from '@ngrx/store';
import { NutritionalTherapy } from '../../../../therapies/nutritional-therapies/entities/nutritional-therapy';
import { EncounterData } from '../../patients.reducers';
import { selectShownEncounter } from '../../patients.selectors';

export const selectShownEncounterNutritionalTherapies = createSelector(
  selectShownEncounter,
  (state: EncounterData | undefined): NutritionalTherapy[] | undefined =>
    state?.nutritionalTherapies
);
