<button
  matRipple
  [matRippleColor]="'#9accd533'"
  mat-button
  [matRippleDisabled]="disabled"
  [disabled]="disabled"
  [type]="buttonType"
  [class.no-label]="!label"
  [class.small]="size === 'small'"
  [class.small-with-icon]="
    size === 'small' && (leftIcon || rightIcon || centerIcon)
  "
  [class.large]="size === 'large'"
  [class.large-with-icon]="
    size === 'large' && (leftIcon || rightIcon || centerIcon)
  "
  [class.large-without-icon]="
    size === 'large' && !(leftIcon || rightIcon || centerIcon)
  "
  [attr.tabindex]="tabindex ?? null"
  [class.primary]="color === 'primary'"
  [class.secondary]="color === 'secondary'"
  [class.tertiary]="color === 'tertiary'"
  [class.error-primary]="isError && color === 'primary'"
  [class.error-secondary-tertiary]="
    isError && (color === 'secondary' || color === 'tertiary')
  "
  (click)="onClick($event)"
>
  <natea-icons
    *ngIf="leftIcon"
    [name]="leftIcon"
    [title]="iconTitle"
    [class.left-icon]="leftIcon"
    [class.primary]="color === 'primary'"
    [class.secondary]="color === 'secondary'"
    [class.tertiary]="color === 'tertiary'"
    class="icon"
  ></natea-icons>
  <natea-icons
    *ngIf="centerIcon"
    [name]="centerIcon"
    [title]="iconTitle"
    [class.center-icon]="centerIcon"
    class="icon"
    [class.primary]="color === 'primary'"
    [class.secondary]="color === 'secondary'"
    [class.tertiary]="color === 'tertiary'"
  ></natea-icons>
  <span [class.invisible]="showLoader">
    {{ label }}
  </span>

  <natea-loader
    *ngIf="showLoader"
    [diameter]="16"
    [strokeWidth]="2"
    [color]="
      color === 'primary' ? LoaderVariants.SECONDARY : LoaderVariants.PRIMARY
    "
  ></natea-loader>

  <natea-icons
    *ngIf="rightIcon"
    [name]="rightIcon"
    [title]="iconTitle"
    class="icon"
    [class.right-icon]="rightIcon"
    [class.primary]="color === 'primary'"
    [class.secondary]="color === 'secondary'"
    [class.tertiary]="color === 'tertiary'"
  ></natea-icons>
</button>
