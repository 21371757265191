import { TranslocoService } from '@ngneat/transloco';
import { NateaIconsName, OptionsItem } from 'natea-components';
import { ProcedureOccurrenceDurationType } from '../../../shared/components/multi-section-timeline/models/multi-section-timeline-instance';
import { Procedure } from '../entities/procedure';
import {
  ProcedureDevice,
  ProcedureLocation,
  ProcedureRoute,
} from '../entities/procedure-data';
import { ProcedureOccurrenceMovementType } from '../entities/procedure-movement';
import {
  ProcedureOccurrence,
  ProcedureOccurrenceState,
} from '../entities/procedure-occurrence';

export const iconForProcedureOccurrenceState = (
  state: ProcedureOccurrenceState
): NateaIconsName => {
  switch (state) {
    case ProcedureOccurrenceState.Planned:
      return 'pause';
    case ProcedureOccurrenceState.Running:
      return 'play';
    case ProcedureOccurrenceState.Executed:
      return 'terminazione';
  }
};

export const labelForProcedureOccurrenceState = (
  state: ProcedureOccurrenceState,
  transloco: TranslocoService
): string => {
  switch (state) {
    case ProcedureOccurrenceState.Planned:
      return transloco.translate('procedures.state.planned');
    case ProcedureOccurrenceState.Running:
      return transloco.translate('procedures.state.running');
    case ProcedureOccurrenceState.Executed:
      return transloco.translate('procedures.state.completed');
  }
};

export const optionItemFromProcedure = (
  procedure: Procedure | undefined
): OptionsItem<Procedure> | null =>
  procedure
    ? {
        id: procedure.id,
        label: procedure.description,
        data: procedure,
      }
    : null;

export const optionItemFromProcedureDevice = (
  data: ProcedureDevice | null | undefined
): OptionsItem<ProcedureDevice> | null =>
  data
    ? {
        id: data.id,
        label: data.description,
        data,
      }
    : null;

export const optionItemFromProcedureLocation = (
  data: ProcedureLocation | null | undefined
): OptionsItem<ProcedureLocation> | null =>
  data
    ? {
        id: data.id,
        label: data.description,
        data,
      }
    : null;

export const optionItemFromProcedureRoute = (
  data: ProcedureRoute | null | undefined
): OptionsItem<ProcedureRoute> | null =>
  data
    ? {
        id: data.id,
        label: data.description,
        data,
      }
    : null;

export const terminationDateFromProcedureOccurrence = (
  occurrence: ProcedureOccurrence | undefined
): Date | null => {
  if (occurrence?.state === ProcedureOccurrenceState.Executed) {
    if (occurrence.type === ProcedureOccurrenceDurationType.Prolonged) {
      return occurrence.terminationDate ?? null;
    }
    return occurrence.executionDate;
  }
  return null;
};

export const optionItemFromProcedureMovementType = (
  movementType: ProcedureOccurrenceMovementType,
  transloco: TranslocoService
): OptionsItem<ProcedureOccurrenceMovementType> => ({
  id: movementType,
  label: labelForProcedureMovementType(movementType, transloco),
  data: movementType,
});

export const labelForProcedureMovementType = (
  type: ProcedureOccurrenceMovementType,
  transloco: TranslocoService
): string => {
  switch (type) {
    case ProcedureOccurrenceMovementType.Inserted:
      return transloco.translate('procedures.movementsTable.actionInserted');
    case ProcedureOccurrenceMovementType.Removed:
      return transloco.translate('procedures.movementsTable.actionRemoved');
    case ProcedureOccurrenceMovementType.NotApplicable:
      return transloco.translate('procedures.movementsTable.actionNotApplicable');
    }
};
