<div
  class="page-container extra-sub-header"
  [class.simple-content]="!complexContent"
  [class.collapse-sub-header]="isCollapseSubHeader"
  [class.with-sidebar]="hasSidebar"
  [class.with-sidebar-expanded]="
    hasSidebar && ((isMenuOpened$ | async) ?? false)
  "
  *transloco="let t; read: 'common.buttons'"
>
  <header class="main-header">
    <natea-cc-main-header></natea-cc-main-header>
  </header>
  <header class="sub-header" [class]="subHeaderAlignClass">
    <ng-content select="[sub-header]"></ng-content>
  </header>
  <header class="header-content" [class]="headerContentAlignClass">
    <ng-content select="[header-content]"></ng-content>
  </header>
  <aside class="sidebar" *ngIf="hasSidebar">
    <button type="button" class="sidebar-toggle" (click)="toggleSidebar()">
      <natea-icons
        *ngIf="!(isMenuOpened$ | async) ?? false"
        name="arrow-right"
        class="icon"
        fill="gray"
        [title]="t('expandSidebar')"
      ></natea-icons>
      <natea-icons
        *ngIf="(isMenuOpened$ | async) ?? false"
        name="arrow-left"
        class="icon"
        fill="gray"
        [title]="t('collapseSidebar')"
      ></natea-icons>
    </button>
    <div class="sidebar-content">
      <natea-cc-sidebar
        [collapsed]="!(isMenuOpened$ | async)"
      ></natea-cc-sidebar>
    </div>
  </aside>

  <section
    class="main-content"
    [class.complex]="complexContent"
    [class.scroll-main-content]="isMainContentScroll"
  >
    <ng-content select="[main-content]"></ng-content>
  </section>

  <ng-content select="[modals]"></ng-content>
</div>
