import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { NateaIconsModule } from '../../../icons/natea-icons.module';
import { ButtonComponent, ButtonVariants } from '../../button/button.component';
import { SizeType } from '../model/size-type';

@Component({
  selector: 'natea-base-modal',
  standalone: true,
  imports: [CommonModule, ButtonComponent, NateaIconsModule],
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
  animations: [
    trigger('open', [
      state('true', style({ opacity: '1', display: 'flex' })),
      state('false', style({ opacity: '0', display: 'flex' })),
      transition('false => true', [
        style({ display: 'flex' }),
        animate('.5s ease-in'),
      ]),
      transition('true => false', [animate('.5s ease-in')]),
    ]),
  ],
})
export class BaseModalComponent<T> {
  @Input() isComplexHeader = false;
  @Input() modalTitle = '';
  @Input() modalSubtitle = '';
  @Input() closeButton = false;

  /**
   * Used to customize the style of the modals that are opened above another
   */
  @Input() secondary = false;

  @Input() isCreation = false;

  @Input() closeButtonDisabled = false;

  @Input() modalSize: SizeType = SizeType.LARGE;

  @Output() openModal: EventEmitter<void> = new EventEmitter<void>();

  @Output() closeModal: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() saveData: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (!this.closeButtonDisabled) {
      this.closeModal.emit(event);
    }
  }

  @HostBinding('attr.role') readonly role: string = 'dialog';

  @HostBinding('@open') private _isOpen = true;

  @HostBinding('attr.aria-modal')
  data?: T;
  modalType = SizeType;

  constructor(private readonly host: ElementRef<HTMLElement>) {}

  buttonVariants = ButtonVariants;

  open(data?: T): void {
    this._isOpen = true;
    this.data = data;
    this.openModal.emit();
  }

  readonly onClose = (event: Event): void => {
    if (this.closeButton) {
      this.host.nativeElement.remove();
      this._isOpen = false;
    } else {
      this._isOpen = true;
    }

    this.closeModal.emit(event);
  };

  readonly onSave = (): void => {
    this.saveData.emit(true);
  };

  readonly onCancel = (): void => {
    this._isOpen = false;
    this.closeModal.emit();

    this.host.nativeElement.remove();
  };
}
