export enum WarningStatus {
  None = 'none',
  Warning = 'warning',
  Critical = 'critical',
}

export interface Patient {
  id: string;
  departmentId: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
  currentBed: string;
  measurements?: PatientMeasurements;
  parent?: {
    firstName: string;
    lastName: string;
    birthDate: Date;
    id: string;
  };
  gestationalAge?: number;
  weight?: number;
  encounterStartDate?: Date;
  note?: string;
  warnings?: {
    therapy: WarningStatus;
    procedure: WarningStatus;
    nursing: WarningStatus;
    nutrition: WarningStatus;
    diagnosis: WarningStatus;
    screening: WarningStatus;
  };
}

export interface PatientMeasurements {
  eg: string;
  epcEc: string;
  weight: number;
  lastWeight: number;
  lastWeightDate: Date;
  weightLoss: number;
}
