import { Sort } from '@angular/material/sort';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { SelectedTherapyOccurrenceIds } from '../../common/entities/selected-therapy';
import { NutritionalTherapyCatalogs } from '../entities/nutritional-therapy-catalogs';
import { NutritionalParenteralBag } from '../entities/nutritional-therapy-type';
import {
  clearSelectedParenteralBag,
  confirmAbortNutritionalModal,
  confirmAbortNutritionalTherapyFailure,
  confirmAbortNutritionalTherapySuccess,
  confirmDeleteNutritionalTherapy,
  confirmDeleteNutritionalTherapyFailure,
  confirmDeleteNutritionalTherapySuccess,
  createNutritionalTherapy,
  createNutritionalTherapyFailure,
  createNutritionalTherapySuccess,
  deselectNutritionalTherapy,
  downloadNutritionalTherapyCatalogs,
  downloadNutritionalTherapyCatalogsFailure,
  downloadNutritionalTherapyCatalogsSuccess,
  editEnteralTherapy,
  editEnteralTherapyFailure,
  editEnteralTherapySuccess,
  editParenteralTherapy,
  editParenteralTherapySuccess,
  hideConfirmAbortNutritionalModal,
  hideConfirmDeleteNutritionalModal,
  hideCreateNewNutritionalTherapyModal,
  loadNutritionalTherapies,
  loadNutritionalTherapiesFailure,
  loadNutritionalTherapiesSuccess,
  selectNutritionalParenteralBag,
  selectNutritionalTherapy,
  selectedNutritionalTherapyFromTimeline,
  showConfirmAbortNutritionalModal,
  showCreateNewEnteralTherapyModal,
  showCreateNewParenteralTherapyModal,
  showDeleteNutritionalTherapyConfirmationModal,
  sortNutritionalTherapies,
  tabIndexNutritionalTherapyChanged,
} from './nutritional-therapies.actions';

export interface NutritionalTherapiesUiState {
  isLoadingData: boolean;
  sort?: Sort;
  selectedTherapyId?: string;

  candidateForDeletionId?: string;
  selectedTherapyOccurrence: SelectedTherapyOccurrenceIds | undefined;

  showCreateNewEnteralModal: boolean;
  showCreateNewParenteralModal: boolean;
  isCreatingNew: boolean;
  selectedTherapyOccurrenceInTable: boolean | undefined;
  changingTab: boolean | undefined;

  catalogs?: NutritionalTherapyCatalogs;
  isLoadingCatalogs: boolean;

  selectedTab: number;
  isShowDeleteConfirmationModal: boolean;
  isDeletePressing: boolean;
  isShowAbortConfirmationModal: boolean;
  selectedParenteralBag?: NutritionalParenteralBag;
  parenteralBagSelectorId?: string;
  isConfirmAbortPressing: boolean;
  candidateForAbortId?: string;
  isSaveEnteralTherapyRunning: boolean;
  isSaveParenteralTherapyRunning: boolean;
}

export const initialNutritionalTherapiesUiState: NutritionalTherapiesUiState = {
  isLoadingData: false,
  showCreateNewEnteralModal: false,
  showCreateNewParenteralModal: false,
  isCreatingNew: false,
  isLoadingCatalogs: false,
  selectedTherapyOccurrenceInTable: undefined,
  changingTab: undefined,
  selectedTherapyOccurrence: undefined,
  selectedTab: 0,
  isShowDeleteConfirmationModal: false,
  isDeletePressing: false,
  isShowAbortConfirmationModal: false,
  isConfirmAbortPressing: false,
  isSaveEnteralTherapyRunning: false,
  isSaveParenteralTherapyRunning: false,
};

export const nutritionalTherapiesUiReducer: ActionReducer<
  NutritionalTherapiesUiState,
  Action
> = createReducer(
  initialNutritionalTherapiesUiState,
  on(
    loadNutritionalTherapies,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => ({
      ...state,
      isLoadingData: true,
    })
  ),
  on(
    loadNutritionalTherapiesSuccess,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => ({
      ...state,
      isLoadingData: false,
      selectedTherapyOccurrenceInTable: undefined,
      selectedTherapyOccurrence: undefined,
    })
  ),
  on(
    loadNutritionalTherapiesFailure,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => ({
      ...state,
      isLoadingData: false,
    })
  ),
  on(
    sortNutritionalTherapies,
    (
      state: NutritionalTherapiesUiState,
      { sort }
    ): NutritionalTherapiesUiState => ({
      ...state,
      sort,
    })
  ),
  on(
    selectNutritionalTherapy,
    (
      state: NutritionalTherapiesUiState,
      { selectedTherapyOccurrenceIds }
    ): NutritionalTherapiesUiState => ({
      ...state,
      selectedTherapyOccurrence: {
        ...selectedTherapyOccurrenceIds,
      },

      candidateForAbortId: selectedTherapyOccurrenceIds.therapyId,
      selectedTherapyOccurrenceInTable: true,
      changingTab: false,
    })
  ),

  on(
    deselectNutritionalTherapy,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => ({
      ...state,
      selectedTherapyId: undefined,
      selectedTherapyOccurrence: undefined,
      selectedTherapyOccurrenceInTable: undefined,
      changingTab: undefined,
    })
  ),

  on(
    showCreateNewEnteralTherapyModal,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => ({
      ...state,
      showCreateNewEnteralModal: true,
      showCreateNewParenteralModal: false,
    })
  ),
  on(
    showCreateNewParenteralTherapyModal,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => ({
      ...state,
      showCreateNewEnteralModal: false,
      showCreateNewParenteralModal: true,
    })
  ),
  on(
    hideCreateNewNutritionalTherapyModal,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => ({
      ...state,
      showCreateNewEnteralModal: false,
      showCreateNewParenteralModal: false,
    })
  ),

  on(
    createNutritionalTherapy,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => ({
      ...state,
      isCreatingNew: true,
    })
  ),

  on(
    createNutritionalTherapySuccess,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => ({
      ...state,
      isCreatingNew: false,
    })
  ),
  on(
    createNutritionalTherapyFailure,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => ({
      ...state,
      isCreatingNew: false,
    })
  ),

  on(
    confirmAbortNutritionalModal,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => ({
      ...state,
      isConfirmAbortPressing: true,
    })
  ),

  on(
    confirmAbortNutritionalTherapySuccess,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => ({
      ...state,

      isConfirmAbortPressing: false,
      isShowAbortConfirmationModal: false,
    })
  ),

  on(
    confirmAbortNutritionalTherapyFailure,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => ({
      ...state,
      isConfirmAbortPressing: false,
    })
  ),

  /***************** DOWNLOAD TYPES *****************/

  on(
    downloadNutritionalTherapyCatalogs,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => ({
      ...state,
      isLoadingCatalogs: true,
    })
  ),
  on(
    downloadNutritionalTherapyCatalogsSuccess,
    (
      state: NutritionalTherapiesUiState,
      { catalogs }
    ): NutritionalTherapiesUiState => ({
      ...state,
      isLoadingCatalogs: false,
      catalogs,
    })
  ),
  on(
    downloadNutritionalTherapyCatalogsFailure,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => ({
      ...state,
      isLoadingCatalogs: false,
    })
  ),

  on(
    tabIndexNutritionalTherapyChanged,
    (
      state: NutritionalTherapiesUiState,
      { tabIndex }
    ): NutritionalTherapiesUiState => {
      if (tabIndex === 0) {
        return {
          ...state,
          selectedTab: tabIndex,
          selectedTherapyOccurrence: undefined,
          selectedTherapyOccurrenceInTable: undefined,
          changingTab: undefined,
        };
      } else {
        return {
          ...state,
          selectedTab: tabIndex,
        };
      }
    }
  ),

  on(
    selectedNutritionalTherapyFromTimeline,
    (
      state: NutritionalTherapiesUiState,
      { selectedTherapy }
    ): NutritionalTherapiesUiState => {
      return {
        ...state,
        selectedTherapyOccurrence: selectedTherapy,
        candidateForDeletionId: selectedTherapy.therapyId,
        candidateForAbortId: selectedTherapy.therapyId,

        selectedTab: 1,
        selectedTherapyOccurrenceInTable: false,
        changingTab: state.selectedTab !== 1,
      };
    }
  ),
  on(
    showDeleteNutritionalTherapyConfirmationModal,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => {
      return {
        ...state,
        isShowDeleteConfirmationModal: true,
        candidateForDeletionId: state.selectedTherapyOccurrence?.therapyId,
      };
    }
  ),

  on(
    hideConfirmDeleteNutritionalModal,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => {
      return {
        ...state,
        isShowDeleteConfirmationModal: false,
      };
    }
  ),
  on(
    confirmDeleteNutritionalTherapy,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => {
      return {
        ...state,
        isDeletePressing: true,
      };
    }
  ),
  on(
    confirmDeleteNutritionalTherapySuccess,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => {
      return {
        ...state,
        isDeletePressing: false,
        isShowDeleteConfirmationModal: false,
      };
    }
  ),
  on(
    confirmDeleteNutritionalTherapyFailure,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => {
      return {
        ...state,
        isDeletePressing: false,
      };
    }
  ),
  on(
    showConfirmAbortNutritionalModal,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => {
      return {
        ...state,
        isShowAbortConfirmationModal: true,
      };
    }
  ),
  on(
    hideConfirmAbortNutritionalModal,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => {
      return {
        ...state,
        isShowAbortConfirmationModal: false,
      };
    }
  ),
  on(
    selectNutritionalParenteralBag,
    (
      state: NutritionalTherapiesUiState,
      { bag, formId }: { bag: NutritionalParenteralBag; formId: string }
    ): NutritionalTherapiesUiState => {
      return {
        ...state,
        selectedParenteralBag: bag,
        parenteralBagSelectorId: formId,
      };
    }
  ),
  on(
    clearSelectedParenteralBag,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => {
      return {
        ...state,
        selectedParenteralBag: undefined,
        parenteralBagSelectorId: undefined,
      };
    }
  ),
  on(
    editEnteralTherapy,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => {
      return {
        ...state,
        isSaveEnteralTherapyRunning: true,
      };
    }
  ),

  on(
    editEnteralTherapySuccess,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => {
      return {
        ...state,
        isSaveEnteralTherapyRunning: false,
      };
    }
  ),

  on(
    editEnteralTherapyFailure,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => {
      return {
        ...state,
        isSaveEnteralTherapyRunning: false,
      };
    }
  ),

  on(
    editParenteralTherapy,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => {
      return {
        ...state,
        isSaveParenteralTherapyRunning: true,
      };
    }
  ),

  on(
    editParenteralTherapySuccess,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => {
      return {
        ...state,
        isSaveParenteralTherapyRunning: false,
      };
    }
  ),
  on(
    editEnteralTherapyFailure,
    (state: NutritionalTherapiesUiState): NutritionalTherapiesUiState => {
      return {
        ...state,
        isSaveParenteralTherapyRunning: false,
      };
    }
  )
);
