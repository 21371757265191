/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcedureScheduleContextResource } from './procedureScheduleContextResource';
import { ProcedureTerminationContextResource } from './procedureTerminationContextResource';
import { ProcedureExecutionContextResource } from './procedureExecutionContextResource';


/**
 * Represents a procedure.
 */
export interface ProcedureResource { 
    /**
     * Unique identifier of the hospitalization where the procedure was terminated, if applicable.
     */
    endEncounterId?: string;
    execution?: ProcedureExecutionContextResource;
    /**
     * Unique identifier for the procedure.
     */
    id: string;
    /**
     * Unique identifier of the patient for whom the procedure was created.
     */
    patientId: string;
    schedule: ProcedureScheduleContextResource;
    /**
     * Unique identifier of the hospitalization where the procedure was created.
     */
    startEncounterId: string;
    /**
     * Enumeration representing the different statuses of a procedure.
     */
    status: ProcedureResource.StatusEnum;
    termination?: ProcedureTerminationContextResource;
}
export namespace ProcedureResource {
    export type StatusEnum = 'Scheduled' | 'Executed' | 'Terminated';
    export const StatusEnum = {
        Scheduled: 'Scheduled' as StatusEnum,
        Executed: 'Executed' as StatusEnum,
        Terminated: 'Terminated' as StatusEnum
    };
}


