import {
  MultisectionItemIconConfig,
  MultisectionItemIconState,
} from '../../../../shared/components/multi-section-timeline/models/multi-section-timeline-instance';
import {
  TherapyDurationType,
  TherapyOccurrence,
  TherapyOccurrenceState,
} from '../entities/therapy-occurrence';
import { therapiesGetOccurrenceState } from './therapies-get-occurrence-state';
import { isNotExecuted } from './therapy-is-not-executed';

/*************************** TIMELINE ***************************/

export const timelineIconForTherapyInstantOccurrence = (
  occurrenceState: TherapyOccurrenceState,
  programmingDate: Date
): MultisectionItemIconConfig | undefined => {
  if (isNotExecuted(occurrenceState, programmingDate)) {
    return {
      name: 'exclamation-mark',
      state: MultisectionItemIconState.Error,
      isImportant: true,
    };
  }

  return therapiesGetOccurrenceState(occurrenceState);
};

export const timelineIconsForTherapyProlongedOccurrence = (
  occurrenceState: TherapyOccurrenceState,
  programmingDate: Date
): ProlongedOccurrenceIcons => {
  if (isNotExecuted(occurrenceState, programmingDate)) {
    return {
      startIcon: {
        name: 'exclamation-mark',
        state: MultisectionItemIconState.Error,
        isImportant: true,
      },
      endIcon: {
        name: 'exclamation-mark',
        state: MultisectionItemIconState.Error,
        isImportant: true,
      },
    };
  }
  switch (occurrenceState) {
    case TherapyOccurrenceState.Prepared:
      return {
        startIcon: { name: 'check', state: MultisectionItemIconState.Success },
        endIcon: { name: 'check', state: MultisectionItemIconState.Success },
      };
    case TherapyOccurrenceState.Administered:
      return {
        startIcon: {
          name: 'double-check',
          state: MultisectionItemIconState.Success,
        },
        endIcon: {
          name: 'double-check',
          state: MultisectionItemIconState.Success,
        },
      };
    case TherapyOccurrenceState.InExecution:
      return {
        startIcon: {
          name: 'double-check',
          state: MultisectionItemIconState.Success,
        },
        endIcon: { name: 'play', state: MultisectionItemIconState.Neutral },
      };
    case TherapyOccurrenceState.Prescribed:
      return {
        startIcon: {
          name: 'pause',
          state: MultisectionItemIconState.Neutral,
        },
        endIcon: {
          name: 'pause',
          state: MultisectionItemIconState.Neutral,
        },
      };
    case TherapyOccurrenceState.Aborted:
      return {
        startIcon: {
          name: 'cross-bold',
          state: MultisectionItemIconState.Error,
        },
        endIcon: { name: 'cross-bold', state: MultisectionItemIconState.Error },
      };
    case TherapyOccurrenceState.Suspended:
      return {
        startIcon: {
          name: 'double-check',
          state: MultisectionItemIconState.Success,
        },
        endIcon: { name: 'hand-stop', state: MultisectionItemIconState.Error },
      };
    default:
      return {};
  }
};

/*************************** TABLE ***************************/

export const tableIconForTherapyInstantOccurrence =
  timelineIconForTherapyInstantOccurrence;

export const tableIconForTherapyProlongedOccurrence = (
  occurrenceState: TherapyOccurrenceState,
  programmingDate: Date
): MultisectionItemIconConfig => {
  if (isNotExecuted(occurrenceState, programmingDate)) {
    return {
      name: 'exclamation-mark',
      state: MultisectionItemIconState.Error,
      isImportant: true,
    };
  }

  switch (occurrenceState) {
    case TherapyOccurrenceState.Prescribed:
      return {
        name: 'pause',
        state: MultisectionItemIconState.Neutral,
      };
    case TherapyOccurrenceState.Prepared:
    case TherapyOccurrenceState.SentToPreparation:
      return { name: 'check', state: MultisectionItemIconState.Success };

    case TherapyOccurrenceState.Administered:
      return {
        name: 'double-check',
        state: MultisectionItemIconState.Success,
      };
    case TherapyOccurrenceState.InExecution:
      return {
        name: 'play',
        state: MultisectionItemIconState.Neutral,
      };
    case TherapyOccurrenceState.Aborted:
      return {
        name: 'cross-bold',
        state: MultisectionItemIconState.Error,
      };
    case TherapyOccurrenceState.Suspended:
      return { name: 'hand-stop', state: MultisectionItemIconState.Error };
  }
};

/*************************** FORM ***************************/

export const formIconForTherapyOccurrenceState = (
  occurrence: TherapyOccurrence
): MultisectionItemIconConfig | undefined => {
  if (occurrence.durationType === TherapyDurationType.Instant) {
    return tableIconForTherapyInstantOccurrence(
      occurrence.state,
      occurrence.programmingDate
    );
  } else {
    return tableIconForTherapyProlongedOccurrence(
      occurrence.state,
      occurrence.programmingDate
    );
  }
};

/*************************** MODELS ***************************/

interface ProlongedOccurrenceIcons {
  startIcon?: MultisectionItemIconConfig;
  endIcon?: MultisectionItemIconConfig;
}
