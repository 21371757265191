import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService } from 'natea-components';
import { PregnancyCardActions } from './pregnancy.actions';
import { selectOngoingEncounterAndPatientIds } from 'src/app/features/patients/store/patients.selectors';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { ApiError } from 'src/app/shared/entities/errors';
import { PregnancyCardWebApiWebApi } from '../webapi/pregnancy-card-web-api';
import { PregnancyCardData } from '../forms/pregnancy-card-form-data';

@Injectable()
export class PregnancyEffects {
  loadAllPregnancyCard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PregnancyCardActions.LoadAllPregnancyCard),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      exhaustMap(([, onGoingEncounterIds]) => {
        const patientId = onGoingEncounterIds?.patientId;
        const encounterId = onGoingEncounterIds?.encounterId;
        return this.pregnancyCardWebApi.getAllPregnancyCard().pipe(
          map((pregnancyCard: PregnancyCardData) => {
            return {
              type: PregnancyCardActions.LoadAllPregnancyCardSuccess,
              pregnancyCard,
              patientId,
              encounterId,
            };
          }),
          catchError((error: ApiError) => {
            return of({
              type: PregnancyCardActions.LoadAllPregnancyCardFailure,
              payload: { error },
            });
          })
        );
      })
    );
  });
  constructor(
    private actions$: Actions,
    private store: Store,
    private snackBar: SnackbarService,
    private translocoService: TranslocoService,
    private pregnancyCardWebApi: PregnancyCardWebApiWebApi
  ) {}
}
