import { isDevMode } from '@angular/core';
import { routerReducer } from '@ngrx/router-store';
import { MetaReducer } from '@ngrx/store';
import {
  configReducer,
  ConfigState,
} from '../core/config/store/config.reducer';
import {
  domainsReducer,
  DomainsState,
  initialDomainState,
} from '../core/domains/store/domains.reducer';
import {
  boardReducer,
  BoardState,
  initialBoardState,
} from '../features/board/store/board.reducers';
import {
  clinicalNoteInitialState,
  clinicalNoteReducer,
  ClinicalNoteUiState,
} from '../features/clinical-note/store/clinical-note.reducers';
import {
  departmentsReducer,
  DepartmentsState,
  initialDepartmentsState,
} from '../features/departments/store/departments.reducers';
import {
  loginInitialState,
  loginReducer,
  LoginState,
} from '../features/login/store/login.reducers';
import {
  initialPatientsState,
  patientsDataReducer,
  PatientsState,
} from '../features/patients/store/patients.reducers';
import {
  initialTherapiesUiState,
  therapiesUiReducer,
  TherapiesUiState,
} from '../features/therapies/common/store/therapies.reducer';
import {
  initialUserState,
  userReducer,
  UserState,
} from '../features/users/store/users.reducers';
import {
  initialStateLog,
  logReducer,
  LogState,
} from '../shared/components/log-viewer/store/log.reducers';
import {
  factSheetUiReducer,
  FactSheetUiState,
  iniTialFactSheetUiState,
} from '../features/anamnesis/new-born/store/new-born.reducers';
import {
  familyAnamnesisUiReducer,
  FamilyAnamnesisUiState,
  iniTialFamilyAnamnesisUiState,
} from '../features/anamnesis/family-anamnesis/store/family-anamnesis.reducers';
import {
  iniTialPregnancyCardUiState,
  pregnancyCardUiReducer,
  PregnancyCardUiState,
} from '../features/anamnesis/pregnancy/store/pregnancy.reducers';

export interface State {
  // problems: ProblemsState;
  user: UserState;
  clinicalNote: ClinicalNoteUiState;
  // encounter: EncounterState;
  departments: DepartmentsState;
  // patients: PatientsState;
  login: LoginState;
  board: BoardState;
  patients: PatientsState;
  config: ConfigState;
  log: LogState;
  domains: DomainsState;
  therapiesUi: TherapiesUiState;
  factSheet: FactSheetUiState;
  familyAnamnesis: FamilyAnamnesisUiState;
  pregnancyCard: PregnancyCardUiState;
}

export const initialState: State = {
  // problems: initialProblemsState,
  user: initialUserState,
  clinicalNote: clinicalNoteInitialState,
  // encounter: initialEncounterState,
  departments: initialDepartmentsState,
  // patients: initialPatientsState,
  login: loginInitialState,
  board: initialBoardState,
  patients: initialPatientsState,
  config: {} as ConfigState,
  log: initialStateLog,
  domains: initialDomainState,
  therapiesUi: initialTherapiesUiState,
  factSheet: iniTialFactSheetUiState,
  familyAnamnesis: iniTialFamilyAnamnesisUiState,
  pregnancyCard: iniTialPregnancyCardUiState,
};

export const reducers = {
  // problems: problemsReducer,
  user: userReducer,
  clinicalNote: clinicalNoteReducer,
  departments: departmentsReducer,
  router: routerReducer,
  login: loginReducer,
  board: boardReducer,
  patients: patientsDataReducer,
  config: configReducer,
  log: logReducer,
  domains: domainsReducer,
  therapiesUi: therapiesUiReducer,
  factSheet: factSheetUiReducer,
  familyAnamnesis: familyAnamnesisUiReducer,
  pregnancyCard: pregnancyCardUiReducer,
};

export const metaReducers: MetaReducer<State>[] = isDevMode() ? [] : [];
