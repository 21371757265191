import { PatientProblem } from '../../features/problems/interfaces/patient-problem';
import { ProfessionalCategory } from '../../shared/entities/professional-category';
import { mockEncounterDay } from '../../shared/utils/mock-utils';
import { DetectedProblemResource } from '../generated/api';
import getProblemsMockedResponse from './get-problems-mocked-response';

let mocks: DetectedProblemResource[] | undefined = undefined;

const names: string[] = [
  'John',
  'Richard',
  'Jack',
  'James',
  'Robert',
  'Michael',
  'William',
  'David',
  'Sue',
  'Ellen',
  'Donna',
  'Margaret',
  'Jenna',
  'Patricia',
];

const surnames: string[] = [
  'Doe',
  'Smith',
  'Johnson',
  'Williams',
  'Jones',
  'Brown',
  'Davis',
  'Miller',
  'Wilson',
  'Moore',
  'Taylor',
  'Anderson',
  'Thomas',
  'Jackson',
  'Roe',
];

export const problemsNames = [
  {
    id: 'd7d4ec62-4dcf-4e44-b2c3-f1c06b12f147',
    name: 'Prematurità minore di 28 settimane',
  },

  {
    id: 'fb2d9d1d-9724-416c-9ec7-2fe4c97a2876',
    name: 'Ittero in prematuro',
  },

  {
    id: '1a51fed8-0e1e-4b70-8abd-a486e6d93225',
    name: 'Distress Respiratorio Moderato',
  },
  {
    id: 'b9a7a391-15ec-4835-8274-978f7a52795c',
    name: 'Malattia delle Membrane Ialine',
  },
];

export const createDetectedProblemsMock = (
  patientId?: string,
  encounterId?: string
): DetectedProblemResource[] => {
  if (!mocks) {
    const problemTypes = getProblemsMockedResponse().payload ?? [];
    const problemTypesPage2 = getProblemsMockedResponse(2).payload ?? [];
    const problems = [
      {
        id: 'detectedProblem1',
        encounter: {
          id: encounterId ?? 'encounter1',
          departmentId: 'dept789',
          patientId: 'patient456',
          nosographicId: 'nosographic123',
          startDate: mockEncounterDay(1, 10), // RLE
        },
        problem: problemTypesPage2[7],
        startDate: mockEncounterDay(1, 10).toISOString(), // RLE
        description: 'Patient diagnosed with a headache.',
        userInsId: 'user123',
        userUpdId: 'user456',
        insDate: mockEncounterDay(5).toISOString,
      },
      {
        id: 'detectedProblem2',
        encounter: {
          id: encounterId ?? 'encounter1',
          departmentId: 'dept789',
          patientId: 'patient456',
          nosographicId: 'nosographic123',
          startDate: mockEncounterDay(1, 10), // RLE
        },
        problem: problemTypes[1],
        startDate: mockEncounterDay(3, 10).toISOString(), // RLE
        endDate: mockEncounterDay(8, 20, 30).toISOString(), // RLE
        userInsId: 'user789',
        userUpdId: 'user1011',
        insDate: '2023-09-22T13:23:23Z',
        updDate: '2023-09-22T16:51:06Z',
      },
      {
        id: 'detectedProblem4',
        encounter: {
          id: encounterId ?? 'encounter1',
          departmentId: 'dept789',
          patientId: 'patient456',
          nosographicId: 'nosographic123',
          startDate: mockEncounterDay(3, 12), // RLE
        },
        problem: problemTypes[4],
        startDate: mockEncounterDay(1, 12).toISOString(), // RLE
        endDate: mockEncounterDay(4, 8, 30).toISOString(), // RLE
        userInsId: 'user789',
        userUpdId: 'user1011',
        insDate: '2023-09-22T13:23:23Z',
        updDate: '2023-09-22T16:51:06Z',
      },
      {
        id: 'detectedProblem3',
        encounter: {
          id: encounterId ?? 'encounter1',
          departmentId: 'dept789',
          patientId: 'patient456',
          nosographicId: 'nosographic123',
          startDate: mockEncounterDay(1, 15), // RLE
        },
        problem: problemTypes[2],
        startDate: mockEncounterDay(4, 8, 30).toISOString(), // RLE
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac ante sed justo consectetur eleifend. Suspendisse et lacinia ante. Aenean eleifend orci sed dolor vulputate consectetur. Nullam id augue magna. Aliquet sed nunc id, tristique ornare ante. Duis nec placerat urna, vel consectetur lectus. Donec et metus erat. Integer sit amet enim viverra, sodales tortor dapibus, volutpat mauris. Morbi pretium dapibus ligula, id suscipit magna.',
        userInsId: 'user1213',
        userUpdId: 'user1415',
        insDate: mockEncounterDay(5).toISOString,
        updDate: mockEncounterDay(7).toISOString,
      },
    ];

    mocks = Array(problemsNames.length)
      .fill(0)
      .map((_, index): DetectedProblemResource => {
        return {
          ...problems[index % problems.length],
          createdAt: new Date(
            Date.now() - Math.random() * 1000 * 60 * 60 * 24 * 30
          ).toISOString(),
          patientId: '5',
          problemCatalogEntry: {
            id: problemsNames[index].id,
            name: problemsNames[index].name,
            description:
              'Problema di ' + problemsNames[index % problemsNames.length],
            isEnabled: true,
            problemClass: 'M',
          },
          id: 'detectedProblem' + index,

          startEncounterId: encounterId ?? 'encounter1',

          createdBy: {
            id: 'user' + index,
            firstName: names[index % names.length],
            lastName: surnames[index % surnames.length],
          },

          additionalNotes:
            `Desidero fornirle un aggiornamento dettagliato sullo stato di salute del bimbo, basato sull'esame clinico recentemente condotto.` +
            `Condizioni Generali:
            Sembra essere in buona salute generale. La sua vitalità e il suo stato di svegliate sono adeguati per la sua età` +
            `Parametri vitali:

            Frequenza cardiaca: 80-130 bpm
            Pressione arteriosa: 90-105/55-65 mmHg (sistolica/diastolica)
            Temperatura corporea: 36.5-37.5 °C (97.7-99.5 °F)
            Frequenza respiratoria: 20-30 respiri al minuto
            Peso: 10-15 kg (22-33 lb)
            Altezza: 80-100 cm (31-39 in)
            `,

          permissions: {
            canDelete: true,
            canUpdate: true,
          },
        };
      });
  }

  return mocks.filter(
    (mock: DetectedProblemResource): boolean =>
      mock.endEncounterId === encounterId
  );
};

/*export const createDemoProblems = () => {
  const problemWithLongNotes: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema con note lunghe',
    note:
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae` +
      `diam eget risus varius blandit sit amet non magna. Lorem ipsum dolor` +
      ` sit amet, consectetur adipiscing elit. Sed vitae diam eget risus varius` +
      ` blandit sit amet non magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae diam eget risus varius blandit sit amet non magna.`,
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(
      Date.now() - 7 * 24 * 60 * 60 * 1000 + 24 * 60 * 60 * 1000
    ),
  };

  const problemVeryShortClosed: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema corto finito',
    startDate: new Date(Date.now() - 40 * 1000),
    endDate: new Date(Date.now() - 20 * 1000),
  };

  const problemVeryShortOngoing: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema corto in corso',
    startDate: new Date(),
  };

  const demoProblems = [
    problemWithLongNotes,
    problemVeryShortClosed,
    problemVeryShortOngoing,
    ...Array.from(
      { length: 0 },
      (_, index: number): PatientProblem => createRandomProblem(index)
    ),
    { ...problemWithLongNotes },
    ...createEdgeCases(),
  ];

  return demoProblems;
};*/

const createEdgeCases = (): PatientProblem[] => {
  const lastHourProblem: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: "Problema iniziato un'ora fa",
    startDate: new Date(Date.now() - 60 * 60 * 1000),
  };
  const last3HoursProblem: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema iniziato 3 ore fa',
    startDate: new Date(Date.now() - 3 * 60 * 60 * 1000),
  };
  const last12HoursProblem: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema iniziato 12 ore fa',
    startDate: new Date(Date.now() - 12 * 60 * 60 * 1000),
  };
  const last24HoursProblem: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema iniziato 24 ore fa',
    startDate: new Date(Date.now() - 24 * 60 * 60 * 1000),
  };
  const problemLasting25HoursStarting7DaysAgo: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema che dura 25 ore',
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(
      Date.now() - 7 * 24 * 60 * 60 * 1000 + 25 * 60 * 60 * 1000
    ),
  };
  const problemLasting26HoursStarting7DaysAgo: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema che dura 26 ore',
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(
      Date.now() - 7 * 24 * 60 * 60 * 1000 + 26 * 60 * 60 * 1000
    ),
  };
  const problemLasting24HoursStarting7DaysAgo: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema che dura 24 ore',
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(
      Date.now() - 7 * 24 * 60 * 60 * 1000 + 24 * 60 * 60 * 1000
    ),
  };
  const problemLasting16HoursStarting7DaysAgo: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema che dura 16 ore',
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(
      Date.now() - 7 * 24 * 60 * 60 * 1000 + 16 * 60 * 60 * 1000
    ),
  };
  const problemLasting16AndAHalfHoursStarting7DaysAgo: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema che dura 16 ore e mezza',
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(
      Date.now() - 7 * 24 * 60 * 60 * 1000 + 16.5 * 60 * 60 * 1000
    ),
  };
  const problemLasting17HoursStarting7DaysAgo: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema che dura 17 ore',
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(
      Date.now() - 7 * 24 * 60 * 60 * 1000 + 17 * 60 * 60 * 1000
    ),
  };
  const problemLasting17AndAHalfHoursStarting7DaysAgo: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema che dura 17 ore e mezza',
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(
      Date.now() - 7 * 24 * 60 * 60 * 1000 + 17.5 * 60 * 60 * 1000
    ),
  };
  const problemLasting18HoursStarting7DaysAgo: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema che dura 18 ore',
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(
      Date.now() - 7 * 24 * 60 * 60 * 1000 + 18 * 60 * 60 * 1000
    ),
  };
  const problemLasting18AndAHalfHoursStarting7DaysAgo: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema che dura 18 ore e mezza',
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(
      Date.now() - 7 * 24 * 60 * 60 * 1000 + 18.5 * 60 * 60 * 1000
    ),
  };
  const problemLasting19HoursStarting7DaysAgo: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema che dura 19 ore',
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(
      Date.now() - 7 * 24 * 60 * 60 * 1000 + 19 * 60 * 60 * 1000
    ),
  };
  const problemLasting19AndAHalfHoursStarting7DaysAgo: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema che dura 19 ore e mezza',
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(
      Date.now() - 7 * 24 * 60 * 60 * 1000 + 19.5 * 60 * 60 * 1000
    ),
  };
  const problemLasting20HoursStarting7DaysAgo: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: 'Problema che dura 20 ore',
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(
      Date.now() - 7 * 24 * 60 * 60 * 1000 + 20 * 60 * 60 * 1000
    ),
  };
  const problemLastingOneHourStarting7DaysAgo: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: "Problema che dura un'ora",
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000 + 60 * 60 * 1000),
  };
  const problemLastingHalfHourStarting7DaysAgo: PatientProblem = {
    ...getBaseProblem(),
    problemDescription: "Problema che dura mezz'ora",
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000 + 30 * 60 * 1000),
  };

  return [
    lastHourProblem,
    last3HoursProblem,
    last12HoursProblem,
    last24HoursProblem,
    problemLasting26HoursStarting7DaysAgo,
    problemLasting25HoursStarting7DaysAgo,
    problemLasting24HoursStarting7DaysAgo,
    {
      ...problemLasting16HoursStarting7DaysAgo,
      id: randomIntFromInterval(1, 100000).toString(),
    },
    problemLastingOneHourStarting7DaysAgo,
    problemLastingHalfHourStarting7DaysAgo,
    {
      ...problemLasting16HoursStarting7DaysAgo,
      id: randomIntFromInterval(1, 100000).toString(),
    },
    {
      ...problemLasting16HoursStarting7DaysAgo,
      id: randomIntFromInterval(1, 100000).toString(),
    },
    {
      ...problemLasting16HoursStarting7DaysAgo,
      id: randomIntFromInterval(1, 100000).toString(),
    },
    problemLasting16AndAHalfHoursStarting7DaysAgo,
    problemLasting17HoursStarting7DaysAgo,
    problemLasting17AndAHalfHoursStarting7DaysAgo,
    problemLasting18HoursStarting7DaysAgo,
    problemLasting18AndAHalfHoursStarting7DaysAgo,
    problemLasting19HoursStarting7DaysAgo,
    problemLasting19AndAHalfHoursStarting7DaysAgo,
    problemLasting20HoursStarting7DaysAgo,
  ];
};

const createRandomProblem = (index: number): PatientProblem => {
  const startDate: Date = getRandomDate();
  const randomDateBetweenStartAndNow: Date = new Date(
    startDate.getTime() + Math.random() * (Date.now() - startDate.getTime())
  );
  const endDate: Date | undefined =
    index % 2 == 0 ? undefined : randomDateBetweenStartAndNow;

  return {
    ...getBaseProblem(),
    problemDescription: `Problema ${index}`,
    startDate: startDate,
    endDate: endDate,
    problemClass:
      index % 3 === 1
        ? ProfessionalCategory.Nursing
        : ProfessionalCategory.Medical,
    editableFromLoggedUser: Math.random() > 0.3,
    cancelableFromLoggedUser: Math.random() > 0.3,
  };
};

const randomIntFromInterval = (min: number, max: number): number => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const getRandomDate = (): Date => {
  const currentDate: Date = new Date();
  const fifteenDaysAgo: Date = new Date(
    currentDate.getTime() - 15 * 24 * 60 * 60 * 1000
  );

  const randomTimestamp: number =
    fifteenDaysAgo.getTime() +
    Math.random() * (currentDate.getTime() - fifteenDaysAgo.getTime());
  const randomDate: Date = new Date(randomTimestamp);

  return randomDate;
};

const getBaseProblem = (): PatientProblem => ({
  id: randomIntFromInterval(1, 100000).toString(),
  problemDescription: `Problema`,
  patientId: '1',
  encounterId: '1',
  problemType: {
    id: '3',
    name: 'Emicrania',
    description: 'Problema di emicrania',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  startDate: getRandomDate(),
  problemClass: ProfessionalCategory.Medical,
  createdBy: {
    id: '1',
    firstName: 'Giuseppe',
    lastName: 'Mazzini',
  },
  cancelableFromLoggedUser: true,
  editableFromLoggedUser: true,
});
