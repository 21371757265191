import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { toggleMenuGroupOpened } from '../../../features/users/store/users.actions';
import {
  selectCanUserValidateNotes,
  selectOpenedMenuGroups,
} from '../../../features/users/store/users.selectors';
import {
  selectCurrentUrl,
  selectSelectedPatientBasePath,
} from '../../routes/routes-selectors';
import { sidebarMainData } from './data/sidebar-data';
import {
  SidebarItem,
  SidebarItemGroup,
  SidebarItemId,
} from './models/sidebar-item';
import { SidebarItemSelectedEvent } from './sidebar-item/sidebar-item.component';

@Component({
  selector: 'natea-cc-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() collapsed!: boolean;

  @HostBinding('class') get classes(): string {
    return this.collapsed ? 'collapses' : 'expanded';
  }

  mainItems: SidebarItem[] = sidebarMainData.filter(
    (item) => item.type !== 'footer'
  );
  footerItem: SidebarItem[] = sidebarMainData.filter(
    (item) => item.type === 'footer'
  );

  patientBasePath$: Observable<string | undefined> = this.store.select(
    selectSelectedPatientBasePath
  );
  currentUrl$: Observable<string> = this.store.select(selectCurrentUrl);

  expandedGroups$: Observable<SidebarItemId[]> = this.store.select(
    selectOpenedMenuGroups
  );

  canValidateNotes$: Observable<boolean> = this.store.select(
    selectCanUserValidateNotes
  );

  constructor(private store: Store, private router: Router) {
    this.canValidateNotes$.subscribe((canValidate) => {
      if (!canValidate) {
        this.mainItems.forEach((item: SidebarItem) => {
          if (
            item.id === SidebarItemId.ClinicalNotes &&
            item.type === 'group'
          ) {
            item.children = item.children.filter(
              (subItem: SidebarItem): boolean => {
                return subItem.id !== SidebarItemId.ClinicalNotesValidation;
              }
            );
          }
        });
      }
    });
  }

  onItemSelected = ({
    item,
    enclosingGroups,
  }: SidebarItemSelectedEvent): void => {
    if (item.type === 'group') {
      this.onGroupSelected(item, enclosingGroups);
    } else if (item.type === 'leaf') {
      this.navigateTo(item, enclosingGroups);
    }
  };

  private onGroupSelected = (
    group: SidebarItemGroup,
    enclosingGroups: SidebarItemGroup[]
  ): void => {
    if (group.children?.length > 0) {
      this.store.dispatch(toggleMenuGroupOpened({ itemId: group.id }));
    }
    if (group.navigable) {
      this.navigateTo(group, enclosingGroups);
    }
  };

  private navigateTo = (
    item: SidebarItem,
    enclosingGroups: SidebarItemGroup[]
  ): void => {
    this.patientBasePath$.pipe(take(1)).subscribe((basePath) => {
      let groupsPath: string = enclosingGroups
        .map((group: SidebarItemGroup): string => group.path)
        .join('/');
      if (groupsPath) {
        groupsPath = groupsPath.concat('/');
      }
      this.router.navigateByUrl(`${basePath}/${groupsPath}${item.path}`);
    });
  };
}
