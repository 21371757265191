import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ButtonVariants,
  SizeType,
  matchMediaDesktopSize,
} from 'natea-components';
import { shownName } from '../../../../shared/entities/user';
import { PatientData } from '../../../patients/store/patients.reducers';
import { PatientProblem } from '../../interfaces/patient-problem';

@Component({
  selector: 'natea-cc-delete-problem-modal',
  templateUrl: './delete-problem-modal.component.html',
  styleUrls: ['./delete-problem-modal.component.scss'],
})
export class DeleteProblemModalComponent implements OnInit, OnChanges {
  @Input() problem!: PatientProblem;
  @Input() showModal = false;
  @Input() paziente: PatientData | undefined;
  @Output() problemToDelete: EventEmitter<PatientProblem> =
    new EventEmitter<PatientProblem>();

  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteAndCloseModal: EventEmitter<void> = new EventEmitter<void>();

  @Input() isDeletingProblem = false;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeModal.emit();
  }

  pazienteShownName = '';

  buttonVariants = ButtonVariants;

  private desktopSizeMatcher: MediaQueryList = matchMediaDesktopSize(window);
  isDesktopSized = false;

  modalSize = SizeType.SMALL;

  deleteProblemConfirmed = () => {
    this.problemToDelete.emit(this.problem);

    this.showModal = false;
  };

  ngOnInit(): void {
    this.isDesktopSized = this.desktopSizeMatcher.matches;

    this.desktopSizeMatcher.addEventListener(
      'change',

      this.onDesktopSizeChanged
    );
  }

  private onDesktopSizeChanged = (event: MediaQueryListEvent) => {
    this.isDesktopSized = event.matches;
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['paziente'].currentValue) {
      if (this.paziente) {
        this.pazienteShownName = shownName(this.paziente);
      }
    }
  }
}
