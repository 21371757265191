<ng-container *ngrxLet="isForcedOpen$ as isForcedOpen">
  <div
    *transloco="let t; read: 'sidebar'"
    class="sidebar-item-container"
    [class.compact]="compact"
    [class.full-width]="!compact"
    [class.disabled]="!enabled"
    [class.selected]="selected"
    [class.selected-group]="selectedGroup"
    [class]="classes"
  >
    <ng-container *ngrxLet="badgeCount$ as badgeCount">
      <button (click)="onClick()" type="button">
        <natea-icons
          class="item-icon icon"
          [name]="item.icon"
          [class.badge-sibling]="badgeCount"
        ></natea-icons>
        <span class="item-label">{{ t(item.id) }}</span>
        <span *ngIf="badgeCount" class="badge-counter">{{ badgeCount }}</span>
        <natea-icons
          *ngIf="item.type === 'group' && item.children.length > 0"
          name="arrow-right"
          class="expand-icon icon"
          [class.disabled]="isForcedOpen"
          [class.expanded]="isExpanded$ | async"
        ></natea-icons>
      </button>
    </ng-container>
  </div>
  <div
    *ngIf="item.type === 'group' && (isExpanded$ | async)"
    class="children-container"
    [class]="classes"
  >
    <natea-cc-sidebar-item
      *ngFor="let childItem of item.children"
      [item]="childItem"
      [compact]="compact"
      [basePath]="basePath + '/' + item.path"
      [currentPath]="currentPath"
      [nestingLevel]="nestingLevel + 1"
      (itemSelected)="onChildClick($event)"
    ></natea-cc-sidebar-item>
  </div>
</ng-container>
