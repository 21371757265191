<section>
  <div class="text" *ngIf="!hasCheckbox">{{ value }}</div>
  <div *ngIf="!hasCheckbox">
    <natea-icons
      class="icon"
      [name]="iconName"
      [title]="iconTitle"
      [fill]="fill"
      [width]="width"
      [height]="height"
    ></natea-icons>
  </div>
  <div *ngIf="hasCheckbox">
    <natea-checkbox
      [checked]="checked"
      [disabled]="disabled"
      [label]="checkboxLabel"
      [labelPosition]="checkboxLabelPosition"
    ></natea-checkbox>
  </div>
</section>
