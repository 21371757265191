import { NotePutRequest } from '../../../../core/generated/api';
import { ClinicalNote } from '../../interfaces/clinical-note';
import { NewNoteFormValues } from '../../interfaces/new-note-form';
import { ValidationClinicalNoteToEvaluate } from '../models/validation-clinical-note-to-evaluate';

export class NotesValidationAdapters {
  static validationNoteFeToBE(
    noteToEvaluate: NewNoteFormValues
  ): ValidationClinicalNoteToEvaluate {
    return {
      id: noteToEvaluate.id ?? '', // TODO: check if this is correct
      isApproved: noteToEvaluate.validation === '1',
      rejectionReason: noteToEvaluate.motivationNoteNotValid ?? undefined,
    };
  }
  static clinicalNoteFeToUpdate(clinicalNote: ClinicalNote): NotePutRequest {
    return {
      description: clinicalNote.text,
      detectedProblemId: clinicalNote.problem?.id,
      ref: clinicalNote.reference?.id,
      startDate: clinicalNote.creationDate.toISOString(),
    };
  }
}
