<div>
  <natea-cc-sidebar-item
    [item]="item"
    [compact]="this.collapsed"
    (itemSelected)="onItemSelected($event)"
    [basePath]="(patientBasePath$ | async) ?? ''"
    [currentPath]="(currentUrl$ | async) ?? ''"
    [accordionExpanded]="
      item.type === 'group' &&
      ((expandedGroups$ | async)?.includes(item.id) ?? false)
    "
    *ngFor="let item of mainItems"
  ></natea-cc-sidebar-item>
</div>
<div>
  <natea-cc-sidebar-item
    *ngFor="let item of footerItem"
    [item]="item"
    [compact]="this.collapsed"
    [basePath]="(patientBasePath$ | async) ?? ''"
    [currentPath]="(currentUrl$ | async) ?? ''"
    [accordionExpanded]="
      item.type === 'group' &&
      ((expandedGroups$ | async)?.includes(item.id) ?? false)
    "
    (itemSelected)="onItemSelected($event)"
  ></natea-cc-sidebar-item>
</div>
