import { createAction, props } from '@ngrx/store';
import { SidebarItemId } from '../../../shared/components/sidebar/models/sidebar-item';

export enum UserActions {
  LoadUserDate = '[User] Load User Data',
  ToggleMenu = '[User] Toggle Menu',
  ToggleMenuGroupOpened = '[User] Toggle Menu Group Opened',
}

export const toggleMenu = createAction(UserActions.ToggleMenu);

export const toggleMenuGroupOpened = createAction(
  UserActions.ToggleMenuGroupOpened,
  props<{ itemId: SidebarItemId }>()
);
