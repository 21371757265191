export enum EntityEventType {
  Create = 'create',
  Update = 'update',
}

export interface EntityEventField {
  value: string;
  key: string;
  changed: boolean;
}

export interface EntityEvent {
  date: Date;
  type: EntityEventType;
  fields: EntityEventField[];
  user?: string;
}

export interface EntityLog {
  events: EntityEvent[];
}
