/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a stored item in the storage of the user.
 */
export interface StoreItemResource { 
    /**
     * Timestamp when the stored item was created.
     */
    createdAt: string;
    /**
     * Unique identifier for the item.
     */
    id: string;
    /**
     * The item that has been stored.
     */
    item: { [key: string]: object; };
    /**
     * The version with which the item has been stored.
     */
    itemVersion: string;
}

