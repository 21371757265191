import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, timeout } from 'rxjs';
import {
  ApiError,
  ApiErrorLevel,
  FieldErrorCodes,
} from '../../shared/entities/errors';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const timeoutValue = req.headers.get('timeout') || 5000;
    const timeoutValueNumeric = Number(timeoutValue);

    return next.handle(req).pipe(timeout(timeoutValueNumeric));
    // return next.handle(req).pipe(
    //   timeout(2000), // TODO correct
    //   catchError(() => {
    //     const timeoutError: ApiError = {
    //       code: 0,
    //       correlationId: '',
    //       datetime: new Date(),
    //       errors: [
    //         {
    //           code: FieldErrorCodes.Timeout,
    //           message: '',
    //           type: '',
    //           level: ApiErrorLevel.ERROR,
    //           field: '',
    //         },
    //       ],
    //     };
    //     throw timeoutError;
    //   })
    // );
  }
}
