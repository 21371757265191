/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { DetectedProblemCreateRequestResource } from '../model/detectedProblemCreateRequestResource';
// @ts-ignore
import { DetectedProblemPatchRequest } from '../model/detectedProblemPatchRequest';
// @ts-ignore
import { ErrorResponse } from '../model/errorResponse';
// @ts-ignore
import { GeneralResponseDetectedProblemDeletedResource } from '../model/generalResponseDetectedProblemDeletedResource';
// @ts-ignore
import { GeneralResponseDetectedProblemResource } from '../model/generalResponseDetectedProblemResource';
// @ts-ignore
import { GeneralResponseListLogResourceDetectedProblemLogEntryResource } from '../model/generalResponseListLogResourceDetectedProblemLogEntryResource';
// @ts-ignore
import { SearchResponseListDetectedProblemResource } from '../model/searchResponseListDetectedProblemResource';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DetectedProblemApiService {

    protected basePath = 'http://localhost:8090/nateaccpl/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * add detected problem to patient encounter
     * This operation creates a new detection for a new problem catalog entry in the pediatric patient, associating the hospitalization as the initial occurrence of the issue.
     * @param patientId Unique identifier for the patient in whom the problem catalog entry was detected.
     * @param startEncounterId Unique identifier for the initial hospitalization in which the problem catalog entry was first detected for the patient.
     * @param detectedProblemCreateRequestResource Request object for creating a new detection of a problem catalog entry in the pediatric patient.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDetectedProblemToPatientEncounter(patientId: string, startEncounterId: string, detectedProblemCreateRequestResource: DetectedProblemCreateRequestResource, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseDetectedProblemResource>;
    public createDetectedProblemToPatientEncounter(patientId: string, startEncounterId: string, detectedProblemCreateRequestResource: DetectedProblemCreateRequestResource, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseDetectedProblemResource>>;
    public createDetectedProblemToPatientEncounter(patientId: string, startEncounterId: string, detectedProblemCreateRequestResource: DetectedProblemCreateRequestResource, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseDetectedProblemResource>>;
    public createDetectedProblemToPatientEncounter(patientId: string, startEncounterId: string, detectedProblemCreateRequestResource: DetectedProblemCreateRequestResource, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling createDetectedProblemToPatientEncounter.');
        }
        if (startEncounterId === null || startEncounterId === undefined) {
            throw new Error('Required parameter startEncounterId was null or undefined when calling createDetectedProblemToPatientEncounter.');
        }
        if (detectedProblemCreateRequestResource === null || detectedProblemCreateRequestResource === undefined) {
            throw new Error('Required parameter detectedProblemCreateRequestResource was null or undefined when calling createDetectedProblemToPatientEncounter.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startEncounterId !== undefined && startEncounterId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startEncounterId, 'startEncounterId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/detected-problem`;
        return this.httpClient.request<GeneralResponseDetectedProblemResource>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: detectedProblemCreateRequestResource,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete the detected problem specified by id
     * @param detectedProblemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDetectedProblem(detectedProblemId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<GeneralResponseDetectedProblemDeletedResource>;
    public deleteDetectedProblem(detectedProblemId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseDetectedProblemDeletedResource>>;
    public deleteDetectedProblem(detectedProblemId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseDetectedProblemDeletedResource>>;
    public deleteDetectedProblem(detectedProblemId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<any> {
        if (detectedProblemId === null || detectedProblemId === undefined) {
            throw new Error('Required parameter detectedProblemId was null or undefined when calling deleteDetectedProblem.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/detected-problems/${this.configuration.encodeParam({name: "detectedProblemId", value: detectedProblemId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<GeneralResponseDetectedProblemDeletedResource>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get detected problem log
     * This operation retrieves the log entries associated with a detected problem identified by its ID.
     * @param detectedProblemId Unique identifier of the detected problem for which log entries are to be retrieved.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDetectedProblemLogEntries(detectedProblemId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseListLogResourceDetectedProblemLogEntryResource>;
    public getDetectedProblemLogEntries(detectedProblemId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseListLogResourceDetectedProblemLogEntryResource>>;
    public getDetectedProblemLogEntries(detectedProblemId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseListLogResourceDetectedProblemLogEntryResource>>;
    public getDetectedProblemLogEntries(detectedProblemId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (detectedProblemId === null || detectedProblemId === undefined) {
            throw new Error('Required parameter detectedProblemId was null or undefined when calling getDetectedProblemLogEntries.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/detected-problem/${this.configuration.encodeParam({name: "detectedProblemId", value: detectedProblemId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/log`;
        return this.httpClient.request<GeneralResponseListLogResourceDetectedProblemLogEntryResource>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update the detected problem specified by id
     * Update the detected problem identified by its unique identifier, modifying only the fields that are specified with non-null values.
     * @param detectedProblemId The unique identifier of the detected problem to be updated.
     * @param detectedProblemPatchRequest The fields that can be updated for an existing detected problem.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchDetectedProblem(detectedProblemId: string, detectedProblemPatchRequest: DetectedProblemPatchRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<GeneralResponseDetectedProblemResource>;
    public patchDetectedProblem(detectedProblemId: string, detectedProblemPatchRequest: DetectedProblemPatchRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseDetectedProblemResource>>;
    public patchDetectedProblem(detectedProblemId: string, detectedProblemPatchRequest: DetectedProblemPatchRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseDetectedProblemResource>>;
    public patchDetectedProblem(detectedProblemId: string, detectedProblemPatchRequest: DetectedProblemPatchRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext}): Observable<any> {
        if (detectedProblemId === null || detectedProblemId === undefined) {
            throw new Error('Required parameter detectedProblemId was null or undefined when calling patchDetectedProblem.');
        }
        if (detectedProblemPatchRequest === null || detectedProblemPatchRequest === undefined) {
            throw new Error('Required parameter detectedProblemPatchRequest was null or undefined when calling patchDetectedProblem.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/detected-problems/${this.configuration.encodeParam({name: "detectedProblemId", value: detectedProblemId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<GeneralResponseDetectedProblemResource>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: detectedProblemPatchRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search patient detected problems
     * This operation retrieves detected problems that belong to the specified patient. Additional filters can be applied to refine the results, and they are combined using an AND logic.
     * @param patientId Unique identifier of the patient whose detected problems are being searched for.
     * @param problemCatalogEntryTypes The domain keys (as a list) representing the problem catalog entry types within the detected problems to be searched, if applicable.
     * @param startDate The start date for the search of detected problems, if applicable.
     * @param endDate The end date for the search of detected problems, if applicable.
     * @param isClosed Indicates whether the detected problems being searched for are closed or not (default: false).
     * @param itemPage The number of items per page.
     * @param page The page number of the result set.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPatientDetectedProblems(patientId: string, problemCatalogEntryTypes?: Array<string>, startDate?: string, endDate?: string, isClosed?: boolean, itemPage?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SearchResponseListDetectedProblemResource>;
    public searchPatientDetectedProblems(patientId: string, problemCatalogEntryTypes?: Array<string>, startDate?: string, endDate?: string, isClosed?: boolean, itemPage?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SearchResponseListDetectedProblemResource>>;
    public searchPatientDetectedProblems(patientId: string, problemCatalogEntryTypes?: Array<string>, startDate?: string, endDate?: string, isClosed?: boolean, itemPage?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SearchResponseListDetectedProblemResource>>;
    public searchPatientDetectedProblems(patientId: string, problemCatalogEntryTypes?: Array<string>, startDate?: string, endDate?: string, isClosed?: boolean, itemPage?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling searchPatientDetectedProblems.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (problemCatalogEntryTypes) {
            problemCatalogEntryTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'problemCatalogEntryTypes');
            })
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (isClosed !== undefined && isClosed !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isClosed, 'isClosed');
        }
        if (itemPage !== undefined && itemPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>itemPage, 'itemPage');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/detected-problems`;
        return this.httpClient.request<SearchResponseListDetectedProblemResource>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
