import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ButtonVariants } from 'natea-components';
import { Observable } from 'rxjs';
import { BoardContent } from '../../../features/board/interfaces/board-content';
import { BoardItemDelete } from '../../../features/board/interfaces/board-item-delete';
import { BoardUserData } from '../../../features/board/interfaces/board-user-type';
import {
  deleteBoardItems,
  deleteSingleBoardNote,
  hideDeletionBoardNoteModal,
  isAtLeastOneNoteFromClipboardOpened,
  showDeletionBoardNoteModal,
} from '../../../features/board/store/board.actions';
import {
  selectBoardNoteContents,
  selectIsDeleteAllBoardNoteFailed,
  selectIsNotNotesFromClipboard,
  selectIsShowConfirmDeletionBoardNoteModal,
} from '../../../features/board/store/board.selectors';

export type deleteBoardNoteType = 'single' | 'all';

@Component({
  selector: 'natea-cc-blackboard-notes-table',
  templateUrl: './blackboard-notes-table.component.html',
  styleUrls: ['./blackboard-notes-table.component.scss'],
})
export class BlackboardNotesTableComponent {
  @Input() showPasteIcon = true;
  @Output() pasted: EventEmitter<string> = new EventEmitter<string>();
  @Output() isRemoveAllItems: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  showConfirmModal = false;
  isConfirmRemoveAllBoardNoteIsDisabled = false;
  isConfirmDeleteItem = false;

  selectedNote?: BoardContent;
  noteList: BoardContent[] = [];

  isShowDeletionBoardNoteModal$ = this.store.select(
    selectIsShowConfirmDeletionBoardNoteModal
  );

  isDeleteAllBoardNoteFailed$ = this.store.select(
    selectIsDeleteAllBoardNoteFailed
  );

  buttonSecondary = ButtonVariants.SECONDARY;

  openedBoardNotesIds = new Set<string>();

  getNotes$ = this.store.select(selectBoardNoteContents);
  isNotNotesFromClipboard$: Observable<boolean> = this.store.select(
    selectIsNotNotesFromClipboard
  );

  constructor(private store: Store) {}

  toggleNote = (note: BoardContent, event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    if (this.openedBoardNotesIds.has(note.id)) {
      this.openedBoardNotesIds.delete(note.id);
    } else {
      this.openedBoardNotesIds.add(note.id);
    }
    if (this.openedBoardNotesIds.size === 1) {
      this.store.dispatch(
        isAtLeastOneNoteFromClipboardOpened({
          isAtLeastOneNoteFromClipboardOpened: true,
        })
      );
    } else if (this.openedBoardNotesIds.size === 0) {
      this.store.dispatch(
        isAtLeastOneNoteFromClipboardOpened({
          isAtLeastOneNoteFromClipboardOpened: false,
        })
      );
    }
  };

  confirmCleanAllItems = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    const data: BoardUserData = {
      userId: '1',
      originEntityType: 'pasteboard',
    };
    this.store.dispatch(deleteBoardItems({ data }));
    this.isConfirmRemoveAllBoardNoteIsDisabled = true;
  };

  cancelClearAllItems = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    this.store.dispatch(
      hideDeletionBoardNoteModal({ isShowDeletionBoardModal: false })
    );
  };

  cancelClearSingleItem = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();

    this.store.dispatch(
      hideDeletionBoardNoteModal({ isShowDeletionBoardModal: false })
    );
  };

  paste = (event: Event, note: BoardContent) => {
    event.stopPropagation();
    event.preventDefault();

    this.pasted.emit(note.description);
  };

  delete = (event: Event, note: BoardContent, notes: BoardContent[]) => {
    event.stopPropagation();
    event.preventDefault();
    this.isConfirmRemoveAllBoardNoteIsDisabled = false;

    this.selectedNote = note;
    this.noteList = notes;

    this.store.dispatch(showDeletionBoardNoteModal({ deleteType: 'single' }));
  };

  clear = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    this.isConfirmRemoveAllBoardNoteIsDisabled = false;
    this.store.dispatch(showDeletionBoardNoteModal({ deleteType: 'all' }));
  };

  confirmDelete = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!this.selectedNote) {
      return;
    }

    const itemDeleted: BoardItemDelete = {
      storedItemId: this.selectedNote.id,
      userId: 'user1', // TODO is only for test,
      originEntityType: 'pasteboard',
    };
    this.store.dispatch(deleteSingleBoardNote({ itemDeleted }));
    this.isConfirmRemoveAllBoardNoteIsDisabled = true;
    if (this.noteList.length === 1) {
      this.isRemoveAllItems.emit(true);
    }
  };
}
