import {
  GeneralResponseNoteResource,
  NotePutRequest,
  NoteResource,
  UserInfoResource,
} from '../generated/api';
import { generateRandomNumber } from './create-new-item-storage-mocked';
import {
  genereRandomFirstName,
  genereRandomLastName,
} from './mocked-clinicalNotes-api.service';

const updateClinicalNoteMockedResponse = (
  noteId: string,
  notePutRequest: NotePutRequest
): GeneralResponseNoteResource => {
  const userInfo: UserInfoResource = {
    id: generateRandomNumber().toString(),
    firstName: genereRandomFirstName(),
    lastName: genereRandomLastName(),
  };

  const noteResource: NoteResource = {
    id: noteId,
    additionalNotes: notePutRequest.description ?? '',
    permission: {
      canDelete: true,
      canUpdate: true,
      canAssess: true,
    },
    status: 'Approving',
    type: 'M',
    version: Math.random() > 0.6 ? 1 : 2,
    observedAt: notePutRequest.startDate ?? new Date().toISOString(),
    createdBy: userInfo,
    createdAt: new Date().toISOString(),
    encounterId: userInfo.id,
  };
  return {
    payload: noteResource,
  };
};

export default updateClinicalNoteMockedResponse;
