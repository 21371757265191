import { createSelector } from '@ngrx/store';
import {
  selectSelectedPatientEncounters,
  selectShownEncounterIds,
} from '../../patients.selectors';
import { EncounterData, PatientAndEncounterIds } from '../../patients.reducers';
import { AllFamilyPathologies } from 'src/app/features/anamnesis/family-anamnesis/entities/all-family-pathologies';

export const selectLoadAllFamilyPathologies = createSelector(
  selectSelectedPatientEncounters,
  selectShownEncounterIds,
  (
    encounters: EncounterData[] | undefined,
    shownEncounterIds?: PatientAndEncounterIds
  ): AllFamilyPathologies | undefined =>
    encounters?.find(
      (encounter) => encounter.id === shownEncounterIds?.encounterId
    )?.allPathologiesInFamily
);
