import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, map } from 'rxjs';
import { AppRoutes } from '../../../shared/routes/routes';
import { DepartmentData } from '../../departments/store/departments.reducers';
import {
  selectCurrentDepartment,
  selectDepartmentDataLoaded,
} from '../../departments/store/departments.selectors';
import { ProblemType } from '../../problems/interfaces/problem-type';
import { selectAllProblemTypes } from '../../problems/store/problems.selectors';
import { selectPatientsDataLoaded } from '../store/patients.selectors';

export function patientsLoadedGuard(): Observable<boolean> {
  const store: Store = inject(Store);
  const router: Router = inject(Router);

  return combineLatest([
    store.select(selectDepartmentDataLoaded),
    store.select(selectPatientsDataLoaded),
    store.select(selectCurrentDepartment),
    store.select(selectAllProblemTypes),
  ]).pipe(
    map(
      ([departmentsLoaded, patientsLoaded, department, problemTypes]: [
        boolean,
        boolean,
        DepartmentData | undefined,
        ProblemType[] | undefined
      ]): boolean => {
        if (!departmentsLoaded) {
          router.navigateByUrl(AppRoutes.departmentsLoadingPage, {
            skipLocationChange: true,
          });
        } else if (
          (!patientsLoaded || problemTypes === undefined) &&
          department
        ) {
          router.navigateByUrl(AppRoutes.patientsLoadingPage(department.id), {
            skipLocationChange: true,
          });
        }
        return departmentsLoaded && patientsLoaded;
      }
    )
  );
}
