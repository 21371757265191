import { environment } from '../../../environments/environment';
import { ConfigService } from '../../core/config/config.service';
import {
  ClinicalNoteApiService,
  DetectedProblemApiService,
  EncounterApiService,
  ProblemCatalogApiService,
  UserStorageApiService,
} from '../../core/generated/api';
import { MockedClinicalNoteApiService } from '../../core/mocks/mocked-clinicalNotes-api.service';
import { MockedEncounterApiService } from '../../core/mocks/mocked-encounter-api.service';
import { MockedProblemiRilevatiApiService } from '../../core/mocks/mocked-problemiRilevati-api.service';
import { MockedProblemsDataApiService } from '../../core/mocks/mocked-problems-data-api.service';
import { MockedUserStorageApiService } from '../../core/mocks/mocked-user-storage-api-service';
import { MockedDepartmentsWebApi } from '../../features/departments/store/mock/departments-web-api.mock';
import { DepartmentsWebApi } from '../../features/departments/webapis/departments-web-api';
import { MockedPatientsWebApi } from '../../features/patients/store/mock/patients-api-mock';
import { PatientsWebApi } from '../../features/patients/webapi/patient-web-api';

export function ProblemsCatalogApiServiceFactory(): typeof ProblemCatalogApiService {
  return environment.mock
    ? MockedProblemsDataApiService
    : ProblemCatalogApiService;
}

export function DetectedProblemsApiServiceFactory(): typeof DetectedProblemApiService {
  return environment.mock
    ? MockedProblemiRilevatiApiService
    : DetectedProblemApiService;
}

export function DepartmentsApiServiceFactory(): typeof DepartmentsWebApi {
  return environment.mock ? MockedDepartmentsWebApi : DepartmentsWebApi;
}

export function PatientsApiServiceFactory(): typeof PatientsWebApi {
  return environment.mock ? MockedPatientsWebApi : PatientsWebApi;
}

export function ClinicalnotesApiServiceFactory() {
  return environment.mock
    ? MockedClinicalNoteApiService
    : ClinicalNoteApiService;
}

export function UserStorageApiServiceFactory(): typeof UserStorageApiService {
  return environment.mock ? MockedUserStorageApiService : UserStorageApiService;
}

export function EncounterApiServiceFactory() {
  return environment.mock ? MockedEncounterApiService : EncounterApiService;
}

export function BasePathFactory(configService: ConfigService) {
  return window.location.origin + '/nateaccpl/api';
  // return configService.loadAppConfig().then(() => {
  //   console.log('configService.baseUrl', configService.baseUrl);
  //   return configService.baseUrl;
}
