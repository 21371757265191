import { Component, EventEmitter, Input } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { ButtonVariants, SizeType } from 'natea-components';
import { Observable } from 'rxjs';
import {
  goToPatient,
  hideSearchPatientModal,
  loadAllPatients,
  searchPatient,
} from '../../../../features/patients/store/patients.actions';
import {
  selectIsSearchingPatients,
  selectSearchPatientsModalResult,
} from '../../../../features/patients/store/patients.selectors';
import { PatientSelectedEvent } from './components/patients-result-table/patients-result-table.component';
import {
  SearchPatientForm,
  SearchPatientFormValues,
} from './interface/search-patient-form';
import { SearchPatientResultInterface } from './interface/search-result';

@Component({
  selector: 'natea-cc-search-patients',
  templateUrl: './search-patients-modal.component.html',
  styleUrls: ['./search-patients-modal.component.scss'],
})
export class SearchPatientsModalComponent {
  @Input() patientsLoaded = false;

  search: EventEmitter<Event> = new EventEmitter<Event>();
  isSearchPatientForm = true;

  results$: Observable<SearchPatientResultInterface[] | undefined> =
    this.store.select(selectSearchPatientsModalResult);

  isSearchingPatients$ = this.store.select(selectIsSearchingPatients);

  searchPatientForm!: FormGroup<SearchPatientForm>;

  isAnyResults = false;

  formActionsEnabled = true;

  constructor(private fb: FormBuilder, private store: Store) {
    this.searchPatientForm = this.createInitialForm();
    this.results$.subscribe(
      (results: SearchPatientResultInterface[] | undefined) => {
        this.isAnyResults = !!results && results?.length > 0;
      }
    );
    this.searchPatientForm.valueChanges.subscribe(() => {
      this.formActionsEnabled = true;
    });
  }

  ButtonVariants = ButtonVariants;
  modalSize: SizeType = SizeType.XLARGE;

  /**************** form ******************/
  private createInitialForm = (): FormGroup<SearchPatientForm> => {
    const form: FormGroup<SearchPatientForm> =
      this.fb.nonNullable.group<SearchPatientForm>({
        surname: new FormControl(''),
        name: new FormControl(''),
        motherName: new FormControl(''),
        gestationalAgeMin: new FormControl(''),
        gestationalAgeMax: new FormControl(''),
        dateBirth: new FormControl({ value: new Date(), disabled: false }),
        weightMin: new FormControl(''),
        weightMax: new FormControl(''),
        includeDischarged: new FormControl(false),
      });
    form
      .get('gestationalAgeMin')
      ?.addValidators(this.validateRangeMin(form, 'gestationalAgeMax'));
    form
      .get('gestationalAgeMax')
      ?.addValidators(this.validateRangeMax(form, 'gestationalAgeMin'));
    form
      .get('weightMin')
      ?.addValidators(this.validateRangeMin(form, 'weightMax'));
    form
      .get('weightMax')
      ?.addValidators(this.validateRangeMax(form, 'weightMin'));
    return form;
  };

  private validateRangeMin = (
    form: FormGroup,
    otherField: string
  ): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      const otherValue = form.get(otherField)?.value;
      if (
        otherValue != null &&
        otherValue !== '' &&
        control.value != null &&
        control.value != '' &&
        Number(control.value) > Number(otherValue)
      ) {
        return { rangeMin: true };
      }
      return null;
    };
  };

  private validateRangeMax = (
    form: FormGroup,
    otherField: string
  ): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      const otherValue = form.get(otherField)?.value;
      if (
        otherValue != null &&
        otherValue !== '' &&
        control.value != null &&
        control.value != '' &&
        Number(control.value) < Number(otherValue)
      ) {
        return { rangeMax: true };
      }
      return null;
    };
  };
  /**************** form ******************/

  onSearchPatient = (): void => {
    this.formActionsEnabled = false;
    const formData: SearchPatientFormValues = {
      surname: this.searchPatientForm.controls.surname.value,
      name: this.searchPatientForm.controls.name.value,
      motherName: this.searchPatientForm.controls.motherName.value,
      gestationalAgeMin:
        this.searchPatientForm.controls.gestationalAgeMin.value,
      gestationalAgeMax:
        this.searchPatientForm.controls.gestationalAgeMax.value,
      dateBirth: this.searchPatientForm.controls.dateBirth.value,
      weightMin: this.searchPatientForm.controls.weightMin.value,
      weightMax: this.searchPatientForm.controls.weightMax.value,
      includeDischarged:
        this.searchPatientForm.controls.includeDischarged.value,
    };
    this.store.dispatch(searchPatient({ searchPatientFormValues: formData }));
  };

  onNavigate = (event: PatientSelectedEvent): void => {
    if (!this.patientsLoaded) {
      this.store.dispatch(
        loadAllPatients({ departmentId: event.departmentId })
      );
    }
    this.store.dispatch(
      goToPatient({
        departmentId: event.departmentId,
        patientId: event.patientId,
      })
    );
    this.onCloseModal();
  };

  onCloseModal = (): void => {
    this.store.dispatch(hideSearchPatientModal());
  };
}
