import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { generateRandomId } from '../utils/id-generator';
import { NateaIconsName } from './natea-icon';
import { NateaIconsRegistry } from './natea-icons-registry.service';

@Component({
  selector: 'natea-icons',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./natea-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NateaIconsComponent implements OnChanges, OnInit {
  private svgIcon!: SVGElement;
  private wrapperButton!: SVGElement;

  @Input() title?: string;
  @Input() fill?: string;
  @Input() iconClass?: string;
  @Input() width?: string;
  @Input() height?: string;

  @Input() importFromSprite = false;
  @Input() buttonType: 'button' | 'submit' | 'reset' = 'button';
  @Output() iconClick: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();

  private iconName?: string;

  @Input()
  set name(iconName: NateaIconsName) {
    const isFirstInit: boolean = this.iconName === undefined;

    this.iconName = iconName;
    if (this.svgIcon) {
      if (this.iconClick.observed) {
        this.wrapperButton.removeChild(this.svgIcon);
      } else {
        this.element.nativeElement.removeChild(this.svgIcon);
      }
    }
    const svgData: string | undefined =
      this.nateaIconRegistry.getIcon(iconName);
    if (svgData) {
      if (this.importFromSprite) {
        this.svgIcon = this.document.createElement('svg');

        const useTag = this.document.createElement('use');
        useTag.setAttribute('xlink:href', `assets/icons/icons.svg#${iconName}`);

        this.svgIcon.appendChild(useTag);
      } else {
        this.svgIcon = this.svgElementFromString(svgData);
      }

      this.svgIcon.setAttribute('role', 'img');
      this.svgIcon.setAttribute('width', '100%');
      this.svgIcon.setAttribute('height', '100%');
      this.svgIcon.style.display = 'block';

      if (!isFirstInit) {
        if (this.iconClick.observed) {
          this.wrapperButton.appendChild(this.svgIcon);
        } else {
          this.element.nativeElement.appendChild(this.svgIcon);
        }
      }
    }
  }

  constructor(
    private element: ElementRef,
    private nateaIconRegistry: NateaIconsRegistry,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Optional() @Inject(DOCUMENT) private document: any,
    private renderer: Renderer2
  ) {
    this.wrapperButton = this.document.createElement('button');
    this.wrapperButton.setAttribute('type', this.buttonType);
    this.wrapperButton.classList.add('clickable');
  }

  ngOnInit(): void {
    // If the icon is clickable, it must be wrapped in a button
    if (this.iconClick.observed) {
      this.wrapperButton.onclick = (event: MouseEvent): void => {
        this.iconClick.emit(event);
      };
      this.wrapperButton.appendChild(this.svgIcon);
      this.element.nativeElement.appendChild(this.wrapperButton);
    } else {
      this.element.nativeElement.appendChild(this.svgIcon);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['title']) {
      if (changes['title'].currentValue) {
        const currentTitle: HTMLTitleElement | null =
          this.svgIcon.querySelector('title');
        if (currentTitle != null) {
          this.svgIcon.removeChild(currentTitle);
        }
        const title = this.renderer.createElement('title');
        title.innerHTML = changes['title'].currentValue;
        title.id = ['icon', this.iconName, generateRandomId(8), 'title'].join(
          '-'
        );

        this.svgIcon.setAttribute('aria-labelledby', title.id);
        this.svgIcon.appendChild(title);
      }
    }
    if (changes['fill']) {
      this.svgIcon.setAttribute('fill', changes['fill'].currentValue);
    }
    if (changes['iconClass']) {
      this.svgIcon.setAttribute('class', changes['iconClass'].currentValue);
    }
    if (changes['height']) {
      this.svgIcon.setAttribute('height', changes['height'].currentValue);
    }
    if (changes['width']) {
      this.svgIcon.setAttribute('width', changes['width'].currentValue);
    }
  }

  private svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    return (
      div.querySelector('svg') ||
      this.document.createElementNS('http://www.w3.org/2000/svg', 'path')
    );
  }
}
