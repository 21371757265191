<section *transloco="let t; read: 'problems.filter'">
  <div>
    <span class="filter-used">
      <span class="displayed">{{ t("displayed") }}</span>
      <span class="vertical-two-dot">:</span>
      <span class="items-labels">
        <span
          *ngFor="let problemList of problemListItems | async; let i = index"
          class="single-problem"
        >
          <span *ngIf="i !== 0"> | </span>
          {{ t(problemList) }}
        </span>
      </span>
    </span>
    <button
      type="button"
      #iconButton
      (click)="onStateButtonChange($event)"
      class="icon-button"
    >
      <natea-icons name="filter" title="filter" class="icon"></natea-icons>
    </button>

    <ng-container *ngIf="filterProblem$ | async as filterValues">
      <div
        *ngIf="showDropdown"
        nateaCcClickOutside
        class="dropdown-container"
        (clickOutside)="hideDropdown($event)"
      >
        <natea-checkbox
          *ngFor="let labelAndId of filterLabels"
          [checked]="checkElement(filterValues, labelAndId.id)"
          [label]="t(labelAndId.description)"
          [value]="labelAndId.id"
          [id]="labelAndId.id"
          (checkboxChange)="onCheckboxChange($event)"
        ></natea-checkbox>
      </div>
    </ng-container>
  </div>
</section>
