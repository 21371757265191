<mat-form-field
  class="field"
  [class.with-left-icon]="!!leftIcon"
  [ngClass]="class"
  [class.link]="isLink"
  [class.fill-width]="fillParentWidth"
  [class.auto-width]="autoWidth"
  [class.no-hint]="hintLabel === undefined && errorMessages === undefined"
  [class.warn]="errorMessages !== undefined"
>
  <mat-hint
    *ngIf="hintLabel !== undefined && !errorMessages"
    [class.disabled]="isDisabled"
    >{{ hintLabel }}</mat-hint
  >

  <mat-hint class="warn" *ngIf="errorMessages !== undefined">
    <natea-field-error [errorMessages]="errorMessages"></natea-field-error>
  </mat-hint>

  <mat-label [class.disabled]="isDisabled" [class.filled]="">{{
    label
  }}</mat-label>

  <ng-container>
    <natea-icons
      *ngIf="leftIcon && (hasValue || selectedItem)"
      [name]="leftIcon"
      class="icon left-icon"
      [class.disabled]="isDisabled"
    ></natea-icons>

    <mat-select
      #select
      matSelect
      [value]="selectedItem?.id"
      [ngClass]="{ 'has-left-icon': leftIcon && (hasValue || selectedItem) }"
      [hideSingleSelectionIndicator]="true"
      (openedChange)="onOpenedChange($event)"
      (selectionChange)="onValueChange($event)"
      [required]="isRequired"
      [compareWith]="attributeDisplay"
      [disabled]="isDisabled"
      class="select"
    >
      <mat-select-trigger *ngIf="isLink">
        <p class="item-label">
          {{
            selectedValue
              ? selectedValue
              : selectedItem?.triggerLabel ?? selectedItem?.label
          }}
        </p>
      </mat-select-trigger>
      <mat-option *ngFor="let item of renderedItems" [value]="item.id">
        <span class="item-label">{{ item.label }}</span>
      </mat-option>
      <mat-option *ngIf="isLoadingMore" [disabled]="true">
        <natea-loader
          class="loader"
          [diameter]="30"
          [strokeWidth]="3"
        ></natea-loader>
      </mat-option>
    </mat-select>
  </ng-container>
</mat-form-field>
