import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatRadioButton,
  MatRadioChange,
  MatRadioModule,
} from '@angular/material/radio';

import { ThemePalette } from '@angular/material/core';
import { OptionsItem } from '../../shared/interfaces/options-item';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'natea-radio-button',
  standalone: true,
  imports: [CommonModule, MatRadioModule],
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RadioButtonComponent,
      multi: true,
    },
  ],
})
export class RadioButtonComponent<T> implements ControlValueAccessor {
  @Input() items: OptionsItem<T>[] = [];

  @Input() checked = false;

  @Input() label?: string = '';

  @Input() required = false;

  @Input() id = '';

  @Input() name = '';

  @Input() color: ThemePalette = 'primary';

  @Input() alignVertically = false;

  disabled = false;

  @Input() labelPosition: 'before' | 'after' = 'after';

  @Output() radioChange: EventEmitter<string> = new EventEmitter<string>();

  selected?: MatRadioButton;
  value = '';

  onChange!: (val: string | null) => void;
  onTouched!: () => void;

  writeValue(data: string): void {
    this.value = data;
  }
  registerOnChange(fn: (val: string | null) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChanged(event: MatRadioChange): void {
    this.value = event.value;

    if (this.onChange) {
      this.onChange(event.value);
      this.radioChange.emit(event.value);
    }
  }
}
