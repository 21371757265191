import { Sort, SortDirection } from '@angular/material/sort';
import { EntityEvent } from '../../../entities/entity-log';
import { compareDates } from '../../../utils/utils';

export const createLogComparator = (
  sort?: Sort
): ((a: EntityEvent, b: EntityEvent) => number) => {
  if (sort?.direction === '') {
    return () => 0;
  }
  return (a: EntityEvent, b: EntityEvent): number => {
    if (!sort) {
      return 0;
    }
    const { active, direction }: { active: string; direction: SortDirection } =
      sort;
    const modifier: number = direction === 'asc' ? -1 : 1;
    switch (active) {
      case 'date':
        return compareDates(a.date, b.date) * modifier;
      case 'type':
        return a.type.localeCompare(b.type) * modifier;

      default:
        return 0;
    }
  };
};
