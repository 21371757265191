import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppRoutes } from '../../../../shared/routes/routes';
import { loadDepartments } from '../../store/departments.actions';
import { selectDepartmentDataLoaded } from '../../store/departments.selectors';

@Component({
  selector: 'natea-cc-departments-loader',
  templateUrl: './departments-loader.component.html',
  styleUrls: ['./departments-loader.component.scss'],
})
export class DepartmentsLoaderComponent implements OnInit {
  loaded$: Observable<boolean> = this.store.select(selectDepartmentDataLoaded);

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    this.store.dispatch(loadDepartments());
    this.loaded$.subscribe((loaded) => {
      if (loaded) {
        this.router.navigateByUrl(AppRoutes.departmentsRoot);
      }
    });
  }
}
