import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DateTime } from 'luxon';

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray: T[] = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

@Injectable()
export class CustomDateAdapter extends NgxMatDateAdapter<Date> {
  dateParseFormat: string;
  dateTimeParseFormat: string;

  constructor(private translate: TranslocoService) {
    super();
    this.dateParseFormat = this.translate.translate('common.dateParseFormat');
    this.dateTimeParseFormat = this.translate.translate(
      'common.dateTimeParseFormat'
    );
  }

  override getHour(date: Date): number {
    return date.getHours();
  }
  override getMinute(date: Date): number {
    return date.getMinutes();
  }
  override getSecond(date: Date): number {
    return date.getSeconds();
  }
  override setHour(date: Date, value: number): void {
    date.setHours(value);
  }
  override setMinute(date: Date, value: number): void {
    date.setMinutes(value);
  }
  override setSecond(date: Date, value: number): void {
    date.setSeconds(value);
  }
  override getYear(date: Date): number {
    return date.getFullYear();
  }
  override getMonth(date: Date): number {
    return date.getMonth();
  }
  override getDate(date: Date): number {
    return date.getDate();
  }
  override getDayOfWeek(date: Date): number {
    return date.getDay();
  }
  override getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    const dtf: Intl.DateTimeFormat = new Intl.DateTimeFormat(this.locale, {
      month: style,
      timeZone: 'utc',
    });
    return range(12, (i: number): string =>
      this._format(dtf, new Date(2017, i, 1))
    );
  }

  override getDateNames(): string[] {
    const dtf: Intl.DateTimeFormat = new Intl.DateTimeFormat(this.locale, {
      day: 'numeric',
      timeZone: 'utc',
    });
    return range(31, (i: number): string =>
      this._format(dtf, new Date(2017, 0, i + 1))
    );
  }
  override getDayOfWeekNames(style: 'short' | 'long' | 'narrow'): string[] {
    const dtf: Intl.DateTimeFormat = new Intl.DateTimeFormat(this.locale, {
      weekday: style,
      timeZone: 'utc',
    });
    return range(7, (i: number): string =>
      this._format(dtf, new Date(2017, 0, i + 1))
    );
  }
  override getYearName(date: Date): string {
    const dtf: Intl.DateTimeFormat = new Intl.DateTimeFormat(this.locale, {
      year: 'numeric',
      timeZone: 'utc',
    });
    return this._format(dtf, date);
  }
  override getFirstDayOfWeek(): number {
    return 0;
  }
  override getNumDaysInMonth(date: Date): number {
    return this.getDate(
      this._createDateWithOverflow(
        this.getYear(date),
        this.getMonth(date) + 1,
        0
      )
    );
  }
  override clone(date: Date): Date {
    return new Date(date.getTime());
  }
  override createDate(year: number, month: number, date: number): Date {
    return this._createDateWithOverflow(year, month, date);
  }
  override today(): Date {
    return new Date();
  }
  override parse(value: any, parseFormat?: any): Date | null {
    if (typeof value == 'number') {
      return new Date(value);
    }

    if (!value) {
      return null;
    }

    const format: string =
      typeof value === 'string' && value.includes(':')
        ? this.dateTimeParseFormat
        : this.dateParseFormat;

    return DateTime.fromFormat(value, format).toJSDate();
  }
  override addCalendarYears(date: Date, years: number): Date {
    return this.addCalendarMonths(date, years * 12);
  }
  override addCalendarMonths(date: Date, months: number): Date {
    let newDate: Date = this._createDateWithOverflow(
      this.getYear(date),
      this.getMonth(date) + months,
      this.getDate(date)
    );

    if (
      this.getMonth(newDate) !=
      (((this.getMonth(date) + months) % 12) + 12) % 12
    ) {
      newDate = this._createDateWithOverflow(
        this.getYear(newDate),
        this.getMonth(newDate),
        0
      );
    }

    return newDate;
  }
  override addCalendarDays(date: Date, days: number): Date {
    return this._createDateWithOverflow(
      this.getYear(date),
      this.getMonth(date),
      this.getDate(date) + days
    );
  }
  override toIso8601(date: Date): string {
    return [
      date.getUTCFullYear(),
      this._to2digit(date.getUTCMonth() + 1),
      this._to2digit(date.getUTCDate()),
    ].join('-');
  }
  override isDateInstance(obj: any): boolean {
    return obj instanceof Date;
  }
  override isValid(date: Date): boolean {
    return this.isDateInstance(date);
  }
  override invalid(): Date {
    return new Date(NaN);
  }
  override format(date: Date, displayFormat: any): string {
    return date
      .toLocaleString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
      .replace(',', '');
  }

  private _to2digit(n: number): string {
    return ('00' + n).slice(-2);
  }

  private _format(dtf: Intl.DateTimeFormat, date: Date): string {
    const data = new Date();
    data.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    data.setUTCHours(
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
    return dtf.format(data);
  }

  private _createDateWithOverflow(
    year: number,
    month: number,
    date: number
  ): Date {
    const data = new Date();
    data.setFullYear(year, month, date);

    return data;
  }
}
