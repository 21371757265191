import { Sort } from '@angular/material/sort';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import {
  saveBoardContentToBoard,
  saveBoardContentToBoardFailure,
  saveBoardContentToBoardSuccess,
} from '../../../../features/board/store/board.actions';
import { ClinicalNote } from '../../interfaces/clinical-note';
import {
  hideClinicalNoteModal,
  showEditClinicalNoteModal,
  showLinkedNote,
} from '../../store/clinical-note.actions';
import { ClinicalNoteModalState } from '../../store/clinical-note.reducers';
import {
  evaluateClinicalNote,
  hideValidationClinicalNoteModal,
  showEditValidationClinicalNoteModal,
  showValidatedAndArchivedClinicalNoteModal,
  showValidationClinicalNoteModal,
  showViewValidationClinicalNoteModal,
  sortNotesValidation,
  validateClinicalNoteFailure,
  validateClinicalNoteSavePressed,
  validateClinicalNoteSuccess,
  validationHideLinkedNote,
} from './notes-validation.actions';

export interface NotesValidationUiState {
  sort?: Sort;
  clinicalNoteValidationModalState?: ClinicalNoteModalState;
  isSavePressed: boolean;
  linkedNotesToShow?: ClinicalNote[];
}

export const noteValidationInitialState: NotesValidationUiState = {
  sort: undefined,
  clinicalNoteValidationModalState: undefined,
  isSavePressed: false,
};

export const notesValidationReducer: ActionReducer<
  NotesValidationUiState,
  Action
> = createReducer(
  noteValidationInitialState,
  on(
    sortNotesValidation,
    (state, { sort }): NotesValidationUiState => ({
      ...state,
      sort,
    })
  ),

  on(
    showValidatedAndArchivedClinicalNoteModal,
    (
      state: NotesValidationUiState,
      { validatedNote }: { validatedNote: ClinicalNote }
    ): NotesValidationUiState => {
      return {
        ...state,
        clinicalNoteValidationModalState: {
          mode: 'validatedAndArchived',
          clinicalNote: validatedNote,
        },
      };
    }
  ),
  on(
    showLinkedNote,
    (state: NotesValidationUiState, { note }): NotesValidationUiState => ({
      ...state,
      linkedNotesToShow: [...(state.linkedNotesToShow ?? []), note],
    })
  ),

  on(
    validationHideLinkedNote,
    (state: NotesValidationUiState): NotesValidationUiState => ({
      ...state,
      // Current list without last item
      linkedNotesToShow: state.linkedNotesToShow?.slice(
        0,
        state.linkedNotesToShow.length - 1
      ),
    })
  ),

  on(
    showValidationClinicalNoteModal,
    (
      state: NotesValidationUiState,
      { clinicalNoteToValidate }: { clinicalNoteToValidate: ClinicalNote }
    ): NotesValidationUiState => ({
      ...state,
      clinicalNoteValidationModalState: {
        mode: 'validation',
        clinicalNote: clinicalNoteToValidate,
      },
    })
  ),
  on(
    hideClinicalNoteModal,
    (state: NotesValidationUiState): NotesValidationUiState => ({
      ...state,
      clinicalNoteValidationModalState: undefined,
    })
  ),

  on(
    showEditValidationClinicalNoteModal,
    (
      state: NotesValidationUiState,
      { clinicalNoteToEdit }: { clinicalNoteToEdit: ClinicalNote }
    ): NotesValidationUiState => ({
      ...state,
      clinicalNoteValidationModalState: {
        mode: 'edit',
        clinicalNote: clinicalNoteToEdit,
      },
    })
  ),

  on(
    showViewValidationClinicalNoteModal,
    (
      state: NotesValidationUiState,
      { clinicalNoteToView }: { clinicalNoteToView: ClinicalNote }
    ): NotesValidationUiState => ({
      ...state,
      clinicalNoteValidationModalState: {
        mode: 'consultation',
        clinicalNote: clinicalNoteToView,
      },
    })
  ),

  on(
    showEditClinicalNoteModal,
    (
      state: NotesValidationUiState,
      { clinicalNoteToEdit }: { clinicalNoteToEdit: ClinicalNote }
    ): NotesValidationUiState => ({
      ...state,
      clinicalNoteValidationModalState: {
        mode: 'edit',
        clinicalNote: clinicalNoteToEdit,
      },
    })
  ),

  on(
    hideValidationClinicalNoteModal,
    (state: NotesValidationUiState): NotesValidationUiState => ({
      ...state,
      clinicalNoteValidationModalState: undefined,
    })
  ),

  on(
    validateClinicalNoteSuccess,
    (state: NotesValidationUiState): NotesValidationUiState => ({
      ...state,
      clinicalNoteValidationModalState: undefined,
      isSavePressed: false,
    })
  ),
  on(
    validateClinicalNoteSavePressed,

    (state: NotesValidationUiState): NotesValidationUiState => ({
      ...state,
      isSavePressed: true,
    })
  ),

  on(
    evaluateClinicalNote,
    (state: NotesValidationUiState): NotesValidationUiState => ({
      ...state,
      isSavePressed: true,
    })
  ),

  on(
    saveBoardContentToBoard,
    (state: NotesValidationUiState): NotesValidationUiState => ({
      ...state,
      isSavePressed: true,
    })
  ),

  on(
    saveBoardContentToBoardSuccess,
    (state: NotesValidationUiState): NotesValidationUiState => ({
      ...state,
      isSavePressed: false,
    })
  ),

  on(
    saveBoardContentToBoardFailure,
    (state: NotesValidationUiState): NotesValidationUiState => ({
      ...state,
      isSavePressed: false,
    })
  ),

  on(
    validateClinicalNoteFailure,
    (state: NotesValidationUiState): NotesValidationUiState => ({
      ...state,
      isSavePressed: false,
    })
  )
);
