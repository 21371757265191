import { ProblemType } from '../../features/problems/interfaces/problem-type';
import { ProfessionalCategory } from '../../shared/entities/professional-category';
import { SearchResponseListProblemCatalogEntryResource } from '../generated/api/model/searchResponseListProblemCatalogEntryResource';

const defaultItemPerPage = 10;
const getProblemsMockedResponse = (
  page = 1,
  description = '',
  itemPerPage = defaultItemPerPage
): SearchResponseListProblemCatalogEntryResource => {
  try {
    const filteredItems = list.filter((item) =>
      item.name.toLowerCase().includes(description.toLowerCase())
    );
    return {
      payload: filteredItems
        .slice(itemPerPage * (page - 1), itemPerPage * page)
        .map((item) => ({
          id: item.id,
          name: item.name,
          longDescription: item.description,
          isEnabled: item.valid,
          problemClass: 'M'
        })),
      pagination: {
        pageNumber: page ?? 1,
        itemsPerPage:
          itemPerPage > filteredItems.length
            ? filteredItems.length
            : itemPerPage,
        totalItemNumber: filteredItems.length,
        totalPageNumber: Math.ceil(filteredItems.length / itemPerPage),
      },
    };
  } catch (_) {
    return {
      payload: [],
    };
  }
  // return {

  //   payload: new Array(10).fill(null).map((_, index) => {

  //     const offsetIndex = itemPerPage * (page - 1) + index;
  //     return {
  //       id:
  //         '167ff78523d6a9ef42b27ec73973' +
  //         offsetIndex.toString().padStart(6, '0'),
  //       description: 'Problem ' + offsetIndex,
  //       longDescription: longDescriptionGenerator(index),
  //       valid: validGenerator(),
  //       problemClass: problemClassGenerator(),
  //     };
  //   }),
  //   pagination: {
  //     pageNumber: page ?? 1,
  //     itemPerPage,
  //     totalItemNumber: 21,
  //     totalPageNumber: 3,
  //   },
  // };
};

const longDescriptionGenerator = (index: number): string | undefined => {
  const randomInt: number = generateRandomInt(0, 10);

  if (randomInt > 5) {
    return 'Problem ' + index;
  } else {
    return;
  }
};

const validGenerator = (): string => {
  const randomInt = generateRandomInt(0, 10);

  if (randomInt > 6) {
    return '1';
  } else {
    return '0';
  }
};

/*const problemClassGenerator = (): string => {
  const randomInt = generateRandomInt(0, 10);

 /* if (randomInt > 6) {
    return ProfessionalCategory.Medical;
  } else {
    return ProfessionalCategory.Nursing;
  }
};*/

export const generateRandomInt = (min: number, max: number): number => {
  const randomBuffer = new Uint32Array(1);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const crypto: Crypto = window.crypto || window.msCrypto;

  crypto.getRandomValues(randomBuffer);

  const randomNumber = randomBuffer[0] / (0xffffffff + 1);

  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(randomNumber * (max - min + 1)) + min;
};

export default getProblemsMockedResponse;

const list: ProblemType[] = [ // RLE
  {
    id: '1f1e7e54-8c1d-4b01-a556-df98a25c6a84',
    name: 'Anemia aplastica',
    description:
      'La anemia aplastica è una condizione caratterizzata da una produzione insufficiente di cellule del sangue nel midollo osseo.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '2f04cb76-5c9b-4e96-9026-0e67b51d51cd',
    name: 'Anemia falciforme',
    description:
      "L'anemia falciforme è una malattia genetica del sangue che causa globuli rossi a forma di falce e può causare sintomi dolorosi e complicazioni.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '3e5a9f24-dc6a-42c2-9f35-b50e0a4605f8',
    name: 'Anencefalia',
    description:
      "L'anencefalia è una grave malformazione congenita in cui il cervello e il cranio non si sviluppano completamente.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: 'd8ed0f05-6f14-4d2a-baa4-72a9c7d07c73',
    name: 'Assistenza ai pazienti chirurgici',
    description:
      "L'assistenza ai pazienti chirurgici comprende la preparazione preoperatoria, il supporto intraoperatorio e le cure postoperatorie.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '4b21e4e3-259f-4d14-9ca0-64303c4475da',
    name: 'Atassia',
    description:
      "L'atassia è un disturbo del movimento che colpisce il coordinamento muscolare e il controllo.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '5c9ef011-9d62-476d-a055-7d5b58e74d0f',
    name: 'Atresia biliare',
    description:
      "L'atresia biliare è una condizione in cui i dotti biliari nel fegato non si sviluppano correttamente, causando un'insufficienza nel drenaggio della bile.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '042c1a54-7f7c-4b3a-95d3-02c48823eb99',
    name: 'Distrofia muscolare di Duchenne',
    description:
      'La distrofia muscolare di Duchenne è una malattia genetica che causa debolezza muscolare progressiva e può portare a problemi cardiaci e respiratori.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '8c6e5f0b-d41f-4c7d-9263-8b2791ee564d',
    name: 'Disturbo dello spettro autistico',
    description:
      "Il disturbo dello spettro autistico è una condizione del neurosviluppo che influisce sulla comunicazione, sull'interazione sociale e sul comportamento.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: 'e1cb8655-32fe-4140-9f4d-3c35d01236d6',
    name: 'Educazione del paziente',
    description:
      "L'educazione del paziente è cruciale per garantire che i pazienti comprendano le loro condizioni, i trattamenti e le cure necessarie.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: 'd0aa051e-63b4-4d68-8046-3a2952aae667',
    name: 'Emorragia intracranica neonatale',
    description:
      "L'emorragia intracranica neonatale è una sanguinamento nel cervello dei neonati, spesso associato alla prematurità e alla fragilità dei vasi sanguigni.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '6b4443c0-7400-45b4-9a7d-13f1783a3c1a',
    name: 'Epilessia infantile',
    description:
      "L'epilessia infantile è una condizione caratterizzata da crisi convulsive ricorrenti, che possono variare in intensità e durata.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '972e7e9b-3c25-40f0-a32b-6a6e7e91b6f3',
    name: 'Fibromatosi',
    description:
      'La fibromatosi è una crescita di tessuto fibroso che può verificarsi in vari organi o tessuti, spesso causando formazioni nodulari.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: 'cf6e1eeb-1097-4b5d-9820-224c7bbd1f9f',
    name: 'Fibrosi cistica',
    description:
      'La fibrosi cistica è una malattia genetica che colpisce i polmoni, il sistema digestivo e altre aree del corpo, causando problemi respiratori e digestivi.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: 'f9cc4f12-4e39-4482-b8b4-e3e418903c11',
    name: 'Gestione del dolore',
    description:
      'La gestione del dolore richiede valutazione, pianificazione e somministrazione di interventi per alleviare il disagio dei pazienti.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: 'dcf7a4db-99fe-4f39-9ef7-9f7a1e2d3cfb',
    name: 'Glioblastoma multiforme pediatrico',
    description:
      'Il glioblastoma multiforme pediatrico è una forma aggressiva di tumore cerebrale che si verifica nei bambini, richiedendo interventi chirurgici e terapie.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '3c64f3c6-95e0-47b5-85fe-eb88964b4cb2',
    name: 'Idrocefalo',
    description:
      "L'idrocefalo è un accumulo anormale di liquido cerebrospinale nel cervello, che può causare ingrossamento del cranio e problemi neurologici.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '10b9c4d3-e5ef-4d3f-8f50-aa77b0ea73fb',
    name: 'Igiene inadeguata',
    description:
      "L'igiene inadeguata può portare a infezioni cutanee, problemi respiratori e altri rischi per la salute.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: 'bb01b2ad-4056-4e44-98dd-cf31308e0d8b',
    name: 'Iperbilirubinemia',
    description:
      "L'iperbilirubinemia è un'elevata concentrazione di bilirubina nel sangue, che può causare ittero e potenziali danni al cervello nei neonati.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: 'b26e3e23-9b64-4fda-af6c-ee7e86515a90',
    name: 'Ipertensione arteriosa pediatrica',
    description:
      "L'ipertensione arteriosa pediatrica è un aumento della pressione sanguigna nei bambini, che può aumentare il rischio di problemi cardiaci e vascolari.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '8c6b9bc9-e81f-4b49-953f-ea0e79e41772',
    name: 'Leucemia linfoblastica acuta',
    description:
      'La leucemia linfoblastica acuta è un tipo di cancro del sangue che colpisce i globuli bianchi immaturi, richiedendo trattamenti intensivi.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '6b19b25b-d8d3-4e85-92bf-cc54d7510f3b',
    name: 'Monitoraggio dei segni vitali',
    description:
      'Il monitoraggio regolare dei segni vitali aiuta a rilevare eventuali cambiamenti nello stato del paziente e a prendere misure tempestive.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '6c548c9f-7d6a-4143-8c6d-e2eb190a7f57',
    name: 'Osteogenesi imperfetta',
    description:
      "L'osteogenesi imperfetta è una malattia genetica che causa fragilità ossea e può portare a fratture frequenti.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '1a79efc7-1031-46c2-9c3a-90ea1f85b582',
    name: 'Otite media',
    description:
      "L'otite media è un'infezione dell'orecchio medio, spesso causata da batteri o virus, che può causare dolore e disturbi uditivi.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '87269354-8fb2-4281-8c50-c1fbc16838e7',
    name: 'Paralisi cerebrale infantile',
    description:
      'La paralisi cerebrale infantile è una disabilità motoria causata da danni al cervello in fase di sviluppo, che può influenzare il movimento e la postura.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '7e16041f-05c9-4d66-b1c5-9d89693cda22',
    name: 'Parotite infantile',
    description:
      "La parotite è un'infezione virale che colpisce le ghiandole salivari, causando gonfiore delle guance e possibili complicazioni.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '87420e11-e0d1-448b-a077-bb6c9b15449f',
    name: 'Pertosse',
    description:
      'La pertosse è una malattia altamente contagiosa delle vie respiratorie, caratterizzata da tosse grave e parossistica.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '1214c3fc-3e99-4e9a-8824-9f3e88316c59',
    name: 'Poliomielite infantile',
    description:
      'La poliomielite è una malattia virale che può causare paralisi, spesso colpendo i bambini, e può portare a danni permanenti al sistema nervoso.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '6ab3a9da-3ea0-4e21-b87e-3e1d7e10a3f3',
    name: 'Posizionamento del catetere',
    description:
      'Il posizionamento del catetere richiede precisione per evitare danni ai tessuti circostanti e ridurre il rischio di infezioni.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '69215b3b-73ad-4c57-9249-7f4f876c3ed2',
    name: 'Prevenzione delle infezioni',
    description:
      'La prevenzione delle infezioni richiede attenzione alle pratiche igieniche e ai protocolli per ridurre il rischio di diffusione di agenti patogeni.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: 'ee88e892-5a0a-4a68-9492-3bc79f6b4cd2',
    name: 'Prevenzione delle lesioni da pressione',
    description:
      'La prevenzione delle lesioni da pressione richiede un adeguato posizionamento, turni e cure della pelle per evitare danni da pressione prolungata.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '81dc4fa4-8a29-4f9b-ae60-9e7993f9c06e',
    name: 'Sindrome da distress respiratorio neonatale',
    description:
      'La sindrome da distress respiratorio neonatale è una condizione caratterizzata da difficoltà respiratorie nei neonati a causa di una mancanza di surfattante nei polmoni.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '6c43284a-ded2-4b1e-8c14-2f4e3bc2e5d9',
    name: 'Sindrome di Turner',
    description:
      'La sindrome di Turner è una condizione genetica caratterizzata da una monosomia X e può causare ritardo nella crescita e caratteristiche fisiche distintive.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '58f6de16-53aa-4b2a-9819-6b3ab037b9e6',
    name: 'Sindrome di velocardiofacciale',
    description:
      'La sindrome velocardiofacciale è una rara malattia genetica che può causare problemi cardiaci, difetti facciali e sfide cognitive.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: 'c5e5eadd-cc2e-4f82-aa6e-49815067f1d3',
    name: 'Sindrome nefrosica',
    description:
      "La sindrome nefrosica è una condizione renale caratterizzata da un'elevata escrezione di proteine nelle urine, edema e bassi livelli di proteine nel sangue.",
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: 'e1bb1446-446c-4da1-a925-43e265587f5f',
    name: 'Somministrazione di farmaci',
    description:
      'La somministrazione accurata dei farmaci richiede attenzione alle dosi, alle vie di somministrazione e agli effetti collaterali.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: 'b1f52a7b-60a4-45a6-a441-0321c5b73df5',
    name: 'Spasmo del sangue arterioso',
    description:
      'Lo spasmo del sangue arterioso è una condizione in cui le arterie si contraggono improvvisamente, limitando il flusso sanguigno e causando sintomi.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: 'de4be6b0-efb6-458e-9e66-9f0f4be3aae7',
    name: 'Spina bifida',
    description:
      'La spina bifida è una malformazione del midollo spinale e delle vertebre circostanti, che può causare disabilità motorie e neurologiche.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  {
    id: '90b673eb-b4ac-44f9-8ca9-e6b00a78c7c4',
    name: 'Valutazione delle ferite',
    description:
      'La valutazione delle ferite richiede competenze nella valutazione del danno tessutale, nella pulizia e nella scelta di trattamenti adeguati.',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
];
