import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { SearchPatientFormValues } from '../../../shared/components/modals/search-patients-modal/interface/search-patient-form';
import { SearchPatientResultInterface } from '../../../shared/components/modals/search-patients-modal/interface/search-result';
import { ApiError } from '../../../shared/entities/errors';
import { Patient } from '../../../shared/entities/patient';
import { PatientEncounter } from '../../problems/interfaces/patient-encounter';

export enum PatientActions {
  LoadAll = '[Patients] Load PatientsList',
  LoadAllSuccess = '[Patients] Load PatientsList Success',
  LoadAllFail = '[Patients] Load PatientsList Fail',
  SortPatients = '[Patients] Sort Patients',
  LoadMandatoryData = '[Patients] Load Mandatory data',
  LoadEncounters = '[Patients] Load Encounters',
  LoadEncountersSuccess = '[Patients] Load Encounters Success',
  LoadEncountersFailure = '[Patients] Load Encounters Failure',
  ShowEncounter = '[Patients] Show Encounter',
  ShowEncounterForSelectedPatient = '[Patients] Show Encounter For Selected Patient',
  HideEncounter = '[Patients] Hide Encounter',
  HideEncounterForSelectedPatient = '[Patients] Hide Encounter For Selected Patient',
  ShowSearchDepartmentsModal = '[Patients] Show Search Modal',
  HideDepartmentsModal = '[Patients] Hide Search Modal',
  SearchPatient = '[Patients] Search Patient',
  SearchPatientSuccess = '[Patients] Search Patient Success',
  SearchPatientFailure = '[Patients] Search Patient Failure',
  ExportPatients = '[Patients] Export Patients',
  ExportPatientsSuccess = '[Patients] Export Patients Success',
  ExportPatientsFailure = '[Patients] Export Patients Failure',
  GoToPatient = '[Patients] Go To Patient',
  GoToPatientSuccess = '[Patients] Go To Patient Success',
  GoToPatientFailure = '[Patients] Go To Patient Failure',
}

export const loadAllPatients = createAction(
  PatientActions.LoadAll,
  props<{ departmentId: string }>()
);

export const loadAllPatientsSuccess = createAction(
  PatientActions.LoadAllSuccess,
  props<{ patients: Patient[] }>()
);

export const loadAllPatientsFailure = createAction(
  PatientActions.LoadAllFail,
  props<{ error: ApiError }>()
);

export const sortPatients = createAction(
  PatientActions.SortPatients,
  props<{ sort: Sort }>()
);

export const loadUserMandatoryData = createAction(
  PatientActions.LoadMandatoryData,
  props<{ patientId: string }>()
);

export const loadEncounters = createAction(
  PatientActions.LoadEncounters,
  props<{ patientId: string }>()
);

export const loadEncountersSuccess = createAction(
  PatientActions.LoadEncountersSuccess,
  props<{ encounters: PatientEncounter[]; patientId: string }>()
);

export const loadEncountersFailure = createAction(
  PatientActions.LoadEncountersFailure,
  props<{ error: ApiError }>()
);

export const showEncounterForSelectedPatient = createAction(
  PatientActions.ShowEncounterForSelectedPatient,
  props<{ encounterId: string }>()
);

export const showEncounter = createAction(
  PatientActions.ShowEncounter,
  props<{ patientId: string; encounterId: string }>()
);

export const hideEncounterForSelectedPatient = createAction(
  PatientActions.HideEncounterForSelectedPatient,
  props<{ encounterId: string }>()
);

export const hideEncounter = createAction(
  PatientActions.HideEncounter,
  props<{ patientId: string; encounterId: string }>()
);

export const searchPatient = createAction(
  PatientActions.SearchPatient,
  props<{ searchPatientFormValues: SearchPatientFormValues }>()
);

export const searchPatientSuccess = createAction(
  PatientActions.SearchPatientSuccess,
  props<{ result: SearchPatientResultInterface[] }>()
);
export const searchPatientFailure = createAction(
  PatientActions.SearchPatientFailure,
  props<{ error: ApiError }>()
);

export const showSearchPatientModal = createAction(
  PatientActions.ShowSearchDepartmentsModal
);

export const hideSearchPatientModal = createAction(
  PatientActions.HideDepartmentsModal
);

export const exportPatients = createAction(PatientActions.ExportPatients);

export const exportPatientsSuccess = createAction(
  PatientActions.ExportPatientsSuccess
);

export const exportPatientsFailure = createAction(
  PatientActions.ExportPatientsFailure,
  props<{ error: ApiError }>()
);

export const goToPatient = createAction(
  PatientActions.GoToPatient,
  props<{
    departmentId: string | null;
    patientId: string;
  }>()
);

export const goToPatientSuccess = createAction(
  PatientActions.GoToPatientSuccess
);

export const goToPatientFailure = createAction(
  PatientActions.GoToPatientFailure,
  props<{ error: ApiError }>()
);
