import { Person } from '../../../../shared/entities/person';

export enum TherapyOccurrenceState {
  Prescribed,
  SentToPreparation,
  Prepared,
  InExecution,
  Administered,
  Suspended,
  Aborted,
}
// export enum TherapyOccurrenceState {
//   Prescribed = 'prescribed',
//   SentToPreparation = 'sentToPreparation',
//   Prepared = 'prepared',
//   InExecution = 'inExecution',
//   Administered = 'administered',
//   Suspended = 'suspended',
//   Aborted = 'aborted',
// }

export enum TherapyDurationType {
  Instant = 'Instant',
  Prolonged = 'Prolonged',
}

/***
 * Each single occurrence of a therapy. It may have additional information about the administration parameters
 * (e.g. the dosage, the administration type, etc.) or generic ones (e.g. the milk type prescribed for a meal).
 */
export interface TherapyOccurrence<
  AdministrationType = unknown,
  AdditionalInfo = unknown
> {
  id: string;

  prescriptionId: string;

  durationType: TherapyDurationType;

  // Expected duration of the therapy (if not null, therapy is prolonged) TODO define the unit of measure
  duration?: number;

  // Date in which the therapy has been administered
  executionDate?: Date;

  // Date in which the therapy has been completed
  terminationDate?: Date;

  // Useful to keep track of the occurrences that has been suspended
  isValid: boolean;

  state: TherapyOccurrenceState;

  weightKg: string;

  // Date in which the doctor suppose to administer the therapy
  programmingDate: Date;
  administration?: AdministrationType;

  additionalInfo?: AdditionalInfo;
}

export interface TherapyOccurrenceSearchResultItem {
  id: string;
  prescriptionId: string;
  ingredient: string;
  author: Person;
  prescriptionDate: Date;
  programmingDate: Date;
  executionDate?: Date;
  state: TherapyOccurrenceState;
  durationType: TherapyDurationType;
}
