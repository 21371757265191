import { AppRoutes } from '../../../routes/routes';
import { SidebarItem, SidebarItemId } from '../models/sidebar-item';

// Create the entire structure of the sidebar
export const sidebarMainData: SidebarItem[] = [
  {
    id: SidebarItemId.ClinicalRecap,
    icon: 'riepilogo-clinico',
    type: 'leaf',
    path: '',
  },
  {
    id: SidebarItemId.Anamnesis,
    icon: 'anamnesi',
    type: 'group',
    path: AppRoutes.Paths.Anamnesis,
    children: [
      {
        id: SidebarItemId.FamilyAnamnesis,
        icon: 'anamnesi-familiare',
        type: 'leaf',
        path: AppRoutes.Paths.FamilyAnamnesis,
      },
      {
        id: SidebarItemId.Pregnancy,
        icon: 'anamnesi-gravidanza',
        type: 'leaf',
        path: AppRoutes.Paths.Pregnancy,
      },
      {
        id: SidebarItemId.LaborAndDelivery,
        icon: 'anamnesi-travaglio-e-parto',
        type: 'leaf',
        path: '',
      },
      {
        id: SidebarItemId.Newborn,
        icon: 'anamnesi-neonato',
        type: 'leaf',
        path: AppRoutes.Paths.NewBorn,
      },
      {
        id: SidebarItemId.PrimaryResuscitation,
        icon: 'anamnesi-rianimazione-primaria',
        type: 'leaf',
        path: '',
      },
    ],
  },

  {
    id: SidebarItemId.DeliveryRoomMonitoring,
    icon: 'monitoraggio-in-sala-parto',
    type: 'leaf',
    path: '',
  },
  {
    id: SidebarItemId.NeonatalScreenings,
    icon: 'screening-neonatale',
    type: 'leaf',
    path: '',
  },
  {
    id: SidebarItemId.Problems,
    icon: 'patologie',
    type: 'leaf',
    path: AppRoutes.Paths.Problems,
  },
  {
    id: SidebarItemId.ClinicalNotes,
    icon: 'note-di-diario',
    type: 'group',
    path: AppRoutes.Paths.ClinicalNotes,
    navigable: true,
    children: [
      {
        id: SidebarItemId.ClinicalNotesValidation,
        icon: 'note-di-diario-validazione',
        type: 'leaf',
        path: AppRoutes.Paths.NotesValidation,
      },
    ],
  },

  {
    id: SidebarItemId.Therapies,
    icon: 'terapie',
    type: 'group',
    path: AppRoutes.Paths.Therapies,
    children: [
      {
        id: SidebarItemId.TherapeuticalConfirmation,
        icon: 'conferma-terapeutica',
        type: 'leaf',
        path: AppRoutes.Paths.TherapeuticalConfirmation,
      },
      {
        id: SidebarItemId.PharmacologicalTherapies,
        icon: 'terapie-farmacologiche',
        type: 'leaf',
        path: AppRoutes.Paths.PharmacologicalTherapies,
      },
      {
        id: SidebarItemId.TransfusionTherapies,
        icon: 'terapie-trasfusionali',
        type: 'leaf',
        path: AppRoutes.Paths.TransfusionalTherapies,
      },
      {
        id: SidebarItemId.NutritionalTherapies,
        icon: 'terapie-nutrizionali',
        type: 'leaf',
        path: AppRoutes.Paths.NutritionalTherapies,
      },
      {
        id: SidebarItemId.InstrumentalTherapies,
        icon: 'terapie-strumentali',
        type: 'leaf',
        path: AppRoutes.Paths.InstrumentalTherapies,
      },
    ],
  },
  {
    id: SidebarItemId.Procedures,
    icon: 'procedure',
    type: 'leaf',
    path: AppRoutes.Paths.Procedures,
  },
  {
    id: SidebarItemId.DiagnosticTests,
    icon: 'esami',
    type: 'leaf',
    path: '',
  },
  {
    id: SidebarItemId.Consultations,
    icon: 'consulente',
    type: 'leaf',
    path: '',
  },
  {
    id: SidebarItemId.NursingRecord,
    icon: 'cartella-infermieristica',
    type: 'group',
    path: AppRoutes.Paths.NurseRecords,
    children: [
      {
        id: SidebarItemId.NursingGantt,
        icon: 'gantt-infermieristico',
        type: 'leaf',
        path: AppRoutes.Paths.NursingGantt,
      },
      {
        id: SidebarItemId.TherapyAdministration,
        icon: 'terapie',
        type: 'group',
        path: AppRoutes.Paths.TherapiesAdministration,
        children: [
          {
            id: SidebarItemId.PharmacologicalTherapiesAdministration,
            icon: 'terapie-farmacologiche',
            type: 'leaf',
            path: AppRoutes.Paths.PharmacologicalTherapies,
          },
          {
            id: SidebarItemId.TransfusionTherapiesAdministration,
            icon: 'terapie-trasfusionali',
            type: 'leaf',
            path: AppRoutes.Paths.TransfusionalTherapies,
          },
          {
            id: SidebarItemId.NutritionalTherapiesAdministration,
            icon: 'terapie-nutrizionali',
            type: 'leaf',
            path: AppRoutes.Paths.NutritionalTherapies,
          },
          {
            id: SidebarItemId.InstrumentalTherapiesAdministration,
            icon: 'terapie-strumentali',
            type: 'leaf',
            path: AppRoutes.Paths.InstrumentalTherapies,
          },
        ],
      },
      {
        id: SidebarItemId.IncomeAndOutcome,
        icon: 'entrate-e-uscite',
        type: 'leaf',
        path: '',
      },
      {
        id: SidebarItemId.NursingAssessments,
        icon: 'infermiere',
        type: 'leaf',
        path: '',
      },
      {
        id: SidebarItemId.AnthropometricData,
        icon: 'dati-antropometrici',
        type: 'leaf',
        path: '',
      },
    ],
  },

  {
    id: SidebarItemId.ClinicalRecord,
    icon: 'storico-paziente',
    type: 'footer',
    path: '',
  },
];

export const enabledSidebarItems: Set<SidebarItemId> = new Set([
  SidebarItemId.Anamnesis,
  SidebarItemId.Pregnancy,
  SidebarItemId.Newborn,
  SidebarItemId.FamilyAnamnesis,
  SidebarItemId.Problems,
  SidebarItemId.ClinicalNotes,
  SidebarItemId.ClinicalNotesValidation,
  SidebarItemId.InstrumentalTherapies,
  SidebarItemId.NursingGantt,
  SidebarItemId.Procedures,
  SidebarItemId.Therapies,
  SidebarItemId.InstrumentalTherapies,
  SidebarItemId.PharmacologicalTherapies,
  SidebarItemId.NutritionalTherapies,
  SidebarItemId.TransfusionTherapies,
  SidebarItemId.NursingRecord,
  SidebarItemId.TherapyAdministration,
  SidebarItemId.PharmacologicalTherapiesAdministration,
  SidebarItemId.InstrumentalTherapiesAdministration,
  SidebarItemId.NutritionalTherapiesAdministration,
  SidebarItemId.TransfusionTherapiesAdministration,
  SidebarItemId.TherapeuticalConfirmation,
]);
