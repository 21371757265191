import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { ApiError } from '../../../../shared/entities/errors';
import { TransfusionalTherapy } from '../entities/transfusional-therapy';
import { TransfusionalTherapyFormData } from '../form/transfusional-therapy-form-data';
import { BloodComponent } from '../entities/blood-component';
import { SelectedTherapyOccurrenceIds } from '../../common/entities/selected-therapy';

export enum TransfusionalTherapiesActions {
  LoadTransfusionalTherapies = '[TransfusionalTherapies] Load TransfusionalTherapies',
  LoadTransfusionalTherapiesSuccess = '[TransfusionalTherapies] Load TransfusionalTherapies Success',
  LoadTransfusionalTherapiesFailure = '[TransfusionalTherapies] Load TransfusionalTherapies Failure',

  SortTransfusionalTherapies = '[TransfusionalTherapies] Sort',

  SelectOccurrence = '[TransfusionalTherapies] Select Occurrence',
  DeselectOccurrence = '[TransfusionalTherapies] Deselect Occurrence',

  ShowCreateNewModal = '[TransfusionalTherapies] Show Create New Modal',
  HideCreateNewModal = '[TransfusionalTherapies] Hide Create New Modal',

  CreateTransfusionalTherapy = '[TransfusionalTherapies] Create TransfusionalTherapy',
  CreateTransfusionalTherapySuccess = '[TransfusionalTherapies] Create TransfusionalTherapy Success',
  CreateTransfusionalTherapyFailure = '[TransfusionalTherapies] Create TransfusionalTherapy Failure',

  EditTransfusionalTherapy = '[TransfusionalTherapies] Edit TransfusionalTherapy',
  EditTransfusionalTherapySuccess = '[TransfusionalTherapies] Edit TransfusionalTherapy Success',
  EditTransfusionalTherapyFailure = '[TransfusionalTherapies] Edit TransfusionalTherapy Failure',

  SelectedTransfusionalTherapyFromTimeline = '[TransfusionalTherapies] Selected TransfusionalTherapy From Timeline',
  TabIndexTransfusionalTherapyChanged = '[TransfusionalTherapies] Tab Index TransfusionalTherapy Changed',

  DownloadTypes = '[TransfusionalTherapies] Download Types',
  DownloadTypesSuccess = '[TransfusionalTherapies] Download Types Success',
  DownloadTypesFailure = '[TransfusionalTherapies] Download Types Failure',

  ConfirmDeleteTransfusionalTherapy = '[TransfusionalTherapies] Confirm Delete TransfusionalTherapy',
  HideDeleteConfirmationModal = '[TransfusionalTherapies] Hide Delete Confirmation Modal',
  ShowDeleteConfirmationModal = '[TransfusionalTherapies] Show Delete Confirmation Modal',

  ConfirmDeleteTransfusionalTherapyFailure = '[TransfusionalTherapies] Confirm Delete TransfusionalTherapy Failure',
  ConfirmDeleteTransfusionalTherapySuccess = '[TransfusionalTherapies] Confirm Delete TransfusionalTherapy Success',
  ShowConfirmAbortModal = '[TransfusionalTherapies] Show Confirm Abort Modal',
  HideConfirmAbortModal = '[TransfusionalTherapies] Hide Confirm Abort Modal',

  ConfirmAbortTransfusionalTherapy = '[TransfusionalTherapies] Confirm Abort TransfusionalTherapy',
  ConfirmAbortTransfusionalTherapyFailure = '[TransfusionalTherapies] Confirm Abort TransfusionalTherapy Failure',
  ConfirmAbortTransfusionalTherapySuccess = '[TransfusionalTherapies] Confirm Abort TransfusionalTherapy Success',

  SelectSuspensionCandidate = '[TransfusionalTherapies] Select Suspension Candidate',
  AbortSuspension = '[TransfusionalTherapies] Abort Suspension',
  ConfirmSuspension = '[TransfusionalTherapies] Confirm Suspension',
  SuspensionSuccess = '[TransfusionalTherapies] Suspension Success',
  SuspensionFailure = '[TransfusionalTherapies] Suspension Failure',

  ShowTransfusionalConfirmSuspendModal = '[TransfusionalTherapies] Show Transfusional Confirm Suspend Modal',
  HideTransfusionalConfirmSuspendModal = '[TransfusionalTherapies] Hide Transfusional Confirm Suspend Modal',
  ConfirmSuspendTransfusionalTherapy = '[TransfusionalTherapies] Confirm Suspend Transfusional Therapy',

  SelectedTransfusionalTherapyFromSearchTable = '[TransfusionalTherapies] Selected TransfusionalTherapy From Search Table',
}

export const loadTransfusionalTherapies = createAction(
  TransfusionalTherapiesActions.LoadTransfusionalTherapies
);

export const loadTransfusionalTherapiesSuccess = createAction(
  TransfusionalTherapiesActions.LoadTransfusionalTherapiesSuccess,
  props<{
    therapies: TransfusionalTherapy[];
    patientId: string;
    encounterId: string;
  }>()
);

export const loadTransfusionalTherapiesFailure = createAction(
  TransfusionalTherapiesActions.LoadTransfusionalTherapiesFailure,
  props<{ error: ApiError }>()
);

export const sortTransfusionalTherapies = createAction(
  TransfusionalTherapiesActions.SortTransfusionalTherapies,
  props<{ sort: Sort }>()
);

export const selectTransfusionalTherapyOccurrence = createAction(
  TransfusionalTherapiesActions.SelectOccurrence,
  props<{ selectedTherapy: SelectedTherapyOccurrenceIds }>()
);

export const deselectTransfusionalTherapy = createAction(
  TransfusionalTherapiesActions.DeselectOccurrence
);

export const showCreateNewTransfusionalTherapyModal = createAction(
  TransfusionalTherapiesActions.ShowCreateNewModal
);
export const hideCreateNewTransfusionalTherapyModal = createAction(
  TransfusionalTherapiesActions.HideCreateNewModal
);

export const createTransfusionalTherapy = createAction(
  TransfusionalTherapiesActions.CreateTransfusionalTherapy,
  props<{
    therapy: TransfusionalTherapyFormData;
  }>()
);

export const createTransfusionalTherapySuccess = createAction(
  TransfusionalTherapiesActions.CreateTransfusionalTherapySuccess,
  props<{
    therapy: TransfusionalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const createTransfusionalTherapyFailure = createAction(
  TransfusionalTherapiesActions.CreateTransfusionalTherapyFailure,
  props<{ error: ApiError }>()
);

// Download therapy types

export const downloadTransfusionalTherapyTypes = createAction(
  TransfusionalTherapiesActions.DownloadTypes
);

export const downloadTransfusionalTherapyTypesSuccess = createAction(
  TransfusionalTherapiesActions.DownloadTypesSuccess,
  props<{
    types: BloodComponent[];
  }>()
);

export const downloadTransfusionalTherapyTypesFailure = createAction(
  TransfusionalTherapiesActions.DownloadTypesFailure,
  props<{ error: ApiError }>()
);

// Edit therapy

export const editTransfusionalTherapy = createAction(
  TransfusionalTherapiesActions.EditTransfusionalTherapy,
  props<{
    therapyId: string;
    therapy: TransfusionalTherapyFormData;
  }>()
);

export const editTransfusionalTherapySuccess = createAction(
  TransfusionalTherapiesActions.EditTransfusionalTherapySuccess,
  props<{
    therapy: TransfusionalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const editTransfusionalTherapyFailure = createAction(
  TransfusionalTherapiesActions.EditTransfusionalTherapyFailure,
  props<{ error: ApiError }>()
);

export const selectedTransfusionalTherapyFromTimeline = createAction(
  TransfusionalTherapiesActions.SelectedTransfusionalTherapyFromTimeline,
  props<{ selectedTherapy: SelectedTherapyOccurrenceIds }>()
);

// Delete

export const showDeleteTransfusionalTherapyConfirmationModal = createAction(
  TransfusionalTherapiesActions.ShowDeleteConfirmationModal
);

export const confirmDeleteTransfusionalTherapy = createAction(
  TransfusionalTherapiesActions.ConfirmDeleteTransfusionalTherapy
);

export const confirmDeleteTransfusionalTherapySuccess = createAction(
  TransfusionalTherapiesActions.ConfirmDeleteTransfusionalTherapySuccess,
  props<{
    therapyId: string;
    patientId: string;
    encounterId: string;
  }>()
);

export const confirmDeleteTransfusionalTherapyFailure = createAction(
  TransfusionalTherapiesActions.ConfirmDeleteTransfusionalTherapyFailure,
  props<{ error: ApiError }>()
);

// Abort

export const showConfirmAbortTransfusionalModal = createAction(
  TransfusionalTherapiesActions.ShowConfirmAbortModal
);

export const confirmAbortTransfusionalModal = createAction(
  TransfusionalTherapiesActions.ConfirmAbortTransfusionalTherapy,
  props<{ justification: string }>()
);

export const confirmAbortTransfusionalTherapySuccess = createAction(
  TransfusionalTherapiesActions.ConfirmAbortTransfusionalTherapySuccess,
  props<{
    therapy: TransfusionalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const hideConfirmAbortTransfusionalModal = createAction(
  TransfusionalTherapiesActions.HideConfirmAbortModal
);

export const hideConfirmDeleteTransfusionalModal = createAction(
  TransfusionalTherapiesActions.HideDeleteConfirmationModal
);

export const confirmAbortTransfusionalTherapyFailure = createAction(
  TransfusionalTherapiesActions.ConfirmAbortTransfusionalTherapyFailure,
  props<{ error: ApiError }>()
);

// Suspension

export const transfusionalTherapySuspensionSuccess = createAction(
  TransfusionalTherapiesActions.SuspensionSuccess,
  props<{
    therapy: TransfusionalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const tabIndexTransfusionalTherapyChanged = createAction(
  TransfusionalTherapiesActions.TabIndexTransfusionalTherapyChanged,
  props<{ tabIndex: number }>()
);

export const showTransfusionalConfirmSuspendModal = createAction(
  TransfusionalTherapiesActions.ShowTransfusionalConfirmSuspendModal
);

export const hideTransfusionalConfirmSuspendModal = createAction(
  TransfusionalTherapiesActions.HideTransfusionalConfirmSuspendModal
);

export const confirmSuspendTransfusionalTherapy = createAction(
  TransfusionalTherapiesActions.ConfirmSuspendTransfusionalTherapy,
  props<{ justification: string }>()
);

export const confirmSuspendTransfusionalTherapyFailure = createAction(
  TransfusionalTherapiesActions.SuspensionFailure,
  props<{ error: ApiError }>()
);

export const selectedTransfusionalTherapyFromSearchTable = createAction(
  TransfusionalTherapiesActions.SelectedTransfusionalTherapyFromSearchTable,
  props<{ selectedTherapy: SelectedTherapyOccurrenceIds }>()
);
