import { Injectable } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import { NewBornData } from '../form/new-born-form-data';
import { NewBornFactSheetCatalogs } from '../entities/general-catalog';
import { factSheetNewBornMock } from './fact-sheet-new-born-mock';

@Injectable({
  providedIn: 'root',
})
export class FactSheetNewBornWebApi {
  public getAllFactSheet(): Observable<NewBornData | undefined> {
    return of(factSheetNewBornMock()).pipe(delay(1000));
  }

  saveFactSheet(
    factSheet: NewBornData,
    percentileValue:
      | {
          percentileWeight: string;
          percentileLength: string;
          percentileHeadCircumference: string;
        }
      | undefined
  ): Observable<NewBornData> {
    /* return throwError(() => new Error('Error saving fact sheet')).pipe(
      delay(1000)
    );*/

    const newBornData: NewBornData = {
      auxologicalData: {
        weightInCentimeter: factSheet.auxologicalData.weightInCentimeter,
        withInCentimeter: factSheet.auxologicalData.withInCentimeter,
        headCircumference: factSheet.auxologicalData.headCircumference,
      },
      note: factSheet.note ? factSheet.note : '',
      completeSheet: factSheet.completeSheet ? factSheet.completeSheet : false,
      blood: factSheet.blood,
      occultProphylaxis: factSheet.occultProphylaxis,
      vitaminK: factSheet.vitaminK,
      general: factSheet.general,

      percentileHeadCircumference: percentileValue?.percentileHeadCircumference,
      percentileLength: percentileValue?.percentileLength,
      percentileWeight: percentileValue?.percentileWeight,
    };
    return of(newBornData).pipe(delay(1000));
  }

  downloadCatalog(): Observable<NewBornFactSheetCatalogs> {
    const generalCatalog: NewBornFactSheetCatalogs = {
      general: {
        directCoombsTest: [
          { id: '1', label: 'Debolmente positivo' },
          { id: '2', label: 'Positivo' },
          { id: '0', label: 'Negativo' },
          { id: '3', label: 'Fortemente negativo' },
        ],
        timeInSec: [
          { id: '1', label: '30-60' },
          { id: '2', label: '60-120' },
          { id: '3', label: '120-180' },
        ],
        collection: [
          { id: '1', label: 'Sì' },
          { id: '0', label: 'No' },
        ],
        uterineTransfer: [
          { id: '1', label: 'Sì' },
          { id: '0', label: 'No' },
        ],
        emissionUrine: [
          { id: '1', label: 'Sì' },
          { id: '0', label: 'No' },
        ],
        emissionMeconium: [
          { id: '1', label: 'Sì' },
          { id: '0', label: 'No' },
        ],
        surfacente: [
          { id: '1', label: 'Sì' },
          { id: '0', label: 'No' },
        ],
      },

      blood: {
        group: [
          { id: '1', label: 'A' },
          { id: '2', label: 'B' },
          { id: '3', label: 'AB' },
          { id: '4', label: 'O' },
        ],
        rhFactor: [
          { id: '1', label: 'Rh+' },
          { id: '2', label: 'Rh-' },
        ],
      },

      occultProphylaxis: {
        pharmacy: [
          { id: '6', label: 'Ofloxacina' },
          {
            id: '3',
            label: 'Soluzione di iodio-povidrone al 2.4% a base acquosa',
          },
          { id: '4', label: "Soluzione di nitrato d'argento all' 1%" },
          { id: '5', label: "Unguento oftalmico di cloramfenicolo all'1% " },
          { id: '2', label: 'Unguento oftalmico di eritromicina allo 0,5%' },
          {
            id: '1',
            label: "Unguento oftalmico di tetraciclina idrocloridrata all'1%",
          },
        ],
      },

      vitaminK: {
        vitaminK: [
          { id: '1', label: '1 mg' },
          { id: '2', label: '2 mg' },
          { id: '3', label: '3 mg' },
          { id: '4', label: '4 mg' },
        ],
        route: [
          { id: '1', label: 'Intramuscolare' },
          { id: '2', label: 'Intravenosa' },
          { id: '3', label: 'Orale' },
        ],
      },
    };

    return of(generalCatalog).pipe(delay(1000));
  }
}
