import { Sort, SortDirection } from '@angular/material/sort';
import { compareDates } from '../../../../shared/utils/utils';
import { NutritionalTherapyTableOccurrence } from '../components/nutritional-therapies-table/nutritional-therapies-table.component';





export const nutritionalTherapiesComparators = (sort?: Sort) => {
  if (sort?.direction === '') {
    return () => 0;
  }

  return (
    a: NutritionalTherapyTableOccurrence,
    b: NutritionalTherapyTableOccurrence
  ): number => {
    if (!sort) {
      return 0;
    }
    const { active, direction }: { active: string; direction: SortDirection } =
      sort;
    const modifier: number = direction === 'asc' ? 1 : -1;
    switch (active) {
      case 'typology':
        return a.typology.localeCompare(b.typology) * modifier;
      case 'programmingDate':
        return compareDates(a.programmingDate, b.programmingDate) * modifier;
      case 'feedType':
        return a.feedType.localeCompare(b.feedType) * modifier;
      case 'state':
          if (a.icon?.isImportant) return modifier * -1;
          if (b.icon?.isImportant) return modifier;
          return (a.state - b.state) * modifier;
      default:
        return 0;
    }
  };
};
