<ng-container *transloco="let t; read: 'problems.timeline'">
  <div
    #wrapper
    class="wrapper"
    *ngIf="!isProblemsListEmpty; else emptyContainer"
    [class.locked]="expandedNotesHeight !== undefined"
    (scroll)="onTimelineScroll($event)"
  >
    <div class="shader"></div>
    <div class="header-container">
      <div class="timeline-header">
        <ng-container *ngIf="isDesktopSized">
          <span *ngFor="let date of dateRange" class="cell date">
            {{
              isToday(date)
                ? t("todayLabel")
                : (date
                  | localizedDate : LocalizedDateFormat.WeekdayDateMonth
                  | uppercase)
            }}
          </span>
        </ng-container>
        <ng-container *ngIf="!isDesktopSized">
          <span
            *ngFor="let date of dateRange"
            [class]="isToday(date) ? 'today' : 'not-today'"
          >
            <span class="cell date not-today-label">
              {{
                date | localizedDate : LocalizedDateFormat.Weekday | uppercase
              }}
            </span>
            <span class="cell date not-today-label">
              {{
                date | localizedDate : LocalizedDateFormat.DateMonth | uppercase
              }}
            </span>
            <span class="cell date today-label">
              {{ t("todayLabel") }}
            </span>
          </span>
        </ng-container>
        <div
          *ngFor="let date of dateRange"
          class="timeline-header-borders"
        ></div>
      </div>
    </div>

    <div
      class="side-columns"
      [class.no-pointer-events]="expandedNotesHeight !== undefined"
    >
      <div class="column problems-name-column">
        <div
          *ngFor="let problem of positionedProblems"
          class="row"
          [class]="problemTypeClass(problem.problemClass)"
          [style.paddingBottom.px]="
            problem === expandedProblem && expandedNotesHeight !== undefined
              ? expandedNotesHeight
              : undefined
          "
        >
          <button
            class="info"
            (click)="onInfoClick(problem)"
            *ngIf="!isDesktopSized"
            type="button"
          >
            <natea-icons
              class="icon info-icon"
              name="circle-info"
            ></natea-icons>
          </button>

          <natea-icons
            *ngIf="problem.problemClass && isDesktopSized"
            class="icon"
            [class]="problemTypeClass(problem.problemClass)"
            [name]="iconForProblemType(problem.problemClass)"
          ></natea-icons>
          <h5>
            <button
              [class]="problemTypeClass(problem.problemClass)"
              (click)="onConsultation(problem)"
              type="button"
            >
              <span>{{ problem.problemDescription }}</span>
            </button>
          </h5>
          <button
            class="info"
            (click)="toggleNote(problem)"
            *ngIf="problem.note && !isDesktopSized"
            type="button"
          >
            <natea-icons
              class="icon arrow-icon"
              [class.expanded]="problem === expandedProblem"
              name="arrow-right"
            ></natea-icons>
          </button>

          <button
            class="info"
            (click)="onInfoClick(problem)"
            *ngIf="isDesktopSized"
            type="button"
          >
            <natea-icons
              class="icon info-icon"
              name="circle-info"
            ></natea-icons>
          </button>

          <button
            type="button"
            class="info"
            (click)="toggleNote(problem)"
            *ngIf="problem.note && isDesktopSized"
          >
            <natea-icons
              class="icon arrow-icon"
              [class.expanded]="problem === expandedProblem"
              name="arrow-right"
            ></natea-icons>
          </button>
          <div class="wrapper-text-icon">
            <natea-icons
              class="icon clock-icon"
              name="clock"
              [class.invisible]="!problem.endDate"
            ></natea-icons>
            <span class="problem-name-duration-label" *ngIf="!isDesktopSized">
              {{ durationLabelForProblemRow(problem) }}
            </span>
          </div>
        </div>
      </div>

      <div class="column actions-column">
        <div
          *ngFor="let problem of positionedProblems"
          class="row"
          [style.paddingBottom.px]="
            problem === expandedProblem ? expandedNotesHeight : undefined
          "
        >
          <button
            (click)="edit.emit(problem)"
            class="edit"
            [disabled]="!problem.editableFromLoggedUser"
            type="button"
          >
            <natea-icons
              *ngIf="problem.problemClass"
              class="icon"
              [class]="problemTypeClass(problem.problemClass)"
              name="edit"
              [class.disabled]="!problem.editableFromLoggedUser"
            ></natea-icons>
          </button>

          <button
            class="button-bin"
            (click)="deleteProblem.emit(problem)"
            [disabled]="!problem.cancelableFromLoggedUser"
            type="button"
          >
            <natea-icons
              *ngIf="problem.problemClass"
              class="icon"
              [class]="problemTypeClass(problem.problemClass)"
              name="bin"
              [class.disabled]="!problem.cancelableFromLoggedUser"
            ></natea-icons>
          </button>
        </div>
      </div>
    </div>

    <div #mainColumnContainer class="container">
      <div #timelineContainer class="timeline-container">
        <div #timelineColumn class="column timeline-column">
          <div class="row filler-row">
            <span *ngFor="let date of dateRange" class="cell date"> </span>
          </div>
          <div
            class="timeline-item-wrapper"
            *ngFor="let problem of positionedProblems; let i = index"
          >
            <div
              class="row timeline-row"
              [class.first-item]="i == 0"
              [class.last-item]="i == positionedProblems.length - 1"
              [class.expanded]="problem === expandedProblem"
            >
              <div *ngFor="let date of dateRange" class="date date-cell"></div>
              <natea-cc-problems-timeline-item
                *ngIf="
                  (problem.endDate === undefined ||
                    problem.endDate >= minDate) &&
                  problem.startDate <= maxDate
                "
                class="cell timeline-item"
                [style.left.%]="problem.leftPositionPercentage"
                [style.width.%]="problem.width"
                [showStart]="showProblemStartDate(problem)"
                [showEnd]="problem.endDate !== undefined"
                [problemType]="problem.problemClass!"
                [durationMinutes]="problem.durationMinutes"
                [showPill]="isDesktopSized"
                [tooltipSide]="i === 0 ? TooltipSide.Bottom : TooltipSide.Top"
                [startDate]="problem.startDate"
                [endDate]="problem.endDate"
                [minDate]="minDate"
                [maxDate]="maxDate"
              ></natea-cc-problems-timeline-item>
            </div>

            <div *ngIf="problem === expandedProblem" class="row filler-row">
              <span *ngFor="let date of dateRange" class="cell date"> </span>
            </div>
            <div
              #notes
              class="notes-container"
              *ngIf="problem === expandedProblem"
            >
              <span class="notes-title">{{ t("notesTitle") }}</span>
              <span
                class="notes"
                [class.visible]="expandedProblem != undefined"
                [style.width.px]="mainContainerWidth"
                [style.left.px]="scrollHorizontalOffset"
              >
                {{ expandedProblem.note ?? "" }}
              </span>
            </div>
            <div
              class="close-button-container"
              *ngIf="problem === expandedProblem"
              [style.width.px]="mainContainerWidth"
              [style.height.px]="closeNoteButtonHeightPx"
              [style.transform]="'translateX(' + scrollHorizontalOffset + 'px)'"
            >
              <natea-button
                size="small"
                class="close-note-button"
                [label]="t('closeNoteLabel')"
                (click)="closeNote()"
              ></natea-button>
            </div>

            <div *ngIf="problem === expandedProblem" class="row filler-row">
              <span *ngFor="let date of dateRange" class="cell date"> </span>
            </div>
          </div>
          <div class="row filler-row">
            <span *ngFor="let date of dateRange" class="cell date"> </span>
          </div>
          <div
            class="today-line"
            [style.left.%]="todayLineLeftPosition"
            [style.display]="showTodayLine ? undefined : 'none'"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #emptyContainer>
    <div class="empty-container" *transloco="let t; read: 'problems.timeline'">
      <div class="empty" *ngIf="isProblemsListEmpty && isAppliedFilter">
        {{ t("noResults") }}
      </div>

      <div *ngIf="!isAppliedFilter" class="empty">
        {{ t("noResultsWithFilter") }}
      </div>
    </div>
  </ng-template>
</ng-container>
