import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { NateaIconsName } from '../../icons/natea-icon';
import { NateaIconsModule } from '../../icons/natea-icons.module';
import { TabItemComponent } from './tab-item/tab-item.component';

export interface TabMenuItems<T> {
  iconName: NateaIconsName;
  label: string;
  iconAlt?: string;
  isDisabled?: boolean;
  content: T;
}

@Component({
  selector: 'natea-tab-menu-items',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    NateaIconsModule,
    RouterModule,
    TabItemComponent,
  ],
  templateUrl: './tab-menu-items.component.html',
  styleUrls: ['./tab-menu-items.component.scss'],
})
export class TabMenuItemsComponent {
  @ContentChildren(TabItemComponent) tabs = {} as QueryList<TabItemComponent>;
  @ContentChildren(TabItemComponent, { descendants: true }) allTab =
    {} as QueryList<TabItemComponent>;
  @Input() tabNumber = 0;

  @Input() firstIconAlt = '';

  @Output() selectedTabChange: EventEmitter<MatTabChangeEvent> =
    new EventEmitter<MatTabChangeEvent>();

  selectedIndex = 0;

  tabChange = (selectedTab: MatTabChangeEvent) => {
    this.selectedIndex = selectedTab.index;
  };
}
