import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TooltipComponentVariant, TooltipSide } from 'natea-components';
import { ProfessionalCategory } from '../../../../shared/entities/professional-category';
import { daysHoursMinutesFromDurationMinutes } from '../../../../shared/utils/duration-utils';

export enum TimelineItemLabelStyle {
  Automatic = 'automatic',
  Hours = 'hours',
  DayHours = 'day-hours',
}

// Constants to calculate pill length in extended view, in duration hours

// The days, hours and minutes values, and the prefix for terminated problems,
// are considered to have same length in duration hours
const BLOCK_HOURS = 8;
// Prefix for ongoing problems, in duration hours
const ONGOING_PREFIX_HOURS: number = BLOCK_HOURS * 3;
// Minimum duration to use extended view, in hours
const MIN_EXTENDED_VIEW_HOURS = 20;

@Component({
  selector: 'natea-cc-problems-timeline-item',
  templateUrl: './problems-timeline-item.component.html',
  styleUrls: ['./problems-timeline-item.component.scss'],
})
export class ProblemTimelineItemComponent implements OnInit, OnChanges {
  ProfessionalCategory = ProfessionalCategory;
  TooltipSide = TooltipSide;
  TooltipComponentVariant = TooltipComponentVariant;

  @Input() showStart = true;
  @Input() showEnd = true;
  @Input() showPill = true;

  @Input() problemType!: ProfessionalCategory;

  @Input() completed = true;

  @Input() durationMinutes?: number;

  @Input() labelStyle: TimelineItemLabelStyle = TimelineItemLabelStyle.DayHours;

  @Input() tooltipSide: TooltipSide = TooltipSide.Top;

  @Input() startDate?: Date;
  @Input() endDate?: Date;

  @Input() minDate!: Date;
  @Input() maxDate!: Date;

  daysCount?: number;
  hoursCount?: number;
  minutesCount?: number;

  compact = false;

  label = '';

  onGoingPrefix = '';

  constructor(private transloco: TranslocoService) {}

  ngOnInit(): void {
    this.onGoingPrefix = this.transloco.translate('problems.onGoingFromPrefix');
  }

  get problemTypeClass(): string {
    return this.problemType !== ProfessionalCategory.Nursing
      ? 'medical'
      : 'nursing';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['durationMinutes']) {
      this.updateDuration();
    }
  }

  updateDuration(): void {
    const label: string[] = [];
    if (this.durationMinutes !== undefined) {
      const {
        days,
        hours,
        minutes,
      }: { days: number; hours: number; minutes: number } =
        daysHoursMinutesFromDurationMinutes(this.durationMinutes);
      if (this.labelStyle === TimelineItemLabelStyle.DayHours) {
        this.daysCount = days;
        this.hoursCount = hours;
        this.minutesCount = minutes;
      }

      if (!this.daysCount && !this.hoursCount) {
        this.minutesCount = Math.floor(this.durationMinutes % 60);
      }
    }

    // Calculate pill length in extended view, in duration hours. If the problem duration
    // is less than this value (or the minimum duration), the compact view will be used.
    // Pill length is determined by the prefix (ongoing or not) and the duration
    // (non-zero days, hours or minutes)
    let pillLength: number = this.endDate ? BLOCK_HOURS : ONGOING_PREFIX_HOURS;

    if (this.daysCount) {
      label.push(
        this.transloco.translate('problems.durationDaysLabel', {
          days: `${this.daysCount}`.padStart(2, '0'),
        })
      );
      pillLength += BLOCK_HOURS;
    }
    if (this.hoursCount) {
      label.push(
        this.transloco.translate('problems.durationHoursLabel', {
          hours: `${this.hoursCount}`.padStart(2, '0'),
        })
      );
      pillLength += BLOCK_HOURS;
    }
    if (this.minutesCount) {
      label.push(
        this.transloco.translate('problems.durationMinutesLabel', {
          minutes: `${this.minutesCount}`.padStart(2, '0'),
        })
      );
      pillLength += BLOCK_HOURS;
    }

    this.compact = this.mustShowCompact(pillLength);

    this.label = label.join(' ');
  }

  private mustShowCompact = (pillLength: number): boolean => {
    const mustRecalculate =
      this.startDate &&
      this.endDate &&
      (this.startDate < this.minDate || this.endDate > this.maxDate);

    let minutes = this.durationMinutes ?? 0;

    if (mustRecalculate) {
      const start =
        this.startDate && this.startDate > this.minDate
          ? this.startDate
          : this.minDate;
      const end =
        this.endDate && this.endDate < this.maxDate
          ? this.endDate
          : this.maxDate;

      // Difference in minutes
      minutes = (end.getTime() - start.getTime()) / (60 * 1000);
    }

    if (minutes < Math.max(pillLength, MIN_EXTENDED_VIEW_HOURS) * 60) {
      return true;
    }

    return false;
  };
}
