<mat-checkbox
  [color]="color"
  [checked]="checked"
  [indeterminate]="isIndeterminate"
  [disabled]="disabled"
  [labelPosition]="labelPosition"
  [id]="id"
  (change)="onChanged($event)"
  (indeterminateChange)="onIntermediateChange($event)"
  [value]="value"
  (click)="onClick($event)"
>
  <span *ngIf="label">
    {{ label }}
  </span>
</mat-checkbox>
