/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Request object for creating and updating the schedule context of a procedure.
 */
export interface ProcedureScheduleContextRequestResource { 
    /**
     * Additional notes regarding the scheduling of the prescribed procedure, if applicable.
     */
    additionalNotes?: string;
    /**
     * Unique identifier of the detected problem related to the prescribed procedure, if applicable.
     */
    detectedProblemId?: string;
    /**
     * Timestamp indicating when the procedure was prescribed.
     */
    prescribedAt: string;
    /**
     * Unique identifier of the prescribed procedure catalog entry.
     */
    procedureCatalogEntryId: string;
    /**
     * Timestamp indicating when the procedure is scheduled to be executed.
     */
    scheduledAt: string;
}

