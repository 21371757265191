<mat-form-field
  [class]="fieldClass"
  [class.fill-width]="fillParentWidth"
  [class.auto-width]="autoWidth"
  [class.no-hint]="hintLabel === undefined && errorMessages === undefined"
  [class.warn]="errorMessages !== undefined"
>
  <mat-hint
    *ngIf="hintLabel !== undefined && !errorMessages"
    [class.disabled]="isDisabled"
    >{{ hintLabel }}</mat-hint
  >

  <mat-hint class="warn" *ngIf="errorMessages !== undefined">
    <natea-field-error [errorMessages]="errorMessages"></natea-field-error>
  </mat-hint>

  <mat-label [class.disabled]="isDisabled" [class.filled]="isFilled">{{
    label
  }}</mat-label>

  <ng-content></ng-content>
</mat-form-field>
