import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { ApiError } from '../../../shared/entities/errors';
import { ClinicalNote } from '../interfaces/clinical-note';
import { ClinicalNoteFilter } from '../interfaces/clinical-note-filter';
import { DateRangeFilter } from '../interfaces/date-filter';
import { NewNoteFormValues } from '../interfaces/new-note-form';

export enum ClinicalNoteActions {
  LoadClinicalNoteFilter = '[ClinicalNotes] Load clinical Notes Filter',
  LoadAllClinicalNotes = '[ClinicalNotes] Load All Clinical Notes',
  LoadAllClinicalNotesSuccess = '[ClinicalNotes] Load All Clinical Notes Success',
  LoadAllClinicalNotesFailure = '[ClinicalNotes] Load All Clinical Notes Failure',
  LoadClinicalNote = '[ClinicalNotes] Load Clinical Notes',
  LoadClinicalNoteSuccess = '[ClinicalNotes] Load Clinical Notes Success',
  LoadClinicalNoteFailure = '[ClinicalNotes] Load Clinical Notes Failure',
  LoadClinicalDateRange = '[ClinicalNotes] Load Clinical Date Range',
  SortClinicalNotes = '[ClinicalNotes] Sort',
  CreateClinicalNote = '[ClinicalNotes] Create clinical note',
  CreateClinicalNoteFailure = '[ClinicalNotes] Create clinical note Failure',
  CreateClinicalNoteSuccess = '[ClinicalNotes] Create clinical note Success',
  DeleteClinicalNote = '[ClinicalNotess] Delete  ClinicalNotes',
  DeleteClinicalNoteSuccess = '[ClinicalNotess] Delete  ClinicalNotes Success',
  DeleteClinicalNoteFailure = '[ClinicalNotess] Delete  ClinicalNotes Failure',
  ShowCreateClinicalNoteModal = '[ClinicalNotes] Show Create Modal',
  ShowEditClinicalNoteModal = '[ClinicalNotes] Show Edit Modal',
  ShowViewClinicalNoteModal = '[ClinicalNotes] Show View ClinicalNote Modal',
  HideClinicalNoteModal = '[ClinicalNotes] Hide ClinicalNote Modal',
  UpdateClinicalNote = '[ClinicalNotes] Update ClinicalNote',
  UpdateClinicalNoteSuccess = '[ClinicalNotes] Update ClinicalNote Success',
  UpdateClinicalNoteFailure = '[ClinicalNotes] Update ClinicalNote Failure',
  ClinicalNotePressedSave = '[ClinicalNotes] clinicalNote Pressed Save',
  EvaluateClinicalNote = '[ClinicalNotes] Evaluate ClinicalNote',
  ShowModalPreviewClinicalNote = '[ClinicalNotes] Show Modal Preview ClinicalNote',
  ShowLinkedNote = '[ClinicalNotes] Show Linked Note',
  HideLinkedNote = '[ClinicalNotes] Hide Linked Note',
  ShowHeaderModalInfo = '[ClinicalNotes] Show Header Modal Info',
  HideHeaderModalInfo = '[ClinicalNotes] Hide Header Modal Info',
  ShowSecondHeaderLine = '[ClinicalNotes] Show Second Header Line',
}

/******************* Notes filter /*******************/

export const loadFilterNote = createAction(
  ClinicalNoteActions.LoadClinicalNoteFilter,
  props<{ filter: ClinicalNoteFilter }>()
);

/******************* Notes loading /*******************/

export const loadAllClinicalNotes = createAction(
  ClinicalNoteActions.LoadAllClinicalNotes,
  props<{ patientId: string; encounterId: string }>()
);

export const loadAllClinicalNotesSuccess = createAction(
  ClinicalNoteActions.LoadAllClinicalNotesSuccess,
  props<{ notes: ClinicalNote[]; patientId: string; encounterId: string }>()
);

export const loadAllClinicalNotesFailure = createAction(
  ClinicalNoteActions.LoadAllClinicalNotesFailure,
  props<{ error: ApiError; patientId: string; encounterId: string }>()
);

export const loadClinicalNote = createAction(
  ClinicalNoteActions.LoadClinicalNote,
  props<{ clinicalNoteId: string }>()
);

export const loadClinicalNoteSuccess = createAction(
  ClinicalNoteActions.LoadClinicalNoteSuccess,
  props<{ notes: ClinicalNote }>()
);

export const loadClinicalNoteFailure = createAction(
  ClinicalNoteActions.LoadClinicalNoteFailure,
  props<{ error: ApiError }>()
);

/******************* Date range /*******************/

export const loadClinicalDateRange = createAction(
  ClinicalNoteActions.LoadClinicalDateRange,
  props<{ dateFilter: DateRangeFilter }>()
);

/******************* Sort /*******************/
export const sortClinicalNotes = createAction(
  ClinicalNoteActions.SortClinicalNotes,
  props<{ sort?: Sort }>()
);

/******************* Create /*******************/

export const createClinicalNote = createAction(
  ClinicalNoteActions.CreateClinicalNote,
  props<{ note: NewNoteFormValues }>()
);

export const createClinicalNoteSuccess = createAction(
  ClinicalNoteActions.CreateClinicalNoteSuccess,
  props<{ note: ClinicalNote; patientId: string; encounterId: string }>()
);

export const createClinicalNoteFailure = createAction(
  ClinicalNoteActions.CreateClinicalNoteFailure,
  props<{ error: ApiError }>()
);

/******************* Update /*******************/

export const updateClinicalNote = createAction(
  ClinicalNoteActions.UpdateClinicalNote,
  props<{ note: ClinicalNote }>()
);

export const updateClinicalNoteSuccess = createAction(
  ClinicalNoteActions.UpdateClinicalNoteSuccess,
  props<{ note: ClinicalNote; patientId: string; encounterId: string }>()
);

export const updateClinicalNoteFailure = createAction(
  ClinicalNoteActions.UpdateClinicalNoteFailure,
  props<{ error: ApiError }>()
);

/******************* Delete /*******************/

export const deleteClinicalNote = createAction(
  ClinicalNoteActions.DeleteClinicalNote,
  props<{ clinicalNoteId: string }>()
);

export const deleteClinicalNoteSuccess = createAction(
  ClinicalNoteActions.DeleteClinicalNoteSuccess,
  props<{ clinicalNoteId: string; patientId: string; encounterId: string }>()
);

export const deleteClinicalNoteFailure = createAction(
  ClinicalNoteActions.DeleteClinicalNoteFailure,
  props<{ error: ApiError }>()
);

/******************* Modal /********************/

export const showCreateClinicalNoteModal = createAction(
  ClinicalNoteActions.ShowCreateClinicalNoteModal
);

export const showEditClinicalNoteModal = createAction(
  ClinicalNoteActions.ShowEditClinicalNoteModal,
  props<{ clinicalNoteToEdit: ClinicalNote }>()
);

export const showViewClinicalNoteModal = createAction(
  ClinicalNoteActions.ShowViewClinicalNoteModal,
  props<{ clinicalNoteToView: ClinicalNote }>()
);

export const hideClinicalNoteModal = createAction(
  ClinicalNoteActions.HideClinicalNoteModal
);

/******************* Save /********************/

export const clinicalNotePressedSave = createAction(
  ClinicalNoteActions.ClinicalNotePressedSave
);

/******************* Preview note  /********************/

export const showClinicalNotePreviewModal = createAction(
  ClinicalNoteActions.ShowModalPreviewClinicalNote,
  props<{ noteToShow: string }>()
);

/******************* Linked notes  /********************/

export const showLinkedNote = createAction(
  ClinicalNoteActions.ShowLinkedNote,
  props<{ note: ClinicalNote }>()
);

export const hideLinkedNote = createAction(ClinicalNoteActions.HideLinkedNote);

/******************* Validation  /********************/

export const evaluateClinicalNote = createAction(
  ClinicalNoteActions.EvaluateClinicalNote,
  props<{ clinicalNoteToEvaluate: NewNoteFormValues }>()
);

/******************* Header modal Info  /********************/

export const showHeaderModalInfo = createAction(
  ClinicalNoteActions.ShowHeaderModalInfo
);

export const hideHeaderModalInfo = createAction(
  ClinicalNoteActions.HideHeaderModalInfo
);

export const clinicalNoteShownExtHeaderLine = createAction(
  ClinicalNoteActions.ShowSecondHeaderLine,
  props<{ show: boolean }>()
);
