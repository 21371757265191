import { Injectable } from '@angular/core';
import { Observable, map, throwError } from 'rxjs';
import {
  DetectedProblemApiService,
  DetectedProblemResource,
  EncounterApiService,
  ProblemCatalogApiService,
  ProblemCatalogEntryResource,
  SearchResponseListDetectedProblemResource,
} from '../../../core/generated/api';
import { ProblemFormData } from '../interfaces/new-problem-form';
import { PatientProblem } from '../interfaces/patient-problem';
import { ProblemDataResult } from './problem.models';
import { ProblemsAdapters } from './problems.adapters';

@Injectable({
  providedIn: 'root',
})
export class ProblemsWebAPI {
  constructor(
    private encounterService: EncounterApiService,
    private problemDetectionsService: DetectedProblemApiService,
    private problemsCatalogService: ProblemCatalogApiService
  ) {}

  public getProblemsForPatient(
    patientId: string
  ): Observable<PatientProblem[]> {
    return this.problemDetectionsService
      .searchPatientDetectedProblems(patientId)
      .pipe(
        map(
          (problems: SearchResponseListDetectedProblemResource) =>
            problems.payload?.map((problem: DetectedProblemResource) =>
              ProblemsAdapters.patientProblemBeToFe(patientId, problem)
            ) ?? []
        )
      );
  }

  public createProblemForPatient(
    patientId: string,
    encounterId: string,
    problem: ProblemFormData
  ): Observable<PatientProblem> {
    return this.problemDetectionsService
      .createDetectedProblemToPatientEncounter(
        patientId,
        encounterId,
        ProblemsAdapters.patientProblemFormToCreate(problem)
      )
      .pipe(
        map((result) => {
          if (!result.payload) {
            throw Error('Problem not created');
          }
          return ProblemsAdapters.patientProblemBeToFe(
            patientId,
            result.payload
          );
        })
      );
  }

  public updateProblemForPatient(
    patientId: string,
    problem: ProblemFormData,
    problemId: string
  ): Observable<PatientProblem> {
    if (!problemId) {
      return throwError(() => new Error('Problem id is mandatory')); // TODO correct error type
    }
    return this.problemDetectionsService
      .patchDetectedProblem(
        problemId,
        ProblemsAdapters.patientProblemFeToUpdate(problem)
      )
      .pipe(
        map((result) => {
          if (!result.payload) {
            throw new Error();
          }
          return ProblemsAdapters.patientProblemBeToFe(
            patientId,
            result.payload
          );
        })
      );
  }

  public deleteProblemForPatient(
    patientId: string,
    problemId: string
  ): Observable<string> {
    return this.problemDetectionsService
      .deleteDetectedProblem(problemId)
      .pipe(map(() => problemId));
  }

  public getProblemData(
    description?: string,
    page = 1
  ): Observable<ProblemDataResult> {
    return this.problemsCatalogService
      .getProblemCatalog(description, undefined, page)
      .pipe(
        map((result) => ({
          data:
            result.payload?.map((problem: ProblemCatalogEntryResource) =>
              ProblemsAdapters.problemDataBeToFe(problem)
            ) ?? [],
          pagination: !result.pagination // TODO correct
            ? undefined
            : {
                itemPerPage: result.pagination.itemsPerPage,
                pageNumber: result.pagination.pageNumber,
                totalItemNumber: result.pagination.totalItemNumber,
                totalPageNumber: result.pagination.totalPageNumber,
              },
        }))
      );
  }
}
