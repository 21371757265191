import { ProfessionalCategory } from '../../shared/entities/professional-category';
import { mockEncounterDay } from '../../shared/utils/mock-utils'; // RLE
import { randomBool } from '../../shared/utils/utils';
import { NoteResource } from '../generated/api';
import { problemsNames } from './get-detected-problems-mock';
import { randomChoice, randomDateMinutesAfter } from './utils';

export const createClinicalNotesMock = (
  patientId: string,
  encounterId: string,
  length: number
): NoteResource[] => {
  const statuses: string[] = ['approved', 'Approving', 'Rejected'];

  const types: string[] = ['I', 'M'];
  const authors = [
    { id: '1', firstName: 'Mario', lastName: 'Rossi' },
    { id: '2', firstName: 'Luca', lastName: 'Bianchi' },
    { id: '3', firstName: 'Anna', lastName: 'Verdi' },
  ];

  const problemsList = problemsNames;

  const textList: string[] = [
    `parametri vitali stabili, attualmente SpO2 98%, FC 149 bpm, PA in range
Condizioni cliniche stabili
dinamica respiratoria lievemente polipnoica alle manipolazioni
ingresso aereo simmetrico e bilaterale
addome trattabile e non dolente, peristlsi valida, cicatrice laparotomica in ordine
.Piccola tumefazione residua a livello dx, valutata già dai chirurghi
peso odierno in aumento +20gr  da ieri, +120g/settimana
NE a 81 ml/kg/die in GC tollerata  + NPT a 95ml/kg/die---> aumenta NE a 96 ml/kg/die
Diuresi parziale odierna 5.46 cc/kg/h,
alvo aperto a feci segnalate ipocoliche nella mattina
Terapia:
- caffeina
- morfina a 0.03mg/kg x3
- idroclorotiazide dal 2/12
- lattoferrina e FOS
- folina (terminata EPO)
- nistatina
`,
    // 'Quam sapien dui libero parola fringilla vel magnis aliquam. Molestie nibh duis purus damattis venenatis tiburones demo label 1234',
    // 'Dignissim aenean torquent etiam parola a potenti conubia dolor risus. Placeat fusce netus id dui aenean numero 1 prova 1234',
    // 'Maecenas rutrum letius vestibulum dolor vitae parola imperdiet sociosqu phasellus proin eleifend inceptores demo 1234',
    // 'Ligula facilisi dictumst sem inceptos nullam quisque odio. Parola nisi porttitor dictumst tincidunt lectus la prova numero 4 demo 1234',
    // 'Quam sapien dui libero porttitor fringilla vel parola aliquam. Molestie nibh duis purus mattis venenatis tia prova prova prova prova demo 1234',
    // 'Dignissim aenean torquent etiam litora a potenti conubia dolor risus. Placerat fusce netus id dui parola numero 1111111111 demo 1234',
    // 'Maecenas parola letius vestibulum dolor vitae lectus imperdiet sociosqu phasellus proin eleifend inceptores purus mattis venenatis tia field prova prova prova prova demo 1234',
    // 'Ligula parola dictumst sem inceptos nullam quique odio. Netus nisi porttitor dictumst tincidunt lectus lacus prova prova prova prova demo 1234',
    // 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nunc eget aliquam aliquam, nisl nunc aliquet nunc, vitae aliq uam nunc nisl nec nisl. Nulla',
  ];

  const textList2: string[] = [
    `parametri vitali stabili, attualmente SpO2 98%, FC 149 bpm, PA in range
     Condizioni cliniche stabili
dinamica respiratoria lievemente polipnoica alle manipolazioni
ingresso aereo simmetrico e bilaterale
addome trattabile e non dolente, peristlsi valida, cicatrice laparotomica in ordine
Piccola tumefazione residua a livello dx, valutata già dai chirurghi
peso odierno in aumento +20gr  da ieri, +120g/settimana
NE a 81 ml/kg/die in GC tollerata  + NPT a 95ml/kg/die---> aumenta NE a 96 ml/kg/die
Diuresi parziale odierna 5.46 cc/kg/h,
alvo aperto a feci segnalate ipocoliche nella mattina
`,

    `Paziente di 2 giorni  con febbre da due giorni, genitori riportano tosse secca e naso che cola. Esame obiettivo:
febbre a 38,5°C, faringe arrossata, linfonodi cervicali ingrossati.
Sospetto di infezione virale delle vie respiratorie superiori,
consigliati riposo e idratazione, seguire con paracetamolo per il controllo della febbre `,
    `Neonato nato il 21/02/2023, peso alla nascita di 3,5 kg, Apgar score 9/9. Esame obiettivo: tono muscolare buono, buona suzione al seno,
ascolto cardiaco e respiratorio regolari. Madre riporta allattamento al seno senza difficoltà. Nessun segno di complicanze neonatali evidenti al momento della visita. `,
    `Certamente, ecco un altro esempio di due righe di note pediatriche per un neonato:

Neonato nato il 21/02/2023, peso alla nascita di 3,2 kg, Apgar score 8/9. Esame obiettivo: leggera itterizia cutanea, ma sclere bianche,
suzione vigorosa, frequenza cardiaca e respiratoria nella norma.
Madre segnala allattamento al seno regolare. Nessun segno di distress respiratorio o altre complicanze evidenti al momento della visita.`,
  ];

  const notes: NoteResource[] = [];

  console.log('problemsList ', problemsList);

  for (let j = 1; j <= 9; j++) {
    // RLE
    for (let i = 1; i <= length; i++) {
      const observationDate: Date = mockEncounterDay(j);
      observationDate.setHours(i * 4, 60 - i * 6);
      notes.push({
        id: i === 1 ? `REF${1}` : i.toString(),
        createdAt: randomDateMinutesAfter(0, 5, observationDate).toISOString(),
        status: i === 0 ? 'Approving' : randomChoice(statuses),
        type: randomChoice(types),
        assessedAt: randomDateMinutesAfter(
          15,
          120,
          observationDate
        ).toISOString(),

        observedAt: observationDate.toISOString(),
        additionalNotes: textList2[i % textList2.length],
        createdBy: authors[i % authors.length],
        encounterId: encounterId || `E${i}`,

        permission: {
          canUpdate: Math.random() < 0.5,
          canDelete: Math.random() < 0.5,
          canAssess: Math.random() < 0.5,
        },

        clinicalNoteRef: randomBool()
          ? {
              linkedTo: `REF${1}`,
              createdAt: new Date('2023-10-10').toISOString(),
            }
          : undefined,

        version: randomChoice([1, 2]),
        rejectionReason:
          'Spiegazione della problematica non sufficientemente chiara',
        detectedProblem: {
          id: '1',
          createdAt: new Date('2024-2-10').toISOString(),
          createdBy: randomChoice(authors),
          patientId: patientId,
          permissions: {
            canUpdate: Math.random() < 0.5,
            canDelete: Math.random() < 0.5,
          },
          problemCatalogEntry: {
            id: problemsList[i - 1]?.id ?? problemsList[0].id,
            name: problemsList[i - 1]?.name ?? problemsList[0].name,
            description: 'Descrizione problema 1',
            problemClass: ProfessionalCategory.Medical,
            isEnabled: true,
          },
          startDate: new Date('2024-1-1').toISOString(),
        },
      });
    }
  }

  const today = new Date();
  today.setTime(0);
  today.setMinutes(0);
  // notes[0].startDate = today.toISOString();

  return notes;
};
