<mat-slide-toggle
  [disableRipple]="disableRipple"
  [color]="'primary'"
  [checked]="checked"
  [disabled]="disabled"
  [labelPosition]="labelPosition"
  [id]="id"
  [name]="name"
  [required]="required"
  [aria-describedby]="ariaDescribedby"
  [aria-label]="ariaLabel || null"
  [aria-labelledby]="ariaLabelledby || null"
  (change)="onChanged($event)"
  (toggleChange)="onToggleChange()"
>
  <span
    *ngIf="label"
    [class.after]="labelPosition === 'after'"
    [class.before]="labelPosition === 'before'"
    [class.label-disabled]="disabled"
  >
    {{ label }}
  </span>
</mat-slide-toggle>
