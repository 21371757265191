import { Sort } from '@angular/material/sort';
import { createSelector } from '@ngrx/store';
import { OptionsItem } from 'natea-components';
import {
  MultiSectionTimelineInstance,
  MultisectionTimelineOccurrence,
} from '../../../../shared/components/multi-section-timeline/models/multi-section-timeline-instance';
import { DomainItem } from '../../../../shared/entities/domain-item';
import { optionItemFromDomainItem } from '../../../../shared/utils/utils';
import { selectShownEncounterPharmacologicalTherapies } from '../../../patients/store/clinical-data/therapies/patients-data.pharmacological-therapies.selectors';
import { PatientAndEncounterIds } from '../../../patients/store/patients.reducers';
import { selectOngoingEncounterAndPatientIds } from '../../../patients/store/patients.selectors';
import { selectPharmacologicalTherapiesUiState } from '../../../patients/store/selected-patient/selected-patient.selectors';
import { SelectedTherapyOccurrenceIds } from '../../common/entities/selected-therapy';
import { therapyComparator } from '../../common/entities/therapies-comparator';
import { TherapiesTableItem } from '../../common/entities/therapies-table-item';
import {
  TherapyOccurrence,
  TherapyOccurrenceSearchResultItem,
} from '../../common/entities/therapy-occurrence';
import {
  PharmacologicalTherapy,
  PharmacologicalTherapyOccurrence,
} from '../entities/pharmacological-therapy';
import { PharmacologicalTherapyCancelSuspend } from '../entities/pharmacological-therapy-cancel-suspend';
import {
  PharmacologicalTherapyMode,
  PharmacologicalTherapyType,
} from '../entities/pharmacological-therapy-type';
import {
  mapPharmacologicalTherapyOccurrenceToTableItem,
  mapPharmacologicalTherapyPrescriptionToTimelineOccurrence,
  optionItemFromPharmacologicalTherapyMode,
  optionItemFromPharmacologicalTherapyType,
} from '../utils/pharmacological-therapies-utils';
import {
  PharmacologicalTherapiesCatalogs,
  PharmacologicalTherapiesUiState,
} from './pharmacological-therapies.reducer';

export const selectIsLoadingPharmacologicalTherapies = createSelector(
  selectPharmacologicalTherapiesUiState,
  (state: PharmacologicalTherapiesUiState): boolean =>
    state.isLoadingData || state.isDownloadingCatalogs
);

export const selectPharmacologicalTherapiesSort = createSelector(
  selectPharmacologicalTherapiesUiState,
  (state: PharmacologicalTherapiesUiState): Sort | undefined => state.sort
);

export const selectShowCreateNewPharmacologicalTherapyModal = createSelector(
  selectPharmacologicalTherapiesUiState,
  (state: PharmacologicalTherapiesUiState): boolean => state.showCreateNewModal
);

export const selectIsCreatingNewPharmacologicalTherapy = createSelector(
  selectPharmacologicalTherapiesUiState,
  (state: PharmacologicalTherapiesUiState): boolean => state.isCreatingNew
);

export const selectIsUpdatingPharmacologicalTherapy = createSelector(
  selectPharmacologicalTherapiesUiState,
  (state: PharmacologicalTherapiesUiState): boolean => state.isUpdating
);

export const selectSelectedPharmacologicalTherapyOccurrenceIds = createSelector(
  selectPharmacologicalTherapiesUiState,
  (
    state: PharmacologicalTherapiesUiState
  ): SelectedTherapyOccurrenceIds | undefined => {
    return state.selectedTherapyOccurrence;
  }
);

export const selectSelectedPharmacologicalTherapyOccurrenceIdsAndSource =
  createSelector(
    selectPharmacologicalTherapiesUiState,
    (
      state: PharmacologicalTherapiesUiState
    ): {
      occurrence?: SelectedTherapyOccurrenceIds;
      fromTable?: boolean;
      changingTab?: boolean;
    } => {
      return {
        occurrence: state.selectedTherapyOccurrence,
        fromTable: state.selectedTherapyOccurrenceInTable,
        changingTab: state.changingTab,
      };
    }
  );

export const selectSelectedPharmacologicalTherapyPrescription = createSelector(
  selectSelectedPharmacologicalTherapyOccurrenceIds,
  selectShownEncounterPharmacologicalTherapies,
  (
    selectedOccurrenceIds: SelectedTherapyOccurrenceIds | undefined,
    therapies: PharmacologicalTherapy[] | undefined
  ): PharmacologicalTherapy | undefined =>
    therapies?.find(
      (therapy) => therapy.id === selectedOccurrenceIds?.therapyId
    )
);

export const selectSelectedPharmacologicalTherapyOccurrence = createSelector(
  selectSelectedPharmacologicalTherapyOccurrenceIds,
  selectSelectedPharmacologicalTherapyPrescription,
  (
    ids?: SelectedTherapyOccurrenceIds,
    prescription?: PharmacologicalTherapy
  ): PharmacologicalTherapyOccurrence | undefined =>
    prescription?.occurrences.find(
      (occurrence) => occurrence.id === ids?.occurrenceId
    )
);

export const selectPharmacologicalTherapyCandidateForDeletion = createSelector(
  selectPharmacologicalTherapiesUiState,
  selectShownEncounterPharmacologicalTherapies,
  (
    state: PharmacologicalTherapiesUiState,
    therapies: PharmacologicalTherapy[] | undefined
  ): PharmacologicalTherapy | undefined =>
    therapies?.find((therapy) => therapy.id === state.candidateForDeletionId)
);

// Shown encounter therapies as timeline items
export const selectPharmacologicalTherapiesAsTimelineItems = createSelector(
  selectShownEncounterPharmacologicalTherapies,
  (
    therapies: PharmacologicalTherapy[] | undefined
  ): MultiSectionTimelineInstance<TherapyOccurrence>[] | undefined => {
    if (!therapies) return undefined;

    const medicinesMap = new Map<string, PharmacologicalTherapyType>();
    const medicineTherapiesMap = new Map<string, PharmacologicalTherapy[]>();

    therapies?.forEach((therapy) => {
      const medicine = therapy.therapyType;
      if (!medicinesMap.has(medicine.id)) {
        medicinesMap.set(medicine.id, medicine);
        medicineTherapiesMap.set(medicine.id, [therapy]);
      } else {
        medicineTherapiesMap.get(medicine.id)?.push(therapy);
      }
    });

    return Array.from(medicinesMap.keys()).map((medicineId) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const medicine = medicinesMap.get(medicineId)!;
      return {
        id: medicine.id,
        label: medicine.description,
        occurrences:
          medicineTherapiesMap
            .get(medicineId)
            ?.map(
              (
                therapy: PharmacologicalTherapy
              ): MultisectionTimelineOccurrence<TherapyOccurrence<unknown>> =>
                mapPharmacologicalTherapyPrescriptionToTimelineOccurrence(
                  therapy
                )
            ) ?? [],
      };
    });
  }
);

export const selectPharmacologicalTherapiesAsTableItems = createSelector(
  selectShownEncounterPharmacologicalTherapies,
  selectPharmacologicalTherapiesSort,
  (
    therapies?: PharmacologicalTherapy[],
    sort?: Sort
  ): TherapiesTableItem[] | undefined => {
    const comparator = therapyComparator(sort);
    const items = therapies?.reduce((acc, therapy) => {
      return acc.concat(
        therapy.occurrences.map((occurrence) =>
          mapPharmacologicalTherapyOccurrenceToTableItem(occurrence, therapy)
        )
      );
    }, [] as TherapiesTableItem[]);
    return items?.sort(comparator);
  }
);

export const selectPharmacologicalTherapiesAsSearchResultItems = createSelector(
  selectPharmacologicalTherapiesAsTableItems,
  (
    items: TherapiesTableItem[] | undefined
  ): TherapyOccurrenceSearchResultItem[] | undefined => {
    return items?.map((item) => ({
      id: item.id,
      prescriptionId: item.therapyId,
      ingredient: item.name,
      author: item.createdBy,
      prescriptionDate: item.prescriptionDate,
      state: item.state,
      durationType: item.durationType,
      programmingDate: item.programmingDate,
      executionDate: item.executionDate,
    }));
  }
);

export const selectPharmacologicalTherapiesSelectedTabindex = createSelector(
  selectPharmacologicalTherapiesUiState,
  (state: PharmacologicalTherapiesUiState): number => state.tabIndex
);

export const selectPharmacologicalTherapiesCatalogs = createSelector(
  selectPharmacologicalTherapiesUiState,
  (
    state: PharmacologicalTherapiesUiState
  ): PharmacologicalTherapiesCatalogs | undefined => state.catalogs
);

export const selectPharmacologicalTherapyTypesAsOptionItems = createSelector(
  selectPharmacologicalTherapiesCatalogs,
  (
    catalogs?: PharmacologicalTherapiesCatalogs
  ): OptionsItem<PharmacologicalTherapyType>[] | undefined =>
    catalogs?.therapyTypes.map(optionItemFromPharmacologicalTherapyType)
);

export const selectPharmacologicalTherapyModesAsOptionItems = createSelector(
  selectPharmacologicalTherapiesCatalogs,
  (
    catalogs?: PharmacologicalTherapiesCatalogs
  ): OptionsItem<PharmacologicalTherapyMode>[] | undefined => {
    return catalogs?.modes.map(optionItemFromPharmacologicalTherapyMode);
  }
);

export const selectPharmacologicalTherapyRoutesAsOptionItems = createSelector(
  selectPharmacologicalTherapiesCatalogs,
  (
    catalogs?: PharmacologicalTherapiesCatalogs
  ): OptionsItem<DomainItem>[] | undefined =>
    catalogs?.routes.map(optionItemFromDomainItem)
);

export const selectPharmacologicalTherapyDiluentsAsOptionItems = createSelector(
  selectPharmacologicalTherapiesCatalogs,
  (
    catalogs?: PharmacologicalTherapiesCatalogs
  ): OptionsItem<DomainItem>[] | undefined =>
    catalogs?.diluents.map(optionItemFromDomainItem)
);

export const selectShowPharmacologicalTherapyToDelete = createSelector(
  selectPharmacologicalTherapiesUiState,
  selectShownEncounterPharmacologicalTherapies,
  (
    state: PharmacologicalTherapiesUiState,
    therapies: PharmacologicalTherapy[] | undefined
  ): string | undefined =>
    therapies?.find((therapy) => therapy.id === state.deleteTherapyOccurrenceId)
      ?.name
);

export const selectPharmacologicalDeleteTherapyAndEncounter = createSelector(
  selectPharmacologicalTherapiesUiState,
  selectOngoingEncounterAndPatientIds,
  (
    state: PharmacologicalTherapiesUiState,
    encounter: PatientAndEncounterIds | undefined
  ): {
    therapyId: string | undefined;
    patientId: string | undefined;
    encounterId: string | undefined;
  } => {
    return {
      therapyId: state.deleteTherapyOccurrenceId,
      patientId: encounter?.patientId,
      encounterId: encounter?.encounterId,
    };
  }
);

export const selectShowConfirmCancelPharmacologicalTherapyModal =
  createSelector(
    selectPharmacologicalTherapiesUiState,
    (
      state: PharmacologicalTherapiesUiState
    ): PharmacologicalTherapyCancelSuspend | undefined =>
      state.pharmacologicalTherapyCancel
  );

export const selectPharmacologicalCancelTherapyAndEncounter = createSelector(
  selectShowConfirmCancelPharmacologicalTherapyModal,
  selectOngoingEncounterAndPatientIds,
  (
    therapy: PharmacologicalTherapyCancelSuspend | undefined,
    encounter: PatientAndEncounterIds | undefined
  ): {
    therapy: PharmacologicalTherapyCancelSuspend | undefined;
    encounter: PatientAndEncounterIds | undefined;
  } => {
    return { therapy, encounter };
  }
);

export const selectPharmacologicalTherapyDeleteIsButtonDisabled =
  createSelector(
    selectPharmacologicalTherapiesUiState,
    (state: PharmacologicalTherapiesUiState): boolean => state.isButtonDisabled
  );

export const selectGetTherapyToDeleteId = createSelector(
  selectPharmacologicalTherapiesUiState,
  (state: PharmacologicalTherapiesUiState): string | undefined =>
    state.deleteTherapyOccurrenceId
);
export const selectPharmacologicalTherapyCandidateForSuspension =
  createSelector(
    selectPharmacologicalTherapiesUiState,
    selectShownEncounterPharmacologicalTherapies,
    (
      state: PharmacologicalTherapiesUiState,
      therapies?: PharmacologicalTherapy[]
    ): PharmacologicalTherapy | undefined => {
      return state.candidateForSuspensionId
        ? therapies?.find(
            (therapy) => therapy.id === state.candidateForSuspensionId
          )
        : undefined;
    }
  );

export const selectIsPharmacologicalTherapySuspensionRunning = createSelector(
  selectPharmacologicalTherapiesUiState,
  (state: PharmacologicalTherapiesUiState): boolean => state.isSuspensionRunning
);
