import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { ConfigService } from '../../../../core/config/config.service';
import { AppRoutes } from '../../../../shared/routes/routes';
import { DepartmentData } from '../../../departments/store/departments.reducers';
import { selectCurrentDepartment } from '../../../departments/store/departments.selectors';
import { ProblemType } from '../../../problems/interfaces/problem-type';
import { loadAllProblemData } from '../../../problems/store/problems.actions';
import { selectAllProblemTypes } from '../../../problems/store/problems.selectors';
import { loadAllPatients } from '../../store/patients.actions';
import { selectPatientsDataLoaded } from '../../store/patients.selectors';

@Component({
  selector: 'natea-cc-patients-loader',
  templateUrl: './patients-loader.component.html',
  styleUrls: ['./patients-loader.component.scss'],
})
export class PatientsLoaderComponent implements OnInit, OnDestroy {
  private patientsLoaded$: Observable<boolean> = this.store.select(
    selectPatientsDataLoaded
  );
  private selectedDepartment$: Observable<DepartmentData | undefined> =
    this.store.select(selectCurrentDepartment);
  private problemTypes$: Observable<ProblemType[] | undefined> =
    this.store.select(selectAllProblemTypes);
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store,
    private router: Router,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.selectedDepartment$.subscribe((department) => {
        if (department) {
          this.store.dispatch(loadAllPatients({ departmentId: department.id }));
        }
      })
    );
    this.subscriptions.push(
      combineLatest([
        this.patientsLoaded$,
        this.selectedDepartment$,
        this.problemTypes$,
      ]).subscribe(
        ([loaded, department, problemTypes]: [
          boolean,
          DepartmentData | undefined,
          ProblemType[] | undefined
        ]) => {
          if (problemTypes === undefined) {
            this.store.dispatch(loadAllProblemData({}));
          }
          if (loaded && department && problemTypes !== undefined) {
            if (this.config.startupPage) {
              this.router.navigateByUrl(this.config.startupPage, {
                replaceUrl: true,
              });
            } else {
              this.router.navigateByUrl(AppRoutes.patientsList(department.id));
            }
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
