import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { Department } from '../../../shared/entities/department';
import { loadDepartments, loadDepartmentsSuccess } from './departments.actions';

/**************************** DATA MODELS *****************************/

export interface DepartmentData extends Department {
  percentageOccupied?: number;
  colorOccupied?: string;
}

export interface DepartmentsState {
  departments?: DepartmentData[];
  showSearchPatientModal: boolean;
  departmentsLoaded: boolean;
}

// export const departmentDataAdapter: EntityAdapter<DepartmentsData> =
//   createEntityAdapter<DepartmentsData>();

export const initialDepartmentsState: DepartmentsState = {
  departments: undefined,
  showSearchPatientModal: false,
  departmentsLoaded: false,
};

/**************************** DATA MODELS *****************************/

export const departmentsReducer: ActionReducer<DepartmentsState, Action> =
  createReducer(
    initialDepartmentsState,
    on(
      loadDepartments,
      (state: DepartmentsState): DepartmentsState => ({
        ...state,
        departments: undefined,
        departmentsLoaded: false,
      })
    ),
    on(
      loadDepartmentsSuccess,
      (state: DepartmentsState, { departments }): DepartmentsState => {
        return {
          ...state,
          departments,
          departmentsLoaded: true,
        };
      }
    )
  );
