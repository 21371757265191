<ng-container *transloco="let t; read: 'common.buttons'">
  <mat-form-field
    [class.no-hint]="hintLabel === undefined"
    [class.fill-width]="fillParentWidth"
    [class.auto-width]="autoWidth"
    [class.no-hint]="hintLabel === undefined && errorMessages === undefined"
    [class.warn]="errorMessages !== undefined"
  >
    <mat-hint class="warn" *ngIf="errorMessages !== undefined">
      <natea-field-error [errorMessages]="errorMessages"></natea-field-error>
    </mat-hint>

    <mat-label
      [class.disabled]="isDisabled"
      [class.input-filled]="isAddClass"
      *ngIf="label"
      >{{ label }}</mat-label
    >
    <input
      #dateInput
      matInput
      [ngxMatDatetimePicker]="picker"
      [placeholder]="placeholder"
      [min]="minDate"
      [max]="maxDate"
      [disabled]="isDisabled"
      [value]="inputValue"
      [required]="isRequired"
      (dateChange)="onDateChanged($event)"
      (dateInput)="onDateInput($event)"
      (outFocus)="onOutFocus($event)"
    />
    <ngx-mat-datepicker-toggle matSuffix [for]="picker">
      <natea-icons
        name="calendar"
        class="icon"
        ngxMatDatepickerToggleIcon
      ></natea-icons>
    </ngx-mat-datepicker-toggle>
    <ngx-mat-datetime-picker
      #picker
      [showSpinners]="showSpinners"
      [showSeconds]="showSeconds"
      [stepHour]="stepHour"
      [stepMinute]="stepMinute"
      [stepSecond]="stepSecond"
      [touchUi]="touchUi"
      [color]="color"
      [enableMeridian]="enableMeridian"
      [disableMinute]="disableMinute"
      [defaultTime]="defaultTime"
      [hideTime]="hideTime"
      (opened)="onOpened()"
      [disabled]="isDisabled"
      (viewChanged)="onViewChanged($event)"
      (monthSelected)="onMonthSelected($event)"
      (yearSelected)="onYearSelected($event)"
      (closed)="onClosed()"
    >
      <ngx-mat-datepicker-actions>
        <natea-button
          [label]="t('reset')"
          (buttonClick)="onReset()"
          [color]="ButtonVariants.SECONDARY"
        >
        </natea-button>
        <natea-button
          [label]="t('cancel')"
          ngxMatDatepickerCancel
          [color]="ButtonVariants.SECONDARY"
        >
        </natea-button>
        <natea-button
          [label]="t('apply')"
          ngxMatDatepickerApply
          [color]="ButtonVariants.PRIMARY"
        >
        </natea-button>
      </ngx-mat-datepicker-actions>
    </ngx-mat-datetime-picker>
  </mat-form-field>
</ng-container>
