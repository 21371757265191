import { UserInfoResource } from '../../generated/api';
import { User } from '../../../shared/entities/user';

export class UserAdapters {
  public static userInfoBeToFe(user: UserInfoResource): User {
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      // TODO check if role is mandatory
    };
  }
  public static userInfoFeToBe(user: User): UserInfoResource {
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      // TODO check if role is mandatory
    };
  }
}
