import { NgModule } from '@angular/core';
import { CanActivateFn, RouterModule, Routes } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AppRoutes } from '../../shared/routes/routes';
import { PatientsLoaderComponent } from './components/patients-loader/patients-loader.component';
import { patientDepartmentsCountGuard } from './guards/patient-departments-count.guard';
import { patientsLoadedGuard } from './guards/patients-loaded.guard';
import { PatientsComponent } from './patients.component';

const guards: CanActivateFn[] = environment.dataGuardsEnabled
  ? [patientsLoadedGuard, patientDepartmentsCountGuard]
  : [];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'patients',
    pathMatch: 'full',
  },
  {
    path: 'loading',
    component: PatientsLoaderComponent,
  },
  {
    path: 'patients',
    canActivate: guards,
    component: PatientsComponent,
  },
  {
    path: `patients/:${AppRoutes.Parameters.patientId}`,
    canActivate: guards,
    loadChildren: () =>
      import('../medical-records/medical-records.module').then(
        (m) => m.MedicalRecordsModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PatientsRoutingModule {}
