import {
  MultisectionItemIconState,
  ProcedureOccurrenceDurationType,
} from '../../../shared/components/multi-section-timeline/models/multi-section-timeline-instance';
import { ProfessionalCategory } from '../../../shared/entities/professional-category';
import { User, shownName } from '../../../shared/entities/user';
import { mockEncounterDay } from '../../../shared/utils/mock-utils';
import { randomId } from '../../../shared/utils/utils';
import {
  ProcedureMovementFormData,
  ProceduresProgrammingFormData,
} from '../components/procedures-detail-form/form-data/procedures-detail-form-data';
import { Procedure } from '../entities/procedure';
import {
  ProcedureOccurrenceMovement,
  ProcedureOccurrenceMovementType,
} from '../entities/procedure-movement';
import {
  ProcedureOccurrence,
  ProcedureOccurrenceState,
} from '../entities/procedure-occurrence';

/*const procedures = [
  'Intubazione',
  'Pos. Acc. Venoso Periferico',
  'Pos. di Catetere Venoso Ombelicale',
  'Pos. Catetere Venoso Centrale',
  'Pos. di drenaggio pericardico',
  'Pos. di reservoir cerebrale',
  'Cannula ECMO dual lumen bicavale',
  'Cateterismo vescicale',
  'Incannulamento arteria periferica',
  'Intubazione',
  'Paracentesi',
  'Pericardiocentesi',
  'Pos. acc. venoso periferico',
  'Pos. di catetere art. ombelicale',
  'Pos. di catetere venoso ombelicale',
  'Pos. di drenaggio toracico',
  'Pos. catetere venoso centrale',
  'Puntura sovrapubica',
  'Puntura ventricolare da reservoir',
  'Rachicentesi',
];*/

const procedures = [
  // RLE
  'Catetere Venoso Centrale',
  'Catetere Venoso Ombelicale',
  'Catetere Venoso Periferico',
  'Intubazione',
];

const procedureType: Procedure[] = [
  // RLE
  {
    id: randomId(),
    description: 'Catetere Venoso Centrale',
  },
  {
    id: randomId(),
    description: 'Catetere Venoso Ombelicale',
  },
  {
    id: randomId(),
    description: 'Catetere Venoso Periferico',
  },
  {
    id: randomId(),
    description: 'Intubazione',
  },
];

const user: User = {
  id: '1',
  firstName: 'Maurizio',
  lastName: 'Rossi',
};

export const procedureOccurrencesListMock = (): ProcedureOccurrence[] => {
  const createList = (index: number): ProcedureOccurrence[] => {
    return [
      {
        id: randomId(),
        type: ProcedureOccurrenceDurationType.Prolonged,
        endIcon: { name: 'placeholder-icona' },
        procedure: procedureType[0],
        prescriptionDate: mockEncounterDay(2, 7, 0), // RLE
        programmingDate: mockEncounterDay(2, 11, 0), // RLE
        executionDate: mockEncounterDay(2, 11, 0), // RLE
        terminationDate: mockEncounterDay(10, 12, 0), // RLE
        adverseEvents: null,
        frSize: 2,

        executor: {
          id: '1',
          label: shownName({
            id: '1',
            firstName: 'Mario',
            lastName: 'Rossi',
          }),
        },
        device: {
          id: '2',
          description: 'PREMICATH 27 G',
        },
        lot: '1234',
        insertion: null,
        place: {
          id: '1',
          shortDescription: 'Reparto 1',
          description: 'Reparto 1',
        },
        insertionLength: null,
        notes: null,
        state: ProcedureOccurrenceState.Executed,
        problem: {
          id: '2',
          name: 'Ittero in prematuro',
          problemClass: ProfessionalCategory.Medical,
          valid: true,
        },
        movements: [
          {
            id: randomId(),
            executionDate: mockEncounterDay(2, 11, 20), // RLE
            type: ProcedureOccurrenceMovementType.Inserted,
            cm: 2,
            medicated: true,
            notes: 'Note 1',
          },
          {
            // RLE
            id: randomId(), // RLE
            executionDate: mockEncounterDay(5, 9, 0), // RLE
            type: ProcedureOccurrenceMovementType.NotApplicable, // RLE
            cm: 0, // RLE
            medicated: true, // RLE
            notes: 'Medicazione periodica', // RLE
          }, // RLE
          {
            // RLE
            id: randomId(), // RLE
            executionDate: mockEncounterDay(8, 9, 0), // RLE
            type: ProcedureOccurrenceMovementType.NotApplicable, // RLE
            cm: 0, // RLE
            medicated: true, // RLE
            notes: 'Medicazione periodica', // RLE
          }, // RLE
          {
            id: randomId(),
            executionDate: mockEncounterDay(10, 10, 40), // RLE
            type: ProcedureOccurrenceMovementType.Removed,
            cm: 1,
            medicated: false,
            notes: 'Note 2',
          },
        ],
      },
      {
        id: randomId(),
        type: ProcedureOccurrenceDurationType.Prolonged,
        endIcon: { name: 'placeholder-icona' },
        procedure: procedureType[1],
        frSize: 2,
        prescriptionDate: mockEncounterDay(2, 7, 0), // RLE
        programmingDate: mockEncounterDay(2, 14, 0), // RLE
        executionDate: mockEncounterDay(2, 14, 0), // RLE
        terminationDate: mockEncounterDay(4, 8, 40), // RLE
        adverseEvents: null,
        executor: null,
        device: null,
        lot: null,
        insertion: null,
        place: null,
        insertionLength: null,
        notes: null,
        state: ProcedureOccurrenceState.Executed,
        problem: {
          id: '2',
          name: 'Ittero in prematuro',
          problemClass: ProfessionalCategory.Medical,
          valid: true,
        },
        movements: [
          {
            id: randomId(),
            executionDate: mockEncounterDay(2, 14, 40), // RLE
            type: ProcedureOccurrenceMovementType.Inserted,
            cm: 2,
            medicated: true,
            notes: 'Note 1',
          },
          {
            id: randomId(),
            executionDate: mockEncounterDay(4, 8, 40), // RLE
            type: ProcedureOccurrenceMovementType.Removed,
            cm: 1,
            medicated: false,
            notes: 'Note 2',
          },
        ],
      },

      {
        id: randomId(),
        type: ProcedureOccurrenceDurationType.Prolonged,
        //        endIcon: { name: 'placeholder-icona' },
        endIcon: {
          name: 'play', // RLE
          state: MultisectionItemIconState.Success, // RLE
        }, // RLE
        procedure: procedureType[1],
        prescriptionDate: mockEncounterDay(6, 7, 0), // RLE
        programmingDate: mockEncounterDay(6, 10, 0), // RLE
        executionDate: mockEncounterDay(6, 10, 30), // RLE
        terminationDate: mockEncounterDay(100), // RLE
        adverseEvents: null,
        executor: {
          id: randomId(),
          label: shownName(user),
        },

        lot: '345',
        insertion: null,
        frSize: 2,
        place: {
          id: randomId(),
          shortDescription: 'Braccio destro',
          description: 'Braccio destro',
        },
        insertionLength: null,
        notes: null,
        state: ProcedureOccurrenceState.Running,
        problem: {
          id: '4',
          name: 'Ittero in prematuro',
          problemClass: ProfessionalCategory.Medical,
          valid: true,
        },
        movements: [
          {
            id: randomId(),
            executionDate: mockEncounterDay(6, 10, 30), // RLE
            type: ProcedureOccurrenceMovementType.Inserted,
            cm: 2,
            medicated: true,
            notes: 'Note 1',
          },
          {
            // RLE
            id: randomId(), // RLE
            executionDate: mockEncounterDay(8, 9, 0), // RLE
            type: ProcedureOccurrenceMovementType.NotApplicable, // RLE
            cm: 0, // RLE
            medicated: true, // RLE
            notes: 'Medicazione periodica', // RLE
          }, // RLE
          {
            // RLE
            id: randomId(), // RLE
            executionDate: mockEncounterDay(10, 9, 0), // RLE
            type: ProcedureOccurrenceMovementType.NotApplicable, // RLE
            cm: 0, // RLE
            medicated: true, // RLE
            notes: 'Medicazione periodica', // RLE
          }, // RLE
        ],
        device: {
          id: '2',
          description: 'MEDEX MEDICAL 27 G',
        },
      },

      {
        id: randomId(),
        type: ProcedureOccurrenceDurationType.Prolonged,
        endIcon: { name: 'placeholder-icona' },
        procedure: procedureType[2],
        prescriptionDate: mockEncounterDay(4, 8, 0), // RLE
        programmingDate: mockEncounterDay(4, 9, 0), // RLE
        executionDate: mockEncounterDay(4, 9, 30), // RLE
        terminationDate: mockEncounterDay(6, 8, 0), // RLE
        adverseEvents: null,
        frSize: 2,
        executor: null,
        device: null,
        lot: null,
        insertion: null,
        place: null,
        insertionLength: null,
        notes: null,
        state: ProcedureOccurrenceState.Executed,
        problem: {
          id: '3',
          name: 'Ittero in prematuro',
          problemClass: ProfessionalCategory.Medical,
          valid: true,
        },
        movements: [
          {
            id: randomId(),
            executionDate: mockEncounterDay(4, 9, 30), // RLE
            type: ProcedureOccurrenceMovementType.Inserted,
            cm: 2,
            medicated: true,
            notes: 'Note 1',
          },
          {
            id: randomId(),
            executionDate: mockEncounterDay(6, 8, 0), // RLE
            type: ProcedureOccurrenceMovementType.Removed,
            cm: 1,
            medicated: false,
            notes: 'Note 2',
          },
        ],
      },
      {
        id: randomId(),
        type: ProcedureOccurrenceDurationType.Prolonged,
        endIcon: {
          name: 'play', // RLE
          state: MultisectionItemIconState.Success, // RLE
        },
        procedure: procedureType[3],
        prescriptionDate: mockEncounterDay(8, 15, 30), // RLE
        programmingDate: mockEncounterDay(8, 17, 0), // RLE
        executionDate: mockEncounterDay(8, 16, 40), // RLE
        terminationDate: mockEncounterDay(100), // RLE
        frSize: 2,

        adverseEvents: 'Evento avverso 1',
        executor: {
          id: '1',
          label: shownName({
            id: '1',
            firstName: 'Mario',
            lastName: 'Rossi',
          }),
        },
        device: {
          id: '2',
          description: 'MEDEX MEDICAL 27 G',
        },
        lot: '1234',
        insertion: {
          id: randomId(),
          description: 'Intramuscolare',
        },
        place: {
          id: randomId(),
          shortDescription: 'Mano sinistra',
          description: 'Mano sinistra',
        },
        insertionLength: 234,
        notes: null,
        state: ProcedureOccurrenceState.Running,
        problem: {
          id: '4',
          name: 'Distress Respiratorio Moderato',
          problemClass: ProfessionalCategory.Medical,
          valid: true,
        },
        movements: [
          // RLE
          {
            // RLE
            id: randomId(), // RLE
            executionDate: mockEncounterDay(8, 16, 0), // RLE
            type: ProcedureOccurrenceMovementType.Inserted, // RLE
            cm: 6, // RLE
            medicated: true, // RLE
            notes: 'Note 1', // RLE
          }, // RLE
        ],
      },
    ];
  };

  return [...createList(0)];
};

export const mockNewOccurrence = (
  formData: ProceduresProgrammingFormData
): ProcedureOccurrence => {
  return {
    id: randomId(),

    frSize: 2,
    type: ProcedureOccurrenceDurationType.Instant,
    icon: { name: 'pause' },
    procedure: formData.procedure?.data ?? {
      id: randomId(),
      description: 'Procedure 1',
    },
    executionDate: formData.programmingDate ?? new Date(),
    prescriptionDate: formData.prescriptionDate ?? new Date(),
    programmingDate: formData.programmingDate ?? null,
    state: ProcedureOccurrenceState.Executed,
    problem: formData.problem?.data ?? {
      id: '2',
      name: 'Mal di testa',
      problemClass: ProfessionalCategory.Medical,
      valid: true,
    },
    movements: [],
    adverseEvents: null,
    executor: null,
    device: null,
    lot: null,
    insertion: null,
    place: null,
    insertionLength: null,
    notes: null,
  };
};

export const mockNewMovement = (
  data: ProcedureMovementFormData
): ProcedureOccurrenceMovement => {
  return {
    id: randomId(),
    executionDate: data.executionDate ?? new Date(),
    type: data.type?.data ?? ProcedureOccurrenceMovementType.Inserted,
    cm: data.cm ?? 1000,
    medicated: data.medicated ?? true,
    notes: data.notes ?? 'Note 1',
  };
};
