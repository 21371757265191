<div
  class="container"
  [class.modal-small]="modalType.SMALL === modalSize"
  [class.modal-medium]="modalType.MEDIUM === modalSize"
  [class.modal-large]="modalType.LARGE === modalSize"
  [class.modal-x-large]="modalType.XLARGE === modalSize"
  [class.double-modal]="secondary"
>
  <header *ngIf="!isComplexHeader">
    <div [class.add-bottom-margin]="!!modalSubtitle">
      <h1 [title]="modalTitle" *ngIf="modalTitle">
        {{ modalTitle }}
      </h1>
      <button
        type="button"
        (click)="onClose($event)"
        class="close-button"
        [disabled]="closeButtonDisabled"
        [class.disabled]="closeButtonDisabled"
      >
        <natea-icons name="close" title="Close" class="icon" [class.disabled]="closeButtonDisabled"></natea-icons>
      </button>
    </div>

    <h3
      class="simple-subheader-creation"
      [title]="modalSubtitle"
      *ngIf="modalSubtitle"
    >
      {{ modalSubtitle }}
    </h3>
  </header>

  <header *ngIf="isComplexHeader">
    <ng-content select="[complex-header]"></ng-content>
  </header>

  <div class="body">
    <ng-content></ng-content>
  </div>
</div>
