/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents the transfer of a patient\'s hospitalization.
 */
export interface TransferResource { 
    /**
     * Unique identifier for the department within the specified hospital where the hospitalization is currently taking place.
     */
    departmentId: string;
    /**
     * Unique identifier for the patient\'s hospitalization.
     */
    encounterId: string;
    /**
     * Timestamp of when the patient entered the specified department.
     */
    enteredAt: string;
    /**
     * Unique identifier for the hospital where the hospitalization is currently taking place.
     */
    hospitalId: string;
    /**
     * Unique identifier for the transfer.
     */
    id: string;
    /**
     * Timestamp of when the patient left the specified department, if applicable.
     */
    leftAt?: string;
    /**
     * Representing the room code and the bed number in the specified department.
     */
    place: string;
}

