import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NateaIconsName, OptionsItem } from 'natea-components';
import { SidebarItemId } from '../../../shared/components/sidebar/models/sidebar-item';
import {
  User,
  UserRole,
  iconForUserRole,
  shownName,
} from '../../../shared/entities/user';
import { selectOngoingEncounterNotesToApprove } from '../../patients/store/clinical-data/clinical-notes/patients-data.clinical-notes.selectors';
import { UserState } from './users.reducers';

export const selectUsers = createFeatureSelector<UserState>('user');

export const selectLoggedUser = createSelector(
  selectUsers,
  (state: UserState): User | undefined => state.user
);
export const selectIsUserLogged = createSelector(
  selectLoggedUser,
  (user?: User): boolean => !!user
);

export const selectLoggedUserRoleIcon = createSelector(
  selectLoggedUser,
  (user?: User): NateaIconsName | undefined => {
    return iconForUserRole(user?.role);
  }
);
export const selectLoggedUserShownName = createSelector(
  selectLoggedUser,
  (user?: User): string | undefined => {
    return user ? shownName(user) : undefined;
  }
);

export const selectIsStudentDoctor = createSelector(
  selectUsers,
  (state: UserState): boolean => state.user?.role === UserRole.DoctorStudent
);

export const selectLoggedUserAsDropdownItem = createSelector(
  selectUsers,
  selectLoggedUser,
  (state: UserState, user?: User): OptionsItem<User> | undefined => {
    if (!user) {
      return undefined;
    }
    return {
      id: user.id,
      label: `${user.firstName} ${user.lastName}`,
      iconName: user.role === UserRole.Doctor ? 'medico' : 'infermiere',
      data: user,
    };
  }
);

export const selectLoggedUserRole = createSelector(
  selectUsers,
  (state: UserState): UserRole | undefined => state.user?.role
);

export const selectIsMenuOpened = createSelector(
  selectUsers,
  (state: UserState): boolean => state.preferences.isMenuOpened
);

export const selectForcedOpenMenuGroups = createSelector(
  selectUsers,
  selectOngoingEncounterNotesToApprove,
  (state: UserState, notesToApprove): SidebarItemId[] => {
    if (notesToApprove?.length) {
      return [SidebarItemId.ClinicalNotes];
    }

    return [];
  }
);

export const selectMenuBadgesMap = createSelector(
  selectUsers,
  selectOngoingEncounterNotesToApprove,
  (state: UserState, notesToApprove): Map<SidebarItemId, number> => {
    if (notesToApprove?.length) {
      return new Map([
        [SidebarItemId.ClinicalNotesValidation, notesToApprove.length],
      ]);
    }

    return new Map();
  }
);

export const selectOpenedMenuGroups = createSelector(
  selectUsers,
  selectForcedOpenMenuGroups,
  (state: UserState, forcedOpenGroups): SidebarItemId[] =>
    state.preferences.expandedMenuGroups.concat(forcedOpenGroups)
);

export const selectIsUserNewNoteAutovalidated = createSelector(
  selectUsers,
  (state: UserState): boolean =>
    state.permissions?.areNotesAutoValidated ?? false // TODO implement using user's permissions
);

export const selectCanUserValidateNotes = createSelector(
  selectUsers,
  (state: UserState): boolean => state.permissions?.canValidateNotes ?? false // TODO implement using user's permissions
);

export const selectCanUserCreateNotes = createSelector(
  selectUsers,
  (state: UserState): boolean => state.permissions?.canCreateNotes ?? false // TODO implement using user's permissions
);

export const selectCanUserConfirmTherapies = createSelector(
  selectUsers,
  (state: UserState): boolean => state.permissions?.canConfirmTherapies ?? false // TODO implement using user's permissions
);
