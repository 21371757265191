import { Sort } from '@angular/material/sort';
import { RouterReducerState, getRouterSelectors } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OptionsItem, TabItem } from 'natea-components';
import { selectIsDomainsDownloaded } from '../../../core/domains/store/domains.selectors';
import { SearchPatientResultInterface } from '../../../shared/components/modals/search-patients-modal/interface/search-result';
import { shortDateString } from '../../../shared/utils/date-utils';
import { PatientEncounter } from '../../problems/interfaces/patient-encounter';
import {
  EncounterData,
  PatientAndEncounterIds,
  PatientAndVisibleEncounterIds,
  PatientData,
  PatientsState,
  patientDataAdapter,
} from './patients.reducers';
import { selectCurrentDepartmentId } from '../../departments/store/departments.selectors';

const selectRouterState = createFeatureSelector<RouterReducerState>('router');
const { selectRouteParams } = getRouterSelectors(selectRouterState);

export const selectPatientsState =
  createFeatureSelector<PatientsState>('patients');

const selectors = patientDataAdapter.getSelectors();

export const selectPatients = createSelector(
  selectPatientsState,
  (patientsState: PatientsState): PatientsState => patientsState
);

export const selectPatientsSort = createSelector(
  selectPatientsState,
  (patientsState: PatientsState): Sort | undefined => patientsState.sort
);

export const selectPatientsList = createSelector(
  selectPatientsState,
  selectCurrentDepartmentId,
  (patientsState: PatientsState, departmentId): PatientData[] | undefined => {
    return selectors
      .selectAll(patientsState)
      .filter((patient) => patient.departmentId === departmentId);
  }
);

export const selectPatientsAsDropdownItems = createSelector(
  selectPatients,
  (state: PatientsState): OptionsItem<string>[] | undefined =>
    selectors.selectAll(state)?.map((patient) => ({
      id: patient.id,
      label: `${patient.firstName} ${patient.lastName}`,
    }))
);

export const selectSelectedPatientId = createSelector(
  selectRouteParams,
  (params: any): string => {
    return params.patientId;
  }
);

export const selectSelectedPatient = createSelector(
  selectPatients,
  selectSelectedPatientId,
  (state: PatientsState, patientId: string): PatientData | undefined =>
    state.entities[patientId]
);

export const selectBirthDate = createSelector(
  selectSelectedPatient,
  (patient: PatientData | undefined): Date | undefined => patient?.birthDate
);

export const selectPatientsDataLoaded = createSelector(
  selectPatients,
  (state: PatientsState): boolean => state.patientsLoaded
);

export const selectSelectedPatientEncountersLoaded = createSelector(
  selectPatients,
  selectSelectedPatient,
  (state: PatientsState, patient: PatientData | undefined): boolean => {
    const result =
      (patient && state.entities[patient?.id]?.encountersLoaded) ?? false;
    return result;
  }
);

export const selectSelectedPatientData = createSelector(
  selectPatients,
  selectSelectedPatientId,
  (state: PatientsState, patientId: string): PatientData | undefined =>
    patientId ? state.entities[patientId] : undefined
);

/******************** ENCOUNTERS /*********************/

/**
 * Returns the list of encounters for the selected patient, or undefined if there is no selected patient.
 */
export const selectSelectedPatientEncounters = createSelector(
  selectSelectedPatientData,
  (patient: PatientData | undefined): EncounterData[] | undefined => {
    if (patient) {
      return patient.encounters.ids.map(
        (id: string | number): EncounterData | undefined =>
          patient.encounters.entities[id]
      ) as EncounterData[];
    }
    return undefined;
  }
);

export const selectShownEncounterIds = createSelector(
  selectSelectedPatient,
  (patient: PatientData | undefined): PatientAndEncounterIds | undefined => {
    return patient?.shownEncounterId
      ? { patientId: patient.id, encounterId: patient.shownEncounterId }
      : undefined;
  }
);

export const selectShownEncounter = createSelector(
  selectSelectedPatient,
  (patient: PatientData | undefined): EncounterData | undefined => {
    const result: EncounterData | undefined = patient?.shownEncounterId
      ? patient?.encounters?.entities[patient?.shownEncounterId]
      : undefined;

    return result;
  }
);

export const selectVisibleEncounterIds = createSelector(
  selectSelectedPatient,
  (patient: PatientData | undefined): PatientAndVisibleEncounterIds => ({
    patientId: patient?.id ?? '',
    visibleEncounterIds:
      patient?.visibleEncounters?.ids.map(
        (id) => patient?.visibleEncounters?.entities[id] ?? ''
      ) ?? [],
  })
);

export const selectEncounterTabs = createSelector(
  selectSelectedPatientEncounters,
  selectVisibleEncounterIds,
  selectShownEncounterIds,
  (
    encounters: EncounterData[] | undefined,
    visibleEncounterIds: PatientAndVisibleEncounterIds,
    shownEncounterIds?: PatientAndEncounterIds
  ): {
    tabs: TabItem[];
    activeIndex: number | null;
  } => {
    const ret: TabItem[] = (encounters ?? []).map(
      (encounter: PatientEncounter): TabItem => {
        return {
          id: encounter.id,
          data: {
            date: encounter.startDate,
            dateLabel: shortDateString(encounter.startDate),
            endDateLabel: encounter.endDate
              ? shortDateString(encounter.endDate)
              : undefined,
          },
          label: encounter.endDate
            ? 'encounter.encounter'
            : 'encounter.currentEncounter',
          isChecked:
            !encounter.endDate ||
            visibleEncounterIds.visibleEncounterIds.includes(encounter.id),
        };
      }
    );
    ret.sort(
      (tabA: TabItem, tabB: TabItem): number =>
        (tabB.data['date'] as Date).getTime() -
        (tabA.data['date'] as Date).getTime()
    );
    const activeIndex: number = ret.findIndex(
      (t: TabItem): boolean => t.id === shownEncounterIds?.encounterId
    );

    return {
      tabs: ret,
      activeIndex: activeIndex !== -1 ? activeIndex : null,
    };
  }
);

/**
 * Returns the active encounter for the selected patient, or undefined if there is no active encounter.
 */
export const selectOngoingEncounter = createSelector(
  selectSelectedPatientEncounters,
  (encounters: EncounterData[] | undefined): EncounterData | undefined => {
    return encounters?.find(
      (encounter: PatientEncounter) => !encounter.endDate
    );
  }
);

/**
 * Returns the ids of the selected patient and its ongoing encounter ids
 */
export const selectOngoingEncounterAndPatientIds = createSelector(
  selectSelectedPatientId,
  selectOngoingEncounter,
  (
    patientId: string,
    encounter?: PatientEncounter
  ): PatientAndEncounterIds | undefined =>
    encounter
      ? {
          patientId,
          encounterId: encounter?.id,
        }
      : undefined
);

export const selectIsShownEncounterOngoing = createSelector(
  selectShownEncounter,
  (encounter: PatientEncounter | undefined): boolean =>
    encounter?.endDate === undefined
);

export const selectShowSearchPatientsModal = createSelector(
  selectPatientsState,
  (state: PatientsState): boolean => state.showSearchPatientModal ?? false
);

export const selectSearchPatientsModalResult = createSelector(
  selectPatientsState,
  (state: PatientsState): SearchPatientResultInterface[] | undefined =>
    state.searchPatientResult ?? undefined
);

export const selectIsSearchingPatients = createSelector(
  selectPatientsState,
  (state: PatientsState): boolean => state.isSearchingPatients
);

export const selectIsPatientsListExportRunning = createSelector(
  selectPatientsState,
  (state: PatientsState): boolean => state.isExportingPatientsList ?? false
);

// Mandatory patients data

export const selectHasPatientMandatoryDataBeenLoaded = createSelector(
  selectSelectedPatientEncountersLoaded,
  selectSelectedPatientData,
  selectIsDomainsDownloaded,
  (
    encountersLoaded: boolean,
    patientData: PatientData | undefined,
    domainsDownloaded: boolean
  ): boolean => {
    return (
      (encountersLoaded &&
        domainsDownloaded &&
        patientData?.problems !== undefined) ??
      false
    );
  }
);
