import { ReducerTypes, on } from '@ngrx/store';
import { loadGanttDataSuccess } from '../../../../nurse-records/nursing-gantt/store/nursing-gantt.actions';
import { PatientsState, updateEncounterData } from '../../patients.reducers';

export const patientNursingGanttReducerFunctions: ReducerTypes<
  PatientsState,
  [never]
>[] = [
  on(loadGanttDataSuccess, (state, { data, patientId, encounterId }) => {
    return updateEncounterData(state, { patientId, encounterId }, () => ({
      nursingGantt: data,
    }));
  }),
];
