<section *transloco="let t; read: 'login'">
  <form class="main" [formGroup]="loginForm" (ngSubmit)="login()">
    <div class="welcome">
      <p>{{ t("welcome") }}</p>
    </div>
    <div class="user-data">
      <div class="login-text">{{ t("title") }}</div>
      <natea-input
        class="user-name"
        [label]="t('userName')"
        formControlName="userName"
      ></natea-input>
      <natea-input
        [label]="t('password')"
        formControlName="password"
        [type]="passwordInputType"
        [rightIcon]="passwordVisibilityIcon"
        (rightIconClick)="togglePasswordVisibility()"
      ></natea-input>
      <button type="button">{{ t("forgotPassword") }}</button>

      <natea-button
        [label]="t('login')"
        buttonType="submit"
        [tabindex]="0"
      ></natea-button>
    </div>
  </form>

  <footer>
    <div *ngIf="(showDemoButtons$ | async) ?? false" class="demo-buttons">
      <natea-button
        label="Login come tutor"
        (click)="tutorLogin()"
      ></natea-button>
      <natea-button
        label="Login come strutturato"
        (click)="structuredLogin()"
      ></natea-button>
      <natea-button
        label="Login come non strutturato"
        (click)="notStructuredLogin()"
      ></natea-button>
      <natea-button
        label="Login come infermiere"
        (click)="nurseLogin()"
      ></natea-button>
    </div>
  </footer>
</section>
