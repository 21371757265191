import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { ApiError } from '../../../../shared/entities/errors';
import { SelectedTherapyOccurrenceIds } from '../../common/entities/selected-therapy';
import { PharmacologicalTherapy } from '../entities/pharmacological-therapy';
import { PharmacologicalTherapyCancelSuspend } from '../entities/pharmacological-therapy-cancel-suspend';
import { PharmacologicalTherapyFormData } from '../form/pharmacological-therapy-form-data';
import { PharmacologicalTherapiesCatalogs } from './pharmacological-therapies.reducer';

export enum PharmacologicalTherapiesActions {
  LoadPharmacologicalTherapies = '[PharmacologicalTherapies] Load PharmacologicalTherapies',
  LoadPharmacologicalTherapiesSuccess = '[PharmacologicalTherapies] Load PharmacologicalTherapies Success',
  LoadPharmacologicalTherapiesFailure = '[PharmacologicalTherapies] Load PharmacologicalTherapies Failure',

  SortPharmacologicalTherapies = '[PharmacologicalTherapies] Sort',

  SelectOccurrence = '[PharmacologicalTherapies] Select Occurrence',
  DeselectOccurrence = '[PharmacologicalTherapies] Deselect Occurrence',

  ShowCreateNewModal = '[PharmacologicalTherapies] Show Create New Modal',
  HideCreateNewModal = '[PharmacologicalTherapies] Hide Create New Modal',

  CreatePharmacologicalTherapy = '[PharmacologicalTherapies] Create PharmacologicalTherapy',
  CreatePharmacologicalTherapySuccess = '[PharmacologicalTherapies] Create PharmacologicalTherapy Success',
  CreatePharmacologicalTherapyFailure = '[PharmacologicalTherapies] Create PharmacologicalTherapy Failure',

  EditPharmacologicalTherapy = '[PharmacologicalTherapies] Edit PharmacologicalTherapy',
  EditPharmacologicalTherapySuccess = '[PharmacologicalTherapies] Edit PharmacologicalTherapy Success',
  EditPharmacologicalTherapyFailure = '[PharmacologicalTherapies] Edit PharmacologicalTherapy Failure',

  SelectedTherapyOccurrenceFromTimeline = '[PharmacologicalTherapies] Selected Therapy Occurrence From Timeline',

  SelectSuspensionCandidate = '[PharmacologicalTherapies] Select Suspension Candidate',
  AbortSuspension = '[PharmacologicalTherapies] Abort Suspension',
  ConfirmSuspension = '[PharmacologicalTherapies] Confirm Suspension',
  SuspensionSuccess = '[PharmacologicalTherapies] Suspension Success',
  SuspensionFailure = '[PharmacologicalTherapies] Suspension Failure',

  ChangeTab = '[PharmacologicalTherapies] Change Tab',

  DownloadCatalogs = '[PharmacologicalTherapies] Download Catalogs',
  DownloadCatalogsSuccess = '[PharmacologicalTherapies] Download Catalogs Success',
  DownloadCatalogsFailure = '[PharmacologicalTherapies] Download Catalogs Failure',

  ShowConfirmDeleteTherapyOccurrenceModal = '[PharmacologicalTherapies] Show Confirm Delete Therapy Occurrence Modal',
  CloseConfirmDeleteTherapyOccurrenceModal = '[PharmacologicalTherapies] Close Confirm Delete Therapy Occurrence Modal',
  ConfirmDeletePharmacologicalTherapyModal = '[PharmacologicalTherapies] Confirm Delete Therapy Occurrence Modal',
  ConfirmDeletePharmacologicalTherapySuccess = '[PharmacologicalTherapies] Confirm Delete Therapy Occurrence Success',
  ConfirmDeletePharmacologicalTherapyFailure = '[PharmacologicalTherapies] Confirm Delete Therapy Occurrence Failure',
  ShowConfirmCancelTherapyOccurrenceModal = '[PharmacologicalTherapies] Show Confirm Cancel Therapy Occurrence Modal',
  CloseConfirmCancelTherapyOccurrenceModal = '[PharmacologicalTherapies] Close Confirm Cancel Therapy Occurrence Modal',
  ConfirmCancelPharmacologicalTherapyModal = '[PharmacologicalTherapies] Confirm Cancel Therapy Occurrence Modal',
  ConfirmCancelPharmacologicalTherapySuccess = '[PharmacologicalTherapies] Confirm Cancel Therapy Occurrence Success',
  ConfirmCancelPharmacologicalTherapyFailure = '[PharmacologicalTherapies] Confirm Cancel Therapy Occurrence Failure',
}

export const loadPharmacologicalTherapies = createAction(
  PharmacologicalTherapiesActions.LoadPharmacologicalTherapies
);

export const loadPharmacologicalTherapiesSuccess = createAction(
  PharmacologicalTherapiesActions.LoadPharmacologicalTherapiesSuccess,
  props<{
    therapies: PharmacologicalTherapy[];
    patientId: string;
    encounterId: string;
  }>()
);

export const loadPharmacologicalTherapiesFailure = createAction(
  PharmacologicalTherapiesActions.LoadPharmacologicalTherapiesFailure,
  props<{ error: ApiError }>()
);

export const sortPharmacologicalTherapies = createAction(
  PharmacologicalTherapiesActions.SortPharmacologicalTherapies,
  props<{ sort?: Sort }>()
);

export const selectPharmacologicalTherapyOccurrence = createAction(
  PharmacologicalTherapiesActions.SelectOccurrence,
  props<{ therapyId: string; occurrenceId: string }>()
);

export const deselectPharmacologicalTherapyOccurrence = createAction(
  PharmacologicalTherapiesActions.DeselectOccurrence
);

export const showCreateNewPharmacologicalTherapyModal = createAction(
  PharmacologicalTherapiesActions.ShowCreateNewModal
);
export const hideCreateNewPharmacologicalTherapyModal = createAction(
  PharmacologicalTherapiesActions.HideCreateNewModal
);

export const createPharmacologicalTherapy = createAction(
  PharmacologicalTherapiesActions.CreatePharmacologicalTherapy,
  props<{
    therapy: PharmacologicalTherapyFormData;
  }>()
);

export const createPharmacologicalTherapySuccess = createAction(
  PharmacologicalTherapiesActions.CreatePharmacologicalTherapySuccess,
  props<{
    therapy: PharmacologicalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const createPharmacologicalTherapyFailure = createAction(
  PharmacologicalTherapiesActions.CreatePharmacologicalTherapyFailure,
  props<{ error: ApiError }>()
);

export const editPharmacologicalTherapy = createAction(
  PharmacologicalTherapiesActions.EditPharmacologicalTherapy,
  props<{
    therapyId: string;
    therapy: PharmacologicalTherapyFormData;
  }>()
);

export const editPharmacologicalTherapySuccess = createAction(
  PharmacologicalTherapiesActions.EditPharmacologicalTherapySuccess,
  props<{
    therapy: PharmacologicalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const editPharmacologicalTherapyFailure = createAction(
  PharmacologicalTherapiesActions.EditPharmacologicalTherapyFailure,
  props<{ error: ApiError }>()
);

export const pharmacologicalTherapyTabChanged = createAction(
  PharmacologicalTherapiesActions.ChangeTab,
  props<{ tabIndex: number }>()
);

export const selectedTherapyOccurrenceFromTimeline = createAction(
  PharmacologicalTherapiesActions.SelectedTherapyOccurrenceFromTimeline,
  props<{ selectedTherapyOccurrenceIds: SelectedTherapyOccurrenceIds }>()
);

export const downloadPharmacologicalTherapiesCatalogs = createAction(
  PharmacologicalTherapiesActions.DownloadCatalogs
);

export const downloadPharmacologicalTherapiesCatalogsSuccess = createAction(
  PharmacologicalTherapiesActions.DownloadCatalogsSuccess,
  props<{ catalogs: PharmacologicalTherapiesCatalogs }>()
);

export const downloadCatalogsFailure = createAction(
  PharmacologicalTherapiesActions.DownloadCatalogsFailure,
  props<{ error: ApiError }>()
);

export const showConfirmDeleteTherapyOccurrenceModal = createAction(
  PharmacologicalTherapiesActions.ShowConfirmDeleteTherapyOccurrenceModal,
  props<{ therapyId: string }>()
);

export const closeConfirmDeletePharmacologicalTherapyModal = createAction(
  PharmacologicalTherapiesActions.CloseConfirmDeleteTherapyOccurrenceModal
);

export const confirmDeletePharmacologicalTherapyModal = createAction(
  PharmacologicalTherapiesActions.ConfirmDeletePharmacologicalTherapyModal
);

export const confirmDeletePharmacologicalTherapySuccess = createAction(
  PharmacologicalTherapiesActions.ConfirmDeletePharmacologicalTherapySuccess,
  props<{
    therapyId: string | undefined;
    patientId: string;
    encounterId: string;
  }>()
);

export const confirmDeletePharmacologicalTherapyFailure = createAction(
  PharmacologicalTherapiesActions.ConfirmDeletePharmacologicalTherapyFailure,
  props<{
    error: ApiError | undefined;
    therapyId: string | undefined;
  }>()
);

export const showConfirmCancelTherapyOccurrenceModal = createAction(
  PharmacologicalTherapiesActions.ShowConfirmCancelTherapyOccurrenceModal,
  props<{ therapy: PharmacologicalTherapyCancelSuspend }>()
);

export const closeConfirmCancelPharmacologicalTherapyModal = createAction(
  PharmacologicalTherapiesActions.CloseConfirmCancelTherapyOccurrenceModal
);

export const confirmCancelPharmacologicalTherapyModal = createAction(
  PharmacologicalTherapiesActions.ConfirmCancelPharmacologicalTherapyModal,
  props<{ reason: string }>()
);

export const confirmCancelPharmacologicalTherapySuccess = createAction(
  PharmacologicalTherapiesActions.ConfirmCancelPharmacologicalTherapySuccess,
  props<{
    therapy: PharmacologicalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const confirmCancelPharmacologicalTherapyFailure = createAction(
  PharmacologicalTherapiesActions.ConfirmCancelPharmacologicalTherapyFailure,
  props<{
    error: ApiError | undefined;
    therapy: PharmacologicalTherapyCancelSuspend | undefined;
  }>()
);

export const selectPharmacologicalTherapySuspensionCandidate = createAction(
  PharmacologicalTherapiesActions.SelectSuspensionCandidate,
  props<{ therapyId: string }>()
);

export const abortPharmacologicalTherapySuspension = createAction(
  PharmacologicalTherapiesActions.AbortSuspension
);

export const confirmPharmacologicalTherapySuspension = createAction(
  PharmacologicalTherapiesActions.ConfirmSuspension,
  props<{ motivation: string }>()
);

export const pharmacologicalTherapySuspensionSuccess = createAction(
  PharmacologicalTherapiesActions.SuspensionSuccess,
  props<{
    therapy: PharmacologicalTherapy;
    patientId: string;
    encounterId: string;
  }>()
);

export const pharmacologicalTherapySuspensionFailure = createAction(
  PharmacologicalTherapiesActions.SuspensionFailure,
  props<{ error: ApiError }>()
);
