import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  selectIsUserLogged,
  selectLoggedUserRoleIcon,
  selectLoggedUserShownName,
} from '../../../features/users/store/users.selectors';
import { AppRoutes } from '../../routes/routes';

@Component({
  selector: 'natea-cc-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent {
  loggedUserShownName$ = this.store.select(selectLoggedUserShownName);
  loggedUserRoleIcon$ = this.store.select(selectLoggedUserRoleIcon);
  isUserLogged$ = this.store.select(selectIsUserLogged);

  constructor(private store: Store, private router: Router) {}

  goToRoot = () => {
    this.router.navigateByUrl(AppRoutes.departmentsRoot);
  };

  logout = () => {
    // TODO implement logout
  };
}
