import { Injectable } from '@angular/core';
import { Observable, delay, of, throwError } from 'rxjs';
import {
  EntityEventType,
  EntityLog,
} from '../../../../shared/entities/entity-log';
import { randomId } from '../../../../shared/utils/utils';
import {
  TherapyDurationType,
  TherapyOccurrenceState,
} from '../../common/entities/therapy-occurrence';
import { PharmacologicalTherapy } from '../entities/pharmacological-therapy';
import { PharmacologicalTherapyCancelSuspend } from '../entities/pharmacological-therapy-cancel-suspend';
import { PharmacologicalTherapyFormData } from '../form/pharmacological-therapy-form-data';
import { pharmacologicalTherapiesCatalogsMock } from '../mocks/pharmacological-therapy-catalogs.mocks';
import { basePharmacologicalTherapyMock } from '../mocks/pharmacological-therapy.mocks';
import { PharmacologicalTherapiesCatalogs } from '../store/pharmacological-therapies.reducer';

@Injectable({
  providedIn: 'root',
})
export class PharmacologicalTherapiesWebApi {
  public downloadCatalogs(): Observable<PharmacologicalTherapiesCatalogs> {
    return of(pharmacologicalTherapiesCatalogsMock).pipe(delay(1000));
  }

  public deletePharmacologicalTherapyPrescription(
    deleteTherapyId: string | undefined
  ): Observable<boolean> {
    /*  if (
      deleteTherapyId &&
      pharmacologicalTherapiesMock(
        pharmacologicalTherapiesListMock(),
        deleteTherapyId
      )
    ) {*/
    if (Math.random() > 0.5) {
      return of(true).pipe(delay(1000));
    } else {
      return of(false).pipe(delay(1000));
    }
  }

  public cancelPharmacologicalTherapyPrescription(
    pharmacologicalTherapyCancel:
      | PharmacologicalTherapyCancelSuspend
      | undefined,
    reason: string | null
  ): Observable<PharmacologicalTherapy> {
    if (!reason) {
      return throwError(() => new Error('Reason is required'));
    }
    return of({
      ...basePharmacologicalTherapyMock,
      id: pharmacologicalTherapyCancel?.id ?? randomId(),
      therapyType: {
        id: randomId(),
        canBePrescribedAtNeed: false,
        name: 'Suspended Lattoferrina e FOS',
        description: 'Suspended Lattoferrina e FOS 50mg',
      },
    }).pipe(delay(1000));
  }

  public createPharmacologicalTherapy = (
    data: PharmacologicalTherapyFormData
  ): Observable<PharmacologicalTherapy> => {
    return of(mockPharmacologicalTherapy(data)).pipe(delay(1000));
  };

  public editPharmacologicalTherapy = (
    data: PharmacologicalTherapyFormData,
    id: string
  ): Observable<PharmacologicalTherapy> => {
    return of(mockPharmacologicalTherapy(data, id)).pipe(delay(1000));
  };

  // Suspend
  public suspendPharmacologicalTherapyPrescription(
    id: string,
    motivation: string
  ): Observable<PharmacologicalTherapy> {
    return of({
      ...basePharmacologicalTherapyMock,
      therapyType: {
        id: randomId(),
        canBePrescribedAtNeed: false,
        name: 'Suspended Lattoferrina e FOS',
        description: 'Suspended Lattoferrina e FOS 50mg',
      },

      id,
    }).pipe(delay(1000));
  }

  public getHistory(id: string): Observable<EntityLog> {
    return of({
      events: [
        {
          // Ten days ago
          date: new Date(Date.now() - 1000 * 60 * 60 * 24 * 10),
          type: EntityEventType.Create,
          fields: [
            {
              value: 'value',
              key: 'key',
              changed: false,
            },
          ],
        },
      ],
    }).pipe(delay(1000));
  }
}

const mockPharmacologicalTherapy = (
  data: PharmacologicalTherapyFormData,
  id?: string
) => {
  console.log('mockPharmacologicalTherapy', data.canal);
  const prescriptionId = randomId();
  const therapy: PharmacologicalTherapy = {
    ...data,
    id: id ?? prescriptionId,
    posologyDose: data.posologyDose!,
    posologyTotalDose: data.posologyTotalDose!,
    posologyDilutedDoseMl: data.posologyDilutedDoseMl!,
    repetition: data.repetition?.data ?? undefined,
    diluent: data.diluent?.data ?? undefined,
    forDays: data.forDays!,
    notes: data.notes ?? undefined,
    asNeededMotivation: data.asNeededMotivation ?? undefined,
    diluentDose: data.diluentDose ?? undefined,
    velocity: data.velocity ?? 0,
    totalVolume: data.totalVolume ?? 0,
    canal: data.canal!.data!,
    administerAtNeed: data.administerAtNeed ?? false,
    updatedBy: undefined,
    occurrences: [
      {
        id: randomId(),
        prescriptionId: prescriptionId,
        state: TherapyOccurrenceState.Prescribed,
        durationType: TherapyDurationType.Instant,
        isValid: true,
        weightKg: '10',
        programmingDate: data.programmingDate!,
      },
    ],
    bsa: 1.15,

    problem: data.referencedProblem?.data ?? undefined,
    therapyType: data.therapy!.data!,
    doubleCheckExecuted: false,
    insertionDate: new Date(),
    durationMinutes: data.duration?.minutes ?? 0,
    patientWeightKg: 2.3,
    patientId: '',
    encounterId: '',
    catalogId: '',
    name: '',
    mode: data.mode!.data!,
    prescriptionDate: data.prescriptionDate!,
    programmingDate: data.programmingDate!,
    createdBy: {
      id: randomId(),
      firstName: 'Mario',
      lastName: 'Rossi',
    },
    creationDate: new Date(),
  };
  return therapy;
};
