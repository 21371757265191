import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TabItem } from 'natea-components';
import { Observable } from 'rxjs';
import {
  hideEncounterForSelectedPatient,
  showEncounterForSelectedPatient,
} from '../../../features/patients/store/patients.actions';
import { selectEncounterTabs } from '../../../features/patients/store/patients.selectors';
import { MultiSectionTimelineInstance } from '../multi-section-timeline/models/multi-section-timeline-instance';
import { selectTherapiesShowSecondHeaderLine } from '../../../features/therapies/common/store/therapies.selectors';

@Component({
  selector: 'natea-cc-timelined-base-section',
  templateUrl: './timelined-base-section.component.html',
  styleUrls: ['./timelined-base-section.component.scss'],
})
export class TimelinedBaseSectionComponent<T> {
  @Input() timelineStartDate?: Date;
  @Input() timelineEndDate?: Date;

  @Input() timelineInstances!: MultiSectionTimelineInstance<T>[];

  @Input() selectedTabIndex = 0;

  @Output() tabChanged: EventEmitter<number> = new EventEmitter<number>();

  showSecondHeaderLine$: Observable<boolean> = this.store.select(
    selectTherapiesShowSecondHeaderLine
  );

  encounterTabs$: Observable<{
    tabs: TabItem[];
    activeIndex: number | null;
  }> = this.store.select(selectEncounterTabs);

  constructor(private store: Store) {}

  /******************* Callbacks *******************/

  onTabChanged = (tabIndex: number): void => {
    this.tabChanged.emit(tabIndex);
  };

  onSelectedEncounter = (tab: TabItem): void => {
    this.store.dispatch(
      showEncounterForSelectedPatient({
        encounterId: tab.id as string,
      })
    );
  };

  onClosedEncounter = (tab: TabItem): void => {
    this.store.dispatch(
      hideEncounterForSelectedPatient({
        encounterId: tab.id as string,
      })
    );
  };
}
