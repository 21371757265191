import { Component } from '@angular/core';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';

@Component({
  selector: 'natea-full-page-loader',
  standalone: true,
  templateUrl: './full-page-loader.component.html',
  styleUrls: ['./full-page-loader.component.scss'],
  imports: [ProgressBarComponent],
})
export class FullPageLoaderComponent {}
