import { Sort, SortDirection } from '@angular/material/sort';
import { compareDates } from '../../../../shared/utils/utils';
import { TherapiesTableItem } from '../../common/entities/therapies-table-item';

export const instrumentalTherapySort = (
  sort?: Sort
): ((a: TherapiesTableItem, b: TherapiesTableItem) => number) => {
  if (sort?.direction === '') {
    return () => 0;
  }

  return (a: TherapiesTableItem, b: TherapiesTableItem): number => {
    if (!sort) {
      return 0;
    }
    const { active, direction }: { active: string; direction: SortDirection } =
      sort;
    const modifier: number = direction === 'asc' ? 1 : -1;
    switch (active) {
      case 'name':
        return a.name.localeCompare(b.name) * modifier;
      case 'prescriptionDate':
        return compareDates(a.prescriptionDate, b.prescriptionDate) * modifier;
      case 'executionDate':
        return compareDates(a.executionDate, b.executionDate) * modifier;
      case 'programmingDate':
        return compareDates(a.programmingDate, b.programmingDate) * modifier;
      case 'state':
        if (a.icon?.isImportant) return modifier * -1;
        if (b.icon?.isImportant) return modifier;
        return (a.state - b.state) * modifier;
      default:
        return 0;
    }
  };
};
