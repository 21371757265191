/// <reference types="node" />
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  ButtonVariants,
  NateaIconsName,
  TooltipSide,
  matchMediaDesktopSize,
} from 'natea-components';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ProfessionalCategory } from '../../../../shared/entities/professional-category';
import { LocalizedDateFormat } from '../../../../shared/pipes/localized-date.pipe';
import { datePercentageInRange } from '../../../../shared/utils/date-utils';
import { daysHoursMinutesFromDurationMinutes } from '../../../../shared/utils/duration-utils';
import { millisInAMinute } from '../../../../shared/utils/utils';
import { PatientProblem } from '../../interfaces/patient-problem';

const positionUpdateIntervalTime: number = environment.production
  ? 1000 * 60
  : 1000 * 60 * 60 * 24; // Prevent annoying reloads in dev mode

interface PositionedProblem extends PatientProblem {
  leftPositionPercentage?: number;
  width?: number;
  durationMinutes?: number;
}

@Component({
  selector: 'natea-cc-problems-timeline',
  templateUrl: './problems-timeline.component.html',
  styleUrls: ['./problems-timeline.component.scss'],
})
export class ProblemsTimelineComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  TooltipSide = TooltipSide;

  positionedProblems: PositionedProblem[] = [];
  LocalizedDateFormat = LocalizedDateFormat;

  @Input() set problems(problems: PatientProblem[]) {
    this.updatePositionedProblems(problems);
  }

  @Input() isAppliedFilter = false;

  @Input() startDate?: Date = new Date();
  @Input() endDate?: Date = new Date();

  @Input() selectedDate!: Subject<Date>;

  @Input() initialScrollDate?: Date = new Date();

  private selectedDateSubscription?: Subscription;

  @Output() deleteProblem: EventEmitter<PatientProblem> =
    new EventEmitter<PatientProblem>();

  @Output() edit: EventEmitter<PatientProblem> =
    new EventEmitter<PatientProblem>();

  @Output() info: EventEmitter<PatientProblem> =
    new EventEmitter<PatientProblem>();

  @Output() consultation: EventEmitter<PatientProblem> =
    new EventEmitter<PatientProblem>();

  @Output() canAddPaddingBottomToProblemContainer: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  get isProblemsListEmpty() {
    return this.positionedProblems.length === 0;
  }

  private desktopSizeMatcher: MediaQueryList = matchMediaDesktopSize(window);
  isDesktopSized = false;

  // Midnight of the first day of the timeline
  minDate!: Date;
  // 23:59:59 of the last day of the timeline
  maxDate!: Date;

  scrollHorizontalOffset = 0;
  expandedProblem?: PatientProblem;

  positionUpdateInterval?: NodeJS.Timeout;
  todayLineLeftPosition: number | undefined;
  buttonVariants = ButtonVariants;

  resizeObserver: ResizeObserver;
  mainColumnContainerWidth?: number;

  expandedNotesHeight?: number;
  closeNoteButtonHeightPx = 40;

  /***
   * Workaround used to prevent that the resize event management would close the expanded note
   * before the height of the note is updated
   */
  isUpdatingExpandedNotesHeight = false;

  private _notes?: ElementRef = undefined;
  @ViewChild('notes', {
    static: false,
  })
  set notes(note: ElementRef | undefined) {
    this._notes = note;
    this.onNoteElementChanged(note);
  }

  get notes(): ElementRef | undefined {
    return this._notes;
  }

  @ViewChild('mainColumnContainer', {
    static: false,
  })
  mainColumnContainer!: ElementRef;

  @ViewChild('timelineColumn', {
    static: false,
  })
  timelineColumn!: ElementRef;

  @ViewChild('wrapper', {
    static: false,
  })
  wrapper!: ElementRef;

  /**
   * Array of dates between minDate and maxDate used to show the dates row at the top of the timeline
   */
  get dateRange(): Date[] {
    const dates: Date[] = [];
    const currentDate: Date = new Date(this.minDate);

    while (currentDate <= this.maxDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  /**
   * If the dates have already been set, show the today line
   */
  get showTodayLine(): boolean {
    return this.minDate !== undefined && this.maxDate !== undefined;
  }

  get hostWidth(): number {
    return this.hostRef.nativeElement.offsetWidth;
  }

  get mainContainerWidth(): number {
    return this.mainColumnContainer.nativeElement.offsetWidth;
  }

  constructor(
    private transloco: TranslocoService,
    private cdr: ChangeDetectorRef,
    private hostRef: ElementRef
  ) {
    this.resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]): void => {
        if (!this.isUpdatingExpandedNotesHeight) {
          for (const entry of entries) {
            if (entry.target === this.mainColumnContainer.nativeElement) {
              if (this.mainColumnContainerWidth !== entry.contentRect.width) {
                this.expandedProblem = undefined;
                this.expandedNotesHeight = undefined;
                cdr.detectChanges();
              }
              this.mainColumnContainerWidth = entry.contentRect.width;
            } else if (
              this._notes &&
              entry.target === this._notes.nativeElement
            ) {
              this.mainColumnContainerWidth = entry.contentRect.width;
            }
          }
        }
      }
    );
  }

  /*************************** Ng lifecycle callbacks /****************************/

  ngOnInit(): void {
    this.selectedDateSubscription = this.selectedDate.subscribe(
      this.scrollToDate
    );

    this.updateRange();
    this.updateTodayLinePosition();

    this.positionUpdateInterval = setInterval(() => {
      this.updateAllItems();
    }, positionUpdateIntervalTime);

    this.isDesktopSized = this.desktopSizeMatcher.matches;

    this.desktopSizeMatcher.addEventListener(
      'change',
      this.onDesktopSizeChanged
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.mainColumnContainer?.nativeElement) {
        this.resizeObserver.observe(this.mainColumnContainer.nativeElement);
      }
    }, 100); // TODO(nico): verificare se esiste un metodo più elegante
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['startDate'] || changes['endDate']) {
      this.updateAllItems();
      const scrollDate = this.initialScrollDate;

      if (
        scrollDate &&
        this.minDate < scrollDate &&
        this.maxDate > scrollDate
      ) {
        setTimeout(() => {
          this.scrollToDate(scrollDate);
        });
      }
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.positionUpdateInterval);

    this.desktopSizeMatcher.removeEventListener(
      'change',
      this.onDesktopSizeChanged
    );

    this.selectedDateSubscription?.unsubscribe();
  }

  /*************************** Callbacks /****************************/

  private onDesktopSizeChanged = (event: MediaQueryListEvent): void => {
    this.isDesktopSized = event.matches;
  };

  iconForProblemType = (problemType: ProfessionalCategory): NateaIconsName =>
    problemType !== ProfessionalCategory.Nursing ? 'medico' : 'infermiere';

  problemTypeClass = (
    problemType: ProfessionalCategory | undefined
  ): string => {
    if (!problemType) {
      return '';
    }
    return problemType !== ProfessionalCategory.Nursing ? 'medical' : 'nursing';
  };

  private calculateProblemLeftPosition = (
    problem: PatientProblem
  ): number | undefined => {
    if (!problem.startDate) {
      return undefined;
    }
    return datePercentageInRange(problem.startDate, this.minDate, this.maxDate);
  };

  private calculateProblemWidth = (
    problem: PatientProblem,
    leftPosition?: number
  ): number | undefined => {
    if (!problem.startDate || leftPosition === undefined) {
      return undefined;
    }

    const now: Date = new Date();
    let endTime: Date = problem.endDate ?? new Date();
    if (endTime > now) {
      // TODO notify problem with logs
      endTime = now;
    }

    const endPosition: number | undefined = datePercentageInRange(
      endTime,
      this.minDate,
      this.maxDate
    );
    return endPosition ? endPosition - leftPosition : undefined;
  };

  private updateAllItems = (): void => {
    this.updateRange();
    this.updateTodayLinePosition();
    this.updatePositionedProblems();
  };

  onConsultation(problem: PatientProblem) {
    this.consultation.emit(problem);
  }

  /**
   *
  /// Calculate the problems left position and width in the timeline
   * @param problems The problems to position in the timeline. If undefined, the current positioned problems will be used
   */
  private updatePositionedProblems = (problems?: PatientProblem[]): void => {
    const currentProblems: PatientProblem[] =
      problems ?? this.positionedProblems;

    this.positionedProblems = currentProblems.map(
      (problem: PatientProblem): PositionedProblem => {
        // Calculate hours between start and end date
        const finishDate: Date = problem.endDate ?? new Date();
        const problemDuration: number | undefined = problem.startDate
          ? (finishDate.getTime() - problem.startDate.getTime()) /
            millisInAMinute
          : undefined;

        const leftPosition: number | undefined =
          this.calculateProblemLeftPosition(problem);
        return {
          ...problem,
          leftPositionPercentage: leftPosition,
          width: this.calculateProblemWidth(problem, leftPosition),
          durationMinutes: problemDuration,
        };
      }
    );

    this.canAddPaddingBottomToProblemContainer.emit(
      this.positionedProblems.length === 0
    );
  };

  private updateTodayLinePosition = (): void => {
    const todayStart: Date = new Date();
    todayStart.setHours(0, 0, 0, 0);

    const todayEnd: Date = new Date();
    todayEnd.setHours(23, 59, 59, 999);

    this.todayLineLeftPosition = datePercentageInRange(
      new Date(),
      this.minDate,
      this.maxDate
    );
  };

  private updateRange = (): void => {
    if (this.startDate) {
      const newMinDate: Date = new Date(this.startDate);
      newMinDate.setHours(0, 0, 0, 0);
      this.minDate = newMinDate;
    }

    const newMaxDate: Date = new Date(this.endDate?.getTime() ?? Date.now());
    newMaxDate.setHours(23, 59, 59, 999);
    this.maxDate = newMaxDate;
  };

  onTimelineScroll(event: Event): void {
    // Get the left scroll offset of the timeline
    this.scrollHorizontalOffset = (event.target as HTMLElement).scrollLeft;
  }

  toggleNote = (problem: PatientProblem): void => {
    /***
     * Workaround used to prevent that the resize event management would close the expanded note
     * before the height of the note is updated
     */
    this.isUpdatingExpandedNotesHeight = true;

    this.expandedProblem =
      this.expandedProblem === problem ? undefined : problem;
    this.cdr.detectChanges();
    setTimeout(() => {
      this.isUpdatingExpandedNotesHeight = false;
    });
  };

  closeNote = () => {
    this.expandedProblem = undefined;
  };

  /**
   * Invoked when a notes element changes status (pass from expanded to compressed or viceversa)
   */
  private onNoteElementChanged(note: ElementRef | undefined): void {
    setTimeout(() => {
      if (note) {
        this.expandedNotesHeight =
          note.nativeElement.offsetHeight + this.closeNoteButtonHeightPx;
      } else {
        this.expandedNotesHeight = undefined;
      }
      console.log('expandedNotesHeight', this.expandedNotesHeight);
    });
  }

  durationLabelForProblemRow = (problem: PositionedProblem): string => {
    const durationMinutes: number | undefined = problem.durationMinutes;
    if (durationMinutes === undefined || durationMinutes < 0) {
      return '';
    }

    const label: string = problem.endDate
      ? ''
      : this.transloco.translate('problems.onGoingFromPrefix');

    const durLabel: string[] = [];

    if (durationMinutes < 1) {
      durLabel.push(this.transloco.translate('problems.durationZeroLabel'));
    } else {
      const {
        days,
        hours,
        minutes,
      }: { days: number; hours: number; minutes: number } =
        daysHoursMinutesFromDurationMinutes(durationMinutes);

      if (days) {
        durLabel.push(
          this.transloco.translate('problems.durationDaysLabel', {
            days: `${days}`.padStart(2, '0'),
          })
        );
      }
      if (hours) {
        durLabel.push(
          this.transloco.translate('problems.durationHoursLabel', {
            hours: `${hours}`.padStart(2, '0'),
          })
        );
      }
      if (minutes) {
        durLabel.push(
          this.transloco.translate('problems.durationMinutesLabel', {
            minutes: `${minutes}`.padStart(2, '0'),
          })
        );
      }
    }
    return label + durLabel.join(' ');
  };

  private scrollToDate = (date: Date): void => {
    // Ensure that the date is at the start of the day
    date.setHours(0);

    const scrollPercentage: number | undefined = datePercentageInRange(
      date,
      // Start of the minDate
      new Date(
        this.minDate.getFullYear(),
        this.minDate.getMonth(),
        this.minDate.getDate(),
        0
      ),
      // End of the maxDate
      new Date(
        this.maxDate.getFullYear(),
        this.maxDate.getMonth(),
        this.maxDate.getDate(),
        23,
        59,
        59
      )
    );

    if (scrollPercentage != undefined) {
      // Get the available scroll space
      const mainColumnScrollWidth = this.wrapper.nativeElement.scrollWidth;
      const mainColumnOffsetWidth = this.wrapper.nativeElement.offsetWidth;
      // Used to center the date in the timeline
      const centerOffset = (mainColumnOffsetWidth ?? 0) / 2;

      const scrollLeftPx =
        (scrollPercentage * mainColumnScrollWidth) / 100 - centerOffset;

      this.wrapper.nativeElement.scrollLeft = scrollLeftPx;
    }
  };

  showProblemStartDate = (problem: PositionedProblem): boolean =>
    problem.startDate !== undefined && problem.startDate >= this.minDate;

  now = (): Date => new Date();

  isToday = (date: Date): boolean => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  onInfoClick = (problem: PatientProblem) => {
    this.info.emit(problem);

    // TODO: remove this after Demo

    if (problem.id === 'detectedProblem3') {
      window.open(
        'https://it.wikipedia.org/wiki/Malattia_da_membrane_ialine_polmonari',
        '_blank'
      );
    } else if (problem.id === 'detectedProblem0') {
      window.open('https://it.wikipedia.org/wiki/Parto_pretermine', '_blank');
    } else if (problem.id === 'detectedProblem1') {
      window.open(
        'https://it.wikipedia.org/wiki/Ittero#Ittero_neonatale',
        '_blank'
      );
    } else if (problem.id === 'detectedProblem2') {
      window.open(
        'https://it.wikipedia.org/wiki/Sindrome_da_distress_respiratorio',
        '_blank'
      );
    }
  };
}
