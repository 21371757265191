const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY: number = HOURS_IN_DAY * MINUTES_IN_HOUR;

export const hoursAndMinutesFromDurationMinutes = (
  durationMinutes: number
): { hours: number; minutes: number } | undefined => {
  return {
    hours: Math.floor(durationMinutes / MINUTES_IN_HOUR),
    minutes: Math.floor(durationMinutes % MINUTES_IN_HOUR),
  };
};

export const daysAndHoursFromDurationMinutes = (
  durationMinutes: number
): { days: number; hours: number } => {
  const days: number = Math.floor(durationMinutes / MINUTES_IN_DAY);
  const hours: number = Math.floor(
    (durationMinutes % MINUTES_IN_DAY) / MINUTES_IN_HOUR
  );
  return {
    days,
    hours,
  };
};

export const daysHoursMinutesFromDurationMinutes = (
  durationMinutes: number
): { days: number; hours: number; minutes: number } => {
  const deltaHours: number = Math.floor(durationMinutes / MINUTES_IN_HOUR);
  const minutes: number = Math.floor(durationMinutes % MINUTES_IN_HOUR);
  const days: number = Math.floor(deltaHours / HOURS_IN_DAY);
  const hours: number = deltaHours % HOURS_IN_DAY;
  return {
    days,
    hours,
    minutes,
  };
};
