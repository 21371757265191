import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SizeType, matchMediaDesktopSize } from 'natea-components';
import { textAndIcon } from './mocks/texts-and-optional-icons';
import { AlgnSimple, TextsAndOptionsIcons } from './models/legend-content';

@Component({
  selector: 'natea-cc-legend-modal',
  templateUrl: './legend-modal.component.html',
  styleUrls: ['./legend-modal.component.scss'],
})
export class LegendModalComponent implements OnInit, OnDestroy {
  isDesktopSized = false;
  @Input() title = '';

  @Input() isSmallModal = false;
  @Input() isSimpleText = false;

  @Input() alignText: AlgnSimple = 'center';

  @Input() TextData = '';
  @Input() isTextAndIcon = false;

  @Input() isTable = false;

  @Input() dataSource: TextsAndOptionsIcons[] = [];
  @Input() showFooter = true;

  @Output() OnClose: EventEmitter<void> = new EventEmitter<void>();
  textAndOptionalIcons = textAndIcon;

  defaultPrimaryColor = '#038095';

  private desktopSizeMatcher: MediaQueryList = matchMediaDesktopSize(window);

  ngOnInit(): void {
    this.isDesktopSized = this.desktopSizeMatcher.matches;
    this.desktopSizeMatcher.addEventListener(
      'change',
      this.onDesktopSizeChanged
    );
  }

  ngOnDestroy(): void {
    this.desktopSizeMatcher.removeEventListener(
      'change',
      this.onDesktopSizeChanged
    );
  }

  close = () => {
    this.OnClose.emit();
  };

  private onDesktopSizeChanged = (event: MediaQueryListEvent): void => {
    this.isDesktopSized = event.matches;
  };

  get modalSize(): SizeType {
    if (this.isSmallModal) {
      return SizeType.SMALL;
    } else {
      return this.isDesktopSized ? SizeType.MEDIUM : SizeType.LARGE;
    }
  }
}
