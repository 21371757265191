import { Sort } from '@angular/material/sort';
import { ActionReducer, Action, createReducer, on } from '@ngrx/store';
import {
  closeSuspensionModal,
  confirmAbortInstrumentalTherapy,
  confirmAbortInstrumentalTherapySuccess,
  confirmDeleteInstrumentalTherapy,
  confirmDeleteInstrumentalTherapyFailure,
  confirmDeleteInstrumentalTherapySuccess,
  confirmInstrumentalTherapySuspension,
  createInstrumentalTherapy,
  createInstrumentalTherapyFailure,
  createInstrumentalTherapySuccess,
  deselectInstrumentalTherapyOccurrence,
  downloadInstrumentalTherapyTypes,
  downloadInstrumentalTherapyTypesFailure,
  downloadInstrumentalTherapyTypesSuccess,
  editInstrumentalTherapy,
  editInstrumentalTherapyFailure,
  editInstrumentalTherapySuccess,
  hideConfirmAbortModal,
  hideCreateNewInstrumentalTherapyModal,
  hideDeleteConfirmationModal,
  instrumentalTherapySuspensionSuccess,
  instrumentalTherapyTabChange,
  loadInstrumentalTherapies,
  loadInstrumentalTherapiesFailure,
  loadInstrumentalTherapiesSuccess,
  selectInstrumentalTherapyFromTimeline,
  selectInstrumentalTherapyOccurrence,
  selectInstrumentalTherapySuspensionCandidate,
  showConfirmAbortInstrumentalTherapyModal,
  showCreateNewInstrumentalTherapyModal,
  showDeleteConfirmationModal,
  sortInstrumentalTherapies,
} from './instrumental-therapies.actions';
import { SelectedTherapyOccurrenceIds } from '../../common/entities/selected-therapy';
import { InstrumentalTherapyType } from '../entities/instrumental-therapy-type';

export interface InstrumentalTherapiesUiState {
  isLoadingData: boolean;
  isLoadingTypes: boolean;
  sort?: Sort;
  selectedTherapyOccurrence?: SelectedTherapyOccurrenceIds;

  candidateForDeletionId?: string;

  showCreateNewModal: boolean;
  isCreatingNew: boolean;
  tabIndex: number;

  therapyTypes?: InstrumentalTherapyType[];
  selectedTherapyOccurrenceInTable?: boolean;
  changingTab?: boolean;

  isUpdating: boolean;

  candidateForSuspensionId?: string;
  isSuspensionRunning: boolean;

  isShowDeleteConfirmationModal: boolean;
  isDeleteRunning: boolean;

  isShowConfirmAbortModal: boolean;
  isRequestAbortTherapyRunning: boolean;
  showSuspendModal: boolean;
}

export const initialInstrumentalTherapiesUiState: InstrumentalTherapiesUiState =
  {
    isLoadingData: false,
    isLoadingTypes: false,
    showCreateNewModal: false,
    isCreatingNew: false,
    tabIndex: 0,
    selectedTherapyOccurrenceInTable: undefined,
    changingTab: undefined,
    isSuspensionRunning: false,
    isShowDeleteConfirmationModal: false,
    isDeleteRunning: false,
    isShowConfirmAbortModal: false,
    isRequestAbortTherapyRunning: false,
    isUpdating: false,
    showSuspendModal: false,
  };

export const instrumentalTherapiesUiReducer: ActionReducer<
  InstrumentalTherapiesUiState,
  Action
> = createReducer(
  initialInstrumentalTherapiesUiState,
  on(
    loadInstrumentalTherapies,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isLoadingData: true,
    })
  ),
  on(
    loadInstrumentalTherapiesSuccess,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isLoadingData: false,
      selectedTherapyOccurrenceInTable: undefined,
      selectedTherapyOccurrence: undefined,
    })
  ),
  on(
    loadInstrumentalTherapiesFailure,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isLoadingData: false,
    })
  ),
  on(
    sortInstrumentalTherapies,
    (
      state: InstrumentalTherapiesUiState,
      { sort }
    ): InstrumentalTherapiesUiState => ({
      ...state,
      sort,
    })
  ),
  on(
    selectInstrumentalTherapyOccurrence,
    (
      state: InstrumentalTherapiesUiState,
      { therapyId, occurrenceId }
    ): InstrumentalTherapiesUiState => ({
      ...state,
      selectedTherapyOccurrence: {
        therapyId,
        occurrenceId,
      },
      selectedTherapyOccurrenceInTable: true,
      changingTab: false,
    })
  ),

  on(
    deselectInstrumentalTherapyOccurrence,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      selectedTherapyOccurrence: undefined,
      selectedTherapyOccurrenceInTable: undefined,
      changingTab: undefined,
    })
  ),

  on(
    showCreateNewInstrumentalTherapyModal,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      showCreateNewModal: true,
    })
  ),
  on(
    hideCreateNewInstrumentalTherapyModal,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      showCreateNewModal: false,
    })
  ),

  on(
    createInstrumentalTherapy,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isCreatingNew: true,
    })
  ),

  on(
    createInstrumentalTherapySuccess,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isCreatingNew: false,
      showCreateNewModal: false,
    })
  ),
  on(
    createInstrumentalTherapyFailure,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isCreatingNew: false,
    })
  ),

  on(
    instrumentalTherapyTabChange,
    (
      state: InstrumentalTherapiesUiState,
      { tabIndex }
    ): InstrumentalTherapiesUiState => {
      if (tabIndex === 0) {
        return {
          ...state,
          tabIndex,
          selectedTherapyOccurrence: undefined,
          selectedTherapyOccurrenceInTable: undefined,
          changingTab: undefined,
        };
      } else {
        return {
          ...state,
          tabIndex,
        };
      }
    }
  ),
  on(
    selectInstrumentalTherapyFromTimeline,
    (
      state: InstrumentalTherapiesUiState,
      { selectedTherapyOccurrenceIds }
    ): InstrumentalTherapiesUiState => ({
      ...state,
      tabIndex: 1,
      selectedTherapyOccurrence: {
        therapyId: selectedTherapyOccurrenceIds.therapyId,
        occurrenceId: selectedTherapyOccurrenceIds.occurrenceId,
      },
      selectedTherapyOccurrenceInTable: false,
      changingTab: state.tabIndex !== 1,
    })
  ),
  on(
    downloadInstrumentalTherapyTypes,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isLoadingTypes: true,
    })
  ),
  on(
    downloadInstrumentalTherapyTypesSuccess,
    (
      state: InstrumentalTherapiesUiState,
      { types }
    ): InstrumentalTherapiesUiState => ({
      ...state,
      isLoadingTypes: false,
      therapyTypes: types,
    })
  ),
  on(
    downloadInstrumentalTherapyTypesFailure,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isLoadingTypes: false,
    })
  ),

  on(
    showDeleteConfirmationModal,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isShowDeleteConfirmationModal: true,
    })
  ),
  on(
    hideDeleteConfirmationModal,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isShowDeleteConfirmationModal: false,
    })
  ),
  on(
    confirmDeleteInstrumentalTherapy,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isDeleteRunning: true,
    })
  ),

  on(
    confirmDeleteInstrumentalTherapySuccess,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isDeleteRunning: false,
      isShowDeleteConfirmationModal: false,
      selectedTherapyOccurrence: undefined,
    })
  ),

  on(
    confirmDeleteInstrumentalTherapyFailure,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isDeleteRunning: false,
    })
  ),
  on(
    showConfirmAbortInstrumentalTherapyModal,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isShowConfirmAbortModal: true,
    })
  ),

  on(
    hideConfirmAbortModal,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isShowConfirmAbortModal: false,
    })
  ),
  on(
    confirmAbortInstrumentalTherapy,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isRequestAbortTherapyRunning: true,
    })
  ),

  on(
    confirmAbortInstrumentalTherapySuccess,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isRequestAbortTherapyRunning: false,
      isShowConfirmAbortModal: false,
    })
  ),

  /******************** EDIT ********************/

  on(
    editInstrumentalTherapy,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isUpdating: true,
    })
  ),
  on(
    editInstrumentalTherapySuccess,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isUpdating: false,
    })
  ),
  on(
    editInstrumentalTherapyFailure,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isUpdating: false,
    })
  ),

  on(
    selectInstrumentalTherapySuspensionCandidate,
    (
      state: InstrumentalTherapiesUiState,
      { therapyId }
    ): InstrumentalTherapiesUiState => ({
      ...state,
      candidateForSuspensionId: therapyId,
      showSuspendModal: true,
    })
  ),
  on(
    closeSuspensionModal,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      showSuspendModal: false,
    })
  ),
  on(
    instrumentalTherapySuspensionSuccess,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      showSuspendModal: false,
      isSuspensionRunning: false,
    })
  ),
  on(
    confirmInstrumentalTherapySuspension,
    (state: InstrumentalTherapiesUiState): InstrumentalTherapiesUiState => ({
      ...state,
      isSuspensionRunning: true,
    })
  )
);
