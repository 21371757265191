import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { TherapyOccurrenceState } from '../../../../therapies/common/entities/therapy-occurrence';
import {
  filterTherapiesDate,
  filterTherapiesState,
} from './instrumental-therapies-administration.action';

export interface InstrumentalTherapiesAdministrationUiState {
  filterState?: TherapyOccurrenceState;
  filterDate?: Date;
}

export const instrumentalTherapiesAdministrationInitialState: InstrumentalTherapiesAdministrationUiState =
  {
    filterState: undefined,
    filterDate: undefined,
  };

export const instrumentalTherapiesAdministrationUiReducer: ActionReducer<
  InstrumentalTherapiesAdministrationUiState,
  Action
> = createReducer(
  instrumentalTherapiesAdministrationInitialState,

  on(
    filterTherapiesState,
    (
      state: InstrumentalTherapiesAdministrationUiState,
      { therapyOccurrenceState }
    ): InstrumentalTherapiesAdministrationUiState => ({
      ...state,
      filterState: therapyOccurrenceState,
    })
  ),
  on(
    filterTherapiesDate,
    (
      state: InstrumentalTherapiesAdministrationUiState,
      { date }
    ): InstrumentalTherapiesAdministrationUiState => ({
      ...state,
      filterDate: date,
    })
  )
);
