<section class="first-section" *transloco="let t; read: 'common.mainHeader'">
  <p>
    <span class="label-style">{{ t("bedRoom") }}</span>
    <span class="label-value">{{ patient?.currentBed }}</span>
  </p>
  <p>
    <span class="label-style">{{ t("yearsMonthsDays") }}:</span>
    <span class="label-value">{{ formattedAge() }}</span>
  </p>
  <p>
    <span class="label-style">{{ t("weightBirth") }}</span>
    <span class="label-value">{{
      patient ? patient.measurements?.weight + " " + "kg" : ""
    }}</span>
  </p>
  <p>
    <span class="label-style">{{ t("lastWeight") }}</span>
    <span class="label-value">{{
      patient ? patient.measurements?.lastWeight + " " + "kg" : ""
    }}</span>
  </p>
  <p>
    <span class="label-style">{{ t("weightLoss") }}</span>
    <span class="label-value">{{
      patient ? patient.measurements?.weightLoss + " " + "%" : ""
    }}</span>
  </p>
  <p>
    <span class="label-style">{{ t("dateOfBirth") }}</span>
    <span class="label-value">{{
      patient?.birthDate
        ? (patient!.birthDate | localizedDate : LocalizedDateFormat.Date)
        : ""
    }}</span>
  </p>
  <p>
    <span class="label-style">{{ t("EG") }}</span>
    <span class="label-value">{{
      patient?.measurements ? patient!.measurements!.eg : ""
    }}</span>
  </p>
  <p>
    <span class="label-style">{{ t("epcEc") }}</span>
    <span class="label-value">{{
      patient ? patient.measurements?.epcEc : ""
    }}</span>
  </p>
  <p>
    <span class="label-style">{{ t("dateLastWeight") }} </span>
    <span class="label-value">{{
      patient?.measurements?.lastWeightDate
        ? (patient!.measurements!.lastWeightDate!
          | localizedDate : LocalizedDateFormat.Date)
        : ""
    }}</span>
  </p>
</section>
