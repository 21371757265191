import { ProfessionalCategory } from '../../../../shared/entities/professional-category';
import { mockEncounterDay } from '../../../../shared/utils/mock-utils';
import { randomId } from '../../../../shared/utils/utils';
import {
  TherapyDurationType,
  TherapyOccurrenceState,
} from '../../common/entities/therapy-occurrence';
import { baseTherapyMock } from '../../common/mocks/therapy.mock';
import {
  EnteralTherapy,
  NutritionalTherapy,
  ParenteralTherapy,
} from '../entities/nutritional-therapy';
import { FeedingType } from '../entities/nutritional-therapy-type';
import { nutritionalTherapiesCatalogsMocks } from './nutritional-therapy-catalogs.mocks';

export const nutritionalTherapiesListMock = (): NutritionalTherapy[] => {
  const createList = (): NutritionalTherapy[] => {
    return [
      {
        // RLE enteral: materno
        ...baseEnteralTherapyMock,

        id: randomId(),
        prescriptionDate: mockEncounterDay(1, 11),
        //        programmingDate: mockEncounterDay(1, 16),
        problem: {
          id: randomId(),
          name: 'Prematurità minore di 32 settimane',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        feedingType: {
          id: randomId(),
          name: 'Materno',
        },
        occurrences: [
          // Day 2
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(2, 8),
            executionDate: mockEncounterDay(2, 8),
            terminationDate: mockEncounterDay(2, 8),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(2, 13),
            executionDate: mockEncounterDay(2, 13),
            terminationDate: mockEncounterDay(2, 13),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(2, 18),
            executionDate: mockEncounterDay(2, 18),
            terminationDate: mockEncounterDay(2, 18),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          // Day 3
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(3, 8),
            executionDate: mockEncounterDay(3, 8),
            terminationDate: mockEncounterDay(3, 8),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(3, 13),
            executionDate: mockEncounterDay(3, 13),
            terminationDate: mockEncounterDay(3, 13),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(3, 18),
            executionDate: mockEncounterDay(3, 18),
            terminationDate: mockEncounterDay(3, 18),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          // Day 4
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(4, 8),
            executionDate: mockEncounterDay(4, 8),
            terminationDate: mockEncounterDay(4, 8),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(4, 13),
            executionDate: mockEncounterDay(4, 13),
            terminationDate: mockEncounterDay(4, 13),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(4, 18),
            executionDate: mockEncounterDay(4, 18),
            terminationDate: mockEncounterDay(4, 18),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          // Day 5
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(5, 8),
            executionDate: mockEncounterDay(5, 8),
            terminationDate: mockEncounterDay(5, 8),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(5, 13),
            executionDate: mockEncounterDay(5, 13),
            terminationDate: mockEncounterDay(5, 13),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(5, 18),
            executionDate: mockEncounterDay(5, 18),
            terminationDate: mockEncounterDay(5, 18),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          // Day 6
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(6, 8),
            executionDate: mockEncounterDay(6, 8),
            terminationDate: mockEncounterDay(6, 8),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(6, 13),
            executionDate: mockEncounterDay(6, 13),
            terminationDate: mockEncounterDay(6, 13),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(6, 18),
            executionDate: mockEncounterDay(6, 18),
            terminationDate: mockEncounterDay(6, 18),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          // Day 7
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(7, 8),
            executionDate: mockEncounterDay(7, 8),
            terminationDate: mockEncounterDay(7, 8),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(7, 13),
            executionDate: mockEncounterDay(7, 13),
            terminationDate: mockEncounterDay(7, 13),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(7, 18),
            executionDate: mockEncounterDay(7, 18),
            terminationDate: mockEncounterDay(7, 18),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          // Day 8
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(8, 8),
            executionDate: mockEncounterDay(8, 8),
            terminationDate: mockEncounterDay(8, 8),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(8, 13),
            executionDate: mockEncounterDay(8, 13),
            terminationDate: mockEncounterDay(8, 13),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(8, 18),
            executionDate: mockEncounterDay(8, 18),
            terminationDate: mockEncounterDay(8, 18),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          // Day 9
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(9, 8),
            executionDate: mockEncounterDay(9, 8),
            terminationDate: mockEncounterDay(9, 8),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(9, 13),
            executionDate: mockEncounterDay(9, 13),
            terminationDate: mockEncounterDay(9, 13),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(9, 18),
            executionDate: mockEncounterDay(9, 18),
            terminationDate: mockEncounterDay(9, 18),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          // Day 10
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(10, 8),
            executionDate: mockEncounterDay(10, 8),
            terminationDate: mockEncounterDay(10, 8),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: mockEncounterDay(10, 18),
            executionDate: mockEncounterDay(10, 18),
            terminationDate: mockEncounterDay(10, 18),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: { ...baseEnteralTherapyEntryMock },
            },
            weightKg: '10',
          },
        ],
      },
      {
        // RLE enteral: materno / formula
        ...baseEnteralTherapyMock,

        id: randomId(),
        prescriptionDate: mockEncounterDay(1, 11),
        //        programmingDate: mockEncounterDay(1, 16),
        problem: {
          id: randomId(),
          name: 'Prematurità minore di 32 settimane',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        feedingType: {
          id: randomId(),
          name: 'Materno / Formula',
        },
        occurrences: [
          // Day 8
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(8, 22),
            executionDate: mockEncounterDay(8, 22),
            terminationDate: mockEncounterDay(8, 22),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: {
                ...baseEnteralTherapyEntryMock,
                milkType: nutritionalTherapiesCatalogsMocks.breastMilkTypes[3],
              },
            },
            weightKg: '10',
          },
          // Day 9
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(9, 22),
            executionDate: mockEncounterDay(9, 22),
            terminationDate: mockEncounterDay(9, 22),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: {
                ...baseEnteralTherapyEntryMock,
                milkType: nutritionalTherapiesCatalogsMocks.breastMilkTypes[3],
              },
            },
            weightKg: '10',
          },
        ],
      },
      {
        // RLE enteral: formula
        ...baseEnteralTherapyMock,

        id: randomId(),
        prescriptionDate: mockEncounterDay(1, 11),
        //        programmingDate: mockEncounterDay(1, 16),
        problem: {
          id: randomId(),
          name: 'Prematurità minore di 32 settimane',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        feedingType: {
          id: randomId(),
          name: 'Formula',
        },
        occurrences: [
          // Day 10
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.InExecution,
            programmingDate: mockEncounterDay(10, 13),
            executionDate: mockEncounterDay(10, 13),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            additionalInfo: {
              entry: {
                ...baseEnteralTherapyEntryMock,
                milkType: nutritionalTherapiesCatalogsMocks.breastMilkTypes[0],
              },
            },
            weightKg: '10',
          },
        ],
      },
      {
        // RLE parenteral
        ...baseParenteralTherapyMock,
        prescriptionDate: mockEncounterDay(1, 8, 30),
        programmingDate: mockEncounterDay(1, 10),
        id: randomId(),
        problem: {
          id: '2',
          name: 'Ittero in prematuro',
          description: 'Mal di testa',
          valid: true,
          problemClass: ProfessionalCategory.Medical,
        },
        contributions: [
          {
            id: randomId(),
            name: 'Proteine',
            enteral: 10,
            parenteral: 20,
            medicine: 30,
            total: 60,
          },
        ],
        duration: 10,
        repetition: {
          id: randomId(),
          intervalSpanMinutes: 60,
          label: 'Ogni ora',
        },
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            isValid: true,
            durationType: TherapyDurationType.Prolonged,
            weightKg: '10',
            programmingDate: mockEncounterDay(1, 10),
            executionDate: mockEncounterDay(1, 10),
            terminationDate: mockEncounterDay(2, 9, 59),
            state: TherapyOccurrenceState.Administered,
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            isValid: true,
            durationType: TherapyDurationType.Prolonged,
            weightKg: '10',
            programmingDate: mockEncounterDay(2, 10),
            executionDate: mockEncounterDay(2, 10),
            terminationDate: mockEncounterDay(3, 9, 59),
            state: TherapyOccurrenceState.Administered,
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            isValid: true,
            durationType: TherapyDurationType.Prolonged,
            weightKg: '10',
            programmingDate: mockEncounterDay(3, 10),
            executionDate: mockEncounterDay(3, 10),
            terminationDate: mockEncounterDay(4, 9, 59),
            state: TherapyOccurrenceState.Administered,
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            isValid: true,
            durationType: TherapyDurationType.Prolonged,
            weightKg: '10',
            programmingDate: mockEncounterDay(4, 10),
            executionDate: mockEncounterDay(4, 10),
            terminationDate: mockEncounterDay(5, 9, 59),
            state: TherapyOccurrenceState.Administered,
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            isValid: true,
            durationType: TherapyDurationType.Prolonged,
            weightKg: '10',
            programmingDate: mockEncounterDay(5, 10),
            executionDate: mockEncounterDay(5, 10),
            terminationDate: mockEncounterDay(6, 9, 59),
            state: TherapyOccurrenceState.Administered,
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            isValid: true,
            durationType: TherapyDurationType.Prolonged,
            weightKg: '10',
            programmingDate: mockEncounterDay(6, 10),
            executionDate: mockEncounterDay(6, 10),
            terminationDate: mockEncounterDay(7, 9, 59),
            state: TherapyOccurrenceState.Administered,
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            isValid: true,
            durationType: TherapyDurationType.Prolonged,
            weightKg: '10',
            programmingDate: mockEncounterDay(7, 10),
            executionDate: mockEncounterDay(7, 10),
            terminationDate: mockEncounterDay(8, 9, 59),
            state: TherapyOccurrenceState.Administered,
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            isValid: true,
            durationType: TherapyDurationType.Prolonged,
            weightKg: '10',
            programmingDate: mockEncounterDay(8, 10),
            executionDate: mockEncounterDay(8, 10),
            terminationDate: mockEncounterDay(9, 9, 59),
            state: TherapyOccurrenceState.Administered,
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            isValid: true,
            durationType: TherapyDurationType.Prolonged,
            weightKg: '10',
            programmingDate: mockEncounterDay(9, 10),
            executionDate: mockEncounterDay(9, 10),
            terminationDate: mockEncounterDay(10, 9, 59),
            state: TherapyOccurrenceState.Administered,
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            isValid: true,
            durationType: TherapyDurationType.Prolonged,
            weightKg: '10',
            programmingDate: mockEncounterDay(10, 10),
            executionDate: mockEncounterDay(10, 10),
            terminationDate: mockEncounterDay(11, 9, 59),
            state: TherapyOccurrenceState.Administered,
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            isValid: true,
            durationType: TherapyDurationType.Prolonged,
            weightKg: '10',
            programmingDate: mockEncounterDay(11, 10),
            executionDate: mockEncounterDay(11, 10),
            state: TherapyOccurrenceState.Prepared,
          },
        ],
      },
    ];
  };

  const list = createList();
  list.forEach((item) => {
    const id = randomId();
    item.id = id;
    item.occurrences.forEach((occurrence) => {
      occurrence.id = randomId();
      occurrence.prescriptionId = id;
    });
  });
  return [...list];
};

export const baseParenteralTherapyMock: ParenteralTherapy = {
  ...baseTherapyMock,
  category: 'parenteral',
  categoryLabel: 'Parenterale',
  canBeDeleted: true,
  canBeAborted: true,

  id: randomId(),
  name: 'Magnesio',
  problem: {
    id: '2',
    name: 'Cefalea',
    description: 'Mal di testa',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  contributions: [
    {
      id: randomId(),
      name: 'Lipidi',
      enteral: 10,
      parenteral: 5,
      medicine: 0,
      total: 15,
    },
  ],
  forDays: 5,
  osmolarity: 10,
  weight: 10,
  totalVolume: 100,
  duration: 10,
  repetition: {
    id: randomId(),
    intervalSpanMinutes: 60,
    label: 'Ogni ora',
  },
  notes: 'Note',
  occurrences: [
    {
      id: randomId(),
      prescriptionId: randomId(),
      isValid: true,
      durationType: TherapyDurationType.Prolonged,
      weightKg: '10',
      programmingDate: new Date('2021-01-01'),
      state: TherapyOccurrenceState.InExecution,
    },
  ],
};

export const baseEnteralTherapyMock: EnteralTherapy = {
  ...baseTherapyMock,
  category: 'enteral',
  categoryLabel: 'Enterale',
  canBeDeleted: true,
  // canBeEdited: false,
  id: randomId(),
  name: 'Magnesio',
  problem: {
    id: '2',
    name: 'Cefalea',
    description: 'Mal di testa',
    valid: true,
    problemClass: ProfessionalCategory.Medical,
  },
  feedingType: {
    id: randomId(),
    name: 'Digiuno',
  },
  availableBreastMilk: 200,
  totalMilkToAdminister: 100,
  totalMeals: 2,
  notes: 'Note',
  occurrences: [
    {
      id: randomId(),
      prescriptionId: randomId(),
      isValid: true,
      durationType: TherapyDurationType.Prolonged,
      weightKg: '10',
      programmingDate: new Date('2021-01-01'),
      state: TherapyOccurrenceState.InExecution,
    },
  ],
  entries: [
    {
      milkType: nutritionalTherapiesCatalogsMocks.breastMilkTypes[2],
      milkBrand: {
        id: randomId(),
        name: 'Nan',
      },
      milkFormulaMotivation: {
        id: randomId(),
        name: 'Rifiuto',
      },
      doseForMeal: 100,
      durationMinutes: 150,
      feedingMethod: {
        id: randomId(),
        name: 'Biberon',
        hasLength: true,
      },
      speed: 50,
      mealsCount: 2,
      supplements: [
        {
          supplementType: {
            id: randomId(),
            name: 'Ferro',
          },
          doseGramsForHundredMilliliters: 100,
          doseGrams: 30,
        },
      ],
    },
  ],
};

export const baseEnteralTherapyEntryMock = {
  milkType: nutritionalTherapiesCatalogsMocks.breastMilkTypes[2],
  milkBrand: {
    id: randomId(),
    name: 'Nan',
  },
  milkFormulaMotivation: {
    id: randomId(),
    name: 'Rifiuto',
  },
  doseForMeal: 100,
  durationMinutes: 150,
  feedingMethod: {
    id: randomId(),
    name: 'Biberon',
    hasLength: true,
  },
  speed: 50,
  mealsCount: 2,
  supplements: [
    {
      supplementType: {
        id: randomId(),
        name: 'Ferro',
      },
      doseGramsForHundredMilliliters: 100,
      doseGrams: 30,
    },
  ],
};
// export const baseNutritionalTherapyMock: NutritionalTherapy = {
//   ...baseTherapyMock,
//   category: {
// key: 'enteral',
// label: 'Enterale'
// },
//   canBeDeleted: true,
//   id: randomId(),
//   name: 'Magnesio',
//   problem: {
//     id: '2',
//     name: 'Cefalea',
//     description: 'Mal di testa',
//     valid: true,
//     problemClass: ProfessionalCategory.Medical,
//   },

//   feedingType: {
//     id: randomId(),
//     name: 'Digiuno',
//   },
//   occurrences: [],

//   availableBreastMilk: 100,
//   totalMilkToAdminister: 100,
//   totalMeals: 3,
//   feedingType: {
//     id: '1',
//     name: 'Fasting',
//   },
//   programmingDate: new Date(),
//   problem: {
//     id: '1',
//     name: 'Cefalea',
//     description: 'Mal di testa',
//     valid: true,
//     problemClass: ProfessionalCategory.Medical,
//   },
// };

export const feedingTypesMock: FeedingType[] = [
  {
    id: '1',
    name: 'Fasting',
  },
  {
    id: '2',
    name: 'Planned',
  },
  {
    id: '3',
    name: 'Planned',
  },
];
