<mat-form-field
  class="natea-input-field"
  [color]="isError ? 'warn' : undefined"
  [class.fill-width]="fillParentWidth"
  [class.auto-width]="autoWidth"
  [class.no-hint]="hintLabel === undefined && errorMessages === undefined"
  [class.warn]="errorMessages !== undefined"
>
  <mat-label [class.disabled]="isDisabled" [class.input-filled]="isAddClass">{{
    label
  }}</mat-label>

  <mat-hint class="warn" *ngIf="errorMessages !== undefined">
    <natea-field-error [errorMessages]="errorMessages"></natea-field-error>
  </mat-hint>

  <input
    matInput
    class="natea-input"
    [type]="'text'"
    [placeholder]="placeholder ?? ''"
    (input)="inputChanged($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [formControl]="internalControl"
    nateaDurationPicker
  />

  <natea-icons
    matSuffix
    class="icon right-icon"
    [class.not-clickable]="true"
    [class.disabled]="true"
    [name]="'clock'"
  ></natea-icons>

  <mat-hint
    *ngIf="hintLabel !== undefined"
    class="assistive-text"
    [class.warn]="isError"
    [class.selected]="hasFocus"
    [class.disabled]="isDisabled"
  >
    {{ hintLabel }}
  </mat-hint>
</mat-form-field>
