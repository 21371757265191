import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService } from 'natea-components';
import { catchError, map, of, switchMap } from 'rxjs';
import { ApiError, localError } from '../../../../shared/entities/errors';
import { SNACK_BAR_DEBOUNCE_TIME_MS } from '../../../../shared/utils/constants';
import { selectOngoingEncounterAndPatientIds } from '../../../patients/store/patients.selectors';
import { ClinicalNote } from '../../interfaces/clinical-note';
import { NotesValidationWebAPI } from '../webapis/notes-validation.webapi';
import { NotesValidationActions } from './notes-validation.actions';
import { HttpStatusCode } from '@angular/common/http';
import { NewNoteFormValues } from '../../interfaces/new-note-form';

@Injectable()
export class NotesValidationEffects {
  validateClinicalNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: string;
        clinicalNoteToEvaluate: NewNoteFormValues;
      }>(NotesValidationActions.EvaluateClinicalNote),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      switchMap(([{ clinicalNoteToEvaluate }, encounterIds]) => {
        const { validation, evaluationDate, text } = clinicalNoteToEvaluate;
        if (!encounterIds) {
          return of({
            type: NotesValidationActions.ValidateClinicalNoteFailure,
            payload: { error: localError(HttpStatusCode.BadRequest) },
          });
        }

        return this.notesValidationWebAPI
          .validateClinicalNote(clinicalNoteToEvaluate)
          .pipe(
            map((value) => {
              const clinicalNoteToValidate: ClinicalNote = {
                ...value,
                isApproved: validation === '1',
                evaluationDate: evaluationDate,
                text: text ? text : value.text,
              };

              return {
                type: NotesValidationActions.ValidateClinicalNoteSuccess,
                clinicalNoteToValidate: clinicalNoteToValidate,
                isNoteValidated: clinicalNoteToEvaluate.validation === '1',
                patientId: encounterIds.patientId,
                encounterId: encounterIds.encounterId,
              };
            }),
            catchError((error: ApiError) =>
              of({
                type: NotesValidationActions.ValidateClinicalNoteFailure,
                payload: { error },
              })
            )
          );
      })
    );
  });

  validateClinicalNoteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NotesValidationActions.ValidateClinicalNoteSuccess),
        map(
          ({
            clinicalNoteToValidate,
            isNoteValidated,
          }: {
            clinicalNoteToValidate: ClinicalNote;
            isNoteValidated: boolean;
          }) => {
            const date = this.datePipe.transform(
              clinicalNoteToValidate.creationDate,
              'dd/MM/yyyy - HH:mm'
            );

            this.snackBar.showSnackbar(
              this.translocoService.translate(
                isNoteValidated
                  ? 'clinicalNote.validationNotes.successFullyValidated'
                  : 'clinicalNote.validationNotes.successFullyInvalid',
                { date }
              ),
              'ok',
              'success-snackbar',
              SNACK_BAR_DEBOUNCE_TIME_MS
            );
          }
        )
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notesValidationWebAPI: NotesValidationWebAPI,
    private snackBar: SnackbarService,
    private translocoService: TranslocoService,
    private datePipe: DatePipe,
    private store: Store
  ) {}
}
