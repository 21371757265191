import { mockEncounterDay } from 'src/app/shared/utils/mock-utils';
import { randomId } from '../../../../shared/utils/utils';
import { TherapyType } from '../../common/entities/therapy-type';
import { TherapyToConfirm } from '../entities/therapy-to-confirm';

export const baseTherapyToConfirmMock: TherapyToConfirm = {
  id: '1',
  name: 'Ibuprofene',
  // programmingDate: new Date(),
  programmingDate: mockEncounterDay(11, 10, 30), // RLE

  posology: 1,
  posologyUnit: {
    id: '1',
    name: 'mg',
    shortDescription: 'mg',
    symbol: 'mg',
  },

  totalDose: 2,
  totalDoseUnit: {
    id: '1',
    name: 'mg',
    shortDescription: 'mg',
    symbol: 'mg',
  },

  patientWeightKg: 2.38,
  patientWeightVariationPercentage: '9',

  prescribedBy: {
    id: '1',
    firstName: 'Mario',
    lastName: 'Rossi',
  },

  patientId: '1',
  encounterId: '1',

  isConfirmable: true,
  type: TherapyType.Pharmacological,
};

//const tomorrowAt10AM = () => {
//  const tomorrow = new Date();
//  tomorrow.setDate(tomorrow.getDate() + 1);
//  tomorrow.setHours(10, 0, 0, 0);
//  return tomorrow;
//};

//const dayAfterTomorrowAt10AM = () => {
//  const tomorrow = new Date();
//  tomorrow.setDate(tomorrow.getDate() + 2);
//  tomorrow.setHours(20, 0, 0, 0);
//  return tomorrow;
//};

export const therapiesToConfirmMock: TherapyToConfirm[] = [
  {
    ...baseTherapyToConfirmMock,
    id: randomId(),
    isConfirmable: false,
    // confirmableAt: Tomorrow at 10 in the morning
    programmingDate: mockEncounterDay(11, 10, 0), // RLE
    // confirmableAt: tomorrowAt10AM(),
    confirmableAt: mockEncounterDay(10, 22, 0), // RLE
  },
  {
    ...baseTherapyToConfirmMock,
    id: randomId(),
    isConfirmable: false,
    programmingDate: mockEncounterDay(12, 10, 0), // RLE
    // confirmableAt: dayAfterTomorrowAt10AM(),
    confirmableAt: mockEncounterDay(11, 22, 0), // RLE
    type: TherapyType.Transfusional,
  },
  { ...baseTherapyToConfirmMock, id: randomId() },
  {
    ...baseTherapyToConfirmMock,
    id: randomId(),
    type: TherapyType.Transfusional,
  },
];
