import { ReducerTypes, on } from '@ngrx/store';
import {
  confirmAbortInstrumentalTherapySuccess,
  confirmDeleteInstrumentalTherapySuccess,
  createInstrumentalTherapySuccess,
  editInstrumentalTherapySuccess,
  instrumentalTherapySuspensionSuccess,
  loadInstrumentalTherapiesSuccess,
} from '../../../../therapies/instrumental-therapies/store/instrumental-therapies.actions';
import { PatientsState, updateEncounterData } from '../../patients.reducers';

export const patientInstrumentalTherapiesReducerFunctions: ReducerTypes<
  PatientsState,
  [never]
>[] = [
  on(
    loadInstrumentalTherapiesSuccess,
    (state, { therapies, patientId, encounterId }) => {
      return updateEncounterData(state, { patientId, encounterId }, () => ({
        instrumentalTherapies: therapies,
      }));
    }
  ),

  on(
    createInstrumentalTherapySuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          instrumentalTherapies: [
            ...(encounterData?.instrumentalTherapies ?? []),
            therapy,
          ],
        })
      );
    }
  ),

  on(
    confirmDeleteInstrumentalTherapySuccess,
    (state, { therapyId, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          instrumentalTherapies: [
            ...(encounterData?.instrumentalTherapies ?? []).filter(
              (therapy) => therapy.id !== therapyId
            ),
          ],
        })
      );
    }
  ),

  on(
    confirmAbortInstrumentalTherapySuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => {
          return {
            instrumentalTherapies: [
              ...(encounterData?.instrumentalTherapies ?? []).map((t) =>
                t.id !== therapy.id ? t : therapy
              ),
            ],
          };
        }
      );
    }
  ),

  on(
    instrumentalTherapySuspensionSuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => {
          return {
            instrumentalTherapies: [
              ...(encounterData?.instrumentalTherapies ?? []).map((t) =>
                t.id !== therapy.id ? t : therapy
              ),
            ],
          };
        }
      );
    }
  ),

  on(
    editInstrumentalTherapySuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => {
          return {
            instrumentalTherapies: [
              ...(encounterData?.instrumentalTherapies ?? []).map((t) =>
                t.id !== therapy.id ? t : therapy
              ),
            ],
          };
        }
      );
    }
  ),
];
