import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { SizeType } from 'natea-components';
import { take } from 'rxjs';
import { EntityEvent, EntityEventField } from '../../../entities/entity-log';
import { sortLog } from '../store/log.actions';
import { selectSortedLogEntities } from '../store/log.selectors';

@Component({
  selector: 'natea-cc-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss'],
})
export class LogComponent implements OnInit {
  @Input() logDate!: Date;

  @Output() closeLog: EventEmitter<void> = new EventEmitter<void>();

  columnsToDisplay: string[] = ['date', 'type'];
  activeSort: Sort = { active: 'date', direction: 'asc' };

  dataSource$ = this.store.select(selectSortedLogEntities);

  // TODO: the first selected entity should be the current one
  // TODO: selectedEntity = this.dataSource.find(entity => entity.date === this.showModal.creationDate)
  selectedEntity?: EntityEvent;

  @Input() logTitle = '';
  modalSize: SizeType = SizeType.XLARGE;

  fields: EntityEventField[] = [];

  constructor(private store: Store) {}
  ngOnInit(): void {
    this.dataSource$.pipe(take(1)).subscribe((entities) => {
      this.selectedEntity = entities[0];
      this.fields = this.selectedEntity.fields;
    });
  }

  onCloseModal = () => {
    this.closeLog.emit();
  };

  selectEntity = (entity: EntityEvent) => {
    this.selectedEntity = entity;
    this.fields = entity.fields;
  };

  onSortChanged = (sort?: Sort): void => {
    this.activeSort = sort ?? this.activeSort;
    this.store.dispatch(sortLog({ sort }));
  };
}
