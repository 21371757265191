import { MeasureUnit } from '../../../../shared/entities/measure-unit';
import { randomId } from '../../../../shared/utils/utils';
import { PharmacologicalTherapiesCatalogs } from '../store/pharmacological-therapies.reducer';

const unitMilligrams: MeasureUnit = {
  id: '1',
  symbol: 'mg',
  name: 'milligrammi',
  shortDescription: 'millis',
};

const unitMilligramsPerKilogram: MeasureUnit = {
  id: '2',
  symbol: 'mg/kg',
  name: 'milligrammi per chilogrammo',
  shortDescription: 'millis per kilo',
};

const unitGrams: MeasureUnit = {
  id: '2',
  symbol: 'g',
  name: 'grammi',
  shortDescription: 'grams',
};

export const pharmacologicalTherapiesCatalogsMock: PharmacologicalTherapiesCatalogs =
  {
    therapyTypes: [
      /*{
        id: '1',
        name: 'Infusione continua',
        description: 'Infusione continua',
        canBePrescribedAtNeed: true,
      },*/
      /* {
        id: '2',
        name: 'Infusione intermittente',
        description: 'Infusione intermittente',
        canBePrescribedAtNeed: false,
        isDefault: true,
      },*/
      {
        id: '1',
        name: 'Amoxicillina + Acido Clavulanico', // RLE
        description: 'Amoxicillina + Acido Clavulanico', // RLE
        canBePrescribedAtNeed: true,
      },
      {
        id: '2',
        name: 'Ampicillina',
        description: 'Ampicillina',
        canBePrescribedAtNeed: false,
        isDefault: false,
      },
      {
        id: '3',
        name: 'Caffeina',
        description: 'Caffeina',
        canBePrescribedAtNeed: false,
      },
      {
        id: '4',
        name: 'Dobutamina',
        description: 'Dobutamina',
        canBePrescribedAtNeed: false,
      },
      {
        id: '5',
        name: 'Nistatina',
        description: 'Nistatina',
        canBePrescribedAtNeed: false,
      },

      {
        id: '6',
        name: 'Surfattante',
        description: 'Surfattante',
        canBePrescribedAtNeed: false,
      },

      {
        id: '7',
        name: 'Paracetamolo',
        description: 'Paracetamolo',
        canBePrescribedAtNeed: true,
      },
    ],
    modes: [
      {
        isInfusional: false,
        id: 'attacco',
        defaultAdministrationTimeMinutes: 80,
        name: 'attacco',
        therapyTypeId: '1',
        unit: unitMilligrams,
        defaultDose: 140,
        minDose: 100,
        maxDose: 180,
        maxPrescriptionDays: 3,
        isDoubleCheck: false,
        canBePrescribedAtNeed: false,
        baseUnit: unitMilligrams,
        inverseDiluentRatio: 1 / 10,
      },

      {
        isInfusional: true,
        id: 'mantenimentoEV',
        defaultAdministrationTimeMinutes: 1440,
        name: 'mantenimento EV',
        therapyTypeId: '3',
        unit: unitMilligramsPerKilogram,
        defaultDose: 2.5,
        minDose: 2.5,
        maxDose: 6,
        maxPrescriptionDays: 3,
        isDoubleCheck: false,
        canBePrescribedAtNeed: false,
        baseUnit: unitMilligrams,
        isDefault: true,
        inverseDiluentRatio: 1 / 10,
      },

      {
        isInfusional: false,
        id: 'attacco',
        defaultAdministrationTimeMinutes: 80,
        name: 'attacco',
        therapyTypeId: '3',
        unit: unitMilligramsPerKilogram,
        defaultDose: 10,
        minDose: 10,
        maxDose: 25,
        maxPrescriptionDays: 3,
        isDoubleCheck: false,
        canBePrescribedAtNeed: false,
        baseUnit: unitMilligrams,
        inverseDiluentRatio: 1 / 10,
      },

      /* {
        isInfusional: false,
        id: 'alBisogno',
        defaultAdministrationTimeMinutes: 0,
        name: 'al bisogno',
        therapyTypeId: '7',
        unit: unitMilligrams,
        defaultDose: 7.5,
        minDose: 7.5,
        maxDose: 7.5,
        maxPrescriptionDays: 3,
        isDoubleCheck: false,
        canBePrescribedAtNeed: true,
        baseUnit: unitMilligrams,
        inverseDiluentRatio: 1/10,
      },*/

      {
        isInfusional: false,
        id: 'sciroppo',
        defaultAdministrationTimeMinutes: 0,
        name: 'sciroppo',
        therapyTypeId: '7',
        unit: unitMilligrams,
        defaultDose: 24,
        minDose: 24,
        maxDose: 24,
        maxPrescriptionDays: 3,
        canBePrescribedAtNeed: true,
        isDoubleCheck: false,
        baseUnit: unitMilligrams,
        inverseDiluentRatio: 5 / 120,
        isDefault: true,
      },

      {
        isInfusional: false,
        id: randomId(),
        defaultAdministrationTimeMinutes: 0,
        name: 'attacco',
        therapyTypeId: '4',
        unit: unitMilligrams,
        defaultDose: 50,
        minDose: 24,
        maxDose: 60,
        maxPrescriptionDays: 3,
        isDoubleCheck: false,
        canBePrescribedAtNeed: false,
        baseUnit: unitMilligrams,
        inverseDiluentRatio: 5 / 120,
      },

      {
        isInfusional: false,
        id: randomId(),
        defaultAdministrationTimeMinutes: 0,
        name: 'bolo',
        therapyTypeId: '5',
        unit: unitMilligrams,
        defaultDose: 50,
        minDose: 24,
        maxDose: 60,
        maxPrescriptionDays: 3,
        isDoubleCheck: false,
        canBePrescribedAtNeed: false,
        baseUnit: unitMilligrams,
        inverseDiluentRatio: 5 / 120,
      },

      {
        isInfusional: true,
        id: randomId(),
        defaultAdministrationTimeMinutes: 0,
        name: 'infusione continua',
        therapyTypeId: '6',
        unit: unitMilligrams,
        defaultDose: 50,
        minDose: 24,
        maxDose: 60,
        maxPrescriptionDays: 3,
        isDoubleCheck: false,
        canBePrescribedAtNeed: false,
        baseUnit: unitMilligrams,

        inverseDiluentRatio: 5 / 120,
      },

      {
        isInfusional: true,
        id: randomId(),
        defaultAdministrationTimeMinutes: 23,
        name: 'infusione continua',
        therapyTypeId: '2',
        unit: unitMilligrams,
        defaultDose: 50,
        minDose: 24,
        maxDose: 60,
        maxPrescriptionDays: 3,
        isDoubleCheck: false,
        canBePrescribedAtNeed: false,
        baseUnit: unitMilligrams,
        inverseDiluentRatio: 5 / 120,
      },
    ],
    routes: [
      {
        key: 'iv',
        label: 'EV',
      },

      {
        key: 'ora',
        label: 'orale',
      },

      {
        key: 'In',
        label: 'Intramuscolo',
      },
      {
        key: 'Cut',
        label: 'Cutanea',
      },
      {
        key: 'oc',
        label: 'Oculare',
      },
      {
        key: 'Orof',
        label: 'Orofaringea',
      },
      {
        key: 'os',
        label: 'OS',
      },
    ],
    diluents: [
      {
        key: 'h2o',
        label: 'Acqua distillata',
      },
    ],
  };
