import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { matchMediaDesktopSize } from 'natea-components';
import { Observable, Subscription } from 'rxjs';
import { ConfigService } from '../../core/config/config.service';
import { showSearchPatientModal } from '../patients/store/patients.actions';
import { selectShowSearchPatientsModal } from '../patients/store/patients.selectors';
import { ProblemType } from '../problems/interfaces/problem-type';
import { loadAllProblemData } from '../problems/store/problems.actions';
import { selectAllProblemTypes } from '../problems/store/problems.selectors';
import { loadDepartments } from './store/departments.actions';
import { DepartmentData } from './store/departments.reducers';
import { selectDepartmentsList } from './store/departments.selectors';

@Component({
  selector: 'natea-cc-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss'],
})
export class DepartmentsComponent implements OnInit, OnDestroy {
  private desktopSizeMatcher: MediaQueryList = matchMediaDesktopSize(window);
  isDesktopSized = false;

  /**
   * Subject used to notify to the timeline component that the value of the
   * selected date has changed
   */

  private subscriptions: Subscription[] = [];

  departmentsData$: Observable<DepartmentData[] | undefined> =
    this.store.select(selectDepartmentsList);

  showSearchPatientModal$: Observable<boolean> = this.store.select(
    selectShowSearchPatientsModal
  );

  private problemTypes$: Observable<ProblemType[] | undefined> =
    this.store.select(selectAllProblemTypes);

  constructor(private store: Store, private config: ConfigService) {}

  get hospitalName(): string {
    return this.config.hospitalName;
  }

  ngOnInit(): void {
    this.store.dispatch(loadDepartments());

    this.problemTypes$.subscribe((problemTypes: ProblemType[] | undefined) => {
      if (problemTypes === undefined) {
        this.store.dispatch(loadAllProblemData({}));
      }
    });

    this.isDesktopSized = this.desktopSizeMatcher.matches;

    this.desktopSizeMatcher.addEventListener(
      'change',
      this.onDesktopSizeChanged
    );
  }

  ngOnDestroy(): void {
    while (this.subscriptions.length > 0) {
      this.subscriptions.pop()?.unsubscribe();
    }
  }

  private onDesktopSizeChanged = (event: MediaQueryListEvent): void => {
    this.isDesktopSized = event.matches;
  };

  onSearchPatient = (): void => {
    this.store.dispatch(showSearchPatientModal());
  };
}
