import { Sort } from '@angular/material/sort';
import { ActionReducer, Action, createReducer, on } from '@ngrx/store';
import {
  confirmAbortTransfusionalModal,
  confirmAbortTransfusionalTherapyFailure,
  confirmAbortTransfusionalTherapySuccess,
  confirmDeleteTransfusionalTherapy,
  confirmDeleteTransfusionalTherapySuccess,
  confirmSuspendTransfusionalTherapy,
  confirmSuspendTransfusionalTherapyFailure,
  createTransfusionalTherapy,
  createTransfusionalTherapyFailure,
  createTransfusionalTherapySuccess,
  deselectTransfusionalTherapy,
  downloadTransfusionalTherapyTypes,
  downloadTransfusionalTherapyTypesSuccess,
  editTransfusionalTherapy,
  editTransfusionalTherapyFailure,
  editTransfusionalTherapySuccess,
  hideConfirmAbortTransfusionalModal,
  hideConfirmDeleteTransfusionalModal,
  hideCreateNewTransfusionalTherapyModal,
  hideTransfusionalConfirmSuspendModal,
  loadTransfusionalTherapies,
  loadTransfusionalTherapiesFailure,
  loadTransfusionalTherapiesSuccess,
  selectTransfusionalTherapyOccurrence,
  selectedTransfusionalTherapyFromSearchTable,
  selectedTransfusionalTherapyFromTimeline,
  showConfirmAbortTransfusionalModal,
  showCreateNewTransfusionalTherapyModal,
  showDeleteTransfusionalTherapyConfirmationModal,
  showTransfusionalConfirmSuspendModal,
  sortTransfusionalTherapies,
  tabIndexTransfusionalTherapyChanged,
  transfusionalTherapySuspensionSuccess,
} from './transfusional-therapies.actions';
import { BloodComponent } from '../entities/blood-component';
import { SelectedTherapyOccurrenceIds } from '../../common/entities/selected-therapy';

export interface TransfusionalTherapiesUiState {
  isLoadingData: boolean;
  sort?: Sort;
  selectedTherapyIds?: SelectedTherapyOccurrenceIds;

  therapyTypes?: BloodComponent[];
  isLoadingTypes: boolean;

  candidateForDeletionId?: string;

  showCreateNewModal: boolean;
  isCreatingNew: boolean;

  isUpdating: boolean;

  tabIndex: number;
  isConfirmSuspend: boolean;

  selectedTherapyOccurrenceInTable?: boolean;
  changingTab?: boolean;
  isConfirmRunning: boolean;

  isShowConfirmAbortModal: boolean;

  isShowConfirmDeleteModal: boolean;

  candidateForAbortId?: string;

  candidateForSuspendId?: string;
}

export const initialTransfusionalTherapiesUiState: TransfusionalTherapiesUiState =
  {
    isLoadingData: false,
    showCreateNewModal: false,
    isCreatingNew: false,
    isLoadingTypes: false,
    isUpdating: false,
    tabIndex: 0,

    isConfirmSuspend: false,
    isConfirmRunning: false,

    isShowConfirmAbortModal: false,

    candidateForAbortId: undefined,

    isShowConfirmDeleteModal: false,
  };

export const transfusionalTherapiesUiReducer: ActionReducer<
  TransfusionalTherapiesUiState,
  Action
> = createReducer(
  initialTransfusionalTherapiesUiState,
  on(
    loadTransfusionalTherapies,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isLoadingData: true,
    })
  ),
  on(
    loadTransfusionalTherapiesSuccess,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isLoadingData: false,
    })
  ),
  on(
    loadTransfusionalTherapiesFailure,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isLoadingData: false,
    })
  ),
  on(
    sortTransfusionalTherapies,
    (
      state: TransfusionalTherapiesUiState,
      { sort }
    ): TransfusionalTherapiesUiState => ({
      ...state,
      sort,
    })
  ),
  on(
    selectTransfusionalTherapyOccurrence,
    (
      state: TransfusionalTherapiesUiState,
      { selectedTherapy }
    ): TransfusionalTherapiesUiState => ({
      ...state,
      selectedTherapyIds: selectedTherapy,
      selectedTherapyOccurrenceInTable: true,
      changingTab: false,
    })
  ),

  on(
    deselectTransfusionalTherapy,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      selectedTherapyIds: undefined,
      selectedTherapyOccurrenceInTable: undefined,
      changingTab: undefined,
    })
  ),

  on(
    showCreateNewTransfusionalTherapyModal,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      showCreateNewModal: true,
    })
  ),
  on(
    hideCreateNewTransfusionalTherapyModal,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      showCreateNewModal: false,
    })
  ),

  on(
    createTransfusionalTherapy,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isCreatingNew: true,
    })
  ),

  on(
    createTransfusionalTherapySuccess,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isCreatingNew: false,
      showCreateNewModal: false,
    })
  ),
  on(
    createTransfusionalTherapyFailure,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isCreatingNew: false,
    })
  ),
  // Edit therapy types
  /******************** EDIT ********************/

  on(
    editTransfusionalTherapy,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isUpdating: true,
    })
  ),
  on(
    editTransfusionalTherapySuccess,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isUpdating: false,
    })
  ),
  on(
    editTransfusionalTherapyFailure,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isUpdating: false,
    })
  ),
  // Download therapy types

  on(
    downloadTransfusionalTherapyTypes,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isLoadingTypes: true,
    })
  ),
  on(
    downloadTransfusionalTherapyTypesSuccess,
    (
      state: TransfusionalTherapiesUiState,
      { types }
    ): TransfusionalTherapiesUiState => ({
      ...state,
      isLoadingTypes: false,
      therapyTypes: types,
    })
  ),
  on(
    loadTransfusionalTherapiesFailure,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isLoadingTypes: false,
    })
  ),
  on(
    selectedTransfusionalTherapyFromTimeline,
    (
      state: TransfusionalTherapiesUiState,
      { selectedTherapy }
    ): TransfusionalTherapiesUiState => ({
      ...state,
      selectedTherapyIds: selectedTherapy,
      tabIndex: 1,
      selectedTherapyOccurrenceInTable: false,
      changingTab: state.tabIndex !== 1,
    })
  ),
  on(
    tabIndexTransfusionalTherapyChanged,
    (
      state: TransfusionalTherapiesUiState,
      { tabIndex }
    ): TransfusionalTherapiesUiState => {
      if (tabIndex === 0) {
        return {
          ...state,
          tabIndex,
          selectedTherapyIds: undefined,
          selectedTherapyOccurrenceInTable: undefined,
          changingTab: undefined,
        };
      } else {
        return {
          ...state,
          tabIndex,
        };
      }
    }
  ),

  on(
    showTransfusionalConfirmSuspendModal,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isConfirmSuspend: true,
    })
  ),

  on(
    hideTransfusionalConfirmSuspendModal,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isConfirmSuspend: false,
    })
  ),

  on(
    confirmSuspendTransfusionalTherapy,

    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isConfirmRunning: true,
      candidateForSuspendId: state.selectedTherapyIds?.therapyId,
    })
  ),

  on(
    confirmAbortTransfusionalModal,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isConfirmRunning: true,
      candidateForAbortId: state.selectedTherapyIds?.therapyId,
    })
  ),

  on(
    confirmAbortTransfusionalTherapyFailure,

    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isConfirmRunning: false,
    })
  ),

  on(
    hideConfirmAbortTransfusionalModal,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isShowConfirmAbortModal: false,
    })
  ),

  on(
    transfusionalTherapySuspensionSuccess,

    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isConfirmRunning: false,
      isConfirmSuspend: false,
    })
  ),
  on(
    confirmSuspendTransfusionalTherapyFailure,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isConfirmRunning: false,
    })
  ),
  on(
    showConfirmAbortTransfusionalModal,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isShowConfirmAbortModal: true,
    })
  ),
  on(
    confirmAbortTransfusionalTherapySuccess,

    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isConfirmRunning: false,
      isShowConfirmAbortModal: false,
    })
  ),

  // Delete

  on(
    showDeleteTransfusionalTherapyConfirmationModal,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isShowConfirmDeleteModal: true,
    })
  ),

  on(
    hideConfirmDeleteTransfusionalModal,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isShowConfirmDeleteModal: false,
    })
  ),

  on(
    confirmDeleteTransfusionalTherapy,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isConfirmRunning: true,
      candidateForDeletionId: state.selectedTherapyIds?.therapyId,
    })
  ),
  on(
    confirmDeleteTransfusionalTherapySuccess,
    (state: TransfusionalTherapiesUiState): TransfusionalTherapiesUiState => ({
      ...state,
      isConfirmRunning: false,
      isShowConfirmDeleteModal: false,
    })
  ),

  on(
    selectedTransfusionalTherapyFromSearchTable,
    (
      state: TransfusionalTherapiesUiState,
      { selectedTherapy }
    ): TransfusionalTherapiesUiState => ({
      ...state,
      selectedTherapyIds: {
        therapyId: selectedTherapy.therapyId,
        occurrenceId: selectedTherapy.occurrenceId,
      },
      tabIndex: 1,
      selectedTherapyOccurrenceInTable: true,
      changingTab: false,
    })
  )
);
