/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Request body for creating the replacement clinical note
 */
export interface NotePutRequest {
  /**
   * The new description of the replacement clinical note, if applicable.
   */
  description?: string;
  /**
   * The new ID of the detected problem associated with the replacement clinical note, if applicable.
   */
  detectedProblemId?: string;
  /**
   * A new reference to another clinical note ID in the replacement clinical note, if applicable.
   */
  ref?: string;
  /**
   * The start date of the replacement clinical note, if applicable.
   */
  startDate?: string;
}
