import { problemsNames } from '../../../../core/mocks/get-detected-problems-mock';
import { MeasureUnit } from '../../../../shared/entities/measure-unit';
import { ProfessionalCategory } from '../../../../shared/entities/professional-category';
import { mockEncounterDay } from '../../../../shared/utils/mock-utils';
import { randomBool, randomId } from '../../../../shared/utils/utils';
import {
  TherapyDurationType,
  TherapyOccurrence,
  TherapyOccurrenceState,
} from '../../common/entities/therapy-occurrence';
import { baseTherapyMock } from '../../common/mocks/therapy.mock';
import { PharmacologicalTherapy } from '../entities/pharmacological-therapy';
import { PharmacologicalTherapyAdministration } from '../entities/pharmacological-therapy-administration';
import { PharmacologicalTherapyType } from '../entities/pharmacological-therapy-type';

const unitMilligrams: MeasureUnit = {
  id: '1',
  symbol: 'mg/kg',
  name: 'milligrammi per chilogrammo',
  shortDescription: 'millis/killogr',
};

const unitMicrogramsPerKilogramPerMinute: MeasureUnit = {
  id: '2',
  symbol: 'mcg/kg/min',
  name: 'microgrammi per chilogrammo al minuto',
  shortDescription: 'microgr/killogr/min',
};

const therapyTypeDobutane: PharmacologicalTherapyType = {
  canBePrescribedAtNeed: randomBool(),
  id: randomId(),
  name: 'Dobutamina',
  description: 'Dobutamina',
};
const tachipirineId: string = randomId();

export const pharmacologicalTherapiesListMock =
  (): PharmacologicalTherapy[] => {
    const createList = (): PharmacologicalTherapy[] => [
      // 5.1.1 • Terapia estemporanea (istantanea) PREPARATA MA NON ANCORA ESEGUITA
      /* {
        ...basePharmacologicalTherapyMock,
        problem: {
          id: randomId(),
          name: 'Fisioterapia pediatrica',
          problemClass: ProfessionalCategory.Medical,
          valid: true,
        },
        id: randomId(),
        name: 'Fisioterapia pediatrica',
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prepared,
            programmingDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 2),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Amoxicillina',
          description: 'Amoxicillina  8g+ Acido Clavulanico  1,4',
        },
        canBeSuspended: true,
      },*/

      {
        ...basePharmacologicalTherapyMock,
        canBeEdited: true,
        problem: {
          id: randomId(),
          name: 'Prematurità minore di 28 settimane',
          problemClass: ProfessionalCategory.Medical,
          valid: true,
        },
        id: randomId(),

        baseUnit: {
          id: randomId(),
          symbol: 'mg',
          name: 'milligrammi',
          shortDescription: 'millis',
        },
        prescriptionDate: mockEncounterDay(3), // RLE
        insertionDate: mockEncounterDay(3), // RLE
        creationDate: mockEncounterDay(3), // RLE
        lastUpdateDate: undefined,

        // name: 'Fisioterapia pediatrica',
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(4, 8, 0), // RLE
            durationType: TherapyDurationType.Prolonged,
            executionDate: mockEncounterDay(4, 8, 0), // RLE
            terminationDate: mockEncounterDay(4, 8, 30), // RLE
            isValid: true,
            weightKg: '10',
            duration: 30,

            administration: {
              description: 'Amoxicillina + Acido Clavulanico', // RLE
              dosage: '38',
              lot: '12345',
              doseDilution: '',
              volume: '',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },

          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(4, 20, 0), // RLE
            executionDate: mockEncounterDay(4, 20, 0), // RLE
            terminationDate: mockEncounterDay(4, 20, 30), // RLE
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
            duration: 30,

            administration: {
              description: 'Amoxicillina + Acido Clavulanico', // RLE
              dosage: '38',
              lot: '12345',
              doseDilution: '',
              volume: '',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(5, 8, 0), // RLE
            executionDate: mockEncounterDay(5, 8, 0), // RLE
            terminationDate: mockEncounterDay(5, 8, 30), // RLE
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
            duration: 30,
            administration: {
              description: 'Amoxicillina + Acido Clavulanico', // RLE
              dosage: '38',
              lot: '12345',
              doseDilution: '',
              volume: '',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(5, 20, 0), // RLE
            executionDate: mockEncounterDay(5, 20, 0), // RLE
            terminationDate: mockEncounterDay(5, 20, 30), // RLE
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
            duration: 30,

            administration: {
              description: 'Amoxicillina + Acido Clavulanico', // RLE
              dosage: '38',
              lot: '12345',
              doseDilution: '',
              volume: '',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(6, 8, 0), // RLE
            executionDate: mockEncounterDay(6, 8, 0), // RLE
            terminationDate: mockEncounterDay(6, 8, 30), // RLE
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
            duration: 30,

            administration: {
              description: 'Amoxicillina + Acido Clavulanico', // RLE
              dosage: '38',
              lot: '12345',
              doseDilution: '',
              volume: '',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(6, 20, 0), // RLE
            executionDate: mockEncounterDay(6, 20, 0), // RLE
            terminationDate: mockEncounterDay(6, 20, 30), // RLE
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
            duration: 30,

            administration: {
              description: 'Amoxicillina + Acido Clavulanico', // RLE
              dosage: '38',
              lot: '12345',
              doseDilution: '',
              volume: '',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(7, 8, 0), // RLE
            executionDate: mockEncounterDay(7, 8, 0), // RLE
            terminationDate: mockEncounterDay(7, 8, 30), // RLE
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
            duration: 30,

            administration: {
              description: 'Amoxicillina + Acido Clavulanico', // RLE
              dosage: '38',
              lot: '12345',
              doseDilution: '',
              volume: '',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: mockEncounterDay(7, 20, 0), // RLE
            executionDate: mockEncounterDay(7, 20, 0), // RLE
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: mockEncounterDay(8, 8, 0), // RLE
            executionDate: mockEncounterDay(8, 8, 0), // RLE
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
        ],

        canal: {
          key: 'iv',
          label: 'EV',
        },

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Amoxicillina + Acido Clavulanico', // RLE
          description: 'Amoxicillina + Acido Clavulanico', // RLE
        },
        canBeSuspended: true,
        mode: {
          ...basePharmacologicalTherapyMock.mode,
          id: randomId(),
          name: 'Attacco',
          unit: unitMilligrams,
        },

        posologyDose: 20,

        posologyTotalDose: 38,

        durationMinutes: 30,
      },

      // 5.1.2 • Terapia estemporanea (istantanea) ESEGUITA
      {
        ...basePharmacologicalTherapyMock,

        baseUnit: {
          id: randomId(),
          symbol: 'mcg',
          name: 'microgrammi per chilogrammo al minuto',
          shortDescription: 'microgr/killogr/min',
        },

        canal: {
          key: 'iv',
          label: 'EV',
        },
        mode: {
          ...basePharmacologicalTherapyMock.mode,
          id: randomId(),
          name: 'Infusione continua',
          unit: unitMicrogramsPerKilogramPerMinute,
        },
        id: randomId(),
        prescriptionDate: mockEncounterDay(4), // RLE
        insertionDate: mockEncounterDay(4), // RLE
        creationDate: mockEncounterDay(4), // RLE
        therapyType: therapyTypeDobutane,

        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: mockEncounterDay(5, 16, 0), // RLE
            executionDate: mockEncounterDay(5, 16, 0), // RLE
            terminationDate: mockEncounterDay(6, 15, 59), // RLE
            durationType: TherapyDurationType.Prolonged,
            duration: 1440,
            isValid: true,
            weightKg: '10',

            administration: {
              description: 'Dobutamina',
              dosage: '25',
              lot: '12345',
              doseDilution: '',
              volume: '13680',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
        ],
        durationMinutes: 1440,
        posologyDose: 5,
        posologyTotalDose: 13680,
      },

      {
        ...basePharmacologicalTherapyMock,

        baseUnit: {
          id: randomId(),
          symbol: 'mcg',
          name: 'microgrammi per chilogrammo',
          shortDescription: 'microgr/killogr',
        },

        canal: {
          key: 'iv',
          label: 'EV',
        },
        mode: {
          ...basePharmacologicalTherapyMock.mode,
          id: randomId(),
          name: 'Infusione continua',
          unit: unitMicrogramsPerKilogramPerMinute,
        },
        id: randomId(),
        prescriptionDate: mockEncounterDay(5), // RLE
        insertionDate: mockEncounterDay(5), // RLE
        creationDate: mockEncounterDay(5), // RLE
        therapyType: therapyTypeDobutane,

        programmingDate: mockEncounterDay(6, 16, 0), // RLE
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.InExecution,
            programmingDate: mockEncounterDay(6, 16, 0), // RLE
            executionDate: mockEncounterDay(6, 16, 0), // RLE
            terminationDate: mockEncounterDay(7, 15, 59), // RLE
            durationType: TherapyDurationType.Prolonged,
            duration: 1440,
            isValid: true,
            weightKg: '20',

            administration: {
              description: 'Dobutamina',
              dosage: '25',
              lot: '12345',
              doseDilution: '',
              volume: '13680',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
        ],
        durationMinutes: 1440,

        posologyDose: 5,
        posologyTotalDose: 13680,
      },

      {
        ...basePharmacologicalTherapyMock,
        baseUnit: {
          id: randomId(),
          symbol: 'mcg',
          name: 'microgrammi per chilogrammo al minuto',
          shortDescription: 'microgr/killogr/min',
        },

        canal: {
          key: 'iv',
          label: 'EV',
        },
        mode: {
          ...basePharmacologicalTherapyMock.mode,
          id: randomId(),
          name: 'Infusione continua',
          unit: unitMicrogramsPerKilogramPerMinute,
        },
        id: randomId(),
        prescriptionDate: mockEncounterDay(7), // RLE
        insertionDate: mockEncounterDay(7), // RLE
        creationDate: mockEncounterDay(7), // RLE
        therapyType: therapyTypeDobutane,

        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(7, 16, 0), // RLE
            executionDate: mockEncounterDay(7, 16, 0), // RLE
            terminationDate: mockEncounterDay(8, 15, 59), // RLE
            durationType: TherapyDurationType.Prolonged,
            duration: 1440,
            isValid: true,
            weightKg: '10',

            administration: {
              description: 'Dobutamina',
              dosage: '25',
              lot: '12345',
              doseDilution: '',
              volume: '13680',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
        ],
        durationMinutes: 1440,
        posologyDose: 5,
        posologyTotalDose: 13680,
      },

      {
        ...basePharmacologicalTherapyMock,
        baseUnit: {
          id: randomId(),
          symbol: 'mcg',
          name: 'microgrammi per chilogrammo al minuto',
          shortDescription: 'microgr/killogr/min',
        },

        canal: {
          key: 'iv',
          label: 'EV',
        },
        mode: {
          ...basePharmacologicalTherapyMock.mode,
          id: randomId(),
          name: 'Infusione continua',
          unit: unitMicrogramsPerKilogramPerMinute,
        },
        id: randomId(),
        prescriptionDate: mockEncounterDay(8), // RLE
        insertionDate: mockEncounterDay(8), // RLE
        creationDate: mockEncounterDay(8), // RLE
        therapyType: therapyTypeDobutane,

        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: mockEncounterDay(8, 16, 0), // RLE
            executionDate: mockEncounterDay(8, 16, 0), // RLE
            terminationDate: mockEncounterDay(9, 15, 59), // RLE
            durationType: TherapyDurationType.Prolonged,
            duration: 1440,
            isValid: true,
            weightKg: '10',

            administration: {
              description: 'Dobutamina',
              dosage: '25',
              lot: '12345',
              doseDilution: '',
              volume: '13680',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
        ],
        durationMinutes: 1440,
        posologyDose: 5,
        posologyTotalDose: 13680,
      },

      {
        ...basePharmacologicalTherapyMock,
        baseUnit: {
          id: randomId(),
          symbol: 'mcg',
          name: 'microgrammi per chilogrammo al minuto',
          shortDescription: 'microgr/killogr/min',
        },

        canal: {
          key: 'iv',
          label: 'EV',
        },
        mode: {
          ...basePharmacologicalTherapyMock.mode,
          id: randomId(),
          name: 'Infusione continua',
          unit: unitMicrogramsPerKilogramPerMinute,
        },
        id: randomId(),
        prescriptionDate: mockEncounterDay(9), // RLE
        insertionDate: mockEncounterDay(9), // RLE
        creationDate: mockEncounterDay(9), // RLE
        therapyType: therapyTypeDobutane,

        programmingDate: mockEncounterDay(10, 16, 0), // RLE
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.InExecution,
            programmingDate: mockEncounterDay(9, 16, 0), // RLE
            executionDate: mockEncounterDay(9, 16, 0), // RLE
            terminationDate: mockEncounterDay(10, 15, 59), // RLE
            durationType: TherapyDurationType.Prolonged,
            duration: 1440,
            isValid: true,
            weightKg: '10',

            administration: {
              description: 'Dobutamina',
              dosage: '25',
              lot: '12345',
              doseDilution: '',
              volume: '13680',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
        ],
        durationMinutes: 1440,
        posologyDose: 5,
        posologyTotalDose: 13680,
        canBeEdited: true,
      },

      // 5.1.2 • Terapia estemporanea (istantanea) ESEGUITA
      /*   {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        name: 'Phardacological themrapy 1 description',
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 3),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            executionDate: new Date(Date.now() + 400 * 60 * 60 * 24),
            weightKg: '10',

            administration: {
              description: 'Apiplus 500mg',
              dosage: '25',
              lot: '12345',
              doseDilution: 'Acqua distillata',
              volume: '5',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
        ],
      },*/
      //5.1.3 • Terapia estemporanea (istantanea) DA ESEGUIRE
      /* {
        ...basePharmacologicalTherapyMock,
        canBeEdited: false,
        id: randomId(),
        name: 'Phardacological themrapy 1 description',
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: new Date(Date.now() + 1000 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Nistatina ',
          description: 'Nistatina 80mg',
        },
      },*/
      // 5.1.4 • Terapia estemporanea (istantanea) NON ESEGUITA
      /* {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: new Date(Date.now() - 1500 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Idroclorotiazide',
          description: 'Idroclorotiazide 20mg',
        },
      },*/
      // 5.1.5 • Terapia estemporanea (istantanea) ANNULLATA​
      /*  {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        prescriptionDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 2),
        programmingDate: new Date(Date.now() + 1000 * 60 * 60 * 24 * 2),
        occurrences: [
          //Futura
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Aborted,
            programmingDate: new Date(Date.now() + 1200 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          id: tachipirineId,
          canBePrescribedAtNeed: true,
          name: 'Lattoferrinaa e FOS ',
          description: 'Lattoferrinaa e FOS 1000mg',
        },
      },*/
      /* {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          //Passata
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Aborted,
            programmingDate: new Date(Date.now() - 1750 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          id: tachipirineId,
          canBePrescribedAtNeed: true,
          name: 'Lattoferrina e FOS',
          description: 'Lattoferrina e FOS 1000mg',
        },
      },*/
      // 5.1.6 • Terapia estemporanea PROLUNGATA PREPARATA MA NON ANCORA ESEGUITA
      /* {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          //Passata
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prepared,
            programmingDate: new Date(Date.now() - 3000 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() - 1000 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Morfina',
          description: 'Morfina a 0.03mg/kg x3',
        },
      },*/
      // 5.1.7 • Terapia estemporanea PROLUNGATA ESEGUITA​
      /* {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          //Passata
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            executionDate: new Date(Date.now() + 400 * 60 * 60 * 24),
            // 3 days ago at midnight
            programmingDate: new Date(
              new Date(new Date().setDate(new Date().getDate() - 3)).setHours(0)
            ),
            terminationDate: new Date(Date.now() - 2200 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',

            administration: {
              description: 'Apiplus 500mg',
              dosage: '25',
              lot: '12345',
              doseDilution: 'Acqua distillata',
              volume: '5',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Caffeina',
          description: 'Caffeina 20 mg/kg',
        },
      },*/
      // 5.1.8 • Terapia estemporanea PROLUNGATA INIZIATA​
      /* {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: new Date(Date.now() + 1500 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 2200 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Morfina',
          description: 'Morfina a 0.03mg/kg x3',
        },
      },
      // 5.1.9 • Terapia estemporanea PROLUNGATA DA ESEGUIRE
      {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.InExecution,
            programmingDate: new Date(Date.now() - 1500 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 2200 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Caffeina',
          description: 'Caffeina 20 mg/kg',
        },
      },
      // 5.1.10 • Terapia estemporanea prolungata NON ESEGUITA
      {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: new Date(Date.now() - 4500 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() - 3200 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Morfina',
          description: 'Morfina a 0.03mg/kg x3',
        },
      },
      // 5.1.11 • Terapia estemporanea prolungata ANNULLATA​
      {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Aborted,
            programmingDate: new Date(Date.now() + 2200 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 3200 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Caffeina',
          description: 'Caffeina 20 mg/kg',
        },
      },
      // 5.1.12 • Terapia estemporanea prolungata SOSPESA​
      {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Suspended,
            programmingDate: new Date(Date.now() - 2200 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 2700 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Morfina',
          description: 'Morfina a 0.03mg/kg x3',
        },
      }, */
      // 5.2.1 • Terapia continuativa (istantanea) PREPARATA MA NON ANCORA ESEGUITA​
      /* {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prepared,
            programmingDate: new Date(Date.now() - 5000 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prepared,
            programmingDate: new Date(Date.now() - 4000 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prepared,
            programmingDate: new Date(Date.now() - 3000 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Idroclorotiazide',
          description: 'Idroclorotiazide 10mg',
        },
      },
      // 5.2.2 • Terapia continuativa (istantanea) ESEGUITA​
      {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: new Date(Date.now() - 4300 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            executionDate: new Date(Date.now() + 400 * 60 * 60 * 24),
            weightKg: '10',

            administration: {
              description: 'Apiplus 500mg',
              dosage: '25',
              lot: '12345',
              doseDilution: 'Acqua distillata',
              volume: '5',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: new Date(Date.now() - 3500 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            executionDate: new Date(Date.now() + 400 * 60 * 60 * 24),
            weightKg: '10',

            administration: {
              description: 'Apiplus 500mg',
              dosage: '25',
              lot: '12345',
              doseDilution: 'Acqua distillata',
              volume: '5',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: new Date(Date.now() - 3000 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            executionDate: new Date(Date.now() + 400 * 60 * 60 * 24),

            weightKg: '10',

            administration: {
              description: 'Apiplus 500mg',
              dosage: '25',
              lot: '12345',
              doseDilution: 'Acqua distillata',
              volume: '5',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Lattoferrina e FOS',
          description: 'Lattoferrina e FOS 10mg',
        },
      },
      // 5.2.3 • Terapia continuativa (istantanea) INIZIATA
      {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: new Date(Date.now() - 1300 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            executionDate: new Date(Date.now() + 400 * 60 * 60 * 24),

            weightKg: '10',
            administration: {
              description: 'Apiplus 500mg',
              dosage: '25',
              lot: '12345',
              doseDilution: 'Acqua distillata',
              volume: '5',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Administered,
            programmingDate: new Date(Date.now() - 800 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            executionDate: new Date(Date.now() + 400 * 60 * 60 * 24),
            isValid: true,
            weightKg: '10',

            administration: {
              description: 'Apiplus 500mg',
              dosage: '25',
              lot: '12345',
              doseDilution: 'Acqua distillata',
              volume: '5',
              administeredBy: {
                id: randomId(),
                firstName: 'Mario',
                lastName: 'bianchi',
              },
            },
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: new Date(Date.now() + 700 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: new Date(Date.now() + 1700 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Nistatina',
          description: 'Nistatina 10mg',
        },
      },
      // 5.2.4 • Terapia continuativa (istantanea) DA ESEGUIRE​
      {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: new Date(Date.now() + 100 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },

          {
            id: randomId(),

            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: new Date(Date.now() + 800 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
          {
            id: randomId(),

            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: new Date(Date.now() + 2300 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Idroclorotiazide',
          description: 'Idroclorotiazide 10mg',
        },
      },
      // 5.2.5 • Terapia continuativa (istantanea) NON ESEGUITA
      {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          {
            id: randomId(),

            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: new Date(Date.now() - 100 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
          {
            id: randomId(),

            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: new Date(Date.now() - 800 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
          {
            id: randomId(),

            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Prescribed,
            programmingDate: new Date(Date.now() - 2300 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Lattoferrina e FOS',
          description: 'Lattoferrina e FOS 10mg',
        },
      },
      // 5.2.6 • Terapia continuativa (istantanea) ANNULLATA​
      {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Aborted,
            programmingDate: new Date(Date.now() + 400 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
          {
            id: randomId(),
            state: TherapyOccurrenceState.Aborted,
            prescriptionId: randomId(),
            programmingDate: new Date(Date.now() + 800 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.Aborted,
            programmingDate: new Date(Date.now() + 1200 * 60 * 60 * 24),
            durationType: TherapyDurationType.Instant,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Nistatina',
          description: 'Nistatina 10mg',
        },
      },
      {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        occurrences: [
          {
            id: randomId(),
            prescriptionId: randomId(),
            state: TherapyOccurrenceState.InExecution,
            programmingDate: new Date(Date.now() - 1500 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 2200 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
          {
            id: randomId(),

            prescriptionId: randomId(),

            state: TherapyOccurrenceState.Prescribed,
            programmingDate: new Date(Date.now() + 2700 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 2900 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: randomBool(),
          id: randomId(),
          name: 'Morfina',
          description: 'Morfina a 0.03mg/kg x3',
        },
      },
      {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        administerAtNeed: true,
        forDays: 5,
        prescriptionDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 5),
        programmingDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 2),
        occurrences: [
          {
            id: randomId(),

            prescriptionId: randomId(),

            state: TherapyOccurrenceState.Prepared,
            programmingDate: new Date(Date.now() + 300 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 500 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
          {
            id: randomId(),

            prescriptionId: randomId(),

            state: TherapyOccurrenceState.Prepared,
            programmingDate: new Date(Date.now() + 1000 * 60 * 60 * 24),
            terminationDate: new Date(Date.now() + 1750 * 60 * 60 * 24),
            durationType: TherapyDurationType.Prolonged,
            isValid: true,
            weightKg: '10',
          },
        ],

        therapyType: {
          canBePrescribedAtNeed: true,
          id: randomId(),
          name: 'Caffeina',
          description: 'Caffeina 20 mg/kg',
        },
      },
      {
        ...basePharmacologicalTherapyMock,
        id: randomId(),
        administerAtNeed: true,
        forDays: 3,
        prescriptionDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 5),
        programmingDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 2),
        occurrences: [],
        therapyType: {
          canBePrescribedAtNeed: true,
          id: randomId(),
          name: 'Caffeina al bisogno',
          description: 'Caffeina al bisogno 20 mg/kg',
        },
      },*/
    ];

    const list: PharmacologicalTherapy[] = createList();
    list.forEach((item: PharmacologicalTherapy) => {
      const id: string = randomId();
      item.id = id;
      item.occurrences.forEach(
        (
          occurrence: TherapyOccurrence<PharmacologicalTherapyAdministration>
        ) => {
          occurrence.id = randomId();
          occurrence.prescriptionId = id;
        }
      );
    });
    return [...list];
  };

export const basePharmacologicalTherapyMock: PharmacologicalTherapy = {
  baseUnit: unitMilligrams,
  ...baseTherapyMock,
  canBeAborted: true,
  updatedBy: {
    id: randomId(),
    firstName: 'Mario',
    lastName: 'bianchi',
  },
  occurrences: [
    {
      id: randomId(),
      prescriptionId: randomId(),
      state: TherapyOccurrenceState.Prescribed,
      programmingDate: new Date(Date.now() + 1000 * 60 * 60 * 24),
      durationType: TherapyDurationType.Instant,
      isValid: true,
      weightKg: '10',
    },
  ],

  problem: {
    id: problemsNames[0].id,
    name: problemsNames[0].name,
    problemClass: ProfessionalCategory.Medical,
    valid: true,
  },

  therapyType: {
    id: randomId(),
    canBePrescribedAtNeed: false,
    name: 'Lattoferrina e FOS ',
    description: 'Lattoferrina e FOS 50mg',
  },
  doubleCheckExecuted: true,

  mode: {
    isInfusional: true,
    defaultAdministrationTimeMinutes: 80,
    id: randomId(),
    name: 'Mode 1',
    therapyTypeId: '1',
    unit: unitMilligrams,
    defaultDose: 30,
    minDose: 1,
    maxDose: 50,
    maxPrescriptionDays: 4,
    isDoubleCheck: true,
    canBePrescribedAtNeed: false,
    baseUnit: unitMilligrams,
    inverseDiluentRatio: 1,
  },

  diluent: {
    key: randomId(),
    label: 'Acqua distillata',
  },

  canal: {
    key: randomId(),
    label: 'orale',
  },
  bsa: 1.15,
  posologyDose: 10,
  posologyDilutedDoseMl: 20,
  posologyTotalDose: 30,
  programmingDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 2),
  repetition: {
    id: randomId(),
    label: '1 volta al giorno',
    intervalSpanMinutes: 60 * 24,
  },
  forDays: 3,
  diluentDose: 129,
  velocity: 10,
  totalVolume: 100,
  durationMinutes: 100,
  patientWeightKg: 2.3,
};
