<button
  class="switch-button switch-button-left"
  [class.selected]="selectedItem === 'left'"
  (click)="this.selectedIndexChange.emit('left')"
  type="button"
>
  <natea-icons
    [name]="leftIcon"
    [title]="leftIconAlt"
    class="icon icon-left"
  ></natea-icons>
</button>
<button
  class="switch-button switch-button-right"
  [class.selected]="selectedItem === 'right'"
  (click)="this.selectedIndexChange.emit('right')"
  type="button"
>
  <natea-icons
    [name]="rightIcon"
    [title]="rightIconAlt"
    class="icon icon-right"
  ></natea-icons>
</button>
