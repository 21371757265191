/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Request object for creating a new detection of a problem catalog entry in the pediatric patient.
 */
export interface DetectedProblemCreateRequestResource { 
    /**
     * Additional notes about the problem catalog entry detected in the pediatric patient, if applicable.
     */
    additionalNotes?: string;
    /**
     * Unique identifier for the problem catalog entry that was detected.
     */
    problemCatalogEntryId: string;
    /**
     * Timestamp when the detection started.
     */
    startDate: string;
}

