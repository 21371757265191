import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotesValidationUiState } from '../../../clinical-note/notes-validation/store/notes-validation.reducer';
import { NursingGanttUiState } from '../../../nurse-records/nursing-gantt/store/nursing-gantt.reducer';
import { ProceduresUiState } from '../../../procedures/store/procedures.reducer';
import { InstrumentalTherapiesUiState } from '../../../therapies/instrumental-therapies/store/instrumental-therapies.reducer';
import { NutritionalTherapiesUiState } from '../../../therapies/nutritional-therapies/store/nutritional-therapies.reducer';
import { PharmacologicalTherapiesUiState } from '../../../therapies/pharmacological-therapies/store/pharmacological-therapies.reducer';
import { TherapiesConfirmationUiState } from '../../../therapies/therapies-confirmation/store/therapies-confirmation.reducer';
import { TransfusionalTherapiesUiState } from '../../../therapies/transfusional-therapies/store/transfusional-therapies.reducer';
import { SelectedPatientState } from './selected-patient.reducers';

const selectSelectedPatientState =
  createFeatureSelector<SelectedPatientState>('patient');

export const selectProblemsUiState = createSelector(
  selectSelectedPatientState,
  (patientState: SelectedPatientState) => patientState.problems
);

export const selectNursingGanttUiState = createSelector(
  selectSelectedPatientState,
  (patientState: SelectedPatientState): NursingGanttUiState =>
    patientState.nursingGantt
);

export const selectProceduresUiState = createSelector(
  selectSelectedPatientState,
  (patientState: SelectedPatientState): ProceduresUiState =>
    patientState.procedures
);

export const selectPharmacologicalTherapiesUiState = createSelector(
  selectSelectedPatientState,
  (patientState: SelectedPatientState): PharmacologicalTherapiesUiState =>
    patientState.pharmacologicalTherapies
);

export const selectInstrumentalTherapiesUiState = createSelector(
  selectSelectedPatientState,
  (patientState: SelectedPatientState): InstrumentalTherapiesUiState =>
    patientState.instrumentalTherapies
);

export const selectNutritionalTherapiesUiState = createSelector(
  selectSelectedPatientState,
  (patientState: SelectedPatientState): NutritionalTherapiesUiState =>
    patientState.nutritionalTherapies
);
export const selectTransfusionalTherapiesUiState = createSelector(
  selectSelectedPatientState,
  (patientState: SelectedPatientState): TransfusionalTherapiesUiState =>
    patientState.transfusionalTherapiesUiState
);

export const selectNotesValidationUiState = createSelector(
  selectSelectedPatientState,
  (patientState: SelectedPatientState): NotesValidationUiState =>
    patientState.notesValidation
);

export const selectTherapiesConfirmationUiState = createSelector(
  selectSelectedPatientState,
  (patientState: SelectedPatientState): TherapiesConfirmationUiState =>
    patientState.therapiesConfirmationUiState
);

export const selectPharmacologicalTherapiesAdministrationUiState =
  createSelector(
    selectSelectedPatientState,
    (patientState: SelectedPatientState) =>
      patientState.pharmacologicalTherapiesAdministrationUiState
  );

export const selectInstrumentalTherapiesAdministrationUiState = createSelector(
  selectSelectedPatientState,
  (patientState: SelectedPatientState) =>
    patientState.instrumentalTherapiesAdministrationUiState
);
