import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NateaIconsName } from '../../icons/natea-icon';
import { NateaIconsModule } from '../../icons/natea-icons.module';
import { TextPillColor } from './text-pill-model/text-pill-color-enum';

@Component({
  selector: 'natea-text-pill',
  standalone: true,
  imports: [CommonModule, NateaIconsModule],
  templateUrl: './text-pill.component.html',
  styleUrls: ['./text-pill.component.scss'],
})
export class TextPillComponent implements OnInit {
  @Input() label = '';
  @Input() iconName?: NateaIconsName;
  @Input() color: TextPillColor = TextPillColor.SUCCESS;
  @Input() hasBox = false;

  @Output() expand: EventEmitter<void> = new EventEmitter<void>();
  showBox = false;

  currentClasses: Record<string, boolean> = {};

  ngOnInit(): void {
    this.setCurrentClasses();
  }

  setCurrentClasses() {
    this.currentClasses = {
      success: this.color === TextPillColor.SUCCESS,
      warning: this.color === TextPillColor.WARNING,
      error: this.color === TextPillColor.ERROR,
      info: this.color === TextPillColor.INFO,
    };
  }

  toggleBox = () => {
    this.showBox = !this.showBox;
    this.expand.emit();
  };
}
