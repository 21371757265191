import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[nateaDurationPicker]',
  standalone: true,
})
export class DurationPickerDirective {
  private navigationKeys = [
    'Backspace',
    'Delete',
    'ArrowLeft',
    'ArrowRight',
    ':',
  ];
  @Input()
  picker: HTMLInputElement;

  constructor(public el: ElementRef) {
    this.picker = el.nativeElement;
    this.picker.value = '00:00';
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (this.navigationKeys.indexOf(e.key) > -1) {
      return;
    }

    this.checkIfOverflow(e);

    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

  private checkIfOverflow = (e: KeyboardEvent) => {
    // Get cursor position

    const target = e.target as HTMLInputElement;

    const cursorPosition = this.picker.selectionStart ?? 0;
    const twoPointsPosition = target.value.indexOf(':');

    if (twoPointsPosition < 0) {
      return;
    }

    if (cursorPosition <= twoPointsPosition) {
      return;
    }

    const [, minutes] = target.value.split(':');

    if (minutes.length === 2 || Number(minutes[0]) > 5) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  @HostListener('change', ['$event']) onInputChange(e: Event) {
    this.validateInput(e);
  }

  @HostListener('click', ['$event']) onClick(e: Event) {
    this.selectFocus(e);
  }

  validateInput(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target.value.indexOf(':') < 0) {
      const hours = Number(target.value);

      target.value = `${hours}:00`; // fallback to default
      target.dispatchEvent(new Event('input'));
    }

    const sectioned = target.value.split(':');

    let minutes = 0;

    if (!sectioned) {
      return;
    }

    if (sectioned.length !== 2) {
      target.value = '00:00'; // fallback to default
      target.dispatchEvent(new Event('input'));
      return;
    }

    if (sectioned[1].length !== 0) {
      minutes = Number(sectioned[1]);
    }

    let hours = Number(sectioned[0]);

    if (sectioned.length === 2 && sectioned[0].length === 0) {
      target.value = hours + ':00'; // fallback to default
      return;
    }

    if (isNaN(hours)) {
      hours = 0;
    }
    if (isNaN(minutes) || minutes < 0) {
      minutes = 0;
    }

    if (minutes > 59) {
      minutes = 59;
    }

    target.value = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
    target.dispatchEvent(new Event('input'));
  }

  selectFocus = (event: Event) => {
    const target = event.target as HTMLInputElement;

    // get cursor position and select nearest block;
    const cursorPosition = target.selectionStart ?? 0;
    // '00:00' this is the format used to determine cursor location
    const hourMarker = target.value.indexOf(':');

    if (hourMarker < 0) {
      // something wrong with the format. just return;
      return;
    }
    if (cursorPosition < hourMarker) {
      target.selectionStart = 0; // hours mode
      target.selectionEnd = hourMarker;
    }
  };
}
