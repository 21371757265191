import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { matchMediaDesktopSize } from 'natea-components';
import { Observable, combineLatest, map } from 'rxjs';
import { ConfigService } from '../../core/config/config.service';
import { AppRoutes } from '../../shared/routes/routes';
import { createPatientsComparator } from '../../shared/utils/optional-item-entity-utils';
import { DepartmentData } from '../departments/store/departments.reducers';
import {
  selectCurrentDepartment,
  selectDepartmentsList,
} from '../departments/store/departments.selectors';
import {
  exportPatients,
  goToPatient,
  loadAllPatients,
  showSearchPatientModal,
  sortPatients,
} from './store/patients.actions';
import { PatientData } from './store/patients.reducers';
import {
  selectPatientsList,
  selectPatientsSort,
  selectShowSearchPatientsModal,
} from './store/patients.selectors';

@Component({
  selector: 'natea-cc-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss'],
})
export class PatientsComponent implements OnInit, OnDestroy {
  isDesktopSized = false;
  private desktopSizeMatcher: MediaQueryList = matchMediaDesktopSize(window);

  department$: Observable<DepartmentData | undefined> = this.store.select(
    selectCurrentDepartment
  );

  patientDataList$: Observable<PatientData[] | undefined> =
    this.store.select(selectPatientsList);

  patientsSort$: Observable<Sort | undefined> =
    this.store.select(selectPatientsSort);

  patients$: Observable<PatientData[] | undefined> = combineLatest([
    this.patientDataList$,
    this.patientsSort$,
  ]).pipe(
    map(
      ([patientsList, sort]: [
        PatientData[] | undefined,
        Sort | undefined
      ]): PatientData[] => {
        const sortedPatients: PatientData[] | undefined = patientsList?.slice();
        if ((patientsList ?? []).length > 1) {
          const sortComparator: (a: PatientData, b: PatientData) => number =
            createPatientsComparator(this.translocoService, sort);

          sortedPatients?.sort(sortComparator);
        }
        return sortedPatients ?? [];
      }
    )
  );

  get hospitalName(): string {
    return this.config.hospitalName;
  }

  departments$: Observable<DepartmentData[] | undefined> = this.store.select(
    selectDepartmentsList
  );

  showSearchPatientModal$: Observable<boolean> = this.store.select(
    selectShowSearchPatientsModal
  );

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private translocoService: TranslocoService,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    this.department$.subscribe((department: DepartmentData | undefined) => {
      if (department) {
        this.store.dispatch(loadAllPatients({ departmentId: department.id }));
      }
    });
    this.isDesktopSized = this.desktopSizeMatcher.matches;
    this.desktopSizeMatcher.addEventListener(
      'change',
      this.onDesktopSizeChanged
    );
  }

  ngOnDestroy(): void {
    this.desktopSizeMatcher.removeEventListener(
      'change',
      this.onDesktopSizeChanged
    );
  }

  private onDesktopSizeChanged = (event: MediaQueryListEvent): void => {
    this.isDesktopSized = event.matches;
  };

  onPatientSelected = (patient: PatientData): void => {
    const departmentId: string | null = this.route.snapshot.paramMap.get(
      AppRoutes.Parameters.departmentId
    );
    this.store.dispatch(goToPatient({ departmentId, patientId: patient.id }));
  };

  onSortChanged = (sort: Sort): void => {
    this.store.dispatch(sortPatients({ sort }));
  };

  onSearchPatient = (): void => {
    this.store.dispatch(showSearchPatientModal());
  };

  onPatientsExported = (): void => {
    this.store.dispatch(exportPatients());
  };
}
