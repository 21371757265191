import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  DateAdapter,
  ErrorStateMatcher,
  MatNativeDateModule,
  ThemePalette,
} from '@angular/material/core';
import {
  DateFilterFn,
  DateRange,
  DatepickerDropdownPositionX,
  DatepickerDropdownPositionY,
  MatCalendarCellClassFunction,
  MatCalendarView,
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NateaIconsModule } from '../../icons/natea-icons.module';
import { FieldErrorComponent } from '../field/field-error/field-error.component';
import { FieldComponent } from '../field/field.component';
import { CustomDateAdapterService } from './date-picker-service/custom-date-adapter.service';

@Component({
  selector: 'natea-date-picker',
  standalone: true,
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapterService,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatePickerComponent,
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    NateaIconsModule,
    FieldErrorComponent,
  ],
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent
  extends FieldComponent
  implements ControlValueAccessor, OnChanges
{
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['setOuterDate'] && changes['setOuterDate'].currentValue) {
      this.writeValue(changes['setOuterDate'].currentValue);
    }
  }
  @Input() placeholder = '';

  @Input() isError = false;

  @Input() maxDate: Date | null = null;
  @Input() minDate: Date | null = null;

  @Input() isDateRange = false;

  @Input() alignHint: 'start' | 'end' = 'start';

  @Input() dateFilter!: DateFilterFn<Date | null>;

  @Input() calendarHeaderComponent!: ComponentType<unknown>;

  @Input() color: ThemePalette;

  @Input() dateClass!: MatCalendarCellClassFunction<Date>;

  @Input() isOpened = false;

  @Input() panelClass: string | string[] = '';

  @Input() restoreFocus = false;

  @Input() startView: 'month' | 'year' | 'multi-year' = 'month';

  @Input() setOuterDate: Date | null = null;

  @Input() touchUi = false;
  @Input() xPosition!: DatepickerDropdownPositionX;

  @Input() yPosition!: DatepickerDropdownPositionY;

  @Input() errorStateMatcher!: ErrorStateMatcher;

  @Output() dateChange: EventEmitter<Date | null> =
    new EventEmitter<Date | null>();

  @Output() dateInputStart: EventEmitter<
    MatDatepickerInputEvent<Date, DateRange<Date>>
  > = new EventEmitter<MatDatepickerInputEvent<Date, DateRange<Date>>>();

  @Output() dateInput: EventEmitter<
    MatDatepickerInputEvent<Date, Date | null>
  > = new EventEmitter<MatDatepickerInputEvent<Date, Date | null>>();

  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  @Output() monthSelected: EventEmitter<string> = new EventEmitter<string>();

  @Output() opened: EventEmitter<void> = new EventEmitter<void>();

  @Output() viewChanged: EventEmitter<MatCalendarView> =
    new EventEmitter<MatCalendarView>();

  @Output() yearSelected: EventEmitter<string> = new EventEmitter<string>();

  isAddClass = false;

  inputValue: Date | null = null;

  onChange!: (val: Date | null) => void;

  onTouched!: () => void;

  onDateChanged = (event: MatDatepickerInputEvent<Date, Date | null>): void => {
    if (this.onChange) {
      this.onChange(event.value);
    }
    this.dateChange.emit(event.value);
  };

  markAsTouched = (): void => {
    if (this.onTouched) {
      this.onTouched();
    }
  };

  writeValue(data: Date | null): void {
    this.inputValue = data;
  }

  registerOnChange(fn: (val: Date | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState = (isDisabled: boolean): void => {
    this.isDisabled = isDisabled;
  };

  onOpened = (): void => {
    this.markAsTouched();
    this.opened.emit();
  };

  onClosed = (): void => {
    this.markAsTouched();
    this.closed.emit();
  };

  onViewChanged = (view: MatCalendarView): void => {
    this.markAsTouched();
    this.viewChanged.emit(view);
  };

  onDateInput = (event: MatDatepickerInputEvent<Date, Date | null>): void => {
    this.markAsTouched();

    this.inputValue = event.value;
    this.dateInput.emit(event.value === null ? undefined : event);
    this.isAddClass = !!event.target.value;
  };

  onMonthSelected = (date: string): void => {
    this.markAsTouched();
    this.monthSelected.emit(date);
  };

  onYearSelected = (year: string): void => {
    this.markAsTouched();
    this.yearSelected.emit(year);
  };
}
