import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import {
  confirmAllTherapies,
  confirmAllTherapiesFailure,
  confirmAllTherapiesSuccess,
  confirmTherapy,
  confirmTherapyFailure,
  confirmTherapySuccess,
  loadTherapiesToConfirm,
  loadTherapiesToConfirmFailure,
  loadTherapiesToConfirmSuccess,
} from './therapies-confirmation.actions';

export interface TherapiesConfirmationUiState {
  isLoadingData: boolean;
  therapiesBeingConfirmed: string[];
  isConfirmingAllTherapies: boolean;
}

export const initialTherapiesConfirmationUiState: TherapiesConfirmationUiState =
  {
    isLoadingData: false,
    therapiesBeingConfirmed: [],
    isConfirmingAllTherapies: false,
  };

export const therapiesConfirmationUiReducer: ActionReducer<
  TherapiesConfirmationUiState,
  Action
> = createReducer(
  initialTherapiesConfirmationUiState,

  /************************* THERAPIES DOWNLOAD *************************/

  on(
    loadTherapiesToConfirm,
    (state: TherapiesConfirmationUiState): TherapiesConfirmationUiState => ({
      ...state,
      isLoadingData: true,
    })
  ),
  on(
    loadTherapiesToConfirmSuccess,
    (state: TherapiesConfirmationUiState): TherapiesConfirmationUiState => ({
      ...state,
      isLoadingData: false,
    })
  ),
  on(
    loadTherapiesToConfirmFailure,
    (state: TherapiesConfirmationUiState): TherapiesConfirmationUiState => ({
      ...state,
      isLoadingData: false,
    })
  ),

  /************************* CONFIRMATION *************************/

  on(
    confirmTherapy,
    (
      state: TherapiesConfirmationUiState,
      { therapyId }
    ): TherapiesConfirmationUiState => ({
      ...state,
      therapiesBeingConfirmed: [...state.therapiesBeingConfirmed, therapyId],
    })
  ),
  on(
    confirmTherapySuccess,
    confirmTherapyFailure,
    (
      state: TherapiesConfirmationUiState,
      { therapyId }
    ): TherapiesConfirmationUiState => ({
      ...state,
      therapiesBeingConfirmed: state.therapiesBeingConfirmed.filter(
        (id) => id !== therapyId
      ),
    })
  ),

  /************************* CONFIRM ALL *************************/

  on(
    confirmAllTherapies,
    (state: TherapiesConfirmationUiState): TherapiesConfirmationUiState => ({
      ...state,
      isConfirmingAllTherapies: true,
    })
  ),
  on(
    confirmAllTherapiesSuccess,
    confirmAllTherapiesFailure,
    (state: TherapiesConfirmationUiState): TherapiesConfirmationUiState => ({
      ...state,
      isConfirmingAllTherapies: false,
    })
  )
);
