import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { deleteBoardNoteType } from '../../../shared/components/blackboard-notes-table/blackboard-notes-table.component';
import { BoardContent } from '../interfaces/board-content';
import {
  deleteAllBoarNoteSuccess,
  deleteBoardItemsFailure,
  deleteSingleBoardSuccess,
  hideDeletionBoardNoteModal,
  isAtLeastOneNoteFromClipboardOpened,
  loadBoardContentsFailure,
  loadBoardContentsSuccess,
  saveBoardContentToBoard,
  saveBoardContentToBoardFailure,
  saveBoardContentToBoardSuccess,
  showDeletionBoardNoteModal,
} from './board.actions';

export interface BoardState {
  boardContents: BoardContent[];
  isAtLeastOneNoteFromClipboardOpened: boolean;
  showDeletionBoardNoteModalType?: deleteBoardNoteType;
  isDeleteAllBoardNoteFailed: boolean;
  isCopyToClipboardRunning: boolean;
}

export const initialBoardState: BoardState = {
  boardContents: [],
  isAtLeastOneNoteFromClipboardOpened: false,
  isDeleteAllBoardNoteFailed: false,
  isCopyToClipboardRunning: false,
};

export const boardReducer: ActionReducer<BoardState, Action> = createReducer(
  initialBoardState,
  on(
    loadBoardContentsSuccess,
    (
      state: BoardState,
      { boardContents }: { boardContents: BoardContent[] }
    ): BoardState => ({
      ...state,
      boardContents,
    })
  ),
  on(
    deleteSingleBoardSuccess,
    (state: BoardState, { id }: { id: string }): BoardState => ({
      ...state,
      boardContents: state.boardContents.filter(
        (content: BoardContent): boolean => content.id !== id
      ),

      showDeletionBoardNoteModalType: undefined,
    })
  ),
  on(
    loadBoardContentsFailure,
    (state: BoardState): BoardState => ({
      ...state,
      boardContents: [],
    })
  ),
  on(
    saveBoardContentToBoardSuccess,
    (state: BoardState, { content }: { content: BoardContent }): BoardState => {
      const contents: BoardContent[] = [...state.boardContents, content];

      const MAX_NOTES = 100;

      contents.sort((a: BoardContent, b: BoardContent): number => {
        if (!a.creationDate || !b.creationDate) {
          return 0;
        }
        return (
          new Date(b.creationDate).getTime() -
          new Date(a.creationDate).getTime()
        );
      });

      if (contents.length > MAX_NOTES) {
        contents.pop();
      }

      return {
        ...state,
        boardContents: contents,
        isCopyToClipboardRunning: false,
      };
    }
  ),
  on(
    saveBoardContentToBoardFailure,
    (state: BoardState): BoardState => ({
      ...state,
      isCopyToClipboardRunning: false,
    })
  ),
  on(
    deleteAllBoarNoteSuccess,
    (state: BoardState): BoardState => ({
      ...state,
      boardContents: [],
      showDeletionBoardNoteModalType: undefined,
    })
  ),
  on(
    isAtLeastOneNoteFromClipboardOpened,
    (
      state: BoardState,
      {
        isAtLeastOneNoteFromClipboardOpened,
      }: { isAtLeastOneNoteFromClipboardOpened: boolean }
    ): BoardState => ({
      ...state,
      isAtLeastOneNoteFromClipboardOpened,
    })
  ),
  on(
    showDeletionBoardNoteModal,

    (
      state: BoardState,
      { deleteType }: { deleteType: deleteBoardNoteType }
    ): BoardState => ({
      ...state,
      showDeletionBoardNoteModalType: deleteType,
      isDeleteAllBoardNoteFailed: false,
    })
  ),
  on(
    hideDeletionBoardNoteModal,
    (state: BoardState): BoardState => ({
      ...state,
      showDeletionBoardNoteModalType: undefined,
    })
  ),
  on(
    deleteBoardItemsFailure,
    (state: BoardState): BoardState => ({
      ...state,
      isDeleteAllBoardNoteFailed: true,
    })
  ),
  on(
    saveBoardContentToBoard,
    (state: BoardState): BoardState => ({
      ...state,
      isCopyToClipboardRunning: true,
    })
  )
);
