import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { TranslocoModule } from '@ngneat/transloco';
import {
  ButtonComponent,
  FullPageLoaderComponent,
  NateaIconsModule,
} from 'natea-components';
import { SharedModule } from '../../shared/shared.module';
import { PatientsModule } from '../patients/patients.module';
import { DepartmentsLoaderComponent } from './components/departments-loader/departments-loader.component';
import { DepartmentsTableComponent } from './components/departments-table/departments-table.component';
import { DepartmentsRoutingModule } from './departments-routing.module';
import { DepartmentsComponent } from './departments.component';

@NgModule({
  declarations: [
    DepartmentsComponent,
    DepartmentsTableComponent,
    DepartmentsLoaderComponent,
  ],
  imports: [
    CommonModule,
    NateaIconsModule,
    FullPageLoaderComponent,
    DepartmentsRoutingModule,
    MatTableModule,
    SharedModule,
    TranslocoModule,
    ButtonComponent,
    PatientsModule,
  ],
})
export class DepartmentsModule {}
