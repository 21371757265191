import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { Config } from '../config';
import { loadConfig } from './config.actions';

export type ConfigState = Config | undefined;

export const configReducer: ActionReducer<ConfigState, Action> = createReducer(
  undefined as ConfigState,
  on(
    loadConfig,
    (_: ConfigState, { config }: { config: Config }): Config => config
  )
);
