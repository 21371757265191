<natea-cc-base-page
  [hasSidebar]="false"
  [complexContent]="false"
  subHeaderAlign="between"
  headerContentAlign="start"
  *transloco="let t; read: 'patient'"
>
  <natea-cc-patients-list-header
    sub-header
    [isDesktopSized]="isDesktopSized"
    [hospitalName]="hospitalName"
    [departmentName]="(department$ | async)?.name ?? ''"
    [multipleDepartments]="((departments$ | async) || []).length > 1"
    (searchPatient)="onSearchPatient()"
  ></natea-cc-patients-list-header>

  <h3 header-content>{{ t("title") }}</h3>
  <div main-content>
    <natea-cc-patients-list
      [isDesktopSized]="isDesktopSized"
      [patients]="(patients$ | async) ?? []"
      [departmentName]="(department$ | async)?.name ?? ''"
      [totalBeds]="(department$ | async)?.totalBeds"
      [occupiedBeds]="(department$ | async)?.occupiedBeds"
      (selectPatient)="onPatientSelected($event)"
      (sortChanged)="onSortChanged($event)"
      (patientsExported)="onPatientsExported()"
    ></natea-cc-patients-list>
    <ng-container modals>
      <natea-cc-search-patients
        *ngIf="showSearchPatientModal$ | async"
        [patientsLoaded]="true"
      ></natea-cc-search-patients>
    </ng-container>
  </div>
</natea-cc-base-page>
