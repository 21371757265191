/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a procedure catalog entry.
 */
export interface ProcedureCatalogEntryResource { 
    /**
     * Unique identifier for the procedure catalog entry.
     */
    id: string;
    /**
     * Indicates if the procedure catalog entry is continuous over time or instantaneous.
     */
    isContinuous: boolean;
    /**
     * Indicates if the procedure catalog entry is currently enabled within the application.
     */
    isEnabled: boolean;
    /**
     * The name of the procedure catalog entry.
     */
    name: string;
    /**
     * The class of procedure catalog entry.
     */
    procedureClass: string;
    /**
     * The type of procedure catalog entry, if applicable.
     */
    procedureType?: string;
}

