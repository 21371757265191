import {
  FactSheetUiState,
  factSheetUiReducer,
} from 'src/app/features/anamnesis/new-born/store/new-born.reducers';
import * as fromRoot from '../../../../store/app.reducers';
import {
  NotesValidationUiState,
  notesValidationReducer,
} from '../../../clinical-note/notes-validation/store/notes-validation.reducer';
import {
  NursingGanttUiState,
  nursingGanttUiReducer,
} from '../../../nurse-records/nursing-gantt/store/nursing-gantt.reducer';
import {
  InstrumentalTherapiesAdministrationUiState,
  instrumentalTherapiesAdministrationUiReducer,
} from '../../../nurse-records/therapies-administration/instrumental-therapies-administration/store/instrumental-therapies-administration.reducer';
import {
  PharmacologicalTherapiesAdministrationUiState,
  pharmacologicalTherapiesAdministrationUiReducer,
} from '../../../nurse-records/therapies-administration/pharmacological-therapies-administration/store/pharmacological-therapies-administration.reducer';
import {
  ProblemsState,
  problemsReducer,
} from '../../../problems/store/problems.reducers';
import {
  ProceduresUiState,
  proceduresUiReducer,
} from '../../../procedures/store/procedures.reducer';
import {
  InstrumentalTherapiesUiState,
  instrumentalTherapiesUiReducer,
} from '../../../therapies/instrumental-therapies/store/instrumental-therapies.reducer';
import {
  NutritionalTherapiesUiState,
  nutritionalTherapiesUiReducer,
} from '../../../therapies/nutritional-therapies/store/nutritional-therapies.reducer';
import {
  PharmacologicalTherapiesUiState,
  pharmacologicalTherapiesUiReducer,
} from '../../../therapies/pharmacological-therapies/store/pharmacological-therapies.reducer';
import {
  TherapiesConfirmationUiState,
  therapiesConfirmationUiReducer,
} from '../../../therapies/therapies-confirmation/store/therapies-confirmation.reducer';
import {
  TransfusionalTherapiesUiState,
  transfusionalTherapiesUiReducer,
} from '../../../therapies/transfusional-therapies/store/transfusional-therapies.reducer';

export interface SelectedPatientState {
  problems: ProblemsState;
  nursingGantt: NursingGanttUiState;
  procedures: ProceduresUiState;
  notesValidation: NotesValidationUiState;
  pharmacologicalTherapies: PharmacologicalTherapiesUiState;
  instrumentalTherapies: InstrumentalTherapiesUiState;
  nutritionalTherapies: NutritionalTherapiesUiState;
  transfusionalTherapiesUiState: TransfusionalTherapiesUiState;
  therapiesConfirmationUiState: TherapiesConfirmationUiState;
  pharmacologicalTherapiesAdministrationUiState: PharmacologicalTherapiesAdministrationUiState;
  instrumentalTherapiesAdministrationUiState: InstrumentalTherapiesAdministrationUiState;
  factSheetUiState: FactSheetUiState;
}

export const selectedPatientReducersMap = {
  problems: problemsReducer,
  nursingGantt: nursingGanttUiReducer,
  procedures: proceduresUiReducer,
  notesValidation: notesValidationReducer,
  pharmacologicalTherapies: pharmacologicalTherapiesUiReducer,
  nutritionalTherapies: nutritionalTherapiesUiReducer,
  transfusionalTherapiesUiState: transfusionalTherapiesUiReducer,
  instrumentalTherapies: instrumentalTherapiesUiReducer,
  therapiesConfirmationUiState: therapiesConfirmationUiReducer,
  pharmacologicalTherapiesAdministrationUiState:
    pharmacologicalTherapiesAdministrationUiReducer,
  instrumentalTherapiesAdministrationUiState:
    instrumentalTherapiesAdministrationUiReducer,
  factSheetUiState: factSheetUiReducer,
};

export interface State extends fromRoot.State, SelectedPatientState {}
