import { PatientDataNursingGanttState } from '../../../patients/store/clinical-data/nursing-gantt/patients-data.nursing-gantt.state';

export const nursingGanttMockData = (): PatientDataNursingGanttState => {
  return {
    warnings: Array(5)
      .fill(0)
      .map((_, i) => `Testo avvertimento ${i}`),
    handovers: [],
    operations: {},
  };
};
