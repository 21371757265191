/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a medical device catalog entry.
 */
export interface MedicalDeviceCatalogEntryResource { 
    /**
     * The unique identifier for the medical device catalog entry.
     */
    id: string;
    /**
     * Indicates whether the medical device catalog entry is currently enabled within the application.
     */
    isEnabled: boolean;
    /**
     * The name of the medical device catalog entry.
     */
    name: string;
}

