import { state, style, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ButtonVariants, NateaIconsName } from 'natea-components';
import { Patient, WarningStatus } from '../../../../shared/entities/patient';
import { PatientData } from '../../store/patients.reducers';
import { selectIsPatientsListExportRunning } from '../../store/patients.selectors';
import { Store } from '@ngrx/store';
import { shownName } from '../../../../shared/entities/user';

@Component({
  selector: 'natea-cc-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss'],
  animations: [
    trigger('expandNote', [
      state('expanded', style({ display: 'table-row' })),
      state('collapsed', style({ display: 'none' })),
    ]),
  ],
})
export class PatientsListComponent implements OnChanges, AfterViewInit {
  @Input() patients: PatientData[] = [];

  @Input() departmentName = '';

  @Input() totalBeds?: number;

  @Input() occupiedBeds?: number;

  @Input() isDesktopSized = false;

  @Output() selectPatient: EventEmitter<PatientData> =
    new EventEmitter<PatientData>();

  @Output() sortChanged: EventEmitter<Sort> = new EventEmitter<Sort>();

  @Output() patientsExported: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('patientsTable') patientsTable?: ElementRef;

  shownName = shownName;

  buttonVariants = ButtonVariants;

  WarningStatus = WarningStatus;

  expandedRows: Set<string> = new Set<string>();

  isAnyData = false;

  isOverflowing = false;

  displayedColumns: string[] = [
    'expand', // Show/hide details
    'patientName', // firstName + lastName
    'birthDate',
    'patientWeight', // measurements.weight (at birthdate)
    'gestationalAge', // how long did the gestation last
    'encounterStartDate', // encounters[endDate == null].startDate
    'currentBed', // encounters[endDate == null].bed
    'warnings', // Icons list
  ];

  isExportingPatients$ = this.store.select(selectIsPatientsListExportRunning);

  constructor(private store: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['patients']?.currentValue) {
      this.isAnyData = !!this.patients.length;

      this.expandedRows = new Set<string>();
    }
    setTimeout(this.checkOverflow);
  }

  ngAfterViewInit(): void {
    setTimeout(this.checkOverflow);
  }

  checkOverflow = (): void => {
    this.isOverflowing =
      this.patientsTable?.nativeElement.offsetHeight +
        this.patientsTable?.nativeElement.scrollTop <
      this.patientsTable?.nativeElement.scrollHeight;
  };

  togglePatientNote = (patient: Patient): void => {
    if (this.isNoteExpanded(patient)) {
      this.expandedRows.delete(patient.id);
    } else {
      this.expandedRows.add(patient.id);
    }
  };

  isNoteExpanded = (patient: Patient): boolean => {
    return this.expandedRows.has(patient.id);
  };

  sortTable = (sortState?: Sort): void => {
    if (sortState) {
      this.sortChanged.emit(sortState);
    }
  };

  exportPatients = (): void => {
    this.patientsExported.emit();
  };

  noteRowId = (patient: Patient): string => `patient-note-${patient.id}`;

  rowEvenOddClass = (patient: PatientData): string =>
    this.patients.findIndex((p: PatientData): boolean => p.id === patient.id) %
      2 ===
    0
      ? 'odd-row'
      : 'even-row';

  rowWarnings = (
    patient: Patient
  ): {
    icon: NateaIconsName;
    color: string;
  }[] => {
    return [
      {
        icon: 'terapie',
        color: this.getWarningColor(patient.warnings?.therapy),
      },
      {
        icon: 'procedure',
        color: this.getWarningColor(patient.warnings?.procedure),
      },
      {
        icon: 'infermiere',
        color: this.getWarningColor(patient.warnings?.nursing),
      },
      {
        icon: 'terapie-nutrizionali',
        color: this.getWarningColor(patient.warnings?.nutrition),
      },
      {
        icon: 'esami',
        color: this.getWarningColor(patient.warnings?.diagnosis),
      },
      {
        icon: 'screening-neonatale',
        color: this.getWarningColor(patient.warnings?.screening),
      },
    ];
  };

  private getWarningColor = (status?: WarningStatus): string => {
    switch (status) {
      case WarningStatus.Warning:
        return '#ff912b';
      case WarningStatus.Critical:
        return '#fa0202';
      default:
        return '';
    }
  };
}
