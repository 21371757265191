import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OptionsItem } from 'natea-components';
import { TherapyRepetition } from '../../../features/therapies/common/entities/therapy-repetition';
import { ApiError } from '../../../shared/entities/errors';
import { DomainsState } from './domains.reducer';
import { DomainItem } from 'src/app/shared/entities/domain-item';
import { mapsDomainToOptionsItem } from '../utils/maps';

const selectDomainsState = createFeatureSelector<DomainsState>('domains');

export const selectTherapyRepetitions = createSelector(
  selectDomainsState,
  (domainsState: DomainsState): TherapyRepetition[] | undefined =>
    domainsState.therapyRepetitions
);

export const selectTherapyRepetitionsAsOptionsItem = createSelector(
  selectDomainsState,
  (domainsState: DomainsState): OptionsItem<TherapyRepetition>[] | undefined =>
    domainsState.therapyRepetitions?.map((therapyRepetition) => ({
      id: therapyRepetition.id,
      label: therapyRepetition.label,
      data: therapyRepetition,
    }))
);

export const selectIsDomainsDownloaded = createSelector(
  selectDomainsState,
  (domainsState: DomainsState): boolean => domainsState.domainsDownloaded
);

export const selectDomainsDownloadError = createSelector(
  selectDomainsState,
  (domainsState: DomainsState): ApiError | undefined =>
    domainsState.downloadError
);

export const selectNutritionalCategoriesMap = createSelector(
  selectDomainsState,
  (domainsState: DomainsState) => domainsState.nutritionalCategories
);

export const selectCoombsTest = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.coombsTest?.map((item) => mapsDomainToOptionsItem(item))
);

export const selectMilkingTime = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.milkingTime?.map((item) => mapsDomainToOptionsItem(item))
);

export const selectWithdrawal = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.withdrawal?.map((item) => mapsDomainToOptionsItem(item))
);

export const selectUterineTransfer = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.uterineTransfer?.map((item) => mapsDomainToOptionsItem(item))
);

export const selectEmissionUrine = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.emissionUrine?.map((item) => mapsDomainToOptionsItem(item))
);

export const selectEmissionMeconium = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.emissionMeconium?.map((item) => mapsDomainToOptionsItem(item))
);

export const selectSurfacente = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.surfacente?.map((item) => mapsDomainToOptionsItem(item))
);

export const selectBloodGroup = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.bloodGroup?.map((item) => mapsDomainToOptionsItem(item))
);

export const selectRhFactor = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.rhFactor?.map((item) => mapsDomainToOptionsItem(item))
);

export const selectPharmacy = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.pharmacy?.map((item) => mapsDomainToOptionsItem(item))
);

export const selectVitaminK = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.vitaminK?.map((item) => mapsDomainToOptionsItem(item))
);

export const selectRoute = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.route?.map((item) => mapsDomainToOptionsItem(item))
);

export const selectEchocardiogram = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.echocardiogram?.map((item) => mapsDomainToOptionsItem(item))
);

export const selectMenstrualCycle = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.menstrualCycle?.map((item) => mapsDomainToOptionsItem(item))
);

export const selectObstetricVisits = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.obstetricVisits?.map((item) => mapsDomainToOptionsItem(item))
);

export const selectPregnancyCareTechniques = createSelector(
  selectDomainsState,
  (
    domainsState: DomainsState
  ): OptionsItem<DomainItem<unknown>>[] | undefined =>
    domainsState.pregnancyCareTechniques?.map((item) =>
      mapsDomainToOptionsItem(item)
    )
);
