import { ReducerTypes, on } from '@ngrx/store';
import {
  createPatientProblemSuccess,
  deletePatientProblemSuccess,
  loadAllProblemsFailure,
  loadAllProblemsSuccess,
  updatePatientProblemSuccess,
} from '../../../../problems/store/problems.actions';
import { PatientsState, updatePatientData } from '../../patients.reducers';

export const patientProblemsReducerFunctions: ReducerTypes<
  PatientsState,
  [never]
>[] = [
  on(loadAllProblemsSuccess, (state, { problems, patientId }) => {
    return updatePatientData(state, patientId, () => ({
      problems: problems.filter((problem) => problem.patientId === patientId),
    }));
  }),

  on(loadAllProblemsFailure, (state, { patientId }) => {
    return updatePatientData(state, patientId, () => ({
      problems: undefined,
    }));
  }),

  on(createPatientProblemSuccess, (state, { problem }) => {
    return updatePatientData(state, problem.patientId ?? '', (patientData) => ({
      problems: [...(patientData?.problems ?? []), problem],
    }));
  }),

  on(updatePatientProblemSuccess, (state, { problem }) =>
    updatePatientData(state, problem.patientId ?? '', (patientData) => ({
      problems: patientData?.problems?.map((p) =>
        p.id === problem.id ? problem : p
      ),
    }))
  ),

  on(deletePatientProblemSuccess, (state, { problem }) =>
    updatePatientData(state, problem.patientId ?? '', (patientData) => ({
      ...state,
      problems: patientData?.problems?.filter((p) => p.id !== problem.id),
      problemToDelete: undefined,
    }))
  ),
];
