import { DateRange } from '../../../models/date-range';
import {
  PositionedInstantOccurrence,
  PositionedProlongedOccurrence,
  PositionedRecurringOccurrence,
  TimelinePositionedOccurrence,
} from '../models/positioned-timeline-item';

export const dateRangeFromOccurrence = (
  occurrence: TimelinePositionedOccurrence<unknown>
): DateRange | undefined => {
  if (occurrence.type === 'instant') {
    return dateRangeFromInstantOccurrence(occurrence);
  } else if (occurrence.type === 'prolonged') {
    return dateRangeFromProlongedOccurrence(occurrence);
  } else if (occurrence.type === 'recurring') {
    return dateRangeFromRecurringOccurrence(occurrence);
  } else {
    throw new Error('Unknown occurrence type');
  }
};

export const dateRangeFromInstantOccurrence = (
  occurrence: PositionedInstantOccurrence<unknown>
): DateRange => {
  return {
    startDate: occurrence.executionDate,
    endDate: occurrence.executionDate,
  };
};
export const dateRangeFromProlongedOccurrence = (
  occurrence: PositionedProlongedOccurrence<unknown>
): DateRange => {
  const endDate =
    occurrence.terminationDate ??
    new Date(
      occurrence.executionDate.getTime() +
        (occurrence.durationMinutes ?? 0) * 60 * 1000
    );
  return {
    startDate: occurrence.executionDate,
    endDate,
  };
};

export const dateRangeFromRecurringOccurrence = (
  occurrence: PositionedRecurringOccurrence<unknown>
): DateRange | undefined => {
  const ranges = occurrence.occurrences.map((o) => dateRangeFromOccurrence(o));
  let minDate: Date | undefined = undefined;
  let maxDate: Date | undefined = undefined;
  ranges.forEach((r) => {
    if (r && (minDate === undefined || r.startDate < minDate)) {
      minDate = r.startDate;
    }
    if (r && (maxDate === undefined || r.endDate > maxDate)) {
      maxDate = r.endDate;
    }
  });

  if (!minDate || !maxDate) {
    return undefined;
  }
  return {
    startDate: minDate,
    endDate: maxDate,
  };
};
