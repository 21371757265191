import { ReducerTypes, on } from '@ngrx/store';
import {
  confirmAllTherapiesSuccess,
  confirmTherapySuccess,
  loadTherapiesToConfirmSuccess,
} from '../../../../therapies/therapies-confirmation/store/therapies-confirmation.actions';
import { PatientsState, updateEncounterData } from '../../patients.reducers';

export const patientTherapiesToConfirmReducerFunctions: ReducerTypes<
  PatientsState,
  [never]
>[] = [
  on(
    loadTherapiesToConfirmSuccess,
    (state, { therapies, patientId, encounterId }) => {
      return updateEncounterData(state, { patientId, encounterId }, () => ({
        therapiesToConfirm: therapies,
      }));
    }
  ),
  on(confirmTherapySuccess, (state, { therapyId, patientId, encounterId }) => {
    return updateEncounterData(
      state,
      { patientId, encounterId },
      (encounter) => {
        return {
          therapiesToConfirm: encounter?.therapiesToConfirm?.filter(
            (therapy) => therapy.id !== therapyId
          ),
        };
      }
    );
  }),
  on(
    confirmAllTherapiesSuccess,
    (state, { therapyIds, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounter) => {
          return {
            therapiesToConfirm: encounter?.therapiesToConfirm?.filter(
              (therapy) => !therapyIds.includes(therapy.id)
            ),
          };
        }
      );
    }
  ),
];
