export const datePercentageInRange = (
  date: Date,
  minDate?: Date,
  maxDate?: Date
): number | undefined => {
  if (!minDate || !maxDate) {
    return undefined;
  }
  const time: number = date.getTime();

  const minTime: number = minDate.getTime();
  if (time < minTime) {
    return 0;
  }

  const endTime: number = maxDate.getTime();
  if (time > endTime) {
    return 100;
  }

  return (100 * (time - minTime)) / (endTime - minTime);
};

export function getDateDifference(
  startDate: Date,
  endDate: Date
): DateDifference {
  const startYear: number = startDate.getFullYear();
  const startMonth: number = startDate.getMonth();
  const startDay: number = startDate.getDate();

  const endYear: number = endDate.getFullYear();
  const endMonth: number = endDate.getMonth();
  const endDay: number = endDate.getDate();

  let yearDiff: number = endYear - startYear;
  let monthDiff: number = endMonth - startMonth;
  let dayDiff: number = endDay - startDay;

  if (dayDiff < 0) {
    monthDiff--;
    const prevMonthLastDay: number = new Date(endYear, endMonth, 0).getDate();
    dayDiff += prevMonthLastDay;
  }

  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }

  return { years: yearDiff, months: monthDiff, days: dayDiff };
}

export const now = (): Date => new Date();

export interface DateDifference {
  years: number;
  months: number;
  days: number;
}

export const shortDateString = (date: Date): string =>
  date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
