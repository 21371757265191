import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import {
  UserDepartmentsStatus,
  UserDepartmentsStatusId,
  selectDepartmentsStatus,
} from '../../departments/store/departments.selectors';

export function patientDepartmentsCountGuard(): Observable<boolean> {
  const store: Store = inject(Store);
  return store
    .select(selectDepartmentsStatus)
    .pipe(
      map(
        (status: UserDepartmentsStatusId): boolean =>
          status.status !== UserDepartmentsStatus.NO_DEPARTMENTS
      )
    );
}
