import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TranslocoModule } from '@ngneat/transloco';
import { NateaIconsModule } from '../../icons/natea-icons.module';
import { CheckboxComponent } from '../checkbox/checkbox.component';

export interface TabItem {
  id: string | number;
  data: Record<string, unknown>;
  isChecked: boolean;
  label: string;
  isRequiredFieldHasValue?: boolean;
}

@Component({
  selector: 'natea-folder-tab',
  standalone: true,
  imports: [CommonModule, NateaIconsModule, CheckboxComponent, TranslocoModule],
  templateUrl: './folder-tab.component.html',
  styleUrls: ['./folder-tab.component.scss'],
})
export class FolderTabComponent {
  @Input() title?: string;
  @Input() isMainContentScrollable = true;
  @Input() tabs: TabItem[] = [];
  @Input() hasDote = false;
  @Output() tabSelected: EventEmitter<TabItem> = new EventEmitter<TabItem>();
  @Output() tabClosed: EventEmitter<TabItem> = new EventEmitter<TabItem>();
  @Input() activeIndex: number | null = null;
  @Input() isCloseBle = true;
  @Input() hasCloseTab = true;
  @Input() hasAlwaysDote = false;
  @Input() isTabsDisabled = false;
  @ViewChild('dropdownContainer') dropdownContainer!: ElementRef;

  @ContentChild('[filter]')
  filterContent: unknown;

  hasFilter = false;

  @ViewChild('filter')
  set setFilter(filter: ElementRef | undefined) {
    setTimeout(() => {
      this.hasFilter = !!filter;
    });
  }

  grayScale70 = '#4d4d4d'; //TODO: take value from colors.scss
  showDropDown = false;

  private _transleLabel = '';

  /* ngOnChanges(changes: SimpleChanges): void {
    if (changes['activeIndex'])
      if (this.activeIndex == null && this.tabs.length > 0) {
        this.activeIndex = 0;
      }
  }*/

  dropDownToggle = (): void => {
    this.showDropDown = !this.showDropDown;
  };

  hasSibling = (): boolean => {
    return this.hasFilter;
  };

  onClick = (event: Event): void => {
    if (
      !this.dropdownContainer?.nativeElement.contains(event.target) &&
      this.showDropDown
    ) {
      this.showDropDown = false;
    }
  };

  onCheckboxChange = (
    checkBoxChange: MatCheckboxChange,
    index: number
  ): void => {
    if (index !== 0) {
      if (checkBoxChange.checked) {
        this.showAndSwitchToTab(index);
      } else {
        this.closeTabItem(index);
      }
      this.showDropDown = false;
    }
  };

  closeTabItem = (index: number): void => {
    if (index !== 0) {
      this.tabs[index].isChecked = false;
      this.tabClosed.emit(this.tabs[index]);
      if (this.activeIndex === index) {
        this.showAndSwitchToTab(0);
      }
    }
  };

  showAndSwitchToTab = (index: number): void => {
    if (index !== this.activeIndex && !this.tabs[index].isChecked) {
      this.tabs[index].isChecked = true;
      this.activeIndex = index;
      this.tabSelected.emit(this.tabs[index]);
    } else {
      this.activeIndex = index;
      this.tabSelected.emit(this.tabs[index]);
    }
  };
}
