import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  MatProgressBarModule,
  ProgressAnimationEnd,
  ProgressBarMode,
} from '@angular/material/progress-bar';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'natea-progress-bar',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule, TranslocoModule],
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() bufferValue = 0;
  @Input() mode: ProgressBarMode = 'indeterminate';
  @Input() value = 0;
  @Output() animationEnd: EventEmitter<ProgressAnimationEnd> =
    new EventEmitter();
}
