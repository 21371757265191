import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TranslocoService } from '@ngneat/transloco';
import { ButtonVariants, SizeType } from 'natea-components';
import { Observable, combineLatest, map, of } from 'rxjs';
import { shownName } from '../../../../shared/entities/user';
import { daysHoursMinutesFromDurationMinutes } from '../../../../shared/utils/duration-utils';
import { PatientProblem } from '../../interfaces/patient-problem';

@Component({
  selector: 'natea-cc-problems-table',
  templateUrl: './problems-table.component.html',
  styleUrls: ['./problems-table.component.scss'],
})
export class ProblemsTableComponent implements OnChanges, AfterViewInit {
  buttonVariants = ButtonVariants;

  @Input() problems: PatientProblem[] = [];

  @Input() isAppliedFilter = false;
  @Output() deleteProblem: EventEmitter<PatientProblem> =
    new EventEmitter<PatientProblem>();
  @Output() edit: EventEmitter<PatientProblem> =
    new EventEmitter<PatientProblem>();

  @Output() info: EventEmitter<void> = new EventEmitter<void>();

  @Output() consultation: EventEmitter<PatientProblem> =
    new EventEmitter<PatientProblem>();

  @Output() sortChanged: EventEmitter<Sort | undefined> = new EventEmitter<
    Sort | undefined
  >();

  @ViewChild('problemsTable') problemsTable!: ElementRef;

  isAnyData = true;

  // sortedProblems: PatientProblem[] = [];
  showModal = false;
  problemToDelete?: PatientProblem;

  isDesktopSized = false;
  desktopModal: SizeType = SizeType.LARGE;
  tabletModal: SizeType = SizeType.MEDIUM;

  initialSelection: PatientProblem[] = [];
  allowMultiSelect = true;

  isOverflowing = false;

  selection: SelectionModel<PatientProblem> =
    new SelectionModel<PatientProblem>(
      this.allowMultiSelect,
      this.initialSelection
    );

  displayedColumns: string[] = [
    'select',
    'endDate',
    'problemDescription',
    'startDate',
    'problemType',
    'userInsertId',
    'duration',
    'actions',
  ];

  constructor(private translocoService: TranslocoService) {}

  ngAfterViewInit(): void {
    setTimeout(this.checkOverflow);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['problems']?.currentValue) {
      this.sortTable();
      if (this.selection.selected.length) {
        this.selection.clear();
      }

      if (!this.problems.length) {
        this.isAnyData = false;
      } else {
        this.isAnyData = true;
      }
    }
    setTimeout(this.checkOverflow);
  }

  checkOverflow = (): void => {
    this.isOverflowing =
      this.problemsTable.nativeElement.offsetHeight +
        this.problemsTable.nativeElement.scrollTop <
      this.problemsTable.nativeElement.scrollHeight;
  };

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected = (): boolean => {
    const numSelected: number = this.selection.selected.length;
    const numRows: number = this.problems.length;
    return numSelected == numRows;
  };

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows = (): void => {
    this.isAllSelected()
      ? this.selection.clear()
      : this.problems.forEach((problem: PatientProblem) => {
          this.selection.select(problem);
        });
  };

  canTerminateProblems = (): boolean => {
    return (
      this.selection.selected.length > 0 &&
      this.selection.selected.every(
        (problem: PatientProblem): boolean => !problem.endDate
      )
    );
  };

  canDeleteProblems = (): boolean => {
    return (
      this.selection.selected.length > 0 &&
      this.selection.selected.every(
        (problem: PatientProblem): boolean => !!problem.cancelableFromLoggedUser
      )
    );
  };

  terminateProblems = (): void => {
    if (this.canTerminateProblems()) {
    }
  };

  deleteProblems = (): void => {
    if (this.canDeleteProblems()) {
    }
  };

  problemDuration = (problem: PatientProblem): Observable<string> => {
    if (!problem.startDate) {
      return of('-');
    }

    const delta: number =
      ((problem.endDate ?? Date.now()).valueOf() -
        problem.startDate.valueOf()) /
      1000 /
      60;

    if (delta == null || delta < 0) {
      return of('-');
    }

    if (delta < 1) {
      return this.translocoService.selectTranslate(
        'problems.durationZeroLabel'
      );
    }

    const {
      days,
      hours,
      minutes,
    }: { days: number; hours: number; minutes: number } =
      daysHoursMinutesFromDurationMinutes(delta);

    return combineLatest([
      this.translocoService.selectTranslate('problems.durationDaysLabel', {
        days: `${days}`.padStart(2, '0'),
      }),
      this.translocoService.selectTranslate('problems.durationHoursLabel', {
        hours: `${hours}`.padStart(2, '0'),
      }),
      this.translocoService.selectTranslate('problems.durationMinutesLabel', {
        minutes: `${minutes}`.padStart(2, '0'),
      }),
    ]).pipe(
      map(
        ([daysString, hoursString, minutesString]: [
          string,
          string,
          string
        ]): string => {
          const label: string[] = [];
          if (days) {
            label.push(daysString);
          }
          if (hours) {
            label.push(hoursString);
          }
          if (minutes) {
            label.push(minutesString);
          }
          return label.join(' ');
        }
      )
    );
  };

  sortTable = (sortState?: Sort): void => {
    if (sortState) {
      this.sortChanged.emit(sortState);
    }
  };

  // FIXME: use a translation instead, so that different languages can use different name/surname order
  userShownName = shownName;
}
