import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoModule } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  AutoCompleteComponent,
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  DropdownItemComponent,
  InputComponent,
  LoaderComponent,
  NateaIconsRegistry,
  TabItemComponent,
  TabMenuItemsComponent,
} from 'natea-components';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfigService } from './core/config/config.service';
import {
  BASE_PATH,
  ClinicalNoteApiService,
  DetectedProblemApiService,
  EncounterApiService,
  ProblemCatalogApiService,
  UserStorageApiService,
} from './core/generated/api';
import { BoardEffects } from './features/board/store/board.effects';
import { ClinicalNoteEffects } from './features/clinical-note/store/clinical-note.effects';
import { DepartmentsModule } from './features/departments/departments.module';
import { DepartmentsEffects } from './features/departments/store/departments.effects';
import { LoginEffects } from './features/login/store/login.effects';
import { NursingGanttEffects } from './features/nurse-records/nursing-gantt/store/nursing-gantt.effects';
import { PatientsModule } from './features/patients/patients.module';
import { PatientsEffects } from './features/patients/store/patients.effects';
import { ProblemsModule } from './features/problems/problems.module';
import { ProblemsEffects } from './features/problems/store/problems.effects';
import { ProceduresEffects } from './features/procedures/store/procedures.effects';
import { ErrorInterceptor } from './core/api/error-interceptor';
import { TimeoutInterceptor } from './core/api/timeout-interceptor';
import {
  BasePathFactory,
  ClinicalnotesApiServiceFactory,
  DepartmentsApiServiceFactory,
  DetectedProblemsApiServiceFactory,
  EncounterApiServiceFactory,
  PatientsApiServiceFactory,
  ProblemsCatalogApiServiceFactory,
  UserStorageApiServiceFactory,
} from './shared/factories/factories';
import { metaReducers, reducers } from './store/app.reducers';
import { TranslocoRootModule } from './transloco-root.module';
import { NotesValidationEffects } from './features/clinical-note/notes-validation/store/notes-validation.effects';
import { registerIcons } from './shared/icons/register-icons';
import { PharmacologicalTherapiesEffects } from './features/therapies/pharmacological-therapies/store/pharmacological-therapies.effects';
import { InstrumentalTherapiesEffects } from './features/therapies/instrumental-therapies/store/instrumental-therapies.effects';
import { NutritionalTherapiesEffects } from './features/therapies/nutritional-therapies/store/nutritional-therapies.effects';
import { TransfusionalTherapiesEffects } from './features/therapies/transfusional-therapies/store/transfusional-therapies.effects';
import { DomainsEffects } from './core/domains/store/domains.effects';
import { AuthInterceptor } from './core/api/auth/auth-interceptor';
import { DepartmentsWebApi } from './features/departments/webapis/departments-web-api';
import { PatientsWebApi } from './features/patients/webapi/patient-web-api';
import { TherapiesConfirmationEffects } from './features/therapies/therapies-confirmation/store/therapies-confirmation.effects';
import { PharmacologicalTherapiesAdministrationEffects } from './features/nurse-records/therapies-administration/pharmacological-therapies-administration/store/pharmacological-therapies-administration.effects';
import { FactSheetEffects } from './features/anamnesis/new-born/store/new-born.effects';
import { FamilyAnamnesisEffects } from './features/anamnesis/family-anamnesis/store/family-anamnesis.effects';
import { PregnancyEffects } from './features/anamnesis/pregnancy/store/pregnancy.effects';

//TODO: se si vuole inserire un'altra timezone, aggiungere qui il locale e nel file di environment
registerLocaleData(localeIt, 'it-IT');
registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: ConfigService) => {
        return () => {
          return appConfigService.loadAppConfig();
        };
      },
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: BASE_PATH,
      useFactory: BasePathFactory,
      deps: [ConfigService],
    },
    MatSnackBar,
    {
      provide: ProblemCatalogApiService,
      useClass: ProblemsCatalogApiServiceFactory(),
    },
    {
      provide: DetectedProblemApiService,
      useClass: DetectedProblemsApiServiceFactory(),
    },
    {
      provide: DepartmentsWebApi,
      useClass: DepartmentsApiServiceFactory(),
    },
    {
      provide: PatientsWebApi,
      useClass: PatientsApiServiceFactory(),
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: ClinicalNoteApiService,
      useClass: ClinicalnotesApiServiceFactory(),
    },
    {
      provide: UserStorageApiService,
      useClass: UserStorageApiServiceFactory(),
    },
    {
      provide: EncounterApiService,
      useClass: EncounterApiServiceFactory(),
    },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CheckboxComponent,
    BrowserAnimationsModule,
    LoaderComponent,
    TabMenuItemsComponent,
    ButtonComponent,
    AutoCompleteComponent,
    MatSnackBarModule,
    DropdownComponent,
    InputComponent,
    DropdownItemComponent,
    TabItemComponent,
    ProblemsModule,
    DepartmentsModule,
    TranslocoRootModule,
    TranslocoModule,
    HttpClientModule,
    PatientsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([
      ProblemsEffects,
      ClinicalNoteEffects,
      BoardEffects,
      PatientsEffects,
      LoginEffects,
      DepartmentsEffects,
      NursingGanttEffects,
      ProceduresEffects,
      NotesValidationEffects,
      PharmacologicalTherapiesEffects,
      NutritionalTherapiesEffects,
      InstrumentalTherapiesEffects,
      TransfusionalTherapiesEffects,
      DomainsEffects,
      TherapiesConfirmationEffects,
      PharmacologicalTherapiesAdministrationEffects,
      FactSheetEffects,
      FamilyAnamnesisEffects,
      PregnancyEffects,
    ]),
    StoreRouterConnectingModule.forRoot(),
  ],
})
export class AppModule {
  constructor(nateaIconRegistry: NateaIconsRegistry) {
    registerIcons(nateaIconRegistry);
  }
}
