import { Component } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'natea-cc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(dateAdapter: DateAdapter<Date>) {
    dateAdapter.setLocale(environment.defaultLocale); // TODO get locale from user
  }
}
