import { ReducerTypes, on } from '@ngrx/store';
import { PatientsState, updateEncounterData } from '../../patients.reducers';
import {
  loadAllFactSheetNewBornSuccess,
  saveFactSheetNewBornSuccess,
} from 'src/app/features/anamnesis/new-born/store/new-born.actions';

export const patientNewBornFactSheetReducerFunctions: ReducerTypes<
  PatientsState,
  [never]
>[] = [
  on(
    loadAllFactSheetNewBornSuccess,
    (state, { factSheet, patientId, encounterId }) => {
      return updateEncounterData(state, { patientId, encounterId }, () => ({
        newBornFactSheet: factSheet
          ? {
              ...factSheet,
            }
          : undefined,
      }));
    }
  ),

  on(
    saveFactSheetNewBornSuccess,
    (state, { factSheet, patientId, encounterId }) => {
      return updateEncounterData(state, { patientId, encounterId }, () => ({
        newBornFactSheet: {
          ...factSheet,
        },
      }));
    }
  ),
];
