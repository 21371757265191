import { ReducerTypes, on } from '@ngrx/store';

import { PharmacologicalTherapy } from '../../../../therapies/pharmacological-therapies/entities/pharmacological-therapy';

import {
  confirmCancelPharmacologicalTherapySuccess,
  confirmDeletePharmacologicalTherapySuccess,
  createPharmacologicalTherapySuccess,
  editPharmacologicalTherapySuccess,
  loadPharmacologicalTherapiesSuccess,
  pharmacologicalTherapySuspensionSuccess,
} from '../../../../therapies/pharmacological-therapies/store/pharmacological-therapies.actions';
import { PatientsState, updateEncounterData } from '../../patients.reducers';

export const patientPharmacologicalTherapiesReducerFunctions: ReducerTypes<
  PatientsState,
  [never]
>[] = [
  on(
    loadPharmacologicalTherapiesSuccess,
    (state, { therapies, patientId, encounterId }) => {
      return updateEncounterData(state, { patientId, encounterId }, () => ({
        pharmacologicalTherapies: therapies,
      }));
    }
  ),

  on(
    createPharmacologicalTherapySuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          pharmacologicalTherapies: [
            ...(encounterData?.pharmacologicalTherapies ?? []),
            therapy,
          ],
        })
      );
    }
  ),

  on(
    editPharmacologicalTherapySuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          pharmacologicalTherapies:
            encounterData?.pharmacologicalTherapies?.map((t) =>
              t.id === therapy.id ? therapy : t
            ),
        })
      );
    }
  ),

  on(
    pharmacologicalTherapySuspensionSuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          pharmacologicalTherapies:
            encounterData?.pharmacologicalTherapies?.map((t) =>
              t.id === therapy.id ? therapy : t
            ),
        })
      );
    }
  ),

  on(
    confirmDeletePharmacologicalTherapySuccess,
    (
      state,
      {
        therapyId,
        patientId,
        encounterId,
      }: {
        therapyId: string | undefined;
        patientId: string;
        encounterId: string;
      }
    ) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          pharmacologicalTherapies: (
            encounterData?.pharmacologicalTherapies ?? []
          ).filter((t: PharmacologicalTherapy): boolean => t.id !== therapyId),
        })
      );
    }
  ),

  on(
    confirmCancelPharmacologicalTherapySuccess,
    (state, { therapy, patientId, encounterId }) => {
      return updateEncounterData(
        state,
        { patientId, encounterId },
        (encounterData) => ({
          pharmacologicalTherapies:
            encounterData?.pharmacologicalTherapies?.map((t) =>
              t.id === therapy?.id ? therapy : t
            ),
        })
      );
    }
  ),
];
