/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ErrorResponse } from '../model/errorResponse';
// @ts-ignore
import { GeneralResponseListProcedureResource } from '../model/generalResponseListProcedureResource';
// @ts-ignore
import { GeneralResponseProcedureDeleteResource } from '../model/generalResponseProcedureDeleteResource';
// @ts-ignore
import { GeneralResponseProcedureResource } from '../model/generalResponseProcedureResource';
// @ts-ignore
import { ProcedureExecutionContextRequestResource } from '../model/procedureExecutionContextRequestResource';
// @ts-ignore
import { ProcedureScheduleContextRequestResource } from '../model/procedureScheduleContextRequestResource';
// @ts-ignore
import { ProcedureTerminationContextRequestResource } from '../model/procedureTerminationContextRequestResource';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ProcedureApiService {

    protected basePath = 'http://localhost:8090/nateaccpl/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * create procedure execution context
     * Creates the execution context of a scheduled procedure associated with a patient.
     * @param patientId Unique identifier of the patient associated with the scheduled procedure.
     * @param procedureId Unique identifier of the procedure within which the execution context is to be created.
     * @param procedureExecutionContextRequestResource Request object for creating and updating the execution context of a procedure.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProcedureExecutionContext(patientId: string, procedureId: string, procedureExecutionContextRequestResource: ProcedureExecutionContextRequestResource, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseProcedureResource>;
    public createProcedureExecutionContext(patientId: string, procedureId: string, procedureExecutionContextRequestResource: ProcedureExecutionContextRequestResource, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseProcedureResource>>;
    public createProcedureExecutionContext(patientId: string, procedureId: string, procedureExecutionContextRequestResource: ProcedureExecutionContextRequestResource, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseProcedureResource>>;
    public createProcedureExecutionContext(patientId: string, procedureId: string, procedureExecutionContextRequestResource: ProcedureExecutionContextRequestResource, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling createProcedureExecutionContext.');
        }
        if (procedureId === null || procedureId === undefined) {
            throw new Error('Required parameter procedureId was null or undefined when calling createProcedureExecutionContext.');
        }
        if (procedureExecutionContextRequestResource === null || procedureExecutionContextRequestResource === undefined) {
            throw new Error('Required parameter procedureExecutionContextRequestResource was null or undefined when calling createProcedureExecutionContext.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/procedure/${this.configuration.encodeParam({name: "procedureId", value: procedureId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/execution`;
        return this.httpClient.request<GeneralResponseProcedureResource>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: procedureExecutionContextRequestResource,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create procedure termination context
     * Creates the termination context of an executed procedure associated with a patient.
     * @param patientId Unique identifier of the patient associated with the executed procedure.
     * @param endEncounterId Unique identifier of the hospitalization in which the procedure was terminated.
     * @param procedureId Unique identifier of the procedure within which the termination context is to be created.
     * @param procedureTerminationContextRequestResource Request object for creating and updating the termination context of a procedure.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProcedureTerminationContext(patientId: string, endEncounterId: string, procedureId: string, procedureTerminationContextRequestResource: ProcedureTerminationContextRequestResource, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseProcedureResource>;
    public createProcedureTerminationContext(patientId: string, endEncounterId: string, procedureId: string, procedureTerminationContextRequestResource: ProcedureTerminationContextRequestResource, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseProcedureResource>>;
    public createProcedureTerminationContext(patientId: string, endEncounterId: string, procedureId: string, procedureTerminationContextRequestResource: ProcedureTerminationContextRequestResource, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseProcedureResource>>;
    public createProcedureTerminationContext(patientId: string, endEncounterId: string, procedureId: string, procedureTerminationContextRequestResource: ProcedureTerminationContextRequestResource, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling createProcedureTerminationContext.');
        }
        if (endEncounterId === null || endEncounterId === undefined) {
            throw new Error('Required parameter endEncounterId was null or undefined when calling createProcedureTerminationContext.');
        }
        if (procedureId === null || procedureId === undefined) {
            throw new Error('Required parameter procedureId was null or undefined when calling createProcedureTerminationContext.');
        }
        if (procedureTerminationContextRequestResource === null || procedureTerminationContextRequestResource === undefined) {
            throw new Error('Required parameter procedureTerminationContextRequestResource was null or undefined when calling createProcedureTerminationContext.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (endEncounterId !== undefined && endEncounterId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endEncounterId, 'endEncounterId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/procedure/${this.configuration.encodeParam({name: "procedureId", value: procedureId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/termination`;
        return this.httpClient.request<GeneralResponseProcedureResource>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: procedureTerminationContextRequestResource,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create procedure with schedule context
     * Creates a new procedure with a schedule context, associating the patient and the initial hospitalization of the procedure.
     * @param patientId Unique identifier of the patient for whom the procedure is to be created.
     * @param startEncounterId Unique identifier of the hospitalization in which the procedure was created.
     * @param procedureScheduleContextRequestResource Request object for creating and updating the schedule context of a procedure.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProcedureWithScheduleContext(patientId: string, startEncounterId: string, procedureScheduleContextRequestResource: ProcedureScheduleContextRequestResource, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseProcedureResource>;
    public createProcedureWithScheduleContext(patientId: string, startEncounterId: string, procedureScheduleContextRequestResource: ProcedureScheduleContextRequestResource, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseProcedureResource>>;
    public createProcedureWithScheduleContext(patientId: string, startEncounterId: string, procedureScheduleContextRequestResource: ProcedureScheduleContextRequestResource, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseProcedureResource>>;
    public createProcedureWithScheduleContext(patientId: string, startEncounterId: string, procedureScheduleContextRequestResource: ProcedureScheduleContextRequestResource, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling createProcedureWithScheduleContext.');
        }
        if (startEncounterId === null || startEncounterId === undefined) {
            throw new Error('Required parameter startEncounterId was null or undefined when calling createProcedureWithScheduleContext.');
        }
        if (procedureScheduleContextRequestResource === null || procedureScheduleContextRequestResource === undefined) {
            throw new Error('Required parameter procedureScheduleContextRequestResource was null or undefined when calling createProcedureWithScheduleContext.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startEncounterId !== undefined && startEncounterId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startEncounterId, 'startEncounterId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/procedure`;
        return this.httpClient.request<GeneralResponseProcedureResource>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: procedureScheduleContextRequestResource,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete procedure execution context
     * Deletes the execution context of an executed procedure associated with a patient.
     * @param patientId Unique identifier of the patient associated with the executed procedure.
     * @param procedureId Unique identifier of the procedure from which the execution context is to be deleted.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProcedureExecutionContext(patientId: string, procedureId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseProcedureResource>;
    public deleteProcedureExecutionContext(patientId: string, procedureId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseProcedureResource>>;
    public deleteProcedureExecutionContext(patientId: string, procedureId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseProcedureResource>>;
    public deleteProcedureExecutionContext(patientId: string, procedureId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling deleteProcedureExecutionContext.');
        }
        if (procedureId === null || procedureId === undefined) {
            throw new Error('Required parameter procedureId was null or undefined when calling deleteProcedureExecutionContext.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/procedure/${this.configuration.encodeParam({name: "procedureId", value: procedureId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/execution`;
        return this.httpClient.request<GeneralResponseProcedureResource>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete procedure termination context
     * Deletes the termination context of a terminated procedure associated with a patient.
     * @param patientId Unique identifier of the patient associated with the terminated procedure.
     * @param procedureId Unique identifier of the procedure from which the termination context is to be deleted.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProcedureTerminationContext(patientId: string, procedureId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseProcedureResource>;
    public deleteProcedureTerminationContext(patientId: string, procedureId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseProcedureResource>>;
    public deleteProcedureTerminationContext(patientId: string, procedureId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseProcedureResource>>;
    public deleteProcedureTerminationContext(patientId: string, procedureId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling deleteProcedureTerminationContext.');
        }
        if (procedureId === null || procedureId === undefined) {
            throw new Error('Required parameter procedureId was null or undefined when calling deleteProcedureTerminationContext.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/procedure/${this.configuration.encodeParam({name: "procedureId", value: procedureId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/termination`;
        return this.httpClient.request<GeneralResponseProcedureResource>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete procedure with schedule context
     * Deletes a created procedure, along with its schedule context, associated with a patient.
     * @param patientId Unique identifier of the patient associated with the scheduled procedure.
     * @param procedureId Unique identifier of the scheduled procedure to be deleted.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProcedureWithScheduleContext(patientId: string, procedureId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseProcedureDeleteResource>;
    public deleteProcedureWithScheduleContext(patientId: string, procedureId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseProcedureDeleteResource>>;
    public deleteProcedureWithScheduleContext(patientId: string, procedureId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseProcedureDeleteResource>>;
    public deleteProcedureWithScheduleContext(patientId: string, procedureId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling deleteProcedureWithScheduleContext.');
        }
        if (procedureId === null || procedureId === undefined) {
            throw new Error('Required parameter procedureId was null or undefined when calling deleteProcedureWithScheduleContext.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/procedure/${this.configuration.encodeParam({name: "procedureId", value: procedureId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<GeneralResponseProcedureDeleteResource>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get procedure by id
     * Retrieves a specific procedure associated with a patient by unique identifier.
     * @param patientId Unique identifier of the patient for whom the procedure is to be searched.
     * @param procedureId Unique identifier of the procedure to be searched.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProcedureById(patientId: string, procedureId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseProcedureResource>;
    public getProcedureById(patientId: string, procedureId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseProcedureResource>>;
    public getProcedureById(patientId: string, procedureId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseProcedureResource>>;
    public getProcedureById(patientId: string, procedureId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling getProcedureById.');
        }
        if (procedureId === null || procedureId === undefined) {
            throw new Error('Required parameter procedureId was null or undefined when calling getProcedureById.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/procedure/${this.configuration.encodeParam({name: "procedureId", value: procedureId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<GeneralResponseProcedureResource>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search procedures
     * Retrieves procedures associated with a patient. Additional filters can be applied to refine the results, and they are combined using an AND logic.
     * @param patientId Unique identifier of the patient for whom procedures are to be searched.
     * @param startDate The start date for the search of procedures.
     * @param endDate The end date for the search of procedures, if applicable.
     * @param areDeletedProceduresIncluded Indicates whether to include or exclude logically deleted procedures during searching (default: false).
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchProcedures(patientId: string, startDate: string, endDate?: string, areDeletedProceduresIncluded?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseListProcedureResource>;
    public searchProcedures(patientId: string, startDate: string, endDate?: string, areDeletedProceduresIncluded?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseListProcedureResource>>;
    public searchProcedures(patientId: string, startDate: string, endDate?: string, areDeletedProceduresIncluded?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseListProcedureResource>>;
    public searchProcedures(patientId: string, startDate: string, endDate?: string, areDeletedProceduresIncluded?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling searchProcedures.');
        }
        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling searchProcedures.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (areDeletedProceduresIncluded !== undefined && areDeletedProceduresIncluded !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>areDeletedProceduresIncluded, 'areDeletedProceduresIncluded');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/procedures`;
        return this.httpClient.request<GeneralResponseListProcedureResource>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update procedure execution context
     * Updates the execution context of an executed procedure associated with a patient.
     * @param patientId Unique identifier of the patient associated with the executed procedure.
     * @param procedureId Unique identifier of the procedure within which the execution context is to be updated.
     * @param procedureExecutionContextRequestResource Request object for creating and updating the execution context of a procedure.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProcedureExecutionContext(patientId: string, procedureId: string, procedureExecutionContextRequestResource: ProcedureExecutionContextRequestResource, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseProcedureResource>;
    public updateProcedureExecutionContext(patientId: string, procedureId: string, procedureExecutionContextRequestResource: ProcedureExecutionContextRequestResource, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseProcedureResource>>;
    public updateProcedureExecutionContext(patientId: string, procedureId: string, procedureExecutionContextRequestResource: ProcedureExecutionContextRequestResource, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseProcedureResource>>;
    public updateProcedureExecutionContext(patientId: string, procedureId: string, procedureExecutionContextRequestResource: ProcedureExecutionContextRequestResource, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling updateProcedureExecutionContext.');
        }
        if (procedureId === null || procedureId === undefined) {
            throw new Error('Required parameter procedureId was null or undefined when calling updateProcedureExecutionContext.');
        }
        if (procedureExecutionContextRequestResource === null || procedureExecutionContextRequestResource === undefined) {
            throw new Error('Required parameter procedureExecutionContextRequestResource was null or undefined when calling updateProcedureExecutionContext.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/procedure/${this.configuration.encodeParam({name: "procedureId", value: procedureId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/execution`;
        return this.httpClient.request<GeneralResponseProcedureResource>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: procedureExecutionContextRequestResource,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update procedure schedule context
     * Updates the schedule context of a scheduled procedure associated with a patient.
     * @param patientId Unique identifier of the patient associated with the scheduled procedure.
     * @param procedureId Unique identifier of the procedure within which the schedule context is to be updated.
     * @param procedureScheduleContextRequestResource Request object for creating and updating the schedule context of a procedure.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProcedureScheduleContext(patientId: string, procedureId: string, procedureScheduleContextRequestResource: ProcedureScheduleContextRequestResource, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseProcedureResource>;
    public updateProcedureScheduleContext(patientId: string, procedureId: string, procedureScheduleContextRequestResource: ProcedureScheduleContextRequestResource, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseProcedureResource>>;
    public updateProcedureScheduleContext(patientId: string, procedureId: string, procedureScheduleContextRequestResource: ProcedureScheduleContextRequestResource, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseProcedureResource>>;
    public updateProcedureScheduleContext(patientId: string, procedureId: string, procedureScheduleContextRequestResource: ProcedureScheduleContextRequestResource, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling updateProcedureScheduleContext.');
        }
        if (procedureId === null || procedureId === undefined) {
            throw new Error('Required parameter procedureId was null or undefined when calling updateProcedureScheduleContext.');
        }
        if (procedureScheduleContextRequestResource === null || procedureScheduleContextRequestResource === undefined) {
            throw new Error('Required parameter procedureScheduleContextRequestResource was null or undefined when calling updateProcedureScheduleContext.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/procedure/${this.configuration.encodeParam({name: "procedureId", value: procedureId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/schedule`;
        return this.httpClient.request<GeneralResponseProcedureResource>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: procedureScheduleContextRequestResource,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update procedure termination context
     * Updates the termination context of a terminated procedure associated with a patient.
     * @param patientId Unique identifier of the patient associated with the terminated procedure.
     * @param procedureId Unique identifier of the procedure within which the termination context is to be updated.
     * @param procedureTerminationContextRequestResource Request object for creating and updating the termination context of a procedure.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProcedureTerminationContext(patientId: string, procedureId: string, procedureTerminationContextRequestResource: ProcedureTerminationContextRequestResource, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GeneralResponseProcedureResource>;
    public updateProcedureTerminationContext(patientId: string, procedureId: string, procedureTerminationContextRequestResource: ProcedureTerminationContextRequestResource, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GeneralResponseProcedureResource>>;
    public updateProcedureTerminationContext(patientId: string, procedureId: string, procedureTerminationContextRequestResource: ProcedureTerminationContextRequestResource, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GeneralResponseProcedureResource>>;
    public updateProcedureTerminationContext(patientId: string, procedureId: string, procedureTerminationContextRequestResource: ProcedureTerminationContextRequestResource, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling updateProcedureTerminationContext.');
        }
        if (procedureId === null || procedureId === undefined) {
            throw new Error('Required parameter procedureId was null or undefined when calling updateProcedureTerminationContext.');
        }
        if (procedureTerminationContextRequestResource === null || procedureTerminationContextRequestResource === undefined) {
            throw new Error('Required parameter procedureTerminationContextRequestResource was null or undefined when calling updateProcedureTerminationContext.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/procedure/${this.configuration.encodeParam({name: "procedureId", value: procedureId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/termination`;
        return this.httpClient.request<GeneralResponseProcedureResource>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: procedureTerminationContextRequestResource,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
