<mat-form-field
  [color]="isError ? 'warn' : undefined"
  [class.no-hint]="hintLabel === undefined || hintLabel"
>
  <mat-label *ngIf="label" [class.filled]="isAddClass">{{ label }}</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input
      matStartDate
      matInput
      [placeholder]="placeholder"
      [errorStateMatcher]="errorStateMatcher"
      [value]="inputValue"
      [required]="required"
      [disabled]="isDisabled"
      [max]="maxDate"
      [min]="minDate"
      (dateChange)="onStartDateChanged($event)"
      (dateInput)="dateInputStart.emit($event)"
    />
    <input
      matEndDate
      matInput
      [placeholder]="placeholderEndDate"
      [value]="inputValueEndDate"
      [required]="requiredEndDate"
      [disabled]="disabledEndDate"
      [errorStateMatcher]="errorStateMatcherDateEnd"
      [max]="maxEndDate"
      [min]="minEndDate"
      (dateChange)="onEndDateChanged($event)"
      (dateInput)="dateInputEnd.emit($event)"
    />
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix [for]="picker">
    <natea-icons
      name="calendar"
      class="icon"
      matDatepickerToggleIcon
    ></natea-icons>
  </mat-datepicker-toggle>
  <mat-date-range-picker
    #picker
    [color]="color"
    [disabled]="disabledDatePicker"
    [calendarHeaderComponent]="calendarHeaderComponent"
    [opened]="isOpened"
    [panelClass]="panelClass"
    [restoreFocus]="restoreFocus"
    [startView]="startView"
    [touchUi]="touchUi"
    (openedStream)="opened.emit($event)"
    (viewChanged)="viewChanged.emit($event)"
    (monthSelected)="monthSelected.emit($event)"
    (yearSelected)="yearSelected.emit($event)"
    (closed)="closed.emit($event)"
  >
    <mat-date-range-picker-actions>
      <natea-button
        matDateRangePickerCancel
        (click)="restore()"
        [color]="variantColor"
        [label]="buttonLabelCancel"
      >
        {{ buttonLabelCancel }}
      </natea-button>
      <natea-button
        class="apply-button"
        matDateRangePickerApply
        (click)="apply.emit()"
        [label]="buttonLabelApply"
      >
        {{ buttonLabelApply }}
      </natea-button>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
</mat-form-field>
<div class="hintosition">
  <mat-hint
    *ngIf="hintLabel"
    [class.warn]="isError"
    [class.disabled]="isDisabled"
    class="hintPosition"
    >{{ hintLabel }}</mat-hint
  >
</div>
