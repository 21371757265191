import { ProfessionalCategory } from '../../../../shared/entities/professional-category';
import {
  TherapyDurationType,
  TherapyOccurrenceState,
} from '../../common/entities/therapy-occurrence';
import { baseTherapyMock } from '../../common/mocks/therapy.mock';
import { InstrumentalTherapy } from '../entities/instrumental-therapy';
import { InstrumentalTherapyType } from '../entities/instrumental-therapy-type';
import { mockEncounterDay } from '../../../../shared/utils/mock-utils';

const randomId = () => {
  return Math.floor(Math.random() * 100000).toString();
};

const instrumentalTherapyTypeMock: InstrumentalTherapyType = {
  id: randomId(),
  name: 'Fototerapia',
  isContinuous: true,
  parameters: [],
};

export const instrumentalTherapiesListMock = (): InstrumentalTherapy[] => {
  const createList = (): InstrumentalTherapy[] => [
    {
      ...baseInstrumentalTherapyMock,
      prescriptionDate: mockEncounterDay(5, 10), // RLE
      creationDate: mockEncounterDay(5, 10), // RLE
      insertionDate: mockEncounterDay(5, 10), // RLE
      programmingDate: mockEncounterDay(6, 12), // RLE
      name: 'Fototerapia',
      type: instrumentalTherapyTypeMock,
      id: randomId(),
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.Administered,
          programmingDate:  mockEncounterDay(3, 18),
          executionDate:  mockEncounterDay(3, 18),
          terminationDate:  mockEncounterDay(3, 20),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
          duration: 120,
        },
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.Administered,
          programmingDate:  mockEncounterDay(4, 10),
          executionDate:  mockEncounterDay(4, 10),
          terminationDate:  mockEncounterDay(4, 12),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
          duration: 120,
        },
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.Administered,
          programmingDate:  mockEncounterDay(4, 18),
          executionDate:  mockEncounterDay(4, 18),
          terminationDate:  mockEncounterDay(4, 20),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
          duration: 120,
        },
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.Administered,
          programmingDate:  mockEncounterDay(5, 10),
          executionDate:  mockEncounterDay(5, 10),
          terminationDate:  mockEncounterDay(5, 12),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
          duration: 120,
        },
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.Administered,
          programmingDate:  mockEncounterDay(5, 18),
          executionDate:  mockEncounterDay(5, 18),
          terminationDate:  mockEncounterDay(5, 20),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
          duration: 120,
        },
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.Administered,
          programmingDate:  mockEncounterDay(6, 10),
          executionDate:  mockEncounterDay(6, 10),
          terminationDate:  mockEncounterDay(6, 12),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
          duration: 120,
        },
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.Administered,
          programmingDate:  mockEncounterDay(6, 18),
          executionDate:  mockEncounterDay(6, 18),
          terminationDate:  mockEncounterDay(6, 20),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
          duration: 120,
        },
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.Administered,
          programmingDate:  mockEncounterDay(7, 10),
          executionDate:  mockEncounterDay(7, 10),
          terminationDate:  mockEncounterDay(7, 12),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
          duration: 120,
        },
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.Administered,
          programmingDate:  mockEncounterDay(7, 18),
          executionDate:  mockEncounterDay(7, 18),
          terminationDate:  mockEncounterDay(7, 20),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
          duration: 120,
        },
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.Administered,
          programmingDate:  mockEncounterDay(8, 10),
          executionDate:  mockEncounterDay(8, 10),
          terminationDate:  mockEncounterDay(8, 12),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
          duration: 120,
        },
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.Administered,
          programmingDate:  mockEncounterDay(8, 18),
          executionDate:  mockEncounterDay(8, 18),
          terminationDate:  mockEncounterDay(8, 20),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
          duration: 120,
        },
      ],
      durationMinutes: 120,
      patientWeightKg: 2.3,
      bsa: 1,
    },

    {
      ...baseInstrumentalTherapyMock,
      name: 'Ventilazione HFO',
      prescriptionDate: mockEncounterDay(7, 10), // RLE
      creationDate: mockEncounterDay(7, 10), // RLE
      insertionDate: mockEncounterDay(8, 10), // RLE
      programmingDate: mockEncounterDay(8, 12), // RLE

      patientWeightKg: 2.3,
      type: {
        id: randomId(),
        name: 'Ventilazione HFO',
        isContinuous: true,
        parameters: [],
      },
      bsa: 1,
      id: randomId(),
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate:  mockEncounterDay(8, 12), // RLE
          executionDate:  mockEncounterDay(8, 16), // RLE
          terminationDate:  mockEncounterDay(11, 16), // RLE
         durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '2.30',
          duration: undefined,
        },
      ],
      durationMinutes: 0,
    },

    // 5.1.1 • Terapia estemporanea (istantanea) PREPARATA MA NON ANCORA ESEGUITA
    /*{
      ...baseInstrumentalTherapyMock,
      id: randomId(),
      name: 'Terapie fisiche',
      type: {
        id: randomId(),
        name: 'Therapy type 2',
        isContinuous: true,
        parameters: [
          {
            id: '12345',
            name: 'Parameter 1',
            measureUnit: {
              id: randomId(),
              name: 'Measure unit 1',
              shortDescription: 'Measure unit 1',
              symbol: 'MU1',
            },
          },
        ],
      },
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.Prepared,
          programmingDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 2),
          durationType: TherapyDurationType.Instant,
          isValid: true,
          weightKg: '10',
        },
      ],
    },

    // 5.1.11 • Terapia estemporanea prolungata ANNULLATA​
    {
      ...baseInstrumentalTherapyMock,
      type: {
        id: randomId(),
        name: 'Terapie respiratorie',
        isContinuous: true,
        parameters: [
          {
            id: '12345',
            name: 'Parameter 1',
            measureUnit: {
              id: randomId(),
              name: 'Measure unit 1',
              shortDescription: 'Measure unit 1',
              symbol: 'MU1',
            },
          },
        ],
      },
      id: randomId(),

      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.Aborted,
          programmingDate: new Date(Date.now() + 2200 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 3200 * 60 * 60 * 24),
          executionDate: new Date(Date.now() + 1600 * 60 * 60 * 24),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
        },
      ],
    },

    // 5.1.11 • Terapia estemporanea prolungata ANNULLATA​
    {
      ...baseInstrumentalTherapyMock,
      type: {
        id: randomId(),
        name: 'Cure della pelle',
        isContinuous: true,
        parameters: [
          {
            id: '12345',
            name: 'Parameter 1',
            measureUnit: {
              id: randomId(),
              name: 'Measure unit 1',
              shortDescription: 'Measure unit 1',
              symbol: 'MU1',
            },
          },
        ],
      },
      id: randomId(),
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() + 2200 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 4200 * 60 * 60 * 24),
          durationType: TherapyDurationType.Prolonged,
          executionDate: new Date(Date.now() + 1500 * 60 * 60 * 24),
          isValid: true,
          weightKg: '10',
        },
      ],

      //therapyType: {
            //canBePrescribedAtNeed: randomBool(),
            //id: randomId(),
            //name: 'Caffeina',
          //  description: 'Caffeina 20 mg/kg',
        //  },
    },

    // 5.1.11 • Terapia estemporanea prolungata ANNULLATA​
    {
      ...baseInstrumentalTherapyMock,
      type: {
        id: randomId(),
        name: 'Vaccinazioni',
        isContinuous: true,
        parameters: [
          {
            id: '12345',
            name: 'Parameter 1',
            measureUnit: {
              id: randomId(),
              name: 'Measure unit 1',
              shortDescription: 'Measure unit 1',
              symbol: 'MU1',
            },
          },
        ],
      },
      id: randomId(),
      name: 'Allattamento al seno',
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() + 1200 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 2200 * 60 * 60 * 24),
          executionDate: new Date(Date.now() + 1300 * 60 * 60 * 24),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
        },
      ],
    },

    // 5.1.11 • Terapia estemporanea prolungata ANNULLATA​
    {
      ...baseInstrumentalTherapyMock,
      type: {
        id: randomId(),
        name: 'Allattamento al biberon',
        isContinuous: true,
        parameters: [
          {
            id: '12345',
            name: 'Parameter 1',
            measureUnit: {
              id: randomId(),
              name: 'Measure unit 1',
              shortDescription: 'Measure unit 1',
              symbol: 'MU1',
            },
          },
        ],
      },
      problem: undefined,
      id: randomId(),
      name: 'Cambio regolare del pannolino',
      repetition: {
        id: randomId(),
        intervalSpanMinutes: 30,
        label: 'Ogni 30 minuti',
      },
      forDays: 5,
      durationMinutes: 330,
      notes: 'Note 1',
      patientWeightKg: 10,
      bsa: 3.5,
      prescriptionDate: new Date(
        Date.now() - 4000 * 60 * 60 * 24 - 20 * 60 * 1000
      ),
      programmingDate: new Date(
        Date.now() - 3000 * 60 * 60 * 24 - 50 * 60 * 1000
      ),
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() - 1500 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 2200 * 60 * 60 * 24),
          executionDate: new Date(Date.now() + 1200 * 60 * 60 * 24),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
        },
      ],
    },

    // 5.1.11 • Terapia estemporanea prolungata ANNULLATA​
    {
      ...baseInstrumentalTherapyMock,
      id: randomId(),
      name: 'Vaccinazioni programmate',
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() - 1000 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 2200 * 60 * 60 * 24),
          executionDate: new Date(Date.now() + 1000 * 60 * 60 * 24),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
        },
      ],
    },

    // 5.1.11 • Terapia estemporanea prolungata ANNULLATA​
    {
      ...baseInstrumentalTherapyMock,
      id: randomId(),
      name: 'Controllo regolare del peso e della crescita',
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() - 900 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 1200 * 60 * 60 * 24),
          executionDate: new Date(Date.now() + 100 * 60 * 60 * 24),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
        },
      ],
    },

    // 5.1.11 • Terapia estemporanea prolungata ANNULLATA​
    {
      ...baseInstrumentalTherapyMock,
      id: randomId(),
      name: "Terapia fototerapica per l'ittero neonatale",
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() - 300 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 3000 * 60 * 60 * 24),
          executionDate: new Date(Date.now() + 1000 * 60 * 60 * 24),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
        },
      ],
    },
    {
      ...baseInstrumentalTherapyMock,
      id: randomId(),
      name: 'Massaggio neonatale',
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() - 300 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 1300 * 60 * 60 * 24),
          executionDate: new Date(Date.now() + 1100 * 60 * 60 * 24),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
        },
      ],
    },

    // 5.1.11 • Terapia estemporanea prolungata ANNULLATA​
    {
      ...baseInstrumentalTherapyMock,
      id: randomId(),
      name: 'Terapie occupazionali per lo sviluppo sensoriale',
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() - 300 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 1000 * 60 * 60 * 24),
          executionDate: new Date(Date.now() + 800 * 60 * 60 * 24),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
        },
      ],
    },

    // 5.1.11 • Terapia estemporanea prolungata ANNULLATA​
    {
      ...baseInstrumentalTherapyMock,
      id: randomId(),
      name: 'Terapie del linguaggio per sviluppare le abilità comunicative',
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() - 300 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 1000 * 60 * 60 * 24),
          executionDate: new Date(Date.now() + 700 * 60 * 60 * 24),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
        },
      ],
    },

    // 5.1.11 • Terapia estemporanea prolungata ANNULLATA​
    {
      ...baseInstrumentalTherapyMock,
      id: randomId(),
      name: 'Terapie di supporto per prematuri',
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() - 300 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 1000 * 60 * 60 * 24),
          executionDate: new Date(Date.now() + 600 * 60 * 60 * 24),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
        },
      ],
    },

    // 5.1.11 • Terapia estemporanea prolungata ANNULLATA​
    {
      ...baseInstrumentalTherapyMock,
      id: randomId(),
      name: "Monitoraggio dell'apnea e della respirazione",
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() - 300 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 1000 * 60 * 60 * 24),
          executionDate: new Date(Date.now() + 400 * 60 * 60 * 24),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
        },
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() - 300 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 3000 * 60 * 60 * 24),
          executionDate: new Date(Date.now() + 2600 * 60 * 60 * 24),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
        },
      ],
    },

    // 5.1.11 • Terapia estemporanea prolungata ANNULLATA​
    {
      ...baseInstrumentalTherapyMock,
      id: randomId(),
      name: 'Cure della pelle per prevenire irritazioni e eruzioni cutanee',
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() - 300 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 1000 * 60 * 60 * 24),
          executionDate: new Date(Date.now() + 300 * 60 * 60 * 24),
          durationType: TherapyDurationType.Instant,
          isValid: true,
          weightKg: '10',
        },
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() - 300 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 1000 * 60 * 60 * 24),
          executionDate: new Date(Date.now()),
          durationType: TherapyDurationType.Instant,
          isValid: true,
          weightKg: '10',
        },
      ],
    },

    // 5.1.11 • Terapia estemporanea prolungata ANNULLATA​
    {
      ...baseInstrumentalTherapyMock,
      type: {
        id: randomId(),
        name: 'Terapie per problemi gastrointestinali come il reflusso',
        isContinuous: true,
        parameters: [
          {
            id: randomId(),
            name: 'Dosaggio',
            measureUnit: {
              id: randomId(),
              name: 'Measure unit 1',
              shortDescription: 'Measure unit 1',
              symbol: 'MU1',
            },
          },
        ],
      },
      id: randomId(),
      name: 'Terapie ortopediche per problemi muscolo-scheletrici',
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() - 300 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 1000 * 60 * 60 * 24),
          executionDate: new Date(Date.now() + 300 * 60 * 60 * 24),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
        },
      ],
    },

    // 5.1.11 • Terapia estemporanea prolungata ANNULLATA​
    {
      ...baseInstrumentalTherapyMock,
      type: {
        id: randomId(),
        name: 'Terapie per la gestione del dolore neonatale',
        isContinuous: true,
        parameters: [
          {
            id: '12345',
            name: 'Parameter 1',
            measureUnit: {
              id: randomId(),
              name: 'Measure unit 1',
              shortDescription: 'Measure unit 1',
              symbol: 'MU1',
            },
          },
        ],
      },
      id: randomId(),
      name: 'Terapie per la gestione del dolore neonatale',
      occurrences: [
        {
          id: randomId(),
          prescriptionId: randomId(),
          state: TherapyOccurrenceState.InExecution,
          programmingDate: new Date(Date.now() - 300 * 60 * 60 * 24),
          terminationDate: new Date(Date.now() + 1000 * 60 * 60 * 24),
          executionDate: new Date(Date.now() + 500 * 60 * 60 * 24),
          durationType: TherapyDurationType.Prolonged,
          isValid: true,
          weightKg: '10',
        },
      ],
    },*/
  ];

  const list = createList();
  list.forEach((item) => {
    const id = randomId();
    item.id = id;
    item.occurrences.forEach((occurrence) => {
      occurrence.id = randomId();
      occurrence.prescriptionId = id;
    });
  });

  return list;
};

export const baseInstrumentalTherapyMock: InstrumentalTherapy = {
  ...baseTherapyMock,
  specificationDuration: false,
  bsa: 1,
  patientWeightKg: 2.3,
  type: {
    id: randomId(),
    name: '',
    isContinuous: true,
    parameters: [],
  },
  occurrences: [],
  problem: {
    id: randomId(),
    name: 'Prematurità minore di 28 settimane',
    problemClass: ProfessionalCategory.Medical,
    valid: true,
  },
  durationMinutes: 0,
  forDays: 0,
  params: {
    '12345': 'Test',
  },
};
