import { PatientProblem } from '../interfaces/patient-problem';
import { PatientEncounter } from '../interfaces/patient-encounter';

export const isPatientProblemContainedInEncounter = (
  patientProblem: PatientProblem,
  encounter: PatientEncounter
) => {
  const encounterEndDate = encounter.endDate ?? new Date();
  return (
    patientProblem.startDate <= encounterEndDate &&
    (!patientProblem.endDate || patientProblem.endDate >= encounter.startDate)
  );
};
