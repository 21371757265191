import { generateRandomId } from 'natea-components';

export const generateRandomUUID = (): string => {
  const uuid: string = generateRandomId(32);
  let index = 0;
  return [8, 4, 4, 4, 12]
    .map((len: number): string => uuid.substring(index, (index += len)))
    .join('-');
};

export const randomChoice = (array: Array<any>): any =>
  array[Math.floor(Math.random() * array.length)];

const ONE_MINUTE: number = 60 * 1000;
const ONE_HOUR: number = 60 * ONE_MINUTE;
const ONE_DAY: number = 24 * ONE_HOUR;
const ONE_MONTH: number = 30 * ONE_DAY;
const ONE_YEAR: number = 365 * ONE_DAY;

const randomDate = (
  min: number,
  max: number,
  from: Date = new Date(),
  unit: number = ONE_DAY,
  backwards = true
): Date =>
  new Date(
    from.getTime() +
      (Math.random() * (max - min) + min) * unit * (backwards ? -1 : 1)
  );

export const randomDateMinutesBefore = (
  minMinutes: number,
  maxMinutes: number,
  from: Date = new Date()
): Date => randomDate(minMinutes, maxMinutes, from, ONE_MINUTE);

export const randomDateMinutesAfter = (
  minMinutes: number,
  maxMinutes: number,
  from: Date = new Date()
): Date => randomDate(minMinutes, maxMinutes, from, ONE_MINUTE, false);

export const randomDateHoursBefore = (
  minHours: number,
  maxHours: number,
  from: Date = new Date()
): Date => randomDate(minHours, maxHours, from, ONE_HOUR);

export const randomDateHoursAfter = (
  minHours: number,
  maxHours: number,
  from: Date = new Date()
): Date => randomDate(minHours, maxHours, from, ONE_HOUR, false);

export const randomDateDaysBefore = (
  minDays: number,
  maxDays: number,
  from: Date = new Date()
): Date => randomDate(minDays, maxDays, from, ONE_DAY);

export const randomDateDaysAfter = (
  minDays: number,
  maxDays: number,
  from: Date = new Date()
): Date => randomDate(minDays, maxDays, from, ONE_DAY, false);

export const randomDateMonthsBefore = (
  minMonths: number,
  maxMonths: number,
  from: Date = new Date()
): Date => randomDate(minMonths, maxMonths, from, ONE_MONTH);

export const randomDateMonthsAfter = (
  minMonths: number,
  maxMonths: number,
  from: Date = new Date()
): Date => randomDate(minMonths, maxMonths, from, ONE_MONTH, false);

export const randomDateYearsBefore = (
  minYears: number,
  maxYears: number,
  from: Date = new Date()
): Date => randomDate(minYears, maxYears, from, ONE_YEAR);

export const randomDateYearsAfter = (
  minYears: number,
  maxYears: number,
  from: Date = new Date()
): Date => randomDate(minYears, maxYears, from, ONE_YEAR, false);
