import { Sort, SortDirection } from '@angular/material/sort';
import { compareDates } from '../../../shared/utils/utils';
import { OccurrencesTableItem } from '../components/procedures-table/procedures-table.component';

export const createProceduresTableItemsComparator = (
  sort?: Sort
): ((a: OccurrencesTableItem, b: OccurrencesTableItem) => number) => {
  if (sort?.direction === '') {
    return () => 0;
  }
  return (a: OccurrencesTableItem, b: OccurrencesTableItem): number => {
    if (!sort) {
      return 0;
    }
    const { active, direction }: { active: string; direction: SortDirection } =
      sort;
    const modifier: number = direction === 'asc' ? 1 : -1;
    switch (active) {
      case 'procedure':
        return a.procedure.localeCompare(b.procedure) * modifier;
      case 'problem':
        return a.problem.localeCompare(b.problem) * modifier;
      case 'execution':
        return compareDates(a.executionDate, b.executionDate) * modifier;
      case 'termination':
        return compareDates(a.terminationDate, b.terminationDate) * modifier;
      case 'state':
        return (a.state - b.state) * modifier;
      default:
        return 0;
    }
  };
};
