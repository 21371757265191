import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { toggleMenu } from '../../../../features/users/store/users.actions';
import { selectIsMenuOpened } from '../../../../features/users/store/users.selectors';

type FlexContent = 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly';

@Component({
  selector: 'natea-cc-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss'],
})
export class BasePageComponent {
  @Input() hasSidebar = true;
  @Input() complexContent = true;
  @Input() subHeaderAlign: FlexContent = 'center';
  @Input() headerContentAlign: FlexContent = 'center';
  @Input() isCollapseSubHeader = false;
  @Input() isMainContentScroll = false;

  isMenuOpened$: Observable<boolean> = this.store.select(selectIsMenuOpened);

  constructor(private store: Store) {}

  public toggleSidebar = (): void => {
    this.store.dispatch(toggleMenu());
  };

  get subHeaderAlignClass(): string {
    return this.headerAlignClass(this.subHeaderAlign);
  }

  get headerContentAlignClass(): string {
    return this.headerAlignClass(this.headerContentAlign);
  }

  private headerAlignClass = (alignValue: FlexContent): string => {
    switch (alignValue) {
      case 'start':
        return 'content-start';
      case 'end':
        return 'content-end';
      case 'between':
        return 'content-between';
      case 'around':
        return 'content-around';
      case 'evenly':
        return 'content-evenly';
      default:
        return '';
    }
  };
}
