import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, iif, map, of, switchMap } from 'rxjs';
import { FamilyAnamnesisActions } from './family-anamnesis.actions';
import { FamilyAnamnesisWebApi } from '../webapi/family-anamnesis-webapi';
import { ApiError, errorMessage } from 'src/app/shared/entities/errors';
import { SnackbarService } from 'natea-components';
import { TranslocoService } from '@ngneat/transloco';
import { SNACK_BAR_DEBOUNCE_TIME_MS } from 'src/app/shared/utils/constants';
import {
  selectFamilyAnamnesisPathologies,
  selectHasNoPathologies,
} from './family-anamnesis.selectors';
import { Store } from '@ngrx/store';
import { selectOngoingEncounterAndPatientIds } from 'src/app/features/patients/store/patients.selectors';
import { FamilyAnamnesis } from '../entities/family-anamnesis';
import { selectLoadAllFamilyPathologies } from 'src/app/features/patients/store/clinical-data/anamnesis/patients-data-family-anamnesis.selectors';
import { AllFamilyPathologies } from '../entities/all-family-pathologies';

@Injectable()
export class FamilyAnamnesisEffects {
  loadAllFamilyAnamnesis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FamilyAnamnesisActions.LoadAllFamilyAnamnesis),

      concatLatestFrom(() => [
        this.store.select(selectOngoingEncounterAndPatientIds),
        this.store.select(selectFamilyAnamnesisPathologies),
      ]),

      exhaustMap(([, patientData, pathologies]) => {
        if (!patientData) {
          return of({
            type: FamilyAnamnesisActions.LoadAllFamilyAnamnesisFailure,
            error: {
              message: 'No patient data found',
              status: 400,
            },
          });
        }
        return this.familyAnamnesisWebApi
          .loadAllFamilyAnamnesis(
            pathologies,
            patientData.patientId,
            patientData.encounterId
          )
          .pipe(
            map((value) => {
              return {
                type: FamilyAnamnesisActions.LoadAllFamilyAnamnesisSuccess,
                allPathologies: value,
                patientId: patientData.patientId,
                encounterId: patientData.encounterId,
              };
            }),

            catchError((error: ApiError) => {
              return of({
                type: FamilyAnamnesisActions.LoadAllFamilyAnamnesisFailure,
                error,
              });
            })
          );
      })
    );
  });

  removeFamilyAnamnesisPathology$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: FamilyAnamnesisActions.RemovePathology;
        id: string;
      }>(FamilyAnamnesisActions.RemovePathology),

      concatLatestFrom(() => [
        this.store.select(selectLoadAllFamilyPathologies),
        this.store.select(selectOngoingEncounterAndPatientIds),
      ]),

      exhaustMap(([{ id }, pathologies, patientData]) => {
        if (!patientData) {
          return of({
            type: FamilyAnamnesisActions.RemovePathologyFailure,
            error: {
              message: 'No patient data found',
              status: 400,
            },
          });
        }
        return this.familyAnamnesisWebApi
          .removeFamilyAnamnesisPathology(id, pathologies)
          .pipe(
            map((allFamilyPathologies) => {
              return {
                type: FamilyAnamnesisActions.RemovePathologySuccess,
                allFamilyPathologies,
                patientId: patientData.patientId,
                encounterId: patientData.encounterId,
              };
            }),

            catchError((error: ApiError) => {
              return of({
                type: FamilyAnamnesisActions.RemovePathologyFailure,
                error,
              });
            })
          );
      })
    );
  });

  removeFamilyAnamnesisPathologySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FamilyAnamnesisActions.RemovePathologySuccess),
        map(() => {
          return this.snackBarService.showSnackbar(
            this.translocoService.translate(
              'anamnesis.notifications.removeFamilyAnamnesisPathologySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  removeFamilyAnamnesisPathologyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<{
          type: FamilyAnamnesisActions.RemovePathologyFailure;
          error: ApiError;
        }>(FamilyAnamnesisActions.RemovePathologyFailure),
        map(({ error }) => {
          return this.snackBarService.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'anamnesis.notifications.removeFamilyAnamnesisPathologyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  removeAllFamilyAnamnesis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FamilyAnamnesisActions.RemoveAllFamilyAnamnesis),

      concatLatestFrom(() => [
        this.store.select(selectOngoingEncounterAndPatientIds),
        this.store.select(selectLoadAllFamilyPathologies),
      ]),

      exhaustMap(([, patientsId, allFamilyPathologies]) => {
        return this.familyAnamnesisWebApi.removeAllFamilyAnamnesis().pipe(
          map(() => {
            return {
              type: FamilyAnamnesisActions.RemoveAllFamilyAnamnesisSuccess,
              patientId: patientsId?.patientId,
              encounterId: patientsId?.encounterId,
              allFamilyPathologies,
            };
          }),

          catchError((error: ApiError) => {
            return of({
              type: FamilyAnamnesisActions.RemoveAllFamilyAnamnesisFailure,
              error,
            });
          })
        );
      })
    );
  });

  removeAllFamilyAnamnesisSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FamilyAnamnesisActions.RemoveAllFamilyAnamnesisSuccess),
        map(() => {
          return this.snackBarService.showSnackbar(
            this.translocoService.translate(
              'anamnesis.notifications.removeAllFamilyAnamnesisSuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  removeAllFamilyAnamnesisFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<{
          type: FamilyAnamnesisActions.RemoveAllFamilyAnamnesisFailure;
          error: ApiError;
        }>(FamilyAnamnesisActions.RemoveAllFamilyAnamnesisFailure),
        map(({ error }) => {
          return this.snackBarService.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'anamnesis.notifications.removeAllFamilyAnamnesisFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  saveAllFamilyAnamnesis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: FamilyAnamnesisActions.SaveAllFamilyAnamnesis;
        allFamilyPathologies: AllFamilyPathologies;
      }>(FamilyAnamnesisActions.SaveAllFamilyAnamnesis),

      concatLatestFrom(() => [
        this.store.select(selectHasNoPathologies),
        this.store.select(selectOngoingEncounterAndPatientIds),
      ]),

      exhaustMap(
        ([{ allFamilyPathologies }, hasNoPathologies, patientData]) => {
          if (!patientData) {
            return of({
              type: FamilyAnamnesisActions.SaveAllFamilyAnamnesisFailure,
              error: {
                message: 'No patient data found',
                status: 400,
              },
            });
          }
          return this.familyAnamnesisWebApi
            .saveAllFamilyAnamnesis(allFamilyPathologies, hasNoPathologies)
            .pipe(
              map((value) => {
                return {
                  type: FamilyAnamnesisActions.SaveAllFamilyAnamnesisSuccess,
                  allPathologies: value,
                  patientId: patientData.patientId,
                  encounterId: patientData.encounterId,
                };
              }),

              catchError((error: ApiError) => {
                return of({
                  type: FamilyAnamnesisActions.SaveAllFamilyAnamnesisFailure,
                  error,
                });
              })
            );
        }
      )
    );
  });

  saveAllFamilyAnamnesisSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FamilyAnamnesisActions.SaveAllFamilyAnamnesisSuccess),
        map(() => {
          return this.snackBarService.showSnackbar(
            this.translocoService.translate(
              'anamnesis.notifications.saveAllFamilyAnamnesisSuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  saveAllFamilyAnamnesisFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<{
          type: FamilyAnamnesisActions.SaveAllFamilyAnamnesisFailure;
          error: ApiError;
        }>(FamilyAnamnesisActions.SaveAllFamilyAnamnesisFailure),
        map(({ error }) => {
          return this.snackBarService.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'anamnesis.notifications.saveAllFamilyAnamnesisFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  insetNewPathology$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: FamilyAnamnesisActions.InsetNewPathology;
        pathology: FamilyAnamnesis;
      }>(FamilyAnamnesisActions.InsetNewPathology),

      concatLatestFrom(() => [
        this.store.select(selectOngoingEncounterAndPatientIds),
        this.store.select(selectLoadAllFamilyPathologies),
      ]),

      switchMap(([{ pathology }, patientData, pathologies]) => {
        if (!patientData) {
          return of({
            type: FamilyAnamnesisActions.InsetNewPathologyFailure,
            error: {
              message: 'No patient data found',
              status: 400,
            },
          });
        }
        return this.familyAnamnesisWebApi

          .insetNewPathology(pathology, pathologies)
          .pipe(
            map((value) => {
              return {
                type: FamilyAnamnesisActions.InsetNewPathologySuccess,
                pathology: value,
                patientId: patientData.patientId,
                encounterId: patientData.encounterId,
              };
            }),

            catchError((error: ApiError) => {
              return of({
                type: FamilyAnamnesisActions.InsetNewPathologyFailure,
                error,
              });
            })
          );
      })
    );
  });

  insetNewPathologySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FamilyAnamnesisActions.InsetNewPathologySuccess),
        map(() => {
          return this.snackBarService.showSnackbar(
            this.translocoService.translate(
              'anamnesis.notifications.insetNewPathologySuccess'
            ),
            this.translocoService.translate('common.buttons.close'),
            'success-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  insetNewPathologyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<{
          type: FamilyAnamnesisActions.InsetNewPathologyFailure;
          error: ApiError;
        }>(FamilyAnamnesisActions.InsetNewPathologyFailure),
        map(({ error }) => {
          return this.snackBarService.showSnackbar(
            errorMessage(error) ??
              this.translocoService.translate(
                'anamnesis.notifications.insetNewPathologyFailure'
              ),
            this.translocoService.translate('common.buttons.close'),
            'error-snackbar',
            SNACK_BAR_DEBOUNCE_TIME_MS
          );
        })
      );
    },
    { dispatch: false }
  );

  checkDiseaseExistence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<{
        type: FamilyAnamnesisActions.CheckDiseaseExistence;
        pathology: FamilyAnamnesis;
      }>(FamilyAnamnesisActions.CheckDiseaseExistence),
      concatLatestFrom(() => [
        this.store.select(selectFamilyAnamnesisPathologies),
      ]),

      switchMap(([{ pathology }, pathologies]) =>
        iif(
          () =>
            pathologies.some(
              (p) => p.pathology.label === pathology.pathology.label
            ),
          of({
            type: FamilyAnamnesisActions.DiseaseExist,
          }),
          of({
            type: FamilyAnamnesisActions.InsetNewPathology,
            pathology,
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private familyAnamnesisWebApi: FamilyAnamnesisWebApi,
    private snackBarService: SnackbarService,
    private translocoService: TranslocoService,
    private store: Store
  ) {}
}
