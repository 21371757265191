import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  DepartmentApiService,
  GeneralResponseListDepartmentResource,
} from '../../../core/generated/api';
import { Department } from '../../../shared/entities/department';
import { DepartmentsAdapters } from './departments.adapters';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsWebApi {
  constructor(private departmentsApiService: DepartmentApiService) {}

  public getDepartments(): Observable<Department[]> {
    return this.departmentsApiService.getUserDepartments().pipe(
      map((result: GeneralResponseListDepartmentResource) => {
        return result.payload?.map(DepartmentsAdapters.departmentBeToFe) ?? [];
      })
    );
  }
}
