/**
 * Medical Records API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DetectedProblemLogEntryResource } from './detectedProblemLogEntryResource';


/**
 * Represents a log entry resource.
 */
export interface LogResourceDetectedProblemLogEntryResource { 
    /**
     * Timestamp when the log entry was generated.
     */
    createdAt: string;
    /**
     * Unique identifier for the user who generated the log entry.
     */
    createdById: string;
    eventObject: DetectedProblemLogEntryResource;
    /**
     * The type of the event recorded by the log entry.
     */
    eventTypeEnum: LogResourceDetectedProblemLogEntryResource.EventTypeEnumEnum;
    /**
     * Unique identifier for the log entry.
     */
    id: string;
}
export namespace LogResourceDetectedProblemLogEntryResource {
    export type EventTypeEnumEnum = 'CREATE' | 'UPDATE';
    export const EventTypeEnumEnum = {
        Create: 'CREATE' as EventTypeEnumEnum,
        Update: 'UPDATE' as EventTypeEnumEnum
    };
}


