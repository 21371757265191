<mat-form-field
  *ngIf="!isDateRange"
  [color]="isError ? 'warn' : undefined"
  [class.no-hint]="hintLabel === undefined"
  [class.fill-width]="fillParentWidth"
  [class.auto-width]="autoWidth"
  [class.no-hint]="hintLabel === undefined && errorMessages === undefined"
  [class.warn]="errorMessages !== undefined"
>
  <mat-hint class="warn" *ngIf="errorMessages !== undefined">
    <natea-field-error [errorMessages]="errorMessages"></natea-field-error>
  </mat-hint>

  <mat-label
    *ngIf="label"
    [class.input-filled]="isAddClass"
    [class.disabled]="isDisabled"
    >{{ label }}</mat-label
  >
  <input
    matInput
    [matDatepicker]="picker"
    [placeholder]="placeholder"
    [value]="inputValue"
    [required]="isRequired"
    [matDatepickerFilter]="dateFilter"
    [max]="maxDate"
    [min]="minDate"
    (dateChange)="onDateChanged($event)"
    (dateInput)="onDateInput($event)"
    [disabled]="isDisabled"
  />
  <mat-hint
    *ngIf="hintLabel"
    [class.warn]="isError"
    [class.disabled]="isDisabled"
    [align]="alignHint"
    >{{ hintLabel }}</mat-hint
  >

  <mat-datepicker-toggle matIconSuffix [for]="picker" [disabled]="isDisabled">
    <natea-icons
      name="calendar"
      class="icon"
      matDatepickerToggleIcon
    ></natea-icons>
  </mat-datepicker-toggle>
  <mat-datepicker
    #picker
    [color]="color"
    [calendarHeaderComponent]="calendarHeaderComponent"
    [opened]="isOpened"
    [xPosition]="xPosition"
    [yPosition]="yPosition"
    [panelClass]="panelClass"
    [restoreFocus]="restoreFocus"
    ngDefaultControl
    [startView]="startView"
    [touchUi]="touchUi"
    (opened)="onOpened()"
    (viewChanged)="onViewChanged($event)"
    (monthSelected)="onMonthSelected($event)"
    (yearSelected)="onYearSelected($event)"
    (closed)="onClosed()"
    [disabled]="isDisabled"
  ></mat-datepicker>
</mat-form-field>
