import { PatientEncounter } from '../../problems/interfaces/patient-encounter';

export const encounterSorter = (
  a: PatientEncounter,
  b: PatientEncounter
): number => {
  if (!a.endDate) {
    return -1;
  }
  if (!b.endDate) {
    return 1;
  }
  return b.endDate.getTime() - a.endDate.getTime();
};
