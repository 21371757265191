import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DurationPickerDirective } from '../../directives/duration-picker.directive';
import { NateaIconsName } from '../../icons/natea-icon';
import { NateaIconsModule } from '../../icons/natea-icons.module';
import { FieldErrorComponent } from '../field/field-error/field-error.component';
import { FieldComponent } from '../field/field.component';

type InputValueType = string | number | null;

@Component({
  selector: 'natea-input',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NateaIconsModule,
    ReactiveFormsModule,
    FieldErrorComponent,
    DurationPickerDirective,
  ],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: InputComponent, multi: true },
  ],
})
export class InputComponent
  extends FieldComponent
  implements ControlValueAccessor, OnChanges
{
  inputValue: InputValueType = null;
  isAddClass = false;

  onChange!: (val: InputValueType) => void;

  onTouched!: () => void;

  markAsTouched = (): void => {
    if (this.onTouched) {
      this.onTouched();
    }
  };

  writeValue = (val: string | number | null): void => {
    this.inputValue = val;
  };

  registerOnChange = (fn: (val: string | number | null) => void): void => {
    this.onChange = fn;
  };

  registerOnTouched = (fn: () => void): void => {
    this.onTouched = fn;
  };

  setDisabledState = (isDisabled: boolean): void => {
    this.isDisabled = isDisabled;
  };

  @Input() placeholder = '';

  @Input() type = '';

  @Input() leftIcon?: NateaIconsName;
  @Input() rightIcon?: NateaIconsName;

  @Input() isError = false;

  @Input() maxLength?: number = undefined;
  @Input() min?: number = undefined;
  @Input() max?: number = undefined;
  @Input() setOuterValue = '';

  @Input() fractionSize?: number = undefined;

  @Output() leftIconClick: EventEmitter<void> = new EventEmitter();
  @Output() rightIconClick: EventEmitter<void> = new EventEmitter();

  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() focusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  hasFocus = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['setOuterValue'] && changes['setOuterValue'].currentValue) {
      this.inputValue = this.setOuterValue;
    }
  }

  onLeftIconClick = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    this.markAsTouched();
    this.leftIconClick.emit();
  };

  onRightIconClick = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    this.markAsTouched();
    this.rightIconClick.emit();
  };

  inputChanged = (event: Event): void => {
    if (this.fractionSize !== undefined) {
      this.restrictDecimalDigits(event as KeyboardEvent, this.fractionSize);
    }
    this.inputValue = (event.target as HTMLInputElement).value;
    this.valueChanged.emit(this.inputValue);
    if (this.onChange) {
      this.onChange(this.inputValue);
    }

    this.isAddClass = !!this.inputValue;
  };

  private restrictDecimalDigits(event: KeyboardEvent, size: number) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    const parts = value.split('.');

    if (parts.length === 2 && parts[1].length > size) {
      // Prevent more than [size] decimal digits
      inputElement.value = parts[0] + '.' + parts[1].slice(0, size);
    }
  }

  onFocus = (): void => {
    this.hasFocus = true;
    this.focusChanged.emit(true);
  };

  onKeyDown = (event: KeyboardEvent): boolean => {
    if (this.type === 'number' && (event.key === 'e' || event.key === 'E')) {
      return false;
    }
    return true;
  };

  onBlur = (): void => {
    this.markAsTouched();
    this.hasFocus = false;
    this.focusChanged.emit(false);
  };
}
