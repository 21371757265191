<mat-form-field
  [class.fill-width]="fillParentWidth"
  [class.auto-width]="autoWidth"
  [class.no-hint]="hintLabel === undefined && errorMessages === undefined"
  [class.warn]="errorMessages !== undefined"
>
  <mat-hint
    *ngIf="hintLabel !== undefined && !errorMessages"
    [class.disabled]="isDisabled"
    >{{ hintLabel }}</mat-hint
  >

  <mat-label *ngIf="label" [class.filled]="textareaIsFilled">{{
    label
  }}</mat-label>
  <textarea
    matInput
    [rows]="rows"
    [required]="isRequired"
    [value]="value"
    [disabled]="isDisabled"
    [class.not-resizable]="!resizable"
    (input)="onInput($event)"
    (blur)="onBlurred()"
  ></textarea>

  <mat-hint class="warn" *ngIf="errorMessages !== undefined">
    <natea-field-error [errorMessages]="errorMessages"></natea-field-error>
  </mat-hint>
</mat-form-field>
