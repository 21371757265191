import { Sort } from '@angular/material/sort';
import { createSelector } from '@ngrx/store';
import { OptionsItem } from 'natea-components';
import { MultiSectionTimelineInstance } from '../../../../shared/components/multi-section-timeline/models/multi-section-timeline-instance';
import { selectShownEncounterTransfusionalTherapies } from '../../../patients/store/clinical-data/therapies/patients-data.transfusional-therapies.selectors';
import { selectTransfusionalTherapiesUiState } from '../../../patients/store/selected-patient/selected-patient.selectors';
import { therapyComparator } from '../../common/entities/therapies-comparator';
import { TherapiesTableItem } from '../../common/entities/therapies-table-item';
import {
  TherapyOccurrence,
  TherapyOccurrenceSearchResultItem,
} from '../../common/entities/therapy-occurrence';
import { SelectedTherapyItem } from '../../instrumental-therapies/entities/instrumental-therapy-item-selected';
import { mapTherapyPrescriptionToTimelineOccurrence } from '../../common/utils/therapy-timeline-mapper';
import { BloodComponent } from '../entities/blood-component';
import {
  TransfusionalTherapy,
  TransfusionalTherapyOccurrence,
} from '../entities/transfusional-therapy';
import { mapTransfusionalTherapyOccurrenceToTableItem } from '../mapper/transfusional-therapies-mapper';
import { TransfusionalTherapiesUiState } from './transfusional-therapies.reducer';

export const selectIsLoadingTransfusionalTherapies = createSelector(
  selectTransfusionalTherapiesUiState,
  (state: TransfusionalTherapiesUiState): boolean =>
    state.isLoadingData && state.isLoadingTypes
);

export const selectTransfusionalTherapiesSort = createSelector(
  selectTransfusionalTherapiesUiState,
  (state: TransfusionalTherapiesUiState): Sort | undefined => state.sort
);

export const selectShowCreateNewTransfusionalTherapyModal = createSelector(
  selectTransfusionalTherapiesUiState,
  (state: TransfusionalTherapiesUiState): boolean => state.showCreateNewModal
);

export const selectIsCreatingNewTransfusionalTherapy = createSelector(
  selectTransfusionalTherapiesUiState,
  (state: TransfusionalTherapiesUiState): boolean => state.isCreatingNew
);

export const selectSelectedTransfusionalTherapyPrescription = createSelector(
  selectTransfusionalTherapiesUiState,
  selectShownEncounterTransfusionalTherapies,
  (
    state: TransfusionalTherapiesUiState,
    therapies: TransfusionalTherapy[] | undefined
  ): TransfusionalTherapy | undefined =>
    therapies?.find(
      (therapy) => therapy.id === state.selectedTherapyIds?.therapyId
    )
);

export const selectSelectedTransfusionalTherapyId = createSelector(
  selectTransfusionalTherapiesUiState,
  (state: TransfusionalTherapiesUiState): SelectedTherapyItem => {
    return {
      selectedOccurrenceId: state.selectedTherapyIds,
      fromTable: state.selectedTherapyOccurrenceInTable,
      changingTab: state.changingTab,
    };
  }
);

export const selectSelectedTransfusionalTherapyOccurrence = createSelector(
  selectSelectedTransfusionalTherapyPrescription,
  selectSelectedTransfusionalTherapyId,
  (
    prescription?: TransfusionalTherapy,
    ids?: SelectedTherapyItem
  ): TransfusionalTherapyOccurrence | undefined =>
    prescription?.occurrences.find(
      (occurrence) => occurrence.id === ids?.selectedOccurrenceId?.occurrenceId
    )
);

export const selectTransfusionalTherapyCandidateForDeletion = createSelector(
  selectTransfusionalTherapiesUiState,
  selectShownEncounterTransfusionalTherapies,
  (
    state: TransfusionalTherapiesUiState,
    therapies: TransfusionalTherapy[] | undefined
  ): TransfusionalTherapy | undefined =>
    therapies?.find((therapy) => therapy.id === state.candidateForDeletionId)
);

export const selectTransfusionalTherapiesAsTableItems = createSelector(
  selectShownEncounterTransfusionalTherapies,
  selectTransfusionalTherapiesSort,
  (
    therapies?: TransfusionalTherapy[],
    sort?: Sort
  ): TherapiesTableItem[] | undefined => {
    const comparator = therapyComparator(sort);
    const items = therapies?.reduce((acc, therapy) => {
      return acc.concat(
        therapy.occurrences.map((occurrence) =>
          mapTransfusionalTherapyOccurrenceToTableItem(occurrence, therapy)
        )
      );
    }, [] as TherapiesTableItem[]);
    return items?.sort(comparator);
  }
);

// Shown encounter therapies as timeline items
export const selectTransfusionalTherapiesAsTimelineItems = createSelector(
  selectShownEncounterTransfusionalTherapies,
  (
    therapies: TransfusionalTherapy[] | undefined
  ): MultiSectionTimelineInstance<TherapyOccurrence>[] | undefined => {
    if (!therapies) return undefined;

    const bloodComponentMap = new Map<BloodComponent, TransfusionalTherapy[]>();
    therapies?.forEach((therapy) => {
      const bloodComponent = therapy.bloodComponent;
      if (!bloodComponentMap.has(bloodComponent)) {
        bloodComponentMap.set(bloodComponent, [therapy]);
      } else {
        bloodComponentMap.get(bloodComponent)?.push(therapy);
      }
    });
    return Array.from(bloodComponentMap.keys()).map((bloodComponent) => ({
      id: bloodComponent.id,
      label: bloodComponent.name,
      occurrences:
        bloodComponentMap.get(bloodComponent)?.map((therapy) => {
          const result = mapTherapyPrescriptionToTimelineOccurrence(therapy);
          return result;
        }) ?? [],
    }));
  }
);

export const selectTransfusionalTherapyTypes = createSelector(
  selectTransfusionalTherapiesUiState,
  (state: TransfusionalTherapiesUiState): BloodComponent[] | undefined =>
    state.therapyTypes
);

export const selectTransfusionalTherapyTypesAsOptionsItems = createSelector(
  selectTransfusionalTherapyTypes,
  (types?: BloodComponent[]): OptionsItem<BloodComponent>[] | undefined =>
    types?.map((type) => ({
      id: type.id,
      label: type.name,
      data: type,
    }))
);

export const selectTransfusionalTherapiesTabIndex = createSelector(
  selectTransfusionalTherapiesUiState,
  (state: TransfusionalTherapiesUiState): number => state.tabIndex
);

export const selectIsUpdatingTransfusionalTherapy = createSelector(
  selectTransfusionalTherapiesUiState,
  (state: TransfusionalTherapiesUiState): boolean | undefined =>
    state.isUpdating
);

export const selectIsConfirmSuspend = createSelector(
  selectTransfusionalTherapiesUiState,
  (state: TransfusionalTherapiesUiState): boolean | undefined =>
    state.isConfirmSuspend
);

export const selectIsConfirmTransfusionalTherapyRunning = createSelector(
  selectTransfusionalTherapiesUiState,
  (state: TransfusionalTherapiesUiState): boolean | undefined =>
    state.isConfirmRunning
);

export const selectIsShowConfirmAbortModal = createSelector(
  selectTransfusionalTherapiesUiState,
  (state: TransfusionalTherapiesUiState): boolean =>
    state.isShowConfirmAbortModal
);

export const selectTransfusionalTherapyCandidateForSuspend = createSelector(
  selectTransfusionalTherapiesUiState,
  selectShownEncounterTransfusionalTherapies,
  (
    state: TransfusionalTherapiesUiState,
    therapies: TransfusionalTherapy[] | undefined
  ): TransfusionalTherapy | undefined =>
    therapies?.find((therapy) => therapy.id === state.candidateForSuspendId)
);

export const selectTransfusionalTherapyCandidateForAbort = createSelector(
  selectTransfusionalTherapiesUiState,
  selectShownEncounterTransfusionalTherapies,
  (
    state: TransfusionalTherapiesUiState,
    therapies: TransfusionalTherapy[] | undefined
  ): TransfusionalTherapy | undefined =>
    therapies?.find((therapy) => therapy.id === state.candidateForAbortId)
);

export const selectIShowDeleteTransfusionalTherapy = createSelector(
  selectTransfusionalTherapiesUiState,
  (state: TransfusionalTherapiesUiState): boolean =>
    state.isShowConfirmDeleteModal
);

export const selectIsDeleteRunning = createSelector(
  selectTransfusionalTherapiesUiState,
  (state: TransfusionalTherapiesUiState): boolean => state.isConfirmRunning
);

export const selectGetCandidateForDeleteTherapyId = createSelector(
  selectTransfusionalTherapiesUiState,
  (state: TransfusionalTherapiesUiState): string | undefined =>
    state.candidateForDeletionId
);

export const selectTransfusionalTherapiesAsSearchResultItems = createSelector(
  selectTransfusionalTherapiesAsTableItems,
  (
    items: TherapiesTableItem[] | undefined
  ): TherapyOccurrenceSearchResultItem[] | undefined => {
    return items?.map((item) => ({
      id: item.id,
      prescriptionId: item.therapyId,
      ingredient: item.name,
      author: item.createdBy,
      prescriptionDate: item.prescriptionDate,
      state: item.state,
      durationType: item.durationType,
      programmingDate: item.programmingDate,
    }));
  }
);
