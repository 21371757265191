import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import {
  diseaseExist,
  emptyPathologies,
  generalFamilyAnamnesisNote,
  hideModalInsertNewPathology,
  hideWarningModalRemoveAll,
  loadAllFamilyAnamnesisSuccess,
  removeAllFamilyAnamnesisSuccess,
  saveAllFamilyAnamnesisSuccess,
  showWarningModalRemoveAll,
  shownModalInsertNewPathology,
  insetNewPathologySuccess,
  checkDiseaseExistence,
  removeAllFamilyAnamnesis,
  removeAllFamilyAnamnesisFailure,
  saveAllFamilyAnamnesis,
} from './family-anamnesis.actions';
import { WarningMessageEnum } from '../entities/warnig-message-enum';

export interface FamilyAnamnesisUiState {
  isSaving: boolean;
  showModalInsertNewPathology: boolean;
  hasNoPathologies?: boolean;
  generalNotes: string;
  showLoaderFullPage: boolean;
  warningMessageEnum?: WarningMessageEnum;
  showLoader: boolean;
}

export const iniTialFamilyAnamnesisUiState: FamilyAnamnesisUiState = {
  isSaving: false,
  showModalInsertNewPathology: false,
  hasNoPathologies: false,
  showLoader: false,
  generalNotes: '',
  showLoaderFullPage: true,
};

export const familyAnamnesisUiReducer: ActionReducer<
  FamilyAnamnesisUiState,
  Action
> = createReducer(
  iniTialFamilyAnamnesisUiState,
  on(
    insetNewPathologySuccess,
    (state): FamilyAnamnesisUiState => ({
      ...state,
      showModalInsertNewPathology: false,
      showLoader: false,
      hasNoPathologies: false,
    })
  ),
  on(
    shownModalInsertNewPathology,
    (state): FamilyAnamnesisUiState => ({
      ...state,
      showModalInsertNewPathology: true,
    })
  ),
  on(
    hideModalInsertNewPathology,
    (state): FamilyAnamnesisUiState => ({
      ...state,
      showModalInsertNewPathology: false,
    })
  ),

  on(
    emptyPathologies,
    (state, { hasPathology }): FamilyAnamnesisUiState => ({
      ...state,
      hasNoPathologies: hasPathology,
    })
  ),

  on(
    removeAllFamilyAnamnesis,
    (state): FamilyAnamnesisUiState => ({
      ...state,
      showLoader: true,
    })
  ),

  on(
    removeAllFamilyAnamnesisSuccess,
    (state): FamilyAnamnesisUiState => ({
      ...state,
      showLoader: false,
    })
  ),

  on(
    removeAllFamilyAnamnesisFailure,
    (state): FamilyAnamnesisUiState => ({
      ...state,
      showLoader: false,
    })
  ),

  on(
    showWarningModalRemoveAll,
    (state): FamilyAnamnesisUiState => ({
      ...state,
      warningMessageEnum: WarningMessageEnum.REMOVE_ALL,
    })
  ),
  on(
    checkDiseaseExistence,
    (state): FamilyAnamnesisUiState => ({
      ...state,
      showLoader: true,
    })
  ),
  on(
    hideWarningModalRemoveAll,
    (state): FamilyAnamnesisUiState => ({
      ...state,
      warningMessageEnum: undefined,
    })
  ),
  on(
    diseaseExist,
    (state): FamilyAnamnesisUiState => ({
      ...state,
      warningMessageEnum: WarningMessageEnum.DUPLICATION,
      showLoader: false,
    })
  ),
  on(
    removeAllFamilyAnamnesisSuccess,
    (state): FamilyAnamnesisUiState => ({
      ...state,
      warningMessageEnum: undefined,
    })
  ),

  on(
    saveAllFamilyAnamnesisSuccess,
    (state, { allPathologies }): FamilyAnamnesisUiState => ({
      ...state,
      hasNoPathologies: allPathologies.hasNoPathology,
      isSaving: false,
    })
  ),

  on(
    loadAllFamilyAnamnesisSuccess,
    (state, { allPathologies }): FamilyAnamnesisUiState => ({
      ...state,
      hasNoPathologies: allPathologies.pathologies.length === 0,
      showLoaderFullPage: false,
    })
  ),
  on(
    saveAllFamilyAnamnesis,
    (state): FamilyAnamnesisUiState => ({
      ...state,
      isSaving: true,
    })
  ),

  on(
    generalFamilyAnamnesisNote,
    (state, { note }): FamilyAnamnesisUiState => ({
      ...state,
      generalNotes: note,
    })
  )
);
