import { Sort } from '@angular/material/sort';
import { createSelector } from '@ngrx/store';
import { OptionsItem } from 'natea-components';
import { NutritionalCategoriesDomainMap } from '../../../../core/domains/store/domains.reducer';
import { selectNutritionalCategoriesMap } from '../../../../core/domains/store/domains.selectors';
import { MultiSectionTimelineInstance } from '../../../../shared/components/multi-section-timeline/models/multi-section-timeline-instance';
import { selectShownEncounterNutritionalTherapies } from '../../../patients/store/clinical-data/therapies/patients-data.nutritional-therapies.selectors';
import { selectNutritionalTherapiesUiState } from '../../../patients/store/selected-patient/selected-patient.selectors';
import {
  TherapyOccurrence,
  TherapyOccurrenceSearchResultItem,
} from '../../common/entities/therapy-occurrence';
import { mapTherapyOccurrenceToTimelineOccurrence } from '../../common/utils/therapy-timeline-mapper';
import { SelectedTherapyItem } from '../../instrumental-therapies/entities/instrumental-therapy-item-selected';
import { NutritionalTherapyTableOccurrence } from '../components/nutritional-therapies-table/nutritional-therapies-table.component';
import {
  NutritionalTherapy,
  NutritionalTherapyOccurrence,
} from '../entities/nutritional-therapy';
import { NutritionalTherapyCatalogs } from '../entities/nutritional-therapy-catalogs';
import {
  BreastMilkType,
  FeedingMethod,
  FeedingType,
  MilkBrand,
  MilkFormulaMotivation,
  NutritionalCategory,
  NutritionalParenteralBag,
  NutritionalParenteralRepetition,
  NutritionalSupplementType,
} from '../entities/nutritional-therapy-type';
import { mapNutritionalTherapiesOccurrenceToTableItems } from '../utils/map-nutritional-therapies-occurrence-to-table-items';
import { nutritionalTherapiesComparators } from '../utils/nutritional-therapies-comparator';
import {
  optionItemFromBreastMilkType,
  optionItemFromFeedingMethod,
  optionItemFromFeedingType,
  optionItemFromMilkBrand,
  optionItemFromMilkFormulaMotivation,
  optionItemFromParenteralBag,
  optionItemFromParenteralRepetition,
  optionItemFromSupplementType,
} from '../utils/nutritional-therapies-utils';
import { NutritionalTherapiesUiState } from './nutritional-therapies.reducer';

export const selectIsLoadingNutritionalTherapies = createSelector(
  selectNutritionalTherapiesUiState,
  (state: NutritionalTherapiesUiState): boolean =>
    state.isLoadingData || state.isLoadingCatalogs
);

export const selectNutritionalTherapiesSort = createSelector(
  selectNutritionalTherapiesUiState,
  (state: NutritionalTherapiesUiState): Sort | undefined => state.sort
);

export const selectShowCreateNewEnteralTherapyModal = createSelector(
  selectNutritionalTherapiesUiState,
  (state: NutritionalTherapiesUiState): boolean =>
    state.showCreateNewEnteralModal
);

export const selectShowCreateNewParenteralTherapyModal = createSelector(
  selectNutritionalTherapiesUiState,
  (state: NutritionalTherapiesUiState): boolean =>
    state.showCreateNewParenteralModal
);

export const selectIsCreatingNewNutritionalTherapy = createSelector(
  selectNutritionalTherapiesUiState,
  (state: NutritionalTherapiesUiState): boolean => state.isCreatingNew
);

export const selectSelectedNutritionalTherapy = createSelector(
  selectNutritionalTherapiesUiState,
  selectShownEncounterNutritionalTherapies,
  (
    state: NutritionalTherapiesUiState,
    therapies: NutritionalTherapy[] | undefined
  ): NutritionalTherapy | undefined =>
    therapies?.find((therapy) => therapy.id === state.selectedTherapyId)
);

export const selectNutritionalTherapyCandidateForDeletion = createSelector(
  selectNutritionalTherapiesUiState,
  selectShownEncounterNutritionalTherapies,
  (
    state: NutritionalTherapiesUiState,
    therapies: NutritionalTherapy[] | undefined
  ): NutritionalTherapy | undefined =>
    therapies?.find((therapy) => therapy.id === state.candidateForDeletionId)
);

/********************* TIMELINE ITEMS *********************/
interface NutritionalTimelineRow {
  label: string;
  category: NutritionalCategory;
  breastMilkType?: BreastMilkType;
}

const getCategoryLabel = (
  category: NutritionalCategory,
  nutritionalCategoriesMap: NutritionalCategoriesDomainMap,
  breastMilkType?: BreastMilkType
): string => {
  console.log('category', category);
  console.log('nutritionalCategoriesMap', nutritionalCategoriesMap);
  console.log('breastMilkType', breastMilkType);
  let label = nutritionalCategoriesMap.get(category)?.label ?? '';
  if (breastMilkType) {
    label += ` ▪ ${breastMilkType.name}`;
  }
  return label;
};
// Shown encounter therapies as timeline items
export const selectNutritionalTherapiesAsTimelineItems = createSelector(
  selectShownEncounterNutritionalTherapies,
  selectNutritionalCategoriesMap,
  (
    therapies: NutritionalTherapy[] | undefined,
    nutritionalCategoriesMap: NutritionalCategoriesDomainMap
  ): MultiSectionTimelineInstance<TherapyOccurrence>[] | undefined => {
    if (!therapies) return undefined;

    const occurrencesMap = new Map<string, TherapyOccurrence[]>();
    const rowsMap = new Map<string, NutritionalTimelineRow>();

    therapies?.forEach((therapy) => {
      const category = therapy.category;
      if (category === 'enteral') {
        therapy.occurrences.forEach((occurrence) => {
          console.log('occurrence', occurrence);
          const breastMilkType =
            occurrence.administration?.breastMilkType ??
            occurrence.additionalInfo?.entry.milkType;
          const key = `${category}_${breastMilkType?.id?.toString()}`;
          if (!occurrencesMap.has(key)) {
            occurrencesMap.set(key, [occurrence]);
            rowsMap.set(key, {
              category: category,
              breastMilkType: breastMilkType,
              label: getCategoryLabel(
                category,
                nutritionalCategoriesMap,
                breastMilkType
              ),
            });
          } else {
            occurrencesMap.get(key)?.push(occurrence);
          }
        });
      } else {
        const key = `${category}`;
        therapy.occurrences.forEach((occurrence) => {
          if (!occurrencesMap.has(key)) {
            occurrencesMap.set(key, [occurrence]);
            rowsMap.set(key, {
              category: category,
              label: getCategoryLabel(category, nutritionalCategoriesMap),
            });
          } else {
            occurrencesMap.get(key)?.push(occurrence);
          }
        });
      }
    });

    return Array.from(rowsMap.keys()).map((key) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const row = rowsMap.get(key)!;

      return {
        id: key,
        label: row.label,

        occurrences:
          occurrencesMap.get(key)?.map((occurrence) => {
            const result = mapTherapyOccurrenceToTimelineOccurrence(occurrence);
            return result;
          }) ?? [],

        iconName: row.category === 'enteral' ? 'enterale' : 'parenterale',
      };
    });
  }
);

/********************* CATALOGS *********************/

export const selectIsLoadingCatalogs = createSelector(
  selectNutritionalTherapiesUiState,
  (state: NutritionalTherapiesUiState): boolean => state.isLoadingCatalogs
);

export const selectNutritionalTherapiesCatalogs = createSelector(
  selectNutritionalTherapiesUiState,
  (
    state: NutritionalTherapiesUiState
  ): NutritionalTherapyCatalogs | undefined => state.catalogs
);

export const selectNutritionalTherapiesFeedingTypesAsOptionItems =
  createSelector(
    selectNutritionalTherapiesCatalogs,
    (
      catalogs?: NutritionalTherapyCatalogs
    ): OptionsItem<FeedingType>[] | undefined => {
      return catalogs?.feedingTypes.map(optionItemFromFeedingType);
    }
  );

export const selectNutritionalTherapiesBreastMilkTypesAsOptionItems =
  createSelector(
    selectNutritionalTherapiesCatalogs,
    (
      catalogs?: NutritionalTherapyCatalogs
    ): OptionsItem<BreastMilkType>[] | undefined => {
      return catalogs?.breastMilkTypes.map(optionItemFromBreastMilkType);
    }
  );

export const selectNutritionalTherapiesMilkBrandsAsOptionItems = createSelector(
  selectNutritionalTherapiesCatalogs,
  (
    catalogs?: NutritionalTherapyCatalogs
  ): OptionsItem<MilkBrand>[] | undefined => {
    return catalogs?.milkBrands.map(optionItemFromMilkBrand);
  }
);

export const selectNutritionalTherapiesMilkFormulaMotivationsAsOptionItems =
  createSelector(
    selectNutritionalTherapiesCatalogs,
    (
      catalogs?: NutritionalTherapyCatalogs
    ): OptionsItem<MilkFormulaMotivation>[] | undefined => {
      return catalogs?.milkFormulaMotivations.map(
        optionItemFromMilkFormulaMotivation
      );
    }
  );

export const selectNutritionalTherapiesFeedingMethodsAsOptionItems =
  createSelector(
    selectNutritionalTherapiesCatalogs,
    (
      catalogs?: NutritionalTherapyCatalogs
    ): OptionsItem<FeedingMethod>[] | undefined => {
      return catalogs?.feedingMethods.map(optionItemFromFeedingMethod);
    }
  );

export const selectNutritionalTherapiesSupplementTypeAsOptionItems =
  createSelector(
    selectNutritionalTherapiesCatalogs,
    (
      catalogs?: NutritionalTherapyCatalogs
    ): OptionsItem<NutritionalSupplementType>[] | undefined => {
      return catalogs?.feedingMethods.map(optionItemFromSupplementType);
    }
  );

/********************* TABLE ITEMS *********************/

export const selectNutritionTableItems = createSelector(
  selectShownEncounterNutritionalTherapies,
  selectNutritionalTherapiesSort,
  (
    nutritionalTherapies?: NutritionalTherapy[],
    sort?: Sort
  ): NutritionalTherapyTableOccurrence[] => {
    if (!nutritionalTherapies) return [];
    const comparator = nutritionalTherapiesComparators(sort);

    const items = nutritionalTherapies?.reduce((acc, therapy) => {
      return acc.concat(
        therapy.occurrences.map((occurrence) =>
          mapNutritionalTherapiesOccurrenceToTableItems(occurrence, therapy)
        )
      );
    }, [] as NutritionalTherapyTableOccurrence[]);

    return items.sort(comparator);
  }
);

export const selectSelectedNutritionalTherapyIds = createSelector(
  selectNutritionalTherapiesUiState,
  (state: NutritionalTherapiesUiState): SelectedTherapyItem => {
    return {
      selectedOccurrenceId: state.selectedTherapyOccurrence,
      fromTable: state.selectedTherapyOccurrenceInTable,
      changingTab: state.changingTab,
    };
  }
);

export const selectSelectedTab = createSelector(
  selectNutritionalTherapiesUiState,
  (state: NutritionalTherapiesUiState): number => state.selectedTab
);

export const selectSelectedNutritionalTherapyPrescription = createSelector(
  selectSelectedNutritionalTherapyIds,
  selectShownEncounterNutritionalTherapies,
  (
    selectedOccurrenceIds: SelectedTherapyItem | undefined,
    therapies: NutritionalTherapy[] | undefined
  ): NutritionalTherapy | undefined =>
    therapies?.find(
      (therapy) =>
        therapy.id === selectedOccurrenceIds?.selectedOccurrenceId?.therapyId
    )
);

export const selectSelectedTherapyOccurrence = createSelector(
  selectSelectedNutritionalTherapyIds,
  selectSelectedNutritionalTherapyPrescription,
  (
    ids?: SelectedTherapyItem,
    prescription?: NutritionalTherapy
  ): NutritionalTherapyOccurrence | undefined =>
    (prescription?.occurrences as NutritionalTherapyOccurrence[])?.find(
      (occurrence: NutritionalTherapyOccurrence) =>
        occurrence.id === ids?.selectedOccurrenceId?.occurrenceId
    )
);

export const selectIsNutritionalTherapyOccurrenceSelected = createSelector(
  selectSelectedNutritionalTherapyIds,
  (occurrenceIds?: SelectedTherapyItem): boolean =>
    occurrenceIds?.selectedOccurrenceId != undefined
);

export const selectIsShowDeleteConfirmationModal = createSelector(
  selectNutritionalTherapiesUiState,
  (state: NutritionalTherapiesUiState): boolean =>
    state.isShowDeleteConfirmationModal
);

export const selectIsDeletePressing = createSelector(
  selectNutritionalTherapiesUiState,
  (state: NutritionalTherapiesUiState): boolean => state.isDeletePressing
);

export const selectNutritionalTherapiesParenteralRepetitionsAsOptionItems =
  createSelector(
    selectNutritionalTherapiesCatalogs,
    (
      catalogs?: NutritionalTherapyCatalogs
    ): OptionsItem<NutritionalParenteralRepetition>[] | undefined => {
      return catalogs?.parenteralRepetitions.map(
        optionItemFromParenteralRepetition
      );
    }
  );

export const selectIsShowAbortNutritionalTherapyConfirmationModal =
  createSelector(
    selectNutritionalTherapiesUiState,
    (state: NutritionalTherapiesUiState): boolean =>
      state.isShowAbortConfirmationModal
  );

export const selectNutritionalTherapiesParenteralBagsAsOptionItems =
  createSelector(
    selectNutritionalTherapiesCatalogs,
    (
      catalogs?: NutritionalTherapyCatalogs
    ): OptionsItem<NutritionalParenteralBag>[] | undefined => {
      return catalogs?.parenteralBags.map(optionItemFromParenteralBag);
    }
  );

export const selectNutritionalTherapiesSelectedParenteralBag = createSelector(
  selectNutritionalTherapiesUiState,
  (
    state: NutritionalTherapiesUiState
  ): {
    bag: NutritionalParenteralBag | undefined;
    formId: string | undefined;
  } => ({
    bag: state.selectedParenteralBag,
    formId: state.parenteralBagSelectorId,
  })
);

export const selectNutritionalTherapiesAsSearchResultItems = createSelector(
  selectNutritionTableItems,
  (
    items: NutritionalTherapyTableOccurrence[] | undefined
  ): TherapyOccurrenceSearchResultItem[] | undefined => {
    return items?.map((item) => ({
      id: item.id,
      prescriptionId: item.therapyId,
      ingredient: item.typology,
      author: item.author,
      prescriptionDate: item.prescriptionDate,
      state: item.state,
      durationType: item.durationType,
      programmingDate: item.programmingDate,
      executionDate: item.executionDate,
    }));
  }
);

export const selectIsConfirmAbortNutritionalTherapyPressing = createSelector(
  selectNutritionalTherapiesUiState,
  (state: NutritionalTherapiesUiState): boolean => state.isConfirmAbortPressing
);

export const selectCandidaTherapyForAbort = createSelector(
  selectNutritionalTherapiesUiState,
  selectShownEncounterNutritionalTherapies,
  (
    state: NutritionalTherapiesUiState,
    therapies: NutritionalTherapy[] | undefined
  ): NutritionalTherapy | undefined =>
    therapies?.find((therapy) => therapy.id === state.candidateForAbortId)
);

export const selectIsEnteralNutritionalTherapyRunning = createSelector(
  selectNutritionalTherapiesUiState,
  (state: NutritionalTherapiesUiState): boolean =>
    state.isSaveEnteralTherapyRunning
);

export const selectIsParenteralNutritionalTherapyRunning = createSelector(
  selectNutritionalTherapiesUiState,
  (state: NutritionalTherapiesUiState): boolean =>
    state.isSaveParenteralTherapyRunning
);
