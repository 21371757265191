import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NateaIconsName } from '../../../icons/natea-icon';
import { NateaIconsModule } from '../../../icons/natea-icons.module';

@Component({
  selector: 'natea-tab-item',
  standalone: true,
  imports: [CommonModule, NateaIconsModule],
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss'],
})
export class TabItemComponent {
  @Input() id = '';
  @Input() label = '';
  @Input() isSelected = false;
  @Input() iconName!: NateaIconsName;
}
