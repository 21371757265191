import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, delay, exhaustMap, of } from 'rxjs';
import { ApiError } from '../../../../shared/entities/errors';
import { selectOngoingEncounterAndPatientIds } from '../../../patients/store/patients.selectors';
import { nursingGanttMockData } from '../mocks/nursing-gantt.mock';
import { NursingGanttActions } from './nursing-gantt.actions';

@Injectable()
export class NursingGanttEffects {
  loadGanttData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NursingGanttActions.LoadGanttData),
      concatLatestFrom(() =>
        this.store.select(selectOngoingEncounterAndPatientIds)
      ),
      exhaustMap(([, onGoingEncounterIds]) =>
        of({
          type: NursingGanttActions.LoadGanttDataSuccess,
          patientId: onGoingEncounterIds?.patientId,
          encounterId: onGoingEncounterIds?.encounterId,
          data: nursingGanttMockData, // TODO real data
        }).pipe(delay(1000))
      ),
      catchError(
        (error: ApiError) =>
          of({
            type: NursingGanttActions.LoadGanttDataFailure,
            error,
          }) // TODO show snackbar
      )
    );
  });

  constructor(private actions$: Actions, private store: Store) {}
}
