<ng-container *ngrxLet="loggedUserShownName$ as shownName">
  <ng-container *ngrxLet="loggedUserRoleIcon$ as roleIcon">
    <button class="logo" (click)="goToRoot()"></button>
    <div class="splitter"></div>
    <natea-icons
      *ngIf="roleIcon"
      class="user-role-icon"
      [name]="roleIcon"
    ></natea-icons>
    <span class="user-shown-name">{{ shownName }}</span>

    <natea-icons
      *ngIf="(isUserLogged$ | async) ?? false"
      class="logout-icon"
      name="logout"
      (iconClick)="logout()"
    ></natea-icons>
  </ng-container>
</ng-container>
